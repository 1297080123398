import { AbstractControl, ValidatorFn, FormControl, FormGroup } from '@angular/forms';

export class CustomValidators {
    constructor() { }

    static nonEmpty(control: any) {
        if (!control.value.replace(/ /g, "") || control.value.replace(/ /g, "").length === 0) {
            return { 'noElements': true };
        }
        return null;
    }

    static onlyChar(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (control.value == '') return null;

            let re = new RegExp('^[a-zA-Z ]*$');
            if (re.test(control.value)) {
                return null;
            } else {
                return { onlyChar: true };
            }
        };
    }

    static mustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                return;
            }

            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            } else {
                matchingControl.setErrors(null);
            }
            return null;
        };
    }

    // static matchingPasswords(c: AbstractControl): { [key: string]: any } {
    static matchingPasswords(c: AbstractControl) {
        return (formGroup: FormGroup) => {
            let password: any = c.get(['password']);
            let confirmPassword: any = c.get(['confirmPassword']);

            if (password.value !== confirmPassword.value) {
                return { mismatchedPasswords: true };
            }

            return null;
            // return { mismatchedPasswords: false };
        };
    }
}