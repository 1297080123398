import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class EncryptDecryptService {

    private SecretKey = environment.SecretKey;

    // Declare this key and iv values in declaration
    private key = CryptoJS.enc.Utf8.parse('4512631236589784');
    private iv = CryptoJS.enc.Utf8.parse('4512631236589784');

    constructor() { }

    // Data Encryption Function
    encrypt(msg: string): string {
        let keySize = 256;
        let salt = CryptoJS.lib.WordArray.random(16);
        let key = CryptoJS.PBKDF2(this.SecretKey, salt, {
            keySize: keySize / 32,
            iterations: 100
        });

        let iv = CryptoJS.lib.WordArray.random(128 / 8);

        let encrypted = CryptoJS.AES.encrypt(msg, key, {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        });

        let result = CryptoJS.enc.Base64.stringify(salt.concat(iv).concat(encrypted.ciphertext));

        return result;
    }
    // encryptnew(msg: string): string {
    //     const plainText = 'This is a secret message to be encrypted';
    //     const encrypted = CryptoJS.AES.encrypt(msg, CryptoJS.enc.Utf8.parse("4512631236589784"), {
    //       iv: CryptoJS.enc.Utf8.parse("4512631236589784"),
    //       mode: CryptoJS.mode.CBC,
    //       padding: CryptoJS.pad.Pkcs7,
    //     });
     
    //     let result = encrypted.toString();
    //     return result;
    //   }

    // Declare this key and iv values in declaration
    private keynew = '0123456789ABCDEF';
    private ivnew = '1234567890ABCDEF';

        encryptnew(plainText: string): string {
          const encrypted = CryptoJS.AES.encrypt(plainText,CryptoJS.enc.Utf8.parse(this.keynew), {
            iv: CryptoJS.enc.Utf8.parse(this.ivnew),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
          });
          let result=encrypted.toString();//CryptoJS.enc.Base64.stringify((encrypted.ciphertext));

          return result;//encrypted.toString();
        }
    
     
    decrypt(ciphertextB64: any) {

        let key = CryptoJS.enc.Utf8.parse(this.SecretKey);
        let iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);

        let decrypted = CryptoJS.AES.decrypt(ciphertextB64, key, { iv: iv });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }


    encryptStringAES(value: string): any {
        // let encrypted = CryptoJS.AES.encrypt(value, this.SecretKey.trim()).toString();
        let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value), this.key, {
            keySize: 128 / 8,
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return encrypted;
    }

    decryptStringAES(textToDecrypt: any): string {
        // let decrypted = CryptoJS.AES.decrypt(textToDecrypt, this.SecretKey.trim()).toString(CryptoJS.enc.Utf8);
        let decrypted = CryptoJS.AES.decrypt(textToDecrypt, this.key, {
            keySize: 128 / 8,
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);

        return decrypted;
    }

}