import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'loading',
    // templateUrl: './loading.component.html'
    template: `<div class="loading" [hidden]="!IsLoading">
                    <div class="loader"></div>
                </div>`
})

export class LoadingComponent implements OnInit {
    @Input() IsLoading: boolean = false;
    @Input() IsFullScreen: boolean = false;

    constructor() { }

    ngOnInit() {
        if (this.IsLoading === null || this.IsLoading === undefined) {
            this.IsLoading = false;
        }

        if (this.IsFullScreen === null || this.IsFullScreen === undefined) {
            this.IsFullScreen = false;
        }
    }

}
