import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { ProcessResponse } from '../app.interface';
import { environment } from '../../environments/environment';


@Injectable()
export class ReportService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private ReportUrl: string;

  constructor(private _http: HttpClient) {
    this.ReportUrl = environment.apiURL.ReportURL;;
  }

  private handleError(error: any): Promise<any> {
    console.error('Error : ', error.message);
    return Promise.reject(error.message || error);
  }

  getMasterReportDetails(odata : any): Observable<ProcessResponse<any>> {
    //let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.ReportUrl + 'GetMasterReportDetails', odata, 
    { headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  getSLAReportDetails(odata : any): Observable<ProcessResponse<any>> {
    //let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.ReportUrl + 'MasterSLAReport', odata, 
    { headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }


  getVendorReportDetails(odata: any): Observable<ProcessResponse<any>> {
    console.log(odata)
    let Params = new HttpParams();
    Params = Params.append('VendorId', Number(odata.Vendor));
    Params = Params.append('Month', odata.Month);
    Params = Params.append('Year', odata.Year);
    let response = this._http.get<ProcessResponse<any>>(this.ReportUrl + 'GetVendorSLAReport',
      { headers: this.headers, params: Params });
    return response;
  }


  getVendor_BGVReportDetails(odata: any): Observable<ProcessResponse<any>> {
    console.log(odata)
    //let Params = new HttpParams();
    // Params = Params.append('VendorId', Number(odata.Vendor));
    // Params = Params.append('AccountID', odata.AccountId);
    // Params = Params.append('Entity',odata.Entity);
    // Params = Params.append('FromDate',odata.FromDate);
    // Params = Params.append('ToDate',odata.ToDate);
    // let response = this._http.get<ProcessResponse<any>>(this.ReportUrl + 'GetMasterReportDetails',
    //   { headers: this.headers, params: Params });
    // return response;
    return this._http.post<ProcessResponse<any>>(this.ReportUrl + 'GetMasterReportDetails', odata, 
    { headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

}
