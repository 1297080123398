import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import { isNullOrUndefined } from 'util';
import { isNullOrUndefined } from '../../shared/app.sharedservice';
import { AppSharedService } from '../../shared/app.sharedservice';

@Component({
  selector: 'session-modal',
  templateUrl: './session.component.html',
})
export class SessionModalComponent implements OnInit {

  @Input() countMinutes!: number;
  @Input() countSeconds!: number;
  @Input() progressCount!: number;
  @Input() maxCount!: number;

  @Output() close = new EventEmitter();

  public sessionpage: boolean = false;

  constructor(private sharedService: AppSharedService) { }

  ngOnInit() {

    if (isNullOrUndefined(this.maxCount)) {
      this.sessionpage = true;

    }
  }

  extendSession(bExtend: boolean) {
    this.close.emit({ extend: bExtend });
  }

}
