import { Component, ElementRef, NgZone } from '@angular/core';
import { EventTargetInterruptSource, Idle } from '@ng-idle/core';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Subscription } from 'rxjs';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { Router } from '../../node_modules/@angular/router';
import { environment } from '../environments/environment';
import { ComponentCodes, ComponentConfig, ProcessStatus, RoleCodes } from './app.interface';
import { AuthenticationService } from './core/auth/authentication.service';
import { RequestService } from './request/request.service';
import { CommonAPIService } from './shared/app.commonservice';
// import { isNullOrUndefined } from 'util';
import { AppSharedService, isNullOrUndefined } from './shared/app.sharedservice';



@Component({
  selector: 'app-main',
  templateUrl: './app-main.component.html'
})
export class AppMainComponent {
  title = 'BGV-UI';

  idleState = 'NOT_STARTED';
  timedOut = false;

  public userinfo: any = {};
  public userAccessInfo: any = {};
  public refURL: string = '';

  public displaySwitchUser: boolean = false;
  public SwitchUserAccess: boolean = false;

  public displaySessionExp: boolean = false;
  public session: any = {};
  public AppIsLoading: boolean = false;
  public languageList: Array<string> = [];
  public subscriptionList$: Subscription[] = [];

  public menuListPermission: any = [];
  public menuListProperty: any = [];
  public menuList: any = [];

  public displayNotifybox: boolean = false;
  public notify: NotificationData = new NotificationData();

  // public ImagePath: string = environment.AppBaseURL + 'assets/images/logo.png';

  public showNoDataMessage: boolean = false;
  public showNoRequestMessage: boolean = false;
  public fieldRandomId: string;

  constructor(public _appSharedService: AppSharedService, public _authService: AuthenticationService,
    public translate: TranslateService, private element: ElementRef, private idle: Idle,
    private _router: Router, private _commonAPIService: CommonAPIService, private ngZone: NgZone,
    private oauthService: OAuthService, private ReqService: RequestService) {

    this._appSharedService.UserInfo$.subscribe(() => {
      this.ngOnInit();
    })
    this.fieldRandomId = Math.random().toString(36).substr(2, 5);

  }

  ngOnInit() {
    this.refURL = environment.refURL;

    // for testing
    //   this.menuList = [
    //   {
    //     "Icon": "",
    //     "Name": "Home",
    //     "DisplayName": "Home",
    //     "SeqOrder": 1,
    //     "IsDefault": false,
    //     "ComponentCode": "",
    //     "RouteLink": "./home",
    //     "SubMenu": []
    //   },
    //   {
    //     "Icon": "",
    //     "Name": "Reports",
    //     "DisplayName": "Reports",
    //     "SeqOrder": 2,
    //     "IsDefault": false,
    //     "ComponentCode": "",
    //     "RouteLink": "./reports",
    //     "SubMenu": [
    //       {
    //         "Icon": "",
    //         "Name": "MasterReport",
    //         "DisplayName": "Master Report",
    //         "SeqOrder": 0,
    //         "IsDefault": false,
    //         "ComponentCode": "",
    //         "RouteLink": "./reports/masterreport",
    //         "SubMenu": [],
    //         "HasPermission": false
    //       },
    //       {
    //         "Icon": "",
    //         "Name": "SLAReport",
    //         "DisplayName": "Master SLA Report",
    //         "SeqOrder": 1,
    //         "IsDefault": false,
    //         "ComponentCode": "",
    //         "RouteLink": "./reports/slareport",
    //         "SubMenu": [],
    //         "HasPermission": false
    //       },
    //       {
    //         "Icon": "",
    //         "Name": "VendorSLAReport",
    //         "DisplayName": "Vendor SLA Report",
    //         "SeqOrder": 2,
    //         "IsDefault": false,
    //         "ComponentCode": "",
    //         "RouteLink": "./reports/vendorslareport",
    //         "SubMenu": [],
    //         "HasPermission": false
    //       },

    //       ]
    //   },
    //   {
    //     "Icon": "",
    //     "Name": "Admin",
    //     "DisplayName": "Admin",
    //     "SeqOrder": 3,
    //     "IsDefault": false,
    //     "ComponentCode": "",
    //     "RouteLink": "./admin",
    //     "SubMenu": [
    //       {
    //         "Icon": "",
    //         "Name": "ManageVendor",
    //         "DisplayName": "Manage Vendor",
    //         "SeqOrder": 0,
    //         "IsDefault": true,
    //         "ComponentCode": "",
    //         "RouteLink": "./admin/vendor",
    //         "SubMenu": []
    //       },
    //       {
    //         "Icon": "",
    //         "Name": "ManageCheck",
    //         "DisplayName": "Manage Check",
    //         "SeqOrder": 1,
    //         "IsDefault": false,
    //         "ComponentCode": "",
    //         "RouteLink": "./admin/stdcheck",
    //         "SubMenu": []
    //       },
    //       {
    //         "Icon": "",
    //         "Name": "CaseInitiation",
    //         "DisplayName": "Case Initiation",
    //         "SeqOrder": 1,
    //         "IsDefault": false,
    //         "ComponentCode": "",
    //         "RouteLink": "./admin/initiatecase",
    //         "SubMenu": []
    //       },
    //       {
    //         "Icon": "",
    //         "Name": "HolidayMaster",
    //         "DisplayName": "Holiday Master",
    //         "SeqOrder": 1,
    //         "IsDefault": false,
    //         "ComponentCode": "",
    //         "RouteLink": "./admin/holiday",
    //         "SubMenu": []
    //       }
    //     ]
    //   },
    //   {
    //     "Icon": "",
    //     "Name": "Support",
    //     "DisplayName": "Support",
    //     "SeqOrder": 4,
    //     "IsDefault": false,
    //     "ComponentCode": "",
    //     "RouteLink": "./support",
    //     "SubMenu": []
    //   }
    // ];

    if (this._appSharedService.IsAuthUser && !isNullOrUndefined(this._appSharedService.UserInfo)) {
      this.showApp();
    }

  }


  showApp() {
    let subscribe$_1: Subscription = this._appSharedService.UserInfo$.subscribe((data: any) => {
      this.userinfo = data;
      this.getLanguageList();

    });
    this.subscriptionList$.push(subscribe$_1);

    let subscribe$_2: Subscription = this._appSharedService.AppIsLoading$.subscribe((data: any) => {
      this.AppIsLoading = data;
    });
    this.subscriptionList$.push(subscribe$_2);

    let subscribe$_3: Subscription = this._appSharedService.ApiToken$.subscribe((data: any) => {
      this.userAccessInfo = this._appSharedService.UserAccessInfo;
    });
    this.subscriptionList$.push(subscribe$_3);

    // this._appSharedService.SelectedLanguage$.subscribe((data:any) => {
    //   this.selectedLang = data.toString();
    //   this.selectedLang = (this.languageList.filter(x => x === this.selectedLang).length > 0 ? this.selectedLang :
    //     (!isNullOrUndefined(localStorage.getItem('currentLanguage')) ? localStorage.getItem('currentLanguage'): 'en-US'));
    //   this._appSharedService.selectedLang = this.selectedLang;

    //   translate.setDefaultLang(this.selectedLang);
    //   translate.use(this.selectedLang);
    // });


    // // initialise default language
    // this.getLanguage();

    //// the lang to use, if the lang isn't available, it will use the current loader to get them
    // translate.use('en');

    this.userinfo = this._appSharedService.UserInfo;
    this.userAccessInfo = this._appSharedService.UserAccessInfo;

    this.getLanguageList();

    console.log('userinfo app init >> ');
    console.log(JSON.stringify(this.userinfo));

    // // every 25 mins
    // setInterval(() => {
    //   console.log('check for token expiry - ' + new Date());
    //   this._authService.checkTokenExpiry();
    //   // }, environment.tokenexpiry * 60 * 1000);
    //   // testing - 5 mins
    // }, 5 * 60 * 1000);

    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        // Changes here will not propagate into your view.
        this.ngZone.run(() => {
          console.log('check for token expiry - ' + new Date());
          this._authService.checkTokenExpiry();
        });
        //   // testing - 5 mins
        // }, 5 * 60 * 1000);
      }, environment.tokenexpiry * 60 * 1000);

    });

    this.setSessionExpiry();

    let userid: number = 0;

    // Note: Special Handling is done in order to login contract employees who will 
    // have alphanumeric in their EmployeeNumber field from Ping SSO
    if (environment.AppInstance == 'INTERNAL') {
      // userid = this.removeString(this._appSharedService.UserInfo.employeeNumber);
      userid = this.removeString(this._appSharedService.UserAccessInfo.actinguserid);
    }
    else {
      userid = this._appSharedService.UserInfo.userId;
    }

    // this.getUserRoleList(this._appSharedService.UserInfo.actinguserid);
    // this.getUserRoleList(this._appSharedService.UserInfo.employeeNumber);
    this.getUserRoleList(userid);

  }

  removeString(value: string) {
    var numsStr = value.replace(/[^0-9]/g, '');
    return parseInt(numsStr);
  }

  openSwitchUser(): void {
    this.displaySwitchUser = true;
  }

  closeSwitchUser(reLoad?: any): void {
    if (!isNullOrUndefined(reLoad) && reLoad.reLoad === true) {
      this.getUserRoleList(this._appSharedService.UserAccessInfo.actinguserid);
    }

    this.displaySwitchUser = false;
  }

  openSessionPopup(): void {
    this.displaySessionExp = true;
  }

  closeSessionPopup(event: any): void {
    this.displaySessionExp = false;

    if (event.extend) {
      this.resetSession(true);
    }
    else {
      this.endSession();
    }
  }

  setSessionExpiry(): void {
    // sets an idle timeout of 15 minutes.
    this.idle.setIdle(environment.sessionexpiry * 60);

    // sets a timeout period of 5 minutes.
    this.idle.setTimeout(environment.sessionexpirywarning * 60);

    // // for testing
    // this.idle.setIdle(1 * 60);
    // this.idle.setTimeout(.5 * 60);

    // sets the interrupts like Keydown, scroll, mouse wheel, mouse down, and etc
    this.idle.setInterrupts([
      new EventTargetInterruptSource(
        this.element.nativeElement, 'keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll')]);
    // this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'NO_LONGER_IDLE';
      console.log('session no longer idle');
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'TIMED_OUT';
      this.timedOut = true;
      console.log('session timeout');

      this.idle.onIdleStart.unsubscribe();
      this.closeSessionPopup({ extend: false });
    });

    this.idle.onIdleStart.subscribe(() => {
      this.session = { count: 1 };
      this.idleState = 'IDLE_START', this.openSessionPopup();
      console.log('session idle start');
    });

    this.idle.onTimeoutWarning.subscribe((countdown: any) => {
      this.idleState = 'IDLE_TIME_IN_PROGRESS';
      this.session = {
        count: (Math.floor((countdown - 1) / 60) + 1),
        progressCount: this.reverseNumber(countdown),
        countMinutes: (Math.floor(countdown / 60)),
        countSeconds: countdown % 60,
        maxCount: this.idle.getTimeout()
      };

      console.log('session idle in-progress');
    });

    this.resetSession();
  }

  // ngOnDestroy() {
  //   //this.endSession();
  // }

  reverseNumber(countdown: number) {
    // return (5*60 - (countdown - 1));
    return (this.idle.getTimeout() - (countdown - 1));
  }

  resetSession(withsave?: boolean) {
    console.log('session reset');

    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;

    if (withsave) {
      let curcomp = this._appSharedService.getCurrentComponent();
      if (!isNullOrUndefined(curcomp)) {
        // let element = document.getElementsByTagName(curcomp['Component']);
        let compref: any = curcomp['ComponentReference'];
        compref.saveClick();
      }
    }
  }

  endSession() {
    console.log('session end');

    this.idle.stop();
    this.idle.onIdleStart.unsubscribe();
    this.idle.onTimeoutWarning.unsubscribe();
    this.idle.onIdleEnd.unsubscribe();
    this.idle.onIdleEnd.unsubscribe();

    // window.close();
    this._router.navigate(['sessionexpired']);

  }

  getLanguageList(): void {
    let subscribe$: Subscription = this._commonAPIService.getLanguage().subscribe((result: any) => {
      if (!isNullOrUndefined(result) && result.Status === 200) {

        this._appSharedService.LanguageDetail = result.Data;

        result.Data.forEach((element: any) => {
          if (this.languageList.filter(x => x === element.Code).length === 0) {
            this.languageList.push(element.Code);
          }
        });
        this.translate.addLangs(this.languageList);
        // //// this language will be used as a fallback when a translation isn't found in the current language
        // if (!isNullOrUndefined(this.selectedLang)) {
        //   this.translate.setDefaultLang(this.selectedLang);
        //   // translate.use(this.lang.match(/en-US|en-IN|de|fr/) ? this.lang : 'en-US');
        //   // this.selectedLang = (this.selectedLang.match(/en-US/) ? this.selectedLang : 'en-US');
        // }
        // else {
        //   this.setDefaultLanguage();
        // }

        // this.translate.use(this.selectedLang);

        let lang = (!isNullOrUndefined(this._appSharedService.urlLang)) ?
          this._appSharedService.urlLang : this._appSharedService.selectedLang;

        this.setLanguage(lang);

      }
      else {
        this.setLanguage('');
      }
    },
      (err: any) => {
        this.setLanguage('');
        console.log('Error on getting language list.');
      });
    this.subscriptionList$.push(subscribe$);
  }


  // setDefaultLanguage(): void {
  //   if (!isNullOrUndefined(localStorage.getItem('currentLanguage'))) {
  //     this.selectedLang = localStorage.getItem('currentLanguage');
  //     this.translate.use(this.selectedLang);
  //   }
  //   else {
  //     this.translate.setDefaultLang('en-US');
  //     let browserLang = this.translate.getBrowserLang();
  //     // let browserLang = window.navigator.language;
  //     // translate.use(browserLang.match(/en-US|en-IN|de|fr/) ? browserLang : 'en-US');
  //     this.selectedLang = (browserLang.match(/en-US/) ? browserLang : 'en-US');
  //   }
  // }

  setLanguage(langCode: string): void {

    // First Preference:  lang given in URL
    // Second Preference: lang stored in localStorage
    // Third Preference:  lang based on user location (received in user token)
    // Fourth Preference: en-US

    let lang: any = langCode;

    lang = (!isNullOrUndefined(this.languageList) && this.languageList.filter(x => x === lang).length > 0) ? lang : '';

    if (isNullOrUndefined(lang) || lang.length === 0) {
      if (!isNullOrUndefined(localStorage.getItem('currentLanguage'))) {
        lang = localStorage.getItem('currentLanguage');
      }
      else {
        if (!isNullOrUndefined(this._appSharedService.UserInfo)
          && !isNullOrUndefined(this._appSharedService.UserInfo.language)) {
          lang = this._appSharedService.UserInfo.language;
        }
      }
    }

    // Still nothing is set, then set default as 'en-US'
    if (lang.length === 0) { lang = 'en-US'; }

    // at this stage, language is always set
    this._appSharedService.selectedLang = lang;

    this.translate.use(lang);
    this.translate.setDefaultLang(lang);

    // save language to localStorage
    localStorage.setItem('currentLanguage', lang);

    // finally set the date format for the selected language
    if (this._appSharedService.LanguageDetail.filter((x: any) => x.Code === lang).length > 0) {
      this._appSharedService.AppDateTimeFormat = this._appSharedService.LanguageDetail.filter((x: any) => x.Code === lang)[0].UserDef1;
      this._appSharedService.AppDateFormat = this._appSharedService.AppDateTimeFormat.substr(0,
        this._appSharedService.AppDateTimeFormat.indexOf(' '));
    }

    // Custom property added in the translate service
    // this.translate['AppDateTimeFormat'] = this._appSharedService.AppDateTimeFormat;
    // this.translate['AppDateFormat'] = this._appSharedService.AppDateFormat;
    this.translate = Object.assign(this.translate, { 'AppDateTimeFormat': this._appSharedService.AppDateTimeFormat });
    this.translate = Object.assign(this.translate, { 'AppDateFormat': this._appSharedService.AppDateFormat });
  }

  onLanguageClick(lang: any): void {
    // this._appSharedService.SelectedLanguage$.next(lang);
    // localStorage.setItem('currentLanguage', lang);
    this.setLanguage(lang);
    window.location.reload();
  }

  getAppTemplate(): void {
    this.AppIsLoading = true;
    let odata: ComponentConfig = {
      Component: 'AppComponent', ComponentCode: ComponentCodes.AppComponent
    };
    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {


        this.menuList = res.Data.FieldTemplate;
        console.log('Tab List Template >> ' + JSON.stringify(this.menuList));
        this.menuListPermission = res.Data.Permission;

        //         [
        //     "MenuList_Home_Permission",
        //     "MenuList_Reports_Permission",
        //     "MenuList_Search_Permission",
        //     "MenuList_MasterReport_Permission",
        //     "MenuList_SLAReport_Permission",
        //     "MenuList_VendorSLAReport_Permission"
        // ]


        //res.Data.Permission;



        console.log(this.menuListPermission);
        this.menuListProperty = res.Data.Properties;

        this.menuList = (this.menuListProperty.filter((x: { Name: string; }) => x.Name.toLowerCase() === 'MenuList'.toLowerCase()).length > 0 ?
          JSON.parse(this.menuListProperty.filter((x: { Name: string; }) => x.Name.toLowerCase() === 'MenuList'.toLowerCase())[0].JSONData) : []);
        console.log("++++++++++++++++++++++++")
        console.log(this.menuList)
        this.menuList.forEach((item: any) => {
          // let menuitem = this.menuList.filter((x: any) => x[this.menuListPermission['MenuList_' + item.Name + '_Permission']]);
          item.HasPermission = false;
          let peritem = this.menuListPermission.indexOf('MenuList_' + item.Name + '_Permission');
          if (peritem >= 0) {
            item.HasPermission = true;
          }
          if (item.SubMenu.length > 0) {
            // this.menuList.forEach((submenuitem: any) => {
            item.SubMenu.forEach((submenuitem: any) => {
              submenuitem.HasPermission = false;
              let subperitem = this.menuListPermission.indexOf('MenuList_' + submenuitem.Name + '_Permission');
              if (subperitem >= 0) {
                submenuitem.HasPermission = true;
              }
            });
          }
        });
      }
      this.AppIsLoading = false;
    },
      err => {
        this.AppIsLoading = false;
        console.log('Error on getHomeTemplate.');
      });
    this.subscriptionList$.push(subscribe$);
  }

  onRouteClick(parenttab: any) {
    this._appSharedService.containerData.FilterKey = '';
    if (parenttab.DisplayName == 'Dashboard') {
    }
    else {
    }
  }

  getSelectedSubTabName(tab: any): string {
    let str = '';
    let subtab = tab.SubMenu.filter((x: any) => x.Active === true);
    if (tab.Active === true && subtab.length > 0) {
      str = subtab[0].DisplayName;
    }
    return str;
  }


  menuTabClick(event: any) {

  }


  getUserRoleList(empId: number): void {
    this.AppIsLoading = true;
    this.showNoDataMessage = false;
    this.showNoRequestMessage = false;

    let subscribe$: Subscription = this._commonAPIService.getUserRole(empId).subscribe(async (result: any) => {

      if (result.Data === null && result.Status === ProcessStatus.Success) {
        this.AppIsLoading = false;
        this.showNoDataMessage = true;
      }

      else if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        this._appSharedService.UserRoleList = result.Data;

        // this._appSharedService.UserRoleList = [];

        if (!isNullOrUndefined(this._appSharedService.UserRoleList) && this._appSharedService.UserRoleList.length > 0) {
          this._appSharedService.currentRoleData = result.Data[0];
          this._appSharedService.currentRoleData$.next(this._appSharedService.currentRoleData);


          // In case of Candidate role, we have to direct to case page 
         // if ( this._appSharedService.currentRoleData.RoleId == RoleCodes.Candidate)
          // {
          //   // Check for any Case Id
          //   let ReqList = await this.getCandidateEmployeeCaseInfo(empId, this._appSharedService.currentRoleData.RoleId);

          //   this.AppIsLoading = false;

          //   if (!isNullOrUndefined(ReqList) && ReqList.length > 0 && ReqList[0].RequestId > 0) {

          //     this.getAppTemplate();
          //     this._router.navigate(['app/home/search/case', ReqList[0].RequestId]);

          //   }
          //   else {
          //     this.showNoRequestMessage = true;
          //   }
          // }
          // Other roles
          // else {
             this.getAppTemplate();
          // }

        }
        else {
          this.AppIsLoading = false;

          this.showNoDataMessage = true;

          // this.notify = {
          //   info: {
          //     header: 'Confirmation Alert',
          //     message: 'Access denied for this application as your login is not mapped to any role.',
          //     yes: '',
          //     no: '',

          //     IsHeaderHidden: true,
          //     HeaderIcon: 'fa fa-check-circle text-error',
          //     IsCrossEnable: true,
          //     SizeClass: 'modal-sm'
          //   },

          //   action: 'AccessDenied',
          //   item: null
          // };

          // this.displayNotifybox = true;
        }

      }
      else {
        this.AppIsLoading = false;

        this.showNoDataMessage = true;
      }

      // this.AppIsLoading = false;
    },
      (err: any) => {
        this.setLanguage('');
        console.log('Error on getting User Role list.');
        this.AppIsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  roleChange(roleData: any): void {
    this._appSharedService.currentRoleData = roleData;
    this._appSharedService.currentRoleData$.next(this._appSharedService.currentRoleData);
    this.getAppTemplate();

  }

  closeNotifybox(): void {
    this.displayNotifybox = false;
  }

  async getCandidateEmployeeCaseInfo(refId: number, roleCode: number) {
    let reqList: any[] = [];
    let odata = {};

    if (roleCode == RoleCodes.Candidate) {
      odata = { 'Select': 'CandidateId', 'Value': refId };
    }

    if (roleCode == RoleCodes.Employee) {
      odata = { 'Select': 'EmployeeId', 'Value': refId };
    }

    await this.ReqService.getRequestDetailsBySearch(odata)
      .then((result: any) => {
        if (!isNullOrUndefined(result) && result.Status === 200) {
          if (!isNullOrUndefined(result.Data) && result.Data.length > 0) {
            reqList = result.Data;
          }
        }
      });

    return reqList;

  }
}



