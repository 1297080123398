
import { escapeRegExp } from '@angular/compiler/src/util';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { BGVStatus, ComponentCodes, ComponentConfig, ProcessStatus, RoleCodes } from 'src/app/app.interface';
import { CommonAPIService } from 'src/app/shared/app.commonservice';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined } from 'src/app/shared/app.sharedservice';
import { GridComponent } from 'src/modules/grid/grid.component';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { FieldTemplate ,FormProperty} from 'src/modules/shared/_classes/utility.interface';
import { RequestService } from '../request.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'central-hr',
    templateUrl: './central-hr.component.html',

})
export class CentralHRComponent implements OnInit {

    @Input() RequestId: number = 0;
    @Input() SelectedTabName: string = '';
    @Input() RequestData: any;

    @Output() RefreshRequest = new EventEmitter<any>();
    @ViewChild('additionalsectiongrid', { static: false }) additionalsectiongrid!: GridComponent;
    public CentralHRTemplate: FieldTemplate[] = [];
    public SubmitDetailsTemplate: FieldTemplate[] = [];
    public CentralHRPermission: any = [];
    public CentralHRProperties: any = [];
    public CentralHRData: any[] = [];
    public CentralHRSectionData: any[] = [];
    public CentralHRDataSummary: any[] = [];
    public subscriptionList$: Subscription[] = [];
    public openCentralHRSection: boolean = false;
    public CentralHRAdditionalSectionData: any[] = [];
    public actionCode: string = '';
    public sectionName: string = '';
    public sectionDisplayName: string = '';
    public DisplayAttachment: boolean = false;
    public transId: number = 0;
    public notify: NotificationData = new NotificationData();
    public sectionData: any[] = []
    public GridProp: any = {
        Editable: true,
        IsSortable: false
    };
    public AttachPerm: any = {
        Upload: true,
        Download: false
    };
    public IsLoading: boolean = false;
    public IsReadonly: boolean = false;
    public displayNotifybox: boolean = false;

  //changes for new screen

  public CentralHRFormData: any = {};
  public GlobalConfig: any = {};
  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };
  public SubmittedDetailsList: any[] = [];  

    constructor(private eleRef: ElementRef, private _requestService: RequestService, private _commonAPIService: CommonAPIService
        , private _appSharedService: AppSharedService) {
        let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
            this.ngOnInit();
        });
        this.subscriptionList$.push(subscribe$);
    }

    ngOnInit(): void {
        if (!isNullOrUndefined(this._appSharedService.currentRoleData)) {
            if (this.RequestData.IsStopCaseSubmitted === true || this.RequestData.IsStopCase === true) {
                this.GridProp.Editable = false;
                this.IsReadonly = true;
            }
            this.getCentralHRTemplate();
           
        }

    }
    public FormProp: FormProperty = {
        'ColSize': 1,
        'CaptionTop': true,
        'CaptionBold': true,  
        'IsEditable': false,
        'ShowControlAlways': true
      };

      public SubmitFormProp: FormProperty = {
        'ColSize': 2,
        'CaptionBold': false,
        'CaptionTop': true,
        'IsEditable': true,
        'ShowControlAlways': true,
        
      };
      public CommentFormProp: FormProperty={
        'ColSize': 2,
        'CaptionBold': false,
        'CaptionTop': false,
        'IsEditable': true,
        'ShowControlAlways': true,
      };

    getCentralHRTemplate(): void {

        this.IsLoading = true;
        let odata: ComponentConfig = {
            Component: 'CentralHR', ComponentCode: ComponentCodes.CentralHR
        };

        let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
            if (res !== undefined && res !== null && res.Status === 200) {
                this.CentralHRTemplate = res.Data.FieldTemplate!;
                this.CentralHRPermission = res.Data.Permission;
                this.setCompProperties(res.Data.Properties!);
                this.SubmitDetailsTemplate=this.CentralHRTemplate.filter(x=>x.Group==='CentralHRSubmit_Template');
                console.log(res.Data.Properties!);
                console.log('Template',this.CentralHRTemplate)
                this.getCheckDocumentDetailsList();
                this.getApprovalDetailsForCHRAndBGVHead();
                this.getSubmittedDetails();

            }
        },
            err => {
                this.IsLoading = false;
                console.log('Error on getCentralHRTemplate');
            });
        this.subscriptionList$.push(subscribe$);
    }
    setCompProperties(Properties: any[]) {
        if (!isNullOrUndefined(Properties) && Properties.length > 0) {

            // 2) Blocking Cols based on custom values
            this.CentralHRProperties.BlockCols = (Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase()).length > 0 ?
                JSON.parse(Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase())[0].JSONData.toString()) : []);

            // Other component specific props
            Properties.forEach(prop => {
                // avoiding generic props
                if (!isNullOrUndefined(prop.Name) &&
                    prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
                    && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
                    this.CentralHRProperties[prop.Name] = JSON.parse(prop.JSONData.toString());
                }
            });
        }
    }
    getCheckDocumentDetailsList() {
        // this.IsLoading = true;

        let odata: object = { 'requestId': this.RequestId, 'checkId': 0 };

        let subscribe$: Subscription = this._requestService.getCheckDocumentDetailsList(odata).subscribe(result => {
            if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
                this.CentralHRData = result.Data; 
                this.setActionButtonPermissions();
                this.enableDisableButtons();
            }
            
        },
            err => {
                console.log('Error on getting getCheckDocumentDetailsList.');
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }
    getCentralHRadditionalSectionList() {
        // this.IsLoading = true;

        let odata: object = { 'requestId': this.RequestId, 'checkId': null };

        let subscribe$: Subscription = this._requestService.getNonGreenadditionalSectionList(odata).subscribe(result => {
            if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
                this.CentralHRAdditionalSectionData = result.Data;
              
            }
            this.IsLoading = false;
        },
            err => {
                console.log('Error on getting getCentralHRadditionalSectionList.');
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }

    setActionButtonPermissions(): void {
        if (!isNullOrUndefined(this.CentralHRProperties.ActionButtons) && this.CentralHRProperties.ActionButtons.length > 0
           ) {
            console.log(this.CentralHRProperties.ActionButtons);
            this.CentralHRProperties.ActionButtons.forEach((item: any) => {

                if (!isNullOrUndefined(item.Name) && item.Name !== '') {
                    item.HasPermission = false;
                    item.IsApplicable = false;
                    let peritem = this.CentralHRPermission.indexOf(item.Name);
                    if (peritem >= 0) {
                        item.HasPermission = true;
                    }
                    switch (item.Name.toUpperCase()) {
                         
                        case 'ACCEPTEDCENTRALHR':
 
                            item.IsApplicable = true;
                            break;
                        case 'REJECTEDCENTRALHR': 
                            item.IsApplicable = true;
                            break;


                    }
                }
            });
        }
    }


     
    SubmitCentralHR(modifieditems: any,actionCode:string, msg: string) {
        // if (this.validateMandatoryFields(modifieditems)) {
        modifieditems.forEach((item: any) => {
            item.ActionCode = actionCode; 
            item.CentralHRComment = this.CentralHRFormData.CentralHRComment;
        });
        this.IsLoading = true;
        let subscribe$: Subscription = this._requestService.SubmitBGVHeadandCentralHR(modifieditems).subscribe(result => {
            if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

                // this.showNotificationMessage(msg, true);
                this.showNotificationMessage(msg, 1, '', null, '', '');
                this.getCheckDocumentDetailsList();

                this.refreshRequest();

            }
            // this.IsLoading = false;
        },

            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
        // }
    }

    showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string) {
        let headerIcon: string = '';

        if (successFlag === 1) {
            headerIcon = 'fa fa-check-circle text-success';
        } else if (successFlag === 2) {
            headerIcon = 'fa fa-exclamation-circle text-warning';
        } else if (successFlag === 3) {
            headerIcon = 'fa fa-exclamation-triangle text-danger';
        } else {
            headerIcon = 'fa fa-info-circle text-info';
        }

        this.notify = {
            info: {
                header: 'Confirmation Alert',
                message: msg,
                yes: yes,
                no: no,

                IsHeaderHidden: true,
                HeaderIcon: headerIcon,
                IsCrossEnable: true,
                SizeClass: 'modal-sm'
            },

            action: action,
            item: item
        };

        this.displayNotifybox = true;
    }

    refreshRequest(): void {
        this.RefreshRequest.emit({ 'requestId': this.RequestId });
    }

    validateMandatoryFields(modifieditems: any[]): boolean {
        let valid: boolean = true;
        if (!isNullOrUndefined(this.CentralHRFormData.CentralHRComment) && this.CentralHRFormData.CentralHRComment === '') {
           
            this.showNotificationMessage('Please provide Central HR comments.', 2, '', null, '', '');
            valid = false;
        }

        // if (isNullOrUndefined(modifieditems) || modifieditems.length === 0) {
        //     // this.showNotificationMessage('No rows are modified.', false);
        //     this.showNotificationMessage('No rows are modified.', 2, '', null, '', '');

        //     valid = false;
        // }

        // if (!isNullOrUndefined(modifieditems) && modifieditems.length > 0) {
        //     let statusList = modifieditems.filter(x => ((isNullOrUndefined(x.CentralHRStatusCode)) || x.CentralHRStatusCode === '') && (!isNullOrUndefined(x.CentralHRComment) && x.CentralHRComment !== ''));

        //     if (!isNullOrUndefined(statusList) && statusList.length > 0) {
        //         // this.showNotificationMessage('Please select non green status for modified rows.', false);
        //         this.showNotificationMessage('Please select Central HR status for modified rows.', 2, '', null, '', '');
        //         valid = false;
        //     }
        // if (!isNullOrUndefined(modifieditems) && modifieditems.length > 0) {
        //     modifieditems.forEach((item: any) => {
        //         item.CentralHRComment=this.CentralHRFormData.CentralHRComment;
        //         //filter((x: any) => x.BGVHeadComment === 1);
        //     });

        //     //Comments Check
        //     let emptyComments = modifieditems.filter(x => ((isNullOrUndefined(x.CentralHRComment)) || x.CentralHRComment === '') && !isNullOrUndefined(x.CentralHRStatusCode) && x.CentralHRStatusCode !== '');

        //     if (!isNullOrUndefined(emptyComments) && emptyComments.length > 0 && valid) {
        //         // this.showNotificationMessage('Please provide comments for modified rows.', false);
        //         this.showNotificationMessage('Please provide Central HR comments for modified rows.', 2, '', null, '', '');
        //         valid = false;
        //     }
        // }

        return valid;
    }

    deleteAttachment(FileData: any) {

        this.IsLoading = true;
        let deleteData: any[] = [];

        FileData.forEach((item: any) => {
            let odata: object = {
                'Module': 'SubCheck',
                'Reference1': Number(this.RequestId),
                'Reference2': 0,
                'Reference3': 0,
                'Reference4': 0,
                'TransactionId': item.FileReferenceId,
                'FilePath': '',
                'FileName': item.FileName,
                'UserId': this._appSharedService.UserInfo.actinguserid
            };
            deleteData.push(odata);
        });
        let subscribe$: Subscription = this._requestService.deleteAttachment(deleteData).subscribe(result => {
            if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

                this.sectionData.forEach(item => {
                    item.DocumentList = null;
                    item.DocumentListDesc = null;
                    item.FileReferenceId = 0;
                    item.FileName = '';
                });
                this.sectionData = [];
            }



            this.IsLoading = false;
            this.openCentralHRSection = false;
            // this.refreshRequest();

        },

            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }

    closeAttachment(event: any): void {
        console.log(event.data);

        let selectedData = this.sectionData.filter(x => x.TransactionId === this.transId)[0];

        if (!isNullOrUndefined(event) && event.Reload === true) {
            // selectedData.FilePath = event.data[0].FilePath;
            selectedData.FileReferenceId = event.data[0].TransactionId;
            selectedData.FileName = event.data[0].FileName;
        }

        this.DisplayAttachment = false;
    }

    enableDisableButtons(): void {
        if (!isNullOrUndefined(this.CentralHRProperties.ActionButtons)
            && this.CentralHRProperties.ActionButtons.length > 0
            && (this.CentralHRProperties.ActionButtons.filter((x: any) => x.Group === 'CentralHR')).length > 0) {

            

            let primaryPageButt = this.CentralHRProperties.ActionButtons.filter((x: any) =>
                x.Group === 'CentralHR');
                primaryPageButt.forEach((item: any) => {

                    if (!isNullOrUndefined(item.Name) && item.Name !== '') {
                        item.IsEnabled = false;
                        switch (item.Name.toUpperCase()) {
                            case 'ACCEPTEDCENTRALHR':
                                if (!isNullOrUndefined(this.CentralHRData)
                                    && this.CentralHRData.length > 0
                                    && this.CentralHRData.filter(x => x.IsEditable === 1 && !isNullOrUndefined(x.CheckStatusCode) 
                                    && (x.CheckStatusCode=== BGVStatus.Non_Green__Awaiting_for_Exception_Approval_1
                                    ||x.CheckStatusCode=== BGVStatus.Non_Green__Awaiting_for_Exception_Approval_2
                                    ||x.CheckStatusCode=== BGVStatus.Non_Green__Awaiting_for_Exception_Approval_3)
                                    ).length > 0
                                ) {
                                    item.IsEnabled = true;
                                    item.HasPermission=true;
                                }
                               // item.IsEnabled = true;
                                break;
                            case 'REJECTEDCENTRALHR':
                                if (!isNullOrUndefined(this.CentralHRData)
                                    && this.CentralHRData.length > 0
                                    && this.CentralHRData.filter(x => x.IsEditable === 1 && !isNullOrUndefined(x.CheckStatusCode) 
                                    && (x.CheckStatusCode=== BGVStatus.Non_Green__Awaiting_for_Exception_Approval_1
                                    ||x.CheckStatusCode=== BGVStatus.Non_Green__Awaiting_for_Exception_Approval_2
                                    ||x.CheckStatusCode=== BGVStatus.Non_Green__Awaiting_for_Exception_Approval_3)
                                    ).length > 0
                                ) {
                                    item.IsEnabled = true;
                                    item.HasPermission=true;
                                }
                                break;
                        
                        }
                    }
                });
           
        }
    }

    onClick(event: any): void {
        console.log(event);
        let selitem: any = event.item;

        if (event.fieldname.toUpperCase() === 'FILEACTION') {
            // event.icon.IconCSS
            switch (event.icon.Value) {
                case '0':
                    //Download
                    this.downloadAttachment(selitem);
                    break;
            }
        }
    }

    downloadAttachment(data: any) {
        this.IsLoading = true;

        let odata: any = {
            'Module': data.FileModule,
            'Reference1': Number(this.RequestId),
            'Reference2': data.CheckId,
            'Reference3': data.DocumentTypeId,
            'Reference4': 0,
            'TransactionId': data.FileReferenceId,
            // 'FilePath': data.FilePath,
            'FilePath': '',
            'FileName': data.FileName
        };

        let subscribe$: Subscription = this._requestService.downloadAttachment(odata).subscribe(result => {
            // if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
            //   console.log(result);
            // }
            if (!isNullOrUndefined(result)) {
                let url = window.URL.createObjectURL(result.data);
                let a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                // a.download = res.filename;
                a.download = data.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
                // this._toastr.success('Resume downloaded successfully', this.CompTitle);
                console.log('Resume downloaded successfully');

                this.notify = {
                    info: {
                        header: 'Confirmation Alert',
                        message: 'Document downloaded successfully',
                        yes: '',
                        no: '',

                        IsHeaderHidden: true,
                        HeaderIcon: 'fa fa-check-circle text-success',
                        IsCrossEnable: true,
                        SizeClass: 'modal-sm'
                    },

                    action: '',
                    item: null
                };

                this.displayNotifybox = true;
            }
            this.IsLoading = false;

        },
            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }

    onChange(event: any): void {
        let selitem: any = event.item;
        // Emitting onchange event of resource plan to parent container
        selitem.IsModified = 1;
        this.transId = 0;
        this.transId = selitem.TransactionId;
        selitem.IsRowHighlight = selitem.IsModified;

        switch (event.fieldname.toUpperCase()) {
            case 'CENTRALHRSTATUSCODE':
                if ((isNullOrUndefined(selitem.CentralHRStatusCode) || selitem.CentralHRStatusCode === '') && !isNullOrUndefined(selitem.OldCentralHRStatusCode) && selitem.OldCentralHRStatusCode !== '') {

                    // this.showNotificationMessage('Please select atleast one Non green status.', 2, '', null, '', '');

                    setTimeout(() => {
                        selitem.CentralHRStatusCode = selitem.OldCentralHRStatusCode
                        selitem.CentralHRStatusDesc = selitem.OldCentralHRStatusDesc
                        selitem.CentralHRComment = selitem.OldCentralHRComment
                        selitem.IsModified = 0;
                        selitem.IsRowHighlight = selitem.IsModified;
                    }, 50);

                }
                break;
        }

        this.enableDisableButtons();

        let eventdata = {
            fieldname: event.fieldname
        };
    }

    closeNotifybox(): void {
        this.displayNotifybox = false;
    }
    actionButtonClick(data: any) {
        let sectionitems = this.sectionData.filter(x => x.IsModified === 1);

        if (!isNullOrUndefined(data.Name) && data.Name !== '') {
            this.actionCode = '';
            this.actionCode = data.ActionCode;
            switch (data.Name.toUpperCase()) {
                case 'SUBMITCENTRALHR':
                    let x = this.CentralHRDataSummary.filter(x => x.IsEditable === 1);
                    // if (this.validateMandatoryFields(this.CentralHRData.filter(x => x.IsEditable === 1)) && this.CentralHRData.filter(x => x.IsEditable === 1).length > 0) {
                    //     // this.SubmitCentralHR(sectionitems);
                    //     this.showNotificationMessage('Are you sure you want to submit the checks?', 1, data.Name, this.CentralHRData.filter(x => x.IsEditable === 1), 'Submit now', 'Cancel');
                    // }
                    if (this.validateMandatoryFields(this.CentralHRDataSummary.filter(x => x.IsModified === 1)) && this.CentralHRDataSummary.filter(x => x.IsModified === 1).length > 0) {
                        // this.SubmitCentralHR(sectionitems);

                        this.CentralHRData.filter(x => x.IsEditable === 1).forEach(item => {
                            item.CentralHRStatusCode = this.CentralHRDataSummary[0].CentralHRStatusCode;
                            item.CentralHRComment = this.CentralHRDataSummary[0].CentralHRComment;
                            item.IsModified = 1;
                        });

                        //let msg = this.CentralHRDataSummary.filter(x => x.IsEditable === 1 && x.IsModified === 1).length > 1 ? 'checks' : 'check';
                        //this.showNotificationMessage('Are you sure you want to submit the ' + msg + '?', 1, data.Name, this.CentralHRData.filter(x => x.IsEditable === 1 && x.IsModified === 1 && !isNullOrUndefined(x.CentralHRStatusCode) && x.CentralHRStatusCode !== ''), 'Submit now', 'Cancel');
                        this.showNotificationMessage('Are you sure you want to submit the case?', 1, data.Name, this.CentralHRData.filter(x => x.IsEditable === 1 && x.IsModified === 1 && !isNullOrUndefined(x.CentralHRStatusCode) && x.CentralHRStatusCode !== ''), 'Submit now', 'Cancel');
                    }

                    break;

                case 'ACCEPTEDCENTRALHR':
                    if (this.validateMandatoryFields(this.CentralHRData.filter(x => x.PendingWith===RoleCodes.CentralHR))) {

                    this.CentralHRData.forEach(item => {
                           
                        //item.CentralHRComment = this.CentralHRDataSummary[0].CentralHRComment;
                       
                        item.IsModified = 1;
                    });

                    //let msg = this.CentralHRDataSummary.filter(x => x.IsEditable === 1 && x.IsModified === 1).length > 1 ? 'checks' : 'check';
                    //this.showNotificationMessage('Are you sure you want to submit the ' + msg + '?', 1, data.Name, this.CentralHRData.filter(x => x.IsEditable === 1 && x.IsModified === 1 && !isNullOrUndefined(x.CentralHRStatusCode) && x.CentralHRStatusCode !== ''), 'Submit now', 'Cancel');
                    this.showNotificationMessage('Are you sure you want to accept the case?', 1, data.Name, this.CentralHRData.filter(x => x.PendingWith==RoleCodes.CentralHR), 'Submit now', 'Cancel');
                    }
                    break;
                case 'REJECTEDCENTRALHR':
                    if (this.validateMandatoryFields(this.CentralHRData.filter(x => x.PendingWith===RoleCodes.CentralHR))) {

                    this.CentralHRData.forEach(item => {
                           
                        //item.CentralHRComment = this.CentralHRDataSummary[0].CentralHRComment;
                       
                        item.IsModified = 1;
                    });

                    //let msg = this.CentralHRDataSummary.filter(x => x.IsEditable === 1 && x.IsModified === 1).length > 1 ? 'checks' : 'check';
                    //this.showNotificationMessage('Are you sure you want to submit the ' + msg + '?', 1, data.Name, this.CentralHRData.filter(x => x.IsEditable === 1 && x.IsModified === 1 && !isNullOrUndefined(x.CentralHRStatusCode) && x.CentralHRStatusCode !== ''), 'Submit now', 'Cancel');
                    this.showNotificationMessage('Are you sure you want to reject the case?', 1, data.Name, this.CentralHRData.filter(x => x.PendingWith==RoleCodes.CentralHR), 'Submit now', 'Cancel');
                     }
                    break;
            }
        }

    }

    setNotifyRes(event: any): void {
        console.log('notify res SUBMITCENTRALHR>> ' + JSON.stringify(event));
        let canClose: boolean = true;
        switch (event.action.toUpperCase()) {
            // case 'SUBMITCENTRALHR':
            //     if (event.result.toUpperCase() === 'SUBMIT NOW') {
            //         this.SubmitCentralHR(event.item, 'Status is submitted sucessfully.');
            //     }
            //     break;
            case 'ACCEPTEDCENTRALHR':
                if (event.result.toUpperCase() === 'SUBMIT NOW') {
                    
                    this.actionCode='AC019'
                    
                    //this.SubmitCentralHR(this.SubmittedDetailsList,this.actionCode, 'Status is submitted sucessfully.');
                    this.SubmitCentralHR(event.item,this.actionCode, 'Status is submitted sucessfully.');
                }
                break;
            case 'REJECTEDCENTRALHR':
                    if (event.result.toUpperCase() === 'SUBMIT NOW') {
                        this.actionCode='AC020'
                        this.SubmitCentralHR(event.item,this.actionCode, 'Status is submitted sucessfully.');
                    }
                    break;
        }
        if (canClose) {
            this.closeNotifybox();
        }
    }

    getApprovalDetailsForCHRAndBGVHead() {
    
    
    this.IsLoading = true;
    this.CentralHRFormData = '';

    let subscribe$: Subscription = this._requestService.GetBasicDetailsBGVHeadAndCHR(this.RequestId).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
      this.CentralHRFormData=result.Data;     
      
       
      }
      
      this.IsLoading = false;

    },
      err => {
        console.log('Error on getting GetRequestData.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
}
getSubmittedDetails() {
    
    
    this.IsLoading = true;
    this.SubmittedDetailsList = [];

    let subscribe$: Subscription = this._requestService.GetSubmittedDetailsCHRAndBGVHead(this.RequestId).subscribe(result => {
    if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
      this.SubmittedDetailsList=result.Data;
    //   this.SubmittedDetailsList.forEach((item: any) => {
    //     item.IsEditable =  1 ;
    //     item.IsModified = 0;
    //   });     
    }
    if (result ===null) {
        this.SubmittedDetailsList=[];
       
    } 
      
      this.IsLoading = false;

    },
      err => {
        console.log('Error on getting GetRequestData.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
}
//GetApprovalDetailsForCHRAndBGVHead
//GetNGSubmittedDetails






}
