import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Subscription } from 'rxjs/internal/Subscription';
import { asEnumerable } from 'linq-es2015';

import { isNullOrUndefined, UtilityService } from '../shared/_services/utility.service';
import { GridConfig, GridProperty } from '../shared/_classes/utility.interface';
import { ProcessResponse } from 'src/app/app.interface';
import { TileService } from './tile.service';
import { TileItem } from './tile.interface';

@Component({
    selector: 'tile',
    templateUrl: './tile.component.html',
    // changeDetection: ChangeDetectionStrategy.OnPush
    changeDetection: ChangeDetectionStrategy.Default
})

export class TileComponent implements OnInit {

    @Input() TileTemplate!: TileItem<any>;

    @Input() GlobalConfig: any = {};
    @Input() GridConfig: GridConfig = {};
    @Input() GridProp: GridProperty = {};

    @Output() deleteTile = new EventEmitter<any>();
    @Output() resizeTile = new EventEmitter<any>();
    @Output() fullscreen = new EventEmitter<any>();

    public tileDataALL: any = [];

    public chartWidth: number = 0;
    public chartHeight: number = 0;
    public chart!: am4charts.PieChart;

    public subscriptionList$: Subscription[] = [];
    
    public DispFilter: boolean = false;
    

    constructor(private translate: TranslateService, private _tileServices: TileService,
        private _elioUtilityService: UtilityService, private zone: NgZone) {

        // alert('inside const');
    }

    ngOnInit(): void {
        // this.generateCharts();
        console.log(this.TileTemplate);
        // this.tileDataALL = this.tileConfig.TileData;
        this.getTileData();
    }

    getTileData(): void {
        if (!isNullOrUndefined(this.TileTemplate.DataSourceURL)) {

            let url: any = this.TileTemplate.DataSourceURL;

            // if source url present, then get it from service
            if (!isNullOrUndefined(this.TileTemplate.DataSourceURL)) {

                // if (!isNullOrUndefined(this.tileConfig.DataSourceURL?.DataSourceURLAttribute) &&
                //     this.tileConfig.DataSourceURL!.DataSourceURLAttribute!.length > 0) {

                // url = this._elioUtilityService.ParsingURL(
                //     url,
                //     this.tileConfig.DataSourceURL?.DataSourceURLAttribute!,
                //     this.tileConfig.Name,
                // );

                this.TileTemplate.IsLoading = true;

                let odata: any = {
                    Component: 'DashBoard', SubComponent: 'DashBoard'
                };
                
                let subscribe$: Subscription = this._tileServices.GetTileData(odata, url).subscribe((res: ProcessResponse<any>) => {
                    if (res !== undefined && res !== null && res.Status === 200) {
                        this.TileTemplate.TileData = res.Data;
                        this.tileDataALL = this.TileTemplate.TileData;
                    }

                    this.TileTemplate.IsLoading = false;
                },
                    err => {
                        this.TileTemplate.IsLoading = false;
                        console.log('Error on getDashBoardTemplate.');
                    });

                    this.subscriptionList$.push(subscribe$);

                // }

            }
        }

    }

    ngAfterViewInit() {
        if (this.TileTemplate.TileType === "Chart") {
            if (this.TileTemplate.TileObject.ChartType === "Pie") {
                if (this.TileTemplate.TileObject.ChartSubType === "DonutChart" || this.TileTemplate.TileObject.ChartSubType === "SimpleChart" || this.TileTemplate.TileObject.ChartSubType === "SemiCircleChart") {
                    this.zone.runOutsideAngular(() => {
                        this.chartWidth = 100;
                        this.chartHeight = 300;
                        let chart = am4core.create("chartdiv-" + this.TileTemplate.Name + "-", am4charts.PieChart);
                        chart.hiddenState.properties.opacity = 0;

                        chart.data = this.TileTemplate.TileData!;
                        chart.data = [{
                            "requestStatus": "With in SLA",
                            "requestCount": 700,
                            "color": am4core.color("#168C3B")
                        }, {
                            "requestStatus": "Nearing SLA",
                            "requestCount": 300,
                            "color": am4core.color("#FE8F13")
                        }, {
                            "requestStatus": "Overdue",
                            "requestCount": 150,
                            "color": am4core.color("#E20121")
                        }];

                        let pieSeries = chart.series.push(new am4charts.PieSeries());
                        pieSeries.dataFields.value = "requestCount"; //this.tileConfig.TileObject.DataFieldName;
                        pieSeries.dataFields.category = "requestStatus"; //this.tileConfig.TileObject.CountFieldName;
                        pieSeries.labels.template.disabled = false; //this.tileConfig.TileObject.IsChartLabelDisabled;
                        if (this.TileTemplate.TileObject.ChartSubType === "SemiCircleChart") {
                            chart.radius = am4core.percent(70);
                            chart.startAngle = 180;
                            chart.endAngle = 360;
                            pieSeries.slices.template.cornerRadius = 10;
                            pieSeries.slices.template.innerCornerRadius = 7;
                            pieSeries.slices.template.draggable = true;
                            pieSeries.slices.template.inert = true;
                            pieSeries.alignLabels = false;

                            pieSeries.hiddenState.properties.startAngle = 90;
                            pieSeries.hiddenState.properties.endAngle = 90;
                        }


                        chart.innerRadius = this.TileTemplate.TileObject.ChartSubType === "SimpleChart" ? am4core.percent(0) : am4core.percent(40);


                        pieSeries.slices.template.stroke = am4core.color("#fff");
                        pieSeries.slices.template.strokeWidth = 1;
                        pieSeries.slices.template.strokeOpacity = 1;
                        pieSeries.slices.template.propertyFields.fill = "color";

                        chart.maxZoomCount = 3;
                        chart.minZoomCount = 0;
                        chart.legend = new am4charts.Legend();
                        chart.legend.position = "right";
                        let marker: any = chart.legend.markers.template.children.getIndex(0);
                        marker.cornerRadius(12, 12, 12, 12);
                        this.chart = chart;
                    });
                }
            }
            if (this.TileTemplate.TileObject.ChartType === "Column") {
                this.zone.runOutsideAngular(() => {
                    this.chartWidth = 100;
                    this.chartHeight = 356;
                    // Create chart instance
                    let chart = am4core.create("chartdiv-" + this.TileTemplate.Name + "-", am4charts.XYChart);

                    // Add data
                    chart.data = this.TileTemplate.TileData!;
                    chart.data = [{
                        "requestStatus": "With in SLA",
                        "requestCount": 700,
                        "color": am4core.color("#168C3B")
                    }, {
                        "requestStatus": "Nearing SLA",
                        "requestCount": 300,
                        "color": am4core.color("#FE8F13")
                    }, {
                        "requestStatus": "Overdue",
                        "requestCount": 150,
                        "color": am4core.color("#E20121")
                    }];

                    // Create axes

                    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                    categoryAxis.dataFields.category = "requestStatus";
                    categoryAxis.renderer.grid.template.location = 0;
                    categoryAxis.renderer.minGridDistance = 30;

                    categoryAxis.renderer.labels.template.adapter.add("dy", function (dy, target) {
                        if (target.dataItem && target.dataItem.index && 2 == 2) {
                            return dy ? dy : 0 + 25;
                        }
                        return dy;
                    });

                    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

                    // Create series
                    let series = chart.series.push(new am4charts.ColumnSeries());
                    series.dataFields.valueY = "requestCount";
                    series.dataFields.categoryX = "requestStatus";
                    // series.name = "requestStatus";
                    series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
                    series.columns.template.fillOpacity = .8;
                    series.columns.template.propertyFields.fill = "color";
                    series.columns.template.stroke = am4core.color("#fff");

                    chart.data.forEach((item: any) => {
                        series.name = item.requestStatus;
                    });

                    let columnTemplate = series.columns.template;
                    columnTemplate.strokeWidth = 2;
                    columnTemplate.strokeOpacity = 1;
                    //add chart cursor
                    chart.cursor = new am4charts.XYCursor();
                    // chart.scrollbarX = new am4core.Scrollbar();
                    // chart.scrollbarY = new am4core.Scrollbar();
                    chart.mouseWheelBehavior = "zoomX";

                    chart.legend = new am4charts.Legend();
                });
            }
            if (this.TileTemplate.TileObject.ChartType === "Bar") {
                this.zone.runOutsideAngular(() => {
                    this.chartWidth = 100;
                    this.chartHeight = 356;
                    let chart = am4core.create("chartdiv-" + this.TileTemplate.Name + "-", am4charts.XYChart);

                    chart.data = [{
                        "requestStatus": "With in SLA",
                        "requestCount": 700,
                        "color": am4core.color("#168C3B")
                    }, {
                        "requestStatus": "Nearing SLA",
                        "requestCount": 300,
                        "color": am4core.color("#FE8F13")
                    }, {
                        "requestStatus": "Overdue",
                        "requestCount": 150,
                        "color": am4core.color("#E20121")
                    }];

                    //create category axis for requestStatus
                    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
                    categoryAxis.dataFields.category = "requestStatus";
                    categoryAxis.renderer.inversed = true;
                    categoryAxis.renderer.grid.template.location = 0;

                    //create value axis for requestCount
                    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
                    valueAxis.renderer.opposite = true;


                    //create columns
                    let series = chart.series.push(new am4charts.ColumnSeries());
                    series.dataFields.categoryY = "requestStatus";
                    series.dataFields.valueX = "requestCount";

                    // series.name = "Request Status";
                    series.columns.template.fillOpacity = 0.8;
                    series.columns.template.strokeOpacity = 0;
                    series.tooltipText = "Count in {categoryY}: {valueX.value}";
                    series.columns.template.propertyFields.fill = "color";
                    series.columns.template.stroke = am4core.color("#fff");

                    // chart.legend = new am4charts.Legend();
                    // chart.legend.position = "bottom";
                    // let marker : any = chart.legend.markers.template.children.getIndex(0);
                    // marker.cornerRadius(12, 12, 12, 12);

                    //add chart cursor
                    chart.cursor = new am4charts.XYCursor();
                    // chart.cursor.behavior = "zoomY";

                    // chart.scrollbarX = new am4core.Scrollbar();
                    // chart.scrollbarY = new am4core.Scrollbar();
                    chart.mouseWheelBehavior = "zoomX";


                });
            }
        }
        
        if (this.TileTemplate.TileType === "CurosalTile") {
            this.TileTemplate.TileObject.forEach((item: any) => {
                if (item.ChartType === "Pie") {
                    if (item.ChartSubType === "DonutChart" || item.ChartSubType === "SimpleChart" || item.ChartSubType === "SemiCircleChart") {
                        this.zone.runOutsideAngular(() => {
                            this.chartWidth = 100;
                            this.chartHeight = 220;
                            let chart = am4core.create("curosalchartdiv-" + item.ChartName + "-", am4charts.PieChart);
                            chart.hiddenState.properties.opacity = 0;
    
                            chart.data = this.TileTemplate.TileData![item.ChartName];
                            // chart.data = [{
                            //     "requestStatus": "With in SLA",
                            //     "requestCount": 700,
                            //     "color": am4core.color("#168C3B")
                            // }, {
                            //     "requestStatus": "Nearing SLA",
                            //     "requestCount": 300,
                            //     "color": am4core.color("#FE8F13")
                            // }, {
                            //     "requestStatus": "Overdue",
                            //     "requestCount": 150,
                            //     "color": am4core.color("#E20121")
                            // }];
    
                            let pieSeries = chart.series.push(new am4charts.PieSeries());
                            pieSeries.dataFields.value = "requestCount"; //this.tileConfig.TileObject.DataFieldName;
                            pieSeries.dataFields.category = "requestStatus"; //this.tileConfig.TileObject.CountFieldName;
                            pieSeries.labels.template.disabled = false; //this.tileConfig.TileObject.IsChartLabelDisabled;
                            if (this.TileTemplate.TileObject.ChartSubType === "SemiCircleChart") {
                                chart.radius = am4core.percent(70);
                                chart.startAngle = 180;
                                chart.endAngle = 360;
                                pieSeries.slices.template.cornerRadius = 10;
                                pieSeries.slices.template.innerCornerRadius = 7;
                                pieSeries.slices.template.draggable = true;
                                pieSeries.slices.template.inert = true;
                                pieSeries.alignLabels = false;
    
                                pieSeries.hiddenState.properties.startAngle = 90;
                                pieSeries.hiddenState.properties.endAngle = 90;
                            }
    
    
                            chart.innerRadius = this.TileTemplate.TileObject.ChartSubType === "SimpleChart" ? am4core.percent(0) : am4core.percent(40);
    
    
                            pieSeries.slices.template.stroke = am4core.color("#fff");
                            pieSeries.slices.template.strokeWidth = 1;
                            pieSeries.slices.template.strokeOpacity = 1;
                            pieSeries.slices.template.propertyFields.fill = "color";
    
                            chart.maxZoomCount = 3;
                            chart.minZoomCount = 0;
                            chart.legend = new am4charts.Legend();
                            chart.legend.position = "right";
                            let marker: any = chart.legend.markers.template.children.getIndex(0);
                            marker.cornerRadius(12, 12, 12, 12);
                            this.chart = chart;
                        });
                    }
                }
                if (item.ChartType === "Column") {
                    this.zone.runOutsideAngular(() => {
                        this.chartWidth = 100;
                        this.chartHeight = 220;
                        // Create chart instance
                        let chart = am4core.create("curosalchartdiv-" + item.ChartName + "-", am4charts.XYChart);
    
                        // Add data
                        chart.data = this.TileTemplate.TileData![item.ChartName];
                        // chart.data = [{
                        //     "requestStatus": "With in SLA",
                        //     "requestCount": 700,
                        //     "color": am4core.color("#168C3B")
                        // }, {
                        //     "requestStatus": "Nearing SLA",
                        //     "requestCount": 300,
                        //     "color": am4core.color("#FE8F13")
                        // }, {
                        //     "requestStatus": "Overdue",
                        //     "requestCount": 150,
                        //     "color": am4core.color("#E20121")
                        // }];
    
                        // Create axes
    
                        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                        categoryAxis.dataFields.category = "requestStatus";
                        categoryAxis.renderer.grid.template.location = 0;
                        categoryAxis.renderer.minGridDistance = 30;
    
                        categoryAxis.renderer.labels.template.adapter.add("dy", function (dy, target) {
                            if (target.dataItem && target.dataItem.index && 2 == 2) {
                                return dy ? dy : 0 + 25;
                            }
                            return dy;
                        });
    
                        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    
                        // Create series
                        let series = chart.series.push(new am4charts.ColumnSeries());
                        series.dataFields.valueY = "requestCount";
                        series.dataFields.categoryX = "requestStatus";
                        // series.name = "requestStatus";
                        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
                        series.columns.template.fillOpacity = .8;
                        series.columns.template.propertyFields.fill = "color";
                        series.columns.template.stroke = am4core.color("#fff");
    
                        chart.data.forEach((item: any) => {
                            series.name = item.requestStatus;
                        });
    
                        let columnTemplate = series.columns.template;
                        columnTemplate.strokeWidth = 2;
                        columnTemplate.strokeOpacity = 1;
                        //add chart cursor
                        chart.cursor = new am4charts.XYCursor();
                        // chart.scrollbarX = new am4core.Scrollbar();
                        // chart.scrollbarY = new am4core.Scrollbar();
                        chart.mouseWheelBehavior = "zoomX";
    
                        // chart.legend = new am4charts.Legend();
                    });
                }
                if (item.ChartType === "Bar") {
                    this.zone.runOutsideAngular(() => {
                        this.chartWidth = 100;
                        this.chartHeight = 220;
                        let chart = am4core.create("curosalchartdiv-" + item.ChartName + "-", am4charts.XYChart);
    
                        chart.data = this.TileTemplate.TileData![item.ChartName];
                        // chart.data = [{
                        //     "requestStatus": "With in SLA",
                        //     "requestCount": 700,
                        //     "color": am4core.color("#168C3B")
                        // }, {
                        //     "requestStatus": "Nearing SLA",
                        //     "requestCount": 300,
                        //     "color": am4core.color("#FE8F13")
                        // }, {
                        //     "requestStatus": "Overdue",
                        //     "requestCount": 150,
                        //     "color": am4core.color("#E20121")
                        // }];
    
                        //create category axis for requestStatus
                        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
                        categoryAxis.dataFields.category = "requestStatus";
                        categoryAxis.renderer.inversed = true;
                        categoryAxis.renderer.grid.template.location = 0;
    
                        //create value axis for requestCount
                        let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
                        valueAxis.renderer.opposite = true;
    
    
                        //create columns
                        let series = chart.series.push(new am4charts.ColumnSeries());
                        series.dataFields.categoryY = "requestStatus";
                        series.dataFields.valueX = "requestCount";
    
                        // series.name = "Request Status";
                        series.columns.template.fillOpacity = 0.8;
                        series.columns.template.strokeOpacity = 0;
                        series.tooltipText = "Count in {categoryY}: {valueX.value}";
                        series.columns.template.propertyFields.fill = "color";
                        series.columns.template.stroke = am4core.color("#fff");
    
                        // chart.legend = new am4charts.Legend();
                        // chart.legend.position = "bottom";
                        // let marker : any = chart.legend.markers.template.children.getIndex(0);
                        // marker.cornerRadius(12, 12, 12, 12);
    
                        //add chart cursor
                        chart.cursor = new am4charts.XYCursor();
                        // chart.cursor.behavior = "zoomY";
    
                        // chart.scrollbarX = new am4core.Scrollbar();
                        // chart.scrollbarY = new am4core.Scrollbar();
                        chart.mouseWheelBehavior = "zoomX";
    
    
                    });
                }

            });
        }
    }

    ngOnDestroy() {
        if (this.TileTemplate.TileType === "Chart") {
            this.zone.runOutsideAngular(() => {
                if (this.chart) {
                    this.chart.dispose();
                }
            });
        }
        if (this.TileTemplate.TileType === "CurosalTile") {
            this.zone.runOutsideAngular(() => {
                if (this.chart) {
                    this.chart.dispose();
                }
            });
        }
    }

    refreshTile() {
        this.getTileData();
    }

    delete(item: any): void {
        this.deleteTile.emit({ 'item': item });
    }

    fullScreen(): void {
        this.fullscreen.emit({ 'item': this.TileTemplate });
    }

    resize(item: any, step: any): void {
        this.resizeTile.emit({ 'item': item, 'step': step });
    }

    showFilter(): void {
        this.DispFilter = true;
    }

    closeFilter(): void {
        this.DispFilter = false;
    }

    setFilter(event: any) {
        if (event.sCondn.length > 0) {
            this.TileTemplate.TileData = asEnumerable(this.tileDataALL)
                .Where($ => eval(event.sCondn))
                .ToArray();
        }
        else {
            this.TileTemplate.TileData = this.tileDataALL;
        }
    }

    frameTemplate(template: any, item: any): string {
        let templatestr = '';

        if (!isNullOrUndefined(template)) {
            let keys: Array<string> = [];
            let temp = template.CardTemplate;

            while (temp.indexOf('{{item.') >= 0) {
                let name = temp.substring(temp.indexOf('{{item.'), temp.indexOf('}}') + 2);
                name = name.replace('{{item.', '');
                name = name.replace('}}', '');
                keys.push(name);
                temp = temp.substr(temp.indexOf('}}') + 2);
            }

            templatestr = template.CardTemplate;
            // loop for each Key to be replaced
            for (let j = 0; j < keys.length; j++) {
                templatestr = templatestr.replace('{{item.' + keys[j] + '}}', item[keys[j]]);
                // console.log(templatestr);
            }

        }

        return templatestr;
    }

    
}
