import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppSharedService, isNullOrUndefined } from 'src/app/shared/app.sharedservice';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { AuthenticationService } from '../auth/authentication.service';
import { EncryptDecryptService } from '../auth/encrypt-decrypt.service';
import { LoginService } from './login.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {

  @Output() close = new EventEmitter();

  public emailid: string = '';
  public passwd: string = '';
  public otp: string = '';
  public showPassword: boolean = false;
  public showOTP: boolean = false;

  // public RegistryData: RegistryDetail = new RegistryDetail();
  public IsLoading: boolean = false;
  public subscriptionList$: Subscription[] = [];
  public displayNotifybox: boolean = false;
  public notify: NotificationData = new NotificationData();

  public userinfo: any;


  constructor(private loginService: LoginService, public _appSharedService: AppSharedService,
    private _authService: AuthenticationService, private _router: Router,
    private encryptDecryptService: EncryptDecryptService) { }

  ngOnInit() { }


  ResetLoginPassword(): void {

    this.emailid = (document.getElementById('txtEmailId') as HTMLInputElement).value;

    if (this.emailid.trim().length <= 0) {
      this.showNotificationMessage('', 'Please enter your Email.', 'VALID_RESET', 0);
      this.displayNotifybox = true;

      return;
    }

    let mail = this.encryptDecryptService.encryptnew(this.emailid.trim());
    let odata = { EmailId: mail };

    let subscribe$: Subscription = this.loginService.ResetLoginPassword(odata).subscribe(res => {
      if (res !== undefined && res !== null) {
        if (res.status === 200 && res.data != undefined && res.data !== null) {
          //let response = res.Data;

          if (!isNullOrUndefined(res.data) && res.data == 1) {
            this.showNotificationMessage('', 'Your password has been reset successfully and sent to your Email.', 'RESET_SUCCESS');
          }
          else {
            this.showNotificationMessage('', res.message, 'REG_FAILURE', -1);
          }

          this.displayNotifybox = true;

        }
        else {
          this.showNotificationMessage('', res.message, 'REG_FAILURE', -1);
          this.displayNotifybox = true;
        }
      }
      else {
        alert('Error on reseting the password.');
      }
      this.IsLoading = false;

    },
      err => {
        this.IsLoading = false;
        console.log('Error on reseting the password.');
      });

  }

  setNotifyRes(event: any): void {
    // console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;

    switch (event.action.toUpperCase()) {
      case 'RESET_SUCCESS':

        if (event.result.toUpperCase() === 'OK') {
          // On successful reset, remain in same login page
          this._router.navigate(['login']);
        }
        break;

      case 'RESET_FAILURE':
        // On reset failure, no redirction
        if (event.result.toUpperCase() === 'OK') {

        }
        break;

      case 'VALID_RESET':
      case 'VALID_USER_FAIL':
      case 'VALID_OTP_FAIL':
        break;
    }

    if (canClose) {
      this.closeNotifybox();
    }

  }

  closeNotifybox(): void {
    this.displayNotifybox = false;
  }

  showNotificationMessage(header: any = '', msg: any = '', action: any = '', successFlag: number = 1) {
    this.notify = {
      info: {
        header: header,
        message: msg,
        yes: 'OK',
        no: '',

        IsHeaderHidden: true,
        HeaderIcon: (successFlag === 1 ? 'fa fa-check-circle text-success' : (successFlag === 0 ? 'fa fa-exclamation-circle text-warning':'fa fa-exclamation-triangle text-danger')),
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: null
    };

    this.displayNotifybox = true;
  }



}