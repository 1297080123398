<ng-container *ngIf="RequestData.IsCreateMode">
    <nav aria-label="breadcrumb" class="breadcrumbSection">
        <div class="d-flex w-100 justify-content-between">
            <ul class="breadcrumb m-0 flex-fill">
                <!-- <li class="breadcrumb-item"><a routerLink="home">{{'Search' | translate}}</a></li>
                                <li class="breadcrumb-item active" aria-current="page">{{'View Details' | translate}}</li> -->
            </ul>
            <div class="align-self-center breadcrumb m-0">
                <div class="px-2"><span class="text-secondary me-1"><i class="fa fa-circle"
                            aria-hidden="true"></i></span>{{'Document upload pending' | translate}}</div>

                <div class="px-2"><span class="text-warning me-1"><i class="fa fa-circle"
                            aria-hidden="true"></i></span>{{'Partially uploaded' | translate}}</div>

                <div class="px-2"><span class="text-success me-1"><i class="fa fa-circle"
                            aria-hidden="true"></i></span>{{'Documents uploaded' | translate}}</div>
            </div>
        </div>
    </nav>
</ng-container>
<div class="h-100">
    <ng-container *ngIf="RequestData.IsCreateMode">
        <div class="p-2 contentScroll simpleCard">
            <!--Start From Here -->
            <div class="card h-100 m-0">
                <div class="card-header">
                    <h3>
                        <div class="d-flex justify-content-between">
                            <span>Background Verification - Action required</span>

                        </div>
                    </h3>
                </div>
                <div class="card-body overflow-auto dashboard-status">

                    <p class="mt-3">
                        <span>The documents need to be uploaded for the below checks</span>
                    </p>
                    <ng-container
                        *ngIf="(DashBoardCheckData != null && DashBoardCheckData != undefined && DashBoardCheckData.length > 0)">
                        <ng-container *ngFor="let checkData of DashBoardCheckData; let i = index">
                            <ul class="list-unstyled d-flex">
                                <li (click)="checkClick(checkData)"
                                    [ngClass]="(checkData.InitiateStatus == 'I002' ? 'w-100 align-self-center cellLink' : 'w-100 align-self-center')">
                                    <span>{{checkData.CheckName | translate}}</span>
                                </li>
                                <li class="flex-fill" (click)="checkClick(checkData)"><a [ngClass]="(checkData.IsApplicable == false ? 'bg-secondary text-white'
                                    : checkData.CheckDocumentOverallStatusColorCode == 'SC001' ? checkData.InitiateStatus == 'I002' ? 'bg-success text-white cellLink' : 'bg-success text-white' 
                                    : checkData.CheckDocumentOverallStatusColorCode == 'SC002' ? checkData.InitiateStatus == 'I002' ? 'bg-secondary text-white cellLink' : 'bg-secondary text-white'
                                    : checkData.CheckDocumentOverallStatusColorCode == 'SC003' ? checkData.InitiateStatus == 'I002' ? 'bg-warning text-white cellLink' : 'bg-warning text-white'
                                    : checkData.InitiateStatus == 'I002' ? 'bg-secondary text-white cellLink' : 'bg-secondary text-white')">{{checkData.UploadedCount}} /
                                        {{checkData.TotalCount}}</a></li>
                            </ul>
                        </ng-container>
                    </ng-container>
                </div>

                <!--End Here -->
                <ng-container *ngIf="!RequestData.IsStopCaseSubmitted && !RequestData.IsClosed">
                    <div class="filters buttonHolder">
                        <div class="d-flex justify-content-between ">
                            <div>
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" checked="" id="attachmentCheck"
                                        [(ngModel)]="AcknowledgeSelected" (change)="onacknowledgeChange()"
                                        style="margin-top: 14px;" [disabled]="disbaleAcknowledgeSelected">
                                    <label class="form-check-label" for="attachmentCheck">
                                        <p class="text-bold">I Acknowledge all the information
                                            provided is true as per my knowledge
                                        </p>
                                    </label>
                                </div>
                            </div>
                            <div>
                                <ng-container
                                    *ngIf="RequestProperty.ActionButtons != null && RequestProperty.ActionButtons != undefined">
                                    <ng-container *ngFor="let actionBar of (RequestProperty.ActionButtons | filterBy: {IsApplicable: true,HasPermission :true,Group:'SubmitDocument'}
                            | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">

                                        <button type="button" [ngClass]="actionBar.CustomClass"
                                            (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                            value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                            |
                                            translate
                                            }}
                                            <span *ngIf="actionBar.ShowArrow" class="ps-3"><i
                                                    class="ion ion-md-arrow-forward" aria-hidden="true"></i></span>
                                        </button>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>

                    </div>
                </ng-container>
            </div>
        </div>

    </ng-container>
    <ng-container *ngIf="!RequestData.IsCreateMode">
        <div class="p-2 contentScroll simpleCard">

            <ng-container *ngIf="showOnBoardingflag">
                <div>
                    <div class="d-flex justify-content-end form-inline">
                        <div class="form-group mb-0">
                            <div class="form-check form-switch">
                                <label style="margin-top: -3px;" class="form-check-label"
                                    for="flexSwitchCheckDefault">{{RequestProperty.ReadyToOnboard.DisplayName
                                    | translate}}</label>
                                <input class="form-check-input" [(ngModel)]="onBoardingSelected" type="checkbox"
                                    role="switch" id="flexSwitchCheckDefault" (change)="changeReadytoOnboard()"
                                    [disabled]="editOnBoardingflag">
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!--Start From Here -->
            <div class="card h-100 m-0">
                <div class="card-header">
                    <h3>
                        <div class="d-flex justify-content-between">
                            <span>Overall Status</span>

                        </div>
                    </h3>
                </div>
                <div class="card-body overflow-auto dashboard-status">

                    <p class="mt-3">
                        <span>The dashboard shows number of checks and their current status</span>
                    </p>
                    <ng-container
                        *ngIf="(DashBoardCheckData != null && DashBoardCheckData != undefined && DashBoardCheckData.length > 0)">
                        <ng-container *ngFor="let checkData of DashBoardCheckData; let i = index">
                            <ul class="list-unstyled d-flex">
                                <li (click)="checkClick(checkData)"
                                    [ngClass]="(checkData.IsInsufficiencyLink == true ? 'w-50 align-self-center cellLink' : 'w-50 align-self-center')">
                                    <span>{{checkData.CheckName | translate}}</span>
                                </li>
                                <li [ngClass]="(checkData.IsInsufficiencyLink == true ? 'w-50 align-self-center cellLink' : 'w-50 align-self-center')"
                                    style="background-color: #f7f7f7;padding: 0.5rem;" (click)="checkClick(checkData)">
                                    <span>{{checkData.CheckStatusName | translate}}</span>
                                </li>

                                <span [ngClass]="(checkData.IsApplicable == false ? 'text-secondary align-self-center'
                        : checkData.StatusColorCode == 'SC001' ? 'text-success align-self-center'
                        : checkData.StatusColorCode == 'SC002' ? 'text-danger align-self-center'
                        : checkData.StatusColorCode == 'SC003' ? 'text-warning align-self-center'
                        : 'text-secondary align-self-center')" data-bs-toggle="tooltip" data-bs-placement="right"
                                    [title]="checkData.CheckName">
                                    <i (click)="checkClick(checkData)" style="font-size: 1.6em;" [ngClass]="(checkData.IsApplicable == false ? 'ion ion-ios-alert fa-lg'
                        : checkData.StatusColorCode == 'SC001' ? 'ion ion-ios-checkmark-circle fa-lg'
                        : checkData.StatusColorCode == 'SC002' ? 'ion ion-ios-alert fa-lg'
                        : checkData.StatusColorCode == 'SC003' ? 'ion ion-ios-alert fa-lg'
                        : 'ion ion-ios-alert fa-lg')" aria-hidden="true" [title]="checkData.CheckStatusName"></i>
                                </span>
                            </ul>
                            <!-- <ul class="list-unstyled d-flex">
                        <li class="w-100 align-self-center"><span>Accepted for Termination</span></li>
                        <li class="flex-fill"><a class="bg-success text-white" href="#">40</a></li>
                    </ul>
                    <ul class="list-unstyled d-flex">
                        <li class="w-100 align-self-center"><span>Clear Insufficiency</span></li>
                        <li class="flex-fill"><a class="bg-danger text-white" href="#">30</a></li>
                    </ul> -->
                        </ng-container>
                    </ng-container>
                </div>

                <!--End Here -->
            </div>
        </div>
    </ng-container>

    <!-- <ng-container *ngIf="RequestData.IsCreateMode">
        <div class="filters buttonHolder">
            <div class="d-flex justify-content-between ">
                <div></div>
                <div>
                    <ng-container
                        *ngIf="!RequestData.IsClosed && RequestProperty.ActionButtons != null && RequestProperty.ActionButtons != undefined && !IsReadonly">

                        <ng-container *ngFor="let actionBar of (RequestProperty.ActionButtons | filterBy: {IsApplicable: true,HasPermission :true,Group:'SubmitDocument'}
                            | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">

                            <button type="button" [ngClass]="actionBar.CustomClass"
                                (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName |
                                translate
                                }}
                                <span *ngIf="actionBar.ShowArrow"><i class="ion ion-md-arrow-forward"
                                        aria-hidden="true"></i></span>
                            </button>

                        </ng-container>
                    </ng-container>

                </div>
            </div>
        </div>
    </ng-container> -->

</div>

<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
        (selected)="setNotifyRes($event) " (close)="closeNotifybox() "></notification>
</div>

<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>

<modal-popup id="ValidationPopup" style="display: none;" [modaldetail]="{'header':'Alert'}">
    <ng-container *ngIf="(ValidationMessageData != null && ValidationMessageData != undefined &&
        ValidationMessageData.length > 0)">
        <ng-container *ngFor="let valData of ValidationMessageData">
            <ul>
                <li>{{valData.ResultMessage | translate}}</li>
            </ul>
        </ng-container>
    </ng-container>
</modal-popup>