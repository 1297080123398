<ng-container *ngIf="(key.Control.Type != 'button' && key.Control.Type != 'icon' && key.Control.Type != 'link'
        && key.Control.Type != 'checkbox' && key.Control.Type != 'badge' && key.Control.Type != 'dataimg')">
  <ng-container *ngIf="!showcontrolalways; else alwaysShowCtrl">
    <div *ngIf="!enableCell(); else enableCelld" (click)="setSelected()" [ngStyle]="{'min-height':'20px'}"
      [ngClass]="{'text-right': key.Control.InputType == 'number'}">
      <div *ngIf="key.LabelTemplate == undefined || key.LabelTemplate == null || key.LabelTemplate.length == 0">
        <p *ngIf="key.LinkedField == undefined || key.LinkedField == null || key.LinkedField.length == 0"
          class="text-truncate" (click)="ctrlClick()" [ngStyle]="key.Style">
          <span [ngClass]="key.Cssnames" *ngIf="key.Control.Type == 'datepicker'" [tooltip]="tooltipTemplate"
            container="body" placement="top" html="true">
            {{ (item[key.FieldName] == null || item[key.FieldName] == undefined || item[key.FieldName].length == 0 ) ? '
            ':
            ((key.Control.Format != null && key.Control.Format != undefined && (key.Control.Format.length > 0 &&
            key.Control.Format.toLowerCase()) == 'true') ? (item[key.FieldName] | localizedDateTime) :
            (item[key.FieldName] | localizedDate) ) }}
          </span>
          <!-- <span [ngClass]="key.Cssnames" *ngIf="key.Control.Type == 'datepicker'" [tooltip]="tooltipTemplate"
            container="body" placement="top" html="true">
              {{ (item[key.FieldName] == null || item[key.FieldName] == undefined || item[key.FieldName].length == 0 ) ? ' ':
                ((key.Control.Format != null && key.Control.Format != undefined && (key.Control.Format.length > 0 &&
                  key.Control.Format.toLowerCase()) == 'true') ? (item[key.FieldName] | date: translateService['AppDateTimeFormat']) : (item[key.FieldName] | date: translateService['AppDateFormat'])) }}
          </span> -->

          <span [ngClass]="key.Cssnames" *ngIf="key.Control.Type != 'datepicker'" [tooltip]="tooltipTemplate"
            container="body" placement="top" html="true">
            {{ key.Control.Type == 'textbox' && key.Control.InputType == 'number' &&
            (key.Control.Format == null || key.Control.Format == undefined || (key.Control.Format.length > 0 &&
            key.Control.Format.toLowerCase()) == 'true') ? ( item[key.FieldName] | localizedNumber ) :
            item[key.FieldName] }}
          </span>
        </p>

        <p *ngIf="key.LinkedField != undefined && key.LinkedField != null && key.LinkedField.length > 0"
          class="text-truncate" (click)="ctrlClick()">
          <span [ngClass]="key.Cssnames" [tooltip]="item[key.LinkedField]" container="body"
            placement="top">{{item[key.LinkedField]}}</span>
        </p>
      </div>

      <!-- <div *ngIf="key.LabelTemplate != undefined && key.LabelTemplate != null && key.LabelTemplate.length > 0"
      [innerHtml]="getLabelContent()"></div> -->
      <div *ngIf="key.LabelTemplate != undefined && key.LabelTemplate != null && key.LabelTemplate.length > 0">
        <p class="text-truncate" (click)="ctrlClick()">
          <span [ngClass]="key.Cssnames" [tooltip]="tooltipTemplate" container="body" placement="top">
            <div *dynamicComponent="key.LabelTemplate; context: {item: item, parent: this};"></div>
          </span>
        </p>
      </div>

    </div>
  </ng-container>

</ng-container>

<div class="seteditdiv" [hidden]="true" (click)="showEditCtrl()"></div>
<div class="reseteditdiv" [hidden]="true" (click)="resetEditMode($event)"></div>

<ng-template #tooltipTemplate>
  <div *ngIf="key.ToolTipTemplate == undefined || key.ToolTipTemplate == null || key.ToolTipTemplate.length == 0"
    [innerHtml]="getToolTipContent()"></div>

  <ng-container
    *ngIf="key.ToolTipTemplate != undefined && key.ToolTipTemplate != null && key.ToolTipTemplate.length > 0">
    <div *dynamicComponent="key.ToolTipTemplate; context: {item: item};"></div>
  </ng-container>
</ng-template>

<ng-template #enableCelld>
  <ng-container *ngIf="key.Control.Type == 'label'">
    <p class="text-truncate">{{item[key.FieldName]}}</p>
  </ng-container>

  <ng-container *ngIf="key.Control.Type == 'textbox'">
    <ng-container *ngIf="(key.Control.InputType == 'number')">
      <label class="sr-only" for="{{parentRandomId}}_{{fieldRandomId}}">Number</label>
        <input type="number" [id]="(parentRandomId + '_' +fieldRandomId)"  name ="{{fieldRandomId}}" class="form-control form-control-sm text-right" [ngClass]="key.Cssnames" 
          [ngStyle]="key.Style" [(ngModel)]="item[key.FieldName]" [required]="key.EditValidate.Required"
          [min]="key.EditValidate.Min" [max]="key.EditValidate.Max" (click)="ctrlClick()" (change)="ctrlChange()"
          (focus)="cellOnFocus($event)" (focusout)="cellOnFocusOut($event)" />
      

    </ng-container>
    <ng-container *ngIf="(key.Control.InputType == 'text')">
      <label class="sr-only" for="{{parentRandomId}}_{{fieldRandomId}}">Text</label>
        <input type="text" [id]="(parentRandomId + '_' +fieldRandomId)"  name="{{fieldRandomId}}" class="form-control form-control-sm {{key.Cssnames}}" [ngStyle]="key.Style" 
          [(ngModel)]="item[key.FieldName]" [required]="key.EditValidate.Required" [maxlength]="key.EditValidate.Max"
          (click)="ctrlClick()" (change)="ctrlChange()" (focus)="cellOnFocus($event)"
          (focusout)="cellOnFocusOut($event)" />
     
    </ng-container>
  </ng-container>

  <ng-container *ngIf="key.Control.Type == 'textarea'">
    <textarea  [id]="(parentRandomId + '_' +fieldRandomId)"  class="form-control form-control-sm" [ngClass]="key.Cssnames" [ngStyle]="key.Style" 
      [(ngModel)]="item[key.FieldName]" [required]="key.EditValidate.Required" [maxlength]="key.EditValidate.Max"
      (click)="ctrlClick()" (change)="ctrlChange()" (focus)="cellOnFocus($event)" (focusout)="cellOnFocusOut($event)"
      style="resize:none;"></textarea>
      <label class="sr-only" for="{{parentRandomId}}_{{fieldRandomId}}">Text</label>
  </ng-container>

  <ng-container *ngIf="key.Control.Type == 'texteditor'">
    <editor [init]="{ 
          base_url: '/tinymce', 
          suffix: '.min',
          branding: false,
          menubar: 'edit insert format table',
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat'
        }" [(ngModel)]="item[key.FieldName]" (onClick)="ctrlClick()" (onChange)="ctrlChange()"
      [disabled]="disableCtrl"></editor>
  </ng-container>

  <ng-container *ngIf="key.Control.Type == 'dropdown'">
    <div *ngIf="dispLoader" class="loader-icon">
      <span class="sr-only">Loading...</span>
      <i class="fa fa-spinner fa-pulse fa-fw fa-xs"></i>
    </div>

    <!-- when invoked from grid -> given #id to open dropdown on edit  -->
    <ng-container *ngIf="!initedit && !dispLoader">
      <!-- [isOpen]="(true)" -->
      <ng-select [id]="(parentRandomId + '_' +fieldRandomId)" *ngIf="key.Control.InputType != 'multi'" #select class="custom" [items]="(key.Control.List)"
        [(ngModel)]="item[key.FieldName]" [bindLabel]="key.Control!.Source!.ValueField!"
        [bindValue]="key.Control!.Source!.KeyField!" placeholder="Select" appendTo="body"
        [inputAttrs]="{autofocus: 'false'}" [compareWith]="compareWithFn" [closeOnSelect]="true"
        (change)="ctrlChange()" (click)="ctrlClick()">
      </ng-select>
      <ng-select [id]="(parentRandomId + '_' +fieldRandomId)" *ngIf="key.Control.InputType == 'multi'" #select class="custom" [items]="key.Control.List"
        [(ngModel)]="selectedMulti" [multiple]="true" [bindLabel]="key.Control!.Source!.ValueField!"
        [bindValue]="key.Control!.Source!.KeyField!" placeholder="Select" appendTo="body"
        [inputAttrs]="{autofocus: 'false'}" [compareWith]="compareWithFn" [closeOnSelect]="true"
        (change)="multiOnChange($event)" (click)="ctrlClick()">
      </ng-select>
      <label class="sr-only" for="{{parentRandomId}}_{{fieldRandomId}}">Text</label>
    </ng-container>

    <!-- when invoked from form -> not given #id so that dropdown is not opened  -->
    <ng-container *ngIf="initedit && !dispLoader">
      <ng-select [id]="(parentRandomId + '_' +fieldRandomId)"  *ngIf="key.Control.InputType != 'multi'" class="custom" [items]="key.Control.List"
        [(ngModel)]="item[key.FieldName]" [bindLabel]="key.Control!.Source!.ValueField!"
        [bindValue]="key.Control!.Source!.KeyField!" placeholder="Select" appendTo="body"
        [inputAttrs]="{autofocus: 'false'}" [compareWith]="compareWithFn" [closeOnSelect]="true"
        (change)="ctrlChange()" (click)="ctrlClick()">
      </ng-select>

      <ng-select [id]="(parentRandomId + '_' +fieldRandomId)" *ngIf="key.Control.InputType == 'multi'" class="custom" [items]="key.Control.List"
        [(ngModel)]="selectedMulti" [multiple]="(key.Control.InputType == 'multi'? true: false)"
        [bindLabel]="key.Control!.Source!.ValueField!" [bindValue]="key.Control!.Source!.KeyField!" placeholder="Select"
        appendTo="body" [inputAttrs]="{autofocus: 'false'}" [compareWith]="compareWithFn" [closeOnSelect]="true"
        (change)="multiOnChange($event)" (click)="ctrlClick()">
      </ng-select>
      <label class="sr-only" for="{{parentRandomId}}_{{fieldRandomId}}">Text</label>
    </ng-container>

    <!-- <ng-container *ngIf="(key.Control.InputType != 'multi')">
  <div *ngIf="dispLoader" class="loader-icon">
    <i class="fa fa-spinner fa-pulse fa-fw fa-xs"></i>
  </div>

  <select *ngIf="!dispLoader" required class="form-control form-control-sm" [(ngModel)]="item[key.FieldName]" [required]="key.EditValidate.Required"
    (click)="ctrlClick()" (change)="ctrlChange()" (focus)="cellOnFocus($event)" (focusout)="cellOnFocusOut($event)"> -->
    <!--<option [ngValue]="null">--Select--</option>-->
    <!-- <option *ngIf="dispLoader"> Loading...</option> -->
    <!-- <option [value]="null" selected="selected">--Select--</option>
        <option *ngFor='let option of key.Control.List' [id]="option[key.Control!.Source!.KeyField!]" [value]="option[key.Control!.Source!.KeyField!]">
          {{option[key.Control!.Source!.ValueField!] | translate}}</option>
    </select>
  </ng-container>

  <ng-container *ngIf="(key.Control.InputType == 'multi')"> -->
    <!-- [hideSelected]="true" (blur)="multiOnBlur($event)" -->
    <!-- <ng-select [id]="(parentRandomId + '_' +fieldRandomId)" [items]="key.Control.List" [(ngModel)]="selectedMulti" [multiple]="true"
        [bindLabel]="key.Control!.Source!.ValueField!" [bindValue]="key.Control!.Source!.KeyField!"
        placeholder="Select" appendTo="body"
        [closeOnSelect]="false" [isOpen]="true" (change)="multiOnChange($event)" > -->
    <!-- <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
      <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.gender | uppercase}}
    </ng-template> -->
    <!-- <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <input id="item-{{index}}" type="checkbox" /> {{item[key.Control!.Source!.ValueField!]}}
    </ng-template> -->
    <!-- </ng-select>
  </ng-container> -->

  </ng-container>

  <ng-container *ngIf="key.Control.Type == 'radio'">
    <div *ngIf="dispLoader" class="loader-icon">
      <span class="sr-only">Loading...</span>
      <i class="fa fa-spinner fa-pulse fa-fw fa-xs"></i>
    </div>

    <ng-container *ngIf="!dispLoader">
      <div class="d-inline-flex">
        <div *ngFor="let option of key.Control.List" class="d-inline-flex me-3">
          <input type="radio" class="form-check-input" [name]="fieldRandomId+'-'+key.FieldName"
            [id]="fieldRandomId+'-'+option[key.Control!.Source!.KeyField!]"
            [value]="option[key.Control!.Source!.ValueField!]"
            [checked]="option[key.Control!.Source!.ValueField!]==item[key.FieldName]" [(ngModel)]="item[key.FieldName]"
            (change)="ctrlChange()" [disabled]="!enableAction()">
          <label [for]="fieldRandomId+'-'+option[key.Control!.Source!.KeyField!]"
            class="form-check-label">{{option[key.Control!.Source!.ValueField!] | translate}}</label>
        </div>
      </div>

      <!-- <div class="d-flex pt-2">
        <div *ngFor="let option of key.Control.List" class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" [name]="key.FieldName"
            [id]="fieldRandomId+'-'+option[key.Control!.Source!.KeyField!]"
            [value]="option[key.Control!.Source!.ValueField!]"
            [checked]="option[key.Control!.Source!.ValueField!]==item[key.FieldName]" [(ngModel)]="item[key.FieldName]"
            (change)="ctrlChange()" [disabled]="!enableAction()">
          <label [for]="fieldRandomId+'-'+option[key.Control!.Source!.KeyField!]"
            class="form-control-label">{{option[key.Control!.Source!.ValueField!] | translate}}</label>
        </div>
      </div>       -->
    </ng-container>

  </ng-container>

  
    <ng-container *ngIf="key.Control.Type == 'datepicker'">
      
    <ng-container *ngIf="key.Cssnames == 'abc'">
      <div  class="input-group input-group-sm d-inline-flex">
        <label class="sr-only" for="{{parentRandomId}}_{{fieldRandomId}}">Date</label>
        <input type="text"  id="{{fieldRandomId}}" name="{{fieldRandomId}}"  class="datepick form-control form-control-sm" bsDatepicker [ngStyle]="key.Style"
          [(ngModel)]="item[key.FieldName]" readonly="true" style="background-color: white;"
          [bsConfig]="{minMode:'month', adaptivePosition: true, containerClass: 'theme-default', dateInputFormat: 'MM-YYYY'}"
          #d="bsDatepicker" (click)="d.toggle()" (onHidden)="ctrlChange()" container="body" />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary border-left-0 calendar" type="button" (click)="d.toggle()">
            <span class="sr-only">calendar</span> 
            <i class="fa fa-calendar" aria-hidden="true"></i></button>
        </div>
      </div>
      
  
    </ng-container>
  </ng-container>

  <ng-container *ngIf="key.Control.Type == 'datepicker'">
      
    <ng-container *ngIf="key.Cssnames == 'fromdate'">
      <div  class="input-group input-group-sm d-inline-flex">
        <label class="sr-only" for="{{parentRandomId}}_{{fieldRandomId}}">Date</label>
        <input type="text"  id="{{fieldRandomId}}" name="{{fieldRandomId}}"  class="datepick form-control form-control-sm" bsDatepicker [ngStyle]="key.Style"
          [(ngModel)]="item[key.FieldName]" readonly="true" style="background-color: white;"
          [bsConfig]="{ minMode:'month', adaptivePosition: true, containerClass: 'theme-default', dateInputFormat: 'MM-YYYY'}"
          #d="bsDatepicker" (click)="d.toggle()" (onHidden)="ctrlChange()" container="body"  />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary border-left-0 calendar" type="button" (click)="d.toggle()">
            <span class="sr-only">calendar</span> 
            <i class="fa fa-calendar" aria-hidden="true"></i></button>
        </div>
      </div>
      
  
    </ng-container>
  </ng-container>

  <ng-container *ngIf="key.Control.Type == 'datepicker'">
    <!--<div *ngif="key.Style != 'courtrecord'">
     <input type="text" style='cursor:pointer' [(ngModel)]="item[key.FieldName]" (click)="ctrlClick(key.FieldName, item)"
        (change)="ctrlChange(key.FieldName, item)" />
     <input type="text"  [ngStyle]="key.Style"
            #dp="bsDatepicker" bsDatepicker
            [minDate]="key.EditValidate.Min" [maxDate]="key.EditValidate.Max"
            [(bsValue)]="item[key.FieldName]" value="{{ (item[key.FieldName]) | date: key.Control.Format}}"
            [bsConfig]="{containerClass: 'theme-default'}" (bsValueChange)="ctrlChange()" /> 
     [bsConfig]="{containerClass: 'theme-default', dateInputFormat: 'DD.MM.YYYY' }" 
     <input class="datepick form-control" type="text" [ngStyle]="key.Style" bsDatepicker [bsConfig]="{containerClass: 'theme-default',showWeekNumbers:false}"
      value="{{ (item[key.FieldName] == null || item[key.FieldName] == undefined || item[key.FieldName].length == 0 ) ? '': ( item[key.FieldName] | localizedDate)}}"
      [minDate]="key.EditValidate.Min" [maxDate]="key.EditValidate.Max" [(ngModel)]="item[key.FieldName]" (ngModelChange)="ctrlChange()"
      /> 
     (dateSelect)="onDateSelect($event)"  -->
     <ng-container *ngIf="key.Cssnames != 'abc'">
      <ng-container *ngIf="key.Cssnames != 'fromdate'">
    <div class="input-group input-group-sm d-inline-flex">
      <label class="sr-only" for="{{parentRandomId}}_{{fieldRandomId}}">Date</label>
      <input type="text"  id="{{fieldRandomId}}" name="{{fieldRandomId}}"  class="datepick form-control form-control-sm" ngbDatepicker [ngStyle]="key.Style"
        [(ngModel)]="item[key.FieldName]" readonly="true" style="background-color: white;"
        [minDate]="((key.EditValidate.Min == null || key.EditValidate.Min == undefined || key.EditValidate.Min.length == 0 ) ? {year:0, month:0, day:0}:
          {year: key.EditValidate.Min.getFullYear(), month: (key.EditValidate.Min.getMonth() + 1), day: key.EditValidate.Min.getDate() })"
        [maxDate]="((key.EditValidate.Max == null || key.EditValidate.Max == undefined || key.EditValidate.Max.length == 0 ) ? {year:0, month:0, day:0}:
          {year: key.EditValidate.Max.getFullYear(), month: (key.EditValidate.Max.getMonth() + 1), day: key.EditValidate.Max.getDate() })"
        [startDate]="((item[key.FieldName] == null || item[key.FieldName] == undefined || item[key.FieldName].length == 0 ) ? {year:0, month:0, day:0}:
          {year: item[key.FieldName].getFullYear(), month: (item[key.FieldName].getMonth() + 1), day: item[key.FieldName].getDate() })" #d="ngbDatepicker" (click)="d.toggle()"
        (dateSelect)="ctrlChange()" (closed)="onDatePickerClose($event)" container="body" placement="bottom auto" />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary border-left-0 calendar" type="button" (click)="d.toggle()">
          <span class="sr-only">calendar</span> 
          <i class="fa fa-calendar" aria-hidden="true"></i></button>
      </div>
    </div>
  </ng-container>
</ng-container>
  </ng-container> 


  <!-- <ng-container *ngIf="key.Control.Type == 'datepicker'">
    
    <div *ngif="key.style == 'courtrecord'" class="input-group input-group-sm d-inline-flex">
      <label class="sr-only" for="{{parentRandomId}}_{{fieldRandomId}}">Date</label>
      <input type="text"  id="{{fieldRandomId}}" name="{{fieldRandomId}}"  class="datepick form-control form-control-sm" bsDatepicker [ngStyle]="key.Style"
        [(ngModel)]="item[key.FieldName]" readonly="true" style="background-color: white;"
        [bsConfig]="{ containerClass: 'theme-default',showWeekNumbers:false, dateInputFormat: 'MM-YYYY'}"
        #d="bsDatepicker" (click)="d.toggle()"
        (dateSelect)="ctrlChange()" (closed)="onDatePickerClose($event)" container="body" 
        (onShown)="onOpenCalendar($event)" />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary border-left-0 calendar" type="button" (click)="d.toggle()">
          <span class="sr-only">calendar</span> 
          <i class="fa fa-calendar" aria-hidden="true"></i></button>
      </div>
    </div>

  </ng-container> -->

  <ng-container *ngIf="key.Control.Type == 'timepicker'">
    <label class="sr-only" for="{{fieldRandomId}}-Timepicker">Timepicker</label>
    <input id="{{fieldRandomId}}-Timepicker" name="{{fieldRandomId}}" type="time" class="form-control form-control-sm" [ngStyle]="key.Style" [(ngModel)]="item[key.FieldName]"
      readonly="true" style="background-color: white;" (change)="ctrlChange()" />

    <!-- <div class="input-group input-group-sm">
      <input type="time" class="form-control form-control-sm" [ngStyle]="key.Style"
        [(ngModel)]="item[key.FieldName]" readonly="true" style="background-color: white;"
        (change)="ctrlChange()" />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary border-left-0 calendar" type="button">
          <i class="fa fa-clock-o" aria-hidden="true"></i></button>
      </div>
    </div> -->

  </ng-container>

  <ng-container *ngIf="key.Control.Type == 'typeahead'">
    <!-- <input class="form-control {{key.Cssnames}}" [ngStyle]="key.Style" [(ngModel)]="item[key.LinkedField]" [typeahead]="key.Control.List"
      [typeaheadOptionField]="key.Control!.Source!.ValueField!" (typeaheadOnSelect)="typeaheadOnSelect($event)" (blur)="typeaheadOnBlur($event)"
      (typeaheadNoResults)="typeaheadNoResults($event)" /> -->
    <ng-container *ngIf="this.key.Control.AllowNewListItem==true">
      <ng-select [id]="(parentRandomId + '_' +fieldRandomId)" [items]="key.Control.List" #select [(ngModel)]="item[key.FieldName]"
        [bindLabel]="key.Control!.Source!.ValueField!" [bindValue]="key.Control!.Source!.KeyField!"
        placeholder="Select value" appendTo="body" [closeOnSelect]="true" (change)="typeaheadOnSelect($event)"
        (search)="typeaheadOnSearch($event)" (blur)="typeaheadOnBlur($event);forceResizeWindow();"
        [loading]="dispLoader" [addTag]="addTagNowRef" (click)="forceResizeWindow();ctrlClick()">
        <!-- [addTag]="true" addTagText="Add a new name"  -->
      </ng-select>
    </ng-container>
    <ng-container *ngIf="this.key.Control.AllowNewListItem!=true">
      <ng-select [id]="(parentRandomId + '_' +fieldRandomId)" [items]="key.Control.List" #select [(ngModel)]="item[key.FieldName]"
        [bindLabel]="key.Control!.Source!.ValueField!" [bindValue]="key.Control!.Source!.KeyField!"
        placeholder="Select value" appendTo="body" [closeOnSelect]="true" (change)="typeaheadOnSelect($event)"
        (search)="typeaheadOnSearch($event)" (blur)="typeaheadOnBlur($event);forceResizeWindow();"
        [loading]="dispLoader" (click)="forceResizeWindow();ctrlClick()">
        <!-- [addTag]="typeaheadAddTag"> -->
        <!-- [addTag]="true" addTagText="Add a new name"  -->
      </ng-select>
    </ng-container>
  </ng-container>

</ng-template>

<ng-template #alwaysShowCtrl>
  <ng-container *ngIf="key.Control.Type == 'label'">
    <!-- <p class="text-truncate">{{item[key.FieldName]}}</p> -->
    <label class="sr-only" for="{{parentRandomId}}_{{fieldRandomId}}">Text</label>
    <input [id]="(parentRandomId + '_' +fieldRandomId)" name="{{fieldRandomId}}" type="text" class="form-control form-control-sm {{key.Cssnames}}" [ngStyle]="key.Style"
      [(ngModel)]="item[key.FieldName]" [disabled]="true" />
  </ng-container>

  <ng-container *ngIf="key.Control.Type == 'textbox'">
    <ng-container *ngIf="(key.Control.InputType == 'number')">
      <label class="sr-only" for="{{parentRandomId}}_{{fieldRandomId}}">NUmber</label>
      <input [id]="(parentRandomId + '_' +fieldRandomId)" name="{{fieldRandomId}}" type="number" class="form-control form-control-sm text-right" [ngClass]="key.Cssnames" 
        [ngStyle]="key.Style" [(ngModel)]="item[key.FieldName]" [required]="key.EditValidate.Required"
        [min]="key.EditValidate.Min" [max]="key.EditValidate.Max" (click)="ctrlClick()" (change)="ctrlChange()"
        (focus)="cellOnFocus($event)" (focusout)="cellOnFocusOut($event)" [disabled]="disableCtrl" />
    </ng-container>
    <ng-container *ngIf="(key.Control.InputType == 'text')">
      <label class="sr-only" for="{{parentRandomId}}_{{fieldRandomId}}">Text</label>
      <input  [id]="(parentRandomId + '_' +fieldRandomId)" name="{{fieldRandomId}}" type="text" [placeholder]=key.Control.PlaceHolderText class="form-control form-control-sm {{key.Cssnames}}" [ngStyle]="key.Style" 
        [(ngModel)]="item[key.FieldName]" [required]="key.EditValidate.Required" [maxlength]="key.EditValidate.Max"
        (click)="ctrlClick()" (change)="ctrlChange()" (focus)="cellOnFocus($event)" (focusout)="cellOnFocusOut($event)"
        [disabled]="disableCtrl" />
    </ng-container>
  </ng-container>

  <ng-container *ngIf="key.Control.Type == 'textarea'">
    <textarea [id]="(parentRandomId + '_' +fieldRandomId)" class="form-control form-control-sm" [ngClass]="key.Cssnames" [ngStyle]="key.Style" 
      [(ngModel)]="item[key.FieldName]" [required]="key.EditValidate.Required" [maxlength]="key.EditValidate.Max"
      (click)="ctrlClick()" (change)="ctrlChange()" (focus)="cellOnFocus($event)" (focusout)="cellOnFocusOut($event)"
      style="resize:none;" [disabled]="disableCtrl"></textarea>
      <label class="sr-only" for="{{parentRandomId}}_{{fieldRandomId}}">Text</label>
  </ng-container>

  <ng-container *ngIf="key.Control.Type == 'texteditor'">
    <!-- statusbar: false -->
    <editor [init]="{ 
          base_url: '/tinymce', 
          suffix: '.min',
          branding: false,
          menubar: 'edit insert format table',
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat'
        }" [(ngModel)]="item[key.FieldName]" (onClick)="ctrlClick()" (onChange)="ctrlChange()"
      [disabled]="disableCtrl"></editor>

  </ng-container>

  <ng-container *ngIf="key.Control.Type == 'dropdown'">
    <div *ngIf="dispLoader" class="loader-icon">
      <span class="sr-only">Loading...</span>
      <i class="fa fa-spinner fa-pulse fa-fw fa-xs"></i>
    </div>

    <!-- when invoked from grid -> given #id to open dropdown on edit  -->
    <ng-container *ngIf="!initedit && !dispLoader">
      <!-- [isOpen]="(true)" -->
      <ng-select [id]="(parentRandomId + '_' +fieldRandomId)" *ngIf="key.Control.InputType != 'multi'" #select class="custom" [items]="key.Control.List"
        [(ngModel)]="item[key.FieldName]" [bindLabel]="key.Control!.Source!.ValueField!"
        [bindValue]="key.Control!.Source!.KeyField!" [placeholder]="placeholder" appendTo="body"
        [inputAttrs]="{autofocus: 'false'}" [compareWith]="compareWithFn" [closeOnSelect]="true" (change)="ctrlChange()"
        [disabled]="disableCtrl" (click)="ctrlClick()">
      </ng-select>
      <ng-select [id]="(parentRandomId + '_' +fieldRandomId)" *ngIf="key.Control.InputType == 'multi'" #select class="custom" [items]="key.Control.List"
        [(ngModel)]="selectedMulti" [multiple]="true" [bindLabel]="key.Control!.Source!.ValueField!"
        [bindValue]="key.Control!.Source!.KeyField!" [placeholder]="placeholder" appendTo="body"
        [inputAttrs]="{autofocus: 'false'}" [compareWith]="compareWithFn" [closeOnSelect]="true"
        (change)="multiOnChange($event)" [disabled]="disableCtrl" (click)="ctrlClick()">
      </ng-select>
      <label class="sr-only" for="{{parentRandomId}}_{{fieldRandomId}}">Text</label>
    </ng-container>

    <!-- when invoked from form -> not given #id so that dropdown is not opened  -->
    <ng-container *ngIf="initedit && !dispLoader">
      <ng-select [id]="(parentRandomId + '_' +fieldRandomId)" *ngIf="key.Control.InputType != 'multi'" class="custom" [items]="key.Control.List"
        [(ngModel)]="item[key.FieldName]" [bindLabel]="key.Control!.Source!.ValueField!"
        [bindValue]="key.Control!.Source!.KeyField!" [placeholder]="placeholder" appendTo="body"
        [inputAttrs]="{autofocus: 'false'}" [compareWith]="compareWithFn" [closeOnSelect]="true" (change)="ctrlChange()"
        [disabled]="disableCtrl" (click)="ctrlClick()">
      </ng-select>

      <ng-select [id]="(parentRandomId + '_' +fieldRandomId)" *ngIf="key.Control.InputType == 'multi'" class="custom" [items]="key.Control.List"
        [(ngModel)]="selectedMulti" [multiple]="(key.Control.InputType == 'multi'? true: false)"
        [bindLabel]="key.Control!.Source!.ValueField!" [bindValue]="key.Control!.Source!.KeyField!"
        [placeholder]="placeholder" appendTo="body" [inputAttrs]="{autofocus: 'false'}" [compareWith]="compareWithFn"
        [closeOnSelect]="true" (change)="multiOnChange($event)" [disabled]="disableCtrl" (click)="ctrlClick()">
      </ng-select>
      <label class="sr-only" for="{{parentRandomId}}_{{fieldRandomId}}">Text</label>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="key.Control.Type == 'radio'">
    <div *ngIf="dispLoader" class="loader-icon">
      <span class="sr-only">Loading...</span>
      <i class="fa fa-spinner fa-pulse fa-fw fa-xs"></i>
    </div>

    <ng-container *ngIf="!dispLoader">
      <div class="d-flex pt-1">
        <div *ngFor="let option of key.Control.List" class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" [name]="key.FieldName"
            [id]="fieldRandomId+'-'+option[key.Control!.Source!.KeyField!]"
            [value]="option[key.Control!.Source!.ValueField!]"
            [checked]="option[key.Control!.Source!.ValueField!]==item[key.FieldName]" [(ngModel)]="item[key.FieldName]"
            (change)="ctrlChange()" [disabled]="!enableAction()">
          <label [for]="fieldRandomId+'-'+option[key.Control!.Source!.KeyField!]"
            class="custom-control-label">{{option[key.Control!.Source!.ValueField!] | translate}}</label>
        </div>
      </div>
    </ng-container>

  </ng-container>


  <ng-container *ngIf="key.Control.Type == 'datepicker'">
    <div class="input-group input-group-sm d-inline-flex">
      <label class="sr-only" for="{{parentRandomId}}_{{fieldRandomId}}">Datepicker</label>
      <input id="{{fieldRandomId}}" name="{{fieldRandomId}}" type="text" class="datepick form-control form-control-sm" ngbDatepicker [(ngModel)]="item[key.FieldName]"
        [readonly]="true" [ngStyle]="{'background-color': (disableCtrl? '#F7F7F7': 'white')}"
        [minDate]="((key.EditValidate.Min == null || key.EditValidate.Min == undefined || key.EditValidate.Min.length == 0 ) ? {year:0, month:0, day:0}:
            {year: key.EditValidate.Min.getFullYear(), month: (key.EditValidate.Min.getMonth() + 1), day: key.EditValidate.Min.getDate() })"
        [maxDate]="((key.EditValidate.Max == null || key.EditValidate.Max == undefined || key.EditValidate.Max.length == 0 ) ? {year:0, month:0, day:0}:
            {year: key.EditValidate.Max.getFullYear(), month: (key.EditValidate.Max.getMonth() + 1), day: key.EditValidate.Max.getDate() })"
        [startDate]="((item[key.FieldName] == null || item[key.FieldName] == undefined || item[key.FieldName].length == 0 ) ? {year:0, month:0, day:0}:
            {year: item[key.FieldName].getFullYear(), month: (item[key.FieldName].getMonth() + 1), day: item[key.FieldName].getDate() })" #d="ngbDatepicker" (click)="d.toggle()" (dateSelect)="ctrlChange()"
        (closed)="onDatePickerClose($event)" container="body" placement="bottom auto" [disabled]="disableCtrl" />
      <div class="input-group-append" *ngIf="!disableCtrl">
        <button class="btn btn-outline-secondary border-left-0 calendar" type="button" (click)="d.toggle()">
         <span class="sr-only">calendar</span> <i class="fa fa-calendar" aria-hidden="true"></i></button>
      </div>
    </div>

  </ng-container>

  <ng-container *ngIf="key.Control.Type == 'timepicker'">
    
    <label class="sr-only" for="{{fieldRandomId}}+Timepicker">Timepicker</label>
    <input type="time"  id="{{fieldRandomId}}+Timepicker" name="{{fieldRandomId}}"  class="form-control form-control-sm" [(ngModel)]="item[key.FieldName]"
      [readonly]="(disableCtrl? true:false)" [ngStyle]="{'background-color': (disableCtrl? '#F7F7F7': 'white')}"
      (change)="ctrlChange()" />

    <!-- <div class="input-group input-group-sm">
      <input type="time" class="form-control form-control-sm" 
        [(ngModel)]="item[key.FieldName]" [readonly]="(disableCtrl? true:false)"
        [ngStyle]="{'background-color': (disableCtrl? '#F7F7F7': 'white')}"
        (change)="ctrlChange()" />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary border-left-0 calendar" type="button" (click)="">
          <i class="fa fa-clock-o" aria-hidden="true"></i></button>
      </div> 
    </div> -->

  </ng-container>

  <ng-container *ngIf="key.Control.Type == 'typeahead'">
    <!-- <div *ngIf="dispLoader" class="loader-icon">
      <i class="fa fa-spinner fa-pulse fa-fw fa-xs"></i>
    </div> -->

    <!-- <ng-container *ngIf="!dispLoader"> -->
    <ng-container *ngIf="this.key.Control.AllowNewListItem==true">
      <ng-select [id]="(parentRandomId + '_' +fieldRandomId)" [items]="key.Control!.List!" #select [(ngModel)]="item[key.FieldName]"
        [bindLabel]="key.Control!.Source!.ValueField!" [bindValue]="key.Control!.Source!.KeyField!"
        placeholder="Select value" appendTo="body" [closeOnSelect]="true" (change)="typeaheadOnSelect($event)"
        (search)="typeaheadOnSearch($event)" (blur)="typeaheadOnBlur($event);forceResizeWindow();"
        [loading]="dispLoader" [addTag]="addTagNowRef" (click)="forceResizeWindow();ctrlClick()" [disabled]="disableCtrl">
        <!-- [addTag]="true" addTagText="Add a new name"  -->
      </ng-select>
      <label class="sr-only" for="{{parentRandomId}}_{{fieldRandomId}}">Text</label>
    </ng-container>
    <ng-container *ngIf="this.key.Control.AllowNewListItem!=true">
      <!-- <ng-select [id]="(parentRandomId + '_' +fieldRandomId)" [items]="key.Control.List" [(ngModel)]="item[key.FieldName]"
                    [bindLabel]="key.Control!.Source!.ValueField!" [bindValue]="key.Control!.Source!.KeyField!" placeholder="Select value"
                    appendTo="body" [closeOnSelect]="true" (change)="typeaheadOnSelect($event)" (blur)="typeaheadOnBlur($event);forceResizeWindow();" 
                    (click)="forceResizeWindow()" [disabled]="disableCtrl">
          </ng-select> -->
      <ng-select [items]="key.Control.List" #select [(ngModel)]="item[key.FieldName]"
        [bindLabel]="key.Control!.Source!.ValueField!"  [bindValue]="key.Control!.Source!.KeyField!"
        placeholder="Select value" appendTo="body" [closeOnSelect]="true" (change)="typeaheadOnSelect($event)"
        (blur)="typeaheadOnBlur($event);forceResizeWindow();" [loading]="dispLoader"
        (search)="typeaheadOnSearch($event)" (click)="forceResizeWindow();ctrlClick()" [disabled]="disableCtrl">
      </ng-select>
    </ng-container>

    <!-- </ng-container>   end of *ngIf="!dispLoader" -->

  </ng-container>

</ng-template>

<ng-container *ngIf="key.Control.Type == 'checkbox'">

  <div class="form-check"
    [ngClass]="{'form-check': (key.Control.InputType == null || key.Control.InputType == undefined || key.Control.InputType == ''), 
          'form-switch': (key.Control.InputType != null && key.Control.InputType != undefined && key.Control.InputType == 'toggle') }">
    <input type="checkbox" class="form-check-input" [id]="fieldRandomId" [(ngModel)]="item[key.FieldName]"
      [disabled]="!enableAction()" (change)="ctrlChange()" (focus)="cellOnFocus($event)"
      (focusout)="cellOnFocusOut($event)" (keydown)="ctrlKey($event)" />
    <label class="form-check-label sr-only" [for]="fieldRandomId">&nbsp; checkbox
      <!-- {{ (key.Control.InputType != null && key.Control.InputType != undefined &&
      key.Control.InputType == 'toggle')? 'Active': '' }} -->
    </label>

  </div>

</ng-container>

<ng-container *ngIf="key.Control.Type == 'button'">
  <button type="button" [name]="key.FieldName" [class]="key.Cssnames" [ngStyle]="key.Style" [disabled]="!enableAction()"
    (click)="ctrlClick()">
    {{key.FieldName}}
  </button>
</ng-container>

<ng-container *ngIf="key.Control.Type == 'link'">
  <a  [attr.aria-label]="key?.FieldName"  [href]="parseURL()" [target]="key.Control.Source!.Target!" [ngStyle]="key.Style" [class]="key.Cssnames">
    {{item[key.FieldName]}}
  </a>
</ng-container>

<!-- This code is only for Milkyway -->
<!--<ng-container *ngIf="key.Control.Type == 'link'">
  <p class="text-truncate"><a href="javascript:;" class="linkTitleSmall {{key.Cssnames}}" *ngIf="key.Control.Source.Target == '_top'"
    (click)="displayPageTab(key.Control.Source.Url,item[key.FieldName])" [tooltip]="item[key.FieldName]"> {{item[key.FieldName]}}</a></p>
  <p class="text-truncate"><a href="javascript:;" class="linkTitleSmall {{key.Cssnames}}" *ngIf="key.Control.Source.Target != '_top'"
    [href]="parseURL()" [target]="key.Control.Source.Target" [ngStyle]="key.Style" [tooltip]="item[key.FieldName]"> {{item[key.FieldName]}}</a></p>
</ng-container>-->

<ng-container *ngIf="key.Control.Type == 'icon'">
  <!-- No icon list -->
  <ng-container
    *ngIf="key.Control.IconList == undefined || key.Control.IconList == null || key.Control.IconList.length == 0">
    <ul class="actionList noLabel d-inline">
      <li>
        <ng-container *ngIf="(key.Control.InputType=='action')">
          <ng-container *ngIf="enableAction()">
            <a [attr.aria-label]="key.Control?.InputType" href="javascript:this.blur();">
              <i [tooltip]="item[key.FieldName]" container="body" placement="top" [class]="key.Cssnames"
                [ngStyle]="key.Style" (click)="ctrlClick()"></i>
            </a>           
          </ng-container>

          <ng-container *ngIf="!enableAction()">
            <a [attr.aria-label]="key.Control?.InputType" class="disabled">
              <i [tooltip]="item[key.FieldName]" container="body" placement="top" [class]="key.Cssnames"
                [ngStyle]="{'color':'grey'}"></i>
            </a>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="(key.Control.InputType=='info')">
          <!-- [tooltip]="item[key.FieldName]"  -->
          <span class="sr-only">tooltip</span>
          <i [tooltip]="tooltipTemplate" container="body" placement="top" [class]="key.Cssnames" [ngStyle]="key.Style"
            (click)="ctrlClick()"></i>
        </ng-container>
      </li>
    </ul>
  </ng-container>

  <!-- icon to be displayed based on fieldvalues from list of icons -->
  <ng-container
    *ngIf="key.Control.IconList != undefined && key.Control.IconList != null && key.Control.IconList.length > 0">
    <ng-container *ngIf="(key.Control.InputType=='action')">
      <ul class="actionList noLabel d-inline">
        <ng-container *ngFor='let icondet of getIconCSSClassList()'>
          <li>
            <!-- [ngStyle]="{'background-color':'#7d8b90'}" -->
            <ng-container *ngIf="enableAction()">
              <a [attr.aria-label]="icondet?.Tooltip" href="javascript:;">
                <i [class]="icondet.IconCSS" [ngStyle]="key.Style" (click)="ctrlIconClick(icondet)"
                  [tooltip]="icondet.Tooltip" container="body" placement="top"></i></a>
            </ng-container>
            <ng-container *ngIf="!enableAction()">
              <a [attr.aria-label]="icondet?.Tooltip"  class="disabled">
                
                <i [class]="icondet.IconCSS" [tooltip]="icondet.Tooltip" container="body"
                  placement="top"></i></a>
            </ng-container>

          </li>
        </ng-container>
      </ul>
    </ng-container>

    <ng-container *ngIf="(key.Control.InputType=='info')">
      <ul class="actionList noLabel">
        <li>
          <a [attr.aria-label]="key.Control?.InputType" href="javascript:this.blur();"><i [tooltip]="tooltipTemplate" container="body" placement="top" [class]="getIconCSSClass()" [ngStyle]="key.Style"
            (click)="ctrlClick()"></i></a>
        </li>
      </ul>
    </ng-container>
  </ng-container>

</ng-container>

<ng-container *ngIf="key.Control.Type == 'badge'">
  <!-- No list -->
  <ng-container
    *ngIf="key.Control.IconList == undefined || key.Control.IconList == null || key.Control.IconList.length == 0">
    <div (click)="ctrlClick()">
      <span
        *ngIf="(key.Control.InputType == null || key.Control.InputType == undefined || key.Control.InputType.length == 0 || key.Control.InputType =='text')"
        [class]="key.Cssnames" [ngStyle]="key.Style" [tooltip]="tooltipTemplate">{{item[key.FieldName] |
        translate}}</span>
      <span
        *ngIf="(key.Control.InputType != null && key.Control.InputType != undefined && key.Control.InputType =='number')"
        [class]="key.Cssnames" [ngStyle]="key.Style" [tooltip]="tooltipTemplate">{{item[key.FieldName]}}</span>
    </div>
  </ng-container>

  <!-- badge to be displayed based on fieldvalues from list -->
  <ng-container
    *ngIf="key.Control.IconList != undefined && key.Control.IconList != null && key.Control.IconList.length > 0">
    <ng-container *ngFor='let icondet of getIconCSSClassList()'>
      <div (click)="ctrlClick()">
        <span
          *ngIf="(key.Control.InputType == null || key.Control.InputType == undefined || key.Control.InputType.length == 0 || key.Control.InputType =='text')"
          [class]="icondet.IconCSS" [ngStyle]="key.Style" [tooltip]="tooltipTemplate">{{item[key.FieldName] |
          translate}}</span>
        <span
          *ngIf="(key.Control.InputType != null && key.Control.InputType != undefined && key.Control.InputType =='number')"
          [class]="icondet.IconCSS" [ngStyle]="key.Style" [tooltip]="tooltipTemplate">{{item[key.FieldName]}}</span>
      </div>
    </ng-container>
  </ng-container>

</ng-container>

<ng-container *ngIf="key.Control.Type == 'dataimg' && item[key.FieldName]!=null && item[key.FieldName]!=undefined">
  <img alt="Capgemini Logo" [src]="sanitizer.bypassSecurityTrustResourceUrl(item[key.FieldName])" (click)="ctrlClick()" />
</ng-container>

<!-- Notification section -->
<div *ngIf="displayNotifybox">
  <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
       (close)="closeNotifybox()"></notification>
</div>


<!-- This code is only for Milkyway -->
<!--<div *ngIf="pagedisplay==true">
  <modal #modalchild [modaldata]="compdata" (close)="closePageTab()"></modal>
</div>-->

<style>
  p {
    margin-bottom: 0px;
  }

  .theme-default .bs-datepicker-head {
    background-color: #fbfbfb
}
  
.bs-datepicker-head button.current {
    border-radius: 15px;
    max-width: 155px;
    color: black;
}

.bs-datepicker-head button.next span, .bs-datepicker-head button.previous span {
    font-size: 28px;
    line-height: 1;
    display: inline-block;
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    color: #0070ad;
}

.theme-default .bs-datepicker-body table td span.selected, .theme-default .bs-datepicker-body table td.selected span, .theme-default .bs-datepicker-body table td span[class*=select-]:after, .theme-default .bs-datepicker-body table td[class*=select-] span:after {
    background-color: #0070ad;
}

  /* For Hiding spinner in Input number */
  /* For Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  /* Webkit browsers like Safari and Chrome */
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>