export class UserRoleDetail {
    UserRoleId: number = 0;
    AppId: string = '';
    ProcessId: string = '';
    TenantId: string = '';
    RoleId: number = 0;
    UserId: number = 0;
    UserStatus:boolean =true;
    ValidFromDate?:Date = new Date('2021-10-26 00:00:00.000');
    ValidTillDate?:Date = new Date('2099-12-31 00:00:00.000');
    IsEditable: number = 1;
    IsModified: number = 0;
    RoleIds: string = "";
    AccountIds: string = "";
    ClientreferenceNumber :string = "";

    constructor(init?: Partial<UserRoleDetail>) {
        Object.assign(this, init);
    }
}