export class FilterMaster 
{
    CandidateId: string = '';
    Name: string = '';
    VendorName: string = '';
    VendorcaserefNo: string = '';
    AccountName: string = '';
    Hiretype: string = '';
    LastMonth: string = '';
    InitiationDate: string = '';
    BGVStatus: string = '';
    IsEditable: number = 1;
    FromDate: string = '';
    ToDate: string = '';
    Entity: string = '';
    
    constructor(init?: Partial<FilterMaster>) {
        Object.assign(this, init);
    }
}
