<div class="h-100">
    <nav aria-label="breadcrumb" class="breadcrumbSection">
        <div class="d-flex w-100 justify-content-between">
            <ul class="breadcrumb m-0 flex-fill">
                <!-- <li class="breadcrumb-item"><a>Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Initiate BGV</li> -->
            </ul>
            <div class="align-self-center breadcrumb m-0">
                <div class="px-2">
                    <span class="text-success me-1"><i class="fa fa-circle" aria-hidden="true"></i></span>
                    Positive
                </div>
                <div class="px-2">
                    <span class="text-warning me-1"><i class="fa fa-circle" aria-hidden="true"></i></span>
                    Minor Discrepancy
                </div>
                <div class="px-2">
                    <span class="text-danger me-1"><i class="fa fa-circle" aria-hidden="true"></i></span>Discrepancy
                </div>
            </div>
        </div>
    </nav>
    <div class="contentScroll p-2 simpleCard">
        <ng-container
            *ngIf="(!displayBGVGrid && InitiateSearchTemplate != null && InitiateSearchTemplate != undefined && InitiateSearchTemplate.length > 0)">
            <searchby [SearchButtons]="InitiateProperties.InitiateSearchButtons"
                [SearchTemplate]="InitiateSearchTemplate" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                [FormProp]="FormProp" (SearchData)="SearchData($event,false,true)" class="customForm">
            </searchby>

            <div class="bg-light p-3">
                <p class="text-bold"></p>
                <div  class="form-inline">
                <ng-container>
                     <label class="form-label">Initiate Project BGV cases in Bulk</label>
                    
                      
                        <input class="form-control form-control-sm" id="inputfile" type="file" 
                            (change)="handleUpload($event)" accept="UploadType" />
                            
                            <label for="inputfile" class="sr-only">UploadType</label>
                   
                            <button type="button" class="btnCustom btnPrimary rounded-pill" (click)="uploadFiles()"
                                value="{{'Upload' | translate }}">{{'Upload' | translate }}
                            </button>
                   
                            <!-- <a type="button" class="btn btn-success" (click)="downloadExcelfile()">Download File</a> -->


                                <a href="javascript:this.blur();" class="actionList_a" aria-label="Download Excel" (click)="downloadExcelfile()">
                                             <span><u>Download Sample Excel</u></span>
                                        </a>
                                        <div style="color: darkgray;">
                                            <h6><u>NOTE</u></h6>
<h6>NewAccount  - Should be mentioned as per the drop down details available in the BGV application.</h6>
<h6>DateOfJoining - format to be MM-DD-YYYY</h6>
<h6>Kindly check duplicates before uploading the excel as it might incur cost.</h6>
<h6>ProfessionalEmailId - Capgemini email id to be mentioned for all the joined resources.</h6>
<h6>Cases that have been uploaded will be flown in  BGV the day after the upload.</h6>
                                        </div>
                                     
                </ng-container>
                </div>
            </div>
        </ng-container>

        <!-- <ng-container *ngIf="(displayBGVGrid && reqListTemplate_initBGV != null && reqListTemplate_initBGV != undefined && reqListTemplate_initBGV.length > 0
            && (reqListTemplate_initBGV | filterBy: {Group: FormGroup}))">
            <div class="planInfoWraper customForm">
                <div class="filters m-0">
                    <div class="row">
                        <customform #initiateSearchForm
                            [FormTemplate]="(reqListTemplate_initBGV | filterBy: {Group: FormGroup})"
                            [FormData]="initiateFormData" [FormProp]="InitiateFormProp" [FormConfig]="FormConfig"
                            [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)"
                            (FieldChange)="onChange($event)">
                        </customform>
                    </div>
                </div>
            </div>
        </ng-container> -->

        <div class="card h-100 m-0">
            <ng-container
                *ngIf="(!displayBGVGrid && searchEvent.item !=null && searchEvent.item != undefined && 
                            searchEvent.item.Value !=null && searchEvent.item.Value != undefined && searchEvent.item.Value.length > 0)">
                <div class="card-header">
                    <div class="d-flex w-100 justify-content-between">
                        <h3>
                            <span>Search Results:
                                <span class="text-primary ps-2">{{dataTotalCount}} Records Found</span></span>
                        </h3>
                    </div>
                </div>
            </ng-container>
            <div class="card-body overflow-auto p-0">
                <div class="contentScroll p-3">

                    <ng-container
                        *ngIf="(!displayBGVGrid && reqListTemplate != null && reqListTemplate != undefined &&  reqListTemplate.length > 0)">
                        <grid-dispctrl #initiategrid [FieldTemplate]="reqListTemplate" [GridData]="reqListData"
                            [GridProp]="GridProp" [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                            (FieldChange)="onChange($event)" (ActionBarClick)="actButtonClick($event)">
                        </grid-dispctrl>


                    </ng-container>

                    <ng-container *ngIf="displayBGVGrid">
                        <div class="row small-gutter">
                            <div class="form-group">
                                <customform #initiateForm
                                    [FormTemplate]="(reqListTemplate_initBGV | filterBy: {Group: 'Initiate_Case'})"
                                    [FormData]="initiateFormData" [FormProp]="InitiateBGV_FormProp"
                                    [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                                    (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                </customform>
                            </div>
                        </div>

                        <div class="row small-gutter">
                            <div class="form-group">
                                <customform #initiateSearchForm
                                    [FormTemplate]="(reqListTemplate_initBGV | filterBy: {Group: FormGroup})"
                                    [FormData]="initiateFormData" [FormProp]="InitiateFormProp"
                                    [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                                    (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                </customform>
                            </div>
                        </div>

                        <!-- <ng-container *ngIf="(reqListTemplate_initBGV != null && reqListTemplate_initBGV != undefined && reqListTemplate_initBGV.length > 0
                            && (reqListTemplate_initBGV | filterBy: {Group: FormGroup}))">
                            <div class="planInfoWraper customForm">
                                <div class="filters m-0">
                                    <div class="row">
                                        <customform #initiateSearchForm
                                            [FormTemplate]="(reqListTemplate_initBGV | filterBy: {Group: FormGroup})"
                                            [FormData]="initiateFormData" [FormProp]="InitiateFormProp" [FormConfig]="FormConfig"
                                            [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)"
                                            (FieldChange)="onChange($event)">
                                        </customform>
                                    </div>
                                </div>
                            </div>
                        </ng-container> -->
                        <ng-container *ngIf="hideshowgrid">
                        <p *ngIf="openAdminInitiateCase" class="text-bold mb-1">
                            <span *ngIf="openAdminInitiateCase">
                                Background Checks
                            </span>
                        </p>

                        <ng-container *ngIf="openAdminInitiateCase">
                            <grid-dispctrl #initiateCheckgrid [FieldTemplate]="reqListTemplate_InitBGVAdmin"
                                [GridData]="accountCheckMappingData" [GridProp]="GridProp"
                                [GridDataTotalCount]="accountCheckMappingData.length" (FieldClick)="onClick($event)"
                                (FieldChange)="onChange($event)" (ActionBarClick)="actButtonClick($event)"
                                [cellLock]="setCellLock" [parent]="this">
                            </grid-dispctrl>
                        </ng-container>
                    </ng-container>
                    </ng-container>


                </div>



                <div class="filters buttonHolder">
                    <!-- <ng-container *ngIf="openAdminInitiateCase">
                        <div class="d-flex justify-content-between">
                            Note: If you wanted to remove any of the check for
                            employee/
                            candidate you can uncheck and select </div>
                    </ng-container> -->
                    <div class="d-flex justify-content-end">

                        <div>
                            <ng-container
                                *ngIf="InitiateProperties.CaseInitiateButtons != null && InitiateProperties.CaseInitiateButtons != undefined">
                                <ng-container *ngIf="!openAdminInitiateCase && displayBGVGrid">
                                    <ng-container
                                        *ngFor="let actionBar of InitiateProperties.CaseInitiateButtons | filterBy: {IsApplicable: true,HasPermission :true, Group :'Requestor'}
                                      | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
                                        <button type="button" [ngClass]="actionBar.CustomClass"
                                            (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                            value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                            |
                                            translate
                                            }}
                                            <span *ngIf="actionBar.ShowArrow" class="ps-3"><i
                                                    class="ion ion-md-arrow-forward" aria-hidden="true"></i></span>
                                        </button>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="openAdminInitiateCase && displayBGVGrid">
                                    <ng-container
                                        *ngFor="let actionBar of InitiateProperties.CaseInitiateButtons | filterBy: {IsApplicable: true,HasPermission :true, Group :'AdminCaseInitiator'}
                                            | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">

                                        <button type="button" [ngClass]="actionBar.CustomClass"
                                            (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                            value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                            |
                                            translate
                                            }}
                                            <span *ngIf="actionBar.ShowArrow" class="ps-3"><i
                                                    class="ion ion-md-arrow-forward" aria-hidden="true"></i></span>
                                        </button>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-footer">
                <div class="d-flex justify-content-center">
                    <div class="small align-self-center">Copyright © 2022 Capgemini All rights reserved.
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>

<!-- Filter Pop-up -->
<!-- <div *ngIf="DispFilter">
    <filter *ngIf="(reqListTemplate!= undefined && reqListTemplate!= null && reqListTemplate.length > 0)"
        [FieldTemplate]="reqListTemplate" [GridData]="reqListDataALL" (close)="showFilter($event)"
        (selected)="setFilter($event)" [InitialCondn]="curFiltCondn">
    </filter>
</div> -->


<!-- Excel Download -->
<excel-export #excelexport [FileName]="ExcelFileName"></excel-export>


<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
        (selected)="setNotifyRes($event)" (close)="closeNotifybox()"></notification>
</div>

<!--Attachment section-->
<ng-container *ngIf="DisplayAttachment==true">
    <file-upload [Module]="'RP006'" [RefId1]="RequestId" [RefId2]="AttachmentItemData.CheckId" [IsMultiple]="false" (close)="closeAttachment($event)"
        [Permissions]="AttachPerm" [CandidateId]="CandidateId">
    </file-upload>
</ng-container>

<modal-popup id="RejectCase" style="display: none;" [modaldetail]="{'header':'Remarks'}">
    <ng-container *ngIf="(RejectCaseTemplate !=null && RejectCaseTemplate !=undefined && RejectCaseTemplate.length>0)">
        <customform #rejectCaseform [FormTemplate]="RejectCaseTemplate" [FormData]="RejectCaseData"
            [FormProp]="RejectCaseFormProp" [FormConfig]="RejectCaseFormConfig" [GlobalConfig]="GlobalConfig"
            (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
        </customform>
    </ng-container>

    <div class="">
        <div class="d-flex justify-content-end p-3">
            <button type="button" class="rounded-pill btnCustom btnNegative" (click)="closeModal('RejectCase')"
                value="{{'Cancel' | translate }}">
                {{'Cancel' | translate }}
            </button>
            <button type="button" class="rounded-pill btnCustom btnPrimary" (click)="updateRejectCaseModalPopup()"
                value="{{'Reject Case' | translate }}">
                {{'Reject Case' | translate }}
            </button>
        </div>
    </div>

    <!-- Notification section -->
    <div *ngIf="displayRejectCaseNotifybox">
        <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
            (selected)="setNotifyRes($event)" (close)="closeRejectCaseNotifybox() "></notification>
    </div>

</modal-popup>

<!--using modal popup component-->

<modal-popup id="ViewPreviousChecks" style="display: none;" [modaldetail]="modalInf">
    <ng-container *ngIf="PreviousDetailsFlag">
        <request #requestcmp [caseId]="caseId">
        </request>
    </ng-container>
</modal-popup>