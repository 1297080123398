export class VendorAssignment {
    TransactionID: number = 0;
    VendorId: number = 0;
    BGVType: string = '';
    HireType: string = '';
    Account: string = '';
    CompanyCode: string = '';
    CandidateDigits: string = '';
    RowStatus: string = 'A'
    CreatedBy: string = ''
    CreatedDate: string = ''
    UpdatedBy: string = ''
    UpdatedDate: string = ''
    CreatedFromIP: string = ''
    IsEditable: number = 1;
    IsModified: number = 0;
    Index: number = 0;
    IsValid: boolean = true;
    Action: string = "1";

    constructor(init?: Partial<VendorAssignment>) {
        Object.assign(this, init);
    }
}
