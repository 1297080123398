<!-- <div class="container-fluid"> -->
<div class="">
    <!-- <div class="row">
        <input type="file" class="form-control" id="importFile"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel.sheet.macroEnabled.12"
            style="width:300px" />
    </div> -->

    <!-- Sheet tabs -->
    <ul *ngIf="workbook" class="nav nav-tabs flex-nowrap" role="tablist">
        <li class="nav-item" *ngFor="let sheet of workbook.sheets; let i = index">
            <a class="nav-link" href="#" role="tab" [ngClass]="{ active: i == sheetIndex }"
                [attr.aria-label]="sheet.name"
                (click)="tabClicked($event, i)">{{sheet.name}}</a>
        </li>
    </ul>

    <!-- Current sheet view -->
    <div id="tableHost"></div>
</div>