import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ActionCode, ProcessResponse } from '../app.interface';
import { environment } from '../../environments/environment';
import { CheckInfo, UpdateCheckInfo } from './check/check.interface';
import { AppSharedService, isNullOrUndefined } from '../shared/app.sharedservice';


@Injectable()
export class RequestService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private APIUrl: string;
  private RequestUrl: string;
  private CandidateUrl: string;
  private VendorUrl: string;
  private CheckUrl: string;
  private FileManagementUrl: string;
  private LogUrl: string;
  private MailerURL: string;
  private ReportUrl: string;
  private UserURL: string;
  
  constructor(private _http: HttpClient, private _appSharedService: AppSharedService) {
    this.APIUrl = environment.baseURL + '/Home/';

    this.RequestUrl = environment.apiURL.RequestURL;
    this.CandidateUrl = environment.apiURL.CandiateURL;
    this.VendorUrl = environment.apiURL.VendorURL;
    this.CheckUrl = environment.apiURL.CheckURL;
    this.FileManagementUrl = environment.apiURL.FileManagementURL;
    this.LogUrl = environment.apiURL.LogURL;
    this.MailerURL = environment.apiURL.MailerURL;
    this.ReportUrl = environment.apiURL.ReportURL;
    this.UserURL = environment.apiURL.UserURL;
  }

  private handleError(error: any): Promise<any> {
    console.error('Error : ', error.message);
    return Promise.reject(error.message || error);
  }


  getCourtRecordExcelDetails(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('requestId', odata.requestId);
    Params = Params.append('checkId', odata.checkId);
    Params = Params.append('documentTypeId', odata.documentTypeId);

    let response = this._http.get<ProcessResponse<any>>(this.CheckUrl + 'GetCourtRecordExcelDetails',
      { headers: this.headers, params: Params });
    return response;
  }
  getAddressExcelDetails(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('requestId', odata.requestId);
    Params = Params.append('checkId', odata.checkId);
    Params = Params.append('documentTypeId', odata.documentTypeId);

    let response = this._http.get<ProcessResponse<any>>(this.CheckUrl + 'GetAddressExcelDetails',
      { headers: this.headers, params: Params });
    return response;
  }

  // getCourtRecordExcelDetails(odata: any): Observable<ProcessResponse<any>> {
  //   let Params = new HttpParams();
  //   Params = Params.append('requestId', odata.requestId);
  //   Params = Params.append('checkId', odata.checkId);
  //   let response = this._http.get<ProcessResponse<any>>(this.LogUrl + 'GetCourtRecordExcelDetails',
  //     { headers: this.headers, params: Params });
  //   return response;
  // }

  uploadCourtRecordExcel(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams(); 
    Params = Params.append('requestId', odata.RequestId);
     Params = Params.append('checkId', odata.CheckId);
     Params = Params.append('SubCheckId', odata.SubCheckId);
     Params = Params.append('AccountId', odata.AccountId);   
    let headers1 = new HttpHeaders();
    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'UploadCourtRecordList',
        odata, { headers: headers1, params: Params, reportProgress: true })
        .pipe(
            catchError(this.handleError)
        );

}
uploadAddressExcel(odata: any): Observable<ProcessResponse<any>> {
  let Params = new HttpParams(); 
  Params = Params.append('requestId', odata.RequestId);
     Params = Params.append('checkId', odata.CheckId);
     Params = Params.append('SubCheckId', odata.SubCheckId);
     Params = Params.append('AccountId', odata.AccountId);    
  let headers1 = new HttpHeaders();
  return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'UploadAddressList',
      odata, { headers: headers1, params: Params, reportProgress: true })
      .pipe(
          catchError(this.handleError)
      );

}
downloadSampleExcel(): Observable<any> {
  let Params = new HttpParams();

  return this._http.get(this.CheckUrl + 'DownloadSampleExcel', 
      { headers: this.headers, params: Params, responseType: 'blob' })
      .pipe(map(res => {
          return {
              data: res
          };
      }))
      .pipe(
          catchError(this.handleError)
      );
}
downloadSampleAddressExcel(): Observable<any> {
  let Params = new HttpParams();

  return this._http.get(this.CheckUrl + 'DownloadSampleAddressExcel', 
      { headers: this.headers, params: Params, responseType: 'blob' })
      .pipe(map(res => {
          return {
              data: res
          };
      }))
      .pipe(
          catchError(this.handleError)
      );
}
  getRequestDetails(odata: any, requestId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('RequestId', requestId);

    let response = this._http.get<ProcessResponse<any>>(this.RequestUrl + 'GetRequestDetails',
      { headers: this.headers, params: Params });
    return response;
  }

  getCandidateDetails(odata: any, candidateId: number, requestId: number = 0): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('CandidateId', candidateId);
    Params = Params.append('RequestId', requestId);

    let response = this._http.get<ProcessResponse<any>>(this.CandidateUrl + 'GetCandidatesDetails',
      { headers: this.headers, params: Params });
    return response;
  }

  getVendorDetails(odata: any, vendorId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('VendorId', vendorId);

    let response = this._http.get<ProcessResponse<any>>(this.VendorUrl + 'GetVendorDetails',
      { headers: this.headers, params: Params });
    return response;
  }

  getOverallStatusDetails(odata: any, requestId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('requestId', requestId);

    let response = this._http.get<ProcessResponse<any>>(this.CheckUrl + 'GetRequestCheckDetails',
      { headers: this.headers, params: Params });
    return response;
  }

  requestStopCase(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'RequestStopCase?requestId=' + odata.RequestId + '&comments=' + odata.Comments,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  requestStopCaseByQC(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'RequestStopCaseByQC', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  downloadResume(candidateId: number): Observable<any> {
    let Params = new HttpParams();
    // Params = Params.append('CandidateId', candidateId);
    return this._http.post(this.CandidateUrl + 'DownloadFile', candidateId, { headers: this.headers, params: Params, responseType: 'blob' })
      .pipe(map(res => {
        return {
          data: res
        };
      }))
      .pipe(
        catchError(this.handleError)
      );
  }

  downloadIRF(candidateId: number): Observable<any> {
    let Params = new HttpParams();
    return this._http.post(this.CandidateUrl + 'DownloadIRF', candidateId, { headers: this.headers, params: Params, responseType: 'blob' })
      .pipe(map(res => {
        return {
          data: res
        };
      }))
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllAttachmentList(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('requestId', odata.requestId);
    Params = Params.append('checkId', odata.checkId);
    Params = Params.append('documentTypeId', odata.documentTypeId);

    let response = this._http.get<ProcessResponse<any>>(this.CheckUrl + 'GetFileAttachmentList',
      { headers: this.headers, params: Params });
    return response;
  }

  getMandatoryDocuments(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('requestId', odata.requestId);
    //// Params = Params.append('checkId', odata.checkId);
    //// Params = Params.append('documentTypeId', odata.documentTypeId);

    let response = this._http.get<ProcessResponse<any>>(this.RequestUrl + 'GetMandatoryDocuments',
      { headers: this.headers, params: Params });
    return response;
  }

  // downloadAttachment(odata: any): Observable<ProcessResponse<any>> {
  downloadAttachment(odata: any): Observable<any> {
    let Params = new HttpParams();

    return this._http.post(this.FileManagementUrl + 'Downloadfiles', odata,
      { headers: this.headers, params: Params, responseType: 'blob' })
      .pipe(map(res => {
        return {
          data: res
        };
      }))
      .pipe(
        catchError(this.handleError)
      );
  }

  getCheckDocumentDetailsList(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('requestId', odata.requestId);
    Params = Params.append('checkId', odata.checkId);
    Params = Params.append('flag', odata.flag);


    let response = this._http.get<ProcessResponse<any>>(this.CheckUrl + 'GetCheckDocumentDetails',
      { headers: this.headers, params: Params });
    return response;
  }

  getCheckDetails(odata: any, requestId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('requestId', odata.requestId);
    Params = Params.append('checkId', odata.checkId);

    let response = this._http.get<ProcessResponse<any>>(this.RequestUrl + 'GetCheckDetails',
      { headers: this.headers, params: Params });
    return response;
  }
  GetInsufficiencyDetails(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('requestId', odata.requestId);
    Params = Params.append('checkId', odata.checkId);

    let response = this._http.get<ProcessResponse<any>>(this.CheckUrl + 'GetInsufficiencyDetails',
      { headers: this.headers, params: Params });
    return response;
  }
  UpdateCheck(odata: CheckInfo[]): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'UpdateRequestCheckDocumentStatus', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  StopCheck(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'StopCheck', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  ReinitiateCase(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'ReinitiateCase', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  //client reference number modal popup
  updateClientReferenceModalPopup(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'UpdateClientReference', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }
  updateNonGreenComponent(odata: any,isSBUFieldsModified:any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    let endPoint='UpdateNonGreenComponent';
    if(isSBUFieldsModified){
      endPoint='UpdateNonGreenComponentSBU'
    }
    return this._http.post<ProcessResponse<any>>(this.CheckUrl + endPoint, odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
      }

  UpdateOverallStatus(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'UpdateOverallCaseStatus', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  UpdateCase(requestId: number, actionCode: any = null, checkId: number = 0, documentTypeId: number = 0): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    actionCode = !isNullOrUndefined(actionCode) && actionCode !== '' ? actionCode : ActionCode.Update_Check;
    checkId = !isNullOrUndefined(checkId) && checkId !== 0 ? checkId : 0;
    documentTypeId = !isNullOrUndefined(documentTypeId) && documentTypeId !== 0 ? documentTypeId : 0;
    Params = Params.append('requestId', requestId);
    Params = Params.append('actioncode', actionCode);
    Params = Params.append('roleId', this._appSharedService.currentRoleData.RoleId);

    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'UpdateRequestStatus?requestId=' + requestId + '&actioncode=' + actionCode + '&roleId=' + this._appSharedService.currentRoleData.RoleId + '&CheckId=' + checkId + '&DocumentTypeId=' + documentTypeId,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  UpdateCaseProjInit(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    let requestId=odata[0].RequestId;
    let employeeNumber=odata[0].EmployeeNumber;
    let accountId=odata[0].AccountId;
    let personalEmailId=odata[0].PersonalEmailId;
    let pONumber=odata[0].PONumber;
    let requestEntity=odata[0].RequestEntity;
    

    return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'UpdateCaseProjInit?requestId=' + requestId + '&employeeNumber=' + employeeNumber + '&accountId=' + accountId +'&personalEmailId=' + personalEmailId +'&pONumber=' + pONumber +'&requestEntity=' + requestEntity,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteAttachment(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.FileManagementUrl + 'DeleteFiles', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getCodeList(codeType: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('codeType', codeType);

    let response = this._http.get<ProcessResponse<any>>(this.CheckUrl + 'GetCodeList',
      { headers: this.headers, params: Params });
    return response;
  }

  submitReport(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'ReportSubmit', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  downloadALLAttachment(odata: any): Observable<any> {
    let Params = new HttpParams();
    Params = Params.append('requestId', odata.requestId);
    // Params = Params.append('checkIdList', odata.checkIdList);
    // Params = Params.append('subDocTypeList', odata.subDocTypeList);
    // Params = Params.append('reference2List', odata.reference2List);
    // return this._http.post(this.FileManagementUrl + 'DownloadOverallDocuments',
    return this._http.post(this.CheckUrl + 'DownloadOverallDocuments', odata,
      // return this._http.post(this.CheckUrl + 'DownloadOverallDocuments?requestId=' + odata.requestId
      //   + '&checkIdList=' + odata.checkIdList + '&subDocTypeList=' + odata.subDocTypeList + '&reference2List=' + odata.reference2List,
      { headers: this.headers, params: Params, responseType: 'blob' })
      .pipe(map(res => {
        return {
          data: res
        };
      }))
      .pipe(
        catchError(this.handleError)
      );
  }

  DownloadSelectedDocuments(odata: any): Observable<any> {
    let Params = new HttpParams();
    return this._http.post(this.CheckUrl + 'DownloadSelectedDocuments', odata,
      { headers: this.headers, params: Params, responseType: 'blob' })
      .pipe(map(res => {
        return {
          data: res
        };
      }))
      .pipe(
        catchError(this.handleError)
      );
  }

  ReportDownload(odata: any): Observable<any> {
    let Params = new HttpParams();
    return this._http.post(this.CheckUrl + 'ReportDownload', odata,
      { headers: this.headers, params: Params, responseType: 'blob' })
      .pipe(map(res => {
        return {
          data: res
        };
      }))
      .pipe(
        catchError(this.handleError)
      );
  }
  getCourtRecordExcelList(): Observable<ProcessResponse<any>> {
    return this._http.get<ProcessResponse<any>>(this.UserURL + 'GetCourtRecordExcelDetails',
      { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      );
  }
  // getCourtRecordExcelList(odata: any): Observable<ProcessResponse<any>> {
  //   let Params = new HttpParams();
  //   Params = Params.append('requestId', odata.requestId);
  //   Params = Params.append('checkId', odata.checkId);
  //   let response = this._http.get<ProcessResponse<any>>(this.LogUrl + 'GetCourtRecordExcelDetails',
  //     { headers: this.headers, params: Params });
  //   return response;
  // }
  getActivityLogList(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('requestId', odata.requestId);
    Params = Params.append('documentTypeId', odata.documentTypeId);
    Params = Params.append('checkId', odata.checkId);
    let response = this._http.get<ProcessResponse<any>>(this.LogUrl + 'GetActivityLog',
      { headers: this.headers, params: Params });
    return response;
  }

  SubmitQC(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'UpdateQCDocumentStatus', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  submitCaseClose(requestId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'RequestCloseCase?requestId=' + requestId,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getNonGreenadditionalSectionList(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('requestId', odata.requestId);

    
    let response = this._http.get<ProcessResponse<any>>(this.LogUrl + 'GetActivityLog',
      { headers: this.headers, params: Params });
    return response;
  }

  RaiseL3Insufficiency(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'RaiseL3Insufficiency', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  ResponseToEmployee(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'ResponseToEmployee', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  NGDetailsforMail(odata: any,Actioncode:string): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('Action', Actioncode);
    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'NGDetailsforMail', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }
  getCheckMetaDataList(odata: any, methodName: string): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('requestId', odata.requestId);
    Params = Params.append('checkId', odata.checkId);

    let response = this._http.get<ProcessResponse<any>>(this.CheckUrl + 'GetMetaDataList',
      { headers: this.headers, params: Params });
    return response;
    // let response = this._http.get<ProcessResponse<any>>(this.CheckUrl + methodName,
    //   { headers: this.headers, params: Params });
    // return response;
  }

  getCheckGapList(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('requestId', odata.RequestId);
    Params = Params.append('checkId', odata.CheckId);

    let response = this._http.get<ProcessResponse<any>>(this.CheckUrl + 'GetCheckGapList',
      { headers: this.headers, params: Params });
    return response;
    // let response = this._http.get<ProcessResponse<any>>(this.CheckUrl + methodName,
    //   { headers: this.headers, params: Params });
    // return response;
  }
  getNGDiscrepantComponentList(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('requestId', odata.RequestId);
    Params = Params.append('checkId', odata.CheckId);

    let response = this._http.get<ProcessResponse<any>>(this.CheckUrl + 'GetNGDiscrepantComponentList',
      { headers: this.headers, params: Params });
    return response;
  }

  getDiscrepancyComponentStatus(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('requestId', odata.RequestId);

    let response = this._http.get<ProcessResponse<any>>(this.CheckUrl + 'GetDiscrepancyComponentStatus',
      { headers: this.headers, params: Params });
    return response;
  }

  updateSaveAttachment(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'AddFileAttachmentList', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  SubmitBUHR(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'UpdateBUHRDocumentStatus', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }
  SubmitBGVHeadandCentralHR(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'UpdateBGVHeadandCentralHRDocumentStatus', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }
  getEmpDetailsBySearchForInitiateBGV(odata: any, allPendingFlag: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('AllPendingFlag', allPendingFlag);

    return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'GetEmpDetailsBySearchForInitiateBGV', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  // getAccountCheckMappingList(odata: any): Observable<ProcessResponse<any>> {
  //   let Params = new HttpParams();
  //   Params = Params.append('empnumber', odata.EmployeeNumber);
  //   Params = Params.append('AccountID', odata.AccountId);
  //   return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'GetAccountCheckMapping',
  //     { headers: this.headers, params: Params })
  //     .pipe(
  //       catchError(this.handleError)
  //     );
  // }

  getAccountCheckMappingList(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('empnumber', odata.EmployeeNumber);
    Params = Params.append('AccountID', odata.AccountId);

    let response = this._http.get<ProcessResponse<any>>(this.CheckUrl + 'GetAccountCheckMapping',
      { headers: this.headers, params: Params });
    return response;
  }

  initiateCase(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'InitiateBGV', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }
  GetMailTransaction(requestId: number, emailType: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('requestId', requestId);
    Params = Params.append('emailType', emailType);
    let response = this._http.get<ProcessResponse<any>>(this.MailerURL + 'GetMailTransaction',
      { headers: this.headers, params: Params });
    return response;
  }


  async getRequestDetailsBySearch(odata: any): Promise<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('AllPendingFlag', false);

    let response = await this._http.post<ProcessResponse<any>>(this.RequestUrl + 'GetRequestDetailsBySearch', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      ).toPromise();

    return response;

  }
  async getRoleDetailsSearchFilter(odata: any): Promise<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('AllPendingFlag', false);

    let response = await this._http.post<ProcessResponse<any>>(this.RequestUrl + 'GetRoleDetailsSearchFilter', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      ).toPromise();

    return response;

  }
  //client reference number modal popup
  updateLWDModalPopup(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'updateLWD', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  InitiateToEmployee(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'InitiateToEmployee', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }


  SendMailTransaction(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //  Params = Params.append('emailType', emailType);
    return this._http.post<ProcessResponse<any>>(this.MailerURL + 'ProcessMail', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllFileList(requestId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('RequestId', requestId);

    let response = this._http.get<ProcessResponse<any>>(this.RequestUrl + 'GetAllFileList',
      { headers: this.headers, params: Params });
    return response;
  }

  insertDocumentType(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'AddDocumentType', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  addGap(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'AddCheckGap', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  addNonGreenComponent(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'AddNonGreenComponent', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getCheckDetailsList(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('requestId', odata.requestId);
    let response = this._http.get<ProcessResponse<any>>(this.CheckUrl + 'GetCheckStatusSummary',
      { headers: this.headers, params: Params });
    return response;
  }

  deleteDocumentType(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'DeleteDocumentType', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  insertUpdateMetaData(odata: any, MethodName: string, accountId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('AccountId', accountId);

    return this._http.post<ProcessResponse<any>>(this.CheckUrl + MethodName, odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  SubmitChecks(requestId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('requestId', requestId);

    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'SubmitChecks?requestId=' + requestId,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  //client reference number modal popup
  updateRevisedSLADate(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'updateRevisedSLADate', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  updateReadyToOnboard(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'updateReadyToOnboard', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getoverallValidationMsgData(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('requestId', odata.RequestId);
    Params = Params.append('accountId', odata.AccountId);
    Params = Params.append('checkId', odata.CheckId);

    let response = this._http.get<ProcessResponse<any>>(this.CheckUrl + 'GetRequestCheckValidationMessage',
      { headers: this.headers, params: Params });
    return response;
  }

  SubmitChecksToVendor(requestId: number, vendorId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('requestId', requestId);
    Params = Params.append('vendorId', vendorId);

    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'SubmitChecksToVendor?requestId=' + requestId + '&vendorId=' + vendorId,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  ValidationForSubmitToVendor(requestId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('requestId', requestId);

    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'ValidationForSubmitToVendor?requestId=' + requestId,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }
  /////report request level////
  // getRequestReportDetails(requestId: number): Observable<ProcessResponse<any>> {
  //   let Params = new HttpParams();
  //   Params = Params.append('RequestId', requestId);
  //   let response = this._http.get<ProcessResponse<any>>(this.ReportUrl + 'GetRequestLevelSLAReport',
  //   { headers: this.headers, params: Params });
  //   return response;
  // }




  getRequestReportDetails(requestId: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('RequestId', requestId);

    let response = this._http.get<ProcessResponse<any>>(this.ReportUrl + 'GetRequestLevelSLAReport',
      { headers: this.headers, params: Params });
    return response;
  }

  UpdateRequestCheckGapDetails(odata: any, accountId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('AccountId', accountId);

    return this._http.post<ProcessResponse<any>>(this.CheckUrl + 'UpdateRequestCheckGapDetails', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }
  ///////get attachment details////

  GetAttachmentDetails(odata:any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('attachmentPath',odata);
    let response = this._http.get<ProcessResponse<any>>(this.MailerURL + 'GetAttachmentDetails',
      { headers: this.headers, params: Params });
    return response;
  }
  GetNGMailDetails(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('requestId', odata.requestId);
    Params = Params.append('action', odata.Action);

    let response = this._http.get<ProcessResponse<any>>(this.RequestUrl + 'GetNGMailDetails',
      { headers: this.headers, params: Params });
    return response;
  }
  SubmitNonGreenMailTransaction(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //  Params = Params.append('emailType', emailType);
    //return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'SubmitNonGreenMailTransaction', odata,
    return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'SubmitNonGreenMailTransaction',odata,
    { headers: this.headers, params: Params })
    .pipe(
      catchError(this.handleError)
    );

  }
  NGSendMailTransaction(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    debugger;  
    //  Params = Params.append('emailType', emailType);
    //return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'SubmitNonGreenMailTransaction', odata,
    return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'SubmitNonGreenMailTransaction',odata,
    { headers: this.headers, params: Params })
    .pipe(
      catchError(this.handleError)
    );

  }
GetBasicDetails(requestId: number,actionCode:any): Observable<ProcessResponse<any>> {
  let Params = new HttpParams();
  Params = Params.append('requestId', requestId);
  Params = Params.append('actionCode', actionCode); 
  let response = this._http.get<ProcessResponse<any>>(this.RequestUrl + 'GetBasicDetailsForNGForm',
    { headers: this.headers, params: Params });
  return response;
  }
SubmitNonGreenForm(odata: any): Observable<ProcessResponse<any>> {
  let Params = new HttpParams();

  return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'SubmitNonGreenForm', odata,
    { headers: this.headers, params: Params })
    .pipe(
      catchError(this.handleError)
    );
}
GetSubmittedDetails(requestId: number,actionCode:any): Observable<ProcessResponse<any>> {
  let Params = new HttpParams();
  Params = Params.append('requestId', requestId); 
  Params = Params.append('actionCode', actionCode); 
  let response = this._http.get<ProcessResponse<any>>(this.RequestUrl + 'GetSubmittedDetailsForNGForm',
    { headers: this.headers, params: Params });
  return response;
  }

  InsertMoreDetails(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'InsertMoreDetails', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetVerifiedComponentsList(requestId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('requestId', requestId); 
    let response = this._http.get<ProcessResponse<any>>(this.RequestUrl + 'GetVerifiedComponentsList',
      { headers: this.headers, params: Params });
    return response;
    }
    InsertVerifiedComponents(odata: any): Observable<ProcessResponse<any>> {
      let Params = new HttpParams();
  
      return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'InsertVerifiedComponents', odata,
        { headers: this.headers, params: Params })
        .pipe(
          catchError(this.handleError)
        );
    }
    UpdateVerifiedComponents(odata: any): Observable<ProcessResponse<any>> {
      let Params = new HttpParams();
  
      return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'UpdateVerifiedComponents', odata,
        { headers: this.headers, params: Params })
        .pipe(
          catchError(this.handleError)
        );
    }

 UpdateCheckDetails(odata: any): Observable<ProcessResponse<any>> {
      let Params = new HttpParams();
  
      return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'UpdateCheckDetails', odata,
        { headers: this.headers, params: Params })
        .pipe(
          catchError(this.handleError)
        );
    }
GetSubmittedDetailsCHRAndBGVHead(requestId: number): Observable<ProcessResponse<any>> {
      let Params = new HttpParams();
      Params = Params.append('requestId', requestId); 
      let response = this._http.get<ProcessResponse<any>>(this.RequestUrl + 'GetSubmittedDetailsforBGVHeadAndCHR',
        { headers: this.headers, params: Params });
      return response;
      }
GetBasicDetailsBGVHeadAndCHR(requestId: number): Observable<ProcessResponse<any>> {
        let Params = new HttpParams();
        Params = Params.append('requestId', requestId); 
        let response = this._http.get<ProcessResponse<any>>(this.RequestUrl + 'GetBasicDetailsBGVHeadAndCHR',
          { headers: this.headers, params: Params });
        return response;
        }
        uploadBulkProjectBGVCases(odata: any): Observable<ProcessResponse<any>> {
          let Params = new HttpParams();    
          let headers1 = new HttpHeaders();
          return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'UploadBulkProjectBGVCases',
              odata, { headers: headers1, params: Params, reportProgress: true })
              .pipe(
                  catchError(this.handleError)
              );
        
        }
        
downloadSampleExcelforBulkProjectBGVCases(): Observable<any> {
  let Params = new HttpParams();

  return this._http.get(this.RequestUrl + 'DownloadSampleExcel', 
      { headers: this.headers, params: Params, responseType: 'blob' })
      .pipe(map(res => {
          return {
              data: res
          };
      }))
      .pipe(
          catchError(this.handleError)
      );
}
}