import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined, isStringNullOrEmpty } from '../../../shared/app.sharedservice';
import { environment } from '../../../../environments/environment';
import { GenericComponent } from '../../../../app/app.genericcomponent';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ComponentCodes, ComponentConfig, ProcessStatus } from '../../../app.interface';
import { CommonAPIService } from '../../../shared/app.commonservice';
import { asEnumerable } from 'linq-es2015';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { AdminService } from '../../admin.service';
import { GridDisplayControlComponent } from 'src/modules/grid-dispctrl/grid-dispctrl.component';
import { VendorAssignment } from './manage-volume.interface';
import { ModalModule } from 'src/modules/modal/modal.module';
import { ModalService } from 'src/modules/modal/modal.service';
import { ModalComponent } from 'src/modules/modal/modal.component';

@Component({
  selector: 'app-manage-volume',
  templateUrl: './manage-volume.component.html',
  styleUrls: ['./manage-volume.component.css']
})

@AutoUnsubscribe
export class ManageVolumeComponent extends GenericComponent<VendorAssignment> implements OnInit {

  @ViewChild('infoform', { static: false }) infoform!: ModalComponent;

  public FilterData: any = {};

  public GlobalConfig: any = {};

  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };

  public FormProp: FormProperty = {
    'ColSize': 4,
    'CaptionBold': false,
    'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };

  public venListTemplate: FieldTemplate[] = [];
  public venListData: any[] = [];
  public venListDataClone: any[] = [];
  public DataInput: object = { PageIndex: 0, PageSize: 0 };
  public selectedInfoData: any;
  public curItemsPerPage: number = 10;
  public dataTotalCount: number = 0;
  public GroupByVendor: any[] = [];
  public deleteVolume: any;
  public MVProperties: any = [];

  public GridProp: any = {
    Editable: true,
    //TableCSS: 'auto',
    PaginationConfig: {
      ItemsPerPage: 10
    },
    ActionBarConfig: {
      ActionBarList: null
    }
  }
  public Config: any = {
    ActionButtons: [],
    ActionButtonsBottom: []
  };

  public vendorPermissions: any = [];
  public IsLoading: boolean = false;
  public subscriptionList$: Subscription[] = [];
  public displayNotifybox: boolean = false;
  public notify: NotificationData = new NotificationData();
  public curFiltCondn: string = '';
  public DispFilter: boolean = false;
  public AccountMaster: any[] = [];
  public CompanyCodeMaster: any[] = [];
  public BGVTypeMaster: any[] = [];
  // public DispLoader: boolean = false;

  public ExcelFileName: string = '';
  // public ExcelData: any = [];

  @ViewChildren('mvmaingrid') mvmaingrid!: QueryList<GridDisplayControlComponent>;

  constructor(private _router: Router, private _appSharedService: AppSharedService,
    private _commonAPIService: CommonAPIService, private adminService: AdminService) {

    super(VendorAssignment, _commonAPIService, adminService, "toastr");

    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe(() => {
      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);
  }

  ngOnInit() {

    if (!isNullOrUndefined(this._appSharedService.currentRoleData)) {
      this.getVolumeTemplate();
    }
  }

  getVolumeTemplate(): void {
    this.IsLoading = true;

    this.venListTemplate = [];

    let odata: ComponentConfig = {
      Component: 'Admin', ComponentCode: ComponentCodes.AdminComponent
    };

    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {
        let template = res.Data.FieldTemplate!;

        this.venListTemplate = template.filter(x => x.Group === 'ManageVolume');

        this.setCompProperties(res.Data.Properties!);

        this.vendorPermissions = res.Data.Permission;

        this.GetVendorAssignmentMasters('Account');
        this.GetVendorAssignmentMasters('BGVType');
        this.GetVendorAssignmentMasters('BGVCompanyCode');

        this.VendorHeaderData();
      }
    },
      () => {
        this.IsLoading = false;
        console.log('Error on getVolumeTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }

  setCompProperties(Properties: any[]) {
    if (!isNullOrUndefined(Properties) && Properties.length > 0) {

      this.Config.ActionButtons = (Properties.filter(x => x.Name.toLowerCase() === 'VendorManageActionButtons'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'VendorManageActionButtons'.toLowerCase())[0].JSONData.toString()) : []);

      this.Config.ActionButtonsBottom = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtonsBottom'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtonsBottom'.toLowerCase())[0].JSONData.toString()) : []);

      this.MVProperties.BlockCols = (Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase())[0].JSONData.toString()) : []);

      this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;
    }
  }

  GetVendorAssignmentMasters(codeType: any): void {

    let subscribe$: Subscription = this.adminService.getVendorAssignmentMasters(codeType).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        if (codeType == 'Account') {
          this.AccountMaster = result.Data;
        }
        if (codeType == 'BGVCompanyCode') {
          this.CompanyCodeMaster = result.Data;
        }
        if (codeType == 'BGVType') {
          //this.BGVTypeMaster = result.Data;
          this.BGVTypeMaster = result.Data.filter((item:any)=>
          item.Code !=='RT003')
        }
      }
    },
      () => {
      });
    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);
  }

  VendorHeaderData(): void {

    let subscribe$: Subscription = this.adminService.getVendorDetails().subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
        this.GroupByVendor = result.Data;

        this.VolumeData();
      } else {
        this.IsLoading = false;
      }
    },
      () => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);
  }

  VolumeData(): void {

    this.IsLoading = true;

    this.venListData = [];

    let subscribe$: Subscription = this.adminService.getVendorAssignmentDetails().subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        this.venListData = result.Data;

        this.dataTotalCount = this.venListData.length;

        console.log(this.venListData);

        // this.GroupByVendor = this.venListData
        //   .map(x => ({ VendorId: x.VendorId, VendorName: x.VendorName }))
        //   .filter((p, index, arr) => (arr.findIndex((p2) => p2.VendorId === p.VendorId) === index));

        this.venListData.forEach((item, index) => {
          item.Action = '1';
          item.IsEditable = 1;
          item.IsValid = true;
          item.Index = index;
        });
        this.IsLoading = false;
      }

      this.setActionButtonPermissions();

      this.IsLoading = false;
    },

      () => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);
  }

  setCellLock(fieldname: string, item: any, parent: ManageVolumeComponent): boolean {
    let bLock = false;
    let BlkCols: any[] = [];

    if (!isNullOrUndefined(parent.MVProperties.BlockCols) && parent.MVProperties.BlockCols.length > 0) {
      parent.MVProperties.BlockCols.forEach((blockitem: any) => {
        if (blockitem.Value === item[blockitem.Field]) {
          blockitem.BlockCols.forEach((item: any) => {
            BlkCols.push(item);
          });
        }
      });
    }

    if (BlkCols.length > 0) {
      bLock = (BlkCols.indexOf(fieldname.toUpperCase()) >= 0 ? true : false);
    }

    return bLock;
  }

  pageItemsChange() {

    if (this.curItemsPerPage > 0) {
      this.GridProp.PaginationConfig.ItemsPerPage = this.curItemsPerPage;
    }
    else {
      this.GridProp.PaginationConfig.ItemsPerPage = 100;
    }
    // reload grid data
    this.VolumeData();
  }

  DeleteVolume(): void {

    this.IsLoading = true;

    let subscribe$: Subscription = this.adminService.deleteVolume(this.deleteVolume).subscribe(result => {
      if (!isNullOrUndefined(result)) {

        this.showNotificationMessage
          (
            result.Message,
            result.Status === ProcessStatus.Success ? 1
              : result.Status === ProcessStatus.NoContent ? 2 : 3,
            '', null, '', ''
          );
      }
      this.IsLoading = false;

      if (result.Status === ProcessStatus.Success) {
        this.getVolumeTemplate();
      }
    },

      () => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);

  }

  onChange(event: any, mvSectionName?: any): void {
    console.log(event.item);
    let selitem: any = event.item;
    let itemindex: any = event.item_index;
    selitem.IsModified = 1;

    // TODO: any validations to be checked here
    switch (event.fieldname.toUpperCase()) {
      case 'BGVTYPE':

        this.changeCellLockFlag(selitem, itemindex, mvSectionName);
        break;

      case 'ACCOUNT':

        if (selitem.Account.includes("ALL")) {
          event.Account = "ALL";
        }
        break;
    }
  }

  actButtonClick(event: any, vendorId: number) {
    // console.log('click on action button - ' + event.buttonname);

    switch (event.buttonname.toUpperCase()) {
      case 'ADDNEWROW':
        this.addNewRows(vendorId);
        break;
    }
  }

  onClick(event: any): void {
    let selitem = event.item;

    switch (event.fieldname.toUpperCase()) {
      case 'ACTION':
        switch (event.icon.Value) {
          case '1':
            // Info            
            this.deleteVolume = selitem;
            if (selitem.TransactionID > 0) {
              this.showNotificationMessage('Are you sure you want to disable this mapping?',
                2, 'DeleteVolume', selitem, 'Yes', 'No');

            } else {
              selitem.IsValid = false;
            }
            break;
        }
        break;
    }
  }

  addNewRows(vendorId: number) {

    this.dataTotalCount += 1;

    let newitem = new VendorAssignment();
    newitem.VendorId = vendorId;
    newitem.IsModified = 1;
    newitem.Index = this.dataTotalCount;
    this.venListData.push(newitem);
  }

  setActionButtonPermissions(): void {
    if (!isNullOrUndefined(this.Config.ActionButtonsBottom) && this.Config.ActionButtonsBottom.length > 0) {

      this.Config.ActionButtonsBottom.forEach((item: any) => {
        if (!isNullOrUndefined(item.Name) && item.Name !== '' && item.Group == "ManageVolume") {
          item.HasPermission = false;
          //item.IsApplicable = false;

          let peritem = this.vendorPermissions.indexOf(item.Name);
          if (peritem >= 0) {
            item.HasPermission = true;
          }
        }
      });
    }
  }


  actionButtonClick(data: any) {

    if (!isNullOrUndefined(data.Name) && data.Name !== '') {
      switch (data.Name.toUpperCase()) {
        //StopCase
        case 'CANCELVOLUME':
          let modLength = this.venListData.filter(x => x.IsModified == 1).length;

          if (modLength > 0) {
            this.showNotificationMessage('Are you sure you want to cancel un-saved data?',
              2, 'CancelVolume', null, 'Yes', 'No');
          }
          else {
            this._router.navigate(['app/admin/vendor']);
          }


          break;
      }
    }
  }

  Save(): void {

    let venListData = this.venListData.filter(f => f.IsValid == true);

    if (venListData.length == 0) {
      this.showNotificationMessage('No data to submit', 2, '', null, '', '');
    }
    else if (this.validateSave()) {

      this.IsLoading = true;
      let subscribe$: Subscription = this.adminService.updateVendorAssignment(venListData).subscribe(result => {
        if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

          this.showNotificationMessage
            (
              result.Message,
              result.Status === ProcessStatus.Success ? 1
                : result.Status === ProcessStatus.NoContent ? 2 : 3,
              '', null, '', ''
            );

          if (ProcessStatus.Success) {
            setTimeout(() => {
              this._router.navigate(['app/admin/vendor']);
              this.IsLoading = false;
            }, 2000);
          } else {
            this.IsLoading = false;
          }
        }

        this.IsLoading = false;
      },

        () => {
          this.IsLoading = false;
        });

      this.subscriptionList$.push(subscribe$);
      console.log(this.subscriptionList$);

    }
  }


  validateSave(): boolean {

    var bool: boolean = true;

    let Data = JSON.stringify(this.venListData.filter(f => f.RowStatus == 'A' && f.IsValid == true));

    this.venListDataClone = JSON.parse(Data.toString());

    this.venListDataClone.filter(x => x.IsModified == 1 && x.IsValid == true).forEach((item) => {

      var str = "";
      if (bool) {

        if (item.BGVType != 'RT003') {// Other than Account Transfer

          // var list = this.venListDataClone.filter(x =>
          // (
          //   x.CompanyCode == item.CompanyCode && x.BGVType == item.BGVType && x.Index != item.Index)
          // );
          // bool = this.duplicateCandidate(list, str, item);

          item.CompanyCode.split(",").forEach((comItem: any) => {
            if (bool) {
              var list = this.venListDataClone.filter(x =>
              (
                x.BGVType == item.BGVType && x.CompanyCode.includes(comItem) && x.Index != item.Index)
              );
              bool = this.duplicateCandidate(list, str, item);
            }
          });

          var list = this.venListDataClone.filter(x =>
          (
            x.CompanyCode == item.CompanyCode && x.BGVType == item.BGVType)
          );

          if (bool) {
            let digits: string = "";
            for (let i = 0; i < 10; i++) {

              let canDigits = this.venListDataClone.filter(x => x.CompanyCode == item.CompanyCode && x.BGVType == item.BGVType && x.CandidateDigits.includes(i)).length;

              if (canDigits == 0) {
                digits += digits == "" ? i.toString() : ", " + i.toString();
                bool = false;
              }
            }
            if (!bool) {

              let BGVTypeName = this.BGVTypeMaster.filter(f => f.Code == item.BGVType);
              let CompanyName = this.CompanyCodeMaster.filter(f => f.Code == item.CompanyCode);

              this.showNotificationMessage('The candidate digit(s) ' + digits + ' is not mapped to any vendor for this combination. BGV Type : ' + BGVTypeName[0].CodeDescription + ' and Company Name : ' + CompanyName[0].CodeDescription + '', 2, '', null, '', '');
            }
          }

        }
        else { // For Account Transfer


          if (item.Account == "ALL") {
            var list = this.venListDataClone.filter(x =>
            (
              x.BGVType == item.BGVType && x.Index != item.Index)
            );

            bool = this.duplicateCandidate(list, str, item);

            if (bool) {
              let digits: string = "";
              for (let i = 0; i < 10; i++) {

                let canDigits = this.venListDataClone.filter(x => x.BGVType == item.BGVType && x.CandidateDigits.includes(i)).length;

                if (canDigits == 0) {
                  digits += digits == "" ? i.toString() : ", " + i.toString();
                  bool = false;
                }
              }
              if (!bool) {

                let BGVTypeName = this.BGVTypeMaster.filter(f => f.Code == item.BGVType);
                let AccountName = this.AccountMaster.filter(f => f.Code == item.Account);

                this.showNotificationMessage('The candidate digit(s) ' + digits + ' is not mapped to any vendor for this combination. BGV Type : ' + BGVTypeName[0].CodeDescription + ' and Account : ' + AccountName[0].CodeDescription + '', 2, '', null, '', '');
              }
            }
          }

          else {

            var list = this.venListDataClone.filter(x =>
            (
              x.BGVType == item.BGVType && x.Account == "ALL" && x.Index != item.Index)
            );

            if (list.length > 0) {
              bool = this.duplicateCandidate(list, str, item);
            }

            if (bool) {
              item.Account.split(",").forEach((accItem: any) => {
                str = "";
                if (bool) {
                  var list = this.venListDataClone.filter(x =>
                  (
                    x.BGVType == item.BGVType && x.Account.includes(accItem) && x.Index != item.Index)
                  );
                  bool = this.duplicateCandidate(list, str, item);

                  if (bool) {
                    let digits: string = "";

                    let canDigitsForALLType = this.venListDataClone.filter(x => x.BGVType == item.BGVType && x.Account == "ALL");

                    canDigitsForALLType.forEach((itm: any) => {
                      str += str == "" ? itm.CandidateDigits : "," + itm.CandidateDigits;
                    });

                    for (let i = 0; i < 10; i++) {

                      if (!str.split(",").includes(i.toString())) {
                        let canDigits = this.venListDataClone.filter(x => x.BGVType == item.BGVType && x.Account.includes(accItem) && x.CandidateDigits.includes(i)).length;

                        if (canDigits == 0) {
                          digits += digits == "" ? i.toString() : ", " + i.toString();
                          bool = false;
                        }
                        if (!bool) {
                          let BGVTypeName = this.BGVTypeMaster.filter(f => f.Code == item.BGVType);
                          let AccountName = this.AccountMaster.filter(f => f.Code == accItem);

                          this.showNotificationMessage('The candidate digit(s) ' + digits + ' is not mapped to any vendor for this combination. BGV Type : ' + BGVTypeName[0].CodeDescription + ' and Account : ' + AccountName[0].CodeDescription + '', 2, '', null, '', '');
                        }
                      }
                    }
                  }
                }
              });
            }
          }
        }
      }
    });

    if (bool) {// Validate for all the digits are presented

      let digits: string = "";
      for (let i = 0; i < 10; i++) {

        let canDigits = this.venListDataClone.filter(x => x.IsValid == true && x.CandidateDigits.includes(i)).length;

        if (canDigits == 0) {
          digits += digits == "" ? i.toString() : ", " + i.toString();
          bool = false;
        }
      }
      if (!bool) {
        this.showNotificationMessage('The candidate digit(s) ' + digits + ' is not mapped to any vendor.', 2, '', null, '', '');
      }
    }
    return bool;
  }

  duplicateCandidate(list: any, str: string, item: any) {
    var bool: boolean = true;

    list.forEach((itm: any) => {
      str += str == "" ? itm.CandidateDigits : "," + itm.CandidateDigits;
    });

    str += str == "" ? item.CandidateDigits : "," + item.CandidateDigits;

    var a = str.split(",");

    var canDigits = [...new Set(a.sort().filter((o, i) => o !== undefined && a[i + 1] !== undefined && o === a[i + 1]))]

    //var canDigits = str.split(",").filter((element, index, array) => array.indexOf(element) !== index);

    if (canDigits.length > 0) {
      this.showNotificationMessage('The candidate digit(s) ' + canDigits + ' already mapped to different vendor. Please try with other digit(s).', 2, '', null, '', '');
      bool = false;
    }
    return bool;
  }

  changeCellLockFlag(selitem: any, itemindex: any, mvSectionName?: any) {
    let griditem = this.mvmaingrid.filter(x =>
      x.GridId === 'MV_' + mvSectionName + '_');
    if (griditem.length > 0 && !isNullOrUndefined(griditem[0])) {
      // setTimeout(() => {

      let temp: any = [];
      temp.push(selitem);
      griditem[0].initCellLock(temp);
      griditem[0].refreshAllFieldsForRow(selitem, itemindex);

      // }, 50);
    }
  }

  // enableDisableGridFields(colmlist: string, itemindex: any, flag: boolean, mvSectionName?: any) {
  //   let griditem = this.mvmaingrid.filter(x =>
  //     x.GridId === 'MV_' + mvSectionName + '_');
  //   if (griditem.length > 0 && !isNullOrUndefined(griditem[0])) {
  //     griditem[0].enableFieldsForRow(colmlist, itemindex, flag);
  //   }

  // }

  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item
    };

    this.displayNotifybox = true;
  }

  setNotifyRes(event: any): void {
    console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;
    switch (event.action.toUpperCase()) {
      case 'DELETEVOLUME':
        if (event.result.toUpperCase() === 'YES') {
          // let bool = true;

          // let digits: string = "";
          // for (let i = 0; i < 10; i++) {

          //   let canDigits = this.venListDataClone.filter(x => x.IsValid == true && x.TransactionID != event.item.TransactionID && x.CandidateDigits.includes(i)).length;

          //   if (canDigits == 0) {
          //     digits += digits == "" ? i.toString() : ", " + i.toString();
          //     bool = false;
          //   }
          // }
          // if (!bool) {
          //   this.showNotificationMessage('The candidate digit(s) ' + digits + ' is not mapped to any vendor, if you disable this item, please try to re-map candidate digits to other vendor and continue.', 2, '', null, '', '');
          // }
          // else {
          //   this.DeleteVolume();
          // }
          event.item.RowStatus = "I";
        }
        break;
      case 'CANCELVOLUME':
        if (event.result.toUpperCase() === 'YES') {
          this._router.navigate(['app/admin/vendor']);
        }
        break;

    }
    if (canClose) {
      this.closeNotifybox();
    }
  }

  closeNotifybox(): void {
    this.displayNotifybox = false;
  }
}
