
<!-- <h3>
    <div class="d-flex justify-content-between">
        <span>Amar Joshi</span>
        <ul class="actionList px-3 ms-auto">
            <li><a href="javascript:;"><i class="fa fa-file-archive-o" aria-hidden="true"></i>All
                    Attachments</a></li>
            <li><a href="javascript:;"><i class="fa fa-file-pdf-o" aria-hidden="true"></i>Download
                    Resume</a></li>
            <li class="dropdown formDropdown"><a href="javascript:;" class="dropdown-toggle"
                    data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-download"
                        aria-hidden="true"></i>Reports</a>
                <div class="dropdown-menu ">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="dropdownCheck">
                        <label class="form-check-label" for="dropdownCheck">
                            Select All
                        </label>
                    </div>
                    <div class="dropdown-divider mb-3"></div>
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="rep1">
                        <label class="form-check-label" for="rep1">
                            Interim Report
                        </label>
                    </div>
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="rep2">
                        <label class="form-check-label" for="rep2">
                            Final Report
                        </label>
                    </div>
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="rep3">
                        <label class="form-check-label" for="rep3">
                            Supplementary Report
                        </label>
                    </div>
                    <div class="dropdown-divider mb-3"></div>
                    <div class="d-flex justify-content-end">
                        <button type="reset" class="btnCustom btnNegative rounded-pill"><i
                                class="fa fa-times" aria-hidden="true"></i> Clear</button>
                        <button type="submit" class="btnCustom btnPrimary rounded-pill"><i
                                class="fa fa-download" aria-hidden="true"></i> Download</button>
                    </div>
                </div>
            </li>

        </ul>
        <div>
            <span class="badge rounded-pill bg-secondary">Status: Inprogress</span>
        </div>
    </div>
</h3> -->

<div>
    <div class="card">
        <div class="card-header">
            <h3>
                <div class="d-flex justify-content-between">
                    <span class="">{{TileTemplate.DisplayName}}</span>
                    <ng-container class="" *ngIf="TileTemplate.ShowMenu">
                        <ul class="actionList px-3 ms-auto">
                            <!-- <li>
                                <a href="javascript:;" title="{{'Edit Tile'| translate }}">
                                    <i class="fa fa-edit"></i>
                                </a>
                            </li> -->
                            <li>
                                <a aria-label="Refresh" href="javascript:;" id="{{TileTemplate.Name}}_refresh"
                                    title="{{'Refresh Widget'| translate }}" (click)="refreshTile()">
                                    
                                    <i class="fa fa-refresh"></i>
                                </a>
                            </li>
                            <!-- Note: Min column is defaulted with 12, to make dynamic use TileTemplate.MaxColumn  -->
                            <!-- <li *ngIf="!(TileTemplate.DefaultColumn >= 12)">
                                
                                <button href="javascript:;" title="{{'Increase Widget Width'| translate }}"
                                    [disabled]="(TileTemplate.DefaultColumn >= 12)" (click)="resize(TileTemplate, 6)">
                                    <i class="fa fa-chevron-left small"></i>
                                    <i class="fa fa-chevron-right small"></i>
                                </button>
                            </li> -->
                            <!-- Note: Min column is defaulted with 6, to make dynamic use TileTemplate.MinColumn  -->
                            <!-- <li *ngIf="!(TileTemplate.DefaultColumn <= 6)">
                                
                                <button href="javascript:;" title="{{'Reduce Widget Width'| translate }}"
                                    [disabled]="(TileTemplate.DefaultColumn <= 6)" (click)="resize(TileTemplate, -6)">
                                    <i class="fa fa-chevron-right small"></i>
                                    <i class="fa fa-chevron-left small"></i>
                                </button>
                            </li> -->
                            <li *ngIf="!(TileTemplate.DefaultColumn >= 24)">
                                <a aria-label="Expand" href="javascript:;" title="{{'Expand Tile'| translate }}" (click)="fullScreen()">
                                    
                                    <i class="fa fa-expand"></i>
                                </a>
                            </li>
                            <li *ngIf="!(TileTemplate.DefaultColumn <= 12)">
                                <a aria-label="Compress" href="javascript:;" title="{{'Collapse Tile'| translate }}" (click)="fullScreen()">
                                    
                                    <i class="fa fa-compress"></i>
                                </a>
                            </li>

                            <!-- <li> -->
                            <li *ngIf="(TileTemplate.TileType == 'List' || TileTemplate.TileType == 'Grid')">
                                <a aria-label="Filter" href="javascript:;" title="{{'Filter'| translate }}" (click)="showFilter()">
                                    
                                    <i class="fa fa-filter"></i>
                                </a>
                            </li>

                            <li>
                                <a aria-label="Trash"  href="javascript:;" title="{{'Remove Tile'| translate }}" (click)="delete(TileTemplate)">
                                    
                                    <i class="fa fa-trash"></i>
                                </a>
                            </li>
                        </ul>
                    </ng-container>
                </div>
            </h3>
        </div>

        <div class="card-body readonly">
            <div *ngIf="TileTemplate.IsLoading" style="min-height: 100px;">
                <loading [IsLoading]="TileTemplate.IsLoading" [IsFullScreen]="false"></loading>
            </div>

            <div [hidden]="TileTemplate.IsLoading">

                <!-- List Type -->
                <ng-container *ngIf="TileTemplate.TileType == 'List'">
                    <div class="float-right m-2" *ngIf="TileTemplate.TileObject.ShowLegend == true">
                        <ng-container
                            *ngFor="let legend of (TileTemplate.TileObject.FieldTemplate | filterBy: {LegendShow:true})">
                            <span class="badge rounded-pill m-2"
                                [ngStyle]="{'background-color': ((legend.ListFieldColor != null && legend.ListFieldColor != '' && legend.ListFieldColor != undefined) ? legend.ListFieldColor: 'white')
                                            ,'color': ((legend.FieldForeColor != null && legend.FieldForeColor != '' && legend.FieldForeColor != undefined) ? legend.FieldForeColor: 'black')}">
                                
                                {{legend.FieldName | translate}}</span>
                        </ng-container>
                    </div>

                    <ng-container class="col-md-12" *ngFor="let listdata of TileTemplate.TileData">
                        <ul class="list-group list-group-horizontal">
                            <ng-container *ngFor="let template of TileTemplate.TileObject.FieldTemplate">
                                <li class="list-group-item"
                                    [ngStyle]="{'background-color': ((template.ListFieldColor != null && template.ListFieldColor != '' && template.ListFieldColor != undefined) ? template.ListFieldColor: 'white') 
                                                ,'color': ((template.FieldForeColor != null && template.FieldForeColor != '' && template.FieldForeColor != undefined) ? template.FieldForeColor: 'black')
                                                ,'margin-bottom': ((template.FieldMarginBottom != null && template.FieldMarginBottom != '' && template.FieldMarginBottom != undefined) ? template.FieldMarginBottom: '9px')
                                                ,'margin-right': ((template.FieldMarginRight != null && template.FieldMarginRight != '' && template.FieldMarginRight != undefined) ? template.FieldMarginRight: '7px')
                                                ,'width': ((template.FieldWidth != null && template.FieldWidth != '' && template.FieldWidth != undefined) ? template.FieldWidth: '100px')
                                                ,'text-align': ((template.FieldTextAlign != null && template.FieldTextAlign != '' && template.FieldTextAlign != undefined) ? template.FieldTextAlign: 'center')}">
                                    {{listdata[template.FieldName]}}</li>
                            </ng-container>

                        </ul>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="TileTemplate.TileType == 'Card'">
                    <div class="row row-cols-1 row-cols-md-2 g-4">
                        <ng-container *ngFor="let edata of TileTemplate.TileData">
                            <div class="col">
                                <div class="card"
                                    [ngStyle]="{'background-color': ((edata.CardBackColor != null && edata.CardBackColor != '' && edata.CardBackColor != undefined) ? edata.CardBackColor: 'white'),'color': ((edata.CardForeColor != null && edata.CardForeColor != '' && edata.CardForeColor != undefined) ? edata.CardForeColor: 'black')}">
                                    <div class="card-body readonly">
                                        <!-- <a href="javascript:this.blur();">
                                            <div class="content">
                                                <div class="pendTxt">Action required</div>
                                                <div class="numTxt">10</div>
                                            </div>
                                        </a> -->
                                        <!-- <div [innerHtml]="edata.CardTemplateData"></div> -->
                                        <div [innerHtml]="frameTemplate(TileTemplate.TileObject,edata)"></div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngIf="TileTemplate.TileType == 'Chart'">
                    <div class="col-auto position-relative" style="overflow:auto;">
                        <div [id]="'chartdiv-'+TileTemplate.Name+'-'" [style.width.%]="chartWidth"
                            [style.height.px]="chartHeight">
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="TileTemplate.TileType == 'CurosalTile'">
                    <div id="carouselExampleIndicators" class="carousel slide">
                        <!--data-bs-ride="carousel"-->
                        <ng-container *ngFor="let result of TileTemplate.TileObject; let i = index">
                            <ol class="carousel-indicators">
                                <li data-target="#carouselExampleIndicators" data-slide-to="{ i }" Class="active"></li>
                            </ol>
                        </ng-container>

                        <div class="carousel-inner">
                            <ng-container *ngFor="let result of TileTemplate.TileObject; let i = index">
                                <div class="carousel-item" [ngClass]="{'active': i === 1}">
                                    <div class="col-auto position-relative" style="overflow:auto;">
                                        <div [id]="'curosalchartdiv-'+result.ChartName+'-'" [style.width.%]="chartWidth"
                                            [style.height.px]="chartHeight">
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
                            data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
                            data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </ng-container>

                <ng-container *ngIf="TileTemplate.TileType == 'Grid'">
                    <div class="col-auto position-relative" style="overflow:auto;">
                        <ng-container
                            *ngIf="TileTemplate.TileObject.FieldTemplate!=null && TileTemplate.TileObject.FieldTemplate!=undefined && TileTemplate.TileObject.FieldTemplate.length > 0">
                            <grid-dispctrl #grid [FieldTemplate]="TileTemplate.TileObject.FieldTemplate" [GridProp]="GridProp"
                                [GridData]="TileTemplate.TileData"></grid-dispctrl>
                        </ng-container>
                    </div>
                </ng-container>


            </div>
        </div>

    </div>


    <div *ngIf='DispFilter' >  <!-- [ngStyle]="{ 'display': (DispFilter? 'block':'none')}"> -->
        <filter #tilefilt *ngIf="(TileTemplate.TileType == 'List' || TileTemplate.TileType == 'Grid') && TileTemplate.TileObject.FieldTemplate!=null && TileTemplate.TileObject.FieldTemplate!=undefined 
                      && TileTemplate.TileObject.FieldTemplate.length>0"
            [FieldTemplate]="TileTemplate.TileObject.FieldTemplate" [GridData]="TileTemplate.TileData"
            (close)="closeFilter()" (selected)="setFilter($event)"></filter>
    </div>

</div>


<style>
    /* .carousel-item {
            height: 32rem;
            background-color: #777;
            color: white;
            position: relative;
        } */

    .carousel-item.active {
        background: white;
        background-color: white;
        height: 300px;
        margin: auto;
    }

    .carousel-control-prev-icon {
        background-color: black;
    }

    .carousel-control-next-icon {
        background-color: black;
    }

    ol.carousel-indicators {
        position: absolute;
        bottom: 5px;
        margin: 0;
        left: 0;
        right: 0;
        width: auto;
    }

    ol.carousel-indicators li,
    ol.carousel-indicators li.active {
        width: 1rem;
        height: 1rem;
        margin: 0;
        border-radius: 50%;
        border: 0;
        background: transparent;
    }

    ol.carousel-indicators li {
        background: rgba(255, 255, 255, 0.39);
        margin-left: .5rem;
        margin-right: .5rem;
    }

    ol.carousel-indicators li.active {
        background: rgb(252, 52, 2);
    }

</style>
