<div class="h-100">

    <nav aria-label="breadcrumb" class="breadcrumbSection" *ngIf="(reqMode != 'INITIATE')">
        <div *ngIf="reqMode != 'INITIATE'" class="d-flex w-100 justify-content-between">
            <ul class="breadcrumb m-0 flex-fill">
                <!-- <li class="breadcrumb-item"><a routerLink="home">{{'Search' | translate}}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{'View Details' | translate}}</li> -->
            </ul>
            <div class="align-self-center breadcrumb m-0">
                <ng-container>
                    <div class="px-2"><span class="text-success me-1"><i class="fa fa-circle"
                                aria-hidden="true"></i></span>
                        {{'Positive' | translate}}</div>
                    <div class="px-2"><span class="text-warning me-1"><i class="fa fa-circle"
                                aria-hidden="true"></i></span>
                        {{'Minor Discrepancy' | translate}}</div>
                    <div class="px-2"><span class="text-danger me-1"><i class="fa fa-circle"
                                aria-hidden="true"></i></span>{{'Discrepancy' | translate}}</div>
                </ng-container>
                <!-- <ng-container *ngIf="(reqMode != 'INITIATE')">
                    <div class="px-2"><span class="text-secondary me-1"><i class="fa fa-circle"
                                aria-hidden="true"></i></span>{{'Document upload pending' | translate}}</div>

                    <div class="px-2"><span class="text-warning me-1"><i class="fa fa-circle"
                                aria-hidden="true"></i></span>{{'Partially uploaded' | translate}}</div>

                    <div class="px-2"><span class="text-success me-1"><i class="fa fa-circle"
                                aria-hidden="true"></i></span>{{'Documents uploaded' | translate}}</div>
                </ng-container> -->
            </div>
        </div>
    </nav>
    <div class="contentScroll p-3">
        <div class="card h-100 m-0">
            <div class="card-header">
                <h2>
                    <div class="d-flex justify-content-between">
                        <span>{{requestData.CandidateName | translate}} (Case Id - {{requestData.RequestId |
                            translate}})</span>
                        <ng-container
                            *ngIf="(reqMode != 'INITIATE' && _appSharedService.currentRoleData.RoleId != 1
                        && _appSharedService.currentRoleData.RoleId != 2 && _appSharedService.currentRoleData.RoleId != 3)">
                            <ul class="actionList px-3 ms-auto">
                                <!-- <li (click)="openAttachment()"><a href="javascript:;"><i class="fa fa-file-archive-o"
                                        aria-hidden="true"></i>{{'All
                                    Attachments' | translate}}</a></li> -->
                                <li aria-labelledby="Attachment" (click)="openAttachment()"><a
                                        data-bs-toggle="offcanvas" href="#open-attachments"
                                        aria-controls="open-attachments">

                                        <i id="Attachment" class="fa fa-file-archive-o" aria-hidden="true"></i>{{'All
                                        Attachments' | translate}}</a></li>

                                <ng-container
                                    *ngIf="_appSharedService.currentRoleData.RoleName =='BGV Admin' || _appSharedService.currentRoleData.RoleName=='Vendor Admin'">
                                    <label for="SLA report" class="required sr-only">SLA report</label>
                                    <li aria-label="SLA report" (click)="openModal('VendorSLA-report')"><a
                                            data-bs-toggle="offcanvas" aria-controls="SLA report">

                                            <i id="SLA report" class="fa fa-file-archive-o"
                                                aria-hidden="true"></i>{{'SLA
                                            Report' | translate}}</a></li>

                                </ng-container>



                                <li (click)="downloadResume()"><a href="javascript:;">

                                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>{{'Download
                                        Resume' | translate}}</a></li>

                                <li (click)="downloadIRF()"><a href="javascript:;">
                                    <i class="fa fa-file-pdf-o" aria-hidden="true"></i>{{'IRF' | translate}}
                                </a>
                                </li>
                                <ng-container
                                    *ngIf="reportList != null && reportList != undefined && reportList.length >0 && !disableStatusSection
                                    && _appSharedService.currentRoleData.RoleId != 1
                                    && _appSharedService.currentRoleData.RoleId != 2 
                                    && _appSharedService.currentRoleData.RoleId != 3 
                                    && _appSharedService.currentRoleData.RoleId != 11
                                    && _appSharedService.currentRoleData.RoleId != 12
                                    && _appSharedService.currentRoleData.RoleId != 14 
                                    && _appSharedService.currentRoleData.RoleId != 16">
                                    <li class="dropdown formDropdown">
                                        <a href="javascript:;" class="dropdown-toggle" data-bs-toggle="dropdown"
                                            aria-expanded="false">

                                            <i class="fa fa-download" aria-hidden="true"></i>Reports</a>
                                        <div class="dropdown-menu form-inline">
                                            <div class="form-group">
                                                <div class="form-check">
                                                    <input id="checkvalbox" name="checkvalbox" type="checkbox"
                                                        class="form-check-input" (change)="selectAll(selectedAll)"
                                                        [checked]="selectedAll">
                                                    <label class="form-check-label" for="checkvalbox">
                                                        Select All
                                                    </label>

                                                </div>
                                            </div>
                                            <div class="dropdown-divider mb-3"></div>
                                            <ng-container *ngFor="let reportdata of reportList">
                                                <div class="form-group">
                                                    <div class="form-check">
                                                        <input
                                                            id="chk{{reportdata.CodeDescription.split(' ').join('')}}"
                                                            name="chk{{reportdata.CodeDescription.split(' ').join('')}}"
                                                            type="checkbox" class="form-check-input"
                                                            value="{{reportdata.CodeDescription}}"
                                                            [(ngModel)]="reportdata.IsSelected"
                                                            (change)="checkIfAllSelected()">
                                                        <label class="form-check-label"
                                                            for="chk{{reportdata.CodeDescription.split(' ').join('')}}">
                                                            {{reportdata.CodeDescription | translate}}
                                                        </label>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <div class="dropdown-divider mb-3"></div>
                                            <div class="d-flex justify-content-end">
                                                <button type="reset" class="btnCustom btnNegative rounded-pill"
                                                    (click)="selectAll(true)"><i class="fa fa-times"
                                                        aria-hidden="true"></i>
                                                    Clear</button>
                                                <button type="submit" class="btnCustom btnPrimary rounded-pill"
                                                    (click)="downloadreport()"><i class="fa fa-download"
                                                        aria-hidden="true"></i> Download</button>
                                            </div>
                                        </div>
                                    </li>
                                </ng-container>
                            </ul>
                        </ng-container>
                        <ng-container *ngIf="(reqMode != 'INITIATE')">
                            <div>
                                <div *ngIf="(requestStatusTemplate != null && requestStatusTemplate != undefined && 
                                                requestStatusTemplate.length > 0 && !disableStatusSection)">

                                    <span [ngClass]="(requestData.StatusColorCode == 'SC001' ? 'badge rounded-pill bg-success icon'
                                : requestData.StatusColorCode == 'SC002' ? 'badge rounded-pill bg-danger icon'
                                : requestData.StatusColorCode == 'SC003' ? 'badge rounded-pill bg-warning icon'
                                : 'badge rounded-pill bg-secondary icon')">
                                        <i [ngClass]="(requestData.StatusColorCode == 'SC001' ? 'ion ion-ios-checkmark-circle'
                                    : requestData.StatusColorCode == 'SC002' ? 'ion ion-ios-alert'
                                    : requestData.StatusColorCode == 'SC003' ? 'ion ion-ios-alert'
                                    : 'ion ion-ios-alert')" aria-hidden="true"></i> <span>
                                            {{requestData[requestStatusTemplate[0].FieldName] | translate}}
                                        </span></span>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="(reqMode == 'INITIATE')">
                            <div>
                                <div *ngIf="(dashBoardCheckData != null && dashBoardCheckData != undefined && 
                                dashBoardCheckData.length > 0 && !disableStatusSection)">

                                    <span [ngClass]="(dashBoardCheckData[0].OverAllRequestDocumentStatusColorCode == 'SC001' ? 'badge rounded-pill bg-success icon'
                                : dashBoardCheckData[0].OverAllRequestDocumentStatusColorCode == 'SC002' ? 'badge rounded-pill bg-secondary icon'
                                : dashBoardCheckData[0].OverAllRequestDocumentStatusColorCode == 'SC003' ? 'badge rounded-pill bg-warning icon'
                                : 'badge rounded-pill bg-secondary icon')">
                                        <i [ngClass]="(dashBoardCheckData[0].OverAllRequestDocumentStatusColorCode == 'SC001' ? 'ion ion-ios-checkmark-circle'
                                    : dashBoardCheckData[0].OverAllRequestDocumentStatusColorCode == 'SC002' ? 'ion ion-ios-alert'
                                    : dashBoardCheckData[0].OverAllRequestDocumentStatusColorCode == 'SC003' ? 'ion ion-ios-alert'
                                    : 'ion ion-ios-alert')" aria-hidden="true"></i> <span>
                                            {{dashBoardCheckData[0].OverAllRequestDocumentStatus | translate}}
                                        </span></span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </h2>
            </div>

            <div class="card-body p-0 overflow-auto">
                <ng-container *ngIf="(reqMode != 'INITIATE')">
                    <div class="userData shadow-none">
                        <div class="row">
                            <ng-container *ngFor="let candidate of candidateTemplate">
                                <div class="col-auto align-self-center">
                                    <p class="text-bold">{{candidate.DisplayName | translate}}</p>
                                    <p>{{requestData[candidate.FieldName] | translate}}</p>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                <div class="contentScroll">
                    <div class="d-flex h-100">
                        <!-- <div class="flex-shrink-1 bg-light treeList overflowY"> -->
                        <!-- <ng-container
                            *ngIf="requestProperty != null && requestProperty != undefined && requestProperty.length > 0"> -->
                        <div class="flex-shrink-1 bg-light treeList overflowY">
                            <ul class="list-group list-group-flush" role="tablist" aria-orientation="vertical"
                                id="tabView">
                                <!-- <li>
                                    <a class="list-group-item list-group-item-action active" data-bs-toggle="tab"
                                        href="#tabViewDetails" role="tab" aria-selected="true"
                                        data-url="overview.html">Overview</a>
                                </li> -->

                                <!-- Request Group -->
                                <ng-container
                                    *ngIf="(requestProperty.LeftPaneMenu != null && requestProperty.LeftPaneMenu != undefined && 
                                            (requestProperty.LeftPaneMenu | filterBy: {CanShow: true, Group: 'Request',HasPermission :true}).length > 0)">

                                    <ng-container
                                        *ngFor="let sideBar of (requestProperty.LeftPaneMenu | filterBy: {CanShow: true, Group: 'Request',HasPermission :true}
                                            | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">
                                        <li>
                                            <a [attr.aria-label]="sideBar?.DisplayName" href="javascript:;"
                                                [id]="sideBar.Name" class="list-group-item list-group-item-action"
                                                [ngClass]="{'active': (selLeftMenu == sideBar.Name)}"
                                                (click)="setSelectedSideTab(sideBar)" data-bs-toggle="tab" role="tab"
                                                [attr.aria-selected]="(selLeftMenu == sideBar.Name)">{{sideBar.DisplayName
                                                | translate}}</a>
                                        </li>

                                    </ng-container>
                                </ng-container>

                                <!-- Check Group -->
                                <ng-container
                                    *ngIf="(overallStatusData != null && overallStatusData != undefined && overallStatusData.length > 0 && !hideCheckSection)">
                                    <div class="dropdown-divider"></div>

                                    <ng-container *ngFor="let sideBar of overallStatusData; let i = index">
                                        <li>
                                            <a [attr.aria-label]="sideBar?.CheckName" href="javascript:;"
                                                [id]="sideBar.CheckName" class="list-group-item list-group-item-action"
                                                [ngClass]="{'active': (selLeftMenu == 'CHECK_'+sideBar.CheckName)}"
                                                (click)="setSelectedSideTab(sideBar)" data-bs-toggle="tab" role="tab"
                                                [attr.aria-selected]="(selLeftMenu == 'CHECK_'+sideBar.CheckName)">

                                                <div class="d-flex justify-content-between">
                                                    <span class="align-self-center">{{sideBar.CheckName | translate}}
                                                        <!-- <span
                                                                class="badge rounded-pill bg-secondary d-block">{{sideBar.Description
                                                                | translate}}
                                                                hold
                                                            </span> -->
                                                    </span>
                                                    <!-- <span class="text-secondary align-self-center"
                                                        data-bs-toggle="tooltip" data-bs-placement="right"
                                                        [title]="sideBar.Description+ ' hold'">
                                                        <i class="ion ion-ios-information-circle"
                                                            aria-hidden="true"></i>
                                                    </span> -->
                                                    <ng-container *ngIf="(reqMode != 'INITIATE')">
                                                        <span [ngClass]="(sideBar.IsApplicable == false ? 'text-secondary align-self-center'
                                                                : sideBar.StatusColorCode == 'SC001' ? 'text-success align-self-center'
                                                                : sideBar.StatusColorCode == 'SC002' ? 'text-danger align-self-center'
                                                                : sideBar.StatusColorCode == 'SC003' ? 'text-warning align-self-center'
                                                                : 'text-secondary align-self-center')"
                                                            data-bs-toggle="tooltip" data-bs-placement="right"
                                                            [title]="sideBar.CheckName">
                                                            <i [ngClass]="(sideBar.IsApplicable == false ? 'ion ion-ios-alert'
                                                                : sideBar.StatusColorCode == 'SC001' ? 'ion ion-ios-checkmark-circle'
                                                                : sideBar.StatusColorCode == 'SC002' ? 'ion ion-ios-alert'
                                                                : sideBar.StatusColorCode == 'SC003' ? 'ion ion-ios-alert'
                                                                : 'ion ion-ios-alert')" aria-hidden="true"
                                                                [title]="sideBar.CheckStatusName"></i>
                                                        </span>
                                                    </ng-container>
                                                    <ng-container *ngIf="(reqMode == 'INITIATE')">
                                                        <span [ngClass]="(sideBar.IsApplicable == false ? 'text-secondary align-self-center'
                                                                : sideBar.CheckDocumentOverallStatusColorCode == 'SC001' ? 'text-success align-self-center'
                                                                : sideBar.CheckDocumentOverallStatusColorCode == 'SC002' ? 'text-secondary align-self-center'
                                                                : sideBar.CheckDocumentOverallStatusColorCode == 'SC003' ? 'text-warning align-self-center'
                                                                : 'text-secondary align-self-center')"
                                                            data-bs-toggle="tooltip" data-bs-placement="right"
                                                            [title]="sideBar.CheckName">
                                                            <i [ngClass]="(sideBar.IsApplicable == false ? 'ion ion-ios-alert'
                                                                : sideBar.CheckDocumentOverallStatusColorCode == 'SC001' ? 'ion ion-ios-checkmark-circle'
                                                                : sideBar.CheckDocumentOverallStatusColorCode == 'SC002' ? 'ion ion-ios-alert'
                                                                : sideBar.CheckDocumentOverallStatusColorCode == 'SC003' ? 'ion ion-ios-alert'
                                                                : 'ion ion-ios-alert')" aria-hidden="true"
                                                                [title]="sideBar.CheckDocumentOverallStatus"></i>
                                                        </span>
                                                    </ng-container>
                                                </div>

                                            </a>
                                        </li>
                                    </ng-container>

                                </ng-container>

                                <!-- Update Over all Status -->
                                <ng-container
                                    *ngIf="(requestProperty.LeftPaneMenu != null && requestProperty.LeftPaneMenu != undefined &&
                                     (requestProperty.LeftPaneMenu | filterBy: {CanShow: true, Group: 'UpdateCheck',HasPermission :true }).length > 0)">

                                    <div class="dropdown-divider"></div>

                                    <ng-container
                                        *ngFor="let sideBar of (requestProperty.LeftPaneMenu | filterBy: {CanShow: true, Group: 'UpdateCheck',HasPermission :true }
                                            | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">
                                        <li>
                                            <a [attr.aria-label]="sideBar?.DisplayName" href="javascript:;"
                                                [id]="sideBar.Name" class="list-group-item list-group-item-action"
                                                [ngClass]="{'active': (selLeftMenu == sideBar.Name)}"
                                                (click)="setSelectedSideTab(sideBar)" data-bs-toggle="tab" role="tab"
                                                [attr.aria-selected]="(selLeftMenu == sideBar.Name)">{{sideBar.DisplayName
                                                | translate}}</a>
                                        </li>
                                    </ng-container>
                                </ng-container>

                                <ng-container
                                    *ngIf="(requestProperty.LeftPaneMenu != null && requestProperty.LeftPaneMenu != undefined &&
                                         (requestProperty.LeftPaneMenu | filterBy: {CanShow: true, Group: 'QC',HasPermission :true }).length > 0)">

                                    <div class="dropdown-divider"></div>

                                    <ng-container
                                        *ngFor="let sideBar of (requestProperty.LeftPaneMenu | filterBy: {CanShow: true, Group: 'QC',HasPermission :true }
                                                | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">
                                        <li>
                                            <a [attr.aria-label]="sideBar?.DisplayName" href="javascript:;"
                                                [id]="sideBar.Name" class="list-group-item list-group-item-action"
                                                [ngClass]="{'active': (selLeftMenu == sideBar.Name)}"
                                                (click)="setSelectedSideTab(sideBar)" data-bs-toggle="tab" role="tab"
                                                [attr.aria-selected]="(selLeftMenu == sideBar.Name)">{{sideBar.DisplayName
                                                | translate}}</a>
                                        </li>
                                    </ng-container>
                                </ng-container>
                            </ul>
                        </div>
                        <!-- </ng-container> -->

                        <div class="flex-fill bg-white">
                            <div class="tab-content h-100">
                                <div class="tab-pane fade show active">
                                    <div class="h-100">


                                        <ng-container *ngIf="(selLeftMenu.indexOf('CHECK_') < 0)">

                                            <ng-container *ngIf="selLeftMenu == 'StatusSummary' && (dashBoardCheckData!=null && dashBoardCheckData!=undefined 
                                            && dashBoardCheckData.length > 0)">
                                                <status-summary #checkcomp [RequestId]="RequestId"
                                                    [RequestData]="requestData"
                                                    [DashBoardCheckData]="dashBoardCheckData"
                                                    [RequestProperty]="requestProperty"
                                                    [RequestPermissions]="requestPermissions"
                                                    (CheckClick)="checkClick($event)"
                                                    (LeftmenuClick)="leftmenuClick($event)">
                                                </status-summary>
                                            </ng-container>
                                            <ng-container *ngIf="selLeftMenu == 'Overview'">
                                                <div class="contentScroll p-2 full">
                                                    <ng-container *ngIf="!openStopCaseSection">
                                                        <ng-container *ngIf="showOnBoardingflag">
                                                            <div>
                                                                <div class="d-flex justify-content-end form-inline">
                                                                    <div class="form-group mb-0">
                                                                        <div class="form-check form-switch">
                                                                            <label style="margin-top: -3px;"
                                                                                class="form-check-label"
                                                                                for="flexSwitchCheckDefault">{{requestProperty.ReadyToOnboard.DisplayName
                                                                                | translate}}</label>
                                                                            <input class="form-check-input"
                                                                                [(ngModel)]="onBoardingSelected"
                                                                                type="checkbox" role="switch"
                                                                                id="flexSwitchCheckDefault"
                                                                                (change)="changeReadytoOnboard()"
                                                                                [disabled]="editOnBoardingflag">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="(requestProperty.Overview != null && requestProperty.Overview != undefined &&
                                                             (requestProperty.Overview | filterBy: {HasPermission :true }).length > 0)">
                                                             <tabset
                                                                [tabList]="(requestProperty.Overview | filterBy: {HasPermission :true })"
                                                                (selected)="setSelectedTab($event)">
                                                                <ng-container
                                                                    *ngFor="let overViewTab of (requestProperty.Overview | filterBy: {HasPermission :true })">
                                                                    <ng-container
                                                                        *ngIf="selectedTab==overViewTab.Name && overViewTab.Section.length == 0">
                                                                        <!-- <p><strong>Profile:</strong> {{overViewTab.Name}}</p> -->
                                                                        <div class="contentScroll full p-3"
                                                                            *ngIf="requestTemplate!=null && requestTemplate!=undefined && requestTemplate.length > 0">
                                                                            <customform #searchform
                                                                                [FormTemplate]="requestTemplate | filterBy: {Group: overViewTab.Name}"
                                                                                [FormData]="overViewData"
                                                                                [FormProp]="FormProp"
                                                                                [FormConfig]="FormConfig"
                                                                                [GlobalConfig]="GlobalConfig"
                                                                                (FieldClick)="onClick($event)"
                                                                                (FieldChange)="onChange($event)">
                                                                            </customform>
                                                                            <customform *ngIf="offerStatus =='Joined' " #searchform
                                                                            [FormTemplate]="NGTemplate2"
                                                                            [FormData]="overViewData"
                                                                            [FormProp]="FormProp"
                                                                            [FormConfig]="FormConfig"
                                                                            [GlobalConfig]="GlobalConfig"
                                                                            (FieldClick)="onClick($event)"
                                                                            (FieldChange)="onChange($event)">
                                                                        </customform>
                                                                        </div>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="selectedTab==overViewTab.Name && overViewTab.Section.length > 0">
                                                                        
                                                                        <div class="contentScroll full p-3">
                                                                            <div class="row small-gutter">
                                                                                <ng-container
                                                                                    *ngFor="let innerTab of overViewTab.Section">
                                                                                    
                                                                                    <div class='col-lg-12 profile-details-section'
                                                                                        *ngIf="requestTemplate!=null && requestTemplate!=undefined && requestTemplate.length > 0">
                                                                                        <h3 style="font-size: 1rem;">
                                                                                            {{innerTab.DisplayName |
                                                                                            translate}}
                                                                                        </h3>

                                                                                        <customform
                                                                                            [FormTemplate]="requestTemplate | filterBy: {Group: innerTab.Name}"
                                                                                            [FormData]="overViewData"
                                                                                            [FormProp]="FormProp"
                                                                                            [FormConfig]="FormConfig"
                                                                                            [GlobalConfig]="GlobalConfig"
                                                                                            (FieldClick)="onClick($event)"
                                                                                            (FieldChange)="onChange($event)">
                                                                                        </customform>
                                                                                    </div>
                                                                                </ng-container>

                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </tabset>
                                                        </ng-container>
                                                    </ng-container>
                                                    <!--Section Submission Start From Here -->
                                                    <ng-container *ngIf="openStopCaseSection">
                                                        <div class="card h-100 m-0">
                                                            <div class="card-header">
                                                                <h3>
                                                                    <span>{{'Stop Case' | translate}}</span>
                                                                </h3>
                                                            </div>
                                                            <div class="card-body overflow-auto">
                                                                <div class="gridContainer mb-3">
                                                                    <div class="table-responsive">
                                                                        <ng-container
                                                                            *ngIf="stopCaseRemarksTemplate!=null && stopCaseRemarksTemplate!=undefined && stopCaseRemarksTemplate.length > 0">
                                                                            <grid-dispctrl #additionalsectiongrid
                                                                                [FieldTemplate]="stopCaseRemarksTemplate"
                                                                                [GridProp]="GridProp"
                                                                                [GridData]="stopCasesectionData"
                                                                                [GridDataTotalCount]="stopCasesectionData.length"
                                                                                (FieldClick)="onClick($event)"
                                                                                (FieldChange)="onChange($event)">
                                                                            </grid-dispctrl>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <!--Section Submission End Here -->
                                                </div>
                                            </ng-container>

                                            <ng-container *ngIf="selLeftMenu == 'OverallStatus'">
                                                <div class="p-2 contentScroll simpleCard">
                                                    <div class="card h-100 m-0">
                                                        <div class="card-header">
                                                            <h3>
                                                                <span>Overall Status</span>
                                                            </h3>
                                                        </div>
                                                        <div class="card-body overflow-auto">
                                                            <!-- <div class="gridContainer"> -->
                                                            <div class="table-responsive">
                                                                <ng-container
                                                                    *ngIf="requestTemplate!=null && requestTemplate!=undefined && requestTemplate.length > 0">
                                                                    <grid-dispctrl #overallstatusgrid
                                                                        [FieldTemplate]="requestTemplate | filterBy: {Group: selLeftMenu}"
                                                                        [GridProp]="GridProp"
                                                                        [GridData]="overallStatusData"
                                                                        [GridDataTotalCount]="DataTotalCount">
                                                                    </grid-dispctrl>
                                                                </ng-container>
                                                            </div>
                                                            <!-- </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>

                                            <ng-container *ngIf="selLeftMenu == 'ViewEmail'">
                                                <!-- logu -->
                                                <email #checkcomp [RequestId]="RequestId" [CheckId]="checkId"
                                                    [RequestData]="requestData" [RequestProperty]="requestProperty"
                                                    [RequestTemplate]="requestTemplate">
                                                </email>
                                               
                                            </ng-container>

                                            <ng-container *ngIf="selLeftMenu == 'InsufficiencyStatus'">
                                                <div class="box primary-box">
                                                    <ng-container
                                                        *ngIf="(requestProperty.InsufficiencyStatus != null && requestProperty.InsufficiencyStatus != undefined &&
                                                             (requestProperty.InsufficiencyStatus | filterBy: {HasPermission :true }).length > 0)">
                                                        <tabset
                                                            [tabList]="(requestProperty.InsufficiencyStatus | filterBy: {HasPermission :true })"
                                                            (selected)="setSelectedTab($event)">
                                                            <ng-container
                                                                *ngFor="let insufficienyTabs of (requestProperty.InsufficiencyStatus | filterBy: {HasPermission :true })">
                                                                <ng-container
                                                                    *ngIf="selectedTab==insufficienyTabs.Name">
                                                                    <div class="col-auto position-relative"
                                                                        style="overflow:auto;">
                                                                        <ng-container
                                                                            *ngIf="requestTemplate!=null && requestTemplate!=undefined && requestTemplate.length > 0">
                                                                            <grid-dispctrl #insufficienygrid
                                                                                [FieldTemplate]="requestTemplate | filterBy: {Group: insufficienyTabs.Name}"
                                                                                [GridProp]="GridProp"
                                                                                [GridData]="insufficiencyData">
                                                                            </grid-dispctrl>
                                                                        </ng-container>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                        </tabset>

                                                    </ng-container>
                                                </div>
                                            </ng-container>

                                            <ng-container *ngIf="selLeftMenu == 'UpdateOverAllStatus'">
                                                <updateoverallstatus #UPOverallComp
                                                    [SelectedTabName]='selLeftMenuDisplayName' [RequestId]="RequestId"
                                                    [RequestData]="requestData"
                                                    (RefreshRequest)="refreshRequest($event)">
                                                </updateoverallstatus>
                                            </ng-container>

                                            <ng-container *ngIf="selLeftMenu == 'QualityControl'">
                                                <quality-control #QCComp [SelectedTabName]='selLeftMenuDisplayName'
                                                    [RequestId]="RequestId" [RequestData]="requestData"
                                                    (RefreshRequest)="refreshRequest($event)">
                                                </quality-control>
                                            </ng-container>

                                            <ng-container *ngIf="selLeftMenu == 'NonGreenTeam'">
                                                <non-green #nongreenComp [SelectedTabName]='selLeftMenuDisplayName'
                                                    [RequestId]="RequestId" [RequestData]="requestData"
                                                    (RefreshRequest)="refreshRequest($event)">
                                                </non-green>
                                            </ng-container>
                                            <ng-container *ngIf="selLeftMenu == 'CentralHR'">
                                                <central-hr #CentralHr [SelectedTabName]='selLeftMenuDisplayName'
                                                    [RequestId]="RequestId" [RequestData]="requestData"
                                                    (RefreshRequest)="refreshRequest($event)">
                                                </central-hr>
                                            </ng-container>
                                            <ng-container *ngIf="selLeftMenu == 'BGVHead'">
                                                <bgv-head #BGVHead [SelectedTabName]='selLeftMenuDisplayName'
                                                    [RequestId]="RequestId" [RequestData]="requestData"
                                                    (RefreshRequest)="refreshRequest($event)">
                                                </bgv-head>
                                            </ng-container>
                                            <ng-container *ngIf="selLeftMenu == 'BUHR'">
                                                <bu-hr #BuHr [SelectedTabName]='selLeftMenuDisplayName'
                                                    [RequestId]="RequestId" [RequestData]="requestData"
                                                    (RefreshRequest)="refreshRequest($event)">
                                                </bu-hr>
                                            </ng-container>
                                            <ng-container *ngIf="selLeftMenu == 'SubmitDocument'">
                                                <submitAcknowledgement #submitAcknowledgement [RequestId]="RequestId"
                                                    [RequestData]="requestData" [RequestProperty]="requestProperty">
                                                </submitAcknowledgement>
                                            </ng-container>

                                        </ng-container>

                                        <ng-container *ngIf="(selLeftMenu.indexOf('CHECK_') >= 0)">


                                            <check #checkcomp [RequestId]="RequestId" [CheckId]="checkId"
                                                [RequestData]="requestData" [CheckList]="overallStatusData"
                                                [RequestProperty]="requestProperty" (ChangeCheck)="changeCheck($event)"
                                                (RefreshRequest)="refreshRequest($event)"
                                                (OpenClientRef)="OpenClientRef($event)"
                                                (ShowPreview)="showPreview($event)"
                                                (LeftmenuClick)="leftmenuClick($event)">
                                            </check>
                                        </ng-container>

                                        <!-- <ng-container *ngIf="(selLeftMenu.indexOf('CHECK_') < 0)">
                                                <ng-container *ngIf="selLeftMenu == 'UpdateOverAllStatus'">
                                                    <updateoverallstatus [RequestId]="RequestId"></updateoverallstatus>
                                                </ng-container>
                                            </ng-container> -->
                                            <div class="d-flex justify-content-end">
                                            <ng-container *ngIf="selLeftMenu == 'Overview' && _appSharedService.currentRoleData.RoleId == 8">

                                                <ul class="actionList">
                                                    <ng-container *ngIf="selLeftMenu == 'Overview' && _appSharedService.currentRoleData.RoleId == 8">
                                                        <li>
                                                            <div class="cellLink" id="add-sec">
                                                                <a class="cellLink_ng" aria-expanded="false" (click)="AddMoreNGDiscrepantComponent()">
                                                                    <span>{{'Add More Discrepant Component   ' |  translate}}</span>
                                                                </a>
                                                            </div>
                                                            
                                                        </li>
                                                    </ng-container>
                                                </ul>
                
                                            </ng-container>
                                            </div> 
                                             <!--Additional Sections-->
    <ng-container *ngIf="selLeftMenu == 'Overview' && _appSharedService.currentRoleData.RoleId == 8"> 
    <!-- *ngIf="(additionalSecFormData != null && additionalSecFormData != undefined && additionalSecFormData.length >0)"> -->
    <!-- <ng-container *ngFor="let sectionData of (addtionalSectionList | filterBy: {CanShow: true} | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'})"> -->
    <!-- <ng-container *ngIf="sectionData.CanShow== true"> -->
    <div class="card m-0 mt-3">
        <div class="card-body overflow-auto">
            <div class="accordion" [id]="'_gapsectionData'">
                <div class="accordion-item">
                    <h2 class="accordion-header" [id]="'gapsectionData'">
                        <button class="accordion-button collapsed justify-content-between" type="button"
                            data-bs-toggle="collapse" [attr.data-bs-target]="'#_gapsectionData_H'"
                            aria-expanded="false" [attr.aria-controls]="'_gapsectionData_H'">
                            <div>
                                {{ 'Discrepant Component For Non Green' | translate}}
                            </div>
                        </button>
                    </h2>
 
                    <div [id]="'_gapsectionData_H'" class="accordion-collapse collapse"
                        [attr.aria-labelledby]="'S_gapsectionData_'">
                        <br />
                        <div class="accordion-body"> 
                            <ng-container *ngFor="let data of additionalSecFormDataForNG">
                                <br>
                                <div class="row small-gutter form-inline">

                                    <div class="d-flex justify-content-between">
                                        <p class="d-flex text-bold">{{data.DiscrepantComponentName  |
                                            translate}}</p>
                                        <ul class="actionList">
                                            <li (click)="showConfirmationforGapDelete(data)">
                                                <a href="javascript:;">
                                                    <i class="ion ion-md-trash" aria-hidden="true"></i> Delete
                                                </a>
                                            </li>
                                        </ul>
                                    </div> 
                                    <br />
                                    <br />
                                    <hr class="dividerThin">
                                    <ng-container
                                        *ngIf="(NGTemplate!=null && NGTemplate!=undefined && NGTemplate.length > 0)">
                                        <customform #SEC_gapsectionData [FormTemplate]="NGTemplate"
                                            [FormData]="data" [FormProp]="AdditionalSecProp"
                                            [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                                            (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                        </customform>
                                        <customform  *ngIf="(data.DiscrepantComponent == 'DC001') || (data.DiscrepantComponent == 'DC002')" #SEC_gapsectionData [FormTemplate]="NGTemplate1"
                                            [FormData]="data" [FormProp]="AdditionalSecProp"
                                            [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                                            (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                        </customform>                  
                                    </ng-container>

                                </div>
                            </ng-container>
                            <!-- </div> -->

                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
    <!-- </ng-container> -->
    <!-- </ng-container> -->
</ng-container>
                                        <!--Button For Stop Case in Overview-->
                                        <ng-container *ngIf="selLeftMenu == 'Overview'">
                                            <div class="filters buttonHolder">
                                                <div class="d-flex justify-content-between ">
                                                    <div></div>
                                                    <div>
                                                        <ng-container
                                                            *ngIf="requestProperty.ActionButtons != null && requestProperty.ActionButtons != undefined">

                                                            <ng-container *ngIf="!openStopCaseSection">
                                                                <ng-container
                                                                    *ngFor="let actionBar of (requestProperty.ActionButtons | filterBy: {IsApplicable: true,HasPermission :true, Group :'Request'}
                                                                        | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">

                                                                    <button type="button"
                                                                        [ngClass]="actionBar.CustomClass"
                                                                        (click)="actionButtonClick(actionBar)"
                                                                        [disabled]="!actionBar.IsEnabled"
                                                                        value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                                                        |
                                                                        translate
                                                                        }}
                                                                        <span *ngIf="actionBar.ShowArrow"
                                                                            class="ps-3"><i
                                                                                class="ion ion-md-arrow-forward"
                                                                                aria-hidden="true"></i></span>
                                                                    </button>
                                                                </ng-container>
                                                            </ng-container>

                                                            <ng-container *ngIf="openStopCaseSection">
                                                                <ng-container
                                                                    *ngFor="let actionBar of (requestProperty.ActionButtons | filterBy: {IsApplicable: true,HasPermission :true, Group :'StopCase'}
                                                                    | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">

                                                                    <button type="button"
                                                                        [ngClass]="actionBar.CustomClass"
                                                                        (click)="actionButtonClick(actionBar)"
                                                                        [disabled]="!actionBar.IsEnabled"
                                                                        value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                                                        |
                                                                        translate
                                                                        }}
                                                                        <span *ngIf="actionBar.ShowArrow"
                                                                            class="ps-3"><i
                                                                                class="ion ion-md-arrow-forward"
                                                                                aria-hidden="true"></i></span>
                                                                    </button>
                                                                </ng-container>
                                                            </ng-container>


                                                        </ng-container>


                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="d-flex justify-content-center">
                    <div class="small align-self-center">Copyright © 2022 Capgemini All rights reserved.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>

<!-- Filter Pop-up -->


<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
        (selected)="setNotifyRes($event) " (close)="closeNotifybox() "></notification>
</div>

<!--Attachment section-->
<ng-container *ngIf="DisplayAttachment==true">
    <file-upload [Module]="'StopCase'" [RefId1]="RequestId" [IsMultiple]="false" (close)="closeAttachment($event)"
        [Permissions]="AttachPerm" [CandidateId]="requestData.CandidateId">
    </file-upload>
</ng-container>
<!-- Attachment section -->
<!-- <div *ngIf="displayAllAttachmentbox">
    <attachment [SectionList]="allSectionList" [Permissions]="AttachPerm"
            (close)="closeAttachment()" (downloadALL)="downloadALLAttachment()"></attachment>
</div> -->

<div class="offcanvas offcanvas-end w-75" tabindex="-1" id="open-attachments" aria-labelledby="all-attachments">
    <div class="offcanvas-header">
        <h2 class="offcanvas-title" id="all-attachments"><span>Attachments</span></h2>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body bg-light">
        <div class="row g-0 h-100 align-items-stretch">
            <div class="col-6 bgCoolGrey position-relative">
                <h3 class="w-100 p-3 bgMildGrey">Preview: {{previewFileName}} </h3>

                <!-- <div class="position-absolute top-50 start-50 translate-middle w-100 h-100">
                    <div class="viewerContainer">
                        
                        <p class="position-absolute top-50 start-50 translate-middle">Click view icon to preview the attachment</p>

                    </div>
                </div> -->

                <div class="position-absolute top-50 start-50 translate-middle w-100 h-100">
                    <div class="viewerContainer">
                        <!-- <p>Click view icon to preview the attachment</p> -->

                        <ng-container *ngIf="previewFilePath.length == 0">
                            <p class="position-absolute top-50 start-50 translate-middle">Click view icon to preview the
                                attachment</p>
                        </ng-container>

                        <ng-container
                            *ngIf="previewFilePath!=null && previewFilePath!=undefined && previewFilePath!='' && previewFilePath.length > 0">
                            <!-- <p>File: {{previewFileName}} </p> -->

                            <!-- <iframe [src]="previewFilePath" style="width:100%;height:50vh;">
                            </iframe> -->

                            <ng-container
                                *ngIf="(previewFileType!=null && previewFileType!=undefined && previewFileType!='')">

                                <!-- Images -->
                                <ng-container *ngIf="(previewFileType == 'image')">
                                    <div class="text-center borderBox" style="max-height: 100%;overflow: auto;">
                                        <img alt="Capgemini Logo" class="w-100"
                                            [src]="sanitizer.bypassSecurityTrustResourceUrl(previewFilePath)">
                                    </div>
                                </ng-container>

                                <!-- PDF -->
                                <ng-container *ngIf="(previewFileType == 'pdf')">
                                    <pdf-viewer [src]="previewFilePath" [render-text]="true" [original-size]="false"
                                        style="width: 100%; height: 100%">
                                        <!-- style="width: 100%; height: 100%"> -->
                                    </pdf-viewer>
                                </ng-container>

                                <!-- Excel -->
                                <ng-container *ngIf="(previewFileType == 'excel')">
                                    <excel-viewer [srcFile]="previewFileData"></excel-viewer>
                                </ng-container>

                                <!-- Docs -->
                                <ng-container *ngIf="(previewFileType == 'others')">
                                    <ngx-doc-viewer [url]="previewFilePath" viewer="mammoth"
                                        style="width:100%;height:100%;">
                                        <!-- style="width:100%;height:50vh;" > -->
                                    </ngx-doc-viewer>

                                </ng-container>

                                <!-- <ngx-doc-viewer [url]="previewFilePath" viewer="office" style="width:100%;height:50vh;" >
                                </ngx-doc-viewer> -->

                                <!-- <ngx-doc-viewer [url]="previewFilePath" viewer="google" 
                                    style="width:100%;height:50vh;">
                                </ngx-doc-viewer> -->
                                <!--  -->
                                <!-- <ngx-doc-viewer
                                    [viewerUrl]="https://docs.google.com/gview?url=%URL%&embedded=true"
                                    [url]="https://file-examples.com/wp-content/uploads/2017/02/file-sample_100kB.doc"
                                    viewer="url"
                                    style="width:100%;height:50vh;">
                                </ngx-doc-viewer> -->


                            </ng-container>


                        </ng-container>

                    </div>
                </div>
            </div>
            <div class="col-6 bg-white">
                <h3 class="w-100 p-3 bgMildGrey">Select files to dowlnoad</h3>

                <div class="px-3">
                    <div class="row py-2">
                        <div class="col-8">
                            <div class="form-check">
                                <input id="checkboxval" name="checkboxval" type="checkbox" class="form-check-input"
                                    (change)="setAllSelected(selectedAllAttachment)" [checked]="selectedAllAttachment">
                                <label for="checkboxval" class="form-check-label">
                                    Attachments
                                </label>
                            </div>
                        </div>
                        <div class="col-4">
                            <div>Actions</div>

                        </div>
                    </div>

                    <div class="p-3 mb-2 bg-light text-dark">
                        <h3>Pre-Fetched Documents from SF</h3>
                    </div>

                    <ng-container *ngFor="let mandatoryData of mandatoryDocList ; let k = index">
                        <div class="row py-2">
                            <div class="col-8">
                                <!-- <div class="form-check">
                                    <input type="checkbox" class="form-check-input" [id]="mandatoryData.FileName"
                                        name="mandatoryData.FileName">
                                    <label class="form-check-label" [for]="mandatoryData.FileName">
                                        {{mandatoryData.FileName}}
                                    </label>
                                </div> -->

                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" [id]="mandatoryData.FileName"
                                        value="{{mandatoryData.FileName}}" [(ngModel)]="mandatoryData.IsSelected"
                                        (change)="checkIfAllAttachmentSelected()">
                                    <label class="form-check-label" [for]="mandatoryData.FileName">
                                        {{mandatoryData.FileName | translate}}
                                    </label>
                                </div>
                            </div>
                            <div class="col-4">
                                <ul class="actionList d-flex mt-2">
                                    <li>
                                        <a aria-label="Download Attachment" href="javascript:;"
                                            (click)="downloadMandatoryAttachment(mandatoryData)">

                                            <i class="fa fa-download" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a aria-label="Preview Attachment" href="javascript:;"
                                            (click)="setPreviewFile(mandatoryData)">

                                            <i class="ion ion-ios-eye" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="(allAttachmentList != null && allAttachmentList != undefined)">
                        <ng-container *ngFor="let checkData of overallStatusData; let i = index">
                            <ng-container
                                *ngIf="((allAttachmentList | filterBy: {CheckId: checkData.CheckId }).length > 0)">
                                <div class="p-3 mb-2 bg-light text-dark">
                                    <h3>{{checkData.CheckName + ' Documents' | translate}}</h3>
                                </div>
                                <ng-container
                                    *ngFor="let attachmentData of (allAttachmentList | filterBy: {CheckId: checkData.CheckId }); let j = index">
                                    <div class="row py-2">
                                        <div class="col-8">
                                            <!-- <div class="form-check">
                                                <input type="checkbox" class="form-check-input"
                                                    [id]="attachmentData.TransactionId">
                                                <label class="form-check-label" [for]="attachmentData.TransactionId">
                                                    {{attachmentData.FileName}}
                                                </label>
                                            </div> -->
                                            <div class="form-check">
                                                <input id="chk{{attachmentData.FileName.split('').join('')}}"
                                                    type="checkbox" class="form-check-input"
                                                    value="{{attachmentData.FileName}}"
                                                    [(ngModel)]="attachmentData.IsSelected"
                                                    (change)="checkIfAllAttachmentSelected()">
                                                <label for="chk{{attachmentData.FileName.split('').join('')}}"
                                                    class="form-check-label">
                                                    {{attachmentData.FileName | translate}}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <ul class="actionList d-flex mt-2">
                                                <li>
                                                    <a aria-label="Icon Download" href="javascript:;"
                                                        (click)="downloadAttachment(attachmentData)">
                                                        <i class="fa fa-download" aria-hidden="true"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a aria-label="Icon Preview" href="javascript:;"
                                                        (click)="setPreviewFile(attachmentData)">
                                                        <i class="fa fa-eye" aria-hidden="true"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas-footer">
        <div class="d-flex justify-content-end p-3">
            <button type="button" class="btnCustom btnSecondary rounded-pill" (click)="downloadSelectedAttachment()"><i
                    class="fa fa-download" aria-hidden="true"></i> Download selected</button>
            <button type="button" class="btnCustom btnPrimary rounded-pill" (click)="downloadALLAttachment()"><i
                    class="fa fa-download" aria-hidden="true"></i> Download all</button>
        </div>
    </div>
</div>
<!--REQUEST LEVEL  REPORT-->
<ng-container
    *ngIf="(requestReportTemplate != null && requestReportTemplate != undefined && requestReportTemplate.length > 0)">
    <modal-popup id="VendorSLA-report" style="display: none;"
        [modaldetail]="{header: 'SLA REPORT',size: 'modal-xl modal-dialog-centered modal-dialog-scrollable',width: '2500',height: '800'}">
        <ng-container
            *ngIf="(requestReportTemplate != null && requestReportTemplate != undefined && requestReportTemplate.length > 0)">
            <grid-dispctrl #overallgrid [FieldTemplate]="requestReportTemplate" [GridData]="reqReportListData"
                [GridProp]="GridPropRequestReport" [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                (ActionBarClick)="actButtonClick($event)">
            </grid-dispctrl>
        </ng-container>
    </modal-popup>
</ng-container>




<div *ngIf="DispFilter">
    <filter
        *ngIf="(requestReportTemplate!= undefined && requestReportTemplate!= null && requestReportTemplate.length > 0)"
        [FieldTemplate]="requestReportTemplate" [GridData]="reqReportListData" (close)="showFilter($event)"
        (selected)="setFilter($event)" [InitialCondn]="curFiltCondn">
    </filter>
</div>

<!-- Excel Download -->
<excel-export #excelexport [FileName]="ExcelFileName"></excel-export>

<!--ATTACHMENT SECTION-->
<!-- <div class="offcanvas offcanvas-end w-75" tabindex="-1" id="SLA-report" aria-labelledby="all-attachments">
    <div class="offcanvas-header">
        <h2 class="offcanvas-title" id="SLA-report"><span>SLA REPORT</span></h2>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body bg-light">
        <ng-container *ngIf="(requestReportTemplate != null && requestReportTemplate != undefined && requestReportTemplate.length > 0)">
            <grid-readonly #overallgrid [FieldTemplate]="requestReportTemplate" [GridData]="repListData" [GridProp]="GridPropRequestReport"
              [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)" (ActionBarClick)="actButtonClick($event)">
            </grid-readonly>
          </ng-container>
    </div>
     <div class="offcanvas-footer">
        <div class="d-flex justify-content-end p-3">
            <button type="button" class="btnCustom btnSecondary rounded-pill" (click)="downloadSelectedAttachment()"><i
                    class="fa fa-download" aria-hidden="true"></i> Download selected</button>
            <button type="button" class="btnCustom btnPrimary rounded-pill" (click)="downloadALLAttachment()"><i
                    class="fa fa-download" aria-hidden="true"></i> Download all</button>
        </div>
    </div> -->
<!-- </div>  -->




<!-- client reference number modal popup instead of div ng container id="clientReference"-->
<!-- <ng-container *ngIf="clientReferenceNumberFlag"> -->
<!-- <modal-popup id="clientReference" style="display: none;" [modaldetail]="{'header':'Client Reference'}">
    <ng-container
        *ngIf="(clientReferenceTemplate !=null && clientReferenceTemplate !=undefined && clientReferenceTemplate.length>0)">
        <customform #clientrefform [FormTemplate]="clientReferenceTemplate" [FormData]="clientReferenceData"
            [FormProp]="CRFormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
            (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
        </customform>
    </ng-container>
    <div class="">
        <div class="d-flex justify-content-end p-3">
            <button type="button" class="rounded-pill btnCustom btnPrimary" (click)="updateClientReferenceModalPopup()"
                value="{{'Update' | translate }}">
                {{'Update' | translate }}
            </button>
        </div>
    </div>
</modal-popup> -->
<modal-popup id="clientReference" style="display: none;" [modaldetail]="{'header':'Vendor Reference'}">
    <ng-container
        *ngIf="(clientReferenceTemplate !=null && clientReferenceTemplate !=undefined && clientReferenceTemplate.length>0)">
        <customform #clientrefform [FormTemplate]="clientReferenceTemplate" [FormData]="clientReferenceData"
            [FormProp]="CRFormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
            (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
        </customform>
    </ng-container>

    <div class="">
        <div class="d-flex justify-content-end p-3">
            <button type="button" class="rounded-pill btnCustom btnPrimary" (click)="updateClientReferenceModalPopup()"
                value="{{'Update' | translate }}">
                {{'Update' | translate }}
            </button>
        </div>
    </div>

    <!-- Notification section -->
    <div *ngIf="displayclientNotifybox">
        <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
            (selected)="setNotifyRes($event) " (close)="closeclientNotifybox() "></notification>
    </div>

</modal-popup>

<modal-popup id="RejectCase" style="display: none;" [modaldetail]="{'header':'Remarks'}">
    <ng-container *ngIf="(RejectCaseTemplate !=null && RejectCaseTemplate !=undefined && RejectCaseTemplate.length>0)">
        <customform #rejectCaseform [FormTemplate]="RejectCaseTemplate" [FormData]="RejectCaseData"
            [FormProp]="RejectCaseFormProp" [FormConfig]="RejectCaseFormConfig" [GlobalConfig]="GlobalConfig"
            (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
        </customform>
    </ng-container>

    <div class="">
        <div class="d-flex justify-content-end p-3">
            <button type="button" class="rounded-pill btnCustom btnNegative" (click)="closeModal('RejectCase')"
                value="{{'Cancel' | translate }}">
                {{'Cancel' | translate }}
            </button>
            <button type="button" class="rounded-pill btnCustom btnPrimary" (click)="updateRejectCaseModalPopup()"
                value="{{'Reject Case' | translate }}">
                {{'Reject Case' | translate }}
            </button>
        </div>
    </div>

    <!-- Notification section -->
    <div *ngIf="displayRejectCaseNotifybox">
        <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
            (selected)="setNotifyRes($event)" (close)="closeRejectCaseNotifybox() "></notification>
    </div>

</modal-popup>

<modal-popup id="ReinitiateCase" style="display: none;" [modaldetail]="{'header':'Reinitiate Case'}">

    <ng-container *ngIf="viewReinitiateCase" style="width:100%;">
        <div class="modal-body" style="width:100%">
          <div  class="card-body overflow-auto" style="width:200%;">
            <customform #ReinitiateCaseform style="width:100%;"
                [FormTemplate]="ReinitiateCaseTemplate" [FormData]="ReinitiateCaseData" [FormProp]="FormProp"
                [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
            </customform>
             </div>
        </div>
    </ng-container>

    <div class="">
        <div class="d-flex justify-content-end p-3">
            <button type="button" class="rounded-pill btnCustom btnPrimary"
                (click)="reinitiateCase()" value="{{'Submit' | translate }}">
                {{'Submit' | translate }}
            </button>
        </div>
    </div>

</modal-popup>

<!-- </ng-container> -->