import { Component, OnInit } from '@angular/core';
import { AutoUnsubscribe } from '../shared/app.sharedservice';

@Component({
  selector: 'check-config',
  templateUrl: './check-config.component.html'
})

@AutoUnsubscribe
export class CheckConfigComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
