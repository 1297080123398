import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { AppSharedService, isNullOrUndefined } from 'src/app/shared/app.sharedservice';
import { authConfig } from 'src/app/sso.config';
import { AuthenticationService } from '../auth/authentication.service';


@Component({
    selector: 'app-callback',
    // templateUrl: './callback.component.html',
    template: `<div  style='text-align: center'>
                    <p>Waiting for authentication</p>
                    <!-- <mat-spinner></mat-spinner> -->

                    <div ngIf="ShowErrMsg">
                        <p>{{ErrMsg}}</p>
                    </div>
                    
                </div>`
})
export class CallbackComponent implements AfterViewInit {

    public IsLoading: boolean = true;
    public ShowErrMsg: boolean = true;
    public ErrMsg: string = '';

    constructor(public oauthService: OAuthService, public router: Router,
        public _appSharedService: AppSharedService, public _authService: AuthenticationService) {


    }

    ngAfterViewInit() {
        if (!window.location.hash || window.location.hash.length === 0) {
            const queryString = window.location.search.substring(1); // substring strips '?'
            const path = [window.location.pathname, queryString].join('#');
            window.location.assign(new URL(path, window.location.href).toString());
        } else if (new URLSearchParams(window.location.hash.substring(1)).has('code')) {

            this.IsLoading = false;

            let tokenResponse = this.oauthService.getAccessToken();
            console.log('recieved token response: ' + tokenResponse);

            let claims: any = this.oauthService.getIdentityClaims();
            console.log('token >> ' + JSON.stringify(claims));

            if (!isNullOrUndefined(claims)) {
                if (isNullOrUndefined(this._appSharedService.UserInfo)) {
                    console.log('recieved token response: ');

                    this._appSharedService.IsAuthUser = true;
                    this._appSharedService.IsAuthUser$.next(this._appSharedService.IsAuthUser);

                    // Set the UserInfo from Claims
                    this._appSharedService.setUserInfo(claims);

                    // get the API token
                    this._authService.getAPIToken(claims.email).then(result => {

                        if (result) {
                            this.router.navigate(['app/home']);
                            // window.location.href = window.location.href;
                            // alert('Successfully switched user, Please refresh to proceed');
                        }
                        else {
                            alert('Sorry, unable to complete the API token generation.');
                            // show as invalid mailid
                        }

                    });

                    // this.router.navigate(['/app/home']);
                }
            }
            else {
                console.log('did not recognize callback in URL fragment or query');

            }
            // } else if (new URLSearchParams(window.location.hash.substring(1)).has('error')) {
            //     // http://localhost:4200/callback?error_description=User%20Denied%20Authorization&error=access_denied

            //     this.IsLoading = false;
            //     this.ShowErrMsg = true;
            //     this.ErrMsg = 'User Denied Access';


        } else {
            console.log('did not recognize callback in URL fragment or query');

        }

    }

    get token() {
        let claims: any = this.oauthService.getIdentityClaims();
        console.log('token >> ' + JSON.stringify(claims));
        return claims ? claims : null;
    }

}
