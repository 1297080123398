<!-- <div class="contentScroll p-2"> -->
    <div class="planInfoWraper customForm">
        <div class="filters m-0">
            <div class="d-flex" *ngIf="SearchTemplate!=null && SearchTemplate!=undefined && SearchTemplate.length > 0">
                <!-- <div class='col-auto px-2' -->
                <div class='col-8'>
                    <customform #searchform [FormTemplate]="SearchTemplate" [FormData]="searchData"
                        [FormProp]="FormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                        (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                    </customform>
                </div>

                <div class="col-auto align-self-end ml-auto px-2 pb-2">
                    <!-- <button class="btnCustom btnPrimary rounded-pill">
                      <span>Search</span>
                      <i class="ion ion-md-arrow-forward" aria-hidden="true"></i>
                    </button> -->

                    <!-- <button type="button" class="rounded-pill btnCustom btnPrimary small"
                        data-bs-dismiss="modal" name='search' value="{{'Search' | translate }}"
                        (click)="btnClick('Search')">{{'Search' | translate}}

                        <span class="ps-1">&nbsp;<i class="ion ion-md-arrow-forward" aria-hidden="true"></i></span>
                    </button> -->

<!--                     
                    <button type="button" class="rounded-pill btnCustom btnNegative small"
                        data-bs-dismiss="modal"  name='Clear' value="{{'Clear' | translate }}"
                        (click)="btnClick('Clear')">{{'Clear' | translate}}

                    </button> -->

                    <!-- <button type="button"  class="rounded-pill btnCustom btnPrimary small" data-bs-toggle="collapse" 
                    (click)="btnClick('Initiate Case')" data-bs-dismiss="modal" name='search' value="{{'Initiate BGV' | translate }}">{{'Initiate BGV' | translate }}
                    <span class="ps-1">&nbsp;<i class="ion ion-md-arrow-forward" aria-hidden="true"></i></span>
                  </button> -->



                  <ng-container *ngFor="let actionBar of SearchButtons 
                  | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">

                  <button type="button" [ngClass]="actionBar.CustomClass"
                      (click)="btnClick(actionBar.DisplayName)" [disabled]="!actionBar.IsEnabled"
                      value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName |
                      translate
                      }}
                      <span *ngIf="actionBar.ShowArrow" class="ps-3"><i class="ion ion-md-arrow-forward" aria-hidden="true"></i></span>
                  </button>
              </ng-container>

                </div>
                  
               
            </div>
        </div>
    </div>
<!-- </div> -->

<ng-container>
