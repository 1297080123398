
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { AngularDraggableModule } from 'angular2-draggable';

import { NgbDateCustomParserFormatter } from '../../modules/fieldcontrol/customdatepicker.service';
import { CustomPipeModule } from '../custompipe/custompipe.module';
import { FilterComponent } from './filter.component';


@NgModule({
    declarations: [FilterComponent],
    imports: [
        BrowserModule,
        FormsModule,
        CustomPipeModule,
        NgbModule,
        TranslateModule.forRoot(),
        AngularDraggableModule, NgSelectModule
    ],
    providers: [NgbDateCustomParserFormatter],
    bootstrap: [FilterComponent],
    exports: [FilterComponent]
})
export class FilterModule { }
