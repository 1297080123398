import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AngularDraggableModule } from 'angular2-draggable';
import { LoadingModule } from 'src/modules/loading/loading.module';
import { NotificationModule } from 'src/modules/notification/notification.module';
import { RegistryComponent } from './registry.component';
import { RegistryService } from './registry.service';


@NgModule({
  declarations: [
    RegistryComponent
  ],
  imports: [
    BrowserModule, FormsModule, AngularDraggableModule, ReactiveFormsModule, RouterModule, 
    NotificationModule, LoadingModule
  ],
  providers: [RegistryService],
  bootstrap: [RegistryComponent],
  exports: [RegistryComponent]
})
export class RegistryModule { }
