import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LoadingComponent } from './loading.component';

@NgModule({
  declarations: [
    LoadingComponent
  ],
  imports: [BrowserModule],
  providers: [],
  bootstrap: [LoadingComponent],
  exports: [LoadingComponent]
})

export class LoadingModule { }
