<div class="accordion-item">
    <h2 class="accordion-header" [id]="'S_'+CheckDocData.TransactionId+'_'">
        <button class="accordion-button collapsed justify-content-between" type="button" data-bs-toggle="collapse"
            [attr.data-bs-target]="'#_'+CheckDocData.DocumentTypeName.split(' ').join('').replace('/', '')+'_'+CheckDocData.TransactionId"
            aria-expanded="false"
            [attr.aria-controls]="'_'+CheckDocData.DocumentTypeName.split(' ').join('').replace('/', '')+'_'+CheckDocData.TransactionId">
            <div>
                {{CheckDocData.DocumentTypeName | translate}}
            </div>
            <div class="ms-auto" data-bs-toggle="tooltip" data-bs-placement="right"
                [title]="CheckDocData[DocumentStatusFieldName]">
                <!-- [title]="CheckDocData.DocUploadStatus" -->
                <!-- <span *ngIf="CheckDocData.IsPossiblesuspectMyHireFlag === true">
                    <span [ngClass]="(CheckDocData.IsPossiblesuspectMyHireFlag === true ? 'badge rounded-pill bg-info icon'
                        : '')">
                        <span>
                            Possible Suspect
                        </span>
                    </span>
                </span>
                &nbsp; -->
                <span [ngClass]="(CheckDocData.IsApplicable == false ? 'badge rounded-pill bg-secondary icon'
                                    : CheckDocData.ColorCode == 'SC001' ? 'badge rounded-pill bg-success icon'
                                    : CheckDocData.ColorCode == 'SC002' ? 'badge rounded-pill bg-danger icon'
                                    : CheckDocData.ColorCode == 'SC003' ? 'badge rounded-pill bg-warning icon'
                                    : 'badge rounded-pill bg-secondary icon')">
                    <i aria-hidden="true" [ngClass]="(CheckDocData.IsApplicable == false ? 'ion ion-ios-alert'
                    : CheckDocData.ColorCode == 'SC001' ? 'ion ion-ios-checkmark-circle'
                    : CheckDocData.ColorCode == 'SC002' ? 'ion ion-ios-alert'
                    : CheckDocData.ColorCode == 'SC003' ? 'ion ion-ios-alert'
                    : 'ion ion-ios-alert')"></i> <span>
                        <!-- {{CheckDocData.DocUploadStatus | translate}} -->
                        {{CheckDocData[DocumentStatusFieldName] | translate}}
                    </span>
                </span>
            </div>
            <ng-container *ngIf="enableDeleteButton">
                <ul class="actionList px-3">
                    <li (click)="showConfirmationforSectionDelete(CheckDocData)">
                        <a href="javascript:;">
                            <i class="ion ion-md-trash" aria-hidden="true"></i>
                            Delete
                        </a>
                    </li>
                </ul>
            </ng-container>
        </button>
    </h2>
    <div [id]="'_'+CheckDocData.DocumentTypeName.split(' ').join('').replace('/', '')+'_'+CheckDocData.TransactionId"
        class="accordion-collapse collapse"
        [attr.aria-labelledby]="'S_'+CheckDocData.DocumentTypeName.split(' ').join('').replace('/', '')+'_'+CheckDocData.TransactionId">
        <div class="accordion-body">
            <div class="pt-4">
                <ng-container
                    *ngIf="CheckDocData.WarningMessageList != null && this.CheckDocData.WarningMessageList.length >0">
                    <ng-container
                        *ngFor="let warningMsg of (CheckDocData.WarningMessageList | filterBy: {Active: true} | orderBy: {property: 'Order', direction: '1' , type: 'number'})">
                        <div class="alert alert-warning" role="alert">{{ warningMsg.Message | translate}}</div>
                    </ng-container>
                </ng-container>
                <!--Sub Check Informations-->

                <div *ngIf="(CheckTemplate!=null && CheckTemplate!=undefined && CheckTemplate.length > 0)">
                    <customform #checkform [FormTemplate]="CheckTemplate" [FormData]="SubCheckMetaData[0]"
                        [FormProp]="FormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                        (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                    </customform>
                </div>

                <!--Attachments for Sub Checks-->
                <ng-container
                    *ngIf="(AttachmentTemplate!=null && AttachmentTemplate!=undefined && AttachmentTemplate.length > 0 &&!hideAttachmentgrid)">
                    <p class="text-bold mb-1">
                        <span>Attachments</span>
                    </p>
                    <!-- 
                    <div class="gridContainer"> -->
                    <div class="card-body overflow-auto">
                        <div class="gridAction">
                            <div class="d-flex justify-content-between">
                                <div class="col-auto flex-fill">
                                    <ng-container *ngIf="!RequestData.IsClosed && attachActionButtons != null && attachActionButtons != undefined && !IsReadonly
                                        && attachActionButtons.length > 0">
                                        <actionbar [actionList]="attachActionButtons"
                                            (butClick)="actButtonClick($event)" [noActionClass]="true">
                                        </actionbar>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3" >
                            <grid-dispctrl #checkattachment [FieldTemplate]="AttachmentTemplate" [GridProp]="GridProp"
                                [GridData]="AttachmentData" [GridDataTotalCount]="AttachmentData.length"
                                (FieldClick)="onClick($event)" (FieldChange)="onChangeAttach($event)"
                                [cellLock]="setCellLock" [parent]="this">
                            </grid-dispctrl>

                        </div>
                    </div>
                    <!-- </div> -->
                </ng-container>

                <!--Update and Edit Sub Checks-->
                <ng-container
                    *ngIf="(CheckDocData.UpdateFormTemplate!=null && CheckDocData.UpdateFormTemplate!=undefined && CheckDocData.UpdateFormTemplate.length > 0)">

                    <!-- <div class="dropdown-divider"></div> -->
                    <div class="col-6">
                        <!-- <customform #{{CheckDocData.TransactionId}} -->
                        <customform #editForm [FormTemplate]="CheckDocData.UpdateFormTemplate"
                            [FormId]="'CUSID_'+CheckDocData.TransactionId+'_'" [FormData]="CheckDocData"
                            [FormProp]="EditCheckProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                            (FieldClick)="onClick($event)" [cellLock]="setCellLock" [parent]="this"
                            (FieldChange)="onChange($event)">
                        </customform>
                    </div>
                </ng-container>

                <!--Document Update Button-->
                <!-- <div class="filters buttonHolder">
                    <div class="d-flex justify-content-between ">
                        <div></div>
                        <div>
                            <button type="button" class="rounded-pill btnCustom btnPrimary" (click)="UpdateCheck()"
                                value="{{'Update Check' | translate }}">{{'Update Check' |
                                translate
                                }}
                                <span class="ps-1"><i class="ion ion-md-arrow-forward" aria-hidden="true"></i></span>
                            </button>
                        </div>
                    </div>
                </div> -->

            </div>
        </div>
        <ng-container
            *ngIf="!RequestData.IsClosed && CheckDocData.UpdateFormProperties != null && CheckDocData.UpdateFormProperties != undefined && !IsReadonly">
            <div class="filters buttonHolder">
                <div class="d-flex justify-content-between ">
                    <div></div>
                    <div>
                        <ng-container *ngIf="!IsReadonly">
                            <!-- <ng-container
                                *ngIf="!RequestData.IsClosed && CheckDocData.UpdateFormProperties != null && CheckDocData.UpdateFormProperties != undefined && !IsReadonly"> -->

                            <ng-container
                                *ngFor="let actionBar of (CheckDocData.UpdateFormProperties | filterBy: {IsApplicable: true,HasPermission :true,Group:'Check'}
                                        | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">

                                <button type="button" [ngClass]="actionBar.CustomClass"
                                    (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                    value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName |
                                    translate
                                    }}
                                    <span *ngIf="actionBar.ShowArrow"><i class="ion ion-md-arrow-forward"
                                            aria-hidden="true"></i></span>
                                </button>

                            </ng-container>
                            <!-- </ng-container> -->
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>

    </div>

</div>




<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>

<!--Attachment section-->
<ng-container *ngIf="DisplayAttachment==true">
    <file-upload [Module]="'Check'" [RefId1]="CheckDocData.RequestId" [RefId2]="CheckDocData.CheckId"
        [RefId3]="CheckDocData.DocumentTypeId" [RefId4]="subDocId" [IsMultiple]="false"
        (close)="closeAttachment($event)" [Permissions]="AttachPerm" [UploadType]="UploadType"
        [CandidateId]="RequestData.CandidateId">
    </file-upload>
</ng-container>
<ng-container *ngIf="displayAllAttachmentbox==true">
    <attachment [DisplayMode]="'PREVIEW'" [Module]="'Check'" [RefId1]="previewclickData.RequestId"
        [RefId2]="previewclickData.CheckId" [RefId3]="previewclickData.DocumentTypeId"
        [RefId4]="previewclickData.SubDocumentTypeId" [IsMultiple]="false" (close)="closePreviewAttachment($event)"
        [Permissions]="AttachPerm" [UploadType]="previewclickData.UploadType" [SectionList]="previewclickData">
    </attachment>
</ng-container>

<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
        (selected)="setNotifyRes($event) " (close)="closeNotifybox() "></notification>
</div>

<!-- <modal-popup id="uploadAttachment" style="display: none;" [modaldetail]="{'header':'Upload Attachment'}">


</modal-popup> -->