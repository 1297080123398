import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AngularDraggableModule } from 'angular2-draggable';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { FilterPipeModule } from 'ngx-filter-pipe';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LoadingModule } from 'src/modules/loading/loading.module';
import { FilterModule } from 'src/modules/filter/filter.module';
import { CustomPipeModule } from 'src/modules/custompipe/custompipe.module';
import { FormModule } from 'src/modules/form/form.module';
import { GridReadonlyModule } from 'src/modules/grid-readonly/grid-readonly.module';
import { GridModule } from 'src/modules/grid/grid.module';
import { RequestComponent } from './request.component';
import { CheckComponent } from './check/check.component';
import { DocumentComponent } from './check/document/document.component';
import { EmailComponent } from './email/email.component';
import { QualityControlComponent } from './quality-control/quality-control.component';
import { TabSetModule } from 'src/modules/tabset/tabset.module';
import { RequestService } from './request.service';
import { NotificationModule } from 'src/modules/notification/notification.module';
import { AttachmentModule } from 'src/modules/attachment/attachment.module';
import { ModalModule } from 'src/modules/modal/modal.module';
import { UpdateOverallStatusComponent } from './updateoverallstatus/updateoverallstatus.component';
import { ActionBarModule } from 'src/modules/actionbar/actionbar.module';
import { NonGreenComponent } from './non-green/non-green.component';
import { GridDisplayControlModule } from 'src/modules/grid-dispctrl/grid-dispctrl.module';
import { ExcelViewerModule } from 'src/modules/excel-viewer/excel-viewer.module';
import { CentralHRComponent } from './central-hr/central-hr.component';
import { BGVHeadComponent } from './bgv-head/bgv-head.component';
import { BuHRComponent } from './bu-hr/bu-hr.component';
import { StatusSummaryComponent } from './status-summary/status-summary.component';
import { SubmitAcknowledgementComponent } from './submitAcknowledgement/submitAcknowledgement.component';
import { ExcelExportModule } from 'src/modules/excelexport/excelexport.module';


@NgModule({
  declarations: [
    RequestComponent, CheckComponent, DocumentComponent, QualityControlComponent,
    EmailComponent, UpdateOverallStatusComponent, NonGreenComponent, CentralHRComponent,BGVHeadComponent, BuHRComponent
    ,StatusSummaryComponent,SubmitAcknowledgementComponent
  ],
  imports: [
    BrowserModule, FormsModule, NgbModule,
    AlertModule, PaginationModule, TooltipModule,
    ToastrModule, AngularDraggableModule, TranslateModule,
    LoadingModule, FilterModule, CustomPipeModule, FormModule,ExcelExportModule,
    GridReadonlyModule, FilterPipeModule, GridModule, RouterModule, LoadingModule,
    TabSetModule, NotificationModule, AttachmentModule, ModalModule,
    NgxDocViewerModule, PdfViewerModule, ActionBarModule, GridDisplayControlModule,
    ExcelViewerModule
  ],
  exports: [
    RequestComponent

  ],
  providers: [RequestService],
  bootstrap: [RequestComponent]
})
export class RequestModule {
}
