import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined ,isEmail,isStringNullOrEmpty} from '../shared/app.sharedservice';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ActionCode, ComponentCodes, ComponentConfig, InitiateStatus, ProcessStatus, RoleCodes, StatusColorCode } from '../app.interface';
import { CommonAPIService } from '../shared/app.commonservice';
import { ExcelExportComponent } from 'src/modules/excelexport/excelexport.component';
import { asEnumerable } from 'linq-es2015';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { ModalService } from 'src/modules/modal/modal.service';
import { ModalComponent } from 'src/modules/modal/modal.component';
import { GridComponent } from 'src/modules/grid/grid.component';
import { HomeService } from '../home/home.service';
import { GridDisplayControlComponent } from 'src/modules/grid-dispctrl/grid-dispctrl.component';
import { RequestService } from '../request/request.service';
import { FormComponent } from 'src/modules/form/form.component';
import { RequestComponent } from '../request/request.component';
import { ModalInterface } from 'src/modules/modal/modal.interface';
// import { RequestComponent } from '../request/request.component';
//import { RequestComponent } from '../request/request.component';
@Component({
  selector: 'initiate-case',
  templateUrl: './initiate-case.component.html'
})

@AutoUnsubscribe
export class InitiateCaseComponent implements OnInit {
  // @Input() CaseData: any;
  // @Input() CaseUpdateFlag: boolean = false;

  @Output() close = new EventEmitter();
  @Output() public initiateCaseOnChange = new EventEmitter<any>();

  @ViewChild('infoform', { static: false }) infoform!: ModalComponent;
  @ViewChild('initiategrid', { static: false }) initiategrid!: GridDisplayControlComponent;
  @ViewChild('initiateCheckgrid', { static: false }) initiateCheckgrid!: GridDisplayControlComponent;
  @ViewChild('initiateSearchForm', { static: false }) initiateSearchForm!: FormComponent;
  @ViewChild(RequestComponent)
  requestComponent!: RequestComponent;


  public CaseData: any;
  public caseId: number = 0;
  public CaseUpdateFlag: boolean = false;
  public PreviousDetailsFlag: boolean = false;
  public compTitle: string = 'Home';
  public selectedView: string = 'dashboard';
  public openAdminInitiateCase: boolean = false;
  public hideshowgrid:boolean=false;
  public reLoadCase: boolean = false;
  public AttachmentItemData: any;
  public fileNames: any[] = [];

  public InitiateSearchTemplate: FieldTemplate[] = [];
  public InitiateSearchPermission: any = [];
  public InitiateProperties: any = [];
  public InitiateSearchButtons: any = [];
  public GlobalConfig: any = {};
  public DisplayAttachment: boolean = false;
  public transId: number = 0
  public RequestId: number = 0;
  public CheckId: number = 0;
  public CandidateId: number = 0;
  public EmployeeNumber: number = 0;
  public selFileNames: string = '';
  public uploadSuccess: boolean = false;
   public AttachedFiles: any = null;
   public filesToUpload: any = [];
  public AttachPerm: any = {
    Upload: true,
    Download: false
  };

  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };

  public FormProp: FormProperty = {
    'ColSize': 2,
    'CaptionBold': false,
    'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };

  public InitiateFormProp: FormProperty = {
    'ColSize': 4,
    'CaptionBold': false,
    // 'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };

  public InitiateBGV_FormProp: FormProperty = {
    'ColSize': 4,
    'CaptionBold': false,
    //'IsEditable': true,
    'ShowControlAlways': true
  };

  //declaration for client reference number modal popup
  public RejectCaseTemplate: any[] = [];
  public RejectCaseData: any = {
    RequestId: 0,
    Comments: null,
    IsEditable: 1
  };

  public RejectCaseFormProp: FormProperty = {
    'ColSize': 1,
    'CaptionBold': true,
    'CaptionTop': true,
    'IsEditable': true
  };

  public RejectCaseFormConfig: any = {
    BaseUrl: environment.baseURL
  };

  public reqListTemplate: FieldTemplate[] = [];
  public reqListTemplate_initBGV: FieldTemplate[] = [];
  public reqListTemplate_InitBGVAdmin: FieldTemplate[] = [];
  public reqListData: any[] = [];
  public reqListData_initBGV: any[] = [];
  public reqListDataALL: any[] = [];
  public dataTotalCount: number = 0;
  public DataInput: object = { PageIndex: 0, PageSize: 0 };
  public initiateFormData: any = {};
  public accountCheckMappingData: any[] = [];
  public allFileList: any[] = [];

  public infoListTemplate: FieldTemplate[] = [];
  public selectedInfoData: any;

  public curItemsPerPage: number = 10;

  public GridProp: any = {
    Editable: true,
    PaginationConfig: {
      ItemsPerPage: 10
    },
    ActionBarConfig: {
      ActionBarList: null
    }
  }


  public InfoFormProp: FormProperty = {
    'ColSize': 1,
    'CaptionBold': true,
    'IsEditable': false
  };

  public Config: any = {
    ActionButtons: []
  };

  public IsLoading: boolean = false;
  public subscriptionList$: Subscription[] = [];
  public displayNotifybox: boolean = false;
  public displayRejectCaseNotifybox: boolean = false;
  public notify: NotificationData = new NotificationData();
  public modalInf: ModalInterface = new ModalInterface();
  public curFiltCondn: string = '';
  public DispFilter: boolean = false;
  // public DispLoader: boolean = false;
  public displayBGVGrid: boolean = false;
  public ExcelFileName: string = '';
  // public ExcelData: any = [];
  public SelectedButtonValue: any;
  public OldAccountId: string = '';
  public FormGroup: string = '';
  public searchEvent: any = { 'item': {}, 'action': 'Clear' };

  @ViewChild('excelexport', { static: false }) excelexport!: ExcelExportComponent;
  @ViewChild('overallgrid', { static: false }) overallgrid!: GridComponent;
  @ViewChild('rejectCaseform', { static: false }) rejectCaseform!: ModalComponent;
  @ViewChild('ViewPreviousChecks', { static: false }) ViewPreviousChecks!: ModalComponent;

  constructor(private _router: Router, private _route: ActivatedRoute,
    private _appSharedService: AppSharedService, private _commonAPIService: CommonAPIService
    , private modalService: ModalService, private _requestService: RequestService
  ) {

    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);

  }

  ngOnInit() {

    if (!isNullOrUndefined(this._appSharedService.currentRoleData)) {
      this.selectedView = this._route.snapshot.data['RequestId'];

      if (!isNullOrUndefined(window.history.state.data)) {
        this.CaseData = window.history.state.data;
        this.RequestId = this.CaseData.RequestId;
        this.CandidateId = this.CaseData.CandidateId;
        this.CaseUpdateFlag = true;
      }

      this.getInitiateCaseTemplate();

      // // this.Config = {};
      // // this.InitiateSearchTemplate = [];
      // // this.InitiateProperties = [];
      // // this.reqListTemplate = [];
      // // this.reqListTemplate_InitBGVAdmin = [];
      // // this.reqListTemplate_initBGV = [];
      // // this.RejectCaseTemplate = [];
      // // this.InitiateSearchPermission = [];

      // // this.reqListData = [];
      // // this.reqListDataALL = [];
      // // this.dataTotalCount = 0;
      // // this.initiateFormData = {};
      // // this.searchEvent = { 'item': {}, 'action': 'Clear' };




    }

    // this.SearchData(this.searchEvent, true, false);


  }

  getInitiateCaseTemplate(): void {
    this.IsLoading = true;

    // Clearing Variables
    this.Config = {};
    this.InitiateSearchTemplate = [];
    this.InitiateProperties = [];
    this.reqListTemplate = [];
    this.reqListTemplate_InitBGVAdmin = [];
    this.reqListTemplate_initBGV = [];
    this.RejectCaseTemplate = [];
    this.InitiateSearchPermission = [];

    this.reqListData = [];
    this.reqListDataALL = [];
    this.dataTotalCount = 0;
    this.initiateFormData = {};
    this.searchEvent = { 'item': {}, 'action': 'Clear' };

    let odata: ComponentConfig = {
      Component: 'InitiateCase', ComponentCode: ComponentCodes.InitiateCase
    };

    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {
        let template = res.Data.FieldTemplate!;

        // Initiate Search Form Template
        this.InitiateSearchTemplate = template.filter(x => x.Group === 'SearchFilter');

        // Initiate Search Grid Template
        this.reqListTemplate = template.filter(x => x.Group === 'SearchResult');

        //Initiate Screen Form Template For BGV Manager & Project Initiator 
        this.reqListTemplate_initBGV = template.filter(x => (x.Group === 'Initiate_Case' || x.Group === 'RequestorInitiateCase' || x.Group === 'AdminInitiateCase'));

        
        //Initiate Screen Check Selection Template For BGV Manager 
        this.reqListTemplate_InitBGVAdmin = template.filter(x => x.Group === 'InitiateCaseAdmin');

        //Initiate Screen Reject Case Template For BGV Manager 
        this.RejectCaseTemplate = template.filter(x => x.Group === 'RejectCase');
        this.RejectCaseData.IsEditable = 1;

        // Set Properties
        this.setCompProperties(res.Data.Properties!);

        // Changing Form Column Size Based on CheckId using Property
        if (!isNullOrUndefined(this.InitiateProperties.InitiateFormColSize)) {
          this.InitiateProperties.InitiateFormColSize.forEach((item: any) => {
            if (this._appSharedService.currentRoleData.RoleId === item.RoleId) {
              this.InitiateFormProp.ColSize = item.ColSize;
              this.InitiateBGV_FormProp.ColSize = item.ColSize;
              this.FormGroup = item.FormGroup;
            }
          });
        }

        //Set Permission
        this.InitiateSearchPermission = res.Data.Permission;
        this.setActionButtonPermissions();
        this.enablePreviousCase();
       // this.initiateFormData.IsEditable = 1;
        // this.getInitiateCaseTemplate();
        this.openAdminInitiateCase = this.CaseUpdateFlag === true ? true : false;
        if (this.CaseUpdateFlag === false) {
          this.SearchData(this.searchEvent, true, false, 0);
          this.GridProp.ActionBarConfig.ActionBarList = this.InitiateProperties.CaseInitiateButtons.filter((x: any) => x.Group === 'SearchResult');
          this.hideshowgrid = this._appSharedService.currentRoleData.RoleId === RoleCodes.ProjInitiator ? false : true;

        } else {
          // this.RequestId = this.CaseData.RequestId;
          
          this.hideshowgrid = this._appSharedService.currentRoleData.RoleId === RoleCodes.ProjInitiator ? false : true;
         // this.InitiateBGV_FormProp.IsEditable=false;
          this.GridProp.ActionBarConfig.ActionBarList = this.InitiateProperties.CaseInitiateButtons.filter((x: any) => x.Group === 'InitiateCaseAdmin');
          this.CandidateId = this.CaseData.CandidateId;
          this.SearchData(this.searchEvent, true, false, this.CaseData.RequestId);
          // this.displayBGVGrid = true;
          // this.OldAccountId = this.CaseData.AccountId;
          // this.initiateFormData = this.CaseData;
          // this.getAccountCheckMappingList(this.initiateFormData);
        }
      }

      // this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getRequestTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }

  setCompProperties(Properties: any[]) {
    if (!isNullOrUndefined(Properties) && Properties.length > 0) {

      // 2) Blocking Cols based on custom values
      this.InitiateProperties.BlockCols = (Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase())[0].JSONData.toString()) : []);


      // Other component specific props
      Properties.forEach(prop => {
        // avoiding generic props
        if (!isNullOrUndefined(prop.Name) &&
          prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
          && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
          this.InitiateProperties[prop.Name] = JSON.parse(prop.JSONData.toString());
        }
      });
    }
  }

  SearchData(event: any, allPendingFlag: boolean, searchClicked: boolean, requestId: number = 0): void {
    console.log(event)

    /*if (searchClicked == true && !isNullOrUndefined(event.action) && event.action.toUpperCase() === 'SEARCH' &&
      ((isNullOrUndefined(event.item.Value) || isNullOrUndefined(event.item.Select)
        || event.item.Select.length == 0 || event.item.Value.trim().length == 0) && (isNullOrUndefined(event.item.AccountId)
          || event.item.AccountId.length == 0)))*/
          
          
      if (searchClicked == true && !isNullOrUndefined(event.action) && event.action.toUpperCase() === 'SEARCH' &&
      (( isNullOrUndefined(event.item.Select) || event.item.Select.length == 0 ) && (isNullOrUndefined(event.item.AccountId)
          || event.item.AccountId.length == 0))){

      //  alert("Please select both to proceed search")


      this.notify = {
        info: {
          header: 'Search Alert',
          // message: 'Please select (candidate id and value) or (account name) to proceed with Search.',
          message: 'Please select a column and value or Account Name to proceed with Search.',

          yes: '',
          no: '',

          IsHeaderHidden: true,
          HeaderIcon: 'fa fa-exclamation-circle text-warning',
          IsCrossEnable: true,
          SizeClass: 'modal-sm'
        },

        action: '',
        item: null
      };

      this.displayNotifybox = true;

      return;
    }

    else if (searchClicked == true && ( isNullOrUndefined(event.item.Value) || event.item.Value.trim().length < 3)) {

      //  alert("Please select both to proceed search")

      this.notify = {
        info: {
          header: 'Search Alert',
          message: 'Please enter atleast 3 characters to search',
          yes: '',
          no: '',

          IsHeaderHidden: true,
          HeaderIcon: 'fa fa-exclamation-circle text-warning',
          IsCrossEnable: true,
          SizeClass: 'modal-sm'
        },

        action: '',
        item: null
      };

      this.displayNotifybox = true;

      return;
    }


    this.searchEvent = event;

    this.IsLoading = true;

    this.reqListData = [];
    this.reqListDataALL = [];
    this.dataTotalCount = 0;
    this.initiateFormData = {};

    let odata = event.item;


    if (!isNullOrUndefined(event.action) && event.action.toUpperCase() === 'CLEAR') {
      allPendingFlag = true;
      odata = { RequestId: requestId };
    }

    let subscribe$: Subscription = this._requestService.getEmpDetailsBySearchForInitiateBGV(odata, allPendingFlag).subscribe(result => {
      // if (result.Data==null && result.Message =="Already a case is running") {
      //   this.IsLoading = false;

      //   /////added for already bgv running case///////
      //   //   if(event.item){
      //   this.notify = {
      //     info: {
      //       header: 'Search Alert',
      //       // message: 'Please select (candidate id and value) or (account name) to proceed with Search.',
      //       message: 'Already a BGV case is running',

      //       yes: '',
      //       no: '',

      //       IsHeaderHidden: true,
      //       HeaderIcon: 'fa fa-exclamation-circle text-warning',
      //       IsCrossEnable: true,
      //       SizeClass: 'modal-sm'
      //     },

      //     action: '',
      //     item: null
      //   };
      //   this.displayNotifybox = true;
      //   // }
      //   return;
      // }

      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
        if (result.Message == '' || result.Message == null) {
          this.notify = {};
        }
        else {
          //   /////added for already bgv running case///////
          this.notify = {
            info: {
              header: 'Search Alert',
              message: result.Message,

              yes: 'Yes',
              no: 'No',

              IsHeaderHidden: true,
              HeaderIcon: 'fa fa-exclamation-circle text-warning',
              IsCrossEnable: true,
              SizeClass: ''
            },

            action: 'MULTIBGV',
            item: null
          };
          this.displayNotifybox = true;
          this.IsLoading = false;
        }
        result.Data.forEach((element: any) => {
          if (element.EmployeeNumber === -1) {
            element.EmployeeNumber="NA";
          }
        });
        this.reqListData = result.Data;
        console.log(this.reqListData);
        this.dataTotalCount = this.reqListData.length;

        this.reqListDataALL = this.reqListData;

        if (this.CaseUpdateFlag === true) {
          this.displayBGVGrid = true;
          this.OldAccountId = this.reqListData[0].AccountId;
          this.initiateFormData = this.reqListData[0];
          this.initiateFormData.RequestId = this.RequestId;
          this.accountCheckMappingData = [];
          this.getAccountCheckMappingList(this.initiateFormData);


          if (this.reqListData[0].ExistingRequestId != 0 && this.reqListData[0].ExistingRequestId != null) {
            this.caseId = this.reqListData[0].ExistingRequestId;
            this.enablePreviousCase();
          }

          setTimeout(() => {
            if (!isNullOrUndefined(this.initiateSearchForm)) {
              if(this.CaseUpdateFlag===true)
              {
                //this.InitiateBGV_FormProp.IsEditable=true;
                this.initiateSearchForm.enableFields('RequestType,AccountId,PONumber,RequestEntity,PersonalEmailId',false);                
               // this.initiateSearchForm.enableFields('EmployeeNumber,Name,CandidateId,DateOfJoining',false);                
              }
              else{
              this.initiateSearchForm.enableFields('RequestType,AccountId,PONumber,RequestEntity,PersonalEmailId', true);
              this.initiateSearchForm.setDispcols();
              }
            }
          }, 50);


        }

        // this.reqListData.forEach((item) => {
        //   item.Action = '0,1';
        //   item.BirthDate = new Date();
        //   item.DateOfJoining = new Date();
        //   // item.IsEditable = 1;
        // });

        // this._toastr.success('Request stopped successfully', this.CompTitle);

        // setTimeout(() => {
        //   if (!isNullOrUndefined(this.overallgrid)) {
        //     this.overallgrid.Pagination.CurrentPage = 1;
        //   }
        // }, 50);

      }
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
        //this._toastr.warning(result.Message, this.CompTitle);
      }

      if (this.CaseUpdateFlag === false) {
        this.IsLoading = false;
      }

    },

      err => {
        this.IsLoading = false;
      });


    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);


  }

  pageItemsChange() {

    if (this.curItemsPerPage > 0) {
      this.GridProp.PaginationConfig.ItemsPerPage = this.curItemsPerPage;
    }
    else {
      this.GridProp.PaginationConfig.ItemsPerPage = 100;
    }


    // reload grid data
    if (isNullOrUndefined(this.searchEvent.item.Value) || this.searchEvent.item.Value.length == 0) {
      this.SearchData(this.searchEvent, true, false, 0);
    }
    else {
      this.SearchData(this.searchEvent, false, true, 0);
    }


  }


  actionButtonClick(data: any) {
    if (!isNullOrUndefined(data.Name) && data.Name !== '') {
      // this.actionCode = '';
      // this.actionCode = data.ActionCode;
      switch (data.Name.toUpperCase()) {
        case 'REQUESTORCANCEL':
        case 'ADMINCANCEL':
          // this.closeInitiateBGVSection();
          this.showNotificationMessage('Are you sure you want to leave this page without initiation?', 2, 'CANCELINITIATE', this.RequestId, 'Yes', 'No');
          break;
          case 'CANCEL':
          // this.closeInitiateBGVSection();
          this.showNotificationMessage('Are you sure you want to leave this page?', 2, 'CANCELINITIATE', this.RequestId, 'Yes', 'No');
          break;
        case 'INITIATENOW':
          if (this.validateInitiateFields(data.ActionCode)) {
            this.showNotificationMessage('Are you sure you want to initiate the case for Background Verification?', 1, 'SUBMITCASE', null, 'Initiate Now', 'Cancel');
          }
          break;

        case 'INITIATETOVENDOR':
          if (this.CaseUpdateFlag === false) {
            if (this.validateInitiateFields(data.ActionCode) && this.validateMandatoryFields(data.ActionCode)) {
              this.showNotificationMessage('Are you sure you want to initiate case to the vendor?', 1, 'INITIATETOVENDOR', data.ActionCode, 'Initiate', 'Cancel');
            }
          } else if (this.validateMandatoryFields(data.ActionCode)) {
            this.showNotificationMessage('Are you sure you want to initiate case to the vendor?', 1, 'INITIATETOVENDOR', data.ActionCode, 'Initiate', 'Cancel');
          }

          break;
        case 'INITIATETOEMPLOYEE':
          if (this.validateMandatoryFields(data.ActionCode)) {
            this.showNotificationMessage('Are you sure you want to initiate case to the employee?', 1, 'INITIATETOEMPLOYEE', data.ActionCode, 'Initiate', 'Cancel');
          }

          break;
        case 'ADMINREJECT':
          if (this.validateMandatoryFields(data.ActionCode)) {
            this.showNotificationMessage('Are you sure you want to Reject the Case?', 1, 'ADMINREJECT', data.ActionCode, 'Reject Case', 'Cancel');
          }

          break;
        case 'ADMINCASECLOSE':
          if (this.validateMandatoryFields(data.ActionCode)) {
            this.showNotificationMessage('Are you sure you want to Close the Case?', 1, 'ADMINCASECLOSE', data.ActionCode, 'Close Case', 'Cancel');
          }

          break;
        
        case 'UPDATE':
          if (this.validateInitiateFields(data.ActionCode)) {
          this.showNotificationMessage('Are you sure you want to update the case?', 1, 'UPDATECASE', data.ActionCode, 'Update', 'Cancel');
          }
          break;
      }
    }
  }

  updateCase(odata:any) {
    this.IsLoading = true;

    let subscribe$: Subscription = this._requestService.UpdateCaseProjInit(odata).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
        this.showNotificationMessage('Case updated successfully', 1, '', null, '', '');
        this.IsLoading = false;
        
      }

    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
    
  }
  closeInitiateBGVSection(): void {

    if (!isNullOrUndefined(this.accountCheckMappingData) && this.accountCheckMappingData.length > 0 &&
      this.accountCheckMappingData.filter(x => !isNullOrUndefined(x.FileName) && x.FileName !== '').length > 0) {
      this.deleteAttachment(this.accountCheckMappingData, false);
    } else {

      if (this.CaseUpdateFlag === true) {
        // this.close.emit({});
        this.RequestId = 0;
        this.CandidateId = 0;
        this.CaseData = null;
        this.CaseUpdateFlag = false
        this._router.navigate(['app/home/search']);
        this.displayBGVGrid = false;
        this.initiateFormData = null;
        this.accountCheckMappingData = [];
        this.SearchData(this.searchEvent, true, false, 0);
      }
      else {
        this.initiateCase('DELETE');
      }

    }
  }
  exportToExcel() {

    if (this.reqListData.length === 0) {
      // this.toastr.warning("Report Data is empty");
    }
    else {
      this.IsLoading = true;

      let month = new Date().getMonth() + 1;
      this.ExcelFileName = 'BGV-Search ' + new Date().getDate() + '-' + month + '-' + new Date().getFullYear() + ' ' + new Date().toLocaleTimeString()

      let tempdata = [];
      // tempdata.push({ Name: 'Search Data', Data: this.reqListData });

      tempdata.push({
        Name: 'Search Data', Data: this.reqListData
        , ColumnList: !isNullOrUndefined(this.Config.DownloadColumns) && this.Config.DownloadColumns.length > 0 ? this.Config.DownloadColumns.map((x: any) => x.FieldName) : null
        , FieldTemplate: this.reqListTemplate
      });

      this.excelexport.generateExcelTable_fromJSON(tempdata, this.ExcelFileName);

      this.IsLoading = false;
    }
  }

  showFilter(event?: any): void {
    this.DispFilter = !this.DispFilter;

    if (!isNullOrUndefined(event)) {
      this.curFiltCondn = event.curFiltCondn;
    }
  }

  setFilter(event: any) {
    if (event.sCondn.length > 0) {
      this.reqListData = asEnumerable(this.reqListDataALL)
        .Where($ => (eval(event.sCondn)))
        .ToArray();
      this.dataTotalCount = this.reqListData.length;
    }
    else {
      this.reqListData = this.reqListDataALL;
      this.dataTotalCount = this.reqListData.length;
    }
  }

  onClick(event: any): void {

    let selitem = event.item;

    switch (event.fieldname.toUpperCase()) {
      case 'ACTION':
        //  this.enableDisableButtons();
        // event.icon.IconCSS
        switch (event.icon.Value) {
          case '0':
            // View
            this.GridProp.ActionBarConfig.ActionBarList = this.InitiateProperties.CaseInitiateButtons.filter((x: any) => x.Group === 'InitiateCaseAdmin');
            this.initiateCase('NEW', selitem);
            console.log("----------start---------")
            console.log(selitem.ExistingRequestId);
            if (selitem.ExistingRequestId != 0 && selitem.ExistingRequestId != null) {
              this.caseId = selitem.ExistingRequestId;
            }
            this.enablePreviousCase();
            /////need to comment when backend changes done as for now requestid not coming///
            // else {
            //   this.caseId = 838
            // }

            // this.displayBGVGrid = true;
            // this.OldAccountId = selitem.AccountId;
            // this.initiateFormData = selitem;
            // this.openAdminInitiateCase = this._appSharedService.currentRoleData.RoleId === 9 ? true : false;
            // if (this.openAdminInitiateCase) {
            //   this.getAccountCheckMappingList(selitem);
            // }
            // this._router.navigate(['app/home/search/case', selitem.RequestId]);
            break;
        }

        break;
      case 'ISSELECTED':
        // selitem.InitiateStatus = null;
        // selitem.InitiateStatusDesc = null;
        setTimeout(() => {
          selitem.InitiateStatus = null;
          selitem.InitiateStatusDesc = null;
        }, 50);
        break;
      case 'UPLOADACTION':

        // event.icon.IconCSS
        switch (event.icon.Value) {
          case '0':
            // Upload 
            this.transId = 0;
            this.transId = selitem.CheckId;
            this.CheckId =selitem.CheckId;
            this.AttachmentItemData = selitem;
            this.DisplayAttachment = true;
            break;
        }

        break;
      // case 'CANDIDATEID':
      // case 'CANDIDATENAME':

      //   this._router.navigate(['app/home/search/case', selitem.RequestId]);

      //   break;

    }
  }


  setNotifyRes(event: any): void {
    console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;
    switch (event.action.toUpperCase()) {
      case 'STOPCASE':
        if (event.result.toUpperCase() === 'YES') {
          // this.requestStopCase();
        }
        break;
        case 'MULTIBGV':
          if (event.result.toUpperCase() === 'YES') {
            let selitem = this.reqListData[0];
            this.initiateCase('NEW', selitem);
            console.log("----------start---------")
            console.log(selitem.ExistingRequestId);
            if (selitem.ExistingRequestId != 0 && selitem.ExistingRequestId != null) {
              this.caseId = selitem.ExistingRequestId;
            }
            this.enablePreviousCase();
          }
          break;
      case 'SUBMITCASE':
        if (event.result.toUpperCase() === 'INITIATE NOW') {
          // this.requestStopCase();
          this.initiateCase('CREATE');
        }
        break;
      case 'INITIATETOVENDOR':
        if (event.result.toUpperCase() === 'INITIATE') {
          this.initiateToEmployee(event.item, 'Case is initiated to vendor successfully');
        }
        break;
      case 'INITIATETOEMPLOYEE':
        if (event.result.toUpperCase() === 'INITIATE') {
          this.initiateToEmployee(event.item, 'Case is initiated to employee successfully');
        }
        break;
      case 'ADMINREJECT':
        if (event.result.toUpperCase() === 'REJECT CASE') {
          // this.initiateCase('DELETE', null, 'Case is rejected successfully.');
          this.openModal('RejectCase');
          // this.getAllFileList();
          // this.initiateToEmployee(event.item, 'Case is rejected successfully.');
        }
        break;

      case 'CANCELINITIATE':
        if (event.result.toUpperCase() === 'YES') {
          this.closeInitiateBGVSection();
        }
        break;
      case 'ADMINCASECLOSE':
        if (event.result.toUpperCase() === 'CLOSE CASE') {
          this.initiateToEmployee(event.item, 'Case is closed successfully');
        }
        break;
      case 'UPDATECASE':
        if (event.result.toUpperCase() === 'UPDATE') {
          this.updateCase(this.reqListData);
        }
        break;

    }
    if (canClose) {
      this.closeNotifybox();
    }
  }

  closeNotifybox(): void {
    if (this.reLoadCase === true) {
      if (this.CaseUpdateFlag === true) {
        // this.close.emit({});
        this.RequestId = 0;
        this.CandidateId = 0;
        this.CaseData = null;
        this.CaseUpdateFlag = false
        this.initiateFormData = null;
        this.displayBGVGrid = false;
        this.accountCheckMappingData = [];
        this._router.navigate(['app/home/search']);
      } else {
        this.RequestId = 0;
        this.CandidateId = 0;
        this.CaseData = null;
        this.CaseUpdateFlag = false
        this.initiateFormData = null;
        this.accountCheckMappingData = [];
        this.displayBGVGrid = false;
        this.ngOnInit();
        // this.SearchData(this.searchEvent, true, false, 0);
        this._router.navigate(['app/initiatecase']);

      }
    }

    this.displayNotifybox = false;
  }

  openModal(id: string) {
    this.modalService.open(id);
    document.getElementById('txtvalue')?.focus();
  }

  closeModal(id: string) {
    if (id == 'ViewPreviousChecks') {
      this.PreviousDetailsFlag = false;
    }

    this.modalService.close(id);
  }

  onChange(event: any): void {


    let selitem: any = event.item;
    console.log(selitem)
    let itemindex: any = event.item_index;
    // Emitting onchange event of resource plan to parent container
    selitem.IsModified = 1;
    selitem.IsRowHighlight = selitem.IsModified;
    switch (event.fieldname.toUpperCase()) {
      case 'ACCOUNTID':
        if (this.openAdminInitiateCase) {
          this.accountCheckMappingData = [];
          this.getAccountCheckMappingList(selitem);
        }
        break;
      case 'ISSELECTED':
        setTimeout(() => {
          if (selitem.IsSelected === false) {
            selitem.InitiateStatus = InitiateStatus.Not_Applicable;
            selitem.InitiateStatusDesc = InitiateStatus.Not_Applicable_Desc;
            selitem.ColorCode = null;
            selitem.ColorCodeDesc = null;
          } else {
            selitem.InitiateStatus = InitiateStatus.Verification_Completed;
            selitem.InitiateStatusDesc = InitiateStatus.Verification_Completed_Desc;
            selitem.ColorCode = StatusColorCode.Green;
            selitem.ColorCodeDesc = StatusColorCode.Green_desc;
          }
          this.changeCellLockFlag(selitem, itemindex);
          this.enableDisableButtons();
        }, 150);
        break;
      case 'INITIATESTATUS':
        // case 'COLORCODE':
        setTimeout(() => {
          if (selitem.InitiateStatus === InitiateStatus.Verification_Completed) {
            selitem.ColorCode = StatusColorCode.Green;
            selitem.ColorCodeDesc = StatusColorCode.Green_desc;
          } else {
            selitem.ColorCode = null;
            selitem.ColorCodeDesc = null;
          }
          this.changeCellLockFlag(selitem, itemindex);
          this.enableDisableButtons();
        }, 150);
        break;
      case 'COLORCODE':
        this.enableDisableButtons();
        break;
    }




    let eventdata = {
      fieldname: event.fieldname
    };

    this.initiateCaseOnChange.emit(eventdata);
  }


  setActionButtonPermissions(): void {

    if (!isNullOrUndefined(this.InitiateProperties.CaseInitiateButtons) && this.InitiateProperties.CaseInitiateButtons.length > 0) {
      this.InitiateProperties.CaseInitiateButtons.forEach((item: any) => {
        console.log("action buttons init case")
        console.log(item)
        if (!isNullOrUndefined(item.Name) && item.Name !== '') {
          item.HasPermission = false;
          item.IsApplicable = false;
          let peritem = this.InitiateSearchPermission.indexOf(item.Name);
          if (peritem >= 0) {
            item.HasPermission = true;
            item.IsApplicable = true;
          }
        }
      });
    }
  }

  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item
    };

    this.displayNotifybox = true;
  }

  showRejectCaseNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item
    };

    this.displayRejectCaseNotifybox = true;
  }

  getAccountCheckMappingList(selitem: any) {
    this.IsLoading = true;

if(selitem.EmployeeNumber ==="NA"){
  selitem.EmployeeNumber =-1;
}
    let odata: object = { 'AccountId': selitem.AccountId, 'EmployeeNumber': selitem.EmployeeNumber };

    let subscribe$: Subscription = this._requestService.getAccountCheckMappingList(odata).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        this.accountCheckMappingData = result.Data;
        this.accountCheckMappingData.forEach(item => {
          item.IsSelected = true;
          item.FileReferenceId = 0;
          item.FileName = '';
        });
      }
      this.enableDisableButtons();
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting getAccountCheckMappingList.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  isAlphaNumeric(str: string) {

    let code, i, len;
    for (i = 0, len = str.length; i < len; i++) {

      code = str.charCodeAt(i);

      if (!(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123) // lower alpha (a-z)
        && code !== 45 // hypen
        && code !== 95 // underscore
      ) {
        return false;
      }
    }
    return true;

  };

  initiateCase(mode?: string, selitem?: any, msg?: any) {

    this.IsLoading = true;
    this.initiateFormData = !isNullOrUndefined(selitem) ? selitem : this.initiateFormData;
    this.initiateFormData.Mode = mode;
    this.initiateFormData.Comments=this.RejectCaseData.Comments;
    if(this.initiateFormData.EmployeeNumber==="NA"){
      this.initiateFormData.EmployeeNumber=-1;
    }
    // if(isNullOrUndefined(this.initiateFormData.PersonalEmailId)||this.initiateFormData.PersonalEmailId=='')
    // {

    //    this.InitiateBGV_FormProp.IsEditable=true;
    // }
    // else if(!isNullOrUndefined(this.initiateFormData.PersonalEmailId)){
    //   this.InitiateBGV_FormProp.IsEditable=false;
    // }
  

    let subscribe$: Subscription = this._requestService.initiateCase(this.initiateFormData).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        if (!isNullOrUndefined(result.Data.RequestId) && result.Data.RequestId > 0) {
          this.RequestId = result.Data.RequestId;
          this.initiateFormData.RequestId = this.RequestId;
        }

        if (mode?.toUpperCase() === 'CREATE') {
          this.showNotificationMessage('Case is submitted for Background Verification successfully', 1, '', null, '', '');
          this.displayBGVGrid = false;
          this.initiateFormData = null;
          this.accountCheckMappingData = [];
          this.ngOnInit();
          // this.SearchData(this.searchEvent, true, false, 0);

        } else if (mode?.toUpperCase() === 'DELETE') {
          if (this.CaseUpdateFlag === true) {
            this.reLoadCase = true;
            if (!isNullOrUndefined(msg) && msg !== '') {
              this.showRejectCaseNotificationMessage(msg, 1, '', null, '', '');
            }
          } else {
            this.displayBGVGrid = false;
            this.initiateFormData = null;
            this.accountCheckMappingData = [];
            this.ngOnInit();
            // this.SearchData(this.searchEvent, true, false, 0);
          }

          // if (this.CaseUpdateFlag === true) {
          //   this.RequestId = 0;
          //   this.CandidateId = 0;
          //   this.CaseData = null;
          //   this.CaseUpdateFlag = false
          //   this._router.navigate(['app/home/search']);
          // }
          // this.displayBGVGrid = false;
          // this.initiateFormData = null;
          // this.accountCheckMappingData = [];
          // this.SearchData(this.searchEvent, true, false, 0);

        } else {

          this.displayBGVGrid = true;
          this.OldAccountId = selitem.AccountId;
          this.initiateFormData = selitem;
          this.openAdminInitiateCase = this._appSharedService.currentRoleData.RoleId === RoleCodes.IntiateInsuff ? true : false;
          if (this.openAdminInitiateCase) {
            this.accountCheckMappingData = [];
            this.getAccountCheckMappingList(selitem);
          }
          this.enableDisableButtons();

        }
        // if (!isNullOrUndefined(actionCode) && actionCode !== '') {
        //   this.initiateToEmployee(actionCode);
        // } else {
        //   this.showNotificationMessage('Case is submitted for Background Verification successfully', 1, '', null, '', '');
        //   this.displayBGVGrid = false;
        //   this.initiateFormData = null;
        //   this.SearchData(this.searchEvent, true, false, 0);

        // }
      }
      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  enableDisableButtons(): void {
    if (!isNullOrUndefined(this.InitiateProperties.CaseInitiateButtons)
      && this.InitiateProperties.CaseInitiateButtons.length > 0
      && (this.InitiateProperties.CaseInitiateButtons.filter((x: any) => x.Group === 'AdminCaseInitiator')).length > 0) {

      let primaryPageButt = this.InitiateProperties.CaseInitiateButtons.filter((x: any) =>
        x.Group === 'AdminCaseInitiator');

      // if (!isNullOrUndefined(this.accountCheckMappingData) && this.accountCheckMappingData.length > 0) {
      primaryPageButt.forEach((item: any) => {

        if (!isNullOrUndefined(item.Name) && item.Name !== '') {
          item.IsEnabled = false;
          switch (item.Name.toUpperCase()) {
            case 'INITIATETOEMPLOYEE':
              if (!this.enableCloseCase()
                && !isNullOrUndefined(this.accountCheckMappingData) && this.accountCheckMappingData.length > 0
                && this.accountCheckMappingData.filter(x => x.InitiateStatus === InitiateStatus.Yet_To_Initiate).length > 0
                && this.accountCheckMappingData.filter(x => x.IsSelected === true).length > 0
              ) {
                item.IsEnabled = true;
              }
              break;
            case 'INITIATETOVENDOR':
              item.IsEnabled = false;
              break;
            
            case 'UPDATE':
              item.IsEnabled = true;
              break;
              case 'CANCEL':
              item.IsEnabled = true;
              break;
              
            case 'ADMINREJECT':
              item.IsEnabled = this.CaseUpdateFlag === false ? false : true;
              break;
            case 'ADMINCANCEL':
              item.IsEnabled = true
              break;
            case 'ADMINCASECLOSE':
              item.IsEnabled = this.enableCloseCase();
              break;
            // case 'PREVIOUSDETAILS':
            //   item.IsEnabled = this.enablePreviousCase();
            //   break;
          }
        }
      });

    }

  }

  //// previous case check ///
  enablePreviousCase() {
    let primaryPreviousButt = this.InitiateProperties.CaseInitiateButtons.filter((x: any) =>
      x.Group === 'InitiateCaseAdmin');
    primaryPreviousButt.forEach((item: any) => {
      if (!isNullOrUndefined(item.Name) && item.Name !== '') {
        item.IsApplicable = false;
        switch (item.Name.toUpperCase()) {
          case 'PREVIOUSDETAILS':
            item.IsApplicable = this.PreviousCaseApplicable();
            break;
        }
      }
    });

  }

  PreviousCaseApplicable(): boolean {
    let flag: boolean = false;
    if (this.caseId == 0 || this.caseId == null) {
      flag = false;
    }
    else {
      flag = true;
    }
    return flag;
  }


  enableInitiateToVendor(): boolean {
    let flag: boolean = false;
    if (!isNullOrUndefined(this.accountCheckMappingData) && this.accountCheckMappingData.length > 0
      && this.accountCheckMappingData.filter(x => x.IsSelected === true).length > 0
      && this.accountCheckMappingData.filter(x => x.InitiateStatus === InitiateStatus.Verification_Completed).length === this.accountCheckMappingData.filter(x => x.IsSelected === true).length
    ) {
      flag = true;
    }
    return flag;
  }

  enableCloseCase(): boolean {
    let flag: boolean = false;
    if (!isNullOrUndefined(this.accountCheckMappingData) && this.accountCheckMappingData.length > 0
      && this.accountCheckMappingData.filter(x => x.IsSelected === true).length > 0
      && this.accountCheckMappingData.filter(x => x.InitiateStatus === InitiateStatus.Verification_Completed).length === this.accountCheckMappingData.filter(x => x.IsSelected === true).length
      && this.accountCheckMappingData.filter(x => x.InitiateStatus === InitiateStatus.Verification_Completed).length === this.accountCheckMappingData.filter(x => !isNullOrUndefined(x.ColorCode) && x.ColorCode !== '').length
    ) {
      flag = true;
    }
    return flag;
  }


  setCellLock(fieldname: string, item: any, parent: InitiateCaseComponent): boolean {
    let bLock = false;
    let BlkCols: any[] = [];

    if (!isNullOrUndefined(parent.InitiateProperties.BlockCols) && parent.InitiateProperties.BlockCols.length > 0) {
      parent.InitiateProperties.BlockCols.forEach((blockitem: any) => {
        if (blockitem.Value === item[blockitem.Field]) {
          blockitem.BlockCols.forEach((item: any) => {
            BlkCols.push(item);
          });
        }
      });
    }
    if (BlkCols.length > 0) {
      bLock = (BlkCols.indexOf(fieldname.toUpperCase()) >= 0 ? true : false);
    }

    return bLock;
  }

  changeCellLockFlag(selitem: any, itemindex: any, qcSectionName?: any) {
    if (!isNullOrUndefined(this.initiateCheckgrid)) {
      let temp: any = [];
      temp.push(selitem);
      this.initiateCheckgrid.initCellLock(temp);
      this.initiateCheckgrid.refreshAllFieldsForRow(selitem, itemindex);
    }
  }

  initiateToEmployee(actionCode: any, msg: string) {
    this.IsLoading = true;
    this.accountCheckMappingData.forEach(item => {
      item.RequestId = this.RequestId;
      this.initiateFormData.Mode = 'CREATE';
      item.EmployeeNumber  = this.initiateFormData.EmployeeNumber;
      item.CandidateId = this.initiateFormData.CandidateId;
      item.ActionCode = actionCode;
    });

    this.initiateFormData.requestCheckDetailsbyemps = this.accountCheckMappingData;
    this.initiateFormData.IsClosed = actionCode === ActionCode.Close_case ? 1 : 0;
    this.reLoadCase = false;

    let subscribe$: Subscription = this._requestService.InitiateToEmployee(this.initiateFormData).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
        this.reLoadCase = true;
        this.showNotificationMessage(msg, 1, '', null, '', '');

        // if (this.CaseUpdateFlag === true) {
        //   // this.close.emit({});
        //   this.RequestId = 0;
        //   this.CandidateId = 0;
        //   this.CaseData = null;
        //   this.CaseUpdateFlag = false
        //   this.initiateFormData = null;
        //   this.accountCheckMappingData = [];
        //   this._router.navigate(['app/home/search']);
        // } else {
        //   this._router.navigate(['app/initiatecase']);
        // }
      }
      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  validateInitiateFields(actioncode: any): boolean {
    let valid: boolean = true;

    
    //Intitate to Vendor
    if (actioncode === ActionCode.InitiateToVendor) {
      if (isNullOrUndefined(this.initiateFormData.RequestType) || this.initiateFormData.RequestType === '') {
        this.showNotificationMessage('Please select BGV Type.', 2, '', null, '', '');
        valid = false;
      }
    }

    //Common for both initiation
    if (isNullOrUndefined(this.initiateFormData.AccountId) || this.initiateFormData.AccountId === '') {
      this.showNotificationMessage('Please select New Account to be allocated.', 2, '', null, '', '');
      valid = false;
    }
    // else if (!isNullOrUndefined(this.initiateFormData.AccountId) && !isNullOrUndefined(this.OldAccountId) &&
    //   this.initiateFormData.AccountId === this.OldAccountId) {
    //   this.showNotificationMessage('You cannot initiate the case for same account. Please select different account.', 2, '', null, '', '');
    //   valid = false;
    // }
    else if (isNullOrUndefined(this.initiateFormData.RequestEntity) || this.initiateFormData.RequestEntity === '') {
      this.showNotificationMessage('Please select Entity.', 2, '', null, '', '');
      valid = false;
    }
    else if (isNullOrUndefined(this.initiateFormData.PONumber) || this.initiateFormData.PONumber === '') {
      this.showNotificationMessage('Please provide Project Code.', 2, '', null, '', '');
      valid = false;
    }
    else if (!this.isAlphaNumeric(this.initiateFormData.PONumber)) {
      this.showNotificationMessage('Project Code should not contain white space or special characters.', 2, '', null, '', '');
      valid = false;
    }
    else if (isNullOrUndefined(this.initiateFormData.PersonalEmailId) || (!isStringNullOrEmpty(this.initiateFormData.PersonalEmailId) && !isEmail(this.initiateFormData.PersonalEmailId))) {
      this.showNotificationMessage('Please provide valid Personal EmailId.', 2, '', null, '', '');
      valid = false;
    }
    else if((!isStringNullOrEmpty(this.initiateFormData.PersonalEmailId) && isEmail(this.initiateFormData.PersonalEmailId)))
    {
      // Check Email Domain Name is Not NULL

      let name = this.initiateFormData.PersonalEmailId.substring(0, this.initiateFormData.PersonalEmailId.lastIndexOf("@capgemini.com"));
      // let domain = this.editMemberData.EmailId.substring(this.editMemberData.EmailId.lastIndexOf("@") + 1);

      // Checking @ symbol in domain name Email Domain Name 
      if (!isStringNullOrEmpty(name)) {
        this.showNotificationMessage('Please provide personal EmailID and not Official EmailId.', 2, '', null, '', '');
        return false;
      }
      // } else
      //  if (this.initiateFormData.PersonalEmailId.lastIndexOf("@") >= 0) {
      //   this.showNotificationMessage('Please enter a valid PPersonal Email id.', 2, '', null, '', '');
      //   return false;
      // }
    
    }
    
 
    return valid;
  }

  validateMandatoryFields(actioncode: any): boolean {
    let valid: boolean = true;

    //Intitate to Employee
    if (actioncode === ActionCode.InitiateToEmployee) {

      if (isNullOrUndefined(this.initiateFormData.RequestType) || this.initiateFormData.RequestType === '') {
        this.showNotificationMessage('Please select BGV Type.', 2, '', null, '', '');
        valid = false;
      }
      //Common for both initiation
      else if (isNullOrUndefined(this.initiateFormData.AccountId) || this.initiateFormData.AccountId === '') {
        this.showNotificationMessage('Please select New Account to be allocated.', 2, '', null, '', '');
        valid = false;
      }
      // else if (!isNullOrUndefined(this.initiateFormData.AccountId) && !isNullOrUndefined(this.OldAccountId) &&
      //   this.initiateFormData.AccountId === this.OldAccountId) {
      //   this.showNotificationMessage('You cannot initiate the case for same account. Please select different account.', 2, '', null, '', '');
      //   valid = false;
      // }
      else if (isNullOrUndefined(this.initiateFormData.RequestEntity) || this.initiateFormData.RequestEntity === '') {
        this.showNotificationMessage('Please select Entity.', 2, '', null, '', '');
        valid = false;
      }
      else if (isNullOrUndefined(this.initiateFormData.PONumber) || this.initiateFormData.PONumber === '') {
        this.showNotificationMessage('Please provide Project Code.', 2, '', null, '', '');
        valid = false;
      }
      else if (!this.isAlphaNumeric(this.initiateFormData.PONumber)) {
        this.showNotificationMessage('Project Code should not contain white space or special characters.', 2, '', null, '', '');
        valid = false;
      }

      if (!isNullOrUndefined(this.accountCheckMappingData) && this.accountCheckMappingData.length > 0
        && this.accountCheckMappingData.filter(x => (isNullOrUndefined(x.ColorCode) || x.ColorCode === '') && x.InitiateStatus === InitiateStatus.Verification_Completed).length > 0) {
        this.showNotificationMessage('Please select color code.', 2, '', null, '', '');
        valid = false;
      }
      else if (!isNullOrUndefined(this.accountCheckMappingData) && this.accountCheckMappingData.length > 0
        && this.accountCheckMappingData.filter(x => (isNullOrUndefined(x.FileName) || x.FileName === '') && x.InitiateStatus === InitiateStatus.Verification_Completed).length > 0) {
        this.showNotificationMessage('Please upload the Report.', 2, '', null, '', '');
        valid = false;
      }
      else if (!isNullOrUndefined(this.accountCheckMappingData) && this.accountCheckMappingData.length > 0
        && this.accountCheckMappingData.filter(x => (isNullOrUndefined(x.Comments) || x.Comments === '') && (x.ColorCode === StatusColorCode.Red || x.ColorCode === StatusColorCode.Amber) && x.InitiateStatus === InitiateStatus.Verification_Completed).length > 0) {
        this.showNotificationMessage('Please enter Remarks.', 2, '', null, '', '');
        valid = false;
      }
      else if (isNullOrUndefined(this.initiateFormData.PersonalEmailId) || (!isStringNullOrEmpty(this.initiateFormData.PersonalEmailId) && !isEmail(this.initiateFormData.PersonalEmailId))) {
        this.showNotificationMessage('Please provide valid Personal EmailId.', 2, '', null, '', '');
        valid = false;
      }
      else if((!isStringNullOrEmpty(this.initiateFormData.PersonalEmailId) && isEmail(this.initiateFormData.PersonalEmailId)))
      {
        // Check Email Domain Name is Not NULL
  
        let name = this.initiateFormData.PersonalEmailId.substring(0, this.initiateFormData.PersonalEmailId.lastIndexOf("@capgemini.com"));
        // let domain = this.editMemberData.EmailId.substring(this.editMemberData.EmailId.lastIndexOf("@") + 1);
  
        // Checking @ symbol in domain name Email Domain Name 
        if (!isStringNullOrEmpty(name)) {
          this.showNotificationMessage('Please provide personal EmailID and not Official EmailId.', 2, '', null, '', '');
          return false;
        }
      
      }

    }

    //Intitate to Close Case
    if (actioncode === ActionCode.Close_case) {

      if (isNullOrUndefined(this.initiateFormData.RequestType) || this.initiateFormData.RequestType === '') {
        this.showNotificationMessage('Please select BGV Type.', 2, '', null, '', '');
        valid = false;
      }
      //Common for both initiation
      else if (isNullOrUndefined(this.initiateFormData.AccountId) || this.initiateFormData.AccountId === '') {
        this.showNotificationMessage('Please select New Account to be allocated.', 2, '', null, '', '');
        valid = false;
      }
      // else if (!isNullOrUndefined(this.initiateFormData.AccountId) && !isNullOrUndefined(this.OldAccountId) &&
      //   this.initiateFormData.AccountId === this.OldAccountId) {
      //   this.showNotificationMessage('You cannot initiate the case for same account. Please select different account.', 2, '', null, '', '');
      //   valid = false;
      // }
      else if (isNullOrUndefined(this.initiateFormData.RequestEntity) || this.initiateFormData.RequestEntity === '') {
        this.showNotificationMessage('Please select Entity.', 2, '', null, '', '');
        valid = false;
      }
      
      else if (isNullOrUndefined(this.initiateFormData.PONumber) || this.initiateFormData.PONumber === '') {
        this.showNotificationMessage('Please provide Project Code.', 2, '', null, '', '');
        valid = false;
      }
      else if (!this.isAlphaNumeric(this.initiateFormData.PONumber)) {
        this.showNotificationMessage('Project Code should not contain white space or special characters.', 2, '', null, '', '');
        valid = false;
      }

      if (!isNullOrUndefined(this.accountCheckMappingData) && this.accountCheckMappingData.length > 0
        && this.accountCheckMappingData.filter(x => (isNullOrUndefined(x.ColorCode) || x.ColorCode === '') && x.InitiateStatus !== InitiateStatus.Not_Applicable).length > 0) {
        this.showNotificationMessage('Please select color code.', 2, '', null, '', '');
        valid = false;
      }
      else if (!isNullOrUndefined(this.accountCheckMappingData) && this.accountCheckMappingData.length > 0
        && this.accountCheckMappingData.filter(x => (isNullOrUndefined(x.FileName) || x.FileName === '') && x.InitiateStatus !== InitiateStatus.Not_Applicable).length > 0) {
        this.showNotificationMessage('Please upload the Report.', 2, '', null, '', '');
        valid = false;
      }
      
      else if (!isNullOrUndefined(this.accountCheckMappingData) && this.accountCheckMappingData.length > 0
        && this.accountCheckMappingData.filter(x => (isNullOrUndefined(x.Comments) || x.Comments === '') && (x.ColorCode === StatusColorCode.Red || x.ColorCode === StatusColorCode.Amber) && x.InitiateStatus !== InitiateStatus.Not_Applicable).length > 0) {
        this.showNotificationMessage('Please enter Remarks.', 2, '', null, '', '');
        valid = false;
      }

      else if (isNullOrUndefined(this.initiateFormData.PersonalEmailId) || (!isStringNullOrEmpty(this.initiateFormData.PersonalEmailId) && !isEmail(this.initiateFormData.PersonalEmailId))) {
        this.showNotificationMessage('Please provide valid Personal EmailId.', 2, '', null, '', '');
        valid = false;
      }
      else if((!isStringNullOrEmpty(this.initiateFormData.PersonalEmailId) && isEmail(this.initiateFormData.PersonalEmailId)))
      {
        // Check Email Domain Name is Not NULL
  
        let name = this.initiateFormData.PersonalEmailId.substring(0, this.initiateFormData.PersonalEmailId.lastIndexOf("@capgemini.com"));
        // let domain = this.editMemberData.EmailId.substring(this.editMemberData.EmailId.lastIndexOf("@") + 1);
  
        // Checking @ symbol in domain name Email Domain Name 
        if (!isStringNullOrEmpty(name)) {
          this.showNotificationMessage('Please provide personal EmailID and not Official EmailId.', 2, '', null, '', '');
          valid = false;
        }
      
      }

    }
    return valid;
  }

  closeAttachment(event: any): void {
    console.log(event.data);

    let selectedData = this.accountCheckMappingData.filter(x => x.CheckId === this.transId)[0];

    if (!isNullOrUndefined(event) && event.Reload === true) {
      // selectedData.FilePath = event.data[0].FilePath;
      selectedData.FileReferenceId = event.data[0].transactionId;
      selectedData.FileName = event.data[0].fileName;
    }

    this.DisplayAttachment = false;
  }

  actButtonClick(event: any): void {

    console.log(event)
    this.PreviousDetailsFlag = false;
    switch (event.buttonname.toUpperCase()) {
      ///added for view bgv previous
      case 'PREVIOUSDETAILS':

        this.PreviousDetailsFlag = true;
        this.modalInf =
        {
          header: 'View History',
          size: 'modal-xl modal-dialog-centered modal-dialog-scrollable',
          width: '2500',
          height: '1112'
        };
        this.openModal('ViewPreviousChecks');
        break;

    }
  }

  deleteAttachment(modifieditems: any, Rejectflag: boolean = false) {
    this.IsLoading = true;

    let deleteData: any[] = [];

    let fileItem = modifieditems.filter((x: any) => !isNullOrUndefined(x.FileName) && x.FileName !== '');

    fileItem.forEach((item: any) => {
      let odata: object = {
        'Module': item.Module,
        'Reference1': Number(this.RequestId),
        'Reference2': 0,
        'Reference3': 0,
        'Reference4': 0,
        'TransactionId': item.FileReferenceId,
        // 'FilePath': this.reportData[0].FilePath,
        'FilePath': '',
        'FileName': item.FileName,
        'UserId': this._appSharedService.UserAccessInfo.actinguserid
      };

      deleteData.push(odata);

    });

    let subscribe$: Subscription = this._requestService.deleteAttachment(deleteData).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
        if (Rejectflag === true) {
          this.initiateCase('DELETE', null, 'Case is rejected successfully.');
        }
        else if (this.CaseUpdateFlag === false) {
          this.initiateCase('DELETE');
        } else {
          this.CaseUpdateFlag = false;
          this.displayBGVGrid = false;
          this.initiateFormData = null;
          this.accountCheckMappingData = [];
          this.RequestId = 0;
          this.CandidateId = 0;
          this.CaseData = null;
          this._router.navigate(['app/home/search']);
        }
      }


      // this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  getAllFileList() {
    this.allFileList = [];
    this.IsLoading = true;

    let subscribe$: Subscription = this._requestService.getAllFileList(this.RequestId).subscribe(result => {
      if (result !== undefined && result !== null) {
        this.allFileList = result.Data;
        if (!isNullOrUndefined(this.allFileList) && this.allFileList.length > 0) {
          this.allFileList.forEach(item => {
            item.FileReferenceId = item.TransactionId;
            item.FileName = item.FileName;
            item.FilePath = '';
          });
          this.deleteAttachment(this.allFileList, true);
        }
      } else {
        this.allFileList = [];
        this.initiateCase('DELETE', null, 'Case is rejected successfully.');
      }
      // this.IsLoading = false;

    },
      err => {
        console.log('Error on getting getAccountCheckMappingList.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  updateRejectCaseModalPopup() {
    if (!isNullOrUndefined(this.RejectCaseData.Comments) && this.RejectCaseData.Comments !== '' && this.RejectCaseData.Comments.trim().length > 0) {
      this.getAllFileList();
    } else {
      this.showRejectCaseNotificationMessage('Please provide remarks.', 2, '', null, '', '');
    }

     
  }

  closeRejectCaseNotifybox(): void {
    if (this.reLoadCase === true) {
      if (this.CaseUpdateFlag === true) {
        // this.close.emit({});
        this.RequestId = 0;
        this.CandidateId = 0;
        this.CaseData = null;
        this.CaseUpdateFlag = false
        this.initiateFormData = null;
        this.displayBGVGrid = false;
        this.accountCheckMappingData = [];
        this._router.navigate(['app/home/search']);
      } else {
        this.RequestId = 0;
        this.CandidateId = 0;
        this.CaseData = null;
        this.CaseUpdateFlag = false
        this.initiateFormData = null;
        this.accountCheckMappingData = [];
        this.displayBGVGrid = false;
        this.SearchData(this.searchEvent, true, false, 0);
        this._router.navigate(['app/initiatecase']);

      }
    }
    this.displayRejectCaseNotifybox = false;
  }

  //Bulk upload Logic
  handleUpload(e: any): void {
    if (e.target.files && e.target.files[0]) {
        let formData: FormData = new FormData();
        let filesToUpload = <Array<File>>e.target.files;
        let filenames: string = '';
        for (let i = 0; i < filesToUpload.length; i++) {
            formData.append('uploadedFiles', filesToUpload[i], filesToUpload[i].name);
            filenames = filenames + '; ' + filesToUpload[i].name;
            this.selFileNames = filenames.substr(2, filenames.length);
            this.AttachedFiles = formData;
        }

        // this.selFileNames = filesToUpload[0].name;

        // let reader = new FileReader();
        // reader.onload = (event: any) => {
        //     this.AttachedFiles = event.target.result;
        // }
        // reader.readAsDataURL(e.target.files[0]);
    }
    else {
        this.selFileNames = '';
        this.AttachedFiles = null;
    }

    // e.target.value = '';
}
downloadExcelfile(): void {
  let subscribe$: Subscription = this._requestService.downloadSampleExcelforBulkProjectBGVCases().subscribe(result => {
    this.IsLoading = true;
    if (!isNullOrUndefined(result)) {
        let url = window.URL.createObjectURL(result.data);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
         a.download = "ProjectBGVCasesSampleExcel.xlsx";
        //a.download =    data.FileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
        // this._toastr.success('Resume downloaded successfully', this.CompTitle);

        this.notify = {
            info: {
                header: 'Confirmation Alert',
                message: 'Document downloaded successfully',
                yes: '',
                no: '',

                IsHeaderHidden: true,
                HeaderIcon: 'fa fa-check-circle text-success',
                IsCrossEnable: true,
                SizeClass: 'modal-sm'
            },

            action: '',
            item: null
        };

        this.displayNotifybox = true;
    }
    this.IsLoading = false;

},
    err => {
        this.IsLoading = false;
    });
this.subscriptionList$.push(subscribe$);
}
uploadFiles(): void {
  this.filesToUpload = [];
  if (!isNullOrUndefined(this.AttachedFiles)) {

    this.filesToUpload.push(this.selFileNames.split(';'));
      console.log(this.filesToUpload[0]);
      if (this.filesToUpload[0].length > 1) {
          this.selFileNames = this.filesToUpload[0].toString();
          this.selFileNames = this.selFileNames.replace(/\, /g, '-')

          console.log(this.selFileNames)
          if (!isNullOrUndefined(this.selFileNames) && this.selFileNames !== '' && !this.validateFileName(this.selFileNames.split('.').slice(0, -1).join('.'))) {
              this.IsLoading = true;

              this.uploadSuccess = false;

              this._requestService.uploadBulkProjectBGVCases(this.AttachedFiles).subscribe(res => {
                  if (res !== null && res.Status === 200) {
                      // alert('file Upload success');
                      this.notify = {
                          info: {
                              header: 'Confirmation Alert',
                              message: 'File uploaded successfully.',
                              yes: '',
                              no: '',

                              IsHeaderHidden: true,
                              HeaderIcon: 'fa fa-check-circle text-success',
                              IsCrossEnable: true,
                              SizeClass: 'modal-sm'
                          },

                          action: 'UPLOAD_SUCCESS',
                          item: res.Data
                      };

                      this.displayNotifybox = true;

                      this.selFileNames = '';
                      this.filesToUpload[0] = '';
                      this.uploadSuccess = true;
                       
                  }
                  else {
                      this.notify = {
                          info: {
                              header: 'Confirmation Alert',
                              message: res.Message,
                              yes: '',
                              no: '',

                              IsHeaderHidden: true,
                              HeaderIcon: 'fa fa-exclamation-circle text-warning',
                              IsCrossEnable: true,
                              SizeClass: 'modal-sm'
                          },

                          action: '',
                          item: null
                      };

                      this.displayNotifybox = true;

                  }
                  this.IsLoading = false;
              },
                  err => {
                      // this.toastr.error('Error on file upload.', this.CompTitle);
                      this.IsLoading = false;
                  });

          }
          else {
              this.notify = {
                  info: {
                      header: 'Confirmation Alert',
                      message: 'Invalid file name. File name should not contain whitespaces.',
                      yes: '',
                      no: '',

                      IsHeaderHidden: true,
                      HeaderIcon: 'fa fa-exclamation-circle text-warning',
                      IsCrossEnable: true,
                      SizeClass: 'modal-sm'
                  },

                  action: '',
                  item: null
              };

              this.displayNotifybox = true;
          }
      }
   
          console.log(this.selFileNames)
          if (!isNullOrUndefined(this.selFileNames) && this.selFileNames !== '' && !this.validateFileName(this.selFileNames.split('.').slice(0, -1).join('.'))) {
              this.IsLoading = true;

              this.uploadSuccess = false;

              this._requestService.uploadBulkProjectBGVCases(this.AttachedFiles).subscribe(res => {
                if (res !== null && res.Status === 200&&res.Data!="NoData") {
                      // alert('file Upload success');
                      this.notify = {
                          info: {
                              header: 'Confirmation Alert',
                              message: 'File uploaded successfully.',
                              yes: '',
                              no: '',

                              IsHeaderHidden: true,
                              HeaderIcon: 'fa fa-check-circle text-success',
                              IsCrossEnable: true,
                              SizeClass: 'modal-sm'
                          },

                          action: 'UPLOAD_SUCCESS',
                          item: res.Data
                      };

                      this.displayNotifybox = true;

                      this.selFileNames = '';
                       this.filesToUpload[0] = '';
                      this.uploadSuccess = true;
                     

                  }
                  else {
                      this.notify = {
                          info: {
                              header: 'Confirmation Alert',
                              message: res.Message,
                              yes: '',
                              no: '',

                              IsHeaderHidden: true,
                              HeaderIcon: 'fa fa-exclamation-circle text-warning',
                              IsCrossEnable: true,
                              SizeClass: 'modal-sm'
                          },

                          action: '',
                          item: null
                      };

                      this.displayNotifybox = true;

                  }
                  this.IsLoading = false;
              },
                  err => {
                      // this.toastr.error('Error on file upload.', this.CompTitle);
                      this.IsLoading = false;
                  });

          }
          else {
              this.notify = {
                  info: {
                      header: 'Confirmation Alert',
                      message: 'Invalid file name. File name should not contain whitespaces.',
                      yes: '',
                      no: '',

                      IsHeaderHidden: true,
                      HeaderIcon: 'fa fa-exclamation-circle text-warning',
                      IsCrossEnable: true,
                      SizeClass: 'modal-sm'
                  },

                  action: '',
                  item: null
              };

              this.displayNotifybox = true;
          }
      

  }
  else {
      // this.toastr.warning('Kindly select a file for upload.', this.CompTitle);
      this.notify = {
          info: {
              header: 'Confirmation Alert',
              message: 'Kindly select a file for upload.',
              yes: '',
              no: '',

              IsHeaderHidden: true,
              HeaderIcon: 'fa fa-exclamation-circle text-warning',
              IsCrossEnable: true,
              SizeClass: 'modal-sm'
          },

          action: '',
          item: null
      };

      this.displayNotifybox = true;
  }
}

validateFileName(fileName: any): boolean {

  var specialChars = "<>[]{}?:;|'\"\\,/`=";
  //"<>@!#$%^&*()_+[]{}?:;|'\"\\,./~`-=";
  //   let i: number = 0;

  for (let i = 0; i < specialChars.length; i++) {
      if (fileName.indexOf(specialChars[i]) > -1) {
          return true
      }
  }

  return false;
}


}
