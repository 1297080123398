
<ng-container *ngIf="(IsInternalUser == false && _appSharedService.IsAuthUser == false)"> 
  <router-outlet></router-outlet>
</ng-container>


<!-- <ng-container *ngIf="token"> -->
<ng-container *ngIf="(_appSharedService.IsAuthUser)">
<!-- <ng-container *ngIf="true"> -->
  <router-outlet></router-outlet>
</ng-container>
  

