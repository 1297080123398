import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
// import { isNumber, toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { TranslateService } from '@ngx-translate/core';
// import { NgbDatepickerI18nDefault } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-i18n';
// import { isNumber } from 'util';
import { getProperty, isNumber } from '../shared/_services/utility.service';
import { DatePipe, TranslationWidth } from '@angular/common';


@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {

  constructor(private datePipe: DatePipe, private translateService: TranslateService) {
    super();
  }

  // parse(value: string): NgbDateStruct {
  //   if (value) {
  //     const dateParts = value.trim().split('/');
  //     if (dateParts.length === 1 && isNumber(dateParts[0])) {
  //       return { day: toInteger(dateParts[0]), month: null, year: null };
  //     } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
  //       return { day: toInteger(dateParts[0]), month: toInteger(dateParts[1]), year: null };
  //     } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
  //       return { day: toInteger(dateParts[0]), month: toInteger(dateParts[1]), year: toInteger(dateParts[2]) };
  //     }
  //   }
  //   return null;
  // }

  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('/');
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return { day: parseInt(dateParts[0]), month: null as any, year: null as any};
      } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
        return { day: parseInt(dateParts[0]), month: parseInt(dateParts[1]), year: null as any };
      } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
        return { day: parseInt(dateParts[0]), month: parseInt(dateParts[1]), year: parseInt(dateParts[2]) };
      }
    }
    
    return null as any;
  }

  format(date: NgbDateStruct): string {
    let temp: any = date ?
      // `${isNumber(date.day) ? padNumber(date.day) : ''}/${isNumber(date.month) ? padNumber(date.month) : ''}/${date.year}` :
      // this.dp.transform(new Date(date.year, (date.month - 1), date.day)) :
      this.datePipe.transform(new Date(date.year, (date.month - 1), date.day), 
          (this.translateService as any)['AppDateFormat']) : '';

    return temp;

  }
}

// // Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// // use the Angular LOCALE_ID value
// @Injectable()
// export class I18n extends NgbDatepickerI18nDefault {
//   language = 'en-US';

//   constructor(private translateService: TranslateService) {
//     super(translateService.currentLang);
//     this.language = translateService.currentLang;
//   }

// }

// export class DynamicLocaleId extends String {

//   currentLang: string;

//   constructor(protected service: TranslateService, private _appSharedService: AppSharedService) {
//     super('');

//     this.currentLang = this.service.currentLang;

//     this._appSharedService.SelectedLanguage$.subscribe((data) => {
//       this.currentLang = data.toString();
//     });
//   }

//   toString() {
//     // return this.service.currentLang;
//     return this.currentLang;
//   }
// }


const I18N_VALUES = {
  'en-US': {
    weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
  'en-IN': {
    weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
  'fr': {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
    weekLabel: 'sem'
  },
  'de': {
    weekdays: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
    months: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
  }
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'en-US';

  constructor(private translateService: TranslateService) {
    this.language = translateService.currentLang;
  }
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  // getWeekdayShortName(weekday: number): string {
  //   return (I18N_VALUES as any)[this._i18n.language].weekdays[weekday - 1];
  // }
  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    // throw new Error('Method not implemented.');
    return (I18N_VALUES as any)[this._i18n.language].weekdays[weekday - 1];
  }
  // getWeekLabel(): string { 
  //   return (I18N_VALUES as any)[this._i18n.language].weekLabel; 
  // }

  getMonthShortName(month: number): string {
    return (I18N_VALUES as any)[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  
}
