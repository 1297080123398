import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { AngularDraggableModule } from 'angular2-draggable';
import { SwitchUserComponent } from './switchuser.component';


@NgModule({
  declarations: [
    SwitchUserComponent
  ],
  imports: [
    BrowserModule, FormsModule, AngularDraggableModule, TranslateModule
  ],
  providers: [],
  bootstrap: [SwitchUserComponent],
  exports: [SwitchUserComponent]
})
export class SwitchUserModule { }
