import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { environment } from 'src/environments/environment';
import { Router } from '../../node_modules/@angular/router';
import { AuthenticationService } from './core/auth/authentication.service';
// import { isNullOrUndefined } from 'util';
import { AppSharedService, isNullOrUndefined } from './shared/app.sharedservice';
import { authConfig } from './sso.config';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'BGV-UI';

  IsInternalUser: boolean= false;

  constructor(public _appSharedService: AppSharedService, public _authService: AuthenticationService,
    public translate: TranslateService, public oauthService: OAuthService, public router: Router) {

    if (environment.AppInstance == 'INTERNAL') {
      this.IsInternalUser = true;
    }

    if (this.IsInternalUser == true) {

      this.configureSingleSignOn();

      // // for Testing
      // // Hard-coded login without Ping SSO 
      // let claims: any = JSON.parse(`{"sub":"djennife","aud":"bgvdev","jti":"geYJDtm5DYLVjqaX4qFeXL","iss":"https://ssoprep.capgemini.com","iat":1639545750,"exp":1639549350,"acr":"urn:oasis:names:tc:SAML:2.0:ac:classes:TLSClient","auth_time":1639545684,"address":"address","openid":"openid","profile":"profile","preferred_username":"djennife","given_name":"DAPHNE","devicetype":"Not Managed","EmployeeType":"Employee","employeeNumber":"46086307","https://outlook.office.com/EWS.AccessAsUser.All":"https://outlook.office.com/EWS.AccessAsUser.All","updated_at":9223372036854776000,"DisplayName":"JENNIFER, DAPHNE","name":"JENNIFER, DAPHNE","Country":"India","LOGIN":"djennife","GGID":"46086307","sn":"JENNIFER","write":"write","email":"daphne.jennifer@capgemini.com","group":"cn=DGCorpEmployees,ou=CorpGroups,dc=capgemini,dc=com","pi.sri":"_bzganK8lr3tgZse7iwP0PG51B4.ZXUtY2VudHJhbC0x.uXqv","nonce":"dTM1SVNjcX5RZFBla0NDT1RHNmhoUGtqX1luaVVvQ0d4MTBFUmNmR1ROTFBu"}`);
      // console.log('token >> ' + JSON.stringify(claims));

      // if (!isNullOrUndefined(claims)) {

      //   this._appSharedService.IsAuthUser = true;
      //   this._appSharedService.IsAuthUser$.next(this._appSharedService.IsAuthUser);

      //   this._appSharedService.setUserInfo(claims);

      //   // this.router.navigate(['/app/home']);

      //   // Get the API token
      //   this._authService.getAPIToken(claims.email).then(result => {

      //     if (result) {
      //       this.router.navigate(['app/home']);
      //       // window.location.href = window.location.href;
      //       // alert('Successfully switched user, Please refresh to proceed');
      //     }
      //     else {
      //       alert('Sorry, unable to complete the API token generation.');
      //       // show as invalid mailid
      //     }

      //   });
      // }
    }

  }

  ngOnInit() { }


  configureSingleSignOn() {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndLogin()
      .then((hasReceivedTokens: any) => {
        // this would have stored all the tokens needed
        if (hasReceivedTokens) {

          // get claims on receiving the token successfully
          let claims: any = this.oauthService.getIdentityClaims();
          console.log('token >> ' + JSON.stringify(claims));

          if (!isNullOrUndefined(claims)) {

            this._appSharedService.IsAuthUser = true;
            this._appSharedService.IsAuthUser$.next(this._appSharedService.IsAuthUser);

            this._appSharedService.setUserInfo(claims);

            // this.router.navigate(['/app/home']);

            // Get the API token
            this._authService.getAPIToken(claims.email).then(result => {

              if (result) {
                this.router.navigate(['app/home']);
                // window.location.href = window.location.href;
                // alert('Successfully switched user, Please refresh to proceed');
              }
              else {
                alert('Sorry, unable to complete the API token generation.');
                // show as invalid mailid
              }

            });
          }


          // carry on with your app
          return Promise.resolve();
        }
        else {
          // may want to check if you were previously authenticated
          if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()) {
            return Promise.resolve();
          } else {
            // to safe guard this from progressing through the calling promise,
            // resolve it when it directed to the sign up page
            return new Promise(resolve => {
              this.oauthService.initLoginFlow();
              // example if you are using explicit flow
              window.addEventListener('unload', () => {
                resolve(true);
              });
            });
          }
        }
      });


  }

  // login() {
  //   this.oauthService.initImplicitFlow();

  //   // console.log('token >> ' + JSON.stringify(this.token));
  // }

  logout() {
    this.oauthService.logOut();
  }

  // get token() {
  //   let claims: any = this.oauthService.getIdentityClaims();
  //   console.log('token >> ' + JSON.stringify(claims));

  //   if (!isNullOrUndefined(claims)) {
  //     this._appSharedService.IsAuthUser = true;
  //     this._appSharedService.IsAuthUser$.next(this._appSharedService.IsAuthUser);


  //     // let jwtHelper = new JwtHelper();
  //     // let parsedToken = jwtHelper.decodeToken(this.token.access_token);

  //     // console.log('parsedToken = ' + JSON.stringify(parsedToken));
  //     this._appSharedService.setUserInfo(claims);

  //   }

  //   return claims ? claims : null;
  // }




}

