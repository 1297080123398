import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { FilterPipeModule } from 'ngx-filter-pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { NgIdleModule } from '@ng-idle/core';
import { OAuthModule } from 'angular-oauth2-oidc';

import { AppRoutingModule } from './app-routing.module';
import { CommonAPIService } from './shared/app.commonservice';
import { AppSharedService } from './shared/app.sharedservice';
import { AuthGuardService } from './core/auth/auth-guard.service';
import { AuthenticationService } from './core/auth/authentication.service';
import { CustomHttpInterceptor } from './core/auth/customhttpinterceptor';
import { CustomPipeModule } from '../modules/custompipe/custompipe.module';
import { AppComponent } from './app.component';
import { LoginModule } from './core/login/login.module';
import { SessionModalComponent } from './core/session/session.component';
import { LoadingModule } from '../modules/loading/loading.module';
import { CanDeactivateGuard } from './shared/app.can-deactivate.service';
import { ToastService } from './shared/toast.service';
import { HomeModule } from './home/home.module';
import { environment } from 'src/environments/environment';
import { ResizeService } from './core/screen-size-detector/resize.service';
import { HolidayComponent } from './holiday/holiday.component';
import { RequestModule } from './request/request.module';
import { AccountComponent } from './account/account.component';
import { UserRolesComponent } from './user-roles/user-roles.component';
import { CheckConfigComponent } from './check-config/check-config.component';
import { MenuBarModule } from 'src/modules/menubar/menubar.module';
import { ReportModule } from './report/report.module';
import { NotificationModule } from 'src/modules/notification/notification.module';
import { SwitchUserModule } from './core/switchuser/switchuser.module';
import { RegistryModule } from './core/registry/registry.module';
import { AppMainComponent } from './app-main.component';
import { InitiateCaseModule } from './initiate-case/initiate-case.module';
import { AdminModule } from './admin/admin.module';

registerLocaleData(localeDe);
registerLocaleData(localeFr);


@NgModule({
  declarations: [
    AppComponent, AppMainComponent, SessionModalComponent, HolidayComponent, AccountComponent, UserRolesComponent, CheckConfigComponent
  ],
  imports: [
    BrowserModule, HttpClientModule, BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ProgressbarModule.forRoot(),
    NgIdleModule.forRoot(),
    FilterPipeModule,
    AppRoutingModule,
    LoginModule,
    NotificationModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      autoDismiss: true,
      closeButton: true,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      enableHtml: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    OAuthModule.forRoot(),
    CustomPipeModule, LoadingModule,
    NgbModule, MenuBarModule,
    HomeModule,InitiateCaseModule,
    RequestModule,
    SwitchUserModule, RegistryModule,ReportModule,AdminModule
  ],
  providers: [CommonAPIService, AppSharedService, AuthenticationService, AuthGuardService, ToastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    { provide: APP_BASE_HREF, 
      // useValue: '/' 
      // useValue: '/External/' 
      // useValue: window['_app_base'] || '/',
      useValue: '/' + (window.location.pathname.split('/')[1] || '')
    },
    CanDeactivateGuard,
    ResizeService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {

  constructor(private _appSharedService: AppSharedService, private _authService: AuthenticationService) {
    // this._appSharedService.setAppId(environment.AppID);
  }

}


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  // return new TranslateHttpLoader(http);
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

