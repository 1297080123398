import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

// @Injectable({
//   providedIn: 'root'
// })

@Injectable()
export class ToastService {

  private toastrconfig: any;

  constructor(private _toastr: ToastrService, private _translate: TranslateService) {
    this.toastrconfig = {
      timeOut: 5000,
      autoDismiss: true,
      closeButton: true,
      positionClass: 'toast-top-center',
      preventDuplicates: true
    };
  }

  info(message:any, title?:any) {
    this._toastr.info(this._translate.instant(message), this._translate.instant(title), this.toastrconfig);
  }

  warning(message:any, title?:any) {
    this._toastr.warning(this._translate.instant(message), this._translate.instant(title), this.toastrconfig);
  }

  success(message:any, title?:any) {
    this._toastr.success(this._translate.instant(message), this._translate.instant(title), this.toastrconfig);
  }

  error(message:any, title?:any) {
    this._toastr.error(this._translate.instant(message), this._translate.instant(title), this.toastrconfig);
  }


}
