<!-- Horizontal Menu -->
<ng-container *ngIf="menuType=='mainbar'">
    <!-- <ul id="tabMain" class="nav nav-tabs nav-justified"> -->

    <!-- Multi-level tree -->
    <ul id="MainMenu" class="nav nav-tabs" role="tablist">

        <ng-container
            *ngTemplateOutlet="menuView; context:{ $implicit: (menuList | filterBy: {HasPermission:true}), parentTab: null, level:1 }">
        </ng-container>

        <ng-template #menuView let-tabList let-parentTab="parentTab" , let-level="level">
            <ng-template ngFor let-tab [ngForOf]="tabList">


                <li *ngIf="(tab.SubMenu.length==0)" [ngClass]="[((level == 1)?'nav-item':'')]">
                    <a [attr.aria-label]="tab?.DisplayName"
                        [ngClass]="[((level == 1)?'nav-link':'dropdown-item'), (tab.Active == true ? ' active': '')]"
                        href="#" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"
                        [routerLink]="[tab.RouteLink]" (click)="onRouteClick(tab, parentTab)">
                        <i [class]="tab.Icon"></i> {{tab.DisplayName | translate}} </a>
                </li>

                <li *ngIf="(tab.SubMenu.length>0)" [ngClass]="[((level == 1)?'nav-item dropdown':'')]" [id]="tab.Name">
                    <a [attr.aria-label]="tab?.DisplayName" id="{{fieldRandomId}}-{{tab.DisplayName}}"
                        [ngClass]="[((level == 1)?'nav-link dropdown-toggle':'dropdown-item'), (tab.Active == true ? ' active': '')]"
                        [class.disabled]="false" href="#" data-bs-toggle="dropdown" role="button" aria-haspopup="true"
                        aria-expanded="false" (click)="onRouteClick(tab, parentTab)">
                        <i [class]="tab.Icon"></i> {{tab.DisplayName | translate}}

                        <i class="ps-2 fa" aria-hidden="true"
                            [ngClass]="[((level == 1)?'fa-chevron-down':'fa-chevron-right')]"></i>
                        <!-- [ngClass]="[((level > 1)?'iconRotateLeft':'')]" -->
                    </a>

                    <span class="tabpageName">{{getSelectedSubTabName(tab)}}</span>
                    <!-- Sub Menulist -->
                    <ul class="dropdown-menu" [ngClass]="[((level == 1)?'':'submenu')]">
                        <ng-container
                            *ngTemplateOutlet="menuView; context:{ $implicit: (tab.SubMenu | filterBy: {HasPermission:true}), parentTab: tab, level:level+1 }">
                        </ng-container>

                    </ul>
                </li>

            </ng-template>
        </ng-template>
    </ul>


</ng-container>


<!-- Sidebar Menu -->
<ng-container *ngIf="menuType=='sidebar'">
    <div class="d-inline-flex bd-highlight">
        <div class="bd-highlight innerScroll"
            [ngStyle]="{'display': (sideBarOpen==false ?'none':'block'), 'width': '220px'}">
            <nav class="navbar navbar-inverse navbar-fixed-top" id="sidebar-wrapper" role="navigation">
                <ul class="nav sidebar-nav">
                    <li class="sidebar-brand">
                        <a aria-label="Menu" href="#">Menu</a>
                    </li>

                    <ng-container *ngTemplateOutlet="tabView; context:{ $implicit: menuList }">
                    </ng-container>

                    <ng-template #tabView let-tabList>
                        <ng-template ngFor let-tab [ngForOf]="tabList">
                            <li>
                                <ng-container *ngIf="tab.SubMenu.length==0">
                                    <a [attr.aria-label]="tab?.DisplayName" href="#tabMain"
                                        [routerLink]="[tab.RouteLink]" (click)="onRouteClick(tab)">
                                        <i [class]="tab.Icon"></i> {{tab.DisplayName |translate}}
                                    </a>
                                </ng-container>
                                <ng-container *ngIf="tab.SubMenu.length>0">
                                    <a [attr.aria-label]="tab?.DisplayName" class="collapseTrigger"
                                        data-bs-toggle="collapse" [href]="'#sidebar'+tab.Name" role="button"
                                        aria-expanded="true" [attr.aria-controls]="'sidebar'+tab.Name"
                                        [ngClass]="{'active': (tab.Active == true)}">
                                        <!-- [class.disabled]="tab.SubMenu.length>0" -->

                                        <span class="me-2"><i [class]="tab.Icon"></i> {{tab.DisplayName
                                            |translate}}</span>
                                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                    </a>

                                    <div class="collapse show" [id]="'sidebar'+tab.Name">
                                        <ul class="flex-column" style="padding-left: 20px;">
                                            <ng-container
                                                *ngTemplateOutlet="tabView; context:{ $implicit: tab.SubMenu }">
                                            </ng-container>
                                        </ul>
                                    </div>
                                </ng-container>

                            </li>
                        </ng-template>
                    </ng-template>


                </ul>
            </nav>
        </div>
        <div class="bd-highlight overlay" [ngStyle]="{'display': (sideBarOpen==false ?'none':'block')}"></div>
        <div class="bd-highlight" style="z-index: 99999;"
            [ngStyle]="{'margin-left': (sideBarOpen==false ? '0px':'-32px')}">
            <button type="button" class="hamburger is-closed animated fadeInLeft rounded-3"
                [ngClass]="{'rounded-3': (sideBarOpen==false)}" data-bs-toggle="offcanvas" (click)="hamburger_cross()">
                <span class="hamb-top"></span>
                <span class="hamb-middle"></span>
                <span class="hamb-bottom"></span>
            </button>
        </div>
    </div>
</ng-container>

<style>
    .nav-tabs .nav-item.dropdown-item a [class*="fa-chevron"]:hover,
    .nav-tabs .nav-item .dropdown-item [class*="fa-chevron"]:hover {
        /* .transform(rotate(-90deg)); */

        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }

    .fa-chevron-down:hover {
        content: "\f077";
    }
</style>