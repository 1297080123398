<div class="modal modal-popup" tabindex="-1" role="dialog" style="display:block;" #dragboundary>
    <div id ="File Upload"  class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <!-- Modal content -->
        <div class="ng-draggable modal-content" ngDraggable [inBounds]="true" [bounds]="dragboundary"
            [handle]="draghandle" style="width:700px !important">
            <!-- Modal header -->
            <div class="modal-header bg-light drag-block-handle" #draghandle>
                <h3 class="modal-title"><i class="fa fa-paperclip"></i> File Upload</h3>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="modal" 
                    aria-label="Close" (click)="closeUploader()">
                   
                </button>
                
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <ng-container *ngIf="Permissions.Upload">
                    <div class="form-group">
                        <!-- Single File Upload -->
                        <ng-container *ngIf="!IsMultiple">
                            <!-- <label class="form-label" for="inputfile" *ngIf="selFileNames.length == 0">Choose file</label>
                            <label class="form-label" for="inputfile" [title]="selFileNames"
                                *ngIf="selFileNames.length > 0">{{selFileNames}}</label> -->
                            
                                <input class="form-control form-control-sm" id="inputfile" type="file" 
                                    (change)="handleUpload($event)" accept="UploadType" />

                             <label for="inputfile" class="sr-only">UploadType</label>
                            <!-- <input class="form-control" type="file" id="inputfile" (change)="handleUpload($event)"> --> 

                        </ng-container>
                        
                        <!-- Multiple Files Upload -->
                        <ng-container *ngIf="IsMultiple">
                            <!-- <label class="form-label" for="inputfilemulti" *ngIf="selFileNames.length == 0">Choose files</label>
                            <label class="form-label" for="inputfilemulti" [title]="selFileNames"
                                *ngIf="selFileNames.length > 0">{{selFileNames}}</label> -->

                            <input class="form-control form-control-sm" id="inputfilemulti" type="file" multiple 
                                    (change)="handleUpload($event)" accept="UploadType" />
                            <label for="inputfilemulti" class="sr-only">UploadType</label>

                        </ng-container>

                    </div>
                   
                </ng-container>

                <div>
                    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
                </div>

                <!-- <div class="gridContainer" > -->
                    <div class="table-responsive" *ngIf="false">
                        <table class="table table-sm table-bordered tblGrid fixed">
                            <caption class="sr-only">File Upload Grid</caption>
                            <thead class="tblGridHeader">
                                <th width="40" scope="col">#</th>
                                <th class="text-truncate my-0" scope="col">File Name</th>
                                <th class="text-truncate my-0" scope="col">Action Date</th>
                                <th class="text-truncate my-0" scope="col">Uploaded By</th>
                                <th width="75" scope="col">&nbsp;</th>
                            </thead>
                            <tbody
                                *ngIf="AttachmentData!=null &&  AttachmentData!=undefined && AttachmentData.length>0">
                                <tr *ngFor="let item of AttachmentData; let item_indx=index">
                                    <td>
                                        <p class="text-truncate my-0">{{item_indx+1}}</p>
                                    </td>
                                    <td>
                                        <p class="text-truncate my-0"><a  [attr.aria-label]="item?.FileName" href="javascript:;"
                                                (click)="downloadFile(item)" class="cellLink"
                                                title="{{ item.FileName}}">{{ item.FileName}}</a></p>
                                    </td>
                                    <!-- <td>
                                        <p class="text-truncate my-0" title="{{ item.CreatedDate | date: _appSharedService.AppDateFormat }}">{{ item.CreatedDate | date: _appSharedService.AppDateFormat }}</p>
                                        </td> -->
                                    <td>
                                        <p class="text-truncate my-0"
                                            title="{{ item.CreatedName + '(' + item.CreatedBy + ')' }}">
                                            {{ item.CreatedName + '(' + item.CreatedBy + ')' }}</p>
                                    </td>
                                    <td>
                                        <ul class="actionList" style="text-align: center">
                                            <li>
                                                <!-- [tooltip]="('Click here to delete'| translate)" container="body" placement="top" -->
                                                <a  aria-label="Delete" href="javascript:;" *ngIf="Permissions.Upload&&item.IsDeletable">
                                                    
                                                    <i class="fa fa-trash"
                                                        (click)="showDeleteConfirmation(item)"></i></a>
                                            </li>
                                        </ul>
                                        <!-- <p><button class="btn btn-primary" *ngIf="Permissions.Upload&&item.IsDeletable" (click)="showDeleteConfirmation(item)"><i class="fa fa-trash"></i></button></p> -->
                                    </td>
                                </tr>
                            </tbody>

                            <tbody
                                *ngIf="AttachmentData==null ||  AttachmentData==undefined || AttachmentData.length==0">
                                <tr>
                                    <td colspan="5">No files attached.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                <!-- </div> -->

            </div>
            <!-- /.modal-content -->

            <div class="modal-footer">
                <div class="d-flex justify-content-between">
                   
                    <!-- <button type="button" class="btnCustom btnNegative rounded-pill" (click)="closeUploader()"
                        value="{{'Cancel' | translate }}">{{'Cancel' | translate }}
                    </button> -->

                    <button type="button" class="btnCustom btnPrimary rounded-pill" (click)="uploadFiles()"
                        value="{{'Upload' | translate }}">{{'Upload' | translate }}
                    </button>
                    
                </div>
            </div>

        </div>
        <!-- /.modal-dialog -->
    </div>
</div>

<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify.info" [curaction]="notify.action" [curitem]="notify.item"
        (selected)="setNotifyRes($event)" (close)="closeNotifybox()"></notification>
</div>