import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ListItem } from '../shared/_classes/utility.interface';
import { ProcessResponse } from '../shared/_classes/utility.interface';
import { ElioScopeService } from '../shared/_services/ElioScope.service';


@Injectable()
export class FieldControlService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  private BaseUrl: string;

  constructor(private _http: HttpClient, private _elioScopeService: ElioScopeService) {
    this.BaseUrl = _elioScopeService.getBaseURL();
    console.log('controlservice baseurl = ' + this.BaseUrl);
  }

  private handleError(error: any): Promise<any> {
    console.error('Error : ControlService ', error.message);
    return Promise.reject(error.message || error);
  }

  getCodeData(url: string): Observable<ProcessResponse<ListItem[]>> {
    // return this._http
    //   .get(this.BaseUrl + url, { headers: this.headers })
    //   //.get(url, { headers: this.headers })
    //   .map((res: Response) => res.json())
    //   .catch(this.handleError);

    let baseurl = this._elioScopeService.getBaseURL();
    // return this._http.get<ProcessResponse<ListItem[]>>(this.BaseUrl + '/' + url,
    return this._http.get<ProcessResponse<ListItem[]>>(baseurl + '/' + url,
      { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      );

  }

}
