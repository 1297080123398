import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../../environments/environment';
// import { FieldControlModule, ElioScopeService, ElioUtilityService } from 'fieldcontrol-lib';
import { FieldControlModule } from '../fieldcontrol/fieldcontrol.module';
import { ElioScopeService } from '../shared/_services/ElioScope.service';
import { UtilityService } from '../shared/_services/utility.service';
import { FormComponent } from './form.component';


@NgModule({
  imports: [CommonModule, FieldControlModule,
    TranslateModule.forRoot()
  ],
  declarations: [FormComponent],
  providers: [ElioScopeService, UtilityService],
  exports: [FormComponent]
})

export class FormModule { }
