<!-- Modal section -->
<div class="modal modal-popup" tabindex="-1" role="alert" style="display:block;" #dragboundary>
  <div [ngClass]="modaldetail.size!=null ? modaldetail.size : 'modal-dialog  modal-dialog-centered'"
    class="modal-dialog  modal-dialog-centered">
    <!-- Modal content -->
    <div class="ng-draggable modal-content" [style.width.px]="modaldetail.width!='' ? modaldetail.width : null"
      [style.height.px]="modaldetail.height!='' ? modaldetail.height : null" ngDraggable [inBounds]="true"
      [bounds]="dragboundary" [handle]="draghandle">
      <!-- Modal header -->
      <div class="modal-header bg-light drag-block-handle" #draghandle>
        <h3 class="modal-title">{{modaldetail.header | translate}}</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()">
          <!-- <span aria-hidden="true">&times;</span> -->

          <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->

        </button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <ng-content></ng-content>
      </div>

      <!-- Modal footer -->
      <!-- <div class="modal-footer"> -->
      <!-- <label class="text-error"
            *ngIf="modaldetail.NoteMessage!=null && modaldetail.NoteMessage!=undefined">{{modaldetail.NoteMessage | translate}}</label>
          <input type="button" *ngIf="modaldetail.yes!=null && modaldetail.yes!=undefined" [ngClass]="['btnCustom', (modaldetail.cssyes!=null && modaldetail.cssyes!=undefined && modaldetail.cssyes.length > 0 ? modaldetail.cssyes : 'btnPrimary')]"
            data-bs-dismiss="modal" name="btnYes" value="{{modaldetail.yes}}" (click)="btnYesClick(modaldetail.yes)" />
          <input type="button" *ngIf="modaldetail.no!=null && modaldetail.no!=undefined"
            [ngClass]="['btnCustom', (modaldetail.cssno!=null && modaldetail.cssno!=undefined && modaldetail.cssno.length > 0 ? modaldetail.cssno : 'btnNegative')]"
            data-bs-dismiss="modal" name="btnNo" value="{{modaldetail.no}}" (click)="btnNoClick(modaldetail.no)" /> -->

      <!-- </div> -->
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- /.modal !-->