import { isNull } from '@angular/compiler/src/output/output_ast';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';
//import { constants } from 'buffer';
import { Subscription } from 'rxjs';
import { ActionCode, BGVStatus, ProcessStatus, RoleCodes } from 'src/app/app.interface';
import { AppSharedService, AutoUnsubscribe, isEmail, isNullOrUndefined, isStringNullOrEmpty } from 'src/app/shared/app.sharedservice';
import { environment } from 'src/environments/environment';
import { FormComponent } from 'src/modules/form/form.component';
import { ModalService } from 'src/modules/modal/modal.service';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { RequestService } from '../../request.service';
import { CheckInfo, UpdateCheckInfo } from '../check.interface';


@Component({
  selector: 'document',
  templateUrl: './document.component.html'
})

@AutoUnsubscribe
export class DocumentComponent implements OnInit {

  // @Input() CheckDocData: any;
  @Input() CheckDocData: any;
  @Input() RequestId: number = 0;
  @Input() CheckId: number = 0;
  @Input() RequestData: any;
  @Input() UploadType: any = '';
  @Input() CheckTemplate: FieldTemplate[] = [];
  @Input() CheckPermission: any = [];
  @Input() CheckProperties: any = [];
  @Input() AttachmentTemplate: FieldTemplate[] = [];
  @Input() AttachmentData: any[] = [];
  @Input() editCheckTemplate: FieldTemplate[] = [];
  @Input() SubCheckMetaData: any[] = [];
  @Input() DocumentStatusFieldName: any = '';
  @Input() checkList: CheckInfo[] = [];

  @Output() raiseInsufficiency = new EventEmitter<any>();
  @Output() public checkOnChange = new EventEmitter<any>();
  @Output() showPreview = new EventEmitter<any>();
  @Output() submitenable = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();


  // @ViewChild('editForm', { static: false }) editForm!: FormComponent;
  @ViewChildren('editForm') editForm!: QueryList<FormComponent>;
  @Output() reLoadCheck = new EventEmitter<any>();
  @Output() updateInsuff = new EventEmitter<any>();

  // public editCheckTemplate: FieldTemplate[] = [];
  // public docButtonList: any = [];
  public editCheckData: any = {}

  public showInsufficiency: boolean = true;
  public IsLoading: boolean = false;
  public DisplayAttachment: boolean = false;
  public checkDocumentDetailsList: CheckInfo[] = [];

  public subDocId: number = 0;

  public psGroupName: string = '';

  public checkMetaData: any = {};

  public checkName: string = '';

  public disableStatusFlag: boolean = false;

  public subscriptionList$: Subscription[] = [];

  public displayNotifybox: boolean = false;
  public notify: NotificationData = new NotificationData();

  public attachActionButtons: any = [];

  public IsReadonly: boolean = false;

  public IsPopupClosereloadFlag: boolean = false;

  public IsApplicableFlag: boolean = false;
  public displayAllAttachmentbox: boolean = false;
  public previewclickData: any;

  public enableDeleteButton: boolean = false;

  public hideAttachmentgrid: boolean=false;
  // public docStatus: string = '';

  public FldName: string = '';
  public dropDownList: any[] = [];

  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };

  public FormProp: FormProperty = {
    'ColSize': 3,
    'CaptionBold': true,
    'IsEditable': true,
    'ShowControlAlways': false
  };

  public EditCheckProp: FormProperty = {
    'ColSize': 1,
    'CaptionBold': true,
    'IsEditable': true,
    // 'ShowControlAlways': true
  };

  public GridProp: any = {
    Editable: true,
    IsSortable: false
  };
  public GlobalConfig: any = {};

  public AttachPerm: any = {
    Upload: true,
    Download: false
  };

  constructor(private _requestService: RequestService, private _appSharedService: AppSharedService, private cdRef: ChangeDetectorRef) {
    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);
  }

  ngOnInit(): void {

    if (!isNullOrUndefined(this._appSharedService.currentRoleData)) {


      // this.CheckProperties.disableStatus = JSON.parse(`["10037"]`);

      this.disableStatusFlag = !isNullOrUndefined(this.CheckProperties.DisableStatus) && this.CheckProperties.DisableStatus.length > 0
        && this.CheckProperties.DisableStatus.filter((x: any) => x === this.CheckDocData.UploadStatus).length > 0 ? true : false;

      if (this.RequestData.IsStopCaseSubmitted === true || this.RequestData.IsStopCase === true || this.CheckDocData.IsEditable === 0 || this.disableStatusFlag === true) {
        this.FormProp.IsEditable = false;
        this.EditCheckProp.IsEditable = false;
        this.GridProp.Editable = false;
        this.IsReadonly = true;

        // this.AttachmentData.forEach(item => {
        //   item.IsEditable = 0
        // });

        if (this.RequestData.IsStopCaseSubmitted === true || this.RequestData.IsStopCase === true) {
          this.AttachmentData.forEach(item => {
            item.IsEditable = 0
          });
        }
        else if (!isNullOrUndefined(this.AttachmentTemplate) && this.AttachmentTemplate.length > 0 &&
          this.AttachmentTemplate.filter(x => x.FieldName === 'NewSubDocumentTypeId').length > 0) {

          this.AttachmentTemplate.filter(x => x.FieldName === 'NewSubDocumentTypeId')[0].Editable = 0;
        }
      }

      if (this.CheckDocData.UploadStatus === BGVStatus.Document_Upload_Pending_with_BGV_Manager && this._appSharedService.currentRoleData.RoleId === RoleCodes.IntiateInsuff) {
        this.EditCheckProp.IsEditable = true;
        this.IsReadonly = false;
      }
      if(this.CheckId===8&&(this.CheckDocData.UploadStatus===BGVStatus.L3_Insufficiency_Additional_details_or_documents||this.CheckDocData.UploadStatus===BGVStatus.L3_Insufficiency_Additional_details_or_documents_1||this.CheckDocData.UploadStatus===BGVStatus.L3_Insufficiency_Additional_details_or_documents_2)){
        this.hideAttachmentgrid=false;
      }
      if(this.CheckId===8&&(this.CheckDocData.UploadStatus!==BGVStatus.L3_Insufficiency_Additional_details_or_documents||this.CheckDocData.UploadStatus!==BGVStatus.L3_Insufficiency_Additional_details_or_documents_1||this.CheckDocData.UploadStatus!==BGVStatus.L3_Insufficiency_Additional_details_or_documents_2)){
        this.hideAttachmentgrid=true;
      }
        
        
      // // // if (this._appSharedService.currentRoleData.RoleId === RoleCodes.QC || this._appSharedService.currentRoleData.RoleId === RoleCodes.NonGreen
      // // //   || this._appSharedService.currentRoleData.RoleId === RoleCodes.IntiateInsuff
      // // //   || this._appSharedService.currentRoleData.RoleId === RoleCodes.BUHR
      // // //   || (this._appSharedService.currentRoleData.RoleId === RoleCodes.Recruiter && this.CheckDocData.PendingWith === RoleCodes.Recruiter.toString())
      // // //   || (this._appSharedService.currentRoleData.RoleId === RoleCodes.Candidate && this.CheckDocData.PendingWith === RoleCodes.Candidate.toString())) {

      // // //   if (this.RequestData.IsStopCaseSubmitted === false && this.RequestData.IsStopCase === false && this.disableStatusFlag === false
      // // //     && this._appSharedService.currentRoleData.RoleId === RoleCodes.BUHR && this.CheckDocData.PendingWith === RoleCodes.BUHR.toString()) {

      // // //     this.GridProp.Editable = true;
      // // //     this.AttachmentData.forEach(item => {
      // // //       item.IsEditable = 1
      // // //     });

      // // //   } else if (this.RequestData.IsStopCaseSubmitted === false && this.RequestData.IsStopCase === false && this.disableStatusFlag === false
      // // //     && (!isNullOrUndefined(this.CheckProperties.AttachmentStatusDisable) && this.CheckProperties.AttachmentStatusDisable.length > 0
      // // //       && this.CheckProperties.AttachmentStatusDisable.filter((x: any) => x === this.CheckDocData.UploadStatus).length > 0)) {

      // // //     this.GridProp.Editable = true;
      // // //     this.AttachmentData.forEach(item => {
      // // //       item.IsEditable = 1
      // // //     });

      // // //   }
      // // //   else {
      // // //     this.GridProp.Editable = false;
      // // //     this.AttachmentData.forEach(item => {
      // // //       item.IsEditable = 0
      // // //     });
      // // //   }
      // // // }

      if (((this._appSharedService.currentRoleData.RoleId === RoleCodes.Recruiter && this.CheckDocData.PendingWith === RoleCodes.Recruiter.toString())
        || (this._appSharedService.currentRoleData.RoleId === RoleCodes.Candidate && this.CheckDocData.PendingWith === RoleCodes.Candidate.toString()))
        && this.CheckDocData.DisableHighestPrevField === true) {
        this.EditCheckProp.IsEditable = false;
      }


      if (((this._appSharedService.currentRoleData.RoleId === RoleCodes.IntiateInsuff && this.CheckDocData.PendingWith === RoleCodes.IntiateInsuff.toString()))
        && this.CheckDocData.UploadStatus === BGVStatus.Verification_Completed_Pending_with_BGV_Manager) {
        this.EditCheckProp.IsEditable = false;
      }

      this.CheckDocData.IsApplicableFileAction = '0';

      this.attachActionButtons = !isNullOrUndefined(this.CheckProperties.ActionButtons) && this.CheckProperties.ActionButtons.length > 0
        && this.CheckProperties.ActionButtons.filter((x: any) => x.Group === 'Document' && x.HasPermission === true).length > 0 ?
        this.CheckProperties.ActionButtons.filter((x: any) => x.Group === 'Document' && x.HasPermission === true) : null;

      // console.log(this.CheckDocData);
      //Group Name
      this.psGroupName = 'CHECK_' + this.CheckDocData.DocumentTypeId.toString() + '_P';

      //Getting Meta Data for Sub Checks
      // this.checkMetaData = !isNullOrUndefined(this.SubCheckMetaData) && this.SubCheckMetaData.length > 0 &&
      //   this.SubCheckMetaData.filter(x => x.SubCheckId === this.CheckDocData.DocumentTypeId).length > 0 ?
      //   this.SubCheckMetaData.filter(x => x.SubCheckId === this.CheckDocData.DocumentTypeId)[0] : {};

      // this.checkMetaData.PossibleSuspectIdentified = !isNullOrUndefined(this.CheckDocData.IsPossiblesuspectMyHireFlag)
      //   && this.CheckDocData.IsPossiblesuspectMyHireFlag === true ? 'Yes' : 'No';


      // this.SubCheckMetaData[0].PossibleSuspectIdentified = !isNullOrUndefined(this.CheckDocData.IsPossiblesuspectMyHireFlag)
      //   && this.CheckDocData.IsPossiblesuspectMyHireFlag === true ? 'Yes' : 'No';

      // if (this.RequestData.IsCreateMode === true && (this._appSharedService.currentRoleData.RoleId === RoleCodes.Candidate ||
      //   this._appSharedService.currentRoleData.RoleId === RoleCodes.Employee)) {
      //   this.SubCheckMetaData[0].IsEditable = 1;
      // } else {
      //   this.SubCheckMetaData[0].IsEditable = 0;
      // }
      // Warning Message
      // let msg: string = this.CheckDocData.WarningMessage!;
      // this.CheckDocData.WarningMessageList = JSON.parse(msg);  
      this.CheckDocData.WarningMessageList = [];

      // Changing Form Column Size Based on CheckId using Property
      if (!isNullOrUndefined(this.CheckProperties.FormColSize)) {
        this.CheckProperties.FormColSize.forEach((item: any) => {
          if (this.CheckId === item.CheckId) {
            this.FormProp.ColSize = item.ColSize;
          }
        });
      }

      // // 1st time value initiation of document status and after save data assigning to doc status handled here. 
      // this.CheckDocData.DocumentStatusCode = (!isNullOrUndefined(this.CheckDocData.DocumentStatusDesc)
      //   && this.CheckDocData.DocumentStatusDesc !== '') ?
      //   this.CheckDocData.DocumentStatusDesc : '';



      // Assigning Check name Based on CheckId
      this.checkName = this.CheckDocData.CheckName;
      let UpldFiletype = this.CheckProperties.MetaDataMethodName.filter((x: any) => x.CheckId === this.CheckId);
      if (UpldFiletype.length != 0) {
        this.UploadType = UpldFiletype[0].UploadFileType;
      }

      //Assigning Applicable Status
      this.CheckDocData.IsApplicable = !isNullOrUndefined(this.CheckDocData.IsApplicable) && this.CheckDocData.IsApplicable !== '' ? this.CheckDocData.IsApplicable : true;

      // console.log(this.UploadType)
      //Based on DocumentStatus Hidding Columns
      if (!isNullOrUndefined(this.CheckDocData.DocumentStatusDesc)
        && this.CheckDocData.DocumentStatusDesc !== '') {
        this.hideFieldColumns(this.CheckDocData);
      }

      if (!isNullOrUndefined(this.CheckDocData.Phase)
        && this.CheckDocData.Phase == 1) {
        this.hideFieldColumns(this.CheckDocData);
      }

      if (this.CheckDocData.IsApplicable === false) {
        this.hideApplicableColumns(this.CheckDocData, false);
      } else {
        this.hideApplicableColumns(this.CheckDocData, true);
      }

      // Exceptional handling for final semester flag enabled for vendor.
      this.enabledisableFinalSemesterFlag();

      this.getDropDownData();



      //Enable Delete Button
      this.enableDeleteButton = ((this.RequestData.IsCreateMode === true && this.CheckDocData.IsDocumentSectionDelete === true)
        || (this._appSharedService.currentRoleData.RoleId === RoleCodes.IntiateInsuff && this.CheckDocData.UploadStatus === BGVStatus.Document_Upload_Pending_with_BGV_Manager
          && this.CheckDocData.IsDocumentSectionDelete === true))
        ? true : false;

      // if (this.CheckDocData.UploadStatus === BGVStatus.L1_Insufficiency_Mandate_documents
      //   || this.CheckDocData.UploadStatus === BGVStatus.L2_Insufficiency_Additional_details_or_documents
      //   || this.CheckDocData.UploadStatus === BGVStatus.L3_Insufficiency_Additional_details_or_documents) {

      // this.docButtonList = this.CheckProperties.ActionButtons.filter((x: any) => (x.Name === 'HighestEducationSubmit' || x.Name === 'ClearInsufficiency'));

      this.setActionButtonPermissions();
      // }

    }
  }

  raiseUpdateInsufficiency(flag: boolean = false): void {
    this.showInsufficiency = flag;
    this.raiseInsufficiency.emit({ 'item': this.CheckDocData, 'CancelFlag': flag });
  }


  onClick(event: any): void {
    let selitem: any = event.item;
    this.previewclickData = selitem;
    // console.log(selitem);

    if (event.fieldname.toUpperCase() === 'ACTION') {
      let modifiedFlag: boolean = false;
      let metaData = this.SubCheckMetaData.filter(x => x.IsModified === 1);
      let attachData = this.AttachmentData.filter(x => x.IsModified === 1);

      if ((this.CheckDocData.IsModified === 1 || (!isNullOrUndefined(metaData) && metaData.length > 0)
        || (!isNullOrUndefined(attachData) && attachData.length > 0)) && this.CheckDocData.UploadStatus === BGVStatus.Document_Upload_Pending_with_Employee) {
        modifiedFlag = true;
      }

      // event.icon.IconCSS
      switch (event.icon.Value) {
        case '0':
          //Upload
          if (modifiedFlag === true) {
            this.showNotificationMessage('Please save the details to upload documents.', 2, '', null, '', '', true);
          } else {
            this.subDocId = selitem.SubDocumentTypeId;
            this.DisplayAttachment = true;
          }


          break;

        case '1':
          // download
          if (modifiedFlag === true) {
            this.showNotificationMessage('Please save the details to upload documents.', 2, '', null, '', '', true);
          } else {
            this.downloadAttachment(selitem);
          }


          break;

        case '2':
          // Delete
          if (modifiedFlag === true) {
            this.showNotificationMessage('Please save the details to upload documents.', 2, '', null, '', '', true);
          } else {
            this.showDeleteAttachmentConfirmation(selitem);
          }

          // this.deleteAttachment(selitem);
          break;

        case '3':
          // View
          if (modifiedFlag === true) {
            this.showNotificationMessage('Please save the details to upload documents.', 2, '', null, '', '', true);
          } else {
            selitem.DisplayMode = 'PREVIEW';
            this.displayAllAttachmentbox = true;
            this.showPreview.emit({ 'data': selitem });
          }

          //   this.showDocumentPreview(selitem);



          break;
      }
    }

    if (event.fieldname.toUpperCase() === 'ISAPPLICABLEFILEACTION') {
      // event.icon.IconCSS
      switch (event.icon.Value) {
        case '0':
          //Upload
          this.IsApplicableFlag = true;
          this.DisplayAttachment = true;
          break;
      } 
    }

  }

  onChange(event: any): void {
    let selitem: any = event.item;
    // Emitting onchange event of resource plan to parent container
    selitem.IsModified = 1;
    selitem.IsSaved = 1;

    let prevValue = event.oldValue;

    switch (event.fieldname.toUpperCase()) {
      case 'ISAPPLICABLE':

        selitem.DocumentList = null;
        selitem.DocumentListDesc = null;
        selitem.DocumentStatusDesc = null;
        selitem.FinalSemesterPendingFlag = 'No';
        selitem.HigherEducationFlag = 'No';
        selitem.InsufficiencyComment = null;
        selitem.InsufficiencyType = null;
        selitem.InsufficiencyTypeDesc = null;
        selitem.Ispossiblesuspect = false;
        selitem.IsPaidLessThan5Lakhs = false;
        selitem.PreviousEmploymentFlag = 'No';
        selitem.FileName = null;
        selitem.NotApplicableRemarks = null;

        this.hideApplicableColumns(selitem, false);

        break;
      case 'DOCUMENTSTATUSCODE':
      case 'DOCUMENTSTATUSDESC':


        if (!isNullOrUndefined(selitem.DocumentStatusDesc) && selitem.DocumentStatusDesc !== ''
          && selitem.DocumentStatusDesc === 'Unavailable') {

          if (!isNullOrUndefined(this.CheckProperties.DefaultInsufficiencyType) && this.CheckProperties.DefaultInsufficiencyType.length > 0) {

            this.CheckProperties.DefaultInsufficiencyType.forEach((item: any) => {
              let defaultvalue = item.UploadStatus.filter((x: any) => x === selitem.UploadStatus);

              if (!isNullOrUndefined(defaultvalue) && defaultvalue.length > 0) {
                selitem.InsufficiencyType = item.Code;
                selitem.InsufficiencyTypeDesc = item.CodeDescription;
              }
            });
            if(selitem.CheckId==2 && selitem.DocumentTypeId==5)
            {
            selitem.PreviousEmploymentFlag = 'Yes';
            }           
            this.hideFieldColumns(selitem);

          }
        }

        if (!isNullOrUndefined(selitem.DocumentStatusDesc) && selitem.DocumentStatusDesc !== ''
          && selitem.DocumentStatusDesc === 'Available') {

          if (!isNullOrUndefined(selitem.DocumentStatusDesc) && selitem.DocumentStatusDesc !== ''
            && !isNullOrUndefined(selitem.OldDocumentStatusDesc) && selitem.OldDocumentStatusDesc !== ''
            && selitem.OldDocumentStatusDesc === 'Unavailable'
            && !isNullOrUndefined(selitem.InsufficiencyTypeDesc) && selitem.InsufficiencyTypeDesc !== ''
            && !isNullOrUndefined(selitem.DocumentListDesc) && selitem.DocumentListDesc !== ''
            && !isNullOrUndefined(selitem.OldInsufficiencyType) && selitem.OldInsufficiencyType !== '') {
            this.IsPopupClosereloadFlag = true;
            this.showNotificationMessage('All necessary documents are uploaded?', 2, 'UNAVAILABLECONFIRM', selitem, 'Yes', 'No', true);
             } else {

            selitem.DocumentList = null;
            selitem.DocumentListDesc = null;
            selitem.FinalSemesterPendingFlag = 'No';
            selitem.HigherEducationFlag = 'No';
            selitem.InsufficiencyComment = null;
            selitem.InsufficiencyType = null;
            selitem.InsufficiencyTypeDesc = null;
            // selitem.IsPaidLessThan5Lakhs = false;
            selitem.PreviousEmploymentFlag = 'No';
            selitem.FileName = null;
            selitem.NotApplicableRemarks = null;

            this.hideFieldColumns(selitem);
          }
        }


        // event.item.HigherEducationFlag = null;
        // event.item.FinalSemesterPendingFlag = null;
        // event.item.PreviousEmploymentFlag = null;
        break;
      case 'FINALSEMESTERPENDINGFLAG':
        // if (event.item.FinalSemesterPendingFlag == 'Yes') {
        //   event.item.HigherEducationFlag == 'No';
        // }
        break;
      case 'HIGHEREDUCATIONFLAG':
        // event.item.FinalSemesterPendingFlag = null;
        // this.submitenable.emit({ 'item': this.CheckDocData });
        this.setActionButtonPermissions();
        break;
      case 'PREVIOUSEMPLOYMENTFLAG':
        // this.submitenable.emit({ 'item': this.CheckDocData });
        this.setActionButtonPermissions();
        break;
        case 'SAMPLEPROVIDEDFLAG':
        // this.submitenable.emit({ 'item': this.CheckDocData });
        this.setActionButtonPermissions();
        break;

      case 'DATEOFBIRTH':
        if (!isNullOrUndefined(selitem.DateOfBirth)) {

          //Date of birth Should not be in future date
          if (selitem.DateOfBirth > new Date()) {

            selitem.DateOfBirth = new Date();

            this.showNotificationMessage('Date of birth should not be in future date.', 2, '', null, '', '', true);

          }
        }

        break;
      case 'STARTDATE':
      case 'DATEOFPASSING':
        if (!isNullOrUndefined(selitem.StartDate) || !isNullOrUndefined(selitem.DateofPassing)) {

          //Start Should not be in future date
          if (selitem.StartDate > new Date()) {

            selitem.StartDate = !isNullOrUndefined(selitem.StartDate) ? new Date() : null;
            selitem.DateofPassing = !isNullOrUndefined(selitem.DateofPassing) ? new Date() : null;

            this.showNotificationMessage('Start Date should not be in future date.', 2, '', null, '', '', true);

          } else if (selitem.DateofPassing > new Date()) {

            //End Date Should not be in future date
            selitem.DateofPassing = !isNullOrUndefined(selitem.DateofPassing) ? new Date() : null;
            this.showNotificationMessage('End Date should not be in future date.', 2, '', null, '', '', true);

          } else if (!isNullOrUndefined(selitem.StartDate) && !isNullOrUndefined(selitem.DateofPassing) && selitem.DateofPassing < selitem.StartDate) {

            //End Date Should not be lesser than Start Date
            selitem.DateofPassing = selitem.StartDate;
            if (!isNullOrUndefined(event.fieldname) && event.fieldname !== '' && event.fieldname.toUpperCase() === 'STARTDATE') {

              this.showNotificationMessage('Start Date should not be greater than End Date.', 2, '', null, '', '', true);

            } else if (!isNullOrUndefined(event.fieldname) && event.fieldname !== '' && event.fieldname.toUpperCase() === 'DATEOFPASSING') {

              this.showNotificationMessage('End Date should not be lesser than Start Date.', 2, '', null, '', '', true);

            }


          }
          // else if (selitem.StartDate < selitem.DateofPassing) {

          //   //Start Date Should not be Greater than End Date
          //   selitem.StartDate = selitem.DateofPassing;
          //   this.showNotificationMessage('Start Date should not be lesser than End Date.', 2, '', null, '', '', true);

          // }
        }

        break;
      case 'FROMDATE':
      case 'TODATE':
        let today = new Date();
        let yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = '1'
        const formattedToday = mm + '-' + dd + '-' + yyyy;
        const todayDate=new Date(formattedToday);

        if (!isNullOrUndefined(selitem.FromDate) || !isNullOrUndefined(selitem.ToDate)) {

          //From Should not be in future date
          if (selitem.FromDate > new Date()) {

            selitem.FromDate = todayDate;
    
            this.showNotificationMessage('From date should not be in future date.', 2, '', null, '', '', true);

          } 
          if (selitem.ToDate > new Date()) {

            //To Should not be in future date
            selitem.ToDate = todayDate;
            this.showNotificationMessage('To date should not be in future date.', 2, '', null, '', '', true);

          }  
          if (!isNullOrUndefined(selitem.FromDate) && !isNullOrUndefined(selitem.ToDate) && selitem.ToDate <= selitem.FromDate) {

            //To Date Should not be lesser than From Date
            // selitem.ToDate = selitem.FromDate;
            // this.showNotificationMessage('To Date should not be lesser than From Date.', 2, '', null, '', '', true);

            if (!isNullOrUndefined(event.fieldname) && event.fieldname !== '' && event.fieldname.toUpperCase() === 'FROMDATE') {
              selitem.FromDate = selitem.ToDate;
              this.showNotificationMessage('From Date should not be greater than To Date.', 2, '', null, '', '', true);

            } else if (!isNullOrUndefined(event.fieldname) && event.fieldname !== '' && event.fieldname.toUpperCase() === 'TODATE') {
              selitem.ToDate = selitem.FromDate;
              this.showNotificationMessage('To Date should not be lesser than From Date.', 2, '', null, '', '', true);

            }


          }

          // let timeInMilisec: number = selitem.FromDate.getTime() - selitem.ToDate.getTime();
          // let daysBetweenDates: number = Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24));
          let monthBetweenDates: number = (Math.round(Math.abs(selitem.ToDate - selitem.FromDate) / (1000 * 60 * 60 * 24*30.5))+1);
          if(monthBetweenDates==0)
          {
            monthBetweenDates=1;
          }
          selitem.DurationOfStay = monthBetweenDates;
        }

        break;
      case 'CTC':
        let s = selitem.CTC.replace(/[^\d.-]/g, '');

        // this.showNotificationMessage('Please provide only numeric values.', 2, '', null, '', '', true);

        selitem.CTC = s;

        break;

      case 'OFFICIALEMAILID':
        // if (event.fieldname.toUpperCase() === 'OFFICIALEMAILID'|| event.fieldname.toUpperCase() === 'PERSONALEMAILID'){
        if (!isStringNullOrEmpty(selitem.OfficialEmailID) && !isEmail(selitem.OfficialEmailID)) {

          this.showNotificationMessage('Please provide valid Official EmailId', 2, '', null, '', '', true);

          selitem.OfficialEmailID = null;
        }
        //}
        break;
      case 'PERSONALEMAILID':
        // if (event.fieldname.toUpperCase() === 'OFFICIALEMAILID'|| event.fieldname.toUpperCase() === 'PERSONALEMAILID'){
        if (!isStringNullOrEmpty(selitem.PersonalEmailID) && !isEmail(selitem.PersonalEmailID)) {

          this.showNotificationMessage('Please provide valid Personal EmailId', 2, '', null, '', '', true);

          selitem.PersonalEmailID = null;
        }
        //}
        break;

      case 'ISOVERLAPWITHCG':


          if (!isNullOrUndefined(selitem.ISOverlapwithCG) && selitem.ISOverlapwithCG !== ''
            && (selitem.ISOverlapwithCG === 'NA' || selitem.ISOverlapwithCG === 'Yes' || selitem.ISOverlapwithCG === 'No')) {
  
              this.hideFieldColumns(selitem);
 
          }

         
  
          break;
        case 'RESUMESTATUS':


          if (!isNullOrUndefined(selitem.ResumeStatus) && selitem.ResumeStatus !== ''
            && (selitem.ResumeStatus === 'Matched' || selitem.ResumeStatus === 'Not Matched' )) {
  
              this.hideFieldColumns(selitem);
  
          }

          
  
          break;
      case 'INSUFFICIENCYTYPE':

        if (!isStringNullOrEmpty(selitem.InsufficiencyType)&& selitem.InsufficiencyType !== ''&& selitem.DocumentStatusDesc === 'Unavailable' && (selitem.CheckStatusCode=='10005'||selitem.InsufficiencyStatus=='L1') && selitem.InsufficiencyType !='L1') {

          this.GetInsufficiencyDetails();
          selitem.InsufficiencyType = null;
          selitem.InsufficiencyTypeDesc = null;
        }
        break;
    }
    let eventdata = {
      fieldname: event.fieldname
    };
    
    this.checkOnChange.emit(eventdata);
  }

  onChangeAttach(event: any): void {
    let selitem: any = event.item;
    selitem.IsModified = 1;
    selitem.IsRowHighlight = selitem.IsModified;

    switch (event.fieldname.toUpperCase()) {
      case 'ISAPPLICABLE':

        break;

    }
    let eventdata = {
      fieldname: event.fieldname
    };

    this.checkOnChange.emit(eventdata);
  }


  setCellLock(fieldname: string, item: any, parent: DocumentComponent): boolean {
    let bLock = false;
    let BlkCols: any = [];
    let properties: any = parent.CheckProperties;

    if (!isNullOrUndefined(properties.BlockCols) && properties.BlockCols.length > 0) {
      properties.BlockCols.forEach((blockitem: any) => {
        if (blockitem.Value === item[blockitem.Field]) {
          blockitem.BlockCols.forEach((item: any) => {
            BlkCols.push(item);
          });
        }
      });
    }

    if (BlkCols.length > 0) {
      bLock = (BlkCols.indexOf(fieldname.toUpperCase()) >= 0 ? true : false);
    }

    return bLock;
  }
  
  GetInsufficiencyDetails() {
    // this.IsLoading = true;

    let odata: object = { 'requestId': this.RequestId, 'checkId': 0 };

    let subscribe$: Subscription = this._requestService.GetInsufficiencyDetails(odata).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
        
        this.showNotificationMessage('L2 Insufficiency Cannot be Raised after L1 Insufficiency', 2, '', null, '', '',true);
      } 
        this.IsLoading = false;
    },
        err => {
            this.IsLoading = false;
        });
    this.subscriptionList$.push(subscribe$);
}
  downloadAttachment(data: any) {
    this.IsLoading = true;

    let odata: any = {
      'Module': 'Check',
      'Reference1': data.RequestId,
      'Reference2': data.CheckId,
      'Reference3': data.DocumentTypeId,
      'Reference4': data.SubDocumentTypeId,
      'TransactionId': data.TransactionId,
      // 'FilePath': data.FilePath,
      'FilePath': '',
      'FileName': data.FileName
    };

    let subscribe$: Subscription = this._requestService.downloadAttachment(odata).subscribe(result => {
      // if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
      //   console.log(result);
      // }
      if (!isNullOrUndefined(result)) {
        let url = window.URL.createObjectURL(result.data);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        // a.download = res.filename;
        a.download = data.FileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
        // this._toastr.success('Resume downloaded successfully', this.CompTitle);
        console.log('Resume downloaded successfully');

        this.notify = {
          info: {
            header: 'Confirmation Alert',
            message: 'Document downloaded successfully',
            yes: '',
            no: '',

            IsHeaderHidden: true,
            HeaderIcon: 'fa fa-check-circle text-success',
            IsCrossEnable: true,
            SizeClass: 'modal-sm'
          },

          action: '',
          item: null
        };

        this.displayNotifybox = true;
      }
      this.IsLoading = false;

    },
      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  hideApplicableColumns(selitem: any, initialStage: boolean = false) {
    let disableCols: string = '';
    let enableCols: string = '';

    let fldcolumns = JSON.parse(`[{"HideCols":["Ispossiblesuspect","DocumentStatusDesc","InsufficiencyType","DocumentList","InsufficiencyComment","IsApplicable","HigherEducationFlag","FinalSemesterPendingFlag","IsApplicableFileAction","FileName","NotApplicableRemarks","PreviousEmploymentFlag","IsPaidLessThan5Lakhs","CompanyInvestigationRequired","Clarification","ISResumeUANMatch","ISBGVUANMatch","ISOverlapwithCG","UANReasonforNA","IsResumeAvailable","ResumeStatus","ResumeMismatchComments"]}]`)
    let L3Hidecolumns = JSON.parse(`[{"HideCols":["Ispossiblesuspect","DocumentStatusDesc","InsufficiencyType","IsApplicable","HigherEducationFlag","FinalSemesterPendingFlag","IsApplicableFileAction","FileName","NotApplicableRemarks","PreviousEmploymentFlag","IsPaidLessThan5Lakhs","CompanyInvestigationRequired","ISResumeUANMatch","ISBGVUANMatch","ISOverlapwithCG","UANReasonforNA","IsResumeAvailable","ResumeStatus","ResumeMismatchComments"]}]`)
    let InitiateCaseHidecolumns = JSON.parse(`[{"HideCols":["Ispossiblesuspect","DocumentStatusDesc","InsufficiencyType","DocumentList","InsufficiencyComment","HigherEducationFlag","FinalSemesterPendingFlag","PreviousEmploymentFlag","IsPaidLessThan5Lakhs","CompanyInvestigationRequired","Clarification","ISResumeUANMatch","ISBGVUANMatch","ISOverlapwithCG","UANReasonforNA","IsResumeAvailable","ResumeStatus","ResumeMismatchComments"]}]`)
    let clarificationColumn=JSON.parse(`[{"HideCols":["Clarification"]}]`)

    //only for initiate Case
    if (((this._appSharedService.currentRoleData.RoleId === RoleCodes.IntiateInsuff && this.CheckDocData.PendingWith === RoleCodes.IntiateInsuff.toString())
      && (this.CheckDocData.UploadStatus === BGVStatus.Document_Upload_Pending_with_BGV_Manager || this.CheckDocData.UploadStatus === BGVStatus.Verification_Completed_Pending_with_BGV_Manager))) {
      InitiateCaseHidecolumns[0].HideCols.forEach((cols: any) => {
        if (selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == cols
          && x.Group === this.psGroupName).length > 0) {
          selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == cols
            && x.Group === this.psGroupName)[0].IsHidden = true;
          disableCols = disableCols + ',' + cols;
        }
      });
    }

    if (this.RequestData.IsCreateMode === true) {
      fldcolumns[0].HideCols.forEach((cols: any) => {
        if (selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == cols
          && x.Group === this.psGroupName).length > 0) {
          selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == cols
            && x.Group === this.psGroupName)[0].IsHidden = true;
          disableCols = disableCols + ',' + cols;
        }
      });
    }
    else if (((this._appSharedService.currentRoleData.RoleId === RoleCodes.Recruiter && this.CheckDocData.PendingWith === RoleCodes.Recruiter.toString())
      || (this._appSharedService.currentRoleData.RoleId === RoleCodes.Candidate && this.CheckDocData.PendingWith === RoleCodes.Candidate.toString())
      || (this._appSharedService.currentRoleData.RoleId === RoleCodes.Employee && this.CheckDocData.PendingWith === RoleCodes.Employee.toString())
    )
    && (this.CheckDocData.UploadStatus === BGVStatus.L3_Insufficiency_Additional_details_or_documents||this.CheckDocData.UploadStatus === BGVStatus.L3_Insufficiency_Additional_details_or_documents_1||this.CheckDocData.UploadStatus === BGVStatus.L3_Insufficiency_Additional_details_or_documents_2 
    )) {
      L3Hidecolumns[0].HideCols.forEach((cols: any) => {
        if (selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == cols
          && x.Group === this.psGroupName).length > 0) {
          selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == cols
            && x.Group === this.psGroupName)[0].IsHidden = true;
          disableCols = disableCols + ',' + cols;
        }
      });
    }
    else if (!isNullOrUndefined(this.CheckProperties.HideApplicableColumns) && this.CheckProperties.HideApplicableColumns.length > 0 && !initialStage) {
      this.CheckProperties.HideApplicableColumns.forEach((docItem: any) => {
        // Hidding Columns in Update Check Form, Based on Document Status using Property
        docItem.HideCols.forEach((hideCols: any) => {
          if (selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == hideCols
            && x.Group === this.psGroupName).length > 0) {
            selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == hideCols
              && x.Group === this.psGroupName)[0].IsHidden = true;
            disableCols = disableCols + ',' + hideCols;
          }
          if (selitem.IsApplicable != docItem.Value) {
            if (selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == hideCols
              && x.Group === this.psGroupName &&x.FieldName != "UANReasonforNA" && x.FieldName != "IsApplicableFileAction"
              && x.FieldName != "FileName" 
              && x.FieldName != "NotApplicableRemarks").length > 0
              && this.CheckDocData.UploadStatus !== BGVStatus.Document_Upload_Pending_with_BGV_Manager
              && this.CheckDocData.UploadStatus !== BGVStatus.Verification_Completed_Pending_with_BGV_Manager) {
              selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == hideCols
                && x.Group === this.psGroupName &&x.FieldName != "UANReasonforNA" && x.FieldName != "IsApplicableFileAction"
                && x.FieldName != "FileName"
                && x.FieldName != "NotApplicableRemarks")[0].IsHidden = false;
              enableCols = enableCols + ',' + hideCols;
            }
          } else {
            if (selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == hideCols
              && x.Group === this.psGroupName && (x.FieldName == "UANReasonforNA" ||x.FieldName == "IsApplicableFileAction"
                || x.FieldName == "FileName"
                || x.FieldName == "NotApplicableRemarks")).length > 0) {
              selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == hideCols &&
                x.Group === this.psGroupName && (x.FieldName == "UANReasonforNA" || x.FieldName == "IsApplicableFileAction"
                  || x.FieldName == "FileName"
                  || x.FieldName == "NotApplicableRemarks"))[0].IsHidden = false;
              enableCols = enableCols + ',' + hideCols;
            }
          }
        });
      });
    } else {
      this.CheckProperties.HideApplicableColumns.forEach((docItem: any) => {
        // Hidding Columns in Update Check Form, Based on Document Status using Property
        docItem.HideCols.forEach((hideCols: any) => {
          if (selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == hideCols
            && x.Group === this.psGroupName && (x.FieldName == "UANReasonforNA" ||x.FieldName == "IsApplicableFileAction"
              || x.FieldName == "FileName"
              || x.FieldName == "NotApplicableRemarks")).length > 0) {
            selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == hideCols
              && x.Group === this.psGroupName && x.FieldName == "UANReasonforNA" ||(x.FieldName == "IsApplicableFileAction"
                || x.FieldName == "FileName"
                || x.FieldName == "NotApplicableRemarks"))[0].IsHidden = true;
            disableCols = disableCols + ',' + hideCols;
          }
        });
      });
    }

    if (selitem.Phase == 1) {
      if (selitem.UpdateFormTemplate.filter((x: any) => (x.FieldName == "HigherEducationFlag"
        || x.FieldName == "FinalSemesterPendingFlag"
        || x.FieldName == "PreviousEmploymentFlag")
        && x.Group === this.psGroupName).length > 0) {
        selitem.UpdateFormTemplate.filter((x: any) => (x.FieldName == "HigherEducationFlag"
          || x.FieldName == "FinalSemesterPendingFlag"
          || x.FieldName == "PreviousEmploymentFlag")
          && x.Group === this.psGroupName).forEach((x: any) => {
            x.IsHidden = true
          });
      }
    }

    if (!isNullOrUndefined(this.editForm) && this.editForm.length > 0) {
      let cusitem = this.editForm.filter(x =>
        x.FormId === 'CUSID_' + selitem.TransactionId + '_');
      if (cusitem.length > 0 && !isNullOrUndefined(cusitem[0])) {
        setTimeout(() => {
          cusitem[0].setDispcols();
          if (enableCols.length > 0) { cusitem[0].enableFields(enableCols.substring(1, enableCols.length), false); }
          if (disableCols.length > 0) { cusitem[0].enableFields(disableCols.substring(1, enableCols.length), false); }
        }, 120);

      }
    }
  }

  hideFieldColumns(selitem: any) {

    let disableCols: string = '';
    let enableCols: string = '';

    let fldcolumns = JSON.parse(`[{"HideCols":["Ispossiblesuspect","DocumentStatusDesc","InsufficiencyType","DocumentList","InsufficiencyComment","IsApplicable","HigherEducationFlag","FinalSemesterPendingFlag","IsApplicableFileAction","FileName","NotApplicableRemarks","PreviousEmploymentFlag","IsPaidLessThan5Lakhs","CompanyInvestigationRequired","Clarification","ISResumeUANMatch","ISBGVUANMatch","ISOverlapwithCG","UANReasonforNA","ResumeStatus","ResumeMismatchComments"]}]`)
    let L3Hidecolumns = JSON.parse(`[{"HideCols":["Ispossiblesuspect","DocumentStatusDesc","InsufficiencyType","IsApplicable","HigherEducationFlag","FinalSemesterPendingFlag","IsApplicableFileAction","FileName","NotApplicableRemarks","PreviousEmploymentFlag","IsPaidLessThan5Lakhs","CompanyInvestigationRequired","ISResumeUANMatch","ISBGVUANMatch","ISOverlapwithCG","UANReasonforNA","ResumeStatus","ResumeMismatchComments"]}]`)
    let InitiateCaseHidecolumns = JSON.parse(`[{"HideCols":["Ispossiblesuspect","DocumentStatusDesc","InsufficiencyType","DocumentList","InsufficiencyComment","HigherEducationFlag","FinalSemesterPendingFlag","PreviousEmploymentFlag","IsPaidLessThan5Lakhs","CompanyInvestigationRequired","Clarification","ISResumeUANMatch","ISBGVUANMatch","ISOverlapwithCG","UANReasonforNA","ResumeStatus","ResumeMismatchComments"]}]`)
    let clarificationColumn=JSON.parse(`[{"HideCols":["Clarification"]}]`)

    //only for initiate Case
    if (((this._appSharedService.currentRoleData.RoleId === RoleCodes.IntiateInsuff && this.CheckDocData.PendingWith === RoleCodes.IntiateInsuff.toString())
      && (this.CheckDocData.UploadStatus === BGVStatus.Document_Upload_Pending_with_BGV_Manager || this.CheckDocData.UploadStatus === BGVStatus.Verification_Completed_Pending_with_BGV_Manager))) {
      InitiateCaseHidecolumns[0].HideCols.forEach((cols: any) => {
        if (selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == cols
          && x.Group === this.psGroupName).length > 0) {
          selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == cols
            && x.Group === this.psGroupName)[0].IsHidden = true;
          disableCols = disableCols + ',' + cols;
        }
      });
    }

    if (this.RequestData.IsCreateMode === true) {
      fldcolumns[0].HideCols.forEach((cols: any) => {
        if (selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == cols
          && x.Group === this.psGroupName).length > 0) {
          selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == cols
            && x.Group === this.psGroupName)[0].IsHidden = true;
          disableCols = disableCols + ',' + cols;
        }
      });
    }
    else if (((this._appSharedService.currentRoleData.RoleId === RoleCodes.Recruiter && this.CheckDocData.PendingWith === RoleCodes.Recruiter.toString())
      || (this._appSharedService.currentRoleData.RoleId === RoleCodes.Candidate && this.CheckDocData.PendingWith === RoleCodes.Candidate.toString())
      || (this._appSharedService.currentRoleData.RoleId === RoleCodes.Employee && this.CheckDocData.PendingWith === RoleCodes.Employee.toString()))
      && (this.CheckDocData.UploadStatus === BGVStatus.L3_Insufficiency_Additional_details_or_documents||this.CheckDocData.UploadStatus === BGVStatus.L3_Insufficiency_Additional_details_or_documents_1||this.CheckDocData.UploadStatus === BGVStatus.L3_Insufficiency_Additional_details_or_documents_2)) {
      L3Hidecolumns[0].HideCols.forEach((cols: any) => {
        if (selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == cols
          && x.Group === this.psGroupName).length > 0) {
          selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == cols
            && x.Group === this.psGroupName)[0].IsHidden = true;
          disableCols = disableCols + ',' + cols;
        }
      });
    }
    else if (!isNullOrUndefined(this.CheckProperties.HideColumns) && this.CheckProperties.HideColumns.length > 0) {
      this.CheckProperties.HideColumns.forEach((docItem: any) => {
        // Hidding Columns in Update Check Form, Based on Document Status using Property
        docItem.HideCols.forEach((hideCols: any) => {
          if (selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == hideCols
            && x.Group === this.psGroupName).length > 0) {
            selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == hideCols
              && x.Group === this.psGroupName)[0].IsHidden = true;
            disableCols = disableCols + ',' + hideCols;
          }
          if (selitem.DocumentStatusDesc != docItem.Value) {
            if (selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == hideCols
              && x.Group === this.psGroupName).length > 0) {
              selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == hideCols
                && x.Group === this.psGroupName)[0].IsHidden = false;
              enableCols = enableCols + ',' + hideCols;
            }
          }

          // selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == "UANReasonforNA"
          //       && x.Group === this.psGroupName)[0].IsHidden = true;
          
          if (selitem.ResumeStatus === "Matched"||selitem.DocumentStatusDesc==="Unavailable") {
            if (selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == "ResumeMismatchComments"
            && x.Group === this.psGroupName).length > 0) {
            selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == "ResumeMismatchComments"
              && x.Group === this.psGroupName)[0].IsHidden = true;
              disableCols = disableCols + ',' + hideCols;
            }
          }
          if (selitem.ResumeStatus === "Not Matched") {
            if(selitem.DocumentStatusDesc!="Unavailable"){
            if (selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == "ResumeMismatchComments"
            && x.Group === this.psGroupName).length > 0) {
            selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == "ResumeMismatchComments"
              && x.Group === this.psGroupName)[0].IsHidden = false;
              disableCols = disableCols + ',' + hideCols;
            }
            }
          }
          // selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == "UANReasonforNA"
          //       && x.Group === this.psGroupName)[0].IsHidden = true;

          // if (selitem.ISOverlapwithCG === "Yes" || selitem.ISOverlapwithCG === "No") {
          //   if (selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == "UANReasonforNA"
          //     && x.Group === this.psGroupName).length > 0) {
          //     selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == "UANReasonforNA"
          //       && x.Group === this.psGroupName)[0].IsHidden = true;
          //       disableCols = disableCols + ',' + hideCols;
          //     }
          // }
          // if (selitem.ISOverlapwithCG === "NA") {
          //   if (selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == "UANReasonforNA"
          //     && x.Group === this.psGroupName).length > 0) {
          //     selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == "UANReasonforNA"
          //       && x.Group === this.psGroupName)[0].IsHidden = false;
          //       disableCols = disableCols + ',' + hideCols;
          //     }
          // }
        });

        
      if(selitem.UploadStatus === BGVStatus.L3_Insufficiency_cleared)
      {
        clarificationColumn[0].HideCols.forEach((cols: any) => {
      
          if (selitem.UpdateFormTemplate.filter((x: any) => (x.FieldName == cols
          )
          && x.Group === this.psGroupName).length > 0) {
  
           selitem.UpdateFormTemplate.filter((x: any) => x.FieldName == cols
              && x.Group === this.psGroupName)[0].IsHidden = false;
              //enableCols = enableCols + ',' + cols;        
          }
        });
      }
      });
      
    }

    if (selitem.Phase == 1) {
      if (selitem.UpdateFormTemplate.filter((x: any) => (x.FieldName == "HigherEducationFlag"
        || x.FieldName == "FinalSemesterPendingFlag"
        || x.FieldName == "PreviousEmploymentFlag")
        && x.Group === this.psGroupName).length > 0) {
        selitem.UpdateFormTemplate.filter((x: any) => (x.FieldName == "HigherEducationFlag"
          || x.FieldName == "FinalSemesterPendingFlag"
          || x.FieldName == "PreviousEmploymentFlag")
          && x.Group === this.psGroupName).forEach((x: any) => {
            x.IsHidden = true
          });
      }
    }


    // console.log(selitem.UpdateFormTemplate);


    // this.editForm.setDispcols();

    if (!isNullOrUndefined(this.editForm) && this.editForm.length > 0) {
      let cusitem = this.editForm.filter(x =>
        x.FormId === 'CUSID_' + selitem.TransactionId + '_');
      if (cusitem.length > 0 && !isNullOrUndefined(cusitem[0])) {
        setTimeout(() => {
          cusitem[0].setDispcols();
          if (enableCols.length > 0) { cusitem[0].enableFields(enableCols.substring(1, enableCols.length), false); }
          if (disableCols.length > 0) { cusitem[0].enableFields(disableCols.substring(1, enableCols.length), false); }
          // cusitem[0].enableFields('ExecutionLocation,ExecutionFacility', false);
        }, 120);

      }
    }
  }

  deleteAttachment(data: any) {

    this.IsLoading = true;

    let odata: object = {
      'Module': 'Check',
      'Reference1': data.RequestId,
      'Reference2': data.CheckId,
      'Reference3': data.DocumentTypeId,
      'Reference4': data.SubDocumentTypeId,
      'TransactionId': data.TransactionId,
      // 'FilePath': data.FilePath,
      'FilePath': '',
      'FileName': data.FileName,
      'UserId': this._appSharedService.UserAccessInfo.actinguserid
    };

    let deleteData: any[] = [];
    deleteData.push(odata);

    let subscribe$: Subscription = this._requestService.deleteAttachment(deleteData).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
        this.notify = {
          info: {
            header: 'Confirmation Alert',
            message: 'Document deleted successfully',
            yes: '',
            no: '',

            IsHeaderHidden: true,
            HeaderIcon: 'fa fa-check-circle text-success',
            IsCrossEnable: true,
            SizeClass: 'modal-sm'
          },

          action: '',
          item: data
        };

        this.displayNotifybox = true;

        data.FilePath = '';
        data.FileName = '';
        this.reLoadCheck.emit({ 'checkId': this.CheckId, 'requestId': this.RequestId, 'Reload': true, 'saveFlag': false });

      }
      this.IsLoading = false;
    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }



  closeAttachment(event: any): void {
    console.log(event.data);

    let SaveFlag: boolean = false;
    // let metaData = this.SubCheckMetaData.filter(x => x.IsModified === 1);
    // let attachData = this.AttachmentData.filter(x => x.IsModified === 1);

    // if (this.CheckDocData.IsModified === 1 || (!isNullOrUndefined(metaData) && metaData.length > 0)
    //   || (!isNullOrUndefined(attachData) && attachData.length > 0)) {
    //   SaveFlag = true;
    // }

    if (this.IsApplicableFlag === true) {
      this.CheckDocData.IsModified = 1;
      this.CheckDocData.NotApplicableFileReferenceId = !isNullOrUndefined(event.data) && event.data.length > 0 ? event.data[0].TransactionId : this.CheckDocData.NotApplicableFileReferenceId;
      this.CheckDocData.FileName = !isNullOrUndefined(event.data) && event.data.length > 0 ? event.data[0].FileName : this.CheckDocData.FileName;
    }
    this.DisplayAttachment = false;
    this.subDocId = 0;

    if (!isNullOrUndefined(event.Reload) && event.Reload !== '' && event.Reload === true && this.IsApplicableFlag === false) {
      this.reLoadCheck.emit({ 'checkId': this.CheckId, 'requestId': this.RequestId, 'Reload': true, 'saveFlag': SaveFlag });
    }
    this.IsApplicableFlag = false;
  }


  closePreviewAttachment(event: any): void {
    this.displayAllAttachmentbox = false;

  }


  setNotifyRes(event: any): void {
    console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;
    switch (event.action.toUpperCase()) {
      case 'DELETEATTACHMENT':
        if (event.result.toUpperCase() === 'YES') {
          this.deleteAttachment(event.item);
        }
        break;

      case 'DELETEDOCUMENT':
        if (event.result.toUpperCase() === 'DELETE') {
          this.deleteDocument(event.item);
        }
        break;

      case 'UNAVAILABLECONFIRM':
        if (event.result.toUpperCase() === 'YES') {
          event.item.DocumentList = null;
          event.item.DocumentListDesc = null;
          event.item.FinalSemesterPendingFlag = 'No';
          event.item.HigherEducationFlag = 'No';
          event.item.InsufficiencyComment = null;
          event.item.InsufficiencyType = null;
          event.item.InsufficiencyTypeDesc = null;
          event.item.PreviousEmploymentFlag = 'No';
          event.item.FileName = null;
          event.item.NotApplicableRemarks = null;
          this.IsPopupClosereloadFlag = false;


          // } else if (event.result.toUpperCase() === 'NO') {
        } else {
          event.item.DocumentStatusCode = event.item.OldDocumentStatusCode;
          event.item.DocumentStatusDesc = event.item.OldDocumentStatusDesc;
          event.item.InsufficiencyType = event.item.OldInsufficiencyType;
          event.item.InsufficiencyTypeDesc = event.item.OldInsufficiencyTypeDesc;
          event.item.DocumentList = event.item.OldDocumentList;
          event.item.DocumentListDesc = event.item.OldDocumentListDesc;
          event.item.FinalSemesterPendingFlag = event.item.OldFinalSemesterPendingFlag;
          event.item.HigherEducationFlag = event.item.OldHigherEducationFlag;
          event.item.InsufficiencyComment = event.item.OldInsufficiencyComment;
          event.item.InsufficiencyType = event.item.OldInsufficiencyType;
          event.item.InsufficiencyTypeDesc = event.item.OldInsufficiencyTypeDesc;
          event.item.PreviousEmploymentFlag = event.item.OldPreviousEmploymentFlag;
          event.item.FileName = null;
          event.item.NotApplicableRemarks = null;
          event.item.IsModified = 0;
          this.IsPopupClosereloadFlag = false;
          // if (!isNullOrUndefined(this.CheckProperties.DefaultInsufficiencyType) && this.CheckProperties.DefaultInsufficiencyType.length > 0) {
          //   this.CheckProperties.DefaultInsufficiencyType.forEach((item: any) => {
          //     let defaultvalue = item.UploadStatus.filter((x: any) => x === event.item.UploadStatus);

          //     if (!isNullOrUndefined(defaultvalue) && defaultvalue.length > 0) {
          //       event.item.InsufficiencyType = item.Code;
          //       event.item.InsufficiencyTypeDesc = item.CodeDescription;
          //     }
          //   });
          // }

        }
        this.hideFieldColumns(event.item);
        break;

      case 'CLEARINSUFFICIENCY':
        if (event.result.toUpperCase() === 'YES') {
          this.updateInsuff.emit({
            'checkId': this.CheckId, 'requestId': this.RequestId, 'ActionCode': ActionCode.Clear_Insufficiency
            , 'documentTypeId': this.CheckDocData.DocumentTypeId,'Clarification' :this.CheckDocData.Clarification
          });

        }
        break;
      case 'HIGHESTEDUCATIONSUBMIT':
        if (event.result.toUpperCase() === 'SUBMIT') {
          this.updateInsuff.emit({
            'checkId': this.CheckId, 'requestId': this.RequestId, 'ActionCode': ActionCode.Submit_Highest_Education_Previous_Employment
            , 'documentTypeId': this.CheckDocData.DocumentTypeId
          });
        }
        break;
    }
    if (canClose) {
      this.closeNotifybox();
    }
  }

  closeNotifybox(): void {
    if (this.IsPopupClosereloadFlag === true) {
      this.CheckDocData.DocumentStatusCode = this.CheckDocData.OldDocumentStatusCode;
      this.CheckDocData.DocumentStatusDesc = this.CheckDocData.OldDocumentStatusDesc;
      this.CheckDocData.InsufficiencyType = this.CheckDocData.OldInsufficiencyType;
      this.CheckDocData.InsufficiencyTypeDesc = this.CheckDocData.OldInsufficiencyTypeDesc;
      this.CheckDocData.DocumentList = this.CheckDocData.OldDocumentList;
      this.CheckDocData.DocumentListDesc = this.CheckDocData.OldDocumentListDesc;
      this.CheckDocData.FinalSemesterPendingFlag = this.CheckDocData.OldFinalSemesterPendingFlag;
      this.CheckDocData.HigherEducationFlag = this.CheckDocData.OldHigherEducationFlag;
      this.CheckDocData.InsufficiencyComment = this.CheckDocData.OldInsufficiencyComment;
      this.CheckDocData.InsufficiencyType = this.CheckDocData.OldInsufficiencyType;
      this.CheckDocData.InsufficiencyTypeDesc = this.CheckDocData.OldInsufficiencyTypeDesc;
      this.CheckDocData.PreviousEmploymentFlag = this.CheckDocData.OldPreviousEmploymentFlag;
      this.CheckDocData.FileName = null;
      this.CheckDocData.NotApplicableRemarks = null;
      this.CheckDocData.IsModified = 0;
      this.hideFieldColumns(this.CheckDocData);

      this.IsPopupClosereloadFlag = false;
    }
    this.displayNotifybox = false;

  }


  showDeleteAttachmentConfirmation(item: any) {

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: 'Are you sure you want to delete the attachment?',
        yes: 'Yes',
        no: 'Cancel',

        IsHeaderHidden: true,
        HeaderIcon: 'fa fa-check-circle text-success',
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: 'DELETEATTACHMENT',
      item: item
    };

    this.displayNotifybox = true;
  }


  actButtonClick(event: any) {
    switch (event.buttonname.toUpperCase()) {
      case 'DOCSAVE':
        this.saveAttachment();
        break;
    }
  }


  saveAttachment() {
    let modifieditems = this.AttachmentData.filter(x => x.IsModified === 1 && !isNullOrUndefined(x.NewSubDocumentTypeId) && x.NewSubDocumentTypeId !== '');
    if (this.validateAttachmentSave(modifieditems)) {
      modifieditems.forEach(item => {
        item.FilePath = '';
      });

      this.IsLoading = true;
      let subscribe$: Subscription = this._requestService.updateSaveAttachment(modifieditems).subscribe(result => {
        if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
          this.showNotificationMessage('Document saved successfully', 1, '', null, '', '', true);
          this.reLoadCheck.emit({ 'checkId': this.CheckId, 'requestId': this.RequestId, 'Reload': true, 'saveFlag': false });
        }

        this.IsLoading = false;

      },

        err => {
          this.IsLoading = false;
        });
      this.subscriptionList$.push(subscribe$);

    }



  }


  validateAttachmentSave(modifieditems: any): boolean {
    let valid: boolean = true;

    if (isNullOrUndefined(modifieditems) || modifieditems.length === 0) {
      this.showNotificationMessage('No rows are modified.', 2, '', null, '', '', true);
      valid = false;
    }

    return valid;
  }

  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string, isCrossEnable: boolean) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: isCrossEnable,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item
    };

    this.displayNotifybox = true;
  }

  deleteDocument(modifieditems: any) {
    this.IsLoading = true;
    let subscribe$: Subscription = this._requestService.deleteDocumentType(modifieditems).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
        this.showNotificationMessage('Document section deleted successfully.', 1, '', null, '', '', true);
        this.reLoadCheck.emit({ 'checkId': this.CheckId, 'requestId': this.RequestId, 'Reload': true, 'saveFlag': false });
      }
      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
      });
     


    this.subscriptionList$.push(subscribe$);
  }

  showConfirmationforSectionDelete(modifieditem: any) {
    this.showNotificationMessage('Are you sure you want to delete ' + this.CheckDocData.DocumentTypeName + ' document?', 2, 'DELETEDOCUMENT', modifieditem, 'Delete', 'Cancel', true);
  }

  enabledisableFinalSemesterFlag() {
    // Exceptional handling for final semester flag enabled for vendor.

    if ((this._appSharedService.currentRoleData.RoleId === RoleCodes.VendorMember 
      || this._appSharedService.currentRoleData.RoleId === RoleCodes.VendorAdmin)
      && (this.CheckDocData.PendingWith === RoleCodes.Candidate.toString() 
      || this.CheckDocData.PendingWith === RoleCodes.Recruiter.toString())
      && !isNullOrUndefined(this.CheckDocData.HigherEducationFlag) && this.CheckDocData.HigherEducationFlag !== '' && this.CheckDocData.HigherEducationFlag.toUpperCase() === 'YES'
      && (isNullOrUndefined(this.CheckDocData.FinalSemesterPendingFlag) || this.CheckDocData.FinalSemesterPendingFlag === '' || this.CheckDocData.FinalSemesterPendingFlag === 'No') && this.CheckDocData.FinalSemesterPendingFlag.toUpperCase() !== 'YES'
      && this.RequestData.IsStopCaseSubmitted === false && this.RequestData.IsStopCase === false && this.CheckDocData.IsShowCauseNotice === false) {

      this.EditCheckProp.IsEditable = true;
      this.CheckDocData.IsEditable = true;
      this.IsReadonly = false;

      setTimeout(() => {
        let cusitem = this.editForm.filter(x =>
          x.FormId === 'CUSID_' + this.CheckDocData.TransactionId + '_');
        if (cusitem.length > 0 && !isNullOrUndefined(cusitem[0])) {

          if (!isNullOrUndefined(this.editForm)) {
            cusitem[0].enableFields('FinalSemesterPendingFlag', false);
            cusitem[0].enableFields('IsApplicable,DocumentList,DocumentStatusDesc,HigherEducationFlag,InsufficiencyComment,InsufficiencyType,Ispossiblesuspect,IsPaidLessThan5Lakhs,PreviousEmploymentFlag,IsApplicableFileAction,FileName,NotApplicableRemarks,UANReasonforNA', true);
            cusitem[0].setDispcols();
          }

        }
      }, 120);

    }
    else if ((this._appSharedService.currentRoleData.RoleId === RoleCodes.VendorMember || this._appSharedService.currentRoleData.RoleId === RoleCodes.VendorAdmin)
      && (this.CheckDocData.PendingWith === RoleCodes.VendorMember.toString() || this.CheckDocData.PendingWith === RoleCodes.VendorAdmin.toString())
      && (!isNullOrUndefined(this.CheckDocData.FinalSemesterPendingFlag) && this.CheckDocData.FinalSemesterPendingFlag !== '' && this.CheckDocData.FinalSemesterPendingFlag !== 'No' && this.CheckDocData.FinalSemesterPendingFlag.toUpperCase() === 'YES')
      && this.CheckDocData.IsShowCauseNotice === false
    ) {
      setTimeout(() => {
        let cusitem = this.editForm.filter(x =>
          x.FormId === 'CUSID_' + this.CheckDocData.TransactionId + '_');
        if (cusitem.length > 0 && !isNullOrUndefined(cusitem[0])) {

          if (!isNullOrUndefined(this.editForm)) {
            cusitem[0].enableFields('FinalSemesterPendingFlag', true);
            cusitem[0].setDispcols();
          }

        }
      }, 120);
    } else if ((this._appSharedService.currentRoleData.RoleId === RoleCodes.VendorMember || this._appSharedService.currentRoleData.RoleId === RoleCodes.VendorAdmin)
      && (this.CheckDocData.PendingWith === RoleCodes.VendorMember.toString() || this.CheckDocData.PendingWith === RoleCodes.VendorAdmin.toString())
      && this.CheckDocData.IsShowCauseNotice === true && this.CheckDocData.UploadStatus === BGVStatus.Insufficiency_Not_Cleared
    ) {
      this.EditCheckProp.IsEditable = false;
    } else if ((this._appSharedService.currentRoleData.RoleId === RoleCodes.VendorMember || this._appSharedService.currentRoleData.RoleId === RoleCodes.VendorAdmin)
      && (this.CheckDocData.PendingWith === RoleCodes.VendorMember.toString() || this.CheckDocData.PendingWith === RoleCodes.VendorAdmin.toString())
      && this.CheckDocData.CheckId === 4
      && (this.RequestData.RequestType === 'RT001' || this.RequestData.RequestType === 'RT002')
      && this.RequestData.Entity === 'FS India'
    ) {
      setTimeout(() => {
        let cusitem = this.editForm.filter(x =>
          x.FormId === 'CUSID_' + this.CheckDocData.TransactionId + '_');
        if (cusitem.length > 0 && !isNullOrUndefined(cusitem[0])) {

          if (!isNullOrUndefined(this.editForm)) {
            cusitem[0].enableFields('IsApplicable', true);
            cusitem[0].setDispcols();
          }

        }
      }, 120);
    } else if ((this._appSharedService.currentRoleData.RoleId === RoleCodes.VendorMember 
      || this._appSharedService.currentRoleData.RoleId === RoleCodes.VendorAdmin 
      || this._appSharedService.currentRoleData.RoleId === RoleCodes.IntiateInsuff)
      && (this.CheckDocData.PendingWith === RoleCodes.VendorMember.toString() 
      || this.CheckDocData.PendingWith === RoleCodes.VendorAdmin.toString() 
      || this.CheckDocData.PendingWith === RoleCodes.IntiateInsuff.toString())
      && (this.CheckDocData.UploadStatus === BGVStatus.L1_Insufficiency_Mandate_documents 
        || this.CheckDocData.UploadStatus === BGVStatus.L2_Insufficiency_Additional_details_or_documents
        || this.CheckDocData.UploadStatus === BGVStatus.Prev_Employment__InProgress
        || this.CheckDocData.UploadStatus === BGVStatus.Highest_Education__InProgress
        || this.CheckDocData.UploadStatus === BGVStatus.Final_Semester__InProgress        
        ||this.CheckDocData.UploadStatus ===BGVStatus.Interim_cleared_IR_P_Emp__Insufficiency
        ||this.CheckDocData.UploadStatus ===BGVStatus.Insufficiency_Paidup_Capital_Need_Financial_documents_1
        ||this.CheckDocData.UploadStatus ===BGVStatus.Insufficiency_Paidup_Capital_Need_Financial_documents_2
        ||this.CheckDocData.UploadStatus ===BGVStatus.Insufficiency_Paidup_Capital_Need_Financial_documents_3
        ||this.CheckDocData.UploadStatus ===BGVStatus.Insufficiency_Possible_Suspect_Need_Financial_documents_1
        ||this.CheckDocData.UploadStatus ===BGVStatus.Insufficiency_Possible_Suspect_Need_Financial_documents_2
        ||this.CheckDocData.UploadStatus ===BGVStatus.Insufficiency_Possible_Suspect_Need_Financial_documents_3
        ||this.CheckDocData.UploadStatus ===BGVStatus. L3_Insufficiency_Additional_details_or_documents
        ||this.CheckDocData.UploadStatus ===BGVStatus.L3_Insufficiency_Additional_details_or_documents_1
        ||this.CheckDocData.UploadStatus ===BGVStatus. L3_Insufficiency_Additional_details_or_documents_2)
    ) {
      this.EditCheckProp.IsEditable = false;
    }
    else if ((this._appSharedService.currentRoleData.RoleId === RoleCodes.Employee 
      || this._appSharedService.currentRoleData.RoleId === RoleCodes.Candidate )
      && (this.CheckDocData.PendingWith === RoleCodes.Candidate.toString() 
      || this.CheckDocData.PendingWith === RoleCodes.Employee.toString())
      && (this.CheckDocData.UploadStatus === BGVStatus.L1_Insufficiency_Mandate_documents 
        || this.CheckDocData.UploadStatus === BGVStatus.L2_Insufficiency_Additional_details_or_documents
        || this.CheckDocData.UploadStatus === BGVStatus.Prev_Employment__InProgress     
        || this.CheckDocData.UploadStatus === BGVStatus.Highest_Education__InProgress
        || this.CheckDocData.UploadStatus === BGVStatus.Final_Semester__InProgress
         ||this.CheckDocData.UploadStatus ===BGVStatus.Interim_cleared_IR_P_Emp__Insufficiency
         ||this.CheckDocData.UploadStatus ===BGVStatus.Insufficiency_Paidup_Capital_Need_Financial_documents_1
         ||this.CheckDocData.UploadStatus ===BGVStatus.Insufficiency_Paidup_Capital_Need_Financial_documents_2
         ||this.CheckDocData.UploadStatus ===BGVStatus.Insufficiency_Paidup_Capital_Need_Financial_documents_3
         ||this.CheckDocData.UploadStatus ===BGVStatus.Insufficiency_Possible_Suspect_Need_Financial_documents_1
         ||this.CheckDocData.UploadStatus ===BGVStatus.Insufficiency_Possible_Suspect_Need_Financial_documents_2
         ||this.CheckDocData.UploadStatus ===BGVStatus.Insufficiency_Possible_Suspect_Need_Financial_documents_3
         ||this.CheckDocData.UploadStatus ===BGVStatus. L3_Insufficiency_Additional_details_or_documents
         ||this.CheckDocData.UploadStatus ===BGVStatus.L3_Insufficiency_Additional_details_or_documents_1
         ||this.CheckDocData.UploadStatus ===BGVStatus. L3_Insufficiency_Additional_details_or_documents_2
         )
    ) {
      this.EditCheckProp.IsEditable = true;
    }
  }

  // This function is used to remove the given dpcode from
  // the FieldTemplate List for all DP - except the given input DP if avoid flag is true
  removeDPfromList(dpCode: string, bAvoid?: boolean): void {
    bAvoid = isNullOrUndefined(bAvoid) ? false : bAvoid;

    if (!isNullOrUndefined(dpCode) && dpCode.toString().length > 0) {
      for (let i = 0; i < this.SubCheckMetaData.length; i++) {

        // avoid the current one
        if (this.SubCheckMetaData[i].EducationalLevel != dpCode || bAvoid) {

          let fieldtmplt: FieldTemplate[] = this.CheckTemplate.filter(x =>
            x.FieldName.toUpperCase() === this.FldName);

          if (fieldtmplt.length > 0 && !isNullOrUndefined(fieldtmplt[0].Control.List)) {
            let index = fieldtmplt[0]?.Control?.List?.findIndex(x =>
              x[fieldtmplt[0].Control.Source!.KeyField!] == dpCode);

            if (index != null && index >= 0) {
              fieldtmplt[0]?.Control?.List?.splice(index, 1);
              fieldtmplt[0].Control.List = fieldtmplt[0].Control.List!.slice(0);
            }
          }
        }
      }
    }

    this.cdRef.detectChanges();
  }

  // This function is used to add the given dpcode to the FieldTemplate List for all DP
  addDPtoList(dpCode: string) {

    if (!isNullOrUndefined(dpCode) && dpCode.toString().length > 0) {
      for (let i = 0; i < this.SubCheckMetaData.length; i++) {
        let fieldtmplt: FieldTemplate[] = this.CheckTemplate.filter(x =>
          x.FieldName.toUpperCase() === this.FldName);

        if (fieldtmplt.length > 0 && !isNullOrUndefined(fieldtmplt[0].Control.List)) {

          let index = fieldtmplt[0]?.Control?.List?.findIndex((x => x[fieldtmplt[0].Control.Source!.KeyField!] == dpCode));
          if (index != null && index < 0) {
            let dpDetail = this.dropDownList.filter((x: any) => x[fieldtmplt[0].Control.Source!.KeyField!] == dpCode);
            if (dpDetail.length > 0) {
              let dpDetailNew = JSON.parse(JSON.stringify(dpDetail[0]));

              fieldtmplt[0]?.Control?.List?.push(dpDetailNew);
              fieldtmplt[0].Control.List = fieldtmplt[0].Control.List!
                .sort((a, b) => (a['Entity'] > b['Entity']) ? 1 : ((b['Entity'] > a['Entity']) ? -1 : 0));
              fieldtmplt[0].Control.List = fieldtmplt[0].Control.List.slice(0);
            }

          }
        }

      }

      this.cdRef.detectChanges();
    }
  }

  async getDropDownData(): Promise<void> {
    this.dropDownList = [];
    // this.IsLoading = true;

    let subscribe$: Subscription = this._requestService.getCodeList('EducationLevel').subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        this.dropDownList = result.Data;
      }

      // this.IsLoading = false;

    },
      err => {
        console.log('Error on getting getDropDownData.');
        // this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  setActionButtonPermissions(): void {
    if (!isNullOrUndefined(this.CheckDocData.UpdateFormProperties) && this.CheckDocData.UpdateFormProperties.length > 0
      && this.CheckDocData.UpdateFormProperties.filter((x: any) => (x.Name === 'HighestEducationSubmit' || x.Name === 'ClearInsufficiency')).length > 0) {

      this.CheckDocData.UpdateFormProperties.filter((x: any) => (x.Name === 'HighestEducationSubmit' || x.Name === 'ClearInsufficiency')).forEach((item: any) => {

        if (!isNullOrUndefined(item.Name) && item.Name !== '') {
          item.HasPermission = false;
          item.IsApplicable = false;
          item.IsEnabled = false;
          let peritem = this.CheckPermission.indexOf(item.Name);
          if (peritem >= 0) {
            item.HasPermission = true;
          }
          switch (item.Name.toUpperCase()) {
            case 'CLEARINSUFFICIENCY':

              if (this.enableInsufficiencyClearance() && !this.enableSubmit()) {
                item.IsApplicable = true;
                item.IsEnabled = true;
              }

              break;
            case 'HIGHESTEDUCATIONSUBMIT':
              if (this.enableSubmit()) {
                item.IsApplicable = true;
                item.IsEnabled = true;
              }
              break;
          }
        }
      });
    }
  }

  enableInsufficiencyClearance(): boolean {
    let flag: boolean = false;
    if ((this.CheckDocData.UploadStatus === BGVStatus.L1_Insufficiency_Mandate_documents 
      || this.CheckDocData.UploadStatus === BGVStatus.L2_Insufficiency_Additional_details_or_documents
      || this.CheckDocData.UploadStatus === BGVStatus.L3_Insufficiency_Additional_details_or_documents
      || this.CheckDocData.UploadStatus === BGVStatus.L3_Insufficiency_Additional_details_or_documents_1
      || this.CheckDocData.UploadStatus === BGVStatus.L3_Insufficiency_Additional_details_or_documents_2
      // || this.CheckDocData.UploadStatus === BGVStatus.Interim_cleared_IR__Highest_education_pending
      // || this.CheckDocData.UploadStatus === BGVStatus.Interim_cleared_IR__Highest_education_check_in_progress
      // || this.CheckDocData.UploadStatus === BGVStatus.Interim_cleared_IR__Final_Semester_Documents_pending
       || this.CheckDocData.UploadStatus === BGVStatus.Interim_cleared_IR_P_Emp__Insufficiency
      // || this.CheckDocData.UploadStatus === BGVStatus.Interim_cleared_IR_P_Emp__Inprogress
      || this.CheckDocData.UploadStatus ===BGVStatus.Insufficiency_Paidup_Capital_Need_Financial_documents_1
      || this.CheckDocData.UploadStatus ===BGVStatus.Insufficiency_Possible_Suspect_Need_Financial_documents_1
      || this.CheckDocData.UploadStatus ===BGVStatus.Insufficiency_Possible_Suspect_Need_Financial_documents_2
      || this.CheckDocData.UploadStatus === BGVStatus.Insufficiency_Possible_Suspect_Need_Financial_documents_3
      || this.CheckDocData.UploadStatus === BGVStatus.Insufficiency_Paidup_Capital_Need_Financial_documents_3
      || this.CheckDocData.UploadStatus === BGVStatus.Insufficiency_Paidup_Capital_Need_Financial_documents_2
      || this.CheckDocData.UploadStatus === BGVStatus.Prev_Employment__InProgress
      || this.CheckDocData.UploadStatus === BGVStatus.Highest_Education__InProgress
      || this.CheckDocData.UploadStatus === BGVStatus.Final_Semester__InProgress
      || this.CheckDocData.UploadStatus === BGVStatus.Insufficiency_Not_Cleared)
      && (this.CheckDocData.PendingWith === RoleCodes.Candidate.toString() || this.CheckDocData.PendingWith === RoleCodes.Recruiter.toString()
        || this.CheckDocData.PendingWith === RoleCodes.IntiateInsuff.toString() || this.CheckDocData.PendingWith === RoleCodes.VendorMember.toString()
        || this.CheckDocData.PendingWith === RoleCodes.VendorAdmin.toString() || this.CheckDocData.PendingWith === RoleCodes.Employee.toString())
      && this.RequestData.IsStopCaseSubmitted === false
      && this.RequestData.IsStopCase === false
      
    ) {
      flag = true;
    }
    
    return flag;
  }

  enableSubmit(): boolean {
    let flag: boolean = false;
    if (this.CheckDocData.DisableHighestPrevField === false
      && (this.CheckDocData.PendingWith === RoleCodes.Candidate.toString() || this.CheckDocData.PendingWith === RoleCodes.Recruiter.toString()
        || this.CheckDocData.PendingWith === RoleCodes.IntiateInsuff.toString() || this.CheckDocData.PendingWith === RoleCodes.VendorMember.toString()
        || this.CheckDocData.PendingWith === RoleCodes.VendorAdmin.toString() || this.CheckDocData.PendingWith === RoleCodes.Employee.toString())
      && (this.CheckDocData.HigherEducationFlag === 'Yes' || this.CheckDocData.PreviousEmploymentFlag === 'Yes')
      && this.RequestData.IsStopCaseSubmitted === false
      && this.RequestData.IsStopCase === false
    ) {
      flag = true;
    }
    return flag;
  }

  actionButtonClick(data: any) {
   
    if (this.CheckDocData.CheckId === 8) { 
      // For CheckId 8, directly clear the insufficiency without document checks
      // if (!isNullOrUndefined(this.CheckDocData.DocumentListDesc)) {
      //     var requiredDocument = this.CheckDocData.DocumentListDesc.split(',');
      // }

      let modifieditems = this.checkList.filter(x => x.IsModified === 1);
      
      if (!isNullOrUndefined(data.Name) && data.Name !== '') {
          switch (data.Name.toUpperCase()) {
            
              case 'CLEARINSUFFICIENCY':
                  // Handle clear insufficiency for CheckId 8
                  if (this._appSharedService.currentRoleData.RoleId === RoleCodes.Employee
                      && (isNullOrUndefined(this.CheckDocData.SampleProvidedFlag) || this.CheckDocData.SampleProvidedFlag === 'No')) {
                      this.showNotificationMessage('Please fill in the field "Sample provided" to submit your form and clear your insufficiency', 2, '', null, '', '', true);
                      break;
                  }
                  if ((isNullOrUndefined(this.CheckDocData.Clarification) || this.CheckDocData.Clarification.trim() === '') 
                      && (this._appSharedService.currentRoleData.RoleId === RoleCodes.Employee || this._appSharedService.currentRoleData.RoleId === RoleCodes.Candidate)) {
                      this.showNotificationMessage('Please fill in the field "Clarification Comments" ', 2, '', null, '', '', true);
                      break;
                  }
                  // No need for document checking when CheckId = 8
                  this.showNotificationMessage('Are you sure you want to clear the insufficiency?', 1, 'CLEARINSUFFICIENCY', null, 'Yes', 'Cancel', true);
                  break;

              case 'HIGHESTEDUCATIONSUBMIT':
                  this.showNotificationMessage('Are you sure you want to submit the check?', 1, 'HIGHESTEDUCATIONSUBMIT', null, 'Submit', 'Cancel', true);
                  break;
          }
      }

  } else {
      // For other CheckIds, check if the required documents are uploaded
      if (!isNullOrUndefined(this.CheckDocData.DocumentListDesc)) {
          var requiredDocument = this.CheckDocData.DocumentListDesc.split(',');
      }
      
      let modifieditems = this.checkList.filter(x => x.IsModified === 1);

      if (!isNullOrUndefined(data.Name) && data.Name !== '') {
          switch (data.Name.toUpperCase()) {
            
              case 'CLEARINSUFFICIENCY':
                  if (this.CheckDocData.CheckId !== 8) {  // Only check documents if CheckId is not 8
                      var flag = false;
                      for (let k = 0; k < requiredDocument.length; k++) {
                          flag = false;
                          for (let i = 0; i < this.AttachmentData.length; i++) {
                              this.AttachmentData[i]["UploadedDate"].setHours(0, 0, 0, 0);
                              if (((requiredDocument[k].trim() === this.AttachmentData[i]["SubDocumentTypeName"].trim()) 
                                  && !isNullOrUndefined(this.AttachmentData[i]["FileName"])) || (requiredDocument[k].trim().includes("other"))) {
                                  flag = true;
                                  break;
                              }
                          }
                          if (flag === false) {
                              break;
                          }
                      }
                      if (flag === false) {
                          this.showNotificationMessage('Please upload the "Required document " to clear your insufficiency', 2, '', null, '', '', true);
                          break;
                      }
                  }
                  // Proceed with the insufficiency clear after checking the documents
                  this.showNotificationMessage('Are you sure you want to clear the insufficiency?', 1, 'CLEARINSUFFICIENCY', null, 'Yes', 'Cancel', true);
                  break;

              case 'HIGHESTEDUCATIONSUBMIT':
                  this.showNotificationMessage('Are you sure you want to submit the check?', 1, 'HIGHESTEDUCATIONSUBMIT', null, 'Submit', 'Cancel', true);
                  break;
          }
      }
  }
}

  
}


