import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { AngularDraggableModule } from 'angular2-draggable';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { GridModule } from '../../modules/grid/grid.module';
import { CustomPipeModule, LocalizedDatePipe, LocalizedNumberPipe } from '../custompipe/custompipe.module';
import { FormModule } from '../form/form.module';
import { LoadingModule } from '../loading/loading.module';
import { NotificationModule } from '../notification/notification.module';
import { AttachmentComponent } from './attachment.component';
import { AttachmentService } from './attachment.service';
import { FileUploadComponent } from './fileupload/fileupload.component';
import { ExcelViewerModule } from '../excel-viewer/excel-viewer.module';


@NgModule({
  declarations: [
    AttachmentComponent,
    FileUploadComponent
  ],
  imports: [
    BrowserModule, FormsModule, AngularDraggableModule, TooltipModule,
    GridModule, NotificationModule,
    LoadingModule, FormModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      autoDismiss: true,
      closeButton: true,
      positionClass: 'toast-top-center',
      preventDuplicates: true
    }),
    TranslateModule.forRoot(),
    CustomPipeModule, NgxDocViewerModule, PdfViewerModule, ExcelViewerModule
  ],
  providers: [AttachmentService, LocalizedDatePipe, LocalizedNumberPipe],
  bootstrap: [AttachmentComponent],
  exports: [AttachmentComponent, FileUploadComponent]
})

export class AttachmentModule { }

