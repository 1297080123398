<!-- Manage Roles Component -->

<ng-container>
    <div class="h-100">
        <div class="contentScroll p-3 simpleCard" style="overflow: hidden !important;">
            <div class="card h-100 m-0">
                <div class="card-header">
                    <h3>
                        {{Title}}
                    </h3>
                </div>
                <div class="card-body overflow-auto p-0">
                    <div class="contentScroll p-3">
                        <div *ngIf="viewName == 'ListView'">
                            <div class="gridContainer">
                                <ng-container
                                    *ngIf="(acdListTemplate != null && acdListTemplate != undefined && acdListTemplate.length > 0)">

                                    <grid-dispctrl #overallgrid
                                        [FieldTemplate]="acdListTemplate"
                                        [GridData]="acdListData" [GridProp]="GridProp"
                                        [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                                        (ActionBarClick)="actButtonClick($event)">
                                    </grid-dispctrl>

                                </ng-container>
                            </div>

                        </div>

                        <div *ngIf="viewName == 'EditView'" >
                            <ng-container
                                *ngIf="(acdEditTemplate != null && acdEditTemplate != undefined && acdEditTemplate.length > 0)">

                                <div class="bg-light p-3">
                                    <p class="text-bold"></p>

                                    <div class="row form-inline"  tabindex="0">
                                        <customform [FormTemplate]="acdEditTemplate"
                                            [FormData]="editacdData" [FormProp]="FormProp" [FormConfig]="FormConfig"
                                            [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)"
                                            (FieldChange)="onChange($event)">
                                        </customform>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="card-header" style="padding-left: 0px;">
                                <h3>
                                    {{selectedTab == 'account' ? 'Account & Check Mapping' : selectedTab == 'check' ? 'Check & Document Type Mapping' : ''}}
                                </h3>

                            </div>

                            <div class="gridContainer" *ngIf="(selectedTab == 'account' || selectedTab == 'check')">
                                <ng-container
                                    *ngIf="(acdSubListTemplate != null && acdSubListTemplate != undefined && acdSubListTemplate.length > 0)">

                                    <grid-dispctrl #overallgrid
                                        [FieldTemplate]="acdSubListTemplate"
                                        [GridData]="acdSubListData" [GridProp]="{Editable : true, PaginationConfig:{ItemsPerPage:10}}"
                                        [GridDataTotalCount]="dataTotalCountMapping" (FieldClick)="onClick($event)"
                                        (ActionBarClick)="actButtonClick($event)">
                                    </grid-dispctrl>

                                </ng-container>
                            </div>

                        </div>
                    </div>
                    <div class="filters buttonHolder" id="vendorTab">
                        <div class="d-flex justify-content-between ">
                            <div></div>
                            <div>
                                <ng-container
                                    *ngIf="Config.ActionButtonsBottom != null && Config.ActionButtonsBottom != undefined">
                                    <ng-container
                                    *ngFor="let actionBar of (Config.ActionButtonsBottom | filterBy: 
                                                {
                                                    IsApplicable: true,HasPermission :true, 
                                                    Group: 
                                                    (
                                                        viewName == 'ListView' ? selectedTab + 'List' : 
                                                        viewName == 'EditView' ? selectedTab + 'Edit' : ''
                                                    ) 
                                                }
                                                | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">

                                        <button type="button" [ngClass]="actionBar.CustomClass"
                                            (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                            value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                            |
                                            translate
                                            }}
                                            <span *ngIf="actionBar.ShowArrow" class="ps-3"><i
                                                    class="ion ion-md-arrow-forward" aria-hidden="true"></i></span>
                                        </button>
                                    </ng-container>
                                </ng-container>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-center">
                        <div class="small align-self-center">
                            Copyright © 2022 Capgemini All rights reserved.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>

<!-- Filter Pop-up -->
<div *ngIf="DispFilter">
    <filter *ngIf="(acdListTemplate!= undefined && acdListTemplate!= null && acdListTemplate.length > 0)"
        [FieldTemplate]="acdListTemplate" [GridData]="acdListDataALL" (close)="showFilter($event)"
        (selected)="setFilter($event)" [InitialCondn]="curFiltCondn">
    </filter>
</div>

<!-- Excel Download -->
<excel-export #excelexport [FileName]="ExcelFileName"></excel-export>

<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
        (selected)="setNotifyRes($event)" (close)="closeNotifybox()"></notification>
</div>