export class SectionInfo {
    SectionName: string = '';

    IsHidden: boolean = false;

    AttachmentList: AttachmentInfo[] = [];

    constructor(init?: Partial<SectionInfo>) {
        Object.assign(this, init);
    }

}

export class AttachmentInfo {
    TransactionId?: number = 0;

    Module: string = '';

    Reference1: number = 0;
    Reference2?: number = 0;
    Reference3?: number = 0;
    Reference4?: number = 0;
    Reference5?: number = 0;
    Reference6?: number = 0;

    FileName?: string;
    FilePath?: string;
    RowStatus?: string;

    CreatedName?: string;
    CreatedBy?: string;
    CreatedDate?: Date;

    UpdatedName?: string;
    UpdatedBy?: string;
    UpdatedDate?: Date;

    UserId?: number = 0;
    IsDeletable?: boolean = false;
    
    IsSelected?: boolean = false;   // Used only in UI to select file

    constructor(init?: Partial<AttachmentInfo>) {
        Object.assign(this, init);
    }

}


export class UploadAttachInfo {
    TransactionId: number = 0;
    Reference1: number = 0;
    Reference2?: number = 0;
    Reference3?: number = 0;
    Reference4?: number = 0;
    Reference5?: number = 0;
    Reference6?: number = 0;
    FilePath?: string = '';
    IsDeleted?: boolean = false;
    UploadedBy?: string = '';
    UploadedDate?: Date;
    UploadedByName?: string = '';
    LastUpdatedDate?: Date;
    UploadedType?: string = '';
    UserId?: string = '';
    FileName?: string = '';
    Module?: string = '';

    constructor(init?: Partial<UploadAttachInfo>) {
        Object.assign(this, init);
    }
}

