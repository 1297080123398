import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ProcessResponse } from '../app.interface';
import { environment } from '../../environments/environment';


@Injectable()
export class AdminService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private VendorURL: string;
  private UserURL: string;
  private CheckURL: string;
  private RequestUrl: string;


  constructor(private _http: HttpClient) {
    this.VendorURL = environment.apiURL.VendorURL;
    this.UserURL = environment.apiURL.UserURL;
    this.CheckURL = environment.apiURL.CheckURL;
    this.RequestUrl = environment.apiURL.RequestURL;


  }

  private handleError(error: any): Promise<any> {
    console.error('Error : ', error.message);
    return Promise.reject(error.message || error);
  }

  getVendorDetails(): Observable<ProcessResponse<any>> {
    return this._http.get<ProcessResponse<any>>(this.VendorURL + 'GetAllVendorDetails',
      { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  getVendorDetailsById(vendorId: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('vendorId', vendorId);

    return this._http.get<ProcessResponse<any>>(this.VendorURL + 'getVendorDetailsById',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  updateVendorDetails(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.VendorURL + 'UpdateVendorDetails', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteVendor(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.VendorURL + 'DeleteVendor', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteVolume(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.VendorURL + 'DeleteVolume', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }
  getVendorAssignmentDetails(): Observable<ProcessResponse<any>> {

    return this._http.get<ProcessResponse<any>>(this.VendorURL + 'GetVendorAssignmentDetails',
      { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  getVendorAssignmentMasters(codeType: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('CodeType', codeType);

    return this._http.get<ProcessResponse<any>>(this.VendorURL + 'GetVendorAssignmentMasters',
      { headers: this.headers , params: Params})
      .pipe(
        catchError(this.handleError)
      );
  }


  updateVendorAssignment(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.VendorURL + 'UpdateVendorAssignment', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }


  getMemberDetailsByVendorId(vendorId: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('vendorId', vendorId);

    return this._http.get<ProcessResponse<any>>(this.VendorURL + 'GetMemberDetailsById',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getMemberDetailsByMemberId(memberId: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('memberId', memberId);

    return this._http.get<ProcessResponse<any>>(this.VendorURL + 'GetMemberDetailsByMemberId',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  updateMemberDetails(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.VendorURL + 'UpdateMemberDetails', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteMember(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.VendorURL + 'DeleteMember', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getUserRoleDetails(): Observable<ProcessResponse<any>> {
    return this._http.get<ProcessResponse<any>>(this.UserURL + 'GetRoleDetails',
      { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  getUserRoleDetailsById(userId: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('userId', userId);

    return this._http.get<ProcessResponse<any>>(this.UserURL + 'GetRoleDetailsById',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }
  getRoleDetailsSearchFilter(odata: any, allPendingFlag: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('AllPendingFlag', allPendingFlag);
    Params = Params.append('PageSize', odata.PageSize);
    Params = Params.append('PageIndex', odata.PageIndex);
    

    return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'GetRoleDetailsSearchFilter', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }
  
  updateUserRoleDetails(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.UserURL + 'UpdateRoleDetails', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  updateCrnNumberDetails(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.UserURL + 'UpdateCrnNumber', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteUserRole(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.UserURL + 'DeleteRole', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  } 

  getBUHRDetails(): Observable<ProcessResponse<any>> {
    return this._http.get<ProcessResponse<any>>(this.UserURL + 'GetBUHRDetails',
      { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      );
  }
  uploadBUHRExcel(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();    
    let headers1 = new HttpHeaders();
    return this._http.post<ProcessResponse<any>>(this.UserURL + 'UploadBUHRList',
        odata, { headers: headers1, params: Params, reportProgress: true })
        .pipe(
            catchError(this.handleError)
        );

}
downloadSampleExcel(): Observable<any> {
  let Params = new HttpParams();

  return this._http.get(this.UserURL + 'DownloadSampleExcel', 
      { headers: this.headers, params: Params, responseType: 'blob' })
      .pipe(map(res => {
          return {
              data: res
          };
      }))
      .pipe(
          catchError(this.handleError)
      );
}
  getAccountCheckDocumentDetails(type: string, documentTypeId: number): Observable<ProcessResponse<any>> {

    let methodName =
      type == "account" ? "GetAccountDetails" :
        type == "check" ? "GetCheckDetails" :
          type == "document" ? "GetDocumentDetails" :
            type == "subDoc" ? "GetSubDocumentDetails" :
              type == "account & Check Mapping" ? "GetAccountCheckMappingDetails" :
                type == "check & Document Type Mapping" ? "GetCheckDocumentTypeMappingDetails" : "";

    let Params = new HttpParams();
    Params = Params.append('documentTypeId', documentTypeId);

    return this._http.get<ProcessResponse<any>>(this.CheckURL + methodName,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  
  GetMappingDetailsByAccountId(type: string,accountId: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('mappingId', accountId);

    let methodName =
    type == "account" ? "GetAccountCheckMappingDetailsByAccountId" :
      type == "check" ? "GetCheckDocuementTypeMappingDetailsByCheckId" : "";


    return this._http.get<ProcessResponse<any>>(this.CheckURL + methodName,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getAccountCheckDocumentDetailsById(type: string, acdTranId: any): Observable<ProcessResponse<any>> {

    let methodName =
      type == "account" ? "GetAccountDetailsById" :
        type == "check" ? "GetCheckDetailsById" :
          type == "document" ? "GetDocumentDetailsById" :
            type == "subDoc" ? "GetSubDocumentDetailsById"  :
            type == "account & Check Mapping" ? "GetAccountCheckMappingDetailsById" :
              type == "check & Document Type Mapping" ? "GetCheckDocumentTypeMappingDetailsById" : "";

    let Params = new HttpParams();
    Params = Params.append('acdTranId', acdTranId);

    return this._http.get<ProcessResponse<any>>(this.CheckURL + methodName,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  updateAccountCheckDocumentDetails(type: any, odata: any): Observable<ProcessResponse<any>> {

    let methodName =
      type == "account" ? "UpdateAccountDetails" :
        type == "check" ? "UpdateCheckDetails" :
          type == "document" ? "UpdateDocumentDetails" :
            type == "subDoc" ? "UpdateSubDocumentDetails" :
            type == "account & Check Mapping" ? "UpdateAccountCheckMappingDetails" :
              type == "check & Document Type Mapping" ? "UpdateCheckDocumentTypeMappingDetails" : "";

    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.CheckURL + methodName, odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteAccountCheckDocumentData(type: string, odata: any): Observable<ProcessResponse<any>> {

    let methodName =
      type == "account" ? "DeleteAccount" :
        type == "check" ? "DeleteCheck" :
          type == "document" ? "DeleteDocument" :
            type == "subDoc" ? "DeleteSubDocument" :
            type == "account & Check Mapping" ? "DeleteAccountCheckMapping" :
              type == "check & Document Type Mapping" ? "DeleteCheckDocumentTypeMapping" : "";
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.CheckURL + methodName, odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

}
