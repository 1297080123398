export class RegistryDetail {
    UserId: number = 0;
    EmailId: string = '';
    FirstName: string = '';
    LastName: string = '';
    SecretCode: any;

    ConfirmPassword: string = '';
    EncryptedPassword: string = '';

    constructor(init?: Partial<RegistryDetail>) {
        Object.assign(this, init);
    }
}
