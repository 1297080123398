<ng-container *ngIf="tabType=='plaintype'">

  <!-- <div class="tabset">
    
    <ng-container *ngIf="tabList!=null && tabList!=undefined && tabList.length>0">
      <ng-container *ngFor="let tab of tabList | filterBy: {CanShow: true}">
        
        <input type="radio" [name]="fieldRandomId+'_'+'tabset'" [id]="fieldRandomId+'-'+tab.Name"
           [value]="tab.Name" [checked]="tab.Name==selectedTab" [disabled]="tab.IsDisabled"
           [(ngModel)]="selectedTab" (change)="onChange(tab)">
        <label [for]="fieldRandomId+'-'+tab.Name">
          <i *ngIf="(tab.Icon.length > 0)" [class]="tab.Icon" class="pe-2"></i>{{tab.DisplayName | translate}}</label>
      </ng-container>
    </ng-container>

    <div class="tab-panels">
      <section [id]="selectedTab" class="tab-panel" style=" display: block;">
          <ng-content></ng-content>
      </section>
    </div>

  </div> -->

  <div class="tabset">
    <!--Start From Here-->
    <ng-container *ngIf="tabList!=null && tabList!=undefined && tabList.length>0">
      <ul class="nav nav-tabs" [id]="fieldRandomId+'_'+'tabset'" role="tablist">
        <ng-container *ngFor="let tab of tabList | filterBy: {CanShow: true}">
          <li class="nav-item" [id]="fieldRandomId+'-'+tab.Name">
            <a class="nav-link" href="#tabChildDetails" [ngClass]="{'active': (tab.Name==selectedTab)}"
              data-bs-toggle="tab" role="tab" [attr.aria-selected]="(tab.Name==selectedTab)"
              [attr.aria-label]="tab.Name" (click)="onChange(tab)">{{tab.DisplayName | translate}}</a>
          </li>
        </ng-container>
      </ul>

      <div class="tab-content">
        <div class="tab-pane fade show active" [id]="selectedTab" role="tabpanel">
          <ng-content></ng-content>
        </div>
      </div>
    </ng-container>
    <!--End Here-->
  </div>


</ng-container>

<!-- 
<style>

.tabset > input[type="radio"] {
  position: absolute;
  left: -200vw;
}

.tabset .tab-panel {
  display: none;
}

.tabset > label {
  position: relative;
  display: inline-block;
  /* padding: 15px 15px 25px; */
  padding: 15px 15px 15px;
  border: 1px solid transparent;
  border-bottom: 0;
  cursor: pointer;
  font-weight: 600;
}

.tabset > label::after {
  content: "";
  position: absolute;
  left: 15px;
  bottom: 10px;
  width: 22px;
  height: 4px;
  /* background: #8d8d8d; */
}

.tabset > label:hover,
.tabset > input:focus + label, 
.tabset > input:checked + label {
  color: #06c;
}


.tabset > input:checked + label {
  /* border-color: #ccc; */
  /* border-bottom: 1px solid #fff; */
  border-bottom: 3px solid #06c;
  margin-bottom: -1px;
  /* box-shadow: 0 3px #06c inset; */
  box-shadow: 0 3px 2px -5px #06c inset;
}

.tab-panel {
  padding: 30px 0;
  border-top: 1px solid #ccc;
}

</style> -->