import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FieldTemplate, FormProperty ,GridConst} from 'src/modules/shared/_classes/utility.interface';
import { AppSharedService, AutoUnsubscribe, isDate, isNullOrUndefined } from '../../shared/app.sharedservice';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ComponentCodes, ComponentConfig, ProcessStatus,RoleCodes } from '../../app.interface';

import { CommonAPIService } from '../../shared/app.commonservice';
import { ExcelExportComponent } from 'src/modules/excelexport/excelexport.component';
import { asEnumerable } from 'linq-es2015';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { GridComponent } from 'src/modules/grid/grid.component';
import { AdminService } from '../admin.service';
import { ModalComponent } from 'src/modules/modal/modal.component';
import { ModalService } from 'src/modules/modal/modal.service';
import { UserRoleDetail } from './cr-number.interface';
import { HomeService } from 'src/app/home/home.service';

@Component({
  selector: 'app-cr-number',
  templateUrl: './cr-number.component.html',
  // styleUrls: ['./cr-number.component.css']
})

@AutoUnsubscribe

export class CrNumberComponent implements OnInit {

  @ViewChild('infoform', { static: false }) infoform!: ModalComponent;

  public FilterData: any = {};

  public GlobalConfig: any = {};
  public AccountTemplate: FieldTemplate[] = [];
  public AdminSearchTemplate: FieldTemplate[] = [];
  public reqListData: any[] = [];
  public reqListDataALL: any[] = [];
  public dataTotalCount: number = 0;
  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };

  public FormProp: FormProperty = {
    'ColSize': 2,
    'CaptionBold': false,
    'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };

  public roleListTemplate: FieldTemplate[] = [];
  public SearchTemplate: FieldTemplate[] = [];
  public SearchButtons: any[] = [];
  public roleListData: any[] = [];
  public roleListDataALL: any[] = [];
  public rolePermissions: any = [];
  public DataInput: any = { PageIndex: 0, PageSize: 0 };
  public tempparamters:any;
  public flag:boolean=false;
  public IsServerPagination: boolean = false;

  //public DataInput: object = { PageIndex: 0, PageSize: 0 };
  public selectedInfoData: any;
  public curItemsPerPage: number = 10;
  public editUserRoleData = new UserRoleDetail();
  public SelectedTab: string = "UserRoleList";
  public Title: string = "";
  public reqListTemplate: FieldTemplate[] = [];
  public reqCRNNumberTemplate: FieldTemplate[] = [];

  public searchEvent: any = { 'item': {}, 'action': 'Clear' };
  public GridProp: any = {
    Editable: true,
    //TableCSS: 'auto',
    PaginationConfig: {
      ItemsPerPage: 10
    },
    ActionBarConfig: {
    ActionBarList: null
    }
  }
  public Config: any = {
    ActionButtons: [],
    ActionButtonsBottom: []
  };

  public IsLoading: boolean = false;
  public subscriptionList$: Subscription[] = [];
  public displayNotifybox: boolean = false;
  public notify: NotificationData = new NotificationData();
  public curFiltCondn: string = '';
  public DispFilter: boolean = false;
    // public DispLoader: boolean = false;

  public ExcelFileName: string = '';
 
  // public ExcelData: any = [];

  @ViewChild('excelexport', { static: false }) excelexport!: ExcelExportComponent;
  @ViewChild('overallgrid', { static: false }) overallgrid!: GridComponent;

  constructor(private _route: ActivatedRoute, private _router: Router, private _appSharedService: AppSharedService,
    private _commonAPIService: CommonAPIService, private adminService: AdminService, private homeService: HomeService,private modalService: ModalService) {

    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);

  }

  ngOnInit() {

    if (!isNullOrUndefined(this._appSharedService.currentRoleData)) {
      this.Config.ActionButtons = JSON.parse(`[{"Name":"ExportToExcel","Icon":"fa fa-file-excel-o","DisplayName":"Dow<u>n</u>load Excel","AccessKey":"N","IsApplicable":true,"HasPermission":true,"IsEnabled":true,"Confirmation":{"IsEnabled":false,"Message":null,"Action":null},"SeqOrder":1},{"Name":"Filter","Icon":"fa fa-filter","DisplayName":"F<u>i</u>lter","AccessKey":"I","IsApplicable":true,"HasPermission":true,"IsEnabled":true,"Confirmation":{"IsEnabled":false,"Message":null,"Action":null},"SeqOrder":2}]`);
      this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;
      this.getRoleTemplate();
    }
    
    this.SearchButtons = JSON.parse(`[{"Name":"Search","CustomClass":"rounded-pill btnCustom btnPrimary small","DisplayName":"Search","AccessKey":"N","ShowArrow":true,"IsApplicable":true,"HasPermission":true,"IsEnabled":true,"Confirmation":{"IsEnabled":false,"Message":null,"Action":null},"SeqOrder":1},{"Name":"Clear","CustomClass":"rounded-pill btnCustom btnNegative small","DisplayName":"Clear","AccessKey":"S","ShowArrow":false,"IsApplicable":true,"HasPermission":true,"IsEnabled":true,"Confirmation":{"IsEnabled":false,"Message":null,"Action":null},"SeqOrder":2}]`);

  }
 
  getRoleTemplate(): void {
    this.IsLoading = true;
    this.AdminSearchTemplate = [];
    this.reqListTemplate = [];
    this.reqCRNNumberTemplate = [];

    this.searchEvent = { 'item': {}, 'action': 'Clear' };
    this.roleListTemplate = [];

    let odata: ComponentConfig = {
      Component: 'SearchBy', ComponentCode: ComponentCodes.SearchComponent
    };

    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {
        let template = res.Data.FieldTemplate!;

         this.roleListTemplate = res.Data.FieldTemplate!;

        this.setCompProperties(res.Data.Properties!);

         
         
        this.rolePermissions = res.Data.Permission;
        this.AccountTemplate = this.roleListTemplate.filter(x => x.Group === 'Account_UserRole');
        this.AdminSearchTemplate = this.roleListTemplate.filter(x => x.Group === 'SearchFilter');
        this.reqListTemplate = template.filter(x => x.Group === 'SearchResult');
        const allowedGroups = ['CandidateId','CandidateName', 'EmployeeNumber','RequestId', 'BGVType','ClientReferenceNumber','Action'];
        this.reqCRNNumberTemplate = this.reqListTemplate.filter(x => allowedGroups.includes(x.FieldName));
        this.UserRoleData();
        this.SearchData(this.searchEvent, true, false);

      }
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getRoleTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }
  
  setCompProperties(Properties: any[]) {
    if (!isNullOrUndefined(Properties) && Properties.length > 0) {
      // 1) ActionButtons field
      this.Config.ActionButtons = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase())[0].JSONData.toString()) : []);

      this.Config.ActionButtonsBottom = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtonsBottom'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtonsBottom'.toLowerCase())[0].JSONData.toString()) : []);

      this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;

      // Other component specific props
      Properties.forEach(prop => {
        // avoiding generic props
        if (!isNullOrUndefined(prop.Name) &&
          prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
          && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
          this.Config[prop.Name] = JSON.parse(prop.JSONData.toString());
        }
      });
 // 3) Pagination props
      this.GridProp.PaginationConfig = (Properties
        .filter((x: any) => x.Name.toLowerCase() === 'PaginationConfig'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'PaginationConfig'.toLowerCase())[0].JSONData) : this.GridProp.PaginationConfig);

      if (!isNullOrUndefined(this.GridProp.PaginationConfig) &&
        !isNullOrUndefined(this.GridProp.PaginationConfig.ServerLoad) &&
        this.GridProp.PaginationConfig.ServerLoad) {

        this.IsServerPagination = true;

        this.DataInput['PageSize'] = isNullOrUndefined(this.GridProp.PaginationConfig.ItemsPerPage) ?
          GridConst.DEFAULTITEMSPERPAGE : this.GridProp.PaginationConfig.ItemsPerPage;
      }
    }
  }

  setActionButtonPermissions(): void {
    if (!isNullOrUndefined(this.Config.ActionButtonsBottom) && this.Config.ActionButtonsBottom.length > 0) {

      this.Config.ActionButtonsBottom.forEach((item: any) => {
        if (!isNullOrUndefined(item.Name) && item.Name !== '') {
          item.HasPermission = false;
          //item.IsApplicable = false;

          let peritem = this.rolePermissions.indexOf(item.Name);
          if (peritem >= 0) {
            item.HasPermission = true;
          }
        }
      });
    }
  }

  UserRoleData(): void {

    this.IsLoading = true;

    this.roleListData = [];
    this.roleListDataALL = [];
    this.dataTotalCount = 0;

    let subscribe$: Subscription = this.adminService.getUserRoleDetails().subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        this.roleListData = result.Data;
        console.log(this.roleListData);
        this.dataTotalCount = this.roleListData.length;

        this.roleListDataALL = this.roleListData;

        this.roleListData.forEach((item) => {
          item.Action = item.IsActivate == 0 ? '0,1' : "3";
          item.IsEditable = 1;
        });

        this.setActionButtonPermissions();

        setTimeout(() => {
          if (!isNullOrUndefined(this.overallgrid)) {
            this.overallgrid.Pagination.CurrentPage = 1;
          }
        }, 50);

      }
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
        //this._toastr.warning(result.Message, this.CompTitle);
      }

      this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);
  }

  

  SaveUserRoleEdit(): void{

       this.IsLoading = true;

      let subscribe$: Subscription = this.adminService.updateCrnNumberDetails(this.editUserRoleData).subscribe(result => {
        
        if (!isNullOrUndefined(result)) {

          this.showNotificationMessage(result.Message, 1, '', null, '', '');

        }
        this.IsLoading=false;
     },
        err => {
          this.IsLoading = false;
        });

      this.subscriptionList$.push(subscribe$);
  

    }
  

  
  ReloadUserRoleData(): void {
    this.Title = "User Role Details";
    this.SelectedTab = "UserRoleList";
    this.getRoleTemplate();
  }

  pageItemsChange() {

    if (this.curItemsPerPage > 0) {
      this.GridProp.PaginationConfig.ItemsPerPage = this.curItemsPerPage;
    }
    else {
      this.GridProp.PaginationConfig.ItemsPerPage = 100;
    }
    this.DataInput['PageIndex'] = 0;
    this.DataInput['PageSize'] = this.GridProp.PaginationConfig.ItemsPerPage;
    // reload grid data
    if (isNullOrUndefined(this.searchEvent.item.Value) || this.searchEvent.item.Value.length == 0) {
      this.SearchData(this.searchEvent, true, false);
    }
    else {
      this.SearchData(this.searchEvent, false, true);
    }
  }

  OnCancel() {
    
          if (this.editUserRoleData.IsModified == 1) {
            this.showNotificationMessage('Are you sure you want to cancel un-saved data?',
              2, 'CancelUserRoleEdit', null, 'Yes', 'No');
          }
          else {
            this.ReloadUserRoleData();
          }
          
  }

  SAVEUSERROLE():void{
    this.SaveUserRoleEdit();
  }

  onClick(event: any): void {
    let selitem = event.item;
    this.editUserRoleData = selitem;
 
           // if (selitem.EmployeeNumber > 0) {
              const templateFields = this.reqCRNNumberTemplate.filter((x: any) => x.Group === "SearchResult");
            
              if(!isNullOrUndefined(templateFields))
              {
                templateFields.forEach((x: any) => {
                  // Set IsHidden to true for fields other than CandidateName and ClientReferenceNumber
                  if (x.FieldName !== "CandidateName" && x.FieldName !== "ClientReferenceNumber") {
                    x.IsHidden = true;
                  }
              
                  // Set Editable property based on the field
                  if (x.FieldName === "CandidateName") {
                    x.Editable = false;
                  } else if (x.FieldName === "ClientReferenceNumber") {
                    x.Editable = true;
                    x.Control.Type="textbox";
                    x.Control.InputType="text";
                  }
                });
              }

            
            this.SelectedTab = "UserRoleEdit";
          
  }

  onChange(event: any): void {
    let selitem: any = event.item;
    event.item.IsModified = 1; 
    
  }

  actButtonClick(event: any) {
    switch (event.buttonname.toUpperCase()) {
      case 'EXPORTTOEXCEL':
        // this.exportToExcel();
        break;
      case 'FILTER':
        this.showFilter();
        break;

    }
  }
  
  SearchData(event: any, allPendingFlag: boolean, searchClicked: boolean): void {
    // console.log(event)

    
    if (searchClicked == true && !isNullOrUndefined(event.action) && event.action.toUpperCase() === 'SEARCH' &&
        (isNullOrUndefined(event.item.Select) || event.item.Select.length == 0 ) && isNullOrUndefined(event.item.FromDate)
        &&isNullOrUndefined(event.item.ToDate)) {

      //  alert("Please select both to proceed search")
    
      this.notify = {
        info: {
          header: 'Search Alert',
          message: 'Please select a column and value to proceed with Search.',
          yes: '',
          no: '',

          IsHeaderHidden: true,
          HeaderIcon: 'fa fa-exclamation-circle text-warning',
          IsCrossEnable: true,
          SizeClass: 'modal-sm'
        },

        action: '',
        item: null
      };

      this.displayNotifybox = true;

      return;
    }

    else if (searchClicked == true && ( isNullOrUndefined(event.item.Value) || event.item.Value.trim().length < 3)&& isNullOrUndefined(event.item.FromDate)
    &&isNullOrUndefined(event.item.ToDate)) {

      //  alert("Please select both to proceed search")

      this.notify = {
        info: {
          header: 'Search Alert',
          message: 'Please enter atleast 3 characters to search',
          yes: '',
          no: '',

          IsHeaderHidden: true,
          HeaderIcon: 'fa fa-exclamation-circle text-warning',
          IsCrossEnable: true,
          SizeClass: 'modal-sm'
        },

        action: '',
        item: null
      };

      this.displayNotifybox = true;

      return;
    }


    this.searchEvent = event;

    // get data from DB
    this.DataInput['PageIndex'] = 0;

   let eResult= event.item;
   this.getData(event, allPendingFlag);
    

  }
  getData(event: any, allPendingFlag: boolean, bDownload?: boolean): void {
    bDownload = (isNullOrUndefined(bDownload) ? false : bDownload);
    this.IsLoading = true;

    this.reqListData = [];
    this.reqListDataALL = [];
    this.dataTotalCount = 0;

    let odata = event.item;
    this.tempparamters=event.item;
    
    this.flag=false;
    if (!isNullOrUndefined(event.action) && event.action.toUpperCase() === 'CLEAR') {
      allPendingFlag = true;
      this.flag=true;
      odata = {};
    }
    
    odata['PageIndex'] = this.DataInput['PageIndex'];
    odata['PageSize'] = this.DataInput['PageSize'];
    odata['Frompage'] = "CRNNumber";

    let subscribe$: Subscription = this.homeService.getRequestDetailsBySearch(odata, allPendingFlag).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        if (this.IsServerPagination) {
          this.dataTotalCount = parseInt(result.Message);

          // // // reload all data irrespective of pagination]
          // // if (!isNullOrUndefined(bReload) && bReload) {
          // //   this.reqListData = [];
          // //   this.reqListDataALL = [];
          // // }

          // // Since pagewise data is fetched, data has to be appended
          // result.Data.forEach((x: any) => this.reqListData.push(x));
          // console.log(JSON.stringify(this.reqListData));

          this.reqListData = result.Data;
          // console.log(this.reqListData);

          this.reqListDataALL = this.reqListData;

        }
        else {


          this.reqListData = result.Data;
          console.log(this.reqListData);
          this.dataTotalCount = this.reqListData.length;

          this.reqListDataALL = this.reqListData;
        }

        // after data load
        this.reqListData.forEach((item) => {
          // item.IsInitiate = 0;
          item.Action = item.IsActivate == 0 ? '0,1' : "3";
         item.IsEditable=1;

        });

        // this._toastr.success('Request stopped successfully', this.CompTitle);

        setTimeout(() => {
          if (!isNullOrUndefined(this.overallgrid)) {
            if (this.IsServerPagination) {
              this.overallgrid.Pagination.CurrentPage = parseInt(this.DataInput['PageIndex']) + 1;
            }
            else {
              this.overallgrid.Pagination.CurrentPage = 1;
            }
          }
        }, 120);

      }
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
        //this._toastr.warning(result.Message, this.CompTitle);
      }

      this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);

  }



 
  
  showFilter(event?: any): void {
    this.DispFilter = !this.DispFilter;

    if (!isNullOrUndefined(event)) {
      this.curFiltCondn = event.curFiltCondn;
    }
  }

  setFilter(event: any) {
    if (event.sCondn.length > 0) {
      this.roleListData = asEnumerable(this.roleListDataALL)
        .Where($ => (eval(event.sCondn)))
        .ToArray();
      this.dataTotalCount = this.roleListData.length;
    }
    else {
      this.roleListData = this.roleListDataALL;
      this.dataTotalCount = this.roleListData.length;
    }
  }

  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item
    };

    this.displayNotifybox = true;
  }
  
  closeNotifybox(): void {
    this.displayNotifybox = false;
    this.ReloadUserRoleData();

  }

  
}


