import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ElioScopeService {
  private BaseURL!: string;
  private BaseURL$: Observable<string>;
  private BaseURLObserver: any;

  constructor() {
    this.BaseURL$ = new Observable(
      observer => (this.BaseURLObserver = observer)
    );
  }

  public setBaseURL(value: any) {
    this.BaseURL = value;
    if (this.BaseURL$) {
      this.BaseURL$.subscribe(value => (this.BaseURL = value));
    }
  }

  public getBaseURL(): string {
    return this.BaseURL;
  }
}
