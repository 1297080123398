import { isNull } from "@angular/compiler/src/output/output_ast";

export class AccChkDocDetail {
    AccountId: number = 0;
    AccountName: string = '';
    SPOCEmailID: string = '';
    DLEmailID: string = '';
    SpecificVendorId: string = "0";
    Description: string = '';
    MSADocumentLink: string = '';
    PeoplePartner: string = '';
    IsVisible: boolean = true;

    DocumentTypeId: number = 0;
    DocumentTypeName: string = '';
    FileFormats: string = '';
    MaxFileSize?: string = "";
    WarningMessage: string = '';
    userdef1: string = '0';
    userdef2: string = '';
    userdef3: string = '';
    userdef4: string = '';
    userdef5: string = '';
    userdef6: string = '';
    userdef7: string = '';

    SubDocumentTypeId: number = 0;
    SubDocumentTypeName: string = '';

    CheckId: number = 0;
    CheckName: string = '';
    ExpectedTurnAroundTime: string = "";
    ReferenceDocumentLink: string = "";
    
    TransactionId: number = 0;
    ValidFromDate?:Date = new Date('2021-10-26 00:00:00.000');
    ValidTillDate?:Date = new Date('2099-12-31 00:00:00.000');

    IsEditable: number = 1;
    IsModified: number = 0;

    constructor(init?: Partial<AccChkDocDetail>) {
        Object.assign(this, init);
    }
}
