export const environment = {
  production: true,
  environmentName: 'Prod-Internal',

  AppID: 'APP01',

  AppInstance: 'INTERNAL', // 'INTERNAL', 'EXTERNAL'

  AppBaseURL: 'https://bgv.capgemini.com/',

  authURL: 'https://bgv.capgemini.com',

  baseURL: 'https://bgv.capgemini.com',

  refURL: '',
  eventURL: '',
  hubBaseURL: '',

  apiURL: {
    IdentityURL: 'https://bgv.capgemini.com/IdentityService/Auth/',
    LoginURL: 'https://bgv.capgemini.com/LoginService/Login/',

    UIConfigURL: 'https://bgv.capgemini.com/UIConfigService/UIConfig/',

    RequestURL: 'https://bgv.capgemini.com/RequestService/RequestDetails/',
    CheckURL: 'https://bgv.capgemini.com/CheckService/CheckDocType/',
    CandiateURL: 'https://bgv.capgemini.com/CandidateService/CandidatesDetails/',
    VendorURL: 'https://bgv.capgemini.com/VendorService/VendorDetails/',

    FileManagementURL: 'https://bgv.capgemini.com/FileManagementService/FileManagement/',
    LogURL: 'https://bgv.capgemini.com/LogService/Log/',
    UserURL: 'https://bgv.capgemini.com/UserManagementService/UserManagement/',

    ReportURL: 'https://bgv.capgemini.com/ReportService/Report/',
    MailerURL: 'https://bgv.capgemini.com/MailerService/Mailer/',
    AdminURL: 'https://bgv.capgemini.com/AdminService/Admin/'
  },

  IsParallelEditEnabled: false,
  tokenexpiry: 25,    // in mins
  sessionexpiry: 15,  // in mins
  sessionexpirywarning: 5,   // in mins
  
  SecretKey: 'CapSecretKeyForEncryption&Descryption',
  FieldTypeText: 'text',
  FieldTypePass: 'password',
  
};
