<!-- <ng-container *ngIf="token"> -->
<!-- <ng-container *ngIf="true"> -->
<ng-container *ngIf="(this._appSharedService.UserInfo != null && this._appSharedService.UserInfo != undefined 
                        && this._appSharedService.UserInfo.email.length > 0)">

    <!-- Navbar Starts Here -->

    <!-- Header Section -->
    <nav id="topNav" class="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
        <!-- Logo Section -->

        <!-- Brand -->
        <!-- <a class="navbar-brand" routerLink="home"><img [src]="ImagePath" /></a> -->
        <a class="navbar-brand" aria-label="Capgemini Logo Redirect to Home Page" href="javascript:this.blur();" 
            routerLink="home">
            <img src="assets/images/logo.png" alt="Capgemini Logo Redirect to Home Page" />
            
        </a>

        <!-- Toggler/collapsibe Button -->
        <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-target="#navbar-collapse-1"
            aria-controls="navbar-collapse-1" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>


        <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="collapse navbar-collapse" id="navbar-collapse-1">
            
                <!-- Title -->
                <a class="navbar-brand ms-auto" aria-label="Background Verification" href="javascript:this.blur();"
                    routerLink="home"><h1 class="d-inline-block">{{ 'Background Verification' | translate }}</h1></a>

            <!-- Links -->
            <!--  -->
            <ul class="navbar-nav ms-auto">
                <!-- <li class="nav-item dropdown" role="button" *ngIf="false" -->
                <li class="nav-item dropdown" *ngIf="_appSharedService.UserRoleList.length > 1">

                    <a class="nav-link dropdown-toggle" [attr.aria-label]="'Switch Role [' + (_appSharedService?.currentRoleData?.RoleName) + ']'" href="javascript:this.blur();" data-bs-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false" id="{{fieldRandomId}}-{{'Switch Role [' + (_appSharedService.currentRoleData.RoleName) + ']'}}" role="button">
                        {{'Switch Role [' + (_appSharedService.currentRoleData.RoleName) + ']'}}
                        <!-- <i class="fa fa-angle-down" aria-hidden="true"></i> -->
                    </a>

                    <ul class="dropdown-menu">
                        <ng-container *ngFor="let roleData of _appSharedService.UserRoleList; let i = index">
                            <li>
                                <a  id="{{fieldRandomId}}-{{roleData.RoleName}}"  [attr.aria-label]="roleData?.RoleName" href="javascript:this.blur();" class="dropdown-item" (click)="roleChange(roleData)"
                                    [ngClass]="[(_appSharedService.currentRoleData.RoleName == roleData.RoleName) ?  'active': '' ]">{{roleData.RoleName}}</a>
                            </li>
                        </ng-container>
                    </ul>
                </li>
            </ul>

            <!-- User Profile -->
            <ul class="navbar-nav userProfile"
                [ngClass]="[(_appSharedService.UserRoleList.length <=1) ?  'ms-auto': '' ]">
                <li class="nav-item dropdown">
                    <h2 class="sr-only">User Navigation</h2>
                    <ng-container *ngIf="(userAccessInfo != null && userAccessInfo != undefined 
                            && (userAccessInfo.email == userAccessInfo.actingemail))">
                        <a  id="{{fieldRandomId}}-userProfile" class="nav-link dropdown-toggle"  aria-label="User Info" href="javascript:this.blur();"
                            role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            title="{{userinfo['displayName'] == undefined? '': userinfo['displayName']}}">
                            <!-- <img alt="User Avatar" src="http://home.intra.aricent.com/appcenter/_layouts/15/userphoto.aspx?size=S&ts=282019914&accountname={{userinfo['email']}}"
                        class="img-avatar-thumb img-avatar img-avatar32" /> -->

                            <img alt="User Avatar" src="assets/images/npphoto.png"
                                class="img-avatar-thumb img-avatar img-avatar32 align-self-center" />
                            <!-- <span>ADMIN</span> -->
                            <span>{{userinfo['displayName'] == undefined? '': userinfo['displayName']}}</span>
                        </a>
                    </ng-container>


                    <ng-container *ngIf="(userAccessInfo != null && userAccessInfo != undefined 
                            && (userAccessInfo.email != userAccessInfo.actingemail))">
                        <a id="{{fieldRandomId}}-User Access Info" class="nav-link dropdown-toggle" aria-label="User Access Info Link" href="javascript:this.blur();"
                            role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            title="{{userAccessInfo['name'] == undefined? '': userAccessInfo['name']}}">

                            <img alt="User Avatar" src="assets/images/npphoto.png"
                                class="img-avatar-thumb img-avatar img-avatar32 align-self-center" />
                            <span>{{userAccessInfo['name'] == undefined? '': userAccessInfo['name']}}</span>
                            <!-- <span>{{ ' [Proxy: ' + userAccessInfo['name'] + ']' }}</span> -->
                        </a>
                    </ng-container>

                    <ul class="dropdown-menu">
                        <ng-container *ngIf="(userAccessInfo != null && userAccessInfo != undefined 
                                        && userAccessInfo.email.length > 0)">

                            <li *ngIf="userAccessInfo['canswitchuser']=='True'">
                                <label for="Switch User Dialog" class="sr-only">Switch User Dialog</label>
                                <a aria-label="Switch User Dialog" href="javascript:this.blur();" class="dropdown-item" 
                                        (click)="openSwitchUser()">{{'Switch User' | translate}}</a>
                            </li>
                        </ng-container>

                        <!-- <li>
                            <a class="dropdown-item" (click)="logout()" style="color: white;">{{'Logout' | translate}}</a>
                        </li> -->
                    </ul>
                </li>
            </ul>
        </div>
        <!-- /.navbar-collapse -->

    </nav>
    <!-- Navbar Ends Here -->


    <!--  Main Container Starts Here -->
    <div id="bodyContainer" class="bodyContainer">

        <!-- Nav tabs -->
        <ng-container *ngIf="(menuList != null && menuList != undefined && menuList.length > 0)">
            <menubar id="tabMain" [menuList]="(menuList | filterBy : {HasPermission :true})"
                (menuClick)="menuTabClick($event)"></menubar>
        </ng-container>

        <!-- Tab panes -->
        <div class="tab-content">
            <div class="tab-pane fade active show">
                <div class="h-100">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>

        <ng-container *ngIf="showNoDataMessage">
            <div class="position-absolute top-50 start-50 translate-middle">
                <h5>Your login is not mapped to any role and hence no data is displayed.</h5>
            </div>
        </ng-container>

        <!-- <ng-container *ngIf="showNoRequestMessage">
            <div class="position-absolute top-50 start-50 translate-middle">
                <h5>You do not have any request to act upon and hence no data is displayed.</h5>
            </div>
        </ng-container> -->

    </div>
    <!--  Main Container Ends Here -->

</ng-container>

<!-- SwitchUser section -->
<div *ngIf="displaySwitchUser">
    <app-switchuser (close)="closeSwitchUser($event)"></app-switchuser>
</div>

<!-- Session Expiry section -->
<div *ngIf="displaySessionExp">
    <session-modal [countMinutes]="session['countMinutes']" [countSeconds]="session['countSeconds']"
        [progressCount]="session['progressCount']" [maxCount]="session['maxCount']" (close)="closeSessionPopup($event)">
    </session-modal>
</div>

<!-- Loader for entire app -->
<div>
    <loading [IsLoading]="AppIsLoading" [IsFullScreen]="true"></loading>
</div>

<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
        (close)="closeNotifybox() "></notification>
</div>