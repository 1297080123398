<div class="p-2 contentScroll simpleCard searchCaseOverflow">
    <!--Start From Here -->
    <!-- <ng-container *ngIf="(checkDocumentDetailsList != null && checkDocumentDetailsList != undefined && 
                        checkDocumentDetailsList.length > 0)"> -->

    <ng-container *ngIf="(checkDocumentDetailsList != null && checkDocumentDetailsList != undefined &&
                        checkDocumentDetailsList.length > 0 && checkTemplate != null && checkTemplate != undefined
                        && checkTemplate.length > 0)">

        <div class="card m-0">
            <div class="card-header">
                <h3>
                    <div>
                        <div class="d-flex justify-content-between">
                            <span>{{checkDocumentDetailsList[0].CheckName}}</span>



                            <ng-container
                                *ngIf="!displayAddMoreSection   && checkStatus != null && checkStatus != '' && checkStatus != undefined ">
                                <div data-bs-toggle="tooltip" data-bs-placement="right" [title]="checkStatus">
                                    <!-- [title]="checkDocumentDetailsList[0].CheckStatus" -->
                                    <span [ngClass]="(IsApplicableflag == true ? 'badge rounded-pill bg-secondary icon' 
                                    : checkDocumentDetailsList[0].CheckStatusColorCode == 'SC001' ? 'badge rounded-pill bg-success icon'
                                    : checkDocumentDetailsList[0].CheckStatusColorCode == 'SC002' ? 'badge rounded-pill bg-danger icon'
                                    : checkDocumentDetailsList[0].CheckStatusColorCode == 'SC003' ? 'badge rounded-pill bg-warning icon'
                                    : 'badge rounded-pill bg-secondary icon')">
                                        <i [ngClass]="(IsApplicableflag == true ? 'ion ion-ios-alert'
                                        : checkDocumentDetailsList[0].CheckStatusColorCode == 'SC001' ? 'ion ion-ios-checkmark-circle'
                                        : checkDocumentDetailsList[0].CheckStatusColorCode == 'SC002' ? 'ion ion-ios-alert'
                                        : checkDocumentDetailsList[0].CheckStatusColorCode == 'SC003' ? 'ion ion-ios-alert'
                                        : 'ion ion-ios-alert')" aria-hidden="true"></i> <span>
                                            <!-- {{checkDocumentDetailsList[0].CheckStatus}} -->
                                            {{checkStatus | translate}}
                                        </span></span>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="(displayAddMoreSection || displayAddMoreGaps)">

                                <ul class="actionList">
                                    <ng-container *ngIf="displayPermanentAddressSection">
                                        <li>
                                            <div class="cellLink" id="add-sec">
                                                <a class="cellLink" aria-expanded="false" (click)="AddPermanentAddress()">
                                                    <span>Add Permanent Address Section</span>
                                                </a>
                                            </div>
                                        </li>
                                    </ng-container>
                                    <ng-container *ngIf="displayAddMoreSection">
                                        <li>
                                            <div class="cellLink" id="add-sec">
                                                <a class="cellLink" aria-expanded="false" (click)="AddMoreDocument()">
                                                    <span>{{'Add More ' + checkDocumentDetailsList[0].CheckName |
                                                        translate}}</span>
                                                </a>
                                            </div>
                                            <!-- <div class="cellLink" id="add-sec" (click)="Count">11</div>>
                                                <a class="cellLink" aria-expanded="false" (click)="AddMoreCourtRecordExcel()">
                                                    <span>{{'Add More ' + checkDocumentDetailsList[0].CheckName |
                                                        translate}}</span>
                                                </a>
                                            </div> -->
                                        </li>
                                    </ng-container>
                                    
                                    <ng-container *ngIf="displayAddMoreGaps">
                                        <ng-container *ngIf="displayAddMoreSection">|</ng-container>
                                        <li>
                                            <div class="cellLink" id="add-sec">
                                                <a class="cellLink" aria-expanded="false" (click)="AddGapSection()">
                                                    <span>{{'Add ' + checkDocumentDetailsList[0].CheckName + ' Gap ' |
                                                        translate}}</span>
                                                </a>
                                            </div>
                                        </li>
                                    </ng-container>
                                </ul>

                            </ng-container>
                        </div>
                    </div>


                </h3>
            </div>
            <div class="card-body overflow-auto">
                <!-- && RequestData.IsCreateMode -->
                <!-- Validation Message at the time of Employee filling metadata -->
                <ng-container *ngIf="(overallValidationMsgData != null && overallValidationMsgData != undefined && 
                        overallValidationMsgData.length > 0 && (RequestData.IsCreateMode || RequestData.RequestStatus === '10077'
                        || RequestData.RequestStatus === '10078'))">
                    <ng-container *ngFor="let valData of overallValidationMsgData">
                        <ng-container
                            *ngIf="valData.ValidationMessage != null && valData.ValidationMessage != '' && valData.ValidationMessage != undefined">
                            <div class="alert alert-warning" role="alert">{{valData.ValidationMessage | translate}}
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <!-- SubCheck sections -->
                <div class="accordion" [id]="'P_'+CheckId+'_'">
                    <ng-container *ngIf="!IsLoading">
                        <ng-container *ngFor="let chkDocData of (checkDocumentDetailsList | filterBy: {CanShow: true})">

                            <document #documentform [RequestId]="RequestId" [CheckId]="CheckId"
                                [CheckDocData]="chkDocData" [checkList]="checkDocumentDetailsList"
                                [CheckTemplate]="(checkTemplate | filterBy: {Group: 'CHECK_' + chkDocData.DocumentTypeId + '_F'})"
                                [CheckPermission]="checkPermission" [CheckProperties]="checkProperties"
                                [AttachmentTemplate]="(checkTemplate | filterBy: {Group: 'CHECK_' +chkDocData.DocumentTypeId + '_A'})"
                                [AttachmentData]="(attachmentList | filterBy: {DocumentTypeId: chkDocData.DocumentTypeId})"
                                [SubCheckMetaData]="(checkMetaDataList | filterBy: {SubCheckId: chkDocData.DocumentTypeId})"
                                [editCheckTemplate]="(checkTemplate | filterBy: {Group: 'CHECK_' + chkDocData.DocumentTypeId + '_F'})"
                                [DocumentStatusFieldName]="documentStatusFieldName" [RequestData]="RequestData"
                                (raiseInsufficiency)="raiseInsufficiency($event)" (reLoadCheck)="reLoadCheck($event)"
                                (submitenable)="submitenable($event)" (showPreview)="showPreview($event)"
                                (updateInsuff)="updateInsuff($event)">
                            </document>

                            <!-- checkMetaDataList (checkMetaDataList | filterBy: {SubCheckId: chkDocData.DocumentTypeId}) -->

                        </ng-container>
                    </ng-container>
                </div>
                <br>
                <!-- CourtRecordExcelList section and AddressExcel Section -->
                <ng-container
                    *ngIf="(checkProperties.CourtRecordExcelList!=null && checkProperties.CourtRecordExcelList!=undefined 
                            && checkProperties.CourtRecordExcelList.length > 0 && checkTemplate != null && checkTemplate != undefined && checkTemplate.length >0  && displayUploadMoreDocuments)">
                    <ng-container
                        *ngFor="let sectionData of (checkProperties.CourtRecordExcelList | filterBy: {HasPermission :true})">

                        <div class="accordion" [id]="'_'+sectionData.Name">
                            <div class="accordion-item">
                                <h2 class="accordion-header" [id]="sectionData.Name">
                                    <button class="accordion-button collapsed justify-content-between" type="button"
                                        data-bs-toggle="collapse" [attr.data-bs-target]="'#_'+sectionData.Name+'_H'"
                                        aria-expanded="false" [attr.aria-controls]="'_'+sectionData.Name+'_H'">
                                        <div>
                                            {{sectionData.DisplayName | translate}}
                                        </div>
                                    </button>
                                </h2>
                                <!-- <div [id]="'_'+sectionData.Name+'_H'" class="accordion-collapse collapse"
                                    [attr.aria-labelledby]="sectionData.Name"> -->
                                <div [id]="'_'+sectionData.Name+'_H'" class="accordion-collapse collapse"
                                    [attr.aria-labelledby]="'S_'+sectionData.Name+'_'">
                                    <div class="accordion-body">
                                        <div class="pt-4">
                                            <ng-container *ngIf="sectionData.SectionType == 'Grid'">
                                                
                                                <div class="bg-light p-3">
                                                    <p class="text-bold"></p>
                                                    <div  class="form-inline">
                                                        <ng-container>
                                                            <label class="form-label">Upload Address</label>
                                                            <input class="form-control form-control-sm" id="inputfile" type="file" 
                                                            (change)="handleUpload($event)" accept="UploadType"/>
                                                            <label for="inputfile" class="sr-only">UploadType</label>
                                                            <button type="button" class="btnCustom btnPrimary rounded-pill" (click) ="uploadFiles()"
                                                            value="{{'Upload' | translate }}">{{'Upload' | translate }}
                                                            </button>
                                                            <a href="javascript:this.blur();" class="actionList_b" aria-hidden="true" (click)="downloadExcelfile()">
                                                                <span><p style="font-size:10px"></p><u>Download Sample Excel</u></span>
                                                           </a>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <ng-container
                                                    *ngIf="(checkTemplate!=null && checkTemplate!=undefined && checkTemplate.length > 0)">

                                                    <grid-dispctrl #additionalsectiongrid
                                                        [FieldTemplate]="CourtRecordExcelTemplate"
                                                
                                                        [GridProp]="GridProp" [GridData]="excelListData"
                                                        [GridDataTotalCount]="excelListData.length">
                                                        
                                                    </grid-dispctrl>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <!-- </div> -->
                            </div>
                        </div>
                        <!-- </ng-container> -->
                    </ng-container>
                </ng-container>
                                <ng-container
                    *ngIf="(checkProperties.AddressExcelList!=null && checkProperties.AddressExcelList!=undefined 
                            && checkProperties.AddressExcelList.length > 0 && checkTemplate != null && checkTemplate != undefined && checkTemplate.length >0  && displayUploadMoreDocuments_address)">
                    <ng-container
                        *ngFor="let sectionData of (checkProperties.AddressExcelList | filterBy: {HasPermission :true})">

                        <div class="accordion" [id]="'_'+sectionData.Name">
                            <div class="accordion-item">
                                <h2 class="accordion-header" [id]="sectionData.Name">
                                    <button class="accordion-button collapsed justify-content-between" type="button"
                                        data-bs-toggle="collapse" [attr.data-bs-target]="'#_'+sectionData.Name+'_H'"
                                        aria-expanded="false" [attr.aria-controls]="'_'+sectionData.Name+'_H'">
                                        <div>
                                            {{sectionData.DisplayName | translate}}
                                        </div>
                                    </button>
                                </h2>
                                <!-- <div [id]="'_'+sectionData.Name+'_H'" class="accordion-collapse collapse"
                                    [attr.aria-labelledby]="sectionData.Name"> -->
                                <div [id]="'_'+sectionData.Name+'_H'" class="accordion-collapse collapse"
                                    [attr.aria-labelledby]="'S_'+sectionData.Name+'_'">
                                    <div class="accordion-body">
                                        <div class="pt-4">
                                            <ng-container *ngIf="sectionData.SectionType == 'Grid'">
                                                
                                                <div class="bg-light p-3">
                                                    <p class="text-bold"></p>
                                                    <div  class="form-inline">
                                                        <ng-container>
                                                            <label class="form-label">Upload Address</label>
                                                            <input class="form-control form-control-sm" id="inputfile" type="file" 
                                                            (change)="handleUpload($event)" accept="UploadType"/>
                                                            <label for="inputfile" class="sr-only">UploadType</label>
                                                            <button type="button" class="btnCustom btnPrimary rounded-pill" (click) ="uploadAddressFiles()"
                                                            value="{{'Upload' | translate }}">{{'Upload' | translate }}
                                                            </button>
                                                            <a href="javascript:this.blur();" class="actionList_b" aria-hidden="true" (click)="downloadAddressExcelfile()">
                                                                <span><p style="font-size:10px"></p><u>Download Sample Excel</u></span>
                                                           </a>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <ng-container
                                                    *ngIf="(checkTemplate!=null && checkTemplate!=undefined && checkTemplate.length > 0)">

                                                    <grid-dispctrl #additionalsectiongrid
                                                        [FieldTemplate]="AddressExcelTemplate"
                                                
                                                        [GridProp]="GridProp" [GridData]="excelAddressListData"
                                                        [GridDataTotalCount]="excelAddressListData.length">
                                                        
                                                    </grid-dispctrl>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <!-- </div> -->
                            </div>
                        </div>
                        <!-- </ng-container> -->
                    </ng-container>
                </ng-container>
                <br>
                
                <!-- ActivityLog section -->
                <ng-container
                    *ngIf="(checkProperties.ActivityLog!=null && checkProperties.ActivityLog!=undefined 
                            && checkProperties.ActivityLog.length > 0 && checkTemplate != null && checkTemplate != undefined && checkTemplate.length >0)">
                    <ng-container
                        *ngFor="let sectionData of (checkProperties.ActivityLog | filterBy: {HasPermission :true})">

                        <div class="accordion" [id]="'_'+sectionData.Name">
                            <div class="accordion-item">
                                <h2 class="accordion-header" [id]="sectionData.Name">
                                    <button class="accordion-button collapsed justify-content-between" type="button"
                                        data-bs-toggle="collapse" [attr.data-bs-target]="'#_'+sectionData.Name+'_H'"
                                        aria-expanded="false" [attr.aria-controls]="'_'+sectionData.Name+'_H'">
                                        <div>
                                            {{sectionData.DisplayName | translate}}
                                        </div>
                                    </button>
                                </h2>
                                <!-- <div [id]="'_'+sectionData.Name+'_H'" class="accordion-collapse collapse"
                                    [attr.aria-labelledby]="sectionData.Name"> -->
                                <div [id]="'_'+sectionData.Name+'_H'" class="accordion-collapse collapse"
                                    [attr.aria-labelledby]="'S_'+sectionData.Name+'_'">
                                    <div class="accordion-body">
                                        <div class="pt-4">
                                            <ng-container *ngIf="sectionData.SectionType == 'Grid'">
                                                <ng-container
                                                    *ngIf="(checkTemplate!=null && checkTemplate!=undefined && checkTemplate.length > 0
                                                        && (checkTemplate | filterBy: {Group:(sectionData.Group+'_'+'ActivityLog')}).length > 0)">

                                                    <grid-dispctrl #GRID_{{sectionData.Name}}
                                                        [FieldTemplate]="checkTemplate | filterBy: {Group: (sectionData.Group+'_'+'ActivityLog')}"
                                                        [GridProp]="GridProp" [GridData]="additionalSecLogData"
                                                        [GridDataTotalCount]="additionalSecLogData.length">
                                                    </grid-dispctrl>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <!-- </div> -->
                            </div>
                        </div>
                        <!-- </ng-container> -->
                    </ng-container>
                </ng-container>
                <!--Update and Edit Checks-->

            </div>
        </div>
    </ng-container>

    <!--Additional Sections-->
    <ng-container
        *ngIf="(additionalSecFormData != null && additionalSecFormData != undefined && additionalSecFormData.length >0)">
        <!-- <ng-container *ngFor="let sectionData of (addtionalSectionList | filterBy: {CanShow: true} | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'})"> -->
        <!-- <ng-container *ngIf="sectionData.CanShow== true"> -->
        <div class="card m-0 mt-3">
            <div class="card-body overflow-auto">
                <div class="accordion" [id]="'_gapsectionData'">
                    <div class="accordion-item">
                        <h2 class="accordion-header" [id]="'gapsectionData'">
                            <button class="accordion-button collapsed justify-content-between" type="button"
                                data-bs-toggle="collapse" [attr.data-bs-target]="'#_gapsectionData_H'"
                                aria-expanded="false" [attr.aria-controls]="'_gapsectionData_H'">
                                <div>
                                    {{checkDocumentDetailsList[0].CheckName + ' Gap ' |
                                    translate}}
                                </div>
                            </button>
                        </h2>

                        <!-- <div [id]="'_'+sectionData.Name+'_H'" class="accordion-collapse collapse"
                                        [attr.aria-labelledby]="sectionData.Name"> -->
                        <div [id]="'_gapsectionData_H'" class="accordion-collapse collapse"
                            [attr.aria-labelledby]="'S_gapsectionData_'">
                            <br />
                            <div class="accordion-body">
                                <!-- <div class="pt-4"> -->
                                <ng-container *ngFor="let data of additionalSecFormData">
                                    <div class="row small-gutter form-inline">

                                        <div class="d-flex justify-content-between">
                                            <p class="d-flex text-bold">{{data.GAPName |
                                                translate}}</p>
                                            <ul class="actionList" *ngIf="displayAddMoreGaps">
                                                <li (click)="showConfirmationforGapDelete(data)">
                                                    <a href="javascript:;">
                                                        <i class="ion ion-md-trash" aria-hidden="true"></i> Delete
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <!-- <a lass="actionList px-3" href="javascript:;">
                                            <i class="ion ion-md-trash" aria-hidden="true"></i> Delete
                                        </a> -->
                                        <br />
                                        <br />
                                        <hr class="dividerThin">

                                        <ng-container
                                            *ngIf="(gapTemplate!=null && gapTemplate!=undefined && gapTemplate.length > 0)">
                                            <customform #SEC_gapsectionData [FormTemplate]="gapTemplate"
                                                [FormData]="data" [FormProp]="AdditionalSecProp"
                                                [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                                                (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                            </customform>

                                        </ng-container>

                                    </div>
                                </ng-container>
                                <!-- </div> -->

                            </div>
                        </div>
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- </ng-container> -->
        <!-- </ng-container> -->
    </ng-container>


</div>

<div class="filters buttonHolder">
    <div class="d-flex justify-content-between ">
        <div></div>
        <div>
            <ng-container *ngIf="!IsReadonly">
                <ng-container *ngIf="!hidePrevNextButton">
                    <button class="btnCustom btnNegative rounded-pill" value="{{'Previous' | translate }}"
                        (click)="nextandprevClick(-1)" [disabled]="disbalePrev">
                        <i class="ion ion-md-arrow-back" aria-hidden="true"></i><span>Previous</span>
                    </button>
                    <button class="btnCustom btnNegative rounded-pill" value="{{'Next' | translate }}"
                        (click)="nextandprevClick(1)" [disabled]="disableNext">
                        <span>Next</span><i class="ion ion-md-arrow-forward" aria-hidden="true"></i>
                    </button>
                </ng-container>
                <ng-container *ngIf="hidePrevNextButton">
                    <button class="btnCustom btnNegative rounded-pill" value="{{'Back to dashboard' | translate }}"
                        (click)="clickbacktodashboard()">
                        <i class="ion ion-md-arrow-back" aria-hidden="true"></i><span>Back to dashboard</span>
                    </button>
                </ng-container>
                <!-- <button type="button" class="btnCustom btnSecondary rounded-pill" (click)="saveCheck(false,0)"
                        value="{{'Save' | translate }}">{{'Save' |
                        translate
                        }}
                        <span class="ps-1"><i class="ion ion-md-arrow-forward" aria-hidden="true"></i></span>
                    </button>
                    <button type="button" class="btnCustom btnPrimary rounded-pill"
                        (click)="showUpdateCaseConfirmation()" value="{{'Update Case' | translate }}"
                        [disabled]="disableUpdateCase">{{'Update Case' |
                        translate
                        }}
                        <span class="ps-1"><i class="ion ion-md-arrow-forward" aria-hidden="true"></i></span>
                    </button> -->
                <ng-container
                    *ngIf="!RequestData.IsClosed && checkProperties.ActionButtons != null && checkProperties.ActionButtons != undefined && !IsReadonly">

                    <ng-container *ngFor="let actionBar of (checkProperties.ActionButtons | filterBy: {IsApplicable: true,HasPermission :true,Group:'Check'}
                                | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">

                        <button type="button" [ngClass]="actionBar.CustomClass" (click)="actionButtonClick(actionBar)"
                            [disabled]="!actionBar.IsEnabled"
                            value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName |
                            translate
                            }}
                            <span *ngIf="actionBar.ShowArrow"><i class="ion ion-md-arrow-forward"
                                    aria-hidden="true"></i></span>
                        </button>

                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
<!--End Here -->
<ng-container *ngIf="(checkDocumentDetailsList != null && checkDocumentDetailsList != undefined &&
                        checkDocumentDetailsList.length > 0)">
    <modal-popup id="AddMore" style="display: none;"
        [modaldetail]="{'header':'Add More ' + checkDocumentDetailsList[0].CheckName}">
        <ng-container *ngIf="(AddMoreTemplate !=null && AddMoreTemplate !=undefined && AddMoreTemplate.length>0)">
            <customform #addmore [FormTemplate]="AddMoreTemplate" [FormData]="AddFormData" [FormProp]="AddFormProp"
                [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)"
                (FieldChange)="onChange($event)">
            </customform>
        </ng-container>

        <div class="">
            <div class="d-flex justify-content-end p-3">
                <button type="button" class="rounded-pill btnCustom btnPrimary" (click)="insertDocumentType()"
                    value="{{'Add' | translate }}">
                    {{'Add' | translate }}
                </button>
            </div>
        </div>
        
        <!-- Notification section -->
        <div *ngIf="displayAddMoreNotifybox">
            <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
                (selected)="setNotifyRes($event) " (close)="closeAddMoreNotifybox() "></notification>
        </div>

    </modal-popup>
</ng-container>


<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>
<!-- Excel Download
<excel-export #excelexport [FileName]="ExcelFileName"></excel-export> -->
<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
        (selected)="setNotifyRes($event) " (close)="closeNotifybox() "></notification>
</div>

<!-- <ng-container *ngIf="(ValidationMessageTemplate != null && ValidationMessageTemplate != undefined &&
ValidationMessageTemplate.length > 0)"> -->
<modal-popup id="ValidationPopup" style="display: none;" [modaldetail]="{'header':'Alert'}">
    <!-- <grid-dispctrl [FieldTemplate]="ValidationMessageTemplate" [GridProp]="GridProp"
            [GridData]="ValidationMessageData" [GridDataTotalCount]="ValidationMessageData.length">
        </grid-dispctrl> -->
    <ng-container *ngIf="(ValidationMessageData != null && ValidationMessageData != undefined &&
        ValidationMessageData.length > 0)">
        <ng-container *ngFor="let valData of ValidationMessageData">
            <ul>
                <li>{{valData.ResultMessage | translate}}</li>
            </ul>
        </ng-container>
    </ng-container>
</modal-popup>
<!-- </ng-container> -->

<modal-popup id="saveValidationPopup" style="display: none;" [modaldetail]="{'header':'Alert'}">
    <!-- <grid-dispctrl [FieldTemplate]="ValidationMessageTemplate" [GridProp]="GridProp"
            [GridData]="ValidationMessageData" [GridDataTotalCount]="ValidationMessageData.length">
        </grid-dispctrl> -->
    <ng-container *ngIf="(SaveValidationMessageData != null && SaveValidationMessageData != undefined &&
    SaveValidationMessageData.length > 0)">
        <ol class="list-group list-group-numbered">
            <ng-container *ngFor="let valData of SaveValidationMessageData">
                <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">{{valData.docName | translate}}</div>
                        {{valData.valColumns | translate}}
                    </div>
                </li>
            </ng-container>
        </ol>
    </ng-container>
</modal-popup>

<modal-popup id="VendorAssign" style="display: none;" [modaldetail]="{'header':'Assign Vendor'}">
    <ng-container *ngIf="(VendorTemplate !=null && VendorTemplate !=undefined && VendorTemplate.length>0)">
        <customform #clientrefform [FormTemplate]="VendorTemplate" [FormData]="VendorFormData" [FormProp]="AddFormProp"
            [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)"
            (FieldChange)="onChange($event)">
        </customform>
    </ng-container>

    <div class="">
        <div class="d-flex justify-content-end p-3">
            <button type="button" class="rounded-pill btnCustom btnPrimary"
                (click)="UpdateVendorIdAndInitiatetoVendor()" value="{{'Update' | translate }}">
                {{'Update' | translate }}
            </button>
        </div>
    </div>

    <!-- Notification section -->
    <div *ngIf="displayVendorNotifybox">
        <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
            (selected)="setNotifyRes($event) " (close)="closeVendorNotifybox() "></notification>
    </div>

</modal-popup>

<modal-popup id="StopCheck" style="display: none;" class="modal-body_email" [modaldetail]="{'header':'Stop Check'}">

    <ng-container *ngIf="viewStopCheck" style="width:100%;">
        <div class="modal-body modal-body_email" style="width:180%">
          <div  class="card-body overflow-auto" style="width:50%;">
            <customform #stopcheckform style="width:100%;"
                [FormTemplate]="StopCheckTemplate" [FormData]="StopCheckData" [FormProp]="FormProp"
                [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
            </customform>
             </div>
        </div>
    </ng-container>

    <div class="">
        <div class="d-flex justify-content-end p-3">
            <button type="button" class="rounded-pill btnCustom btnPrimary"
                (click)="stopCheck()" value="{{'Submit' | translate }}">
                {{'Submit' | translate }}
            </button>
        </div>
    </div>

    <!-- Notification section -->
    <div *ngIf="displayVendorNotifybox">
        <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
            (selected)="setNotifyRes($event) " (close)="closeVendorNotifybox() "></notification>
    </div>

</modal-popup>