
import { escapeRegExp } from '@angular/compiler/src/util';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { BGVStatus,ComponentCodes, ComponentConfig, ProcessStatus, RoleCodes } from 'src/app/app.interface';
import { CommonAPIService } from 'src/app/shared/app.commonservice';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined } from 'src/app/shared/app.sharedservice';
import { GridComponent } from 'src/modules/grid/grid.component';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { FieldTemplate ,FormProperty} from 'src/modules/shared/_classes/utility.interface';
import { RequestService } from '../request.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'bgv-head',
    templateUrl: './bgv-head.component.html',

})
export class BGVHeadComponent implements OnInit {

    @Input() RequestId: number = 0;
    @Input() SelectedTabName: string = '';
    @Input() RequestData: any;

    @Output() RefreshRequest = new EventEmitter<any>();
    @ViewChild('additionalsectiongrid', { static: false }) additionalsectiongrid!: GridComponent;
    public BGVHeadTemplate: FieldTemplate[] = [];
    public SubmitDetailsTemplate: FieldTemplate[] = [];
    public CommentTemplate: FieldTemplate[] = [];
    public BGVHeadPermission: any = [];
    public BGVHeadProperties: any = [];
    public BGVHeadData: any[] = [];
    public BGVHeadSectionData: any[] = [];
    public BGVHeadDataSummary: any[] = [];
    public subscriptionList$: Subscription[] = [];
    public openBGVHeadSection: boolean = false;
    public BGVHeadAdditionalSectionData: any[] = [];
    public actionCode: string = '';
    public sectionName: string = '';
    public sectionDisplayName: string = '';
    public DisplayAttachment: boolean = false;
    public transId: number = 0;
    public notify: NotificationData = new NotificationData();
    public sectionData: any[] = []
    public GridProp: any = {
        Editable: true,
        IsSortable: false
    };
    public AttachPerm: any = {
        Upload: true,
        Download: false
    };
    public IsLoading: boolean = false;
    public IsReadonly: boolean = false;
    public displayNotifybox: boolean = false;

  //changes for new screen

  public BGVHeadFormData: any = {};
  public GlobalConfig: any = {};
  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };
  public SubmittedDetailsList: any[] = [];
  public CentralHRCommentData: any[] = [];
    constructor(private eleRef: ElementRef, private _requestService: RequestService, private _commonAPIService: CommonAPIService
        , private _appSharedService: AppSharedService) {
        let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
            this.ngOnInit();
        });
        this.subscriptionList$.push(subscribe$);
    }

    ngOnInit(): void {
        if (!isNullOrUndefined(this._appSharedService.currentRoleData)) {
            if (this.RequestData.IsStopCaseSubmitted === true || this.RequestData.IsStopCase === true) {
                this.GridProp.Editable = false;
                this.IsReadonly = true;
            }
            this.getBGVHeadTemplate();

        }

    }
    public FormProp: FormProperty = {
        // 'ColSize': 1,
        // 'CaptionTop': false,
        // 'IsEditable': true,
        'ColSize': 1,
        'CaptionTop': true,
        'CaptionBold': true,  
        'IsEditable': false,
        'ShowControlAlways': true
      };

      public SubmitFormProp: FormProperty = {
        'ColSize': 2,
        'CaptionBold': false,
        'CaptionTop': true,
        'IsEditable': true,
        'ShowControlAlways': true,

      };
      public CommentFormProp: FormProperty={
        'ColSize': 2,
        'CaptionBold': false,
        'CaptionTop': false,
        'IsEditable': true,
        'ShowControlAlways': true,
      };

    getBGVHeadTemplate(): void {

        this.IsLoading = true;
        let odata: ComponentConfig = {
            Component: 'BGVHead', ComponentCode: ComponentCodes.BGVHead
        };

        let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
            if (res !== undefined && res !== null && res.Status === 200) {
                this.BGVHeadTemplate = res.Data.FieldTemplate!;
                this.BGVHeadPermission = res.Data.Permission;
                this.setCompProperties(res.Data.Properties!);
                this.SubmitDetailsTemplate=this.BGVHeadTemplate.filter(x=>x.Group==='BGVHeadSubmit_Template');
                this.CommentTemplate=this.BGVHeadTemplate.filter(x=>x.Group==='BGVHeadCommentSection');
                console.log(res.Data.Properties!);
                this.getCheckDocumentDetailsList();

                this.getApprovalDetailsForCHRAndBGVHead();
                this.getSubmittedDetails();


            }
        },
            err => {
                this.IsLoading = false;
                console.log('Error on getBGVHeadTemplate');
            });
        this.subscriptionList$.push(subscribe$);
    }
    setCompProperties(Properties: any[]) {
        if (!isNullOrUndefined(Properties) && Properties.length > 0) {

            // 2) Blocking Cols based on custom values
            this.BGVHeadProperties.BlockCols = (Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase()).length > 0 ?
                JSON.parse(Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase())[0].JSONData.toString()) : []);

            // Other component specific props
            Properties.forEach(prop => {
                // avoiding generic props
                if (!isNullOrUndefined(prop.Name) &&
                    prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
                    && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
                    this.BGVHeadProperties[prop.Name] = JSON.parse(prop.JSONData.toString());
                }
            });
        }
    }
    getCheckDocumentDetailsList() {
        // this.IsLoading = true;

        let odata: object = { 'requestId': this.RequestId, 'checkId': 0 };

        let subscribe$: Subscription = this._requestService.getCheckDocumentDetailsList(odata).subscribe(result => {
            if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
                this.BGVHeadData = result.Data;

                this.setActionButtonPermissions();
                this.enableDisableButtons();
                //this.getBGVHeadadditionalSectionList();
            }
            // this.IsLoading = false;
        },
            err => {
                console.log('Error on getting getCheckDocumentDetailsList.');
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }
    getBGVHeadadditionalSectionList() {
        // this.IsLoading = true;

        let odata: object = { 'requestId': this.RequestId, 'checkId': null };

        let subscribe$: Subscription = this._requestService.getNonGreenadditionalSectionList(odata).subscribe(result => {
            if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
                this.BGVHeadAdditionalSectionData = result.Data;
            }
            this.IsLoading = false;
        },
            err => {
                console.log('Error on getting getBGVHeadadditionalSectionList.');
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }

    setActionButtonPermissions(): void {
        if (!isNullOrUndefined(this.BGVHeadProperties.ActionButtons) && this.BGVHeadProperties.ActionButtons.length > 0
           ) {
            console.log(this.BGVHeadProperties.ActionButtons);
            this.BGVHeadProperties.ActionButtons.forEach((item: any) => {

                if (!isNullOrUndefined(item.Name) && item.Name !== '') {
                    item.HasPermission = false;
                    item.IsApplicable = false;
                    let peritem = this.BGVHeadPermission.indexOf(item.Name);
                    if (peritem >= 0) {
                        item.HasPermission = true;
                    }
                    switch (item.Name.toUpperCase()) {
                        // case 'SUBMITBGVHead':

                        //     // item.IsApplicable = this.enableCloseCase();
                        //     item.IsApplicable = true;
                        //     break;
                        // case 'CANCELBGVHead':
                        //     //   item.IsApplicable = this.enableSubmitCase();
                        //     item.IsApplicable = true;
                        //     break;
                        case 'ACCEPTEDBGVHEAD':

                            item.IsApplicable = ((this.BGVHeadData.filter(x => x.PendingWith === RoleCodes.BGVHead.toString()
                            && (x.UploadStatus === BGVStatus.Non_Green__Awaiting_for_Exception_Approval_From_BGV_Head_1
                                || x.UploadStatus === BGVStatus.Non_Green__Awaiting_for_Exception_Approval_From_BGV_Head_2
                                || x.UploadStatus === BGVStatus.Non_Green__Awaiting_for_Exception_Approval_From_BGV_Head_3)).length > 0
                            )) ? true : false;

                            item.IsApplicable = item.IsApplicable;
                            break;
                        case 'REJECTEDBGVHEAD':
                            item.IsApplicable = ((this.BGVHeadData.filter(x => x.PendingWith === RoleCodes.BGVHead.toString()
                            && (x.UploadStatus === BGVStatus.Non_Green__Awaiting_for_Exception_Approval_From_BGV_Head_1
                            || x.UploadStatus === BGVStatus.Non_Green__Awaiting_for_Exception_Approval_From_BGV_Head_2
                            || x.UploadStatus === BGVStatus.Non_Green__Awaiting_for_Exception_Approval_From_BGV_Head_3)).length > 0
                        )) ? true : false;

                            item.IsApplicable = item.IsApplicable;
                            break;
                        case 'TERMINATIONACCEPTED':

                            item.IsApplicable = ((this.BGVHeadData.filter(x => x.PendingWith === RoleCodes.BGVHead.toString()
                            && (x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_From_BGV_Head_1
                               )).length > 0
                            )) ? true : false;

                            item.IsApplicable = item.IsApplicable;
                            break;
                        case 'TERMINATIONREJECTED':
                            item.IsApplicable = ((this.BGVHeadData.filter(x => x.PendingWith === RoleCodes.BGVHead.toString()
                            && (x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_From_BGV_Head_1
                            )).length > 0
                        )) ? true : false;

                            item.IsApplicable = item.IsApplicable;
                            break;

                    }
                }
            });
        }
    }


    // SubmitBGVHead(modifieditems: any, msg: string) {
    //     // if (this.validateMandatoryFields(modifieditems)) {
    //     modifieditems.forEach((item: any) => {
    //         item.ActionCode = item.BGVHeadStatusCode;
    //     });
    //     this.IsLoading = true;
    //     let subscribe$: Subscription = this._requestService.SubmitBUandBGVHead(modifieditems).subscribe(result => {
    //         if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

    //             // this.showNotificationMessage(msg, true);
    //             this.showNotificationMessage(msg, 1, '', null, '', '');
    //             // this.getCheckDocumentDetailsList();

    //             this.refreshRequest();

    //         }
    //         // this.IsLoading = false;
    //     },

    //         err => {
    //             this.IsLoading = false;
    //         });
    //     this.subscriptionList$.push(subscribe$);
    //     // }
    // }
    SubmitBGVHead(modifieditems: any,actionCode:string, msg: string) {
        // if (this.validateMandatoryFields(modifieditems)) {
        modifieditems.forEach((item: any) => {
            item.ActionCode = actionCode;
            item.BGVHeadComment=this.BGVHeadFormData.BGVHeadComment; //filter((x: any) => x.BGVHeadComment === 1);
        });
        this.IsLoading = true;
        let subscribe$: Subscription = this._requestService.SubmitBGVHeadandCentralHR(modifieditems).subscribe(result => {
            if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

                // this.showNotificationMessage(msg, true);
                this.showNotificationMessage(msg, 1, '', null, '', '');
                 this.getCheckDocumentDetailsList();

                this.refreshRequest();

            }
            // this.IsLoading = false;
        },

            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
        // }
    }

    showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string) {
        let headerIcon: string = '';

        if (successFlag === 1) {
            headerIcon = 'fa fa-check-circle text-success';
        } else if (successFlag === 2) {
            headerIcon = 'fa fa-exclamation-circle text-warning';
        } else if (successFlag === 3) {
            headerIcon = 'fa fa-exclamation-triangle text-danger';
        } else {
            headerIcon = 'fa fa-info-circle text-info';
        }

        this.notify = {
            info: {
                header: 'Confirmation Alert',
                message: msg,
                yes: yes,
                no: no,

                IsHeaderHidden: true,
                HeaderIcon: headerIcon,
                IsCrossEnable: true,
                SizeClass: 'modal-sm'
            },

            action: action,
            item: item
        };

        this.displayNotifybox = true;
    }

    refreshRequest(): void {
        this.RefreshRequest.emit({ 'requestId': this.RequestId });
    }

    validateMandatoryFields(modifieditems: any[]): boolean {
        let valid: boolean = true;
        if (!isNullOrUndefined(this.BGVHeadFormData.BGVHeadComment) && this.BGVHeadFormData.BGVHeadComment === '') {
           
            this.showNotificationMessage('Please provide BGV Head comments.', 2, '', null, '', '');
            valid = false;
        }

        // if (isNullOrUndefined(modifieditems) || modifieditems.length === 0) {
        //     // this.showNotificationMessage('No rows are modified.', false);
        //     this.showNotificationMessage('No rows are modified.', 2, '', null, '', '');

        //     valid = false;
        // }

        // if (!isNullOrUndefined(modifieditems) && modifieditems.length > 0) {
        //     let statusList = modifieditems.filter(x => ((isNullOrUndefined(x.BGVHeadStatusCode)) || x.BGVHeadStatusCode === '')
        //     && (!isNullOrUndefined(x.BGVHeadComment) && x.BGVHeadComment !== ''));

            // if (!isNullOrUndefined(statusList) && statusList.length > 0) {
            //     // this.showNotificationMessage('Please select non green status for modified rows.', false);
            //     this.showNotificationMessage('Please select BGV Head status for modified rows.', 2, '', null, '', '');
            //     valid = false;
            // }

        //     if (!isNullOrUndefined(modifieditems) && modifieditems.length > 0) {
        //         modifieditems.forEach((item: any) => {
        //             item.BGVHeadComment=this.BGVHeadFormData.BGVHeadComment;
        //             //filter((x: any) => x.BGVHeadComment === 1);
        //         });

        //     //Comments Check
        //     let emptyComments = modifieditems.filter(x => ((isNullOrUndefined(x.BGVHeadComment)) || x.BGVHeadComment === ''));

        //     if (!isNullOrUndefined(emptyComments) && emptyComments.length > 0 && valid) {
        //         // this.showNotificationMessage('Please provide comments for modified rows.', false);
        //         this.showNotificationMessage('Please provide BGV Head comments for modified rows.', 2, '', null, '', '');
        //         valid = false;
        //     }
        // }

        return valid;
    }

    deleteAttachment(FileData: any) {

        this.IsLoading = true;
        let deleteData: any[] = [];

        FileData.forEach((item: any) => {
            let odata: object = {
                'Module': 'SubCheck',
                'Reference1': Number(this.RequestId),
                'Reference2': 0,
                'Reference3': 0,
                'Reference4': 0,
                'TransactionId': item.FileReferenceId,
                'FilePath': '',
                'FileName': item.FileName,
                'UserId': this._appSharedService.UserInfo.actinguserid
            };
            deleteData.push(odata);
        });
        let subscribe$: Subscription = this._requestService.deleteAttachment(deleteData).subscribe(result => {
            if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

                this.sectionData.forEach(item => {
                    item.DocumentList = null;
                    item.DocumentListDesc = null;
                    item.FileReferenceId = 0;
                    item.FileName = '';
                });
                this.sectionData = [];
            }



            this.IsLoading = false;
            this.openBGVHeadSection = false;
            // this.refreshRequest();

        },

            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }

    closeAttachment(event: any): void {
        console.log(event.data);

        let selectedData = this.sectionData.filter(x => x.TransactionId === this.transId)[0];

        if (!isNullOrUndefined(event) && event.Reload === true) {
            // selectedData.FilePath = event.data[0].FilePath;
            selectedData.FileReferenceId = event.data[0].TransactionId;
            selectedData.FileName = event.data[0].FileName;
        }

        this.DisplayAttachment = false;
    }

    enableDisableButtons(): void {
        if (!isNullOrUndefined(this.BGVHeadProperties.ActionButtons)
            && this.BGVHeadProperties.ActionButtons.length > 0
            && (this.BGVHeadProperties.ActionButtons.filter((x: any) => x.Group === 'BGVHead')).length > 0) {



            let primaryPageButt = this.BGVHeadProperties.ActionButtons.filter((x: any) =>
                x.Group === 'BGVHead');

                primaryPageButt.forEach((item: any) => {

                    if (!isNullOrUndefined(item.Name) && item.Name !== '') {
                        item.IsEnabled = false;
                        switch (item.Name.toUpperCase()) {
                            case 'ACCEPTEDBGVHEAD':

                                if (!isNullOrUndefined(this.BGVHeadData)
                                && this.BGVHeadData.length > 0
                                && this.BGVHeadData.filter(x => x.IsEditable === 1 && !isNullOrUndefined(x.CheckStatusCode)
                                && (x.UploadStatus=== BGVStatus.Non_Green__Awaiting_for_Exception_Approval_From_BGV_Head_1
                                ||x.UploadStatus=== BGVStatus.Non_Green__Awaiting_for_Exception_Approval_From_BGV_Head_2
                                ||x.UploadStatus=== BGVStatus.Non_Green__Awaiting_for_Exception_Approval_From_BGV_Head_3
                                )
                                ).length > 0
                            ) {
                                item.IsEnabled = true;
                                item.HasPermission=true;
                            }
                                break;
                            case 'REJECTEDBGVHEAD':
                                if (!isNullOrUndefined(this.BGVHeadData)
                                && this.BGVHeadData.length > 0
                                && this.BGVHeadData.filter(x => x.IsEditable === 1 && !isNullOrUndefined(x.CheckStatusCode)
                                && (x.UploadStatus=== BGVStatus.Non_Green__Awaiting_for_Exception_Approval_From_BGV_Head_1
                                ||x.UploadStatus=== BGVStatus.Non_Green__Awaiting_for_Exception_Approval_From_BGV_Head_2
                                ||x.UploadStatus=== BGVStatus.Non_Green__Awaiting_for_Exception_Approval_From_BGV_Head_3
                                )
                                ).length > 0
                            ) {
                                item.IsEnabled = true;
                                item.HasPermission=true;
                            }
                                break;



                                case 'TERMINATIONACCEPTED':

                                if (!isNullOrUndefined(this.BGVHeadData)
                                && this.BGVHeadData.length > 0
                                && this.BGVHeadData.filter(x => x.IsEditable === 1 && !isNullOrUndefined(x.CheckStatusCode)
                                && (
                                 x.CheckStatusCode=== BGVStatus.NonGreen_Process_initiated_for_termination_From_BGV_Head_1)
                                ).length > 0
                            ) {
                                item.IsEnabled = true;
                                item.HasPermission=true;
                            }
                                break;
                            case 'TERMINATIONREJECTED':
                                if (!isNullOrUndefined(this.BGVHeadData)
                                && this.BGVHeadData.length > 0
                                && this.BGVHeadData.filter(x => x.IsEditable === 1 && !isNullOrUndefined(x.CheckStatusCode)
                                && (
                                 x.CheckStatusCode=== BGVStatus.NonGreen_Process_initiated_for_termination_From_BGV_Head_1)
                                ).length > 0
                            ) {
                                item.IsEnabled = true;
                                item.HasPermission=true;
                            }
                                break;
                        }
                    }
                });

        }
    }

    onClick(event: any): void {
        console.log(event);
        let selitem: any = event.item;

        if (event.fieldname.toUpperCase() === 'FILEACTION') {
            // event.icon.IconCSS
            switch (event.icon.Value) {
                case '0':
                    //Download
                    this.downloadAttachment(selitem);
                    break;
            }
        }
    }

    downloadAttachment(data: any) {
        this.IsLoading = true;

        let odata: any = {
            'Module': data.FileModule,
            'Reference1': Number(this.RequestId),
            'Reference2': data.CheckId,
            'Reference3': data.DocumentTypeId,
            'Reference4': 0,
            'TransactionId': data.FileReferenceId,
            // 'FilePath': data.FilePath,
            'FilePath': '',
            'FileName': data.FileName
        };

        let subscribe$: Subscription = this._requestService.downloadAttachment(odata).subscribe(result => {
            // if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
            //   console.log(result);
            // }
            if (!isNullOrUndefined(result)) {
                let url = window.URL.createObjectURL(result.data);
                let a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                // a.download = res.filename;
                a.download = data.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
                // this._toastr.success('Resume downloaded successfully', this.CompTitle);
                console.log('Resume downloaded successfully');

                this.notify = {
                    info: {
                        header: 'Confirmation Alert',
                        message: 'Document downloaded successfully',
                        yes: '',
                        no: '',

                        IsHeaderHidden: true,
                        HeaderIcon: 'fa fa-check-circle text-success',
                        IsCrossEnable: true,
                        SizeClass: 'modal-sm'
                    },

                    action: '',
                    item: null
                };

                this.displayNotifybox = true;
            }
            this.IsLoading = false;

        },
            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }

    onChange(event: any): void {
        let selitem: any = event.item;
        // Emitting onchange event of resource plan to parent container
        selitem.IsModified = 1;
        this.transId = 0;
        this.transId = selitem.TransactionId;
        selitem.IsRowHighlight = selitem.IsModified;

        switch (event.fieldname.toUpperCase()) {
            case ' BGVHEADSTATUSCODE':
                if ((isNullOrUndefined(selitem.BGVHeadStatusCode) || selitem.BGVHeadStatusCode === '') && !isNullOrUndefined(selitem.OldBGVHeadStatusCode) && selitem.OldBGVHeadStatusCode !== '') {

                    // this.showNotificationMessage('Please select atleast one Non green status.', 2, '', null, '', '');

                    setTimeout(() => {
                        selitem.BGVHeadStatusCode = selitem.OldBGVHeadStatusCode
                        selitem.BGVHeadStatusDesc = selitem.OldBGVHeadStatusDesc
                        selitem.BGVHeadComment = selitem.OldBGVHeadComment
                        selitem.IsModified = 0;
                        selitem.IsRowHighlight = selitem.IsModified;
                    }, 50);

                }
                break;
        }

        this.enableDisableButtons();

        let eventdata = {
            fieldname: event.fieldname
        };
    }

    closeNotifybox(): void {
        this.displayNotifybox = false;
    }
    actionButtonClick(data: any) {
        let sectionitems = this.sectionData.filter(x => x.IsModified === 1);

        if (!isNullOrUndefined(data.Name) && data.Name !== '') {
            this.actionCode = '';
            this.actionCode = data.ActionCode;
            switch (data.Name.toUpperCase()) {
                case 'SUBMITBGVHEAD':
                    let x = this.BGVHeadDataSummary.filter(x => x.IsEditable === 1);
                    // if (this.validateMandatoryFields(this.BGVHeadData.filter(x => x.IsEditable === 1)) && this.BGVHeadData.filter(x => x.IsEditable === 1).length > 0) {
                    //     // this.SubmitBGVHead(sectionitems);
                    //     this.showNotificationMessage('Are you sure you want to submit the checks?', 1, data.Name, this.BGVHeadData.filter(x => x.IsEditable === 1), 'Submit now', 'Cancel');
                    // }
                    if (this.validateMandatoryFields(this.BGVHeadDataSummary.filter(x => x.IsModified === 1)) && this.BGVHeadDataSummary.filter(x => x.IsModified === 1).length > 0) {
                        // this.SubmitBGVHead(sectionitems);

                        this.BGVHeadData.filter(x => x.IsEditable === 1).forEach(item => {
                            item.BGVHeadStatusCode = this.BGVHeadDataSummary[0].BGVHeadStatusCode;
                            item.BGVHeadComment = this.BGVHeadDataSummary[0].BGVHeadComment;
                            item.IsModified = 1;
                        });

                        //let msg = this.BGVHeadDataSummary.filter(x => x.IsEditable === 1 && x.IsModified === 1).length > 1 ? 'checks' : 'check';
                        //this.showNotificationMessage('Are you sure you want to submit the ' + msg + '?', 1, data.Name, this.BGVHeadData.filter(x => x.IsEditable === 1 && x.IsModified === 1 && !isNullOrUndefined(x.BGVHeadStatusCode) && x.BGVHeadStatusCode !== ''), 'Submit now', 'Cancel');
                        this.showNotificationMessage('Are you sure you want to submit the case?', 1, data.Name, this.BGVHeadData.filter(x => x.PendingWith==RoleCodes.BGVHead), 'Submit now', 'Cancel');
                    }

                    break;

                case 'ACCEPTEDBGVHEAD':
                    if (this.validateMandatoryFields(this.BGVHeadData.filter(x => x.PendingWith===RoleCodes.BGVHead))) {

                    this.BGVHeadData.forEach(item => {

                       // item.BGVHeadComment = this.BGVHeadDataSummary[0].BGVHeadComment;

                        item.IsModified = 1;
                    });

                    //let msg = this.BGVHeadDataSummary.filter(x => x.IsEditable === 1 && x.IsModified === 1).length > 1 ? 'checks' : 'check';
                    //this.showNotificationMessage('Are you sure you want to submit the ' + msg + '?', 1, data.Name, this.BGVHeadData.filter(x => x.IsEditable === 1 && x.IsModified === 1 && !isNullOrUndefined(x.BGVHeadStatusCode) && x.BGVHeadStatusCode !== ''), 'Submit now', 'Cancel');
                    this.showNotificationMessage('Are you sure you want to accept the case?', 1, data.Name, this.BGVHeadData.filter(x => x.PendingWith==RoleCodes.BGVHead), 'Submit now', 'Cancel');
                }
                    break;
                case 'REJECTEDBGVHEAD':
                    if (this.validateMandatoryFields(this.BGVHeadData.filter(x => x.PendingWith===RoleCodes.BGVHead))) {

                    this.BGVHeadData.forEach(item => {

                       //item.BGVHeadComment = this.BGVHeadDataSummary[0].BGVHeadComment;

                        item.IsModified = 1;
                    });

                    //let msg = this.BGVHeadDataSummary.filter(x => x.IsEditable === 1 && x.IsModified === 1).length > 1 ? 'checks' : 'check';
                    //this.showNotificationMessage('Are you sure you want to submit the ' + msg + '?', 1, data.Name, this.BGVHeadData.filter(x => x.IsEditable === 1 && x.IsModified === 1 && !isNullOrUndefined(x.BGVHeadStatusCode) && x.BGVHeadStatusCode !== ''), 'Submit now', 'Cancel');
                    this.showNotificationMessage('Are you sure you want to reject the case?', 1, data.Name, this.BGVHeadData.filter(x => x.PendingWith==RoleCodes.BGVHead), 'Submit now', 'Cancel');
                    }   
                    break;

                    case 'TERMINATIONACCEPTED':
                        if (this.validateMandatoryFields(this.BGVHeadData.filter(x => x.PendingWith===RoleCodes.BGVHead))) {

                            this.BGVHeadData.forEach(item => {

                            // item.BGVHeadComment = this.BGVHeadDataSummary[0].BGVHeadComment;

                                item.IsModified = 1;
                            });

                            //let msg = this.BGVHeadDataSummary.filter(x => x.IsEditable === 1 && x.IsModified === 1).length > 1 ? 'checks' : 'check';
                            //this.showNotificationMessage('Are you sure you want to submit the ' + msg + '?', 1, data.Name, this.BGVHeadData.filter(x => x.IsEditable === 1 && x.IsModified === 1 && !isNullOrUndefined(x.BGVHeadStatusCode) && x.BGVHeadStatusCode !== ''), 'Submit now', 'Cancel');
                            this.showNotificationMessage('Are you sure you want to accept the termination approval?', 1, data.Name, this.BGVHeadData.filter(x => x.PendingWith==RoleCodes.BGVHead), 'Submit now', 'Cancel');
                        }
                    break;
                case 'TERMINATIONREJECTED':
                    if (this.validateMandatoryFields(this.BGVHeadData.filter(x => x.PendingWith===RoleCodes.BGVHead)))
                    {
                        this.BGVHeadData.forEach(item => {

                            //item.BGVHeadComment = this.BGVHeadDataSummary[0].BGVHeadComment;
     
                             item.IsModified = 1;
                   
                    
                        });
                   

                     //let msg = this.BGVHeadDataSummary.filter(x => x.IsEditable === 1 && x.IsModified === 1).length > 1 ? 'checks' : 'check';
                     //this.showNotificationMessage('Are you sure you want to submit the ' + msg + '?', 1, data.Name, this.BGVHeadData.filter(x => x.IsEditable === 1 && x.IsModified === 1 && !isNullOrUndefined(x.BGVHeadStatusCode) && x.BGVHeadStatusCode !== ''), 'Submit now', 'Cancel');
                     this.showNotificationMessage('Are you sure you want to reject the termination approval?', 1, data.Name, this.BGVHeadData.filter(x => x.PendingWith==RoleCodes.BGVHead), 'Submit now', 'Cancel');
                    }
                break;
            }
        }

    }

    setNotifyRes(event: any): void {
        console.log('notify res >> ' + JSON.stringify(event));
        let canClose: boolean = true;
        switch (event.action.toUpperCase()) {
            // case 'SUBMITBGVHead':
            //     if (event.result.toUpperCase() === 'SUBMIT NOW') {
            //         this.SubmitBGVHead(event.item, 'Status is submitted sucessfully.');
            //     }
            //     break;
            case 'ACCEPTEDBGVHEAD':
                if (event.result.toUpperCase() === 'SUBMIT NOW') {

                    this.actionCode='AC034'

                    this.SubmitBGVHead(event.item,this.actionCode, 'Status is submitted sucessfully.');
                }
                break;
            case 'REJECTEDBGVHEAD':
                    if (event.result.toUpperCase() === 'SUBMIT NOW') {
                        this.actionCode='AC035'
                        this.SubmitBGVHead(event.item,this.actionCode, 'Status is submitted sucessfully.');
                    }
                    break;
                    case 'TERMINATIONACCEPTED':
                        if (event.result.toUpperCase() === 'SUBMIT NOW') {

                            this.actionCode='AC037'

                            this.SubmitBGVHead(event.item,this.actionCode, 'Status is submitted sucessfully.');
                        }
                        break;
                    case 'TERMINATIONREJECTED':
                            if (event.result.toUpperCase() === 'SUBMIT NOW') {
                                this.actionCode='AC038'
                                this.SubmitBGVHead(event.item,this.actionCode, 'Status is submitted sucessfully.');
                            }
                            break;
        }
        if (canClose) {
            this.closeNotifybox();
        }
    }

    getApprovalDetailsForCHRAndBGVHead() {


    this.IsLoading = true;
    this.BGVHeadFormData = '';

    let subscribe$: Subscription = this._requestService.GetBasicDetailsBGVHeadAndCHR(this.RequestId).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
      this.BGVHeadFormData = result.Data;


      }

      this.IsLoading = false;

    },
      err => {
        console.log('Error on getting GetRequestData.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
}
getSubmittedDetails() {


    this.IsLoading = true;
    this.SubmittedDetailsList = [];

    let subscribe$: Subscription = this._requestService.GetSubmittedDetailsCHRAndBGVHead(this.RequestId).subscribe(result => {
    if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
      this.SubmittedDetailsList = result.Data;
    //   this.SubmittedDetailsList.forEach((item: any) => {
    //     item.IsEditable =  1 ;
    //     item.IsModified = 0;
    //   });
    }
    if (result ===null) {
        this.SubmittedDetailsList=[];

    }

      this.IsLoading = false;

    },
      err => {
        console.log('Error on getting GetRequestData.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
}
//GetApprovalDetailsForCHRAndBGVHead
//GetNGSubmittedDetails






}
