<!-- Manage Vendor Component -->

<ng-container>
    <div class="h-100">
        <div class="contentScroll p-3 simpleCard" style="overflow: hidden !important;">
            <div class="card h-100 m-0">
                <div class="card-header">
                    <h3>
                        {{Title}}
                    </h3>
                </div>
                <div class="card-body overflow-auto p-0">
                    <div class="contentScroll p-3">
                        <div *ngIf="SelectedTab == 'VendorList'">
                            <div class="gridContainer">
                                <ng-container
                                    *ngIf="(venListTemplate != null && venListTemplate != undefined && venListTemplate.length > 0)">

                                    <grid-dispctrl #overallgrid
                                        [FieldTemplate]="(venListTemplate | filterBy:{Group: 'SearchResult'})"
                                        [GridData]="venListData" [GridProp]="GridProp"
                                        [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                                        (ActionBarClick)="actButtonClick($event)">
                                    </grid-dispctrl>

                                </ng-container>
                            </div>
                        </div>

                        <div *ngIf="SelectedTab == 'VendorEdit'">
                            <ng-container
                                *ngIf="(venListTemplate != null && venListTemplate != undefined && venListTemplate.length > 0)">

                                <div class="bg-light p-3"
                                    *ngFor="let group of (Config.GroupList | orderBy: {property: 'Seqorder', direction: '1' , type: 'number'})">
                                    <p class="text-bold">{{group.DisplayName | translate}}</p>

                                    <div class="row form-inline">
                                        <customform #editForm [FormId]="'Ven_'+group.GroupName+'_'"
                                            [FormTemplate]="(venListTemplate | filterBy: {Group:group.GroupName})"
                                            [FormData]="editVendorData" [FormProp]="FormProp" [FormConfig]="FormConfig"
                                            [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)"
                                            (FieldChange)="onChange($event)">
                                        </customform>
                                    </div>
                                </div>
                            </ng-container>

                        </div>

                        <div *ngIf="SelectedTab == 'MemberList'">
                            <div class="gridContainer">
                                <ng-container
                                    *ngIf="(venListTemplate != null && venListTemplate != undefined && venListTemplate.length > 0)">

                                    <grid-dispctrl #overallgrid
                                        [FieldTemplate]="(venListTemplate | filterBy:{Group: 'MemberResult'})"
                                        [GridData]="memListData" [GridProp]="GridProp"
                                        [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                                        (ActionBarClick)="actButtonClick($event)">
                                    </grid-dispctrl>

                                </ng-container>
                            </div>

                        </div>

                        <div *ngIf="SelectedTab == 'MemberEdit'">
                            <ng-container
                                *ngIf="(venListTemplate != null && venListTemplate != undefined && venListTemplate.length > 0)">

                                <div class="bg-light p-3">
                                    <p class="text-bold"></p>

                                    <div class="row form-inline">
                                        <customform [FormTemplate]="(venListTemplate | filterBy:{Group: 'MemberEdit'})"
                                            [FormData]="editMemberData" [FormProp]="FormProp" [FormConfig]="FormConfig"
                                            [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)"
                                            (FieldChange)="onChange($event)">
                                        </customform>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="filters buttonHolder">
                        <div class="d-flex justify-content-between">
                            <div></div>
                            <div>
                                <ng-container
                                    *ngIf="Config.ActionButtonsBottom != null && Config.ActionButtonsBottom != undefined">
                                    <ng-container
                                        *ngFor="let actionBar of (Config.ActionButtonsBottom | filterBy: 
                                            {
                                                IsApplicable: true,HasPermission :true, 
                                                Group: 
                                                (
                                                SelectedTab == 'VendorList' ? 'ViewVendor' : 
                                                SelectedTab == 'VendorEdit' ? 'AddVendor' : 
                                                SelectedTab == 'MemberList' ? 'ViewMember' :
                                                SelectedTab == 'MemberEdit' ? 'AddMember' : ''
                                                ) 
                                            }
                                                        | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">

                                        <button type="button" [ngClass]="actionBar.CustomClass"
                                            (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                            value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                            |
                                            translate
                                            }}
                                            <span *ngIf="actionBar.ShowArrow && actionBar.Name != 'BackVendor'"
                                                class="ps-3"><i class="ion ion-md-arrow-forward"
                                                    aria-hidden="true"></i></span>
                                            <span *ngIf="actionBar.ShowArrow && actionBar.Name == 'BackVendor'"
                                                class="ps-3"><i class="ion ion-md-arrow-back"
                                                    aria-hidden="true"></i></span>
                                        </button>
                                    </ng-container>
                                </ng-container>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-center">
                        <div class="small align-self-center">
                            Copyright © 2022 Capgemini All rights reserved.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>

<!-- Filter Pop-up -->
<div *ngIf="DispFilter">
    <filter *ngIf="(venListTemplate!= undefined && venListTemplate!= null && venListTemplate.length > 0)"
        [FieldTemplate]=
        "(
            SelectedTab == 'VendorList' ? (venListTemplate | filterBy:{Group: 'SearchResult'}) : 
            SelectedTab == 'MemberList' ? (venListTemplate | filterBy:{Group: 'MemberResult'}) : ''
        )" 
        [GridData]=
        "(
            SelectedTab == 'VendorList' ? venListDataALL : 
            SelectedTab == 'MemberList' ? memListDataALL : ''
        )"
        (close)="showFilter($event)" (selected)="setFilter($event)"
        [InitialCondn]="curFiltCondn">
    </filter>
</div>

<!-- Excel Download -->
<excel-export #excelexport [FileName]="ExcelFileName"></excel-export>

<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
        (selected)="setNotifyRes($event)" (close)="closeNotifybox()"></notification>
</div>