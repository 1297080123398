import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AngularDraggableModule } from 'angular2-draggable';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FilterPipeModule } from 'ngx-filter-pipe';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';

import { LoadingModule } from 'src/modules/loading/loading.module';
import { FilterModule } from 'src/modules/filter/filter.module';
import { CustomPipeModule } from 'src/modules/custompipe/custompipe.module';
import { FormModule } from 'src/modules/form/form.module';
import { GridReadonlyModule } from 'src/modules/grid-readonly/grid-readonly.module';
import { GridModule } from 'src/modules/grid/grid.module';
import { SearchByModule } from 'src/modules/searchby/searchby.module';
import { DashboardModule } from 'src/modules/dashboard/dashboard.module';
import { ExcelExportModule } from 'src/modules/excelexport/excelexport.module';
import { ActionBarModule } from 'src/modules/actionbar/actionbar.module';
import { NotificationModule } from 'src/modules/notification/notification.module';
import { InitiateCaseComponent } from './initiate-case.component';
import { GridDisplayControlModule } from 'src/modules/grid-dispctrl/grid-dispctrl.module';
import { AttachmentModule } from 'src/modules/attachment/attachment.module';
import { RequestModule } from '../request/request.module';
import { ReportModule } from '../report/report.module';
//import { ModalInterface } from 'src/modules/modal/modal.interface';
//import { HomeModule } from '../home/home.module';
import { ModalModule } from 'src/modules/modal/modal.module';

@NgModule({
  declarations: [
    InitiateCaseComponent,

  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  imports: [
    BrowserModule, FormsModule, NgbModule,
    AlertModule, PaginationModule, TooltipModule,
    ToastrModule, AngularDraggableModule, TranslateModule,
    LoadingModule, FilterModule, CustomPipeModule, FormModule,
    GridReadonlyModule, FilterPipeModule, GridModule, RouterModule,
    ExcelExportModule, ActionBarModule, NotificationModule,
    DashboardModule, SearchByModule, ModalModule, GridDisplayControlModule
    , AttachmentModule, RequestModule
    , ReportModule
  ],
  exports: [
    InitiateCaseComponent

  ],
//  providers: [ModalModule],
  bootstrap: [InitiateCaseComponent],

})

export class InitiateCaseModule {
}
