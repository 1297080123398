import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { isNullOrUndefined } from 'src/app/shared/app.sharedservice';
import { MenuItem } from './menubar.interface';

@Component({
    selector: 'menubar',
    templateUrl: './menubar.component.html'
})

export class MenuBarComponent implements OnInit {
    @Input() menuList!: MenuItem[];
    @Input() menuType?: string = 'mainbar'; //'sidebar';

    @Output() public menuClick = new EventEmitter<any>();

    public selectedTab: any; // = {tab: {}, parenttab: {}};
    public currentComponentRef: any;
    public subscriptionList$: Subscription[] = [];

    public isMenuSmall: boolean = true;
    public sideBarOpen: boolean = false;
    public fieldRandomId: string;
    constructor(private _route: ActivatedRoute, private eleRef: ElementRef, private _router: Router) {
        let subscribe$: Subscription = this._router.events.pipe(
            // filter(event => event instanceof NavigationStart)
            filter((event: any) => event instanceof NavigationEnd)
          ).subscribe((event: NavigationEnd) => {
            // change in routing
            console.log('change in routing >> ');
            console.log(event.url);
            this.setSelectedTab();
          });

          this.subscriptionList$.push(subscribe$);
          this.fieldRandomId = Math.random().toString(36).substr(2, 5);
    }

    // Click listener on the window object to handle clicks anywhere on 
    // the screen.
    @HostListener('window:click', ['$event']) onOutsideClick(event: any) {
        if (this.sideBarOpen && !this.eleRef.nativeElement.contains(event.target)) {
            this.sideBarOpen = false;
            document.getElementsByClassName('sidebar')[0].classList.remove('showsidebar');
            document.body.classList.remove('push');
        }
    }

   
    ngOnInit() {

        if (!isNullOrUndefined(this.menuList) && this.menuList.length > 0){
            // this.menuList = this.menuList.filter((x:MenuItem)=> x.HasPermission == true );

            let temp = this.menuList.filter((x:MenuItem)=> x.IsDefault == true );
    
                if (temp.length > 0) {
                    temp[0].Active = true;
                }
        }

    }

    // // ngOnChanges() {
    // ngOnChanges(changes: SimpleChanges) {
    //     // console.log('changes >> ');
    //     // console.log(changes);
    
    //     // Change in template
    //     if (!isNullOrUndefined(changes.menuList) && changes.menuList.previousValue !== changes.menuList.currentValue) {
    //         if (!isNullOrUndefined(this.menuList) && this.menuList.length > 0){
    //             let temp = this.menuList.filter((x:MenuItem)=> x.IsDefault == true );
    
    //             if (temp.length > 0) {
    //                 temp[0].Active = true;
    //             }
    //         }
        
    //     }       

    // }    

    toggleSideBar() {
    }


    hamburger_cross() {
        // let trigger: HTMLButtonElement = document.getElementsByClassName('hamburger')[0];
        // let overlay: HTMLDivElement = document.getElementsByClassName('overlay')[0];
        let trigger: HTMLButtonElement = this.eleRef.nativeElement.querySelector('.hamburger');
        let overlay: HTMLDivElement = this.eleRef.nativeElement.querySelector('.overlay');

        if (this.sideBarOpen == true) {
            overlay.hidden = true;
            trigger.classList.remove('is-open');
            trigger.classList.add('is-closed');
            this.sideBarOpen = false;
        } else {
            overlay.hidden = false;
            trigger.classList.remove('is-closed');
            trigger.classList.add('is-open');
            this.sideBarOpen = true;
        }
    }


    buttonClick(menu: any): void {
        /// Anomonous data for Click emit event -- Start
        let eventdata = { menu: menu };
        this.menuClick.emit(eventdata);
        /// Anomonous data for Click emit event -- End
    }

    setSelectedTab() {
        // Reset previously selected
        if (!isNullOrUndefined(this.selectedTab)) {
            this.selectedTab.Active = false;
        }
        // reset parent tabs
        let partemp = this.menuList.filter(x => x.Active === true);
        if (partemp.length > 0) {
            partemp[0].Active = false;
        }

        // Set currently selected
        // set selected tab from route url
        // let route = this._route.snapshot.root.url;
        let routepath = this._route.snapshot?.firstChild?.routeConfig?.path || '';

        let routearray = routepath.split('/');
        let temp = this.menuList;

        if (routearray.length > 0 && this.menuList.length > 0) {
            let i = 0;
            let x = './';
            while (temp.length > 0) {
                let curtab = temp.filter(p => p.RouteLink === x + routearray[i]);
                if (curtab.length > 0) {
                    curtab[0]['Active'] = true;
                    temp = curtab[0]['SubMenu'];
                    x = x + routearray[i] + '/';

                    this.selectedTab = curtab[0];

                    i++;
                }
                else {
                    temp = [];
                }
            }
        }

    }

    getSelectedSubTabName(tab: any): string {
        let str = '';
        let subtab = tab.SubMenu.filter((x: any) => x.Active === true);

        if (tab.Active === true && subtab.length > 0) {
            str = subtab[0].DisplayName;
        }

        return str;
    }

    onRouteClick(tab: any, parenttab?: any) {
        // console.log('on route click');
        // console.log('tab===>', JSON.stringify(tab));

        // // // Reset previously selected
        // // this.selectedTab.Active = false;

        // // // reset parent tabs
        // // let temp = this.menuList.filter(x => x.Active === true);
        // // if (temp.length > 0) {
        // //   temp[0].Active = false;
        // // }

        // // // Set currently selected
        // // this.selectedTab = tab;
        // // tab.Active = true;
        // // if (!isNullOrUndefined(parenttab)) {
        // //   parenttab.Active = true;
        // // }


        // // // // auto save changes if any
        // // // if (!isNullOrUndefined(this.currentComponentRef) && !isNullOrUndefined(this.currentComponentRef.autoSave)
        // // //     && typeof this.currentComponentRef.autoSave === 'function') {
        // // //   this.currentComponentRef.autoSave();
        // // // }

    }

    // onActivate(componentRef: any) {
    //     // if (componentRef instanceof SummaryComponent) {

    //     // }

    //     this.currentComponentRef = componentRef;

    //     return;
    // }




}
