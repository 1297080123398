<div class="h-100">
    <div class="p-2 contentScroll simpleCard">
        <!--Update Over All Start From Here -->
        <ng-container *ngIf="openReport == false">
            <div class="card h-100 m-0">
                <div class="card-header">
                    <h3>
                        <span>{{SelectedTabName | translate}}</span>
                    </h3>
                </div>
                <div class="card-body overflow-auto">
                    <!-- <ng-container
                        *ngIf="updateOverallStatusProperties.UpdateOverallSections!=null && updateOverallStatusProperties.UpdateOverallSections!=undefined && updateOverallStatusProperties.UpdateOverallSections.length > 0">
                        <ng-container
                            *ngFor="let updateSection of (updateOverallStatusProperties.UpdateOverallSections | filterBy: {CanShow: true})"> -->
                    <!-- <div class="gridAction">
                        <div class="d-flex justify-content-between">
                            <label class="d-flex">
                                <span class="pe-2">Show</span>
                                <select class="form-select form-select-sm align-self-center"
                                    name="complex-table1_length" aria-controls="complex-table1"
                                    [(ngModel)]="curItemsPerPage" (change)="pageItemsChange()">
                                    <option value=10>10</option>
                                    <option value=25>25</option>
                                    <option value=50>50</option>
                                    <option value=100>100</option> -->
                                    <!-- <option value=-1>All</option> -->
                                <!-- </select>
                                <span class="ps-2">entries</span>
                            </label> -->
<!-- 
                            <div class="col-auto flex-fill"> -->
                                <!-- <actionbar [actionList]="updateOverallStatusProperties.ActionButtons" (butClick)="actButtonClick($event)"
                                      [noActionClass]="true">
                                    </actionbar> -->
                            <!-- </div> -->
                            <!-- <ul class="actionList">
                                          <li>
                                            <a href="javascript:;"><i class="fa fa-file-excel-o" aria-hidden="true"></i>Download Excel</a>
                                          </li>
                                        </ul> -->
                        <!-- </div>
                    </div> -->
                    <!-- <div class="gridContainer mb-3"> -->
                    <div class="mb-3">
                        <ng-container
                            *ngIf="updateOverallStatusTemplate!=null && updateOverallStatusTemplate!=undefined && updateOverallStatusTemplate.length > 0">
                            <grid-dispctrl #updateStatusgrid
                                [FieldTemplate]="updateOverallStatusTemplate | filterBy: {Group: 'Updatestatus'}"
                                [GridProp]="GridProp" [GridData]="updateOverallStatusData"
                                [GridDataTotalCount]="updateOverallStatusData.length"
                                [parent]="this" (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                            </grid-dispctrl>
                        </ng-container>
                    </div>

                    <button style="float: right;" type="button" class="rounded-pill btnCustom btnPrimary" (click)="showOverallStatus()">Show/Hide Overall Status
                        <span><i class="ion ion-md-arrow-forward" aria-hidden="true"></i></span>
                    </button>


                    <br><br>

                    <ng-container
                        *ngIf="updateOverallStatusProperties.UpdateOverallSections!=null && updateOverallStatusProperties.UpdateOverallSections!=undefined && updateOverallStatusProperties.UpdateOverallSections.length > 0 && isAdditonalSectionClicked">
                        <ng-container
                            *ngFor="let additionalSection of (updateOverallStatusProperties.UpdateOverallSections | filterBy: {CanShow: true})">
                            <p class="text-bold mb-1">
                                <span>{{additionalSection.DisplayName |
                                    translate}}</span>
                            </p>

                            <ng-container
                                *ngIf="updateOverallStatusTemplate!=null && updateOverallStatusTemplate!=undefined && updateOverallStatusTemplate.length > 0">
                                <grid-dispctrl #updateStatusgrid
                                    [FieldTemplate]="updateOverallStatusTemplate | filterBy: {Group: additionalSection.Name}"
                                    [GridProp]="GridProp" [GridData]="updateOverallAdditionalSectionData"
                                    [GridDataTotalCount]="updateOverallAdditionalSectionData.length"
                                    (FieldClick)="onClick($event)"
                                    (FieldChange)="onChange($event)">
                                </grid-dispctrl>
                            </ng-container>

                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <!--Update Over All End Here -->

        <!--Report Submission Start From Here -->
        <ng-container *ngIf="openReport == true">
            <div class="card h-100 m-0">
                <div class="card-header">
                    <h3>
                        <span>Update Overall Status > {{reportDisplayName | translate}}</span>
                    </h3>
                </div>
                <div class="card-body overflow-auto">

                    <ng-container
                        *ngIf="reportTemplate!=null && reportTemplate!=undefined && reportTemplate.length > 0">
                        <grid-dispctrl #reportgrid [FieldTemplate]="reportTemplate" [GridProp]="GridProp" [GridData]="reportData"
                            [GridDataTotalCount]="reportData.length"
                            (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                        </grid-dispctrl>

                    </ng-container>

                </div>
            </div>
        </ng-container>
        <!--Report Submission End Here -->
    </div>

    <div class="filters buttonHolder">
        <div class="d-flex justify-content-between ">
            <div></div>
            <div>
               


                <ng-container
                    *ngIf="updateOverallStatusProperties.ActionButtons != null && updateOverallStatusProperties.ActionButtons != undefined">

                    <!--Update Over all Status buttons-->
                    <ng-container *ngIf="openReport == false">
                        <ng-container *ngFor="let actionBar of (updateOverallStatusProperties.ActionButtons | filterBy: {IsApplicable: true,HasPermission :true, Group :'UpdateOverallStatus'}
                            | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">

                            <button type="button" [ngClass]="actionBar.CustomClass"
                                (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName |
                                translate
                                }}
                                <span *ngIf="actionBar.ShowArrow" class="ps-3"><i class="ion ion-md-arrow-forward" aria-hidden="true"></i></span>
                            </button>
                        </ng-container>
                    </ng-container>

                    <!--Interim,Final and supplementary report buttons-->
                    <ng-container *ngIf="openReport == true">
                        <ng-container *ngFor="let actionBar of (updateOverallStatusProperties.ActionButtons | filterBy: {IsApplicable: true,HasPermission :true, Group :reportName}
                            | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">

                            <button type="button" [ngClass]="actionBar.CustomClass"
                                (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName |
                                translate
                                }}
                                <span *ngIf="actionBar.ShowArrow" class="ps-3"><i class="ion ion-md-arrow-forward" aria-hidden="true"></i></span>
                            </button>
                        </ng-container>
                    </ng-container>
                </ng-container>


            </div>
        </div>
    </div>

</div>

<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
        (selected)="setNotifyRes($event) " (close)="closeNotifybox() "></notification>
</div>

<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>

<!--Attachment section-->
<ng-container *ngIf="DisplayAttachment==true">
    <file-upload [Module]="reportType" [RefId1]="RequestId" [IsMultiple]="false" (close)="closeAttachment($event)"
        [Permissions]="AttachPerm" [UploadType]="updateOverallStatusProperties.UploadFileType" 
        [CandidateId] = "RequestData.CandidateId">
    </file-upload>
</ng-container>