
<div class="h-100">
    <div class="p-2 contentScroll simpleCard">
        <!--Non Green Parent Page Start From Here -->
        <ng-container *ngIf="!openCentralHRSection">
            <div class="card h-100 m-0">
                <div class="card-header">
                    <h3>
                        <span>{{SelectedTabName | translate}}</span>
                    </h3>
                </div>
                <!-- Commenting start for new central HR screen -->
                <!-- <div class="card-body overflow-auto">

                    <ng-container
                        *ngIf="CentralHRProperties.CentralHRSummary!=null && CentralHRProperties.CentralHRSummary!=undefined && CentralHRProperties.CentralHRSummary.length > 0">
                        <ng-container
                            *ngFor="let CentralHRSummary of (CentralHRProperties.CentralHRSummary | filterBy: {CanShow: true})">
                            <p class="text-bold mb-1">
                                <span>{{CentralHRSummary.DisplayName |
                                    translate}}</span>
                            </p>

                            <div class="gridAction">
                                <div class="d-flex justify-content-between">

                                </div>
                            </div>


                                <div class="table-responsive">
                                    <ng-container
                                        *ngIf="CentralHRTemplate!=null && CentralHRTemplate!=undefined && CentralHRTemplate.length > 0">
                                        <grid-dispctrl #updateStatusgrid
                                            [FieldTemplate]="CentralHRTemplate | filterBy: {Group: CentralHRSummary.Name}"
                                            [GridProp]="GridProp" [GridData]="CentralHRDataSummary"
                                            [GridDataTotalCount]="CentralHRDataSummary.length" (FieldClick)="onClick($event)"
                                            (FieldChange)="onChange($event)">
                                        </grid-dispctrl>
                                    </ng-container>
                                </div>

                            <br/>
                        <br/>
                        </ng-container>
                    </ng-container>

                    <br>
                    <ng-container
                        *ngIf="CentralHRProperties.CentralHRSection!=null && CentralHRProperties.CentralHRSection!=undefined && CentralHRProperties.CentralHRSection.length > 0">
                        <ng-container
                            *ngFor="let CentralHRSection of (CentralHRProperties.CentralHRSection | filterBy: {CanShow: true})">
                            <p class="text-bold mb-1">
                                <span>{{CentralHRSection.DisplayName |
                                    translate}}</span>
                            </p>

                            <div class="gridAction">
                                <div class="d-flex justify-content-between">

                                </div>
                            </div>


                                <div class="table-responsive">
                                    <ng-container
                                        *ngIf="CentralHRTemplate!=null && CentralHRTemplate!=undefined && CentralHRTemplate.length > 0">
                                        <grid-dispctrl #updateStatusgrid
                                            [FieldTemplate]="CentralHRTemplate | filterBy: {Group: CentralHRSection.Name}"
                                            [GridProp]="GridProp" [GridData]="CentralHRData"
                                            [GridDataTotalCount]="CentralHRData.length" (FieldClick)="onClick($event)"
                                            (FieldChange)="onChange($event)">
                                        </grid-dispctrl>
                                    </ng-container>
                                </div>

                            <br/>
                        <br/>
                        </ng-container>
                    </ng-container>
                    <br>
                    <ng-container
                        *ngIf="CentralHRProperties.AdditionalSection!=null && CentralHRProperties.AdditionalSection!=undefined && CentralHRProperties.AdditionalSection.length > 0">
                        <ng-container
                            *ngFor="let additionalSection of (CentralHRProperties.AdditionalSection | filterBy: {CanShow: true})">
                            <p class="text-bold mb-1">
                                <span>{{additionalSection.DisplayName |
                                    translate}}</span>
                            </p>

                                <div class="table-responsive">
                                    <ng-container
                                        *ngIf="CentralHRTemplate!=null && CentralHRTemplate!=undefined && CentralHRTemplate.length > 0">
                                        <grid-dispctrl #updateStatusgrid
                                            [FieldTemplate]="CentralHRTemplate | filterBy: {Group: additionalSection.Name}"
                                            [GridProp]="GridProp" [GridData]="CentralHRAdditionalSectionData"
                                            [GridDataTotalCount]="CentralHRAdditionalSectionData.length"
                                            (FieldClick)="onClick($event)"
                                            (FieldChange)="onChange($event)">
                                        </grid-dispctrl>
                                    </ng-container>
                                </div>

                            <br/>
                        <br/>
                        </ng-container>
                    </ng-container>
                </div> -->
                <!-- Commenting end for new central HR screen -->
                <!-- New changes start here -->
                 <div class="card-body overflow-auto">
                 <ng-container  *ngIf="CentralHRProperties.CentralHRSummary!=null && CentralHRProperties.CentralHRSummary!=undefined && CentralHRProperties.CentralHRSummary.length > 0">

              
                        <ng-container>
                                <div class="row small-gutter">
                                    
                                <ng-container
                                        *ngIf="CentralHRTemplate!=null && CentralHRTemplate!=undefined && CentralHRTemplate.length > 0">
                                    <ng-container>
                                        <customform #ngcustomform
                                        [FormTemplate]="CentralHRTemplate | filterBy: {Group: 'CandidateDetails'}"
                                        [FormData]="CentralHRFormData"
                                        (FieldClick)="onClick($event)"
                                        (FieldChange)="onChange($event)">
                                        </customform>
                                    </ng-container>
                                        <br>
                                        <br>
                                    <ng-container>    
                                        <customform #ngcustomform
                                        [FormTemplate]="CentralHRTemplate | filterBy: {Group: 'CentralHRScreen'}"
                                        [FormData]="CentralHRFormData"
                                        [FormProp]="FormProp"
                                        (FieldClick)="onClick($event)"
                                        (FieldChange)="onChange($event)">
                                        </customform>
                                    </ng-container>
                                </ng-container>
                                </div>
                            
                        <br>
                        <br>
                        <ng-container *ngFor="let data of SubmittedDetailsList">
                            <ng-container
                                *ngIf="(SubmitDetailsTemplate!=null && SubmitDetailsTemplate!=undefined && SubmitDetailsTemplate.length > 0)">
                                <div class="d-flex justify-content-between">
                                    <p class="d-flex text-bold">{{data.VerifiedComponentName |
                                        translate}}</p>
                                </div>
                                <br>
                                
                                    <customform #ngcustomform2 
                                    [FormTemplate]="SubmitDetailsTemplate"
                                    [FormData]="data" 
                                    [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                                    (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                </customform>
                            
                            </ng-container>
                        </ng-container>
                    </ng-container> 
                         <br>
                        <ng-container
                            *ngIf="CentralHRTemplate!=null && CentralHRTemplate!=undefined && CentralHRTemplate.length > 0">
                            <div class="row small-gutter">
                                <customform #ngcustomform1
                                [FormTemplate]="CentralHRTemplate | filterBy: {Group: 'CentralHRComment'}"
                                [FormData]="CentralHRFormData" 
                                [FormProp]="CommentFormProp"
                                >
    
                                </customform>
                            </div>
                        </ng-container> 
                                                    
                    </ng-container>
                
             
                 </div>

            </div>
        </ng-container>
        <!--Non Green Parent Page All End Here -->
    </div>

    <div class="filters buttonHolder">
        <div class="d-flex justify-content-between ">
            <div></div>
            <div>
                <ng-container
                    *ngIf="CentralHRProperties.ActionButtons != null && CentralHRProperties.ActionButtons != undefined && !IsReadonly && !RequestData.IsClosed">

                    <!--Non Green Parent Page buttons-->
                    <!-- <ng-container *ngIf="!openCentralHRSection"> -->
                        <ng-container *ngFor="let actionBar of (CentralHRProperties.ActionButtons | filterBy: {IsApplicable: true,HasPermission :true, Group :'CentralHR'}
                            | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">

                            <button type="button" [ngClass]="actionBar.CustomClass"
                                (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName |
                                translate
                                }}
                                <span *ngIf="actionBar.ShowArrow" class="ps-3"><i class="ion ion-md-arrow-forward" aria-hidden="true"></i></span>
                            </button>
                        </ng-container>
                    <!-- </ng-container> -->
                </ng-container>
            </div>
        </div>
    </div>

</div>

<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
        (selected)="setNotifyRes($event) " (close)="closeNotifybox() "></notification>
</div>

<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>


<!--Attachment section-->
<ng-container *ngIf="DisplayAttachment==true">
    <file-upload [Module]="'SubCheck'" [RefId1]="RequestId" [IsMultiple]="false" (close)="closeAttachment($event)"
        [Permissions]="AttachPerm" [CandidateId] = "RequestData.CandidateId">
    </file-upload>
</ng-container>