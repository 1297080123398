<div class="h-100">
    <div class="contentScroll p-2 simpleCard" style="overflow: hidden !important;">
        <div class="card h-100 m-0">
            <div class="card-header">
                <h3>
                    <span>Manage Volume distribution</span>
                </h3>
            </div>
            <div class="card-body overflow-auto p-0">
                <div class="contentScroll p-3">
                    <ng-container
                        *ngIf="(venListTemplate != null && venListTemplate != undefined && venListTemplate.length > 0)">
                        <div class="bg-light p-3" *ngFor="let group of GroupByVendor | filterBy: {RowStatus: 'A'}">
                            <p class="text-bold"><span>Vendor Legal name :
                                    <span class="text-primary ps-2">{{group.VendorName | translate}}</span></span></p>
                            <div class="gridContainer">
                                <ng-container
                                    *ngIf="(venListTemplate != null && venListTemplate != undefined && venListTemplate.length > 0)">

                                    <grid-dispctrl #mvmaingrid [GridId]="'MV_'+group.VendorId+'_'"
                                        [FieldTemplate]="venListTemplate" 
                                        [GridProp]="GridProp"
                                        [GridData]="(venListData | filterBy: {VendorId:group.VendorId, IsValid: true, RowStatus:'A'})"
                                        (FieldChange)="onChange($event, group.VendorId)" (FieldClick)="onClick($event)"
                                        (ActionBarClick)="actButtonClick($event,group.VendorId)" 
                                        [cellLock]="setCellLock" [parent]="this"> 
                                    </grid-dispctrl>

                                </ng-container>
                            </div>
                        </div>
                    </ng-container>

                </div>
                <div class="filters buttonHolder">
                    <div class="d-flex justify-content-end ">
                        <div>
                            <ng-container
                                *ngIf="Config.ActionButtonsBottom != null && Config.ActionButtonsBottom != undefined">
                                <ng-container
                                    *ngFor="let actionBar of (Config.ActionButtonsBottom | filterBy: {IsApplicable: true,HasPermission :true}
                                                | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">

                                                
                                    <button *ngIf="actionBar.Name != 'SaveAndSubmit'" type="button" [ngClass]="actionBar.CustomClass"
                                        (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                        value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                        |
                                        translate
                                        }}
                                        <span *ngIf="actionBar.ShowArrow" class="ps-3"><i
                                                class="ion ion-md-arrow-forward" aria-hidden="true"></i></span>
                                    </button>

                                    <button *ngIf="actionBar.Name == 'SaveAndSubmit'" class="btnCustom btnPrimary rounded-pill" data-bs-toggle="modal"
                                    data-bs-target="#submit-confirm" [disabled]="!actionBar.IsEnabled"
                                    value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                    |
                                    translate
                                    }}
                                    <span *ngIf="actionBar.ShowArrow" class="ps-3"><i
                                            class="ion ion-md-arrow-forward" aria-hidden="true"></i></span>
                                </button> 

                                </ng-container>
                            </ng-container>

                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="d-flex justify-content-center">
                    <div class="small align-self-center text-muted">Copyright © 2020 Capgemini All rights reserved.
                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- Loader section -->
    <div [hidden]="!IsLoading">
        <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
    </div>

    <!-- Notification section -->
    <div *ngIf="displayNotifybox">
        <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
            (selected)="setNotifyRes($event)" (close)="closeNotifybox()"></notification>
    </div>

    <div class="modal fade" id="submit-confirm" tabindex="-1" aria-labelledby="submitConfirm" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="submitConfirm"></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body text-center">
                    <i class="fa-3x ion ion-ios-information-circle text-warning" aria-hidden="true"></i>
                    <h5 class="text-center">Are you sure you want to submit data?</h5>
                </div>
                <div class="modal-footer justify-content-center">
                    <button type="button" class="btnCustom btnNegative rounded-pill" data-bs-dismiss="modal">
                        <span>Cancel</span><i class="ion ion-md-arrow-forward" aria-hidden="true"></i>
                    </button>
                    <button type="button" class="btnCustom btnPrimary rounded-pill" data-bs-toggle="modal"
                        data-bs-target="#confirm-case" data-bs-dismiss="modal" (click)="Save()">
                        <span>Submit Now</span><i class="ion ion-md-arrow-forward" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>