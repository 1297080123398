import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppSharedService } from 'src/app/shared/app.sharedservice';
import { ProcessResponse, ProcessResponse1 } from '../../app/app.interface';
import { environment } from '../../environments/environment';
import { AttachmentInfo } from './attachment.interface';


@Injectable()
export class AttachmentService {
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    private FileMngtUrl: string;

    constructor(private _http: HttpClient, public _appSharedService: AppSharedService) {
        this.FileMngtUrl = environment.apiURL.FileManagementURL;
    }

    private handleError(error: any): Promise<any> {
        console.error('Error : ', error.message);
        return Promise.reject(error.message || error);
    }

    getAttachments(odata: any): Observable<ProcessResponse<any>> {
        let Params = new HttpParams();
        Params = Params.append('Module', odata.Module);
        Params = Params.append('Reference1', odata.Reference1);
        Params = Params.append('Reference2', odata.Reference2);
        Params = Params.append('Reference3', odata.Reference3);
        Params = Params.append('Reference4', odata.Reference4);
        Params = Params.append('Reference5', odata.Reference5);
        Params = Params.append('Reference6', odata.Reference6);
        Params = Params.append('UserId', this._appSharedService.UserAccessInfo.actinguserid);


        // return this._http.get<ProcessResponse<any>>(this.CheckUrl + 'GetFileAttachmentList', { headers: this.headers, params: Params })
        return this._http.get<ProcessResponse<any>>(this.FileMngtUrl + 'GetFileManageTransactionDetails', { headers: this.headers, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }

    uploadAttachments(odata: any): Observable<ProcessResponse1<any>> {
        let Params = new HttpParams();
        Params = Params.append('module', odata.Module);
        Params = Params.append('reference1', odata.Reference1);
        Params = Params.append('reference2', odata.Reference2);
        Params = Params.append('reference3', odata.Reference3);
        Params = Params.append('reference4', odata.Reference4);
        Params = Params.append('reference5', odata.Reference5);
        Params = Params.append('reference6', odata.Reference6);
        // Params = Params.append('UserId', this._appSharedService.UserAccessInfo.actinguserid);
        Params = Params.append('userId', odata.UserId);

        // Here header should not contain content-type.
        let headers1 = new HttpHeaders();

        // return this._http.post<ProcessResponse<any>>(this.FileMngtUrl + 'UploadfilesHierarchy',
        return this._http.post<ProcessResponse1<any>>(this.FileMngtUrl + 'UploadfilesHierarchy?reference1=' + odata.Reference1 + '&reference2=' + odata.Reference2 
                                                                + '&reference3=' + odata.Reference3 + '&reference4=' + odata.Reference4
                                                                + '&reference5=' + odata.Reference5 + '&reference6=' + odata.Reference6
                                                                + '&userId=' + odata.UserId + '&module=' + odata.Module,
            odata, { headers: headers1, params: Params, reportProgress: true })
            .pipe(
                catchError(this.handleError)
            );

    }

    deleteAttachment(Attachment: AttachmentInfo): Observable<ProcessResponse<any>> {
        return this._http.post<ProcessResponse<any>>(this.FileMngtUrl + 'DeleteAttachment', Attachment, { headers: this.headers })
            .pipe(
                catchError(this.handleError)
            );
    }

    downloadFile(Attachment: AttachmentInfo): Observable<any> {
        return this._http.post(this.FileMngtUrl + 'DownloadFile', Attachment, { responseType: 'blob' })
            .pipe(map(res => {
                return {
                    data: res
                };
            }))
            .pipe(
                catchError(this.handleError)
            );
    }

    // downloadAttachment(odata: any): Observable<ProcessResponse<any>> {
    downloadAttachment(odata: any): Observable<any> {
        let Params = new HttpParams();

        return this._http.post(this.FileMngtUrl + 'Downloadfiles', odata,
            { headers: this.headers, params: Params, responseType: 'blob' })
            .pipe(map(res => {
                return {
                    data: res
                };
            }))
            .pipe(
                catchError(this.handleError)
            );
    }

}
