import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FilterPipeModule } from 'ngx-filter-pipe';

import { CustomPipeModule } from '../custompipe/custompipe.module';
import { MenuBarComponent } from './menubar.component';


@NgModule({
  declarations: [
    MenuBarComponent
  ],
  imports: [
    BrowserModule, FormsModule, TooltipModule,
    TranslateModule.forRoot(),
    CustomPipeModule, FilterPipeModule,
    RouterModule,
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient]
    //   }
    // })
  ],
  providers: [],
  bootstrap: [MenuBarComponent],
  exports: [MenuBarComponent]
})

export class MenuBarModule { }
