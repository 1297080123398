/*
*   Module          :
*   Description     :
*   Remarks         :
*   Author          :
*   Created Date    :
*   Modified By     :
*   Modified Date   :
*/

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
// import { isNullOrUndefined } from 'util';
import { isNullOrUndefined, getProperty } from '../../shared/app.sharedservice';
import { environment } from '../../../environments/environment';
import { AppSharedService } from '../../shared/app.sharedservice';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable()
export class AuthGuardService implements CanActivate {

    public actinguser!: string;
    public defaultlang!: string;
    
    constructor(private _authenticationService: AuthenticationService, private _appSharedService: AppSharedService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

        let retFlag: boolean = false;

        if (this._authenticationService.token !== undefined) {
            retFlag = true;
        }

        return retFlag;

        // // // Check if the url is redirected from some other app with query params
        // // // if (route.queryParamMap['params']) {
        // // let paramslist = getProperty(route.queryParamMap, 'params' as any);
        // // if (paramslist) {
        // //     this.actinguser = route.queryParams['actingid'];
        // //     // this.defaultlang = !isNullOrUndefined(route.queryParams['lang'])
        // //     //     && route.queryParams['lang'] !== 'null' ? route.queryParams['lang']
        // //     //     : (!isNullOrUndefined(this._appSharedService)
        // //     //         && !isNullOrUndefined(this._appSharedService.UserInfo)
        // //     //         && !isNullOrUndefined(this._appSharedService.UserInfo.language))
        // //     //         ? this._appSharedService.UserInfo.language : (!isNullOrUndefined(localStorage.getItem('currentLanguage'))
        // //     //             ? localStorage.getItem('currentLanguage') : 'en-US');
        // //     // if (!isNullOrUndefined(route.queryParams['lang']) && route.queryParams['lang'] != 'null') {
        // //     //     localStorage.setItem('currentLanguage', this.defaultlang);
        // //     // }

        // //     this.defaultlang = (!isNullOrUndefined(route.queryParams['lang'])
        // //         && route.queryParams['lang'] !== 'null') ? route.queryParams['lang'] : '';

        // // }

        // // if (this._authenticationService.token !== undefined) {
        // //     return true;
        // // }

        // // if (!environment.DefaultLogin.Enabled) {
        // //     // user mailid given in query param
        // //     this._appSharedService.setUserParam(this.actinguser, this.defaultlang);
        // //     if (isNullOrUndefined(this.actinguser)) {
        // //         // return this._authenticationService.getAPIToken();
        // //         return false;
        // //     }
        // //     else {
        // //         // user mailid given in query param
        // //         //// this._appSharedService.SetUserParam(this.actinguser, this.defaultlang);
        // //         return this._authenticationService.getAPIToken(this.actinguser);
        // //     }

        // // }
        // // else {
        // //     // test login
        // //     return this._authenticationService.getAPIToken(environment.DefaultLogin.Username);
        // // }

    }
}
