import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AngularDraggableModule } from 'angular2-draggable';
import { LoadingModule } from 'src/modules/loading/loading.module';
import { NotificationModule } from 'src/modules/notification/notification.module';

import { LoginComponent } from './login.component';
import { LoginService } from './login.service';
import { ForgotPasswordComponent } from './forgot-password.component';


@NgModule({
  declarations: [
    LoginComponent, ForgotPasswordComponent
  ],
  imports: [
    BrowserModule, FormsModule, AngularDraggableModule, RouterModule,
    NotificationModule, LoadingModule
  ],
  providers: [LoginService],
  bootstrap: [LoginComponent],
  exports: [LoginComponent, ForgotPasswordComponent]
})
export class LoginModule { }
