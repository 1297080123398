import { Component, OnInit } from '@angular/core';
import { AutoUnsubscribe } from '../shared/app.sharedservice';

@Component({
  selector: 'holiday',
  templateUrl: './holiday.component.html',
  styleUrls: ['./holiday.component.css']
})

@AutoUnsubscribe
export class HolidayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
