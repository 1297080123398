import { DecimalPipe, DatePipe } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
// import { isNullOrUndefined } from 'util';
import { isNullOrUndefined } from '../shared/_services/utility.service';


@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) { }

  transform(value: any): any {
    const date = new Date(value);

    // const options = {
    //   weekday: 'long',
    //   year: 'numeric',
    //   month: 'long',
    //   day: 'numeric',
    //   hour: '2-digit',
    //   minute: '2-digit',
    //   second: '2-digit'
    // };

    // // console.log('local lang - ' + this.translateService.currentLang);
    // // return date.toLocaleString(this.translateService.currentLang, options);
    // return date.toLocaleDateString(this.translateService.currentLang);

    const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
    return datePipe.transform(value, (this.translateService as any)['AppDateFormat']);

  }

}

@Pipe({
  name: 'localizedDateTime',
  pure: false
})
export class LocalizedDateTimePipe implements PipeTransform {

  constructor(private translateService: TranslateService) { }

  transform(value: any): any {
    const date = new Date(value);

    // const options = {
    //   weekday: 'long',
    //   year: 'numeric',
    //   month: 'long',
    //   day: 'numeric',
    //   hour: '2-digit',
    //   minute: '2-digit',
    //   second: '2-digit'
    // };

    // // console.log('local lang - ' + this.translateService.currentLang);
    // // return date.toLocaleString(this.translateService.currentLang, options);
    // return date.toLocaleDateString(this.translateService.currentLang) + ' ' + date.toLocaleTimeString(this.translateService.currentLang);

    const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
    return datePipe.transform(value, (this.translateService as any)['AppDateTimeFormat']);

  }

}

@Pipe({
  name: 'localizedNumber',
  pure: false
})
export class LocalizedNumberPipe implements PipeTransform {

  constructor(private translateService: TranslateService) { }

  transform(value: any): any {
    if (!isNullOrUndefined(this.translateService)
      && !isNullOrUndefined(this.translateService.currentLang)
      && !isNullOrUndefined(value)) {
      // console.log('local lang - ' + this.translateService.currentLang);
      return (new DecimalPipe(this.translateService.currentLang).transform(value, '1.2-2'));
    }
    else if (!isNullOrUndefined(this.translateService)
      && !isNullOrUndefined(this.translateService.currentLang)
      && isNullOrUndefined(value)) {
      return (new DecimalPipe(this.translateService.currentLang).transform(0, '1.2-2'));
    }
    else if ((isNullOrUndefined(this.translateService)
      || isNullOrUndefined(this.translateService.currentLang))
      && !isNullOrUndefined(value)) {
      return (new DecimalPipe('en-US').transform(value, '1.2-2'));
    }
    else {
      return (new DecimalPipe('en-US').transform(0, '1.2-2'));
    }
  }

  // transform(value: any, digitsInfo?: string): any {
  //   // console.log('local lang - ' + this.translateService.currentLang);
  //   // return (new DecimalPipe(this.translateService.currentLang).transform(value, '1.2-2'));

  //   let digits = (!isNullOrUndefined(digitsInfo) ? digitsInfo : '1.2-2');
  //   return (new DecimalPipe(this.translateService.currentLang).transform(value, digits));

  // }
}

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(style: any) {
    return this.sanitizer.bypassSecurityTrustHtml(style);
    // return this.sanitizer.bypassSecurityTrustStyle(style);
    // return this.sanitizer.bypassSecurityTrustXxx(style); - see docs
  }
}

@Pipe({ name: 'groupBy' })
export class GroupByPipe implements PipeTransform {
  transform(collection: Array<any>, property: string): Array<any> {
    // prevents the application from breaking if the array of objects doesn't exist yet
    if (!collection) {
      return [];
    }

    const groupedCollection = collection.reduce((previous, current) => {
      if (!previous[current[property]]) {
        previous[current[property]] = [current];
      } else {
        previous[current[property]].push(current);
      }

      return previous;
    }, {});

    // this will return an array of objects, each object containing a group of objects
    return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
  }
}

@Pipe({
  name: 'orderBy',
  pure: false
})
export class OrderByPipe implements PipeTransform {

  transform(records: Array<any>, args?: any): any {
    return records.sort(function (a, b) {
      let retval;

      // Available type
      // 'label'
      // 'textbox' => text, number
      // 'textarea'
      // 'dropdown'
      // 'typeahead'
      // 'link'
      // 'datepicker'
      // 'checkbox'
      // 'button'
      // 'icon'

      let coltype: string = args.type.toString().toLowerCase();

      switch (coltype) {
        case 'label':
        case 'text':
        case 'textarea':
        case 'dropdown':
        case 'typeahead':
        case 'link':
        case 'button':
        case 'icon':
          // string sort
          // Case insensitive
          if (!isNullOrUndefined(args.ignorecase) || args.ignorecase) {
            let a1val = (!isNullOrUndefined(a[args.property]) ? a[args.property].toUpperCase() : '');
            let b1val = (!isNullOrUndefined(b[args.property]) ? b[args.property].toUpperCase() : '');

            if (a1val < b1val) {
              retval = -1 * args.direction;
            }
            else if (a1val > b1val) {
              retval = 1 * args.direction;
            }
            else {
              retval = 0;
            }
          }
          else {    // Case sensitive
            if (a[args.property] < b[args.property]) {
              retval = -1 * args.direction;
            }
            else if (a[args.property] > b[args.property]) {
              retval = 1 * args.direction;
            }
            else {
              retval = 0;
            }
          }

          break;

        case 'checkbox':
          // boolean sort
          let a1 = (!isNullOrUndefined(a[args.property]) ? a[args.property] : false);
          let b1 = (!isNullOrUndefined(b[args.property]) ? b[args.property] : false);

          retval = (a1 === b1) ? 0 : a1 ? -1 : 1;

          break;

        case 'number':
        case 'datepicker':
        default:
          if (a[args.property] < b[args.property]) {
            retval = -1 * args.direction;
          }
          else if (a[args.property] > b[args.property]) {
            retval = 1 * args.direction;
          }
          else {
            retval = 0;
          }
          break;

      }

      return retval;

      // // Case insensitive
      // if (!isNullOrUndefined(args.ignorecase) || args.ignorecase) {
      //   let a1, b1;


      //   if (coltype === 'label' || coltype === 'text' || coltype === 'textarea' || coltype === 'dropdown' || coltype === 'typeahead') {
      //     a1 = (!isNullOrUndefined(a[args.property]) ? a[args.property].toUpperCase() : '');
      //     b1 = (!isNullOrUndefined(b[args.property]) ? b[args.property].toUpperCase() : '');
      //   }
      //   else {
      //     a1 = a[args.property];
      //     b1 = b[args.property];
      //   }

      //   if (a1 < b1) {
      //     return -1 * args.direction;
      //   }
      //   else if (a1 > b1) {
      //     return 1 * args.direction;
      //   }
      //   else {
      //     return 0;
      //   }
      // }
      // else {    // Case sensitive
      //   if (a[args.property] < b[args.property]) {
      //     return -1 * args.direction;
      //   }
      //   else if (a[args.property] > b[args.property]) {
      //     return 1 * args.direction;
      //   }
      //   else {
      //     return 0;
      //   }
      // }

      // // if (args.direction){
      // //   return (a[args.property] < b[args.property]);
      // // }
      // // else {
      // //   return (a[args.property] > b[args.property])
      // // }
    });
  }

}


@NgModule({
  imports: [
    BrowserModule
  ],
  declarations: [LocalizedDatePipe, LocalizedDateTimePipe, LocalizedNumberPipe, SafeHtmlPipe, GroupByPipe, OrderByPipe],
  providers: [],
  exports: [LocalizedDatePipe, LocalizedDateTimePipe, LocalizedNumberPipe, SafeHtmlPipe, GroupByPipe, OrderByPipe]
})
export class CustomPipeModule { }
