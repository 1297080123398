import { Component, EventEmitter, OnInit,Output, ViewChild } from '@angular/core';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { AppSharedService, AutoUnsubscribe, isDate, isNullOrUndefined } from '../../shared/app.sharedservice';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ComponentCodes, ComponentConfig, ProcessStatus } from '../../app.interface';
import { CommonAPIService } from '../../shared/app.commonservice';
import { ExcelExportComponent } from 'src/modules/excelexport/excelexport.component';
import { asEnumerable } from 'linq-es2015';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { GridComponent } from 'src/modules/grid/grid.component';
import { AdminService } from '../admin.service';
import { ModalComponent } from 'src/modules/modal/modal.component';
import { ModalService } from 'src/modules/modal/modal.service';
import { BUHRListUpload } from './upload-buhr-list.interface';

@Component({
  selector: 'app-upload-buhr-list',
  templateUrl: './upload-buhr-list.component.html'
})

@AutoUnsubscribe

export class UploadBUHRComponent implements OnInit {

  @ViewChild('infoform', { static: false }) infoform!: ModalComponent;
  @Output() close = new EventEmitter<any>();
  public FilterData: any = {};

  public GlobalConfig: any = {};

  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };

  public FormProp: FormProperty = {
    'ColSize': 2,
    'CaptionBold': false,
    'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };

  public uploadSuccess: boolean = false;
  public filesToUpload: any = [];
  public roleListTemplate: FieldTemplate[] = [];
  public SearchTemplate: FieldTemplate[] = [];
  public SearchButtons: any[] = [];
  public roleListData: any[] = [];
  public roleListDataALL: any[] = [];
  public rolePermissions: any = [];
  public dataTotalCount: number = 0;
  public DataInput: object = { PageIndex: 0, PageSize: 0 };
  public selectedInfoData: any;
  public curItemsPerPage: number = 10; 
  public SelectedTab: string = "BUHRList";
  public Title: string = "";
  public AttachedFiles: any = null;
  public selFileNames: string = '';
  
  public GridProp: any = {
    Editable: true,
    //TableCSS: 'auto',
    PaginationConfig: {
      ItemsPerPage: 10
    },
    ActionBarConfig: {
      ActionBarList: null
    }
  }
  public Config: any = {
    ActionButtons: [],
    ActionButtonsBottom: []
  };

  public IsLoading: boolean = false;
  public subscriptionList$: Subscription[] = [];
  public displayNotifybox: boolean = false;
  public notify: NotificationData = new NotificationData();
  public curFiltCondn: string = '';
  public DispFilter: boolean = false;
    // public DispLoader: boolean = false;

  public ExcelFileName: string = '';
  // public ExcelData: any = [];

  @ViewChild('excelexport', { static: false }) excelexport!: ExcelExportComponent;
  @ViewChild('overallgrid', { static: false }) overallgrid!: GridComponent;

  constructor(private _route: ActivatedRoute, private _router: Router, private _appSharedService: AppSharedService,
    private _commonAPIService: CommonAPIService, private adminService: AdminService, private modalService: ModalService) {

    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);

  }

  ngOnInit() {

    if (!isNullOrUndefined(this._appSharedService.currentRoleData)) {
      this.Config.ActionButtons = JSON.parse(`[{"Name":"ExportToExcel","Icon":"fa fa-file-excel-o","DisplayName":"Dow<u>n</u>load Excel","AccessKey":"N","IsApplicable":true,"HasPermission":true,"IsEnabled":true,"Confirmation":{"IsEnabled":false,"Message":null,"Action":null},"SeqOrder":1},{"Name":"Filter","Icon":"fa fa-filter","DisplayName":"F<u>i</u>lter","AccessKey":"I","IsApplicable":true,"HasPermission":true,"IsEnabled":true,"Confirmation":{"IsEnabled":false,"Message":null,"Action":null},"SeqOrder":2}]`);
      this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;
      this.getRoleTemplate();
    }

    this.SearchTemplate = JSON.parse(`[{"FieldName":"UserId","DisplayName":"User Id/Name","LabelTemplate":null,"LinkedField":null,"ColumnWidth":"20","Control":{"Type":"textbox","InputType":"text","List":null,"Source":{"Url":null,"KeyField":null,"ValueField":null,"UrlAttribute":null,"Target":null},"DependentControl":null,"Format":null,"LoadedUrl":null,"DynamicLoad":false},"Cssnames":"","Style":"","Events":"","Editable":1,"IsHidden":false,"ToolTipTemplate":null,"EditValidate":{"Required":false,"Min":null,"Max":null},"FieldCollection":null,"Group":"SearchFilter","ResponsiveMediaSize":"md,lg,xl"}]`);
    this.SearchButtons = JSON.parse(`[{"Name":"Search","CustomClass":"rounded-pill btnCustom btnPrimary small","DisplayName":"Search","AccessKey":"N","ShowArrow":true,"IsApplicable":true,"HasPermission":true,"IsEnabled":true,"Confirmation":{"IsEnabled":false,"Message":null,"Action":null},"SeqOrder":1},{"Name":"Clear","CustomClass":"rounded-pill btnCustom btnNegative small","DisplayName":"Clear","AccessKey":"S","ShowArrow":false,"IsApplicable":true,"HasPermission":true,"IsEnabled":true,"Confirmation":{"IsEnabled":false,"Message":null,"Action":null},"SeqOrder":2}]`);

  }

  getRoleTemplate(): void {
    this.IsLoading = true;

    this.roleListTemplate = [];

    let odata: ComponentConfig = {
      Component: 'Admin', ComponentCode: ComponentCodes.AdminComponent
    };

    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {

        this.roleListTemplate = res.Data.FieldTemplate!;

        this.setCompProperties(res.Data.Properties!);

        this.rolePermissions = res.Data.Permission;

        this.BUHRData();
      }
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getRoleTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }

  setCompProperties(Properties: any[]) {
    if (!isNullOrUndefined(Properties) && Properties.length > 0) {
      // 1) ActionButtons field
      this.Config.ActionButtons = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase())[0].JSONData.toString()) : []);

      this.Config.ActionButtonsBottom = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtonsBottom'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtonsBottom'.toLowerCase())[0].JSONData.toString()) : []);

      this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;

      // Other component specific props
      Properties.forEach(prop => {
        // avoiding generic props
        if (!isNullOrUndefined(prop.Name) &&
          prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
          && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
          this.Config[prop.Name] = JSON.parse(prop.JSONData.toString());
        }
      });

    }
  }

  setActionButtonPermissions(): void {
    if (!isNullOrUndefined(this.Config.ActionButtonsBottom) && this.Config.ActionButtonsBottom.length > 0) {

      this.Config.ActionButtonsBottom.forEach((item: any) => {
        if (!isNullOrUndefined(item.Name) && item.Name !== '') {
          item.HasPermission = false;
          //item.IsApplicable = false;

          let peritem = this.rolePermissions.indexOf(item.Name);
          if (peritem >= 0) {
            item.HasPermission = true;
          }
        }
      });
    }
  }

  BUHRData(): void {

    this.IsLoading = true;

    this.roleListData = [];
    this.roleListDataALL = [];
    this.dataTotalCount = 0;

    let subscribe$: Subscription = this.adminService.getBUHRDetails().subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        this.roleListData = result.Data;
        console.log(this.roleListData);
        this.dataTotalCount = this.roleListData.length;

        this.roleListDataALL = this.roleListData;

        this.roleListData.forEach((item) => {
          item.Action = item.IsActivate == 0 ? '0,1' : "2";
          item.IsEditable = 1;
        });

        this.setActionButtonPermissions();

        setTimeout(() => {
          if (!isNullOrUndefined(this.overallgrid)) {
            this.overallgrid.Pagination.CurrentPage = 1;
          }
        }, 50);

      }
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
        //this._toastr.warning(result.Message, this.CompTitle);
      }

      this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);
  }

  

  ReloadBUHRData(): void {
    this.Title = "BU HR Upload";
    this.SelectedTab = "BUHRList";
    this.getRoleTemplate();
  }

  pageItemsChange() {

    if (this.curItemsPerPage > 0) {
      this.GridProp.PaginationConfig.ItemsPerPage = this.curItemsPerPage;
    }
    else {
      this.GridProp.PaginationConfig.ItemsPerPage = 100;
    }
    // reload grid data
    this.BUHRData();
  }
 
  onChange(event: any): void {
    let selitem: any = event.item;
    event.item.IsModified = 1;
  }

  actButtonClick(event: any) {
    switch (event.buttonname.toUpperCase()) {
      case 'EXPORTTOEXCEL':
        this.exportToExcel();
        break;
      case 'FILTER':
        this.showFilter();
        break;

    }
  }

  SearchData(event: any): void {

    console.log(event)

    if (!isNullOrUndefined(event.action) && event.action.toUpperCase() === 'SEARCH' && event.item.UserId.length < 3) {

      this.notify = {
        info: {
          header: 'Search Alert',
          message: 'Please provide minimum 3 characters for user field.',
          yes: '',
          no: '',

          IsHeaderHidden: true,
          HeaderIcon: 'fa fa-exclamation-circle text-warning',
          IsCrossEnable: true,
          SizeClass: 'modal-sm'
        },

        action: '',
        item: null
      };

      this.displayNotifybox = true;

      return;
    }
    


    // let subscribe$: Subscription = this.adminService.getMasterReportDetails(this.FilterData).subscribe(result => {
    //   if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

    //     this.repListData = result.Data;
        

    //   }
    //   if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
    //   }

    //   this.IsLoading = false;

    // },

    //   err => {
    //     this.IsLoading = false;
    //   });

    // this.subscriptionList$.push(subscribe$);
    // console.log(this.subscriptionList$);


  }

  exportToExcel() {

    if (this.roleListData.length === 0) {
      // this.toastr.warning("Report Data is empty");
    }
    else {
      this.IsLoading = true;

      let month = new Date().getMonth() + 1;
      this.ExcelFileName = 'BGV-BUHR-Details' + new Date().getDate() + '-' + month + '-' + new Date().getFullYear() + ' ' + new Date().toLocaleTimeString()

      let tempdata = [];
      // tempdata.push({ Name: 'Master Data', Data: this.roleListData });
      tempdata.push({
        Name: 'BUHR Data', Data: this.roleListData
        , ColumnList: !isNullOrUndefined(this.Config.DownloadColumns) && this.Config.DownloadColumns.length > 0 ? this.Config.DownloadColumns.map((x: any) => x.FieldName) : null
        , FieldTemplate: this.roleListTemplate.filter(x => x.Group === 'BUHRListResult')
      });

      this.excelexport.generateExcelTable_fromJSON(tempdata, this.ExcelFileName);

      this.IsLoading = false;
    }
  }

  showFilter(event?: any): void {
    this.DispFilter = !this.DispFilter;

    if (!isNullOrUndefined(event)) {
      this.curFiltCondn = event.curFiltCondn;
    }
  }

  setFilter(event: any) {
    if (event.sCondn.length > 0) {
      this.roleListData = asEnumerable(this.roleListDataALL)
        .Where($ => (eval(event.sCondn)))
        .ToArray();
      this.dataTotalCount = this.roleListData.length;
    }
    else {
      this.roleListData = this.roleListDataALL;
      this.dataTotalCount = this.roleListData.length;
    }
  }

  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item
    };

    this.displayNotifybox = true;
  }
  handleUpload(e: any): void {
    if (e.target.files && e.target.files[0]) {
        let formData: FormData = new FormData();
        let filesToUpload = <Array<File>>e.target.files;
        let filenames: string = '';
        for (let i = 0; i < filesToUpload.length; i++) {
            formData.append('uploadedFiles', filesToUpload[i], filesToUpload[i].name);
            filenames = filenames + '; ' + filesToUpload[i].name;
            this.selFileNames = filenames.substr(2, filenames.length);
            this.AttachedFiles = formData;
        }

        // this.selFileNames = filesToUpload[0].name;

        // let reader = new FileReader();
        // reader.onload = (event: any) => {
        //     this.AttachedFiles = event.target.result;
        // }
        // reader.readAsDataURL(e.target.files[0]);
    }
    else {
        this.selFileNames = '';
        this.AttachedFiles = null;
    }

    // e.target.value = '';
}
downloadExcelfile(): void {
  let subscribe$: Subscription = this.adminService.downloadSampleExcel().subscribe(result => {
     
    if (!isNullOrUndefined(result)) {
        let url = window.URL.createObjectURL(result.data);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
         a.download = "BUHRSampleExcel.xlsx";
        //a.download =    data.FileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
        // this._toastr.success('Resume downloaded successfully', this.CompTitle);

        this.notify = {
            info: {
                header: 'Confirmation Alert',
                message: 'Document downloaded successfully',
                yes: '',
                no: '',

                IsHeaderHidden: true,
                HeaderIcon: 'fa fa-check-circle text-success',
                IsCrossEnable: true,
                SizeClass: 'modal-sm'
            },

            action: '',
            item: null
        };

        this.displayNotifybox = true;
    }
    this.IsLoading = false;

},
    err => {
        this.IsLoading = false;
    });
this.subscriptionList$.push(subscribe$);
}

uploadFiles(): void {
  this.filesToUpload = [];
  if (!isNullOrUndefined(this.AttachedFiles)) {


    this.filesToUpload.push(this.selFileNames.split(';'));
      console.log(this.filesToUpload[0]);
      if (this.filesToUpload[0].length > 1) {
          this.selFileNames = this.filesToUpload[0].toString();
          this.selFileNames = this.selFileNames.replace(/\, /g, '-')

          console.log(this.selFileNames)
          if (!isNullOrUndefined(this.selFileNames) && this.selFileNames !== '' && !this.validateFileName(this.selFileNames.split('.').slice(0, -1).join('.'))) {
              this.IsLoading = true;

              this.uploadSuccess = false;

              this.adminService.uploadBUHRExcel(this.AttachedFiles).subscribe(res => {
                  if (res !== null && res.Status === 200) {
                      // alert('file Upload success');
                      this.notify = {
                          info: {
                              header: 'Confirmation Alert',
                              message: 'File uploaded successfully.',
                              yes: '',
                              no: '',

                              IsHeaderHidden: true,
                              HeaderIcon: 'fa fa-check-circle text-success',
                              IsCrossEnable: true,
                              SizeClass: 'modal-sm'
                          },

                          action: 'UPLOAD_SUCCESS',
                          item: res.Data
                      };

                      this.displayNotifybox = true;

                      this.selFileNames = '';
                      this.uploadSuccess = true;
                      this.ReloadBUHRData();
                  }
                  else {
                      this.notify = {
                          info: {
                              header: 'Confirmation Alert',
                              message: res.Message,
                              yes: '',
                              no: '',

                              IsHeaderHidden: true,
                              HeaderIcon: 'fa fa-exclamation-circle text-warning',
                              IsCrossEnable: true,
                              SizeClass: 'modal-sm'
                          },

                          action: '',
                          item: null
                      };

                      this.displayNotifybox = true;

                  }
                  this.IsLoading = false;
              },
                  err => {
                      // this.toastr.error('Error on file upload.', this.CompTitle);
                      this.IsLoading = false;
                  });

          }
          else {
              this.notify = {
                  info: {
                      header: 'Confirmation Alert',
                      message: 'Invalid file name. File name should not contain whitespaces.',
                      yes: '',
                      no: '',

                      IsHeaderHidden: true,
                      HeaderIcon: 'fa fa-exclamation-circle text-warning',
                      IsCrossEnable: true,
                      SizeClass: 'modal-sm'
                  },

                  action: '',
                  item: null
              };

              this.displayNotifybox = true;
          }
      }
   
          console.log(this.selFileNames)
          if (!isNullOrUndefined(this.selFileNames) && this.selFileNames !== '' && !this.validateFileName(this.selFileNames.split('.').slice(0, -1).join('.'))) {
              this.IsLoading = true;

              this.uploadSuccess = false;

              this.adminService.uploadBUHRExcel(this.AttachedFiles).subscribe(res => {
                  if (res !== null && res.Status === 200) {
                      // alert('file Upload success');
                      this.notify = {
                          info: {
                              header: 'Confirmation Alert',
                              message: 'File uploaded successfully.',
                              yes: '',
                              no: '',

                              IsHeaderHidden: true,
                              HeaderIcon: 'fa fa-check-circle text-success',
                              IsCrossEnable: true,
                              SizeClass: 'modal-sm'
                          },

                          action: 'UPLOAD_SUCCESS',
                          item: res.Data
                      };

                      this.displayNotifybox = true;

                      this.selFileNames = '';
                      this.uploadSuccess = true;
                      this.ReloadBUHRData();
                      // if (!isNullOrUndefined(this.Module) && this.Module !== '') {
                      //     // if (this.Module === 'interm') {
                      //     //     this.reloadAttach.emit({ 'Reload': true, 'data': res.Data });
                      //     // } else if (this.Module === 'Document') {
                      //     // this.reloadAttach.emit({ 'Reload': true, 'data': res.Data });
                      //     this.close.emit({ 'Reload': true, 'data': res.Data });
                      //     // }

                      // }

                  }
                  else {
                      this.notify = {
                          info: {
                              header: 'Confirmation Alert',
                              message: res.Message,
                              yes: '',
                              no: '',

                              IsHeaderHidden: true,
                              HeaderIcon: 'fa fa-exclamation-circle text-warning',
                              IsCrossEnable: true,
                              SizeClass: 'modal-sm'
                          },

                          action: '',
                          item: null
                      };

                      this.displayNotifybox = true;

                  }
                  this.IsLoading = false;
              },
                  err => {
                      // this.toastr.error('Error on file upload.', this.CompTitle);
                      this.IsLoading = false;
                  });

          }
          else {
              this.notify = {
                  info: {
                      header: 'Confirmation Alert',
                      message: 'Invalid file name. File name should not contain whitespaces.',
                      yes: '',
                      no: '',

                      IsHeaderHidden: true,
                      HeaderIcon: 'fa fa-exclamation-circle text-warning',
                      IsCrossEnable: true,
                      SizeClass: 'modal-sm'
                  },

                  action: '',
                  item: null
              };

              this.displayNotifybox = true;
          }
      

  }
  else {
      // this.toastr.warning('Kindly select a file for upload.', this.CompTitle);
      this.notify = {
          info: {
              header: 'Confirmation Alert',
              message: 'Kindly select a file for upload.',
              yes: '',
              no: '',

              IsHeaderHidden: true,
              HeaderIcon: 'fa fa-exclamation-circle text-warning',
              IsCrossEnable: true,
              SizeClass: 'modal-sm'
          },

          action: '',
          item: null
      };

      this.displayNotifybox = true;
  }
}

validateFileName(fileName: any): boolean {

  var specialChars = "<>[]{}?:;|'\"\\,/`=";
  //"<>@!#$%^&*()_+[]{}?:;|'\"\\,./~`-=";
  //   let i: number = 0;

  for (let i = 0; i < specialChars.length; i++) {
      if (fileName.indexOf(specialChars[i]) > -1) {
          return true
      }
  }

  return false;
}


setNotifyRes(event: any): void {
  console.log('notify res >> ' + JSON.stringify(event));
  
  this.closeNotifybox();
}

closeNotifybox(): void {
  this.displayNotifybox = false;

  if (this.uploadSuccess) {
      this.close.emit({ 'Reload': true, 'data': this.notify.item });
  }

}
}
