import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
// import { FieldTemplate } from 'form-lib';
// import { FieldTemplate } from '../modules/shared/_classes/Elio.interface';
import { FieldTemplate } from '../../modules/shared/_classes/utility.interface';
import { ComponentConfig, ProcessResponse } from '../app.interface';
// import { isNullOrUndefined } from 'util';
import { isNullOrUndefined } from './app.sharedservice';
import { AppSharedService } from './app.sharedservice';


@Injectable()
export class CommonAPIService {
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    private APIUrl: string;
    // private EventUrl: string;
    
    constructor(private _http: HttpClient, private _appSharedService: AppSharedService) {
        this.APIUrl = environment.apiURL.UIConfigURL;
    
        // this.EventUrl = environment.eventURL + '/Event/';
    }

    // ngOnDestroy() {
    // }

    private handleError(error: any): Promise<any> {
        console.error('Error : ', error.message);
        return Promise.reject(error.message || error);
    }

    getFieldTemplate(reqdata: any): Observable<ProcessResponse<FieldTemplate[]>> {
        // GetFieldTemplate(reqdata: any): Observable<ProcessResponse> {
        let Params = new HttpParams();
        // Params = Params.append('Config', reqdata);

        // Params = Params.append('OrgId', reqdata.OrgId);
        // Params = Params.append('AppId', reqdata.AppId);
        Params = Params.append('Component', reqdata.ComponentName);
        Params = Params.append('TemplateName', reqdata.TemplateName);
        Params = Params.append('TemplateType', reqdata.TemplateType);
        Params = Params.append('ReferenceValue', reqdata.ReferenceValue);
        Params = Params.append('ReferenceValueType', reqdata.ReferenceValueType);

        return this._http.get<ProcessResponse<FieldTemplate[]>>(this.APIUrl + 'GetFieldTemplate',
            { headers: this.headers, params: Params })
            // { headers: this.headers, params: Params, withCredentials: true })
            // return this._http.get<ProcessResponse>(this.APIUrl + 'GetFieldTemplate', { headers: this.headers, params: Params })
            // .catch(this.handleError);
            .pipe(
                catchError(this.handleError)
            );

    }

    getComponentConfig(reqdata: any): Observable<ProcessResponse<ComponentConfig>> {
        console.log(reqdata);
        let Params = new HttpParams();
        // Params = Params.append('AppId', reqdata.AppId);
        Params = Params.append('Component', reqdata.Component);
        Params = Params.append('SubComponent', reqdata.SubComponent);
        Params = Params.append('ReferenceValueType', reqdata.ReferenceValueType);
        Params = Params.append('ReferenceValue', reqdata.ReferenceValue);
        Params = Params.append('ComponentCode', reqdata.ComponentCode);

        return this._http.get(this.APIUrl + 'getComponentConfig', { headers: this.headers, params: Params })
            // .pipe(map((result: ProcessResponse<ComponentConfig>) => {
            .pipe(map((result: any) => {

                // Creating full object for FieldTemplate since the server returns only partial object
                if (!isNullOrUndefined(result.Data.FieldTemplate) && result.Data.FieldTemplate.length > 0) {
                    // console.log('template partial >> ' + JSON.stringify(result.Data.FieldTemplate));
                    let fieldtmplt = new Array<FieldTemplate>();
                    result.Data.FieldTemplate.forEach((field: FieldTemplate) => fieldtmplt.push(new FieldTemplate(field)));
                    result.Data.FieldTemplate = fieldtmplt;
                    // console.log('template full >> ' + JSON.stringify(result.Data.FieldTemplate));
                }

                return result;
            }))
            .pipe(
                catchError(this.handleError)
            );

    }

    async getComponentConfig_1(reqdata: any): Promise<ProcessResponse<ComponentConfig>> {
        console.log(reqdata);
        let Params = new HttpParams();
        // Params = Params.append('AppId', reqdata.AppId);
        Params = Params.append('Component', reqdata.Component);
        Params = Params.append('SubComponent', reqdata.SubComponent);
        Params = Params.append('ReferenceValueType', reqdata.ReferenceValueType);
        Params = Params.append('ReferenceValue', reqdata.ReferenceValue);
        Params = Params.append('ComponentCode', reqdata.ComponentCode);

        let response: any = null;
        response = await this._http.get(this.APIUrl + 'getComponentConfig', { headers: this.headers, params: Params })
            .toPromise()
            // .then((result: ProcessResponse<ComponentConfig>) => {
            .then((result: any) => {

                // Creating full object for FieldTemplate since the server returns only partial object
                if (!isNullOrUndefined(result.Data.FieldTemplate) && result.Data.FieldTemplate.length > 0) {
                    // console.l`og('template partial >> ' + JSON.stringify(result.Data.FieldTemplate));
                    let fieldtmplt = new Array<FieldTemplate>();
                    result.Data.FieldTemplate?.forEach((field: FieldTemplate) => fieldtmplt.push(new FieldTemplate(field)));
                    result.Data.FieldTemplate = fieldtmplt;
                    response = result;
                    // console.log('template full >> ' + JSON.stringify(result.Data.FieldTemplate));
                }

            })
            .catch(
                catchError(this.handleError)
            );

        return response;

    }

    // generateEventTaskAndExecute(eventId: string): Observable<ProcessResponse<any>> {
    //     let Params = new HttpParams();
    //     // Params = Params.append('OrgId', reqdata.OrgId);
    //     Params = Params.append('userId', this._appSharedService.UserAccessInfo.actinguserid);
    //     Params = Params.append('eventId', eventId);
    //     Params = Params.append('canForceExecute', '0');

    //     return this._http.post<ProcessResponse<any>>(this.EventUrl + 'GenerateEventTaskAndExecute?userId='
    //         + this._appSharedService.UserAccessInfo.actinguserid +
    //         '&eventId=' + eventId, { headers: this.headers, params: Params })
    //         .pipe(
    //             catchError(this.handleError)
    //         );
    // }

    getLanguage(): Observable<ProcessResponse<any>> {
        let Params = new HttpParams();
        return this._http.get<ProcessResponse<any>>(this.APIUrl + 'GetCodesList?codetype=Language',
            { headers: this.headers, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }

    getUserRole(empId: number): Observable<ProcessResponse<any>> {
        let Params = new HttpParams();
        Params = Params.append('EmployeeId', empId);
        return this._http.get<ProcessResponse<any>>(this.APIUrl + 'GetUserRoleList',
            { headers: this.headers, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }

    // async getUserRole(empId: number): Promise<ProcessResponse<any>> {
    //     let Params = new HttpParams();
    //     Params = Params.append('EmployeeId', empId);

    //     let response = await this._http.get<ProcessResponse<any>>(this.APIUrl + 'GetUserRoleList',
    //         { headers: this.headers, params: Params }).toPromise();

    //     if (!isNullOrUndefined(response) && !isNullOrUndefined(response.Data)) {
    //         this._appSharedService.roleData.RoleId = response.Data[0].RoleId;
    //         this._appSharedService.roleData.RoleDetails = response.Data;
    //     }

    //     return response;
    // }

    GetReleaseHeader(): Observable<ProcessResponse<any>> {
        let Params = new HttpParams();
        return this._http.get<ProcessResponse<any>>(this.APIUrl + 'GetApplicationReleaseHeader',
            { headers: this.headers, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }
}
