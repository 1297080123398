import { escapeRegExp } from '@angular/compiler/src/util';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActionCode, BGVStatus, ComponentCodes, ComponentConfig, ProcessStatus, StatusColorCode , RoleCodes } from 'src/app/app.interface';
import { CommonAPIService } from 'src/app/shared/app.commonservice';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined } from 'src/app/shared/app.sharedservice';
import { environment } from 'src/environments/environment';
import { GridComponent } from 'src/modules/grid/grid.component';
import { ModalService } from 'src/modules/modal/modal.service';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { RequestService } from '../request.service';
import { AttachmentInfo, UploadAttachInfo } from 'src/modules/attachment/attachment.interface';
import { Buffer } from 'buffer';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';



@Component({
    selector: 'bu-hr',
    templateUrl: './bu-hr.component.html',

})
export class BuHRComponent implements OnInit {
    @Input() RequestId: number = 0;
    @Input() SelectedTabName: string = '';
    @Input() RequestData: any;
    @Input() Permissions: any;
    @Output() close = new EventEmitter<any>();

    @Output() RefreshRequest = new EventEmitter<any>();
    @ViewChild('additionalsectiongrid', { static: false }) additionalsectiongrid!: GridComponent;
    public BUHRTemplate: FieldTemplate[] = [];
    public AttachmentData!: AttachmentInfo[];

    public selFileNames: string = '';
    public filesToUpload: any = [];
    public AttachedFiles: any = null;
    public uploadSuccess: boolean = false;
    public SubmitDetailsTemplate: FieldTemplate[] = [];
    public BUHRPermission: any = [];
    public BUHRProperties: any = [];
    public BUHRData: any[] = [];
    public BUHRFilterData: any[] = [];
    public BUHRSectionData: any[] = [];
    public subscriptionList$: Subscription[] = [];
    public openBUHRSection: boolean = false;
    public BUHRAdditionalSectionData: any[] = [];
    public actionCode: string = '';
    public sectionName: string = '';
    public sectionDisplayName: string = '';
    public DisplayAttachment: boolean = false;
    public transId: number = 0;
    public fileData: any[] = [];
    public fileRefId: any[] = [];
    public fileNames: any[] = [];
    public Emailbuttons: any = [];
    public rows: number = 20;
    public NonGreenTemplate: FieldTemplate[] = [];
    public NonGreenProperties: any = [];
    public openNonGreenSection: boolean = false;
    public NonGreenFormData: any = {};
    public i:number=0;
    public count:number=0;
    public sectionDataSummary: any[] = []

    public raisel3modifieditems: any=[];
    public isResponsetoEmployee:boolean=false;
    public VerifiedComponentsList: any[] = [];

    public actionCodes: any = {
        'Forward': 'ACM003',
        'Resend': 'ACM004',
        'Create_Email': 'AC009',
        'RaiseL3':'AC009', 
        'Insufficiency_Clear_By_BUHR' :'AC012',
        'Responsetoemployee':'AC009',       
        'SendToVendor':'AC008',
        'FOLLOWUPMAIL':'REM041',
        'SendToCentralHR':'AC007',
        'SHOWCAUSENOTICE':'REM037',
        'SendToBGVHead':'AC033',
        'TerminationApprovalToBGVHead':'AC036',
        'SendToBUHR':'AC010',
        'CloseCase':'AC014'
         
      }
      public selectedTab: string = 'CreateEmail';
      @Input() RequestProperty: any = [];
      public selectedInfoData: any = {};
      public viewEmailTemp: boolean = false;
      public viewEmail: boolean = false;
      @Input() RequestTemplate: FieldTemplate[] = [];
      public viewEmailData: any[] = [];
      public selectedEmailData: any = {};
      public successFlag: boolean = false;
      public selectedEmailActionData: any = {};
      public ACTION: string = '';
      public Tomail: string = '';
      public Ccmail: string = '';
      public Mailsubject: string = '';
      public MailMessage: string = '';
      public vendorsubmitmodifieditems:any=[];
      public BGVHeadModifiedItems:any[]=[];
      public NonGreenData: any[] = [];
      public submitSecionName:string='';
      public verifiedSecionName:string='';
    public notify: NotificationData = new NotificationData();
    public sectionData: any[] = []
    public GridProp: any = {
        Editable: true,
        IsSortable: false
    };
    public AttachPerm: any = {
        Upload: true,
        Download: false
    };
    public IsLoading: boolean = false;
    public IsReadonly: boolean = false;
    public displayNotifybox: boolean = false;
    public NonGreenAdditionalSectionData: any[] = [];
    public NonGreenPermission: any = [];

    public displaySLANotifybox: boolean = false;
    public insufficiencyClearData: any;
    //declaration for client reference number modal popup
    public RevisedSLADateTemplate: any[] = [];
    public RevisedSLADateData: any = {
        RequestId: 0,
        RevisedSLADate: new Date(),
        IsEditable: 1
    };

    //declaration for client reference number modal popup
    public InsufficiencyClearanceTemplate: any[]=[];
    public LWDTemplate: any[] = [];
    public LWDData: any = {
        RequestId: 0,
        LastWorkingDay: new Date(),
        IsEditable: 1
    };

    
    public LWDFormProp: FormProperty = {
        'ColSize': 1,
        'CaptionBold': true,
        'IsEditable': true
    };

    public LWDFormConfig: any = {
        BaseUrl: environment.baseURL
    };

    public INSFormProp: FormProperty = {
        'ColSize': 1,
        'CaptionBold': true,
        'IsEditable': true
    };

    public BUHRFormData: any = {};
    public GlobalConfig: any = {};
    public FormConfig: any = {
      BaseUrl: environment.baseURL
    };
    public SubmittedDetailsList: any[] = []; 
    
    public FormProp: FormProperty = {
        // 'ColSize': 2,
        // 'CaptionTop': false,  
        // 'IsEditable': false,
        'ColSize': 1,
        'CaptionTop': true,
        'CaptionBold': true,  
        'IsEditable': false,
        'ShowControlAlways': true
     
      };
      public EmailFormProp: FormProperty = {
        // 'ColSize': 2,
        // 'CaptionTop': false,  
        // 'IsEditable': false,
        'ColSize': 1,
        'CaptionTop': true,
        'CaptionBold': true,  
        'IsEditable': true,
        'ShowControlAlways': true
     
      };
      public SubmitFormProp: FormProperty = {
        'ColSize': 2,
        'CaptionBold': false,
        'CaptionTop': true,
        'IsEditable': true,
        'ShowControlAlways': true,
        
      };
      public CommentFormProp: FormProperty={
        'ColSize': 2,
        'CaptionBold': false,
        'CaptionTop': false,
        'IsEditable': true,
        'ShowControlAlways': true,
      };
    constructor(private eleRef: ElementRef, private _requestService: RequestService, private _commonAPIService: CommonAPIService
        , private _appSharedService: AppSharedService, private modalService: ModalService) {
        let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
            this.ngOnInit();
        });
        this.subscriptionList$.push(subscribe$);
    }

    ngOnInit(): void {
        if (!isNullOrUndefined(this._appSharedService.currentRoleData)) {
            if (this.RequestData.IsStopCaseSubmitted === true || this.RequestData.IsStopCase === true) {
                this.GridProp.Editable = false;
                this.IsReadonly = true;
            }
            this.getBUHRTemplate();
            //this.getNonGreenTemplate();
            let today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

            this.GlobalConfig = {
                LWDStartDate: today
            };
            this.rows = (this.FormProp.rows === undefined ? this.rows : this.FormProp.rows);
            this.BUHRProperties.Emailbuttons = JSON.parse(`[{"Name":"Send","CustomClass":"rounded-pill btnCustom btnPrimary small float-right","DisplayName":"Send","AccessKey":"S","ShowArrow":true,"IsApplicable":true,"HasPermission":true,"IsEnabled":true,"Confirmation":{"IsEnabled":false,"Message":null,"Action":null},"SeqOrder":1},{"Name":"Cancel","CustomClass":"rounded-pill btnCustom btnNegative small float-right","DisplayName":"Cancel","AccessKey":"S","ShowArrow":false,"IsApplicable":true,"HasPermission":true,"IsEnabled":true,"Confirmation":{"IsEnabled":false,"Message":null,"Action":null},"SeqOrder":2}]`)
            this.Emailbuttons = JSON.parse(`[
                {
                   "Name":"Attachment",
                   "DisplayName":"Attachment",
                   "CustomClass":" btnCustom btnNegative small float-left",
                   "AccessKey":"N",
                   "IsApplicable":true,
                   "HasPermission":true,
                   "IsEnabled":true,
                   "Confirmation":{
                      "IsEnabled":false,
                      "Message":null,
                      "Action":null
                   },
                   "SeqOrder":1
                }
           
             ]`); 

        }
        if (isNullOrUndefined(this.Permissions)) {
            this.Permissions = { Upload: true, Download: true };
        }

    }

    closeUploader(): void {
        this.close.emit({ 'Reload': false });
    }
    getBUHRTemplate(): void {

        this.IsLoading = true;
        let odata: ComponentConfig = {
            Component: 'BUHR', ComponentCode: ComponentCodes.BUHR
        };

        let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
            if (res !== undefined && res !== null && res.Status === 200) {
                this.BUHRTemplate = res.Data.FieldTemplate!;
                this.BUHRPermission = res.Data.Permission;
                this.setCompProperties(res.Data.Properties!);
                this.InsufficiencyClearanceTemplate = this.BUHRTemplate.filter(x=>x.Group==='CreateEmail_form');
                this.NonGreenTemplate = res.Data.FieldTemplate!;

                this.SubmitDetailsTemplate = this.BUHRTemplate.filter(x=>x.Group==='BUHRSubmit_Template');
                this.LWDTemplate = JSON.parse(`[{"FieldName":"LastWorkingDay","DisplayName":"Last Working Date","LabelTemplate":null,"LinkedField":null,"ColumnWidth":"20","Control":{"Type":"datepicker","InputType":null,"List":null,"Source":{"Url":null,"KeyField":null,"ValueField":null,"UrlAttribute":null,"Target":null},"DependentControl":null,"Format":"dd.MM.yyyy","LoadedUrl":null,"DynamicLoad":false},"Cssnames":"","Style":"","Events":"","Editable":1,"IsHidden":false,"ToolTipTemplate":null,"EditValidate":{"Required":false,"Min":"$Global.LWDStartDate","Max":"12-31-2100"},"FieldCollection":null,"Group":"FORM_BUHR","ResponsiveMediaSize":"md,lg,xl"}]`);
                this.RevisedSLADateTemplate = JSON.parse(`[{"FieldName":"RevisedSLADate","DisplayName":"Revised SLA Date","LabelTemplate":null,"LinkedField":null,"ColumnWidth":"20","Control":{"Type":"datepicker","InputType":null,"List":null,"Source":{"Url":null,"KeyField":null,"ValueField":null,"UrlAttribute":null,"Target":null},"DependentControl":null,"Format":"dd.MM.yyyy","LoadedUrl":null,"DynamicLoad":false},"Cssnames":"","Style":"","Events":"","Editable":1,"IsHidden":false,"ToolTipTemplate":null,"EditValidate":{"Required":false,"Min":null,"Max":null},"FieldCollection":null,"Group":"FORM_SLADate","ResponsiveMediaSize":"md,lg,xl"}]`);
                console.log(res.Data.Properties!);
                this.getCheckDocumentDetailsList();

                //this.setDefaultPageFocus();
                this.getApprovalDetailsForCHRAndBGVHead();
                this.getSubmittedDetails();
                this.getMailerTransaction();
                this.getBasicDetails();

                this.selectedInfoData.IsEditable = 1;
            }
        },
            err => {
                this.IsLoading = false;
                console.log('Error on getBUHRTemplate');
            });
        this.subscriptionList$.push(subscribe$);
    }

    setCompProperties(Properties: any[]) {
        if (!isNullOrUndefined(Properties) && Properties.length > 0) {
            console.log(this.BUHRProperties)
            // 2) Blocking Cols based on custom values
            this.BUHRProperties.BlockCols = (Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase()).length > 0 ?
                JSON.parse(Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase())[0].JSONData.toString()) : []);

            // Other component specific props
            Properties.forEach(prop => {
                // avoiding generic props
                if (!isNullOrUndefined(prop.Name) &&
                    prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
                    && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
                    this.BUHRProperties[prop.Name] = JSON.parse(prop.JSONData.toString());
                }
            });
        }
    }
    getCheckDocumentDetailsList() {
        // this.IsLoading = true;

        let odata: object = { 'requestId': this.RequestId, 'checkId': 0 };

        let subscribe$: Subscription = this._requestService.getCheckDocumentDetailsList(odata).subscribe(result => {
            if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
                this.BUHRData = result.Data;

                this.BUHRData.forEach(item => {
                    if ((item.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_1
                        // || item.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_2
                        // || item.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_3
                        )
                        && (item.ColorCode === StatusColorCode.Red || item.ColorCode === StatusColorCode.Amber)) {
                        item.BUHRStatusCode = null;
                        item.BUHRStatusDesc = null;
                        item.BUHRComment = null;
                    }

                    item.FileAction = item.FileReferenceId > 0 && item.IsEditable == 1 ? '0' : '';
                });


                if (!isNullOrUndefined(this.BUHRData) && this.BUHRData.length > 0
                    && this.BUHRData.filter(x => (x.UploadStatus === BGVStatus.Non_Green_Terminated
                        // || x.UploadStatus === BGVStatus.Non_Green_On_hold_by_BUHR_1
                        // || x.UploadStatus === BGVStatus.Non_Green_On_hold_by_BUHR_2
                        // || x.UploadStatus === BGVStatus.Non_Green_On_hold_by_BUHR_3
                         || x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_1
                         || x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_2         
                        || x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_3
                        || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_1
                        // || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_2
                        // || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_3
                        )).length > 0) {

                    // if (this.BuHRData.filter(x => x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination).length > 0) {
                    this.BUHRFilterData.push(this.BUHRData.filter(x => (x.UploadStatus === BGVStatus.Non_Green_Terminated
                        // || x.UploadStatus === BGVStatus.Non_Green_On_hold_by_BUHR_1
                        // || x.UploadStatus === BGVStatus.Non_Green_On_hold_by_BUHR_2
                        // || x.UploadStatus === BGVStatus.Non_Green_On_hold_by_BUHR_3
                         || x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_1
                         || x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_2
                        || x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_3
                        || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_1
                        // || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_2
                        // || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_3
                        ))[0]);
                    // }else if(){

                    // }

                } else {
                    this.BUHRFilterData = [];
                }
                // this.BUHRFilterData.push(this.BUHRData.filter(x => (x.IsEditable === 1 || x.UploadStatus === BGVStatus.Non_Green_Terminated))[0]);
                this.setActionButtonPermissions();
                this.enableDisableButtons();
                this.BuHRAdditionalSectionData();
                this.getNonGreenadditionalSectionList();

            }
            // this.IsLoading = false;
        },
            err => {
                console.log('Error on getting getCheckDocumentDetailsList.');
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }




    BuHRAdditionalSectionData() {
        // this.IsLoading = true;

        let odata: object = { 'requestId': this.RequestId, 'checkId': null };

        let subscribe$: Subscription = this._requestService.getNonGreenadditionalSectionList(odata).subscribe(result => {
            if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
                this.BUHRAdditionalSectionData = result.Data;
            }
            this.IsLoading = false;
        },
            err => {
                console.log('Error on getting BUHRAdditionalSectionData.');
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }

    setActionButtonPermissions(): void {
        if (!isNullOrUndefined(this.BUHRProperties.ActionButtons) && this.BUHRProperties.ActionButtons.length > 0
            && !isNullOrUndefined(this.BUHRFilterData) && this.BUHRFilterData.length > 0) {

            this.BUHRProperties.ActionButtons.forEach((item: any) => {

                if (!isNullOrUndefined(item.Name) && item.Name !== '') {
                    item.HasPermission = false;
                    item.IsApplicable = false;
                    let peritem = this.BUHRPermission.indexOf(item.Name);
                    let peritem1 = this.NonGreenPermission.indexOf(item.Name);

                    if (peritem >= 0) {
                        item.HasPermission = true;
                    }
                    if (peritem1 >= 0) {
                        item.HasPermission = true;
                    }
                    switch (item.Name.toUpperCase()) {
                        case 'AGREEFORTERMINATION':
                            // item.IsApplicable = this.enableCloseCase();
                            item.IsApplicable = true;
                            break;
                        case 'ASSIGNTONONGREEN':
                            //   item.IsApplicable = this.enableSubmitCase();
                            item.IsApplicable = true;
                            break;
                        case 'BUHRONHOLD':
                            //   item.IsApplicable = this.enableSubmitCase();
                            item.IsApplicable = true;
                            break;

                    }
                }
            });
        }
    }


    SubmitBUHR(modifieditems: any, msg: string) {
        // if (this.validateMandatoryFields(modifieditems)) {
        modifieditems.forEach((item: any) => {
           // let bUHRComments = this.BUHRFilterData.filter((x: any) => (!isNullOrUndefined(x.BUHRComment)) && x.BUHRComment !== '')[0];
            item.BUHRComments =this.BUHRFormData.BUHRComments; //bUHRComments.BUHRComments;
            item.ActionCode = this.actionCode;
            item.IsClosed = this.actionCode === ActionCode.BUHR_Agree_for_Termination ? true : false;
        });
        this.IsLoading = true;
        let subscribe$: Subscription = this._requestService.SubmitBUHR(modifieditems).subscribe(result => {
            if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

                // this.showNotificationMessage(msg, true);
                this.showNotificationMessage(msg, 1, '', null, '', '');
                // this.getCheckDocumentDetailsList();

                this.refreshRequest();

            }
            // this.IsLoading = false;
        },

            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
        // }
    }

    showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string) {
        let headerIcon: string = '';

        if (successFlag === 1) {
            headerIcon = 'fa fa-check-circle text-success';
        } else if (successFlag === 2) {
            headerIcon = 'fa fa-exclamation-circle text-warning';
        } else if (successFlag === 3) {
            headerIcon = 'fa fa-exclamation-triangle text-danger';
        } else {
            headerIcon = 'fa fa-info-circle text-info';
        }

        this.notify = {
            info: {
                header: 'Confirmation Alert',
                message: msg,
                yes: yes,
                no: no,

                IsHeaderHidden: true,
                HeaderIcon: headerIcon,
                IsCrossEnable: true,
                SizeClass: 'modal-sm'
            },

            action: action,
            item: item
        };

        this.displayNotifybox = true;
    }

    showSLANotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string) {
        let headerIcon: string = '';

        if (successFlag === 1) {
            headerIcon = 'fa fa-check-circle text-success';
        } else if (successFlag === 2) {
            headerIcon = 'fa fa-exclamation-circle text-warning';
        } else if (successFlag === 3) {
            headerIcon = 'fa fa-exclamation-triangle text-danger';
        } else {
            headerIcon = 'fa fa-info-circle text-info';
        }

        this.notify = {
            info: {
                header: 'Confirmation Alert',
                message: msg,
                yes: yes,
                no: no,

                IsHeaderHidden: true,
                HeaderIcon: headerIcon,
                IsCrossEnable: true,
                SizeClass: 'modal-sm'
            },

            action: action,
            item: item
        };

        this.displaySLANotifybox = true;
    }

    refreshRequest(): void {
        this.RefreshRequest.emit({ 'requestId': this.RequestId });
    }

    validateMandatoryFields(modifieditems: any[]): boolean {
        let valid: boolean = true;

       
        if (isNullOrUndefined(modifieditems) || modifieditems.length === 0) {
            // this.showNotificationMessage('No rows are modified.', false);
            this.showNotificationMessage('No rows are modified.', 2, '', null, '', '');

            valid = false;
        }

        if (!isNullOrUndefined(modifieditems) && modifieditems.length > 0) {
            modifieditems.forEach((item: any) => { 
                item.BUHRComment=this.BUHRFormData.BUHRComment; //filter((x: any) => x.BGVHeadComment === 1);
            });
            // let statusList = modifieditems.filter(x => ((isNullOrUndefined(x.BUHRStatusCode)) || x.BUHRStatusCode === '') && (!isNullOrUndefined(x.BUHRComment) && x.BUHRComment !== ''));

            // if (!isNullOrUndefined(statusList) && statusList.length > 0) {
            //     // this.showNotificationMessage('Please select non green status for modified rows.', false);
            //     this.showNotificationMessage('Please select BU HR status for modified rows.', 2, '', null, '', '');
            //     valid = false;
            // }

            //Comments Check
            let emptyComments = modifieditems.filter(x => ((isNullOrUndefined(x.BUHRComment)) || x.BUHRComment === ''));

            if (!isNullOrUndefined(emptyComments) && emptyComments.length > 0 && valid) {
                // this.showNotificationMessage('Please provide comments for modified rows.', false);
                this.showNotificationMessage('Please provide BU HR comments for modified rows.', 2, '', null, '', '');
                valid = false;
            }
        }

        return valid;
    }

    validateMandatoryField1(modifieditems: any, action: any): boolean {
        let valid: boolean = true;
        if (action == this.actionCodes.RaiseL3) {
          if (isNullOrUndefined(modifieditems.ToMailMailId) || modifieditems.ToMailMailId.length === 0) {
            this.showNotificationMessage('To field is required.', 2, '', null, '', '');
            valid = false;
          }
         
        //   else if (isNullOrUndefined(modifieditems.MailText) || modifieditems.MailText.length === 0) {
        //     this.showNotificationMessage('Message field is required.', 2, '', null, '', '');
        //     valid = false;
        //   }
          else if (isNullOrUndefined(modifieditems.MailSubject) || modifieditems.MailSubject.length === 0) {
            this.showNotificationMessage('Subject field is required.', 2, '', null, '', '');
            valid = false;
          }
        }
        if (action == this.actionCodes.Insufficiency_Clear_By_BUHR) {
            if (isNullOrUndefined(modifieditems.ToMailMailId) || modifieditems.ToMailMailId.length === 0) {
              this.showNotificationMessage('To field is required.', 2, '', null, '', '');
              valid = false;
            }
           
          //   else if (isNullOrUndefined(modifieditems.MailText) || modifieditems.MailText.length === 0) {
          //     this.showNotificationMessage('Message field is required.', 2, '', null, '', '');
          //     valid = false;
          //   }
            else if (isNullOrUndefined(modifieditems.MailSubject) || modifieditems.MailSubject.length === 0) {
              this.showNotificationMessage('Subject field is required.', 2, '', null, '', '');
              valid = false;
            }
          }
        if (action == this.actionCodes.Responsetoemployee) {
            if (isNullOrUndefined(modifieditems.ToMailMailId) || modifieditems.ToMailMailId.length === 0) {
              this.showNotificationMessage('To field is required.', 2, '', null, '', '');
              valid = false;
            }
            else if (isNullOrUndefined(modifieditems.MailSubject) || modifieditems.MailSubject.length === 0) {
              this.showNotificationMessage('Subject field is required.', 2, '', null, '', '');
              valid = false;
            }
          }
    
    
        else if (action == this.actionCodes.SendToVendor) {
            if (isNullOrUndefined(modifieditems.ToMailMailId) || modifieditems.ToMailMailId.length === 0) {
              this.showNotificationMessage('To field is required.', 2, '', null, '', '');
              valid = false;
            }
            // else if (isNullOrUndefined(modifieditems.MailText) || modifieditems.MailText.length === 0) {
            //   this.showNotificationMessage('Message field is required.', 2, '', null, '', '');
            //   valid = false;
            // }
            else if (isNullOrUndefined(modifieditems.MailSubject) || modifieditems.MailSubject.length === 0) {
                this.showNotificationMessage('Subject field is required.', 2, '', null, '', '');
                valid = false;
              }
          }
          else if (action == this.actionCodes.FOLLOWUPMAIL) {
            if (isNullOrUndefined(modifieditems.ToMailMailId) || modifieditems.ToMailMailId.length === 0) {
              this.showNotificationMessage('To field is required.', 2, '', null, '', '');
              valid = false;
            }
            // else if (isNullOrUndefined(modifieditems.MailText) || modifieditems.MailText.length === 0) {
            //   this.showNotificationMessage('Message field is required.', 2, '', null, '', '');
            //   valid = false;
            // }
            else if (isNullOrUndefined(modifieditems.MailSubject) || modifieditems.MailSubject.length === 0) {
                this.showNotificationMessage('Subject field is required.', 2, '', null, '', '');
                valid = false;
              }
          }
          else if (action == this.actionCodes.SHOWCAUSENOTICE) {
            if (isNullOrUndefined(modifieditems.ToMailMailId) || modifieditems.ToMailMailId.length === 0) {
              this.showNotificationMessage('To field is required.', 2, '', null, '', '');
              valid = false;
            }
            // else if (isNullOrUndefined(modifieditems.MailText) || modifieditems.MailText.length === 0) {
            //   this.showNotificationMessage('Message field is required.', 2, '', null, '', '');
            //   valid = false;
            // }
            else if (isNullOrUndefined(modifieditems.MailSubject) || modifieditems.MailSubject.length === 0) {
                this.showNotificationMessage('Subject field is required.', 2, '', null, '', '');
                valid = false;
              }
          }
          else if (action == this.actionCodes.SendToBGVHead) {
            
            if (isNullOrUndefined(modifieditems.ToMailMailId) || modifieditems.ToMailMailId.length === 0) {
              this.showNotificationMessage('To field is required.', 2, '', null, '', '');
              valid = false;
            }
            // else if (isNullOrUndefined(modifieditems.MailText) || modifieditems.MailText.length === 0) {
            //   this.showNotificationMessage('Message field is required.', 2, '', null, '', '');
            //   valid = false;
            // }
            else if (isNullOrUndefined(modifieditems.MailSubject) || modifieditems.MailSubject.length === 0) {
                this.showNotificationMessage('Subject field is required.', 2, '', null, '', '');
                valid = false;
              }
          }
          else if (action == this.actionCodes.SendToCentralHR) {
            
            if (isNullOrUndefined(modifieditems.ToMailMailId) || modifieditems.ToMailMailId.length === 0) {
              this.showNotificationMessage('To field is required.', 2, '', null, '', '');
              valid = false;
            }
            // else if (isNullOrUndefined(modifieditems.MailText) || modifieditems.MailText.length === 0) {
            //   this.showNotificationMessage('Message field is required.', 2, '', null, '', '');
            //   valid = false;
            // }
            else if (isNullOrUndefined(modifieditems.MailSubject) || modifieditems.MailSubject.length === 0) {
                this.showNotificationMessage('Subject field is required.', 2, '', null, '', '');
                valid = false;
              }
          }
          else if (action == this.actionCodes.TerminationApprovalToBGVHead) {
            
            if (isNullOrUndefined(modifieditems.ToMailMailId) || modifieditems.ToMailMailId.length === 0) {
              this.showNotificationMessage('To field is required.', 2, '', null, '', '');
              valid = false;
            }
            // else if (isNullOrUndefined(modifieditems.MailText) || modifieditems.MailText.length === 0) {
            //   this.showNotificationMessage('Message field is required.', 2, '', null, '', '');
            //   valid = false;
            // }
            else if (isNullOrUndefined(modifieditems.MailSubject) || modifieditems.MailSubject.length === 0) {
                this.showNotificationMessage('Subject field is required.', 2, '', null, '', '');
                valid = false;
              }
          }
          else if (action == this.actionCodes.SendToBUHR) {
            
            if (isNullOrUndefined(modifieditems.ToMailMailId) || modifieditems.ToMailMailId.length === 0) {
              this.showNotificationMessage('To field is required.', 2, '', null, '', '');
              valid = false;
            }
            // else if (isNullOrUndefined(modifieditems.MailText) || modifieditems.MailText.length === 0) {
            //   this.showNotificationMessage('Message field is required.', 2, '', null, '', '');
            //   valid = false;
            // }
            else if (isNullOrUndefined(modifieditems.MailSubject) || modifieditems.MailSubject.length === 0) {
                this.showNotificationMessage('Subject field is required.', 2, '', null, '', '');
                valid = false;
              }
          }
        else {
          if (isNullOrUndefined(modifieditems.ToMailMailId) || modifieditems.ToMailMailId.length === 0) {
            this.showNotificationMessage('To field is required.', 2, '', null, '', '');
            valid = false;
          }
        //   else if (isNullOrUndefined(modifieditems.MailText) || modifieditems.MailText.length === 0) {
        //     this.showNotificationMessage('Message field is required.', 2, '', null, '', '');
        //     valid = false;
        //   }
          else if (isNullOrUndefined(modifieditems.MailSubject) || modifieditems.MailSubject.length === 0) {
            this.showNotificationMessage('Subject field is required.', 2, '', null, '', '');
            valid = false;
          }
        }
    
    
    
    
        // if (!isNullOrUndefined(modifieditems) && modifieditems.length > 0) {
        //   let OfferStatusList = modifieditems.filter(x => ((isNullOrUndefined(x.OfferStatus)) || x.OfferStatus === ''));
    
        //   if (!isNullOrUndefined(OfferStatusList) && OfferStatusList.length > 0) {
        //     this.showNotificationMessage('Please select offer status status for modified rows.', 2, '', null, '', '');
        //     valid = false;
        //   }
    
        // }
        if(valid==false){
            this.IsLoading = false;
        }
        
        return valid;
      }
    
    deleteAttachment(FileData: any) {

        this.IsLoading = true;
        let deleteData: any[] = [];

        FileData.forEach((item: any) => {
            let odata: object = {
                'Module': 'SubCheck',
                'Reference1': Number(this.RequestId),
                'Reference2': 0,
                'Reference3': 0,
                'Reference4': 0,
                'TransactionId': item.FileReferenceId,
                'FilePath': '',
                'FileName': item.FileName,
                'UserId': this._appSharedService.UserInfo.actinguserid
            };
            deleteData.push(odata);
        });
        let subscribe$: Subscription = this._requestService.deleteAttachment(deleteData).subscribe(result => {
            if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

                this.sectionData.forEach(item => {
                    item.DocumentList = null;
                    item.DocumentListDesc = null;
                    item.FileReferenceId = 0;
                    item.FileName = '';
                });
                this.sectionData = [];
            }



            this.IsLoading = false;
            this.openBUHRSection = false;
            // this.refreshRequest();

        },

            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }

    closeAttachment(event: any): void {
        console.log(event.data);

        let selectedData = this.sectionData.filter(x => x.TransactionId === this.transId)[0];

        if (!isNullOrUndefined(event) && event.Reload === true) {
            // selectedData.FilePath = event.data[0].FilePath;
            selectedData.FileReferenceId = event.data[0].TransactionId;
            selectedData.FileName = event.data[0].FileName;
        }

        this.DisplayAttachment = false;
        this.showNotificationMessage('Are you sure you want to clear the insufficiency?', 1, this.insufficiencyClearData.Name, this.BUHRData.filter(x => x.PendingWith==RoleCodes.BUHR), 'Yes', 'No');


    }

    enableDisableButtons(): void {
        if (!isNullOrUndefined(this.BUHRProperties.ActionButtons)
            && this.BUHRProperties.ActionButtons.length > 0
            && (this.BUHRProperties.ActionButtons.filter((x: any) => x.Group === 'BUHR')).length > 0) {

            let modifieditems = this.BUHRData.filter(x => x.IsModified === 1);


            let primaryPageButt = this.BUHRProperties.ActionButtons.filter((x: any) =>
                x.Group === 'BUHR');

            if (!isNullOrUndefined(this.BUHRData) && this.BUHRData.length > 0) {
                primaryPageButt.forEach((item: any) => {

                    if (!isNullOrUndefined(item.Name) && item.Name !== '') {
                        item.IsEnabled = false;
                        switch (item.Name.toUpperCase()) {
                            case 'AGREEFORTERMINATION':
                                // item.IsEnabled = !isNullOrUndefined(this.BUHRFilterData.BUHRStatusCode) && this.BUHRFilterData.BUHRStatusCode === 'AC011' ? true : false;

                                // if (this.BUHRFilterData.filter(x => !isNullOrUndefined(x.BUHRStatusCode) && x.BUHRStatusCode === ActionCode.BUHR_Agree_for_Termination).length > 0
                                //     && this.BUHRData.filter(x => x.UploadStatus === BGVStatus.Non_Green_Terminated).length === 0) {
                                //     item.IsEnabled = true
                                // }
                                // else {
                                // item.IsEnabled = false
                                // }

                                if (this.BUHRFilterData.filter(x => !isNullOrUndefined(x.BUHRStatusCode)
                                    && (x.BUHRStatusCode === ActionCode.Assign_to_nongreen || x.BUHRStatusCode === ActionCode.BUHR_Agree_for_Termination)).length > 0) {
                                    item.IsEnabled = false
                                }
                                else {
                                    item.IsEnabled = true
                                }
                                // item.IsEnabled = true;
                                break;
                            case 'ASSIGNTONONGREEN':
                                if (this.BUHRFilterData.filter(x => !isNullOrUndefined(x.BUHRStatusCode)
                                    && (x.BUHRStatusCode === ActionCode.Assign_to_nongreen || x.BUHRStatusCode === ActionCode.BUHR_Agree_for_Termination)).length > 0) {
                                    item.IsEnabled = false
                                }
                                else {
                                    item.IsEnabled = true
                                }

                                break;
                            case 'BUHRONHOLD':
                                if (this.BUHRFilterData.filter(x => !isNullOrUndefined(x.BUHRStatusCode)
                                    && (x.BUHRStatusCode === ActionCode.Assign_to_nongreen || ActionCode.Insufficiency_On_Hold || x.BUHRStatusCode === ActionCode.BUHR_Agree_for_Termination)).length > 0) {
                                    item.IsEnabled = false
                                }
                                else {
                                    item.IsEnabled = true
                                }
                                // item.IsEnabled = true;
                                break;
                        }
                    }
                });
            }
        }
    }

    onClick(event: any): void {
        console.log(event);
        let selitem: any = event.item;

        console.log(selitem);

        if (event.fieldname.toUpperCase() === 'ACTION') {
            this.transId = 0;
            this.transId = selitem.TransactionId;
            selitem.IsRowHighlight = selitem.IsModified;

            // event.icon.IconCSS
            switch (event.icon.Value) {
                case '0':
                    //Upload
                    this.DisplayAttachment = true;
                    break;
            }
        }
        if (event.fieldname.toUpperCase() === 'FILEACTION') {

            // event.icon.IconCSS
            switch (event.icon.Value) {
                case '0':
                    //Download
                    this.downloadAttachment(selitem);

                    break;
            }
        }

    }

    downloadAttachment(data: any) {
        this.IsLoading = true;

        let odata: any = {
            'Module': data.FileModule,
            'Reference1': Number(this.RequestId),
            'Reference2': data.CheckId,
            'Reference3': 0,
            'Reference4': 0,
            'TransactionId': data.FileReferenceId,
            // 'FilePath': data.FilePath,
            'FilePath': '',
            'FileName': data.FileName
        };

        let subscribe$: Subscription = this._requestService.downloadAttachment(odata).subscribe(result => {
            // if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
            //   console.log(result);
            // }
            if (!isNullOrUndefined(result)) {
                let url = window.URL.createObjectURL(result.data);
                let a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                // a.download = res.filename;
                a.download = data.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
                // this._toastr.success('Resume downloaded successfully', this.CompTitle);
                console.log('Resume downloaded successfully');

                this.notify = {
                    info: {
                        header: 'Confirmation Alert',
                        message: 'Document downloaded successfully',
                        yes: '',
                        no: '',

                        IsHeaderHidden: true,
                        HeaderIcon: 'fa fa-check-circle text-success',
                        IsCrossEnable: true,
                        SizeClass: 'modal-sm'
                    },

                    action: '',
                    item: null
                };

                this.displayNotifybox = true;
            }
            this.IsLoading = false;

        },
            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }

    onChange(event: any): void {
        let selitem: any = event.item;
        // Emitting onchange event of resource plan to parent container
        selitem.IsModified = 1;
        this.transId = 0;
        this.transId = selitem.TransactionId;
        selitem.IsRowHighlight = selitem.IsModified;



        switch (event.fieldname.toUpperCase()) {
            case 'BUHRSTATUSCODE':
                if ((isNullOrUndefined(selitem.BUHRStatusCode) || selitem.BUHRStatusCode === '') && !isNullOrUndefined(selitem.OldBUHRStatusCode) && selitem.OldBUHRStatusCode !== '') {

                    // this.showNotificationMessage('Please select atleast one Non green status.', 2, '', null, '', '');

                    setTimeout(() => {
                        selitem.BUHRStatusCode = selitem.OldBUHRStatusCode
                        selitem.BUHRStatusDesc = selitem.OldBUHRStatusDesc
                        selitem.BUHRComment = selitem.OldBUHRComment
                        selitem.IsModified = 0;
                        selitem.IsRowHighlight = selitem.IsModified;
                    }, 50);

                }
                break;

            case 'REVISEDSLADATE':
                if (this.RevisedSLADateData.RevisedSLADate < new Date()) {
                    this.showSLANotificationMessage('Revised SLA date should not be less than today date.', 2, '', null, '', '');
                    this.RevisedSLADateData.RevisedSLADate = new Date()
                }
                break;
        }

        if (event.fieldname.toUpperCase() === 'ACTION') {
            // event.icon.IconCSS
            switch (event.icon.Value) {
                case '0':
                    //Upload
                    this.DisplayAttachment = true;
                    break;
            }
        }

        this.enableDisableButtons();

        let eventdata = {
            fieldname: event.fieldname
        };
    }
    changeFn(event: any) {
        //this.foo =event.target.value;
      }
     

    closeNotifybox(): void {
        this.displayNotifybox = false;
    }
    actionButtonClick(data: any) {
        let sectionitems = this.sectionData.filter(x => x.IsModified === 1);

        if (!isNullOrUndefined(data.Name) && data.Name !== '') {
            this.actionCode = '';
            this.actionCode = data.ActionCode;
            switch (data.Name.toUpperCase()) {
                case 'AGREEFORTERMINATION':
                    if (this.validateMandatoryFields(this.BUHRFilterData.filter(x => x.IsEditable === 1)) && this.BUHRFilterData.filter(x => x.IsEditable === 1).length > 0) {
                        // this.SubmitBUHR(sectionitems);
                        if (this.BUHRData.filter(x => x.IsEditable === 1 && x.IsLWDFlag === true).length > 0) {
                            this.updateLWD();
                        } else {
                            this.showNotificationMessage('Are you sure you want to terminate the case?', 1, data.Name, this.BUHRData.filter(x => x.PendingWith==RoleCodes.BUHR), 'Submit now', 'Cancel');
                        }
                        // this.updateLWD();
                        // this.showNotificationMessage('Are you sure you want to terminate the case?', 1, data.Name, this.BUHRData, 'Submit now', 'Cancel');
                    }
                    break;

                case 'ASSIGNTONONGREEN':
                    if (this.validateMandatoryFields(this.BUHRFilterData.filter(x => x.IsEditable === 1)) && this.BUHRFilterData.filter(x => x.IsEditable === 1).length > 0) {
                        // this.SubmitBUHR(sectionitems);
                        // if (this.BUHRData.filter(x => x.IsEditable === 1 && x.IsLWDFlag === true).length > 0) {
                        //     this.updateLWD();
                        // } else {
                            // this.IsLoading=true;
                            // this.viewEmailTemp = true;
                            // this.ACTION = this.actionCode;
                            // this.openModal('EmailPopup')
                            // this.GetNGMailDetails();
                           //this.insufficiencyClearData = data;
                           //this.DisplayAttachment = true;
                        
                        this.showNotificationMessage('Are you sure you want to clear the insufficiency?', 1, data.Name, this.BUHRData.filter(x => x.PendingWith==RoleCodes.BUHR), 'Yes', 'No');
                        // }
                        // this.updateLWD();
                        // this.showNotificationMessage('Are you sure you want to terminate the case?', 1, data.Name, this.BUHRData, 'Submit now', 'Cancel');
                    }
                    break;

                case 'BUHRONHOLD':
                    if (this.validateMandatoryFields(this.BUHRFilterData.filter(x => x.IsEditable === 1)) && this.BUHRFilterData.filter(x => x.IsEditable === 1).length > 0) {
                        // this.SubmitBUHR(sectionitems);
                        // if (this.BUHRData.filter(x => x.IsEditable === 1 && x.IsLWDFlag === true).length > 0) {
                        //     this.updateLWD();
                        // } else {
                        this.updateRevisedSLADate();
                        // this.showNotificationMessage('Are you sure you want to Hold the case?', 1, data.Name, this.BUHRData.filter(x => (x.ColorCode === StatusColorCode.Red || x.ColorCode === StatusColorCode.Amber)), 'Yes', 'No');
                        //     this.showNotificationMessage('Are you sure you want to terminate the case?', 1, data.Name, this.BUHRData, 'Submit now', 'Cancel');
                        // }
                        // this.updateLWD();
                        // this.showNotificationMessage('Are you sure you want to terminate the case?', 1, data.Name, this.BUHRData, 'Submit now', 'Cancel');
                    }
                    break;
            }
        }

    }

    setNotifyRes(event: any): void {
        console.log('notify res >> ' + JSON.stringify(event));
        let canClose: boolean = true;
        switch (event.action.toUpperCase()) {
            case 'AGREEFORTERMINATION':
                if (event.result.toUpperCase() === 'SUBMIT NOW') {
                    this.SubmitBUHR(event.item, 'Case is terminated.');
                }
                break;
            case 'ASSIGNTONONGREEN':
                if (event.result.toUpperCase() === 'YES') {
                    this.IsLoading=true;
                        this.viewEmailTemp = true;
                        this.ACTION = this.actionCodes.Insufficiency_Clear_By_BUHR;
                        this.openModal('EmailPopup')
                        this.GetNGMailDetails();
                    //this.SubmitBUHR(event.item, 'Insufficiency is cleared successfully.');
                }
                break;
            case 'BUHRONHOLD':
                if (event.result.toUpperCase() === 'YES') {
                    this.SubmitBUHR(event.item, 'Case is On-hold.');
                }
                break;
        }
        if (canClose) {
            this.closeNotifybox();
        }
    }
    getNonGreenadditionalSectionList() {
        // this.IsLoading = true;

        let odata: object = { 'requestId': this.RequestId, 'checkId': null };

        let subscribe$: Subscription = this._requestService.getNonGreenadditionalSectionList(odata).subscribe(result => {
            if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
                this.NonGreenAdditionalSectionData = result.Data;
            }
            this.IsLoading = false;
        },
            err => {
                console.log('Error on getting getNonGreenadditionalSectionList.');
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }  
    GetNGMailDetails() {
        this.IsLoading = true;
       //this.ACTION = this.actionCodes.RaiseL3;
       let odata: object = { 'requestId': this.RequestId,'Action':this.ACTION};

       let subscribe$: Subscription = this._requestService.GetNGMailDetails(odata).subscribe(result => {
           if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
               this.selectedInfoData=result.Data;
               this.selectedEmailActionData=result.Data;
               //this.NonGreenAdditionalSectionData = result.Data;
               this.IsLoading = false;
           }
           this.IsLoading = false;
       },
           err => {
               console.log('Error on getting GetNGMailDetails.');
               this.IsLoading = false;
           });
       this.subscriptionList$.push(subscribe$);
   }
   btnClick(butt: any): void {
    switch (butt.Name.toUpperCase()) {
      case 'SEND':
        this.sendMailData();
        break;
      case 'CANCEL':
        
        if (this.selectedTab != 'CreateEmail') {
          this.selectedInfoData = {};
          this.viewEmailTemp = false;
          this.viewEmail = false;
        
          
        }
        else {
          this.selectedInfoData = {};
        }
        this.viewEmailTemp = false;
        
        break;
      case 'ATTACHMENT':
        this.DisplayAttachment = true;
        break;
      case 'CLEAR':
        this.DisplayAttachment = false;
        this.fileData = [];
        break;

    }
  }
  getMailerTransaction() {
    console.log(this.selectedTab)
    this.viewEmailData = [];
    this.IsLoading = true;
    let odata: any = {};

    let subscribe$: Subscription = this._requestService.GetMailTransaction(this.RequestId, this.selectedTab).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

        result.Data.forEach((x: any) => this.viewEmailData.push(x));


      }
      console.log(this.viewEmailData)
      this.IsLoading = false;

    },
      err => {
        console.log('Error on getting GetRequestData.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }
  sendMailData() {
    var odata: any = {};
    var transactionId: any = '';
    var MailTransactionId: string = '';
    this.fileRefId = [];
    this.fileNames = [];
    this.fileData.forEach((x: any) => this.fileRefId.push(x.TransactionId));
    this.fileData.forEach((x: any) => this.fileNames.push(x.FileName));
    transactionId = this.fileRefId.toString();
    console.log(this.selectedEmailData)
    this.IsLoading = true;
    this.successFlag = false;
    if(this.ACTION==this.actionCodes.RaiseL3){
        MailTransactionId='17';
    }
    if(this.ACTION==this.actionCodes.Insufficiency_Clear_By_BUHR){
        MailTransactionId='7';
    }
    if(this.ACTION==this.actionCodes.Responsetoemployee){
        MailTransactionId='17';
    }
    if(this.ACTION==this.actionCodes.SendToVendor){
        MailTransactionId='22';
    }
    if(this.ACTION==this.actionCodes.FOLLOWUPMAIL){
        MailTransactionId='17';
    }
    if(this.ACTION==this.actionCodes.SHOWCAUSENOTICE){
        MailTransactionId='17';
    }
    if(this.ACTION==this.actionCodes.SendToBGVHead){
        MailTransactionId='18';
    }
    if(this.ACTION==this.actionCodes.SendToCentralHR){
        MailTransactionId='18';
    }
    if(this.ACTION==this.actionCodes.CloseCase){
        MailTransactionId='18';
    }
    var divmaileditabletext : any = ''; 
    divmaileditabletext= document.getElementById("divmailtext")?.innerHTML;
    const buf= Buffer.from(divmaileditabletext, 'utf8'); //btoa(divmaileditabletext)
    var divmaileditabletext1=buf.toString('base64');
    console.log(buf.toString('hex'));
    // Prints: 68656c6c6f20776f726c64
    console.log(buf.toString('base64'));
      
      odata = {
        "ToEmailRole": this.selectedInfoData.ToMailMailId,
        "CCEMailRole": this.selectedInfoData.CCMailMailId,
        "EmailMessage": divmaileditabletext1,
        "EmailSubject": this.selectedInfoData.MailSubject,
        "RequestId": this.RequestId,
        "EmailType": this.selectedTab,
        "MailTransactionId": this.selectedEmailActionData.MailTransactionId,
        "Action": this.ACTION,
        "FileReferenceId": transactionId

    }
    if (this.validateMandatoryField1(this.selectedInfoData, this.ACTION)) {
      let subscribe$: Subscription = this._requestService.SubmitNonGreenMailTransaction(odata).subscribe(result => {
        console.log(result);
        if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

          
        
          this.successFlag = true;
          this.closeModal('EmailPopup')
           
            if (this.ACTION == this.actionCodes.Responsetoemployee && this.isResponsetoEmployee) { 
                this.showNotificationMessage('Response to Candidate/Employee sent successfully.', 1, '', null, '', '');
              }
              if (this.ACTION == this.actionCodes.Insufficiency_Clear_By_BUHR) { 
                //this.SubmitNonGreen(this.vendorsubmitmodifieditems, 'Case is sent to vendor.');
                this.SubmitBUHR(this.BUHRData, 'Insufficiency Cleared successfully.');

                //this.showNotificationMessage('Insufficiency Cleared successfully.', 1, '', null, '', '');
            }
            if (this.ACTION == this.actionCodes.SendToVendor) { 
            this.SubmitNonGreen(this.vendorsubmitmodifieditems, 'Case is sent to vendor.');
            this.showNotificationMessage('Case is sent to vendor.', 1, '', null, '', '');
            }
           if (this.ACTION == this.actionCodes.FOLLOWUPMAIL) { 
                this.showNotificationMessage('Followup Mail Send successfully.', 1, '', null, '', '');
                }
            if (this.ACTION == this.actionCodes.SHOWCAUSENOTICE) { 
                    this.showNotificationMessage('Show Cause Notice Mail Send successfully.', 1, '', null, '', '');
                    }      
           if (this.ACTION == this.actionCodes.SendToBGVHead) { 
                this.SubmitNonGreen(this.BGVHeadModifiedItems,'')
                this.showNotificationMessage('Case is sent to BGV Head.', 1, '', null, '', '');
            }
           if (this.ACTION == this.actionCodes.SendToCentralHR) { 
             this.SubmitNonGreen(this.BGVHeadModifiedItems,'')
                this.showNotificationMessage('Case is sent to Central HR.', 1, '', null, '', '');
            }
           if (this.ACTION == this.actionCodes.TerminationApprovalToBGVHead) { 
            let ngTerminationData=this.NonGreenData.filter(x=>x.PendingWith === RoleCodes.NonGreen.toString()
                    && (x.ColorCode === StatusColorCode.Red||x.ColorCode === StatusColorCode.Amber));
                this.SubmitNonGreen(ngTerminationData,'')
                this.showNotificationMessage('Case submitted to BGV Head for Termination Approval.', 1, '', null, '', '');
            }
            if (this.ACTION == this.actionCodes.CloseCase) { 
                let ngCloseData=  this.NonGreenData.filter(x => x.IsEditable === 1)
                ngCloseData.forEach((item:any)=>{
                 item.IsClosed=true;
                });
                this.SubmitNonGreen(ngCloseData, '');
                 this.showNotificationMessage('Case closed successfully.', 1, '', null, '', '');
            }
          this.refreshRequest();

          this.displayNotifybox = true;

          this.fileData = [];
        }
        else if(!isNullOrUndefined(result) && result.Status === ProcessStatus.Error) 
        {
            console.log("This is result  " + result.Message);
            this.successFlag = false;
        }
        // this.IsLoading = false;

      },
        err => {
          this.fileData = [];
          this.successFlag = false;
          console.log('Error');
          this.IsLoading = false;
        });
      this.subscriptionList$.push(subscribe$);
    }


  }
  RaiseL3Insufficiency(modifieditems: any, msg: string) {
    // if (this.validateMandatoryFields(modifieditems)) {
        this.isResponsetoEmployee=false;
    modifieditems.forEach((item: any) => {
        item.ActionCode = this.actionCode;
        item.InsufficiencyComment = item.NonGreenComment;
        item.InsufficiencyType = "L3";
        item.ToRoleId=item.Tomail;
        item.CCRoleId=this.Ccmail;
        item.Subject=this.Mailsubject;
        item.Message=this.MailMessage;
        // item.DocumentStatus = false;
        // item.InsufficiencyStatus = item.InsufficiencyType;
    });
    this.IsLoading = true;

    let subscribe$: Subscription = this._requestService.RaiseL3Insufficiency(modifieditems).subscribe(result => {
        if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
            this.viewEmailTemp = true;
            this.ACTION = this.actionCodes.RaiseL3;
            this.openModal('EmailPopup')
            this.GetNGMailDetails();
            // this.showNotificationMessage(msg, true);
           // this.showNotificationMessage(msg, 1, '', null, '', '');
            // this.getCheckDocumentDetailsList();

            //this.refreshRequest();

        }
        this.IsLoading = false;
    },

        err => {
            this.IsLoading = false;
        });
    this.subscriptionList$.push(subscribe$);
    // }
}
SubmitNonGreenForm(modifieditems: any, msg: string) {
    // if (this.validateMandatoryFields(modifieditems)) {
    this.sectionData
     modifieditems.ActionCode=  this.actionCode;
     let submitDetails=this.SubmittedDetailsList.filter(x=>x.IsModified===1);
     let verifiedcomps=this.VerifiedComponentsList.filter(x=>x.IsModified===1); 
     var BGCobservationtext : any = modifieditems.BGCObservations; 
     //BGCobservationtext= document.getElementById("divmailtext")?.innerHTML;
     
     const buf= Buffer.from(modifieditems.BGCObservations, 'utf8'); //btoa(divmaileditabletext)
     modifieditems.BGCObservations=buf.toString('base64');

     const buf1= Buffer.from(modifieditems.VendorFeedback, 'utf8'); //btoa(divmaileditabletext)
     modifieditems.VendorFeedback=buf1.toString('base64');

     if(submitDetails.length>0)
     {
        this.InsertMoreDetails('Update',submitDetails);
     }
     
    this.IsLoading = true;
    let subscribe$: Subscription = this._requestService.SubmitNonGreenForm(modifieditems).subscribe(result => {
        if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
            if (this.ACTION == this.actionCodes.SendToCentralHR) {
                this.ACTION = this.actionCodes.SendToCentralHR;
                this.viewEmailTemp = true;
                this.openModal('EmailPopup')
                this.GetNGMailDetails();
            }
            if (this.ACTION == this.actionCodes.SendToBGVHead) {
                this.ACTION = this.actionCodes.SendToBGVHead
                this.viewEmailTemp = true;
                this.openModal('EmailPopup')
                this.GetNGMailDetails();
            }
            if(this.ACTION==this.actionCodes.TerminationApprovalToBGVHead)
            {
                this.ACTION = this.actionCodes.TerminationApprovalToBGVHead
                this.viewEmailTemp = true;
                this.openModal('EmailPopup')
                this.GetNGMailDetails();
            }
            //this.getBasicDetails();
            //this.getCheckDocumentDetailsList();           
           // this.showNotificationMessage(msg, 1, '', null, '', '');
            //this.openNonGreenSection = false;
          
           
           // this.UpdateVerifiedComponents(verifiedcomps);
        }
         this.IsLoading = false;
    },

        err => {
            this.IsLoading = false;
        });
    this.subscriptionList$.push(subscribe$);
    // }
}
InsertMoreDetails(mode: string = '',data: any) {

    this.IsLoading = true;
    
    let modifieditems: any[] = [];
    if(mode=='Delete')
    {
        modifieditems=this.SubmittedDetailsList.filter(x=>x.TransactionID==data.TransactionID)
        modifieditems[0].Mode=mode;
        modifieditems[0].ActionCode=this.actionCode;

    }
    else if(mode=='Create')
    {
        
        this.SubmittedDetailsList = [];

        let secData: any = {
          RequestId: this.RequestId,   
          ActionCode: this.actionCode,
          
          Mode: mode,
        };
        this.SubmittedDetailsList.push(secData);
        modifieditems=this.SubmittedDetailsList;
    }
   else
    {
    data.forEach((item: any) => {
        item.RequestId= this.RequestId,
            item.Mode=mode,
            item.ActionCode=this.actionCode
            
    });
    modifieditems=data;
    }   
    // if(mode==='Delete'){
    //   this.count--;
    //   if(this.count<0)
    //   {
    //     this.count=0;
    //   }
    
    //      odata= {
    //         RequestId: this.RequestId,
    //         Mode: mode,
    //        TransactionID: !isNullOrUndefined(data) && data.TransactionID > 0 ? data.TransactionID : 0,
    //         CheckStatus:!isNullOrUndefined(data) && data.CheckStatus!=='' ? data.CheckStatus : '',
    //         CheckId:!isNullOrUndefined(data) && data.CheckId!==0 ? data.CheckId : 0,
    //         DocumentTypeId:!isNullOrUndefined(data) && data.DocumentTypeId!==0 ? data.DocumentTypeId : 0,
    //         ActionCode:this.actionCode,
    //         // CheckStatusDesc:!isNullOrUndefined(data) && data.CheckStatusDesc!=='' ? data.CheckStatusDesc : '',
    //        // CheckNameDesc:!isNullOrUndefined(data) && data.CheckNameDesc!=='' ? data.CheckNameDesc : '',
    //       //CheckName:!isNullOrUndefined(data) && data.CheckName!=='' ? data.CheckName : '',
    //         Duration:!isNullOrUndefined(data) && data.Duration!=='' ? data.Duration : '',
    //         DescriptionName:!isNullOrUndefined(data) && data.DescriptionName!=='' ? data.DescriptionName : '',
    //       };
    // }
    // else{
    //      odata= {
    //         RequestId: this.RequestId,
    //         Mode: mode,
    //         TransactionID: !isNullOrUndefined(data) && data[0].TransactionID > 0 ? data[0].TransactionID : 0,
    //         CheckStatus:!isNullOrUndefined(data) && data[0].CheckStatus!=='' ? data[0].CheckStatus : '',
    //         // CheckStatusDesc:!isNullOrUndefined(data) && data[0].CheckStatusDesc!=='' ? data[0].CheckStatusDesc : '',
    //         // CheckNameDesc:!isNullOrUndefined(data) && data[0].CheckNameDesc!=='' ? data[0].CheckNameDesc : '',
    //         ActionCode:this.actionCode,
    //         CheckId:!isNullOrUndefined(data) && data[0].CheckId!==0 ? data[0].CheckId : 0,
    //         DocumentTypeId:!isNullOrUndefined(data) && data[0].DocumentTypeId!==0 ? data[0].DocumentTypeId : 0,
    //         //CheckName:!isNullOrUndefined(data) && data[0].CheckName!=='' ? data[0].CheckName : '',
    //         Duration:!isNullOrUndefined(data) && data[0].Duration!=='' ? data[0].Duration : '',
    //         DescriptionName:!isNullOrUndefined(data) && data[0].DescriptionName!=='' ? data[0].DescriptionName : '',
    //       };
    // }
    
    
    let subscribe$: Subscription = this._requestService.InsertMoreDetails(modifieditems).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
       // // let msg = mode === 'Delete' ? 'Check details deleted successfully.' :mode==='Update'? 'Check details updated successfully.': 'Check details added successfully.';
       // // this.showNotificationMessage(msg, 1, '', null, '', '');
        this.getSubmittedDetails();
      }
    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
    
  }
  closeAttachmentEmail(event: any): void {
    this.DisplayAttachment = false;
    this.fileData = [];
    // this.fileNames=[];

    // event.data.forEach((x: any) => this.fileNames.push(x.FileName));

    //.TransactionId
    if (!isNullOrUndefined(event.data)) {
      event.data.forEach((x: any) => this.fileData.push(x));
      console.log(this.fileData);
      // this.reportData.FilePath = event.data[0].FilePath;
      // this.reportData.TransactionId = event.data[0].TransactionId;
      // this.reportData.FileName = event.data[0].FileName;

      //   this.stopCasesectionData[0].FilePath = event.data[0].FilePath;
      //   this.stopCasesectionData[0].FileReferenceId = event.data[0].TransactionId;
      //   this.stopCasesectionData[0].FileName = event.data[0].FileName;
      // } else {
      //   this.stopCasesectionData[0].IsModified = 0;
      //   this.stopCasesectionData[0].IsRowHighlight = this.stopCasesectionData[0].IsModified;
    }


  }
SubmitNonGreen(modifieditems: any, msg: string) {
    // if (this.validateMandatoryFields(modifieditems)) {
    modifieditems.forEach((item: any) => {
        item.ActionCode = this.actionCode;
        item.ToRoleId=this.Tomail;
        item.CCRoleId=this.Ccmail;

    });
    this.IsLoading = true;

    let subscribe$: Subscription = this._requestService.SubmitQC(modifieditems).subscribe(result => {
        if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

            // this.showNotificationMessage(msg, true);
           // this.showNotificationMessage(msg, 1, '', null, '', '');
            // this.getCheckDocumentDetailsList();

           // this.refreshRequest();
        //    this.viewEmailTemp = true;
        //    this.ACTION = this.actionCodes.SendToVendor;
        //    this.openModal('EmailPopup')
        //    this.GetNGMailDetails();
        // if (this.ACTION == this.actionCodes.SendToVendor) { 
        //     this.ACTION = this.actionCodes.SendToVendor;
        //     this.viewEmailTemp = true;
            
        //     this.openModal('EmailPopup')
        //     this.GetNGMailDetails();
        // }
       // else
         if(this.ACTION == this.actionCodes.SendToBUHR) 
        {
            this.ACTION == this.actionCodes.SendToBUHR
            this.viewEmailTemp = true;
            this.openModal('EmailPopup')
            this.GetNGMailDetails();

        } 
        //  else if (this.ACTION == this.actionCodes.SendToCentralHR) {
        //     this.SubmitNonGreenForm(this.NonGreenFormData,'Case submitted to Central HR.');   
           
        //  }
        // else if(this.ACTION == this.actionCodes.SendToBGVHead){
        //     this.SubmitNonGreenForm(this.NonGreenFormData,'Case submitted to BGV Head.');   
        // }  
        // else if(this.ACTION == this.actionCodes.TerminationApprovalToBGVHead){
        //     this.SubmitNonGreenForm(this.NonGreenFormData,'Case submitted to BGV Head for Termination Approval.');   
        // } 
       

        }
        // this.IsLoading = false;
    },

        err => {
            this.IsLoading = false;
        });
    this.subscriptionList$.push(subscribe$);
    // }
}
getBasicDetails() {
    
    
    this.IsLoading = true;
    let odata: any = {};
    this.BUHRFormData={};
    let subscribe$: Subscription = this._requestService.GetBasicDetails(this.RequestId,this.actionCode).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
      this.BUHRFormData=result.Data;     
      this.BUHRFormData.IsEditable=1;
       console.log('formdata',this.BUHRFormData);
       console.log('formdata',this.RequestData);
      }
      
      this.IsLoading = false;

    },
      err => {
        console.log('Error on getting GetRequestData.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
}

openAdditionalSection(data: any): void {

    if (!isNullOrUndefined(data.Name) && data.Name !== '' && data.Name.toUpperCase() === 'STOPCASE') {
        this.sectionData = [];

        let secData: any = {
            RequestId: this.RequestId,
            RequestStatus: this.RequestData.RequestStatus,
            ActionCode: data.ActionCode,
            FileReferenceId: 0,
            StopCaseComments: '',
            Report: data.DisplayName,
            Action: '0',
            FileName: '',
            IsEditable: 1,
            IsModified: 0,
            MultiFileReferenceId: null,
            MultiFileName: null
        };

        this.sectionData.push(secData);


    } 
   if(!isNullOrUndefined(data.Name) && data.Name !== '' && (data.Name.toUpperCase() === 'SUBMITFORBGVHEAD'||data.Name.toUpperCase() === 'SUBMITFORCENTRALHR'||data.Name.toUpperCase() === 'APPROVALFORTERMINATION')){
    
    this.getBasicDetails();
    this.getSubmittedDetails();
    this.NonGreenFormData.IsEditable=1
     //this.SubmittedDetailsList=this.SubmittedDetailsList[0]; 
        
     this.SubmittedDetailsList.forEach((item: any) => {
        item.IsEditable =  1 ;
        item.IsModified = 0;
        
      });
   }
    else {
        this.sectionData.forEach(item => {
            item.DocumentList = null;
            item.DocumentListDesc = null;
            item.FileReferenceId = 0;
            item.FileName = '';
            item.IsModified = ((item.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Accepted_1
                || item.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Accepted_2
                || item.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Accepted_3
                //|| item.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_1
                //|| item.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_2
                || item.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_3
            ) && item.PendingWith) === RoleCodes.NonGreen.toString() ? 1 : item.IsModified;
            item.IsRowHighlight = item.IsModified;
            item.NonGreenComment = isNullOrUndefined(item.NonGreenComment) ? '' : item.NonGreenComment;
            item.MultiFileReferenceId= null;
            item.MultiFileName= null;
            item.ToRoleId=isNullOrUndefined(item.ToRoleId) ? '' :item.ToRoleId;
            item.CCRoleId=isNullOrUndefined(item.CCRoleId) ? '' :item.CCRoleId;
            item.Subject=isNullOrUndefined(item.Subject) ? '' :item.Subject;
            item.Message=isNullOrUndefined(item.Message) ? '' :item.Message;
        });
      
        this.sectionDataSummary = [];

        if (this.sectionData.length > 0) {

            let Data = JSON.stringify(this.sectionData[0]);
            this.sectionDataSummary.push(JSON.parse(Data.toString()));

            let colorIsRed = this.sectionData.filter(f => f.ColorCode == "SC002");

            let NonGreenComment = "";

            this.sectionData.forEach((item: any) => {
                NonGreenComment += NonGreenComment == "" ? item.NonGreenComment : "," + item.NonGreenComment;
            });

            this.sectionDataSummary[0].NonGreenComment = NonGreenComment;
            this.sectionDataSummary[0].IsEditable = data.ActionCode === ActionCode.NG_Submit_for_termination ? 1 : 0;
           
            if (colorIsRed.length > 0) {
                this.sectionDataSummary[0].ColorCode = colorIsRed[0].ColorCode;
                this.sectionDataSummary[0].ColorCodeDesc = colorIsRed[0].ColorCodeDesc;
            }

        }

        
    }
    

    // Display Name and Filter name for buttons and bread scrum name display
    this.sectionName = data.Name;
    this.sectionDisplayName = data.DisplayName;
    this.submitSecionName ='Submit_Template';
    this.verifiedSecionName ='VerifiedComponents';


    this.openNonGreenSection = true;
   
}

    updateLWD() {
        this.openModal('LWD')
    }

    updateRevisedSLADate() {
        this.openModal('RevisedSLADate')
    }

    uploadFiles(): void {
        this.filesToUpload = [];
        if (!isNullOrUndefined(this.AttachedFiles)) {
      
      
          this.filesToUpload.push(this.selFileNames.split(';'));
            console.log(this.filesToUpload[0]);
            if (this.filesToUpload[0].length > 1) {
                this.selFileNames = this.filesToUpload[0].toString();
                this.selFileNames = this.selFileNames.replace(/\, /g, '-')
      
                console.log(this.selFileNames)
                if (!isNullOrUndefined(this.selFileNames) && this.selFileNames !== '' && !this.validateFileName(this.selFileNames.split('.').slice(0, -1).join('.'))) {
                    this.IsLoading = true;
      
                    this.uploadSuccess = false;
      
                    this._requestService.uploadBulkProjectBGVCases(this.AttachedFiles).subscribe(res => {
                        if (res !== null && res.Status === 200) {
                            // alert('file Upload success');
                            this.notify = {
                                info: {
                                    header: 'Confirmation Alert',
                                    message: 'File uploaded successfully.',
                                    yes: '',
                                    no: '',
      
                                    IsHeaderHidden: true,
                                    HeaderIcon: 'fa fa-check-circle text-success',
                                    IsCrossEnable: true,
                                    SizeClass: 'modal-sm'
                                },
      
                                action: 'UPLOAD_SUCCESS',
                                item: res.Data
                            };
      
                            this.displayNotifybox = true;
      
                            this.selFileNames = '';
                            this.filesToUpload[0] = '';
                            this.uploadSuccess = true;
                             
                        }
                        else {
                            this.notify = {
                                info: {
                                    header: 'Confirmation Alert',
                                    message: res.Message,
                                    yes: '',
                                    no: '',
      
                                    IsHeaderHidden: true,
                                    HeaderIcon: 'fa fa-exclamation-circle text-warning',
                                    IsCrossEnable: true,
                                    SizeClass: 'modal-sm'
                                },
      
                                action: '',
                                item: null
                            };
      
                            this.displayNotifybox = true;
      
                        }
                        this.IsLoading = false;
                    },
                        err => {
                            // this.toastr.error('Error on file upload.', this.CompTitle);
                            this.IsLoading = false;
                        });
      
                }
                else {
                    this.notify = {
                        info: {
                            header: 'Confirmation Alert',
                            message: 'Invalid file name. File name should not contain whitespaces.',
                            yes: '',
                            no: '',
      
                            IsHeaderHidden: true,
                            HeaderIcon: 'fa fa-exclamation-circle text-warning',
                            IsCrossEnable: true,
                            SizeClass: 'modal-sm'
                        },
      
                        action: '',
                        item: null
                    };
      
                    this.displayNotifybox = true;
                }
            }
         
                console.log(this.selFileNames)
                if (!isNullOrUndefined(this.selFileNames) && this.selFileNames !== '' && !this.validateFileName(this.selFileNames.split('.').slice(0, -1).join('.'))) {
                    this.IsLoading = true;
      
                    this.uploadSuccess = false;
      
                    this._requestService.uploadBulkProjectBGVCases(this.AttachedFiles).subscribe(res => {
                      if (res !== null && res.Status === 200&&res.Data!="NoData") {
                            // alert('file Upload success');
                            this.notify = {
                                info: {
                                    header: 'Confirmation Alert',
                                    message: 'File uploaded successfully.',
                                    yes: '',
                                    no: '',
      
                                    IsHeaderHidden: true,
                                    HeaderIcon: 'fa fa-check-circle text-success',
                                    IsCrossEnable: true,
                                    SizeClass: 'modal-sm'
                                },
      
                                action: 'UPLOAD_SUCCESS',
                                item: res.Data
                            };
      
                            this.displayNotifybox = true;
      
                            this.selFileNames = '';
                             this.filesToUpload[0] = '';
                            this.uploadSuccess = true;
                           
      
                        }
                        else {
                            this.notify = {
                                info: {
                                    header: 'Confirmation Alert',
                                    message: res.Message,
                                    yes: '',
                                    no: '',
      
                                    IsHeaderHidden: true,
                                    HeaderIcon: 'fa fa-exclamation-circle text-warning',
                                    IsCrossEnable: true,
                                    SizeClass: 'modal-sm'
                                },
      
                                action: '',
                                item: null
                            };
      
                            this.displayNotifybox = true;
      
                        }
                        this.IsLoading = false;
                    },
                        err => {
                            // this.toastr.error('Error on file upload.', this.CompTitle);
                            this.IsLoading = false;
                        });
      
                }
                else {
                    this.notify = {
                        info: {
                            header: 'Confirmation Alert',
                            message: 'Invalid file name. File name should not contain whitespaces.',
                            yes: '',
                            no: '',
      
                            IsHeaderHidden: true,
                            HeaderIcon: 'fa fa-exclamation-circle text-warning',
                            IsCrossEnable: true,
                            SizeClass: 'modal-sm'
                        },
      
                        action: '',
                        item: null
                    };
      
                    this.displayNotifybox = true;
                }
            
      
        }
        else {
            // this.toastr.warning('Kindly select a file for upload.', this.CompTitle);
            this.notify = {
                info: {
                    header: 'Confirmation Alert',
                    message: 'Kindly select a file for upload.',
                    yes: '',
                    no: '',
      
                    IsHeaderHidden: true,
                    HeaderIcon: 'fa fa-exclamation-circle text-warning',
                    IsCrossEnable: true,
                    SizeClass: 'modal-sm'
                },
      
                action: '',
                item: null
            };
      
            this.displayNotifybox = true;
        }
      }
      
    validateFileName(fileName: any): boolean {

        var specialChars = "<>[]{}?:;|'\"\\,/`=";
        //"<>@!#$%^&*()_+[]{}?:;|'\"\\,./~`-=";
        //   let i: number = 0;
      
        for (let i = 0; i < specialChars.length; i++) {
            if (fileName.indexOf(specialChars[i]) > -1) {
                return true
            }
        }
      
        return false;
      }
    handleUpload(e: any): void {
        if (e.target.files && e.target.files[0]) {
            let formData: FormData = new FormData();
            let filesToUpload = <Array<File>>e.target.files;
            let filenames: string = '';
            for (let i = 0; i < filesToUpload.length; i++) {
                formData.append('uploadedFiles', filesToUpload[i], filesToUpload[i].name);
                filenames = filenames + '; ' + filesToUpload[i].name;
                this.selFileNames = filenames.substr(2, filenames.length);
                this.AttachedFiles = formData;
            }
    
        }
        else {
            this.selFileNames = '';
            this.AttachedFiles = null;
        }
    
        // e.target.value = '';
    }
    openModal(id: string) {
        this.modalService.open(id);
        document.getElementById('txtvalue')?.focus();
    }

    closeModal(id: string) {
        this.modalService.close(id);
    }

    //client reference number modal popup.
    updateLWDModalPopup() {
        this.IsLoading = true;
        let odata: any = {
            RequestId: this.RequestId,
            LastWorkingDay: this.LWDData.LastWorkingDay
        };
        let subscribe$: Subscription = this._requestService.updateLWDModalPopup(odata).subscribe(result => {
            if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
                this.SubmitBUHR(this.BUHRData, 'Case is terminated.');
                this.closeModal('LWD');
                // this.IsLoading = false;
            }
        },

            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);


    }

    //client reference number modal popup.
    updateRevisedSLADateModalPopup() {
        this.IsLoading = true;
        let odata: any = {
            RequestId: this.RequestId,
            RevisedSLADate: this.RevisedSLADateData.RevisedSLADate
        };
        let subscribe$: Subscription = this._requestService.updateRevisedSLADate(odata).subscribe(result => {
            if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
                this.SubmitBUHR(this.BUHRData.filter(x => (x.ColorCode === StatusColorCode.Red || x.ColorCode === StatusColorCode.Amber)), 'Case is On-hold.');
                this.closeModal('RevisedSLADate');
                // this.IsLoading = false;
            }
        },

            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);


    }

    closeSLANotifybox(): void {
        this.displaySLANotifybox = false;
    }

    getApprovalDetailsForCHRAndBGVHead() {
    
    
        this.IsLoading = true;
        this.BUHRFormData = '';
    
        let subscribe$: Subscription = this._requestService.GetBasicDetailsBGVHeadAndCHR(this.RequestId).subscribe(result => {
          if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
          this.BUHRFormData=result.Data;     
          
           
          }
          
          this.IsLoading = false;
    
        },
          err => {
            console.log('Error on getting GetRequestData.');
            this.IsLoading = false;
          });
        this.subscriptionList$.push(subscribe$);
    }
    getSubmittedDetails() {
        
        
        this.IsLoading = true;
        this.SubmittedDetailsList = [];
    
        let subscribe$: Subscription = this._requestService.GetSubmittedDetailsCHRAndBGVHead(this.RequestId).subscribe(result => {
        if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
          this.SubmittedDetailsList=result.Data;
        //   this.SubmittedDetailsList.forEach((item: any) => {
        //     item.IsEditable =  1 ;
        //     item.IsModified = 0;
        //   });     
        }
        if (result ===null) {
            this.SubmittedDetailsList=[];
           
        } 
          
          this.IsLoading = false;
    
        },
          err => {
            console.log('Error on getting GetRequestData.');
            this.IsLoading = false;
          });
        this.subscriptionList$.push(subscribe$);
    }
}
