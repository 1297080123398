import { Component, EventEmitter, Input, OnInit, Output,ElementRef, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { asEnumerable } from 'linq-es2015';
import { FieldTemplate } from '../shared/_classes/utility.interface';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDatepickerI18n, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter, I18n, CustomDatepickerI18n } from '../fieldcontrol/customdatepicker.service';
// import { isNullOrUndefined } from 'util';
import { isNullOrUndefined } from '../shared/_services/utility.service';


@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
  { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }]
})
export class FilterComponent implements OnInit, OnChanges {

  @Input() FieldTemplate: Array<FieldTemplate> = [];
  @Input() GridData: any;
  @Input() InitialCondn!: string;

  @Output() close = new EventEmitter();
  @Output() selected = new EventEmitter();

  public FilterColsDet: any = [];
  public FiltOperator: any = {};
  public selectedFilter: any = [];
  public FiltCondn: string = '';
  public prevSelectedFilter: string = '';
  public focusableEles!: HTMLElement[];
  public firstFocusableEle!: HTMLElement;
  public lastFocusableEle!: HTMLElement;
  constructor(private translate: TranslateService,private eleRef: ElementRef) { }

  ngOnInit() {
    this.FiltOperator = {
      Text: [{ code: 'CT', desc: 'Contains' }, { code: 'NCT', desc: 'Does not Contain' },
      { code: 'EQ', desc: 'Equals' }, { code: 'NEQ', desc: 'Does not Equal' },

      { code: 'SW', desc: 'Starts with' }, { code: 'EW', desc: 'Ends with' }],
      List: [{ code: 'EQ', desc: 'Equals' }, { code: 'NEQ', desc: 'Does not Equal' }],
      Multi: [{ code: 'CT', desc: 'Contains' }, { code: 'NCT', desc: 'Does not Contain' }],
      Number: [{ code: 'EQ', desc: 'Equals' }, { code: 'NEQ', desc: ' Does not Equal' },
      { code: 'GT', desc: 'Greater Than' }, { code: 'GTEQ', desc: 'Greater Than or Equal To' },
      { code: 'LT', desc: 'Less Than' }, { code: 'LTEQ', desc: 'Less Than or Equal To' }, { code: 'BW', desc: 'Between' }],
      Date: [{ code: 'EQ', desc: 'Equals' }, { code: 'NEQ', desc: ' Does not Equal' },
      { code: 'BF', desc: 'Before' }, { code: 'AF', desc: 'After' }, { code: 'BW', desc: 'Between' }]
    };

    this.setFilterCols();
    this.setFilterColsData();

    this.FilterColsDet.forEach((item: any) => {
      let x = {
        displayname: item.displayname,
        column: item.column,
        linkfieldname: item.linkfieldname,
        type: item.type,
        oper: (item.type === 'text' || item.type === 'multi') ? 'CT' : 'EQ',
        value: '',
        value1: ''
      };

      this.selectedFilter.push(x);
    });

    // input condition to be shown on opening filter
    if (!isNullOrUndefined(this.InitialCondn) && this.InitialCondn.length > 0) {
      let initcondntemp = JSON.parse(this.InitialCondn);

      initcondntemp.forEach((element: any) => {
        let eleindx = this.selectedFilter.findIndex((p: any) => p.column === element.column);

        // element found
        if (eleindx >= 0) {
          this.selectedFilter[eleindx].oper = element.oper;
          this.selectedFilter[eleindx].value = element.value;
          this.selectedFilter[eleindx].value1 = element.value1;

          if (this.selectedFilter[eleindx].type === 'date') {
            this.selectedFilter[eleindx].value = (!isNullOrUndefined(this.selectedFilter[eleindx].value)
              && this.selectedFilter[eleindx].value.length > 0 ? new Date(this.selectedFilter[eleindx].value) : '');
            this.selectedFilter[eleindx].value1 = (!isNullOrUndefined(this.selectedFilter[eleindx].value1)
              && this.selectedFilter[eleindx].value1.length > 0 ? new Date(this.selectedFilter[eleindx].value1) : '');
          }
        }

      });

    }

    this.prevSelectedFilter = JSON.stringify(this.selectedFilter);

  }


  ngAfterViewInit() {

    
    setTimeout(() => {

       this.focusableEles = this.eleRef.nativeElement.querySelectorAll(`a[href], area[href],
     input:not([disabled]), select:not([disabled]), textarea:not([disabled]),
          button:not([disabled]), [tabindex="0"]`);
         this.focusableEles = Array.prototype.slice.call(this.focusableEles);

        if (this.focusableEles.length > 0) {
            this.firstFocusableEle = this.focusableEles[0];
            this.lastFocusableEle = this.focusableEles[this.focusableEles.length - 1];

            this.firstFocusableEle.focus();
        }

        let yesctrl = this.eleRef.nativeElement.querySelector('input[name="btnYes"]') as HTMLElement;
        if (!isNullOrUndefined(yesctrl)) {
            yesctrl.focus();
        }
        else {
            let noctrl = this.eleRef.nativeElement.querySelector('input[name="btnNo"]') as HTMLElement;
            if (!isNullOrUndefined(noctrl)) {
                noctrl.focus();
            }
        }
        let divele: HTMLDivElement = this.eleRef.nativeElement.querySelector('.modal-dialog');
        if (!isNullOrUndefined(divele)) {
            divele.addEventListener('keydown', (e) => { this.handleKeyDown(e); });
        }

    }, 10);
}
handleKeyDown(event: any) {
    let KEY_TAB = 9;
    let KEY_ESC = 27;

    switch (event.keyCode) {
        case KEY_TAB:
            if (this.focusableEles.length === 1) {
                event.preventDefault();
                break;
            }

            if (event.shiftKey) {
                if (document.activeElement === this.firstFocusableEle) {
                    event.preventDefault();
                    this.lastFocusableEle.focus();
                }
            }
            else {
                if (document.activeElement === this.lastFocusableEle) {
                    event.preventDefault();
                    this.firstFocusableEle.focus();
                }
            }
            break;
        // case KEY_ESC:
        //   this.close();
        //   break;
        default:
            break;
    }

}
  ngOnChanges() {
    // this.setFilterColsData();
  }

  closeFilter(): void {
    this.selectedFilter = JSON.parse(this.prevSelectedFilter);

    // For date fields, set the value as date instead of string.
    this.selectedFilter.forEach((item: any) => {
      if (item.type === 'date') {
        item.value = (!isNullOrUndefined(item.value) && item.value.length > 0 ? new Date(item.value) : '');
        item.value1 = (!isNullOrUndefined(item.value1) && item.value1.length > 0 ? new Date(item.value1) : '');
      }
    });

    this.close.emit({ curFiltCondn: JSON.stringify(this.selectedFilter) });
  }

  closeFilterClick(): void {
    // this.selectedFilter = JSON.parse(this.prevSelectedFilter);

    // // For date fields, set the value as date instead of string.
    // this.selectedFilter.forEach(item => {
    //   if (item.type === 'date') {
    //     item.value = (!isNullOrUndefined(item.value) && item.value.length > 0 ? new Date(item.value) : '');
    //     item.value1 = (!isNullOrUndefined(item.value1) && item.value1.length > 0 ? new Date(item.value1) : '');
    //   }
    // });

    this.close.emit();
  }


  applyFilter(): void {
    this.prevSelectedFilter = JSON.stringify(this.selectedFilter);
    this.selected.emit({ sCondn: this.FiltCondn });
  }

  resetFilter(): void {
    this.FiltCondn = '';
    this.applyFilter();
  }

  getFiltTypes(TemplField: FieldTemplate): string {
    let type: string = '';

    // FieldTemplate types - button, icon, link, checkbox, label, textbox, textarea, dropdown, datepicker
    // Filter types - Date, number, text, list

    switch (TemplField?.Control?.Type.toLowerCase()) {
      case 'button':
      case 'icon':
      case 'dataimg':
        // case 'checkbox':
        type = 'NA';
        break;

      case 'link':
      case 'label':
      case 'textarea':
        type = 'text';
        break;

      case 'textbox':
        type = (TemplField?.Control?.InputType?.toLowerCase() === 'number' ? 'number' : 'text');
        break;

      case 'checkbox':
        type = (TemplField?.Control?.InputType?.toLowerCase() === 'toggle' ? 'staticlist' : 'NA');
        break;

      case 'radio':
        type = 'list';
        break;

      case 'dropdown':
        type = (TemplField?.Control?.InputType?.toLowerCase() === 'multi' ? 'multi' : 'list');
        break;

      case 'datepicker':
        type = 'date';
        break;

      case 'badge':
        type = (TemplField?.Control?.InputType?.toLowerCase() === 'number' ? 'number' : 'list');
        break;
    }

    return type;
  }

  setFilterCols(): void {
    if (this.FieldTemplate.length > 0) {
      // Only the displayed columns are used in filter
      let filtcols = this.FieldTemplate.filter(x => x.IsHidden === false);

      filtcols.forEach(TemplField => {
        let filtItem = {
          displayname: TemplField.DisplayName,
          column: TemplField.FieldName,
          type: this.getFiltTypes(TemplField),
          source: 'grid',                     // later to be loaded from DB
          loadsp: '',                         // later to be loaded from DB
          linkfieldname: (!isNullOrUndefined(TemplField.LinkedField) && TemplField.LinkedField!.length > 0 ? (TemplField.LinkedField || '') : ''),
          datalist: ''
        };

        if (filtItem.type !== 'NA') {
          this.FilterColsDet.push(filtItem);
        }

      });
    }

  }

  setFilterColsData(): void {

    for (let i = 0; i < this.FilterColsDet.length; i++) {
      // console.log('filter i>>' + i);
      switch (this.FilterColsDet[i].type.toUpperCase()) {
        case 'DATE':
          this.FilterColsDet[i].datalist = '';
          break;

        case 'NUMBER':
          this.FilterColsDet[i].datalist = '';
          break;

        case 'TEXT':
          this.FilterColsDet[i].datalist = '';
          break;

        case 'LIST':
          let objTemp = asEnumerable(this.GridData)
            .Distinct((x: any) => (this.FilterColsDet[i].linkfieldname === '' ? x[this.FilterColsDet[i].column] : x[this.FilterColsDet[i].linkfieldname]))
            .Select((x: any) => (this.FilterColsDet[i].linkfieldname === '' ? x[this.FilterColsDet[i].column] : x[this.FilterColsDet[i].linkfieldname]))
            // .Select(x => { return( x[this.FilterColsDet[i].column].length > 0? x[this.FilterColsDet[i].column]: null) })
            .ToArray()
            .sort();

          if (!isNullOrUndefined(objTemp)) {
            // remove blank value in the array
            let ind = objTemp.indexOf('');
            if (ind >= 0) {
              objTemp.splice(ind, 1);
            }

            // if multi dropdown
            // objTemp.forEach(element => {
            //   if(element.indexOf(',') > -1) {
            //     element.split(',');
            //   }
            // });

            if (objTemp.length > 0) {
              this.FilterColsDet[i].datalist = asEnumerable(objTemp)
                .Select((value, index) => ({ id: (index ? index + 1 : 1), text: value }))
                .ToArray();
            }
            else {
              this.FilterColsDet[i].datalist = '';
            }
          }
          else {
            this.FilterColsDet[i].datalist = '';
          }

          // console.log('filt datalist >> ' + JSON.stringify(this.FilterColsDet[i].datalist));

          break;

        case 'STATICLIST':
          this.FilterColsDet[i].datalist = [{ 'Code': true, 'CodeDescription': 'True' }, { 'Code': false, 'CodeDescription': 'False' }];
          this.FilterColsDet[i].type = 'list';
          break;

        // multi dropdown
        case 'MULTI':
          let obj = asEnumerable(this.GridData)
            .Distinct((x: any) => (this.FilterColsDet[i].linkfieldname === '' ? x[this.FilterColsDet[i].column] : x[this.FilterColsDet[i].linkfieldname]))
            .Select((x: any) => (this.FilterColsDet[i].linkfieldname === '' ? x[this.FilterColsDet[i].column] : x[this.FilterColsDet[i].linkfieldname]))
            // .Select(x => { return( x[this.FilterColsDet[i].column].length > 0? x[this.FilterColsDet[i].column]: null) })
            .ToArray()
            .sort();

          if (!isNullOrUndefined(obj)) {
            // remove blank value in the array
            let ind = obj.indexOf('');
            if (ind >= 0) {
              obj.splice(ind, 1);
            }

            // if multi dropdown
            let tempObj: any = [];

            obj.forEach(element => {
              if (element.indexOf(',') > -1) {
                let temp: any[] = element.split(',');
                temp.forEach(x => {
                  tempObj.push(x)
                })
              }
              else {
                tempObj.push(element);
              }
            });

            // To handle case-insensitive sorting
            tempObj = tempObj.sort(function (a: string, b: string) {
              return a.toLowerCase().localeCompare(b.toLowerCase());
            });

            obj = tempObj.map((x: any) => x)
              .filter((p: any, index: number, arr: any) => (arr.findIndex((p2: any) => p2 === p) === index));

            this.FilterColsDet[i].datalist = asEnumerable(obj)
              .Select((value, index) => ({ id: (index ? index + 1 : 1), text: value }))
              .ToArray();

          }

          break;
      }

    }
  };

  onDateChange(index: number) {
    this.selectedFilter[index].value = isNullOrUndefined(this.selectedFilter[index].value) ? '' :
      new Date(new Date(this.selectedFilter[index].value).toDateString());
    this.selectedFilter[index].value1 = isNullOrUndefined(this.selectedFilter[index].value1) ? '' :
      new Date(new Date(this.selectedFilter[index].value1).toDateString());
  }


  clearFilterCondn(): void {
    this.selectedFilter.forEach((item: any) => {
      item.oper = (item.type === 'text' || item.type === 'multi') ? 'CT' : 'EQ';
      item.value = '';
      item.value1 = '';
    });
  }

  getOperator(Oper: string): string {
    let result: string;

    switch (Oper) {
      default:
      case 'EQ':  // Equals
        result = ' === ';
        break;
      case 'NEQ': // Does not Equal
        result = ' !== ';
        break;
      case 'GT':  // Greater Than
      case 'AF':  // After
        result = ' > ';
        break;
      case 'GTEQ':    // Greater Than or Equal To
        result = ' >= ';
        break;
      case 'LT':  // Less Than
      case 'BF':  // Before
        result = ' < ';
        break;
      case 'LTEQ':    // Less Than or Equal To
        result = ' <= ';
        break;
      case 'BW':  // Between
        result = ' <> ';
        break;
    }

    return result;
  }

  checkSpecialChar(text: string) {
    // Handle special characters - /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/
    // Handling for char - $.()'"/^?!*+
    text = text.replace(/\(/g, '\\(');
    text = text.replace(/\)/g, '\\)');
    text = text.replace(/\//g, '\\/');
    text = text.replace(/\+/g, '\\+');
    text = text.replace(/\'/g, '\'');
    text = text.replace(/\"/g, '\"');
    text = text.replace(/\$/g, '\\$');
    text = text.replace(/\./g, '\\.');
    text = text.replace(/\^/g, '\\^');
    text = text.replace(/\?/g, '\\?');
    text = text.replace(/\!/g, '\\!');
    text = text.replace(/\*/g, '\\*');

    return (text);
  }

  frameFilterCondn() {
    let strFilt = '';

    console.log('this.selectedFilter >> ' + JSON.stringify(this.selectedFilter));
    for (let i = 0; i < this.selectedFilter.length; i++) {
      // console.log(this.selectedFilter[i]);
      switch (this.selectedFilter[i].type.toUpperCase()) {
        case 'TEXT':
          if (this.selectedFilter[i].value !== null && this.selectedFilter[i].value !== undefined) {
            if (this.selectedFilter[i].value.length > 0) {
              strFilt = strFilt + (strFilt.length > 0 ? ' && ' : '');
              let fieldname = this.selectedFilter[i].column;
              // let fieldname = (this.selectedFilter[i].linkfieldname === ''? this.selectedFilter[i].column: this.selectedFilter[i].linkfieldname);
              let selOper = this.selectedFilter[i].oper;
              let curCondn = '';

              let selValue = this.checkSpecialChar(this.selectedFilter[i].value);

              // [{ code: 'EQ', desc: 'Equals' }, { code: 'NEQ', desc: 'Does not Equal' }, { code: 'CT', desc: 'Contains' },
              //  { code: 'NCT', desc: 'Does not Contain' }, { code: 'SW', desc: 'Starts with' }, { code: 'EW', desc: 'Ends with' }]
              switch (selOper) {
                case 'EQ':  // Equals
                case 'NEQ': // Does not Equal
                  curCondn = curCondn + '$.' + fieldname + this.getOperator(selOper) + '\'' + selValue + '\'';
                  break;

                case 'CT':  // Contains
                  curCondn = curCondn + '$.' + fieldname + '.match(/' + selValue + '/ig)';
                  break;

                case 'NCT': // Does not Contain  // e.g: ^((?!test).)*$
                  curCondn = curCondn + '$.' + fieldname + '.match(/^((?!' + selValue + ').)*$/ig)';
                  break;

                case 'SW':  // Starts with
                  curCondn = curCondn + '$.' + fieldname + '.match(/^' + selValue + '/ig)';
                  break;

                case 'EW':  // Ends with
                  curCondn = curCondn + '$.' + fieldname + '.match(/' + selValue + '$/ig)';
                  break;
              }


              // console.log('curCondn = ' + curCondn);
              strFilt = strFilt + curCondn;
              console.log('strFilt = ' + strFilt);
            }
          }

          break;

        case 'NUMBER':
          if (this.selectedFilter[i].oper !== 'BW') {
            if (this.selectedFilter[i].value.toString().length > 0) {
              strFilt = strFilt + (strFilt.length > 0 ? ' && ' : '') + '$.' + this.selectedFilter[i].column +
                this.getOperator(this.selectedFilter[i].oper) + ' ' + this.selectedFilter[i].value + ' ';
            }
          }
          else {
            if (this.selectedFilter[i].value.toString().length > 0 && this.selectedFilter[i].value1.toString().length > 0) {
              strFilt = strFilt + (strFilt.length > 0 ? ' && ' : '') + '$.' + this.selectedFilter[i].column +
                ' >= ' + this.selectedFilter[i].value + ' && $.' + this.selectedFilter[i].column +
                ' <= ' + this.selectedFilter[i].value1;
            }
          }

          break;

        case 'LIST':
          if (this.selectedFilter[i].value !== null && this.selectedFilter[i].value !== undefined) {
            if (this.selectedFilter[i].value.length > 0) {
              strFilt = strFilt + (strFilt.length > 0 ? ' && ' : '');
              // let fieldname = (this.selectedFilter[i].linkfieldname === ''? this.selectedFilter[i].column: this.selectedFilter[i].linkfieldname);
              let fieldname = (this.selectedFilter[i].linkfieldname === '' ? this.selectedFilter[i].column : this.selectedFilter[i].linkfieldname);
              let selOper = this.selectedFilter[i].oper;
              let curCondn = '(';

              for (let j = 0; j < this.selectedFilter[i].value.length; j++) {
                let selValue = this.checkSpecialChar(this.selectedFilter[i].value[j]);

                // Special handling for Null as blank value
                if (selValue.toLowerCase() === 'null') {
                  // .Where(y => y !== null) - Check for nulls in linq.js
                  // .Where(ans => !string.IsNullOrEmpty(ans))
                  curCondn = curCondn + '$.' + fieldname + this.getOperator(selOper) + ' null';
                }
                else {
                  // [{ code: 'EQ', desc: 'Equals' }, { code: 'NEQ', desc: 'Does not Equal' }]
                  curCondn = curCondn + '$.' + fieldname + this.getOperator(selOper) + '\'' + selValue + '\'';
                }

                if (j + 1 === this.selectedFilter[i].value.length) {
                  curCondn = curCondn + ')';
                }
                else {
                  curCondn = curCondn + (selOper === 'NEQ' ? ' && ' : ' || ');
                }

              }

              // console.log('curCondn = ' + curCondn);
              strFilt = strFilt + curCondn;
              // console.log('strFilt = ' + strFilt);
            }
          }

          break;

        case 'MULTI':
          if (this.selectedFilter[i].value !== null && this.selectedFilter[i].value !== undefined) {
            if (this.selectedFilter[i].value.length > 0) {
              strFilt = strFilt + (strFilt.length > 0 ? ' && ' : '');
              // let fieldname = (this.selectedFilter[i].linkfieldname === ''? this.selectedFilter[i].column: this.selectedFilter[i].linkfieldname);
              let fieldname = (this.selectedFilter[i].linkfieldname === '' ? this.selectedFilter[i].column : this.selectedFilter[i].linkfieldname);
              let selOper = this.selectedFilter[i].oper;
              let curCondn = '(';

              for (let j = 0; j < this.selectedFilter[i].value.length; j++) {
                let selValue = this.checkSpecialChar(this.selectedFilter[i].value[j]);


                switch (selOper) {
                  case 'EQ':  // Equals
                  case 'NEQ': // Does not Equal
                    curCondn = curCondn + '$.' + fieldname + this.getOperator(selOper) + '\'' + selValue + '\'';
                    break;

                  case 'CT':  // Contains
                    curCondn = curCondn + '$.' + fieldname + '.match(/' + selValue + '/ig)';
                    break;

                  case 'NCT': // Does not Contain  // e.g: ^((?!test).)*$
                    curCondn = curCondn + '$.' + fieldname + '.match(/^((?!' + selValue + ').)*$/ig)';
                    break;

                  case 'SW':  // Starts with
                    curCondn = curCondn + '$.' + fieldname + '.match(/^' + selValue + '/ig)';
                    break;

                  case 'EW':  // Ends with
                    curCondn = curCondn + '$.' + fieldname + '.match(/' + selValue + '$/ig)';
                    break;
                }

                // Special handling for Null as blank value
                // if (selValue.toLowerCase() === 'null') {
                //   // .Where(y => y !== null) - Check for nulls in linq.js
                //   // .Where(ans => !string.IsNullOrEmpty(ans))
                //   curCondn = curCondn + '$.' + fieldname + this.getOperator(selOper) + ' null';
                // }
                // else {
                //   // [{ code: 'EQ', desc: 'Equals' }, { code: 'NEQ', desc: 'Does not Equal' }]
                //   curCondn = curCondn + '$.' + fieldname + this.getOperator(selOper) + '\'' + selValue + '\'';
                // }

                if (j + 1 === this.selectedFilter[i].value.length) {
                  curCondn = curCondn + ')';
                }
                else {
                  curCondn = curCondn + (selOper === 'NEQ' ? ' && ' : ' || ');
                }

              }

              // console.log('curCondn = ' + curCondn);
              strFilt = strFilt + curCondn;
              // console.log('strFilt = ' + strFilt);
            }
          }

          break;

        case 'DATE':
          if (this.selectedFilter[i].oper !== 'BW') {
            if (this.IsDate(this.selectedFilter[i].value)) {
              // console.log('valid date');

              let tempstdt = new Date(this.selectedFilter[i].value);
              let tempendt = new Date((this.addDays(new Date(tempstdt), 1)).toDateString());

              // Equal and Not Equal not working!
              // 'new Date($.StartDate) > new Date('Wed Feb 01 2017 00:00:00 GMT+0530 (India Standard Time)')'
              if (this.selectedFilter[i].oper === 'EQ') {
                // strFilt = strFilt + (strFilt.length > 0 ? ' && ' : '') + 'new Date($.' + this.selectedFilter[i].column + ')' +
                //   ' >= ' + 'new Date(\'' + this.selectedFilter[i].value + '\') && new Date($.' + this.selectedFilter[i].column + ')' +
                //   ' <= ' + 'new Date(\'' + this.selectedFilter[i].value + '\')';

                strFilt = strFilt + (strFilt.length > 0 ? ' && ' : '') + 'new Date($.' + this.selectedFilter[i].column + ')' +
                  ' >= ' + 'new Date(\'' + tempstdt + '\') && new Date($.' + this.selectedFilter[i].column + ')' +
                  ' < ' + 'new Date(\'' + tempendt + '\')';

              }
              else if (this.selectedFilter[i].oper === 'NEQ') {
                // strFilt = strFilt + (strFilt.length > 0 ? ' && ' : '') + 'new Date($.' + this.selectedFilter[i].column + ')' +
                //   ' > ' + 'new Date(\'' + this.selectedFilter[i].value + '\') || new Date($.' + this.selectedFilter[i].column + ')' +
                //   ' < ' + 'new Date(\'' + this.selectedFilter[i].value + '\')';

                strFilt = strFilt + (strFilt.length > 0 ? ' && ' : '') + 'new Date($.' + this.selectedFilter[i].column + ')' +
                  ' >= ' + 'new Date(\'' + tempendt + '\') || new Date($.' + this.selectedFilter[i].column + ')' +
                  ' < ' + 'new Date(\'' + tempstdt + '\')';
              }
              //added for after filter case as getoperator method was not working for after case
              else if (this.selectedFilter[i].oper === 'AF') {
                strFilt = strFilt + (strFilt.length > 0 ? ' && ' : '') + 'new Date($.' + this.selectedFilter[i].column + ')' +
                  ' >= ' + 'new Date(\'' + tempendt + '\')';

              }
                //added for before filter case as getoperator method was breaking the code 
              else if (this.selectedFilter[i].oper === 'BF') {
                strFilt = strFilt + (strFilt.length > 0 ? ' && ' : '') + 'new Date($.' + this.selectedFilter[i].column + ')' +
                  //   this.getOperator(this.selectedFilter[i].oper) + 'new Date(\'' + this.selectedFilter[i].value + '\')';
                  ' < ' + 'new Date(\'' + tempstdt + '\')';
              }

            }
          }
             //added for between filter case as there were issues in logic 
          else {
          
            if (this.IsDate(this.selectedFilter[i].value) && this.IsDate(this.selectedFilter[i].value1)) {
              // console.log('valid dates');
              let tempstdt = new Date(this.selectedFilter[i].value);
              let tempendt = new Date((this.addDays(new Date(tempstdt), 1)).toDateString());
              let tempval1stdt = new Date(this.selectedFilter[i].value1);
              let tempval1endt = new Date((this.addDays(new Date(tempval1stdt), 1)).toDateString());
              strFilt = strFilt + (strFilt.length > 0 ? ' && ' : '') + 'new Date($.' + this.selectedFilter[i].column + ')' +
                ' >= ' + 'new Date(\'' + tempstdt + '\') && new Date($.' + this.selectedFilter[i].column + ')' +
                ' < ' + 'new Date(\'' + tempval1endt + '\')';
            }

            else {
              console.log('not valid dates');
            }

          }
          break;
      }

    }

    if (strFilt.length > 0) {
      // strFilt = strFilt.substr(4, strFilt.length)
      console.log('strFilt = ' + strFilt);

      this.FiltCondn = strFilt;

      // this.filtergriddata(strFilt);

      this.applyFilter();

    }
    else {
      this.resetFilter();
    }

    this.closeFilter();
  }

  IsDate(x: any) {
    return (null !== x) && !isNaN(x) && ('undefined' !== typeof x.getDate);
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

}
