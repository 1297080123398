import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessResponse } from '../app.interface';
import { environment } from '../../environments/environment';


@Injectable()
export class HomeService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private CommAPIUrl: string;
  private RequestUrl: string;

  constructor(private _http: HttpClient) {
    // this.CommAPIUrl = environment.baseURL + '/Common/';
    // this.RequestUrl = environment.baseURL + '/Request/';

    this.CommAPIUrl = environment.baseURL + '/Common/';
    this.RequestUrl = environment.apiURL.RequestURL;
  }

  private handleError(error: any): Promise<any> {
    console.error('Error : ', error.message);
    return Promise.reject(error.message || error);
  }

  // async GetRoleList(): Promise<ProcessResponse<any>> {
  //   let Params = new HttpParams();

  //   let response = await this._http.get<ProcessResponse<any>>(this.CommAPIUrl + 'GetRoleDetails',
  //     { headers: this.headers, params: Params }).toPromise();
  //   return response;
  // }


  // async GetEmployeeDetails(reqdata: any): Promise<ProcessResponse<any>> {
  //   let Params = new HttpParams();
  //   Params = Params.append('employeeNumber', reqdata.EmployeeNumber);

  //   let response = await this._http.get<ProcessResponse<any>>(this.CommAPIUrl + 'GetEmployeeDetails',
  //     { headers: this.headers, params: Params }).toPromise();
  //   return response;
  // }


  getRequestDetailsBySearch(odata: any, allPendingFlag: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('AllPendingFlag', allPendingFlag);
    Params = Params.append('PageSize', odata.PageSize);
    Params = Params.append('PageIndex', odata.PageIndex);
    

    return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'GetRequestDetailsBySearch', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getRequestDetailsBySearchClosedGreen(odata: any, allPendingFlag: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('AllPendingFlag', allPendingFlag);
    Params = Params.append('PageSize', odata.PageSize);
    Params = Params.append('PageIndex', odata.PageIndex);
    

    return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'GetRequestDetailsBySearchClosedGreen', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  UpdateRequestOfferStatus(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.RequestUrl + 'UpdateRequestOfferStatus', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  



}
