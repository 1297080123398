<ng-container>
    <!-- Header Section -->
    <nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
        <!-- Logo Section -->
        <a class="navbar-brand" aria-label="Capgemini Logo Redirect to Home Page" href="index.html">
            <img src="assets/images/Capgemini-Logo.svg" alt="Capgemini Logo" /></a>
    </nav>


    <!-- Body Section -->
    <div id="bodyContainer" class="bodyContainer">
        <div class="contentScroll full simpleCard">

            <div class="d-flex flex-column justify-content-between h-100">
                <div class="line-image"><span class="sr-only">Capgemini Line image canvas</span></div>
                <div class="h-100">
                    <div class="row align-items-stretch g-0 h-100">
                        <div class="col-7">
                            <div class="bgWrapper">
                                <span class="sr-only">Background image canvas</span>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="row g-0 justify-content-center align-items-center h-100">
                                <div class="col-9 simpleCard customPosition">
                                    <div class="loginContainer">
                                        <div class="card">
                                            <div class="card-header">
                                                <h3>
                                                    <span>Forgot Password</span>
                                                </h3>
                                            </div>
                                            <div class="card-body overflow-auto py-4">
                                                <p class="text-secondary">
                                                    Enter the email associated with your account and we will send a
                                                    mail to reset your password
                                                </p>
                                                <div class="row small-gutter form-inline">
                                                    <div class="col-12">
                                                        <div class="form-group mb-2">
                                                            <label for="emp1" class="required">Email Address</label>
                                                            <div>
                                                                <div class="input-group">
                                                                    <span class="input-group-text" id="basic-addon1"><i
                                                                            class="fa fa-user"></i></span>
                                                                    <input type="text" id="txtEmailId"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="username@domain.com"
                                                                        aria-label="username@domain.com"
                                                                        aria-describedby="basic-addon1">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="filters buttonHolder px-3">
                                                <div class="d-flex justify-content-between">
                                                    <div>
                                                        <a aria-label="Back To Login" href="#" [routerLink]="['/login']"
                                                            class="text-decoration-underline align-self-center">Back to Login</a>
                                                    </div>
                                                    <div>
                                                        <button type="button" class="btnCustom btnPrimary rounded-pill" 
                                                            (click)="ResetLoginPassword()">
                                                            <span>Send Email</span><i class="ion ion-md-arrow-forward"
                                                                aria-hidden="true"></i>
                                                        </button>
                                                       
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="d-flex justify-content-center bg-light p-2">
                        <div class="small align-self-center text-muted">Copyright © 2022 Capgemini All rights
                            reserved.
                        </div>

                    </div>
                </div>

            </div>




        </div>
    </div>

</ng-container>

<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>


<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
        (selected)="setNotifyRes($event) " (close)="closeNotifybox() "></notification>
</div>