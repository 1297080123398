import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ComponentCodes, ComponentConfig, ProcessStatus } from 'src/app/app.interface';
import { CommonAPIService } from 'src/app/shared/app.commonservice';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined } from 'src/app/shared/app.sharedservice';
import { environment } from 'src/environments/environment';
import { FormComponent } from 'src/modules/form/form.component';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { ModalComponent } from 'src/modules/modal/modal.component';
import { ModalService } from 'src/modules/modal/modal.service';
import { RequestService } from '../request.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
@Component({
  selector: 'email',
  templateUrl: './email.component.html'
})

@AutoUnsubscribe
export class EmailComponent implements OnInit {
  @Input() RequestId: number = 0;
  @Input() CheckId: number = 0;
  @Input() RequestData: any;
  @Input() RequestTemplate: FieldTemplate[] = [];
  @Input() RequestProperty: any = [];
  public EmailTemplate: FieldTemplate[] = [];
  public viewEmailData: any[] = [];
  public Emailbuttons: any = [];
  public selectedTab: string = '';
  public ACTION: string = '';
  public subscriptionList$: Subscription[] = [];
  public IsLoading: boolean = false;
  public viewEmail: boolean = false;
  public viewEmailTemp: boolean = false;
  public displayNotifybox: boolean = false;
  public displayAllAttachmentbox: boolean = false;
  public DisplayAttachment: boolean = false;
  public successFlag: boolean = false;
  public selectedIndex: any;
  public notify: NotificationData = new NotificationData();
  public selectedEmailData: any = {};
  public selectedEmailActionData: any = {};
  public selectedInfoData: any = {};
  public fileRefId: any[] = [];
  public fileNames: any[] = [];
  public fileData: any[] = [];
  public selectedData: any = {};
  public actionCodes: any = {
    'Forward': 'ACM003',
    'Resend': 'ACM004',
    'Create_Email': 'ACM005'
  }
  public GlobalConfig: any = {};
  public GridProp: any = {
    Editable: true,
    IsSortable: false
  };
  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };
  public AttachPerm: any = {
    Upload: true,
    Download: true
  };
  public FormProp: FormProperty = {
    'ColSize': 2,
    'CaptionBold': false,
    'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };

  public focusableEles!: HTMLElement[];
  public firstFocusableEle!: HTMLElement;
  public lastFocusableEle!: HTMLElement;
  constructor(private eleRef: ElementRef, private _requestService: RequestService, private _commonAPIService: CommonAPIService
    , private _appSharedService: AppSharedService) {
    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);
  }

  ngOnInit(): void {
    console.log(this.RequestProperty)
    this.getMailerTransaction();
    console.log(this.RequestTemplate);
    this.selectedInfoData.IsEditable = 1;
    //     this.Config.ActionButtons = JSON.parse(`[{
    //    "Name":"ExportToExcel",
    //    "Icon":"fa fa-file-excel-o",
    //    "DisplayName":"<u>D</u>ownload Excel",
    //    "AccessKey":"N",
    //    "IsApplicable":true,
    //    "HasPermission":true,
    //    "IsEnabled":true,
    //    "Confirmation":{
    //       "IsEnabled":false,
    //       "Message":null,
    //       "Action":null
    //    },
    //    "SeqOrder":1
    // }]`);
    //this.candidateTemplate = this.requestTemplate.filter(x => x.Group === 'Request');
    // this.EmailTemplate = this.EmailTemplate.filter(x => x.Group === 'RequestStatus');
    this.EmailTemplate = JSON.parse(`[
      {
        "FieldName": "ToRoleId",
        "DisplayName": "To",
        "LabelTemplate": null,
        "LinkedField": "ToRoleName",
        "ColumnWidth": "10",
        "Control": {
          "Type": "dropdown",
          "InputType": "multi",
          "List": null,
          "Source": {
            "Url": "Mailer/GetUserRoles",
            "KeyField": "RoleId",
            "ValueField": "RoleName",
            "UrlAttribute": null,
            "Target": null
          },
          "DependentControl": null,
          "Format": null,
          "LoadedUrl": null,
          "DynamicLoad": false
        },
        "Cssnames": "",
        "Style": "",
        "Events": "",
        "Editable": 1,
        "IsHidden": false,
        "ToolTipTemplate": null,
        "EditValidate": {
          "Required": false,
          "Min": null,
          "Max": null
        },
        "FieldCollection": null,
        "Group": "CreateEmail_form",
        "ResponsiveMediaSize": "md,lg,xl"
      },
      {
        "FieldName": "CCRoleId",
        "DisplayName": "CC",
        "LabelTemplate": null,
        "LinkedField": "CCRoleName",
        "ColumnWidth": "10",
        "Control": {
          "Type": "dropdown",
          "InputType": "multi",
          "List": null,
          "Source": {
            "Url": "Mailer/GetUserRoles",
            "KeyField": "RoleId",
            "ValueField": "RoleName",
            "UrlAttribute": null,
            "Target": null
          },
          "DependentControl": null,
          "Format": null,
          "LoadedUrl": null,
          "DynamicLoad": false
        },
        "Cssnames": "",
        "Style": "",
        "Events": "",
        "Editable": 1,
        "IsHidden": false,
        "ToolTipTemplate": null,
        "EditValidate": {
          "Required": false,
          "Min": null,
          "Max": null
        },
        "FieldCollection": null,
        "Group": "CreateEmail_form",
        "ResponsiveMediaSize": "md,lg,xl"
      },
      {
        "FieldName": "Subject",
        "DisplayName": "Subject",
        "LabelTemplate": null,
        "LinkedField": null,
        "ColumnWidth": "55",
        "Control": {
          "Type": "textarea",
          "InputType": "text",
          "List": null,
          "Source": {
            "Url": null,
            "KeyField": null,
            "ValueField": null,
            "UrlAttribute": null,
            "Target": null
          },
          "DependentControl": null,
          "Format": null,
          "LoadedUrl": null
        },
        "Cssnames": "",
        "Style": "",
        "Events": "",
        "Editable": 1,
        "IsHidden": false,
        "ToolTipTemplate": null,
        "EditValidate": {
          "Required": false,
          "Min": 0,
          "Max": 250
        },
        "FieldCollection": null,
        "Group": "CreateEmail_form",
        "ResponsiveMediaSize": "md,lg,xl"
      },
      {
        "FieldName": "Message",
        "DisplayName": "Message",
        "LabelTemplate": null,
        "LinkedField": null,
        "ColumnWidth": "110",
        "Control": {
          "Type": "textarea",
          "InputType": "text",
          "List": null,
          "Source": {
            "Url": null,
            "KeyField": null,
            "ValueField": null,
            "UrlAttribute": null,
            "Target": null
          },
          "DependentControl": null,
          "Format": null,
          "LoadedUrl": null
        },
        "Cssnames": "",
        "Style": "",
        "Events": "",
        "Editable": 1,
        "IsHidden": false,
        "ToolTipTemplate": null,
        "EditValidate": {
          "Required": false,
          "Min": 0,
          "Max": 1000
        },
        "FieldCollection": null,
        "Group": "CreateEmail_form",
        "ResponsiveMediaSize": "md,lg,xl"
      },
      {
        "FieldName": "ToRoleId",
        "DisplayName": "To",
        "LabelTemplate": null,
        "LinkedField": "ToRoleName",
        "ColumnWidth": "10",
        "Control": {
          "Type": "dropdown",
          "InputType": "multi",
          "List": null,
          "Source": {
            "Url": "Mailer/GetUserRoles",
            "KeyField": "RoleId",
            "ValueField": "RoleName",
            "UrlAttribute": null,
            "Target": null
          },
          "DependentControl": null,
          "Format": null,
          "LoadedUrl": null,
          "DynamicLoad": false
        },
        "Cssnames": "",
        "Style": "",
        "Events": "",
        "Editable": 1,
        "IsHidden": false,
        "ToolTipTemplate": null,
        "EditValidate": {
          "Required": false,
          "Min": null,
          "Max": null
        },
        "FieldCollection": null,
        "Group": "EmailQueue_form",
        "ResponsiveMediaSize": "md,lg,xl"
      },
      {
        "FieldName": "CCRoleId",
        "DisplayName": "CC",
        "LabelTemplate": null,
        "LinkedField": "CCRoleName",
        "ColumnWidth": "10",
        "Control": {
          "Type": "dropdown",
          "InputType": "multi",
          "List": null,
          "Source": {
            "Url": "Mailer/GetUserRoles",
            "KeyField": "RoleId",
            "ValueField": "RoleName",
            "UrlAttribute": null,
            "Target": null
          },
          "DependentControl": null,
          "Format": null,
          "LoadedUrl": null,
          "DynamicLoad": false
        },
        "Cssnames": "",
        "Style": "",
        "Events": "",
        "Editable": 1,
        "IsHidden": false,
        "ToolTipTemplate": null,
        "EditValidate": {
          "Required": false,
          "Min": null,
          "Max": null
        },
        "FieldCollection": null,
        "Group": "EmailQueue_form",
        "ResponsiveMediaSize": "md,lg,xl"
      },
      {
        "FieldName": "Message",
        "DisplayName": "Message",
        "LabelTemplate": null,
        "LinkedField": null,
        "ColumnWidth": "55",
        "Control": {
          "Type": "textarea",
          "InputType": "text",
          "List": null,
          "Source": {
            "Url": null,
            "KeyField": null,
            "ValueField": null,
            "UrlAttribute": null,
            "Target": null
          },
          "DependentControl": null,
          "Format": null,
          "LoadedUrl": null
        },
        "Cssnames": "",
        "Style": "",
        "Events": "",
        "Editable": 1,
        "IsHidden": false,
        "ToolTipTemplate": null,
        "EditValidate": {
          "Required": false,
          "Min": 0,
          "Max": 1000
        },
        "FieldCollection": null,
        "Group": "EmailQueue_form",
        "ResponsiveMediaSize": "md,lg,xl"
      },
      {
        "FieldName": "ToRoleId",
        "DisplayName": "To",
        "LabelTemplate": null,
        "LinkedField": "ToRoleName",
        "ColumnWidth": "10",
        "Control": {
          "Type": "dropdown",
          "InputType": "multi",
          "List": null,
          "Source": {
            "Url": "Mailer/GetUserRoles",
            "KeyField": "RoleId",
            "ValueField": "RoleName",
            "UrlAttribute": null,
            "Target": null
          },
          "DependentControl": null,
          "Format": null,
          "LoadedUrl": null,
          "DynamicLoad": false
        },
        "Cssnames": "",
        "Style": "",
        "Events": "",
        "Editable": 1,
        "IsHidden": false,
        "ToolTipTemplate": null,
        "EditValidate": {
          "Required": false,
          "Min": null,
          "Max": null
        },
        "FieldCollection": null,
        "Group": "ReceivedEmail_form",
        "ResponsiveMediaSize": "md,lg,xl"
      },
      {
        "FieldName": "CCRoleId",
        "DisplayName": "CC",
        "LabelTemplate": null,
        "LinkedField": "CCRoleName",
        "ColumnWidth": "10",
        "Control": {
          "Type": "dropdown",
          "InputType": "multi",
          "List": null,
          "Source": {
            "Url": "Mailer/GetUserRoles",
            "KeyField": "RoleId",
            "ValueField": "RoleName",
            "UrlAttribute": null,
            "Target": null
          },
          "DependentControl": null,
          "Format": null,
          "LoadedUrl": null,
          "DynamicLoad": false
        },
        "Cssnames": "",
        "Style": "",
        "Events": "",
        "Editable": 1,
        "IsHidden": false,
        "ToolTipTemplate": null,
        "EditValidate": {
          "Required": false,
          "Min": null,
          "Max": null
        },
        "FieldCollection": null,
        "Group": "ReceivedEmail_form",
        "ResponsiveMediaSize": "md,lg,xl"
      },
      {
        "FieldName": "Message",
        "DisplayName": "Message",
        "LabelTemplate": null,
        "LinkedField": null,
        "ColumnWidth": "55",
        "Control": {
          "Type": "textarea",
          "InputType": "text",
          "List": null,
          "Source": {
            "Url": null,
            "KeyField": null,
            "ValueField": null,
            "UrlAttribute": null,
            "Target": null
          },
          "DependentControl": null,
          "Format": null,
          "LoadedUrl": null
        },
        "Cssnames": "",
        "Style": "",
        "Events": "",
        "Editable": 1,
        "IsHidden": false,
        "ToolTipTemplate": null,
        "EditValidate": {
          "Required": false,
          "Min": 0,
          "Max": 1000
        },
        "FieldCollection": null,
        "Group": "ReceivedEmail_form",
        "ResponsiveMediaSize": "md,lg,xl"
      },
      {
        "FieldName": "ToRoleId",
        "DisplayName": "To",
        "LabelTemplate": null,
        "LinkedField": "ToRoleName",
        "ColumnWidth": "10",
        "Control": {
          "Type": "dropdown",
          "InputType": "multi",
          "List": null,
          "Source": {
            "Url": "Mailer/GetUserRoles",
            "KeyField": "RoleId",
            "ValueField": "RoleName",
            "UrlAttribute": null,
            "Target": null
          },
          "DependentControl": null,
          "Format": null,
          "LoadedUrl": null,
          "DynamicLoad": false
        },
        "Cssnames": "",
        "Style": "",
        "Events": "",
        "Editable": 1,
        "IsHidden": false,
        "ToolTipTemplate": null,
        "EditValidate": {
          "Required": false,
          "Min": null,
          "Max": null
        },
        "FieldCollection": null,
        "Group": "SentEmail_form",
        "ResponsiveMediaSize": "md,lg,xl"
      },
      {
        "FieldName": "CCRoleId",
        "DisplayName": "CC",
        "LabelTemplate": null,
        "LinkedField": "CCRoleName",
        "ColumnWidth": "10",
        "Control": {
          "Type": "dropdown",
          "InputType": "multi",
          "List": null,
          "Source": {
            "Url": "Mailer/GetUserRoles",
            "KeyField": "RoleId",
            "ValueField": "RoleName",
            "UrlAttribute": null,
            "Target": null
          },
          "DependentControl": null,
          "Format": null,
          "LoadedUrl": null,
          "DynamicLoad": false
        },
        "Cssnames": "",
        "Style": "",
        "Events": "",
        "Editable": 1,
        "IsHidden": false,
        "ToolTipTemplate": null,
        "EditValidate": {
          "Required": false,
          "Min": null,
          "Max": null
        },
        "FieldCollection": null,
        "Group": "SentEmail_form",
        "ResponsiveMediaSize": "md,lg,xl"
      },
      {
        "FieldName": "Message",
        "DisplayName": "Message",
        "LabelTemplate": null,
        "LinkedField": null,
        "ColumnWidth": "55",
        "Control": {
          "Type": "textarea",
          "InputType": "text",
          "List": null,
          "Source": {
            "Url": null,
            "KeyField": null,
            "ValueField": null,
            "UrlAttribute": null,
            "Target": null
          },
          "DependentControl": null,
          "Format": null,
          "LoadedUrl": null
        },
        "Cssnames": "",
        "Style": "",
        "Events": "",
        "Editable": 1,
        "IsHidden": false,
        "ToolTipTemplate": null,
        "EditValidate": {
          "Required": false,
          "Min": 0,
          "Max": 1000
        },
        "FieldCollection": null,
        "Group": "SentEmail_form",
        "ResponsiveMediaSize": "md,lg,xl"
      },
   {
          "FieldName": "Attachment",
          "DisplayName": "Attachment",
          "LabelTemplate": null,
          "LinkedField": null,
          "ColumnWidth": "55",
          "Control": {
            "Type": "file",
            "InputType": "file",
            "List": null,
            "Source": {
              "Url": null,
              "KeyField": null,
              "ValueField": null,
              "UrlAttribute": null,
              "Target": null
            },
            "DependentControl": null,
            "Format": null,
            "LoadedUrl": null
          },
          "Cssnames": "",
          "Style": "",
          "Events": "",
          "Editable": 1,
          "IsHidden": false,
          "ToolTipTemplate": null,
          "EditValidate": {
            "Required": false,
            "Min": 0,
            "Max": 1000
          },
          "FieldCollection": null,
          "Group": "CreateEmail_form",
          "ResponsiveMediaSize": "md,lg,xl"
        }
    ]`);
    this.Emailbuttons = JSON.parse(`[
      {
         "Name":"Attachment",
         "DisplayName":"Attachment",
         "CustomClass":" btnCustom btnNegative small float-left",
         "AccessKey":"N",
         "IsApplicable":true,
         "HasPermission":true,
         "IsEnabled":true,
         "Confirmation":{
            "IsEnabled":false,
            "Message":null,
            "Action":null
         },
         "SeqOrder":1
      }
 
   ]`);

    //   {
    //     "Name":"Clear",
    //     "DisplayName":"Clear",
    //     "CustomClass":"btnCustom btnNegative small float-left",
    //     "AccessKey":"N",
    //     "IsApplicable":true,
    //     "HasPermission":true,
    //     "IsEnabled":true,
    //     "Confirmation":{
    //        "IsEnabled":false,
    //        "Message":null,
    //        "Action":null
    //     },
    //     "SeqOrder":1
    //  }


    //this.Emailbuttons = JSON.parse(`[
    //   {
    //     "Name":"Attachment",
    //     "DisplayName":"Choose Files",
    //     "CustomClass":"rounded-pill btnCustom btnNegative small float-left",
    //     "AccessKey":"N",
    //     "IsApplicable":true,
    //     "HasPermission":true,
    //     "IsEnabled":true,
    //     "Confirmation":{
    //        "IsEnabled":false,
    //        "Message":null,
    //        "Action":null
    //     },
    //     "SeqOrder":1
    //  },
    //         {
    //           "Name":"Send",
    //           "CustomClass":"rounded-pill btnCustom btnPrimary small float-right",
    //           "DisplayName":"Send",
    //           "AccessKey":"S",
    //           "ShowArrow":true,
    //           "IsApplicable":true,
    //           "HasPermission":true,
    //           "IsEnabled":true,
    //           "Confirmation":{
    //              "IsEnabled":false,
    //              "Message":null,
    //              "Action":null
    //           },
    //           "SeqOrder":1
    //        },
    //        {
    //         "Name":"Cancel",
    //         "CustomClass":"rounded-pill btnCustom btnNegative small float-right",
    //         "DisplayName":"Cancel",
    //         "AccessKey":"S",
    //         "ShowArrow":false,
    //         "IsApplicable":true,
    //         "HasPermission":true,
    //         "IsEnabled":true,
    //         "Confirmation":{
    //            "IsEnabled":false,
    //            "Message":null,
    //            "Action":null
    //         },
    //         "SeqOrder":2
    //      }
    //   ]`);
    // }
  }
  getMailerTransaction() {
    console.log(this.selectedTab)
    this.viewEmailData = [];
    this.IsLoading = true;
    let odata: any = {};

    let subscribe$: Subscription = this._requestService.GetMailTransaction(this.RequestId, this.selectedTab).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

        result.Data.forEach((x: any) => this.viewEmailData.push(x));


      }
      console.log(this.viewEmailData)
      this.IsLoading = false;

    },
      err => {
        console.log('Error on getting GetRequestData.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }


  setDefaultPageFocus() {

    setTimeout(() => {
      this.focusableEles = this.eleRef.nativeElement.querySelectorAll(`a[href], area[href],
            input:not([disabled]), select:not([disabled]), textarea:not([disabled]),
           button:not([disabled]), [tabindex="0"]`);
      this.focusableEles = Array.prototype.slice.call(this.focusableEles);

      if (this.focusableEles.length > 0) {
        this.firstFocusableEle = this.focusableEles[0];
        this.lastFocusableEle = this.focusableEles[this.focusableEles.length - 1];

        this.firstFocusableEle.focus();
      }

      //  let yesctrl = this.eleRef.nativeElement.querySelector('input[name="btnYes"]') as HTMLElement;
      //  if (!isNullOrUndefined(yesctrl)) {
      //      yesctrl.focus();
      //  }
      //  else {
      //      let noctrl = this.eleRef.nativeElement.querySelector('input[name="btnNo"]') as HTMLElement;
      //      if (!isNullOrUndefined(noctrl)) {
      //          noctrl.focus();
      //      }
      //  }
      //  let divele: HTMLDivElement = this.eleRef.nativeElement.querySelector('.modal-dialog');
      //  if (!isNullOrUndefined(divele)) {
      //      divele.addEventListener('keydown', (e) => { this.handleKeyDown(e); });
      //  }

    }, 50);
  }
  setSelectedTab(event: any) {
    console.log(event)
    this.selectedInfoData = {};
    this.ACTION = '';
    this.viewEmailTemp = false;
    this.viewEmail = false;
    this.selectedTab = event.selectedTab;
    if (this.selectedTab != 'CreateEmail') {
      this.getMailerTransaction();
    }
    else {
      this.ACTION = this.actionCodes.Create_Email;
      this.selectedInfoData.IsEditable = 1;

    }

  }


  onClick(event: any): void {
    let selitem = event.item;
    console.log(event)
    this.selectedEmailData = selitem;
    this.fileNames = [];
    this.GetAttachmentDetails(selitem.AttachmentPath);
    //  this.selectedInfoData = selitem;
    console.log(this.selectedEmailData)
    console.log(this.selectedEmailData)
    this.selectedInfoData.IsEditable = 1;
    // this.selectedInfoData.ToRoleName=this.selectedEmailData.ToRoleName;
    // this.selectedInfoData.CCRoleName=this.selectedEmailData.CCRoleName;
    //JSON.parse(JSON.stringify(selitem));
    console.log(selitem)

    // if(event.fieldname.toUpperCase()=='ACTION'){
    //   this.viewEmailTemp = true; 
    //   this.viewEmail = false;
    // }
    // else{
    //   this.viewEmailTemp = false; 

    //    this.viewEmail = true;

    // }

    switch (event.fieldname.toUpperCase()) {
      case 'ACTION':
        this.ACTION = '';
        this.selectedEmailActionData = selitem;
        this.viewEmailTemp = true;
        this.viewEmail = true;

        if (event.icon.Tooltip == 'Resend email') {
          this.ACTION = this.actionCodes.Resend;
          this.selectedInfoData = {
            "IsEditable": 1,
            "CellLock": [
              {
                "FieldName": "ToRoleId",
                "Lock": false
              },
              {
                "FieldName": "CCRoleId",
                "Lock": false
              },
              {
                "FieldName": "Subject",
                "Lock": false
              },
              {
                "FieldName": "Message",
                "Lock": false
              }
            ],
            "ToRoleId": this.selectedEmailData.ToEmailRole,
            "ToRoleName": this.selectedEmailData.ToRoleName,
            "CCRoleId": this.selectedEmailData.CCEMailRole,
            "CCRoleName": this.selectedEmailData.CCRoleName,
            "Subject": this.selectedEmailData.EmailSubject,
            "Message": this.selectedEmailData.EmailMessage
          };

        }
        if (event.icon.Tooltip == 'Forward email') {
          this.ACTION = this.actionCodes.Forward;

          this.selectedInfoData = {
            "IsEditable": 1,
            "CellLock": [
              {
                "FieldName": "ToRoleId",
                "Lock": false
              },
              {
                "FieldName": "CCRoleId",
                "Lock": false
              },
              {
                "FieldName": "Subject",
                "Lock": false
              },
              {
                "FieldName": "Message",
                "Lock": false
              }
            ],
            "ToRoleId": "",
            "ToRoleName": "",
            "CCRoleId": "",
            "CCRoleName": "",
            "Subject": this.selectedEmailData.EmailSubject,
            "Message": ""
          };

        }
        break;
      case 'EMAILSUBJECT':
        this.selectedEmailActionData = selitem;
        this.viewEmail = true;
        this.viewEmailTemp = false;
        break;
      case 'CREATEDDATE':
        this.selectedEmailActionData = selitem;
        this.viewEmail = true;
        this.viewEmailTemp = false;
        break;
      case 'TOEMAIL':
        this.selectedEmailActionData = selitem;
        this.viewEmail = true;
        this.viewEmailTemp = false;
        break;
      case 'MESSAGE':

        this.viewEmail = true;
        this.viewEmailTemp = true;
        break;
      case 'TOROLEID':

        this.viewEmail = true;
        this.viewEmailTemp = true;
        break;
      case 'CCROLEID':

        this.viewEmail = true;
        this.viewEmailTemp = true;
        break;


    }

  }
  onChange(event: any): void {
    console.log(event)
    let selitem: any = event.item;
    this.selectedInfoData = JSON.parse(JSON.stringify(selitem));
  }

  btnClick(butt: any): void {
    switch (butt.Name.toUpperCase()) {
      case 'SEND':
        this.sendMailData();
        break;
      case 'CANCEL':
        if (this.selectedTab != 'CreateEmail') {
          this.selectedInfoData = {};
          this.viewEmailTemp = false;
          this.viewEmail = false;
        }
        else {
          this.selectedInfoData = {};
        }
        break;
      case 'ATTACHMENT':
        this.DisplayAttachment = true;
        break;
      case 'CLEAR':
        this.DisplayAttachment = false;
        this.fileData = [];
        break;

    }
  }

  sendMailData() {
    var odata: any = {};
    var transactionId: any = '';
    this.fileRefId = [];
    this.fileNames = [];
    this.fileData.forEach((x: any) => this.fileRefId.push(x.TransactionId));
    this.fileData.forEach((x: any) => this.fileNames.push(x.FileName));
    transactionId = this.fileRefId.toString();
    console.log(this.selectedEmailData)
    this.IsLoading = true;
    this.successFlag = false;
    if (this.selectedTab == 'CreateEmail') {
      odata = {
        "ToEmailRole": this.selectedInfoData.ToRoleId,
        "CCEMailRole": this.selectedInfoData.CCRoleId,
        "EmailMessage": this.selectedInfoData.Message,
        "EmailSubject": this.selectedInfoData.Subject,
        "RequestId": this.RequestId,
        "EmailType": this.selectedTab,
        "MailTransactionId": 0,
        "Action": this.ACTION,
        "FileReferenceId": transactionId

      };
    }
    else {
      odata = {
        "ToEmailRole": this.selectedInfoData.ToRoleId,
        "CCEMailRole": this.selectedInfoData.CCRoleId,
        "EmailMessage": this.selectedInfoData.Message,
        "EmailSubject": this.selectedInfoData.Subject,
        "RequestId": this.RequestId,
        "EmailType": this.selectedTab,
        "MailTransactionId": this.selectedEmailActionData.MailTransactionId,
        "Action": this.ACTION,
        "FileReferenceId": ''

      };
    }
    if (this.validateMandatoryFields(this.selectedInfoData, this.ACTION)) {
      let subscribe$: Subscription = this._requestService.SendMailTransaction(odata).subscribe(result => {
        console.log(result);
        if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
          this.showNotificationMessage('Sent successfully.', 1, '', null, '', '');
          this.successFlag = true;
          // this.notify = {
          //   info: {
          //     header: 'Message Sent',
          //     message: 'Sent successfully',
          //     yes: '',
          //     no: '',

          //     IsHeaderHidden: true,
          //     HeaderIcon: 'fa fa-check-circle text-success',
          //     IsCrossEnable: true,
          //     SizeClass: 'modal-sm'
          //   },

          //   action: '',
          //   item: null
          // };

          this.displayNotifybox = true;



          this.fileData = [];
        }

        // this.IsLoading = false;

      },
        err => {
          this.fileData = [];
          this.successFlag = false;
          console.log('Error');
          this.IsLoading = false;
        });
      this.subscriptionList$.push(subscribe$);
    }


  }


  setNotifyRes(event: any): void {
    console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;
    // switch (event.action.toUpperCase()) {
    //   case 'DELETEUSERROLE':
    //     if (event.result.toUpperCase() === 'YES') {
    //       this.DeleteUserRole(event.item);
    //     }
    //     break;
    //     case 'CANCELUSERROLEEDIT':
    //     if (event.result.toUpperCase() === 'YES') {
    //       this.ReloadUserRoleData();
    //     }
    //     break;
    // }
    if (canClose) {
      this.closeNotifybox();
    }
  }

  closeNotifybox(): void {
    this.displayNotifybox = false;

    if (this.successFlag == true) {
      this.viewEmailTemp = false;
      this.viewEmail = false;
      this.selectedInfoData = {};
    }
    //     if (this.selectedTab == 'CreateEmail') {
    //       //   this.setSelectedTab( {
    //       //     "selectedTab": "CreateEmail"
    //       // });
    //       this.selectedData = this.selectedInfoData;
    //       console.log(this.selectedData)
    //       this.selectedInfoData = {};
    //       this.ACTION = '';
    //       this.viewEmailTemp = false;
    //       this.viewEmail = false;
    //       this.selectedTab = 'EmailQueue';
    //   //    this.setDefaultPageFocus();
    //     console.log(this.RequestProperty.ViewEmail);
    // //   if (!isNullOrUndefined(this.tabList) && this.tabList.length > 0) {
    // //     let selTab = this.tabList[0];

    // //     let temp = this.tabList.filter((x: any) => x.IsDefault === true);

    // //     if (temp.length > 0) { selTab = temp[0]; }

    // //     // this.selectedTab = selTab.Name

    // //     setTimeout(() => { this.onChange(selTab); }); // , 100);

    // // }
    //       // if (this.selectedTab != 'CreateEmail') {
    //       //   this.getMailerTransaction();
    //       // }


    //     }
    //     else {
    //       this.viewEmailTemp = false;
    //     }
    // }

    // this.selectedInfoData = {};
    // if (this.selectedTab == 'CreateEmail') {
    //   this.setDefaultPageFocus();
    //   this.selectedInfoData = {};
    //   this.ACTION = '';
    //   this.viewEmailTemp = false;
    //   this.viewEmail = false;
    //   this.selectedTab = 'EmailQueue';
    //   //if (this.selectedTab != 'CreateEmail') {
    //   this.getMailerTransaction();
    //   // }


    // }
    // else {
    //   this.viewEmailTemp = false;
    // }
    // if(this.validateMandatoryFields(this.selectedInfoData,this.ACTION)){
    //   this.selectedInfoData = {};
    //   this.displayNotifybox = false;
    // }

  }

  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item
    };

    this.displayNotifybox = true;
  }

  validateMandatoryFields(modifieditems: any, action: any): boolean {
    let valid: boolean = true;
    if (action == this.actionCodes.Forward || action == this.actionCodes.Resend) {
      if (isNullOrUndefined(modifieditems.ToRoleId) || modifieditems.ToRoleId.length === 0) {
        this.showNotificationMessage('To field is required.', 2, '', null, '', '');
        valid = false;
      }
      else if (isNullOrUndefined(modifieditems.Message) || modifieditems.Message.length === 0) {
        this.showNotificationMessage('Message field is required.', 2, '', null, '', '');
        valid = false;
      }
    }
    else {
      if (isNullOrUndefined(modifieditems.ToRoleId) || modifieditems.ToRoleId.length === 0) {
        this.showNotificationMessage('To field is required.', 2, '', null, '', '');
        valid = false;
      }
      else if (isNullOrUndefined(modifieditems.Message) || modifieditems.Message.length === 0) {
        this.showNotificationMessage('Message field is required.', 2, '', null, '', '');
        valid = false;
      }
      else if (isNullOrUndefined(modifieditems.Subject) || modifieditems.Subject.length === 0) {
        this.showNotificationMessage('Subject field is required.', 2, '', null, '', '');
        valid = false;
      }
    }




    // if (!isNullOrUndefined(modifieditems) && modifieditems.length > 0) {
    //   let OfferStatusList = modifieditems.filter(x => ((isNullOrUndefined(x.OfferStatus)) || x.OfferStatus === ''));

    //   if (!isNullOrUndefined(OfferStatusList) && OfferStatusList.length > 0) {
    //     this.showNotificationMessage('Please select offer status status for modified rows.', 2, '', null, '', '');
    //     valid = false;
    //   }

    // }

    return valid;
  }

  closePreviewAttachment(event: any): void {
    this.displayAllAttachmentbox = false;

  }


  showDeleteConfirmation(curitem: any, index: any): void {

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: 'Are you sure you want to delete ?',
        yes: 'Yes',
        no: 'No',

        IsHeaderHidden: true,
        HeaderIcon: 'fa fa-check-circle text-success',
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      }

      // action: 'DELETE',
      // item: curitem
    };
    let files: any = [];
    if (index > -1) {

      this.fileData.splice(index, 1);
      this.ngOnInit();
      // files = this.fileData.forEach((e, i) => e.index = i + 1);
      // 2nd parameter means remove one item only
      //this.fileData = files;
    }

    console.log(this.fileData)
    this.displayNotifybox = true;
  }
  closeAttachment(event: any): void {
    this.DisplayAttachment = false;
    this.fileData = [];
    // this.fileNames=[];

    // event.data.forEach((x: any) => this.fileNames.push(x.FileName));

    //.TransactionId
    if (!isNullOrUndefined(event.data)) {
      event.data.forEach((x: any) => this.fileData.push(x));
      console.log(this.fileData);
      // this.reportData.FilePath = event.data[0].FilePath;
      // this.reportData.TransactionId = event.data[0].TransactionId;
      // this.reportData.FileName = event.data[0].FileName;

      //   this.stopCasesectionData[0].FilePath = event.data[0].FilePath;
      //   this.stopCasesectionData[0].FileReferenceId = event.data[0].TransactionId;
      //   this.stopCasesectionData[0].FileName = event.data[0].FileName;
      // } else {
      //   this.stopCasesectionData[0].IsModified = 0;
      //   this.stopCasesectionData[0].IsRowHighlight = this.stopCasesectionData[0].IsModified;
    }


  }

  GetAttachmentDetails(item: any) {
    console.log(item);
    let subscribe$: Subscription = this._requestService.GetAttachmentDetails(item).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        console.log(result.Data)
        result.Data.forEach((x: any) => this.fileNames.push(x));
      }
      console.log(this.fileNames)
      this.IsLoading = false;

    },
      err => {
        console.log('Error on getting GetAttachmentDetails.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }
  downloadFile(item: any) {

    this.IsLoading = true;

    let odata: object = {
      'Module': 'Mail',
      'Reference1': Number(this.RequestId),
      'TransactionId': item.AttachmentTransactionId,
      // 'FilePath': data.FilePath,
      //'FilePath': '',
      'FileName': item.FileName
    };

    let subscribe$: Subscription = this._requestService.downloadAttachment(odata).subscribe(result => {
      // if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
      //   console.log(result);
      // }
      if (!isNullOrUndefined(result)) {
        let url = window.URL.createObjectURL(result.data);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        // a.download = res.filename;
        a.download = item.FileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
        // this._toastr.success('Resume downloaded successfully', this.CompTitle);

        this.notify = {
          info: {
            header: 'Confirmation Alert',
            message: 'Document downloaded successfully',
            yes: '',
            no: '',

            IsHeaderHidden: true,
            HeaderIcon: 'fa fa-check-circle text-success',
            IsCrossEnable: true,
            SizeClass: 'modal-sm'
          },

          action: '',
          item: null
        };

        this.displayNotifybox = true;
      }
      this.IsLoading = false;

    },
      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);


  }
}