import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionItem } from './actionbar.interface';

@Component({
    selector: 'actionbar',
    // templateUrl: './actionbar.component.html'
    template: `<div *ngIf="(actionList | filterBy: {IsApplicable: true, HasPermission: true}).length > 0">
                    <ul class="actionList">
                        <ng-container *ngFor="let action of actionList | filterBy: {IsApplicable: true}">
                            <li *ngIf="action.HasPermission" [id]="actionBarRandomId+'_'+action.Name" style="cursor:pointer;"
                                (click)="buttonClick(action)" [attr.accesskey]="action.AccessKey">
                                <a [attr.aria-label]="stripTags(action?.DisplayName)" href="javascript:this.blur();"><i [class]="action.Icon" aria-hidden="true"></i>
                                    <span [innerHtml]="(action.DisplayName | translate)"></span>
                                    <span *ngIf="action.ShowArrow" class="ps-3" ><i class="ion ion-md-arrow-forward" aria-hidden="true"></i></span></a>
                            </li>
                        </ng-container>
                    </ul>
                </div>`
})
export class ActionBarComponent implements OnInit {
    @Input() actionList: ActionItem[] = [];
    @Input() noActionClass?: boolean = false;

    @Output() public butClick = new EventEmitter<any>();

    public actionBarRandomId: string = '';
    constructor() {
    this.actionBarRandomId = Math.random().toString(36).substr(2, 5);
     }

    ngOnInit() { }

    buttonClick(action: any): void {
        /// Anomonous data for Click emit event -- Start
        let eventdata = { buttonname: action.Name, actionbut: action };
        this.butClick.emit(eventdata);
        /// Anomonous data for Click emit event -- End
    }

    stripTags(DisplayName: any){
        return DisplayName.replace(/<.*?>/g, '');
    }

}
