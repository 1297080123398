
import { Component, Input, OnInit, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined } from 'src/app/shared/app.sharedservice';
import { NotificationData } from 'src/modules/notification/notification.interface';

@Component({
    selector: 'submitAcknowledgement',
    templateUrl: './submitAcknowledgement.component.html',

})
@AutoUnsubscribe
export class SubmitAcknowledgementComponent implements OnInit {
    @Input() RequestId: number = 0;
    @Input() RequestData: any;
    @Input() RequestProperty: any = [];

    public subscriptionList$: Subscription[] = [];
    public IsLoading: boolean = false;
    public IsReadonly: boolean = false;
    public AcknowledgeSelected: boolean = false;

    public displayNotifybox: boolean = false;
    public notify: NotificationData = new NotificationData();

    constructor(private _appSharedService: AppSharedService) {
        let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
            this.ngOnInit();
        });
        this.subscriptionList$.push(subscribe$);
    }

    ngOnInit(): void {
        if (!isNullOrUndefined(this._appSharedService.currentRoleData)) {
            if (this.RequestData.IsStopCaseSubmitted === true || this.RequestData.IsStopCase === true) {
                this.IsReadonly = true;
            }
        }

    }

    onChange(): void {
    }

    actionButtonClick(data: any) {

        if (!isNullOrUndefined(data.Name) && data.Name !== '') {
            switch (data.Name.toUpperCase()) {
                //Submit Case
                case 'DOCUMENTSUBMIT':
                    this.showNotificationMessage('Are you sure you want to submit the information for background verification?', 1, 'SUBMIT', data.ActionCode, 'Submit', 'Cancel');
                    break;
            }
        }
    }

    showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string) {
        let headerIcon: string = '';

        if (successFlag === 1) {
            headerIcon = 'fa fa-check-circle text-success';
        } else if (successFlag === 2) {
            headerIcon = 'fa fa-exclamation-circle text-warning';
        } else if (successFlag === 3) {
            headerIcon = 'fa fa-exclamation-triangle text-danger';
        } else {
            headerIcon = 'fa fa-info-circle text-info';
        }

        this.notify = {
            info: {
                header: 'Confirmation Alert',
                message: msg,
                yes: yes,
                no: no,

                IsHeaderHidden: true,
                HeaderIcon: headerIcon,
                IsCrossEnable: true,
                SizeClass: 'modal-sm'
            },

            action: action,
            item: item
        };

        this.displayNotifybox = true;
    }

    setNotifyRes(event: any): void {
        console.log('notify res >> ' + JSON.stringify(event));
        let canClose: boolean = true;
        switch (event.action.toUpperCase()) {
            case 'SUBMIT':
                if (event.result.toUpperCase() === 'SUBMIT') {
                    // this.requestStopCase();
                }
                break;
        }
        if (canClose) {
            this.closeNotifybox();
        }
    }

    closeNotifybox(): void {
        this.displayNotifybox = false;
    }

}
