import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { AppSharedService, AutoUnsubscribe, isDate, isEmail, isNullOrUndefined, isNumber, isStringNullOrEmpty } from '../../shared/app.sharedservice';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ComponentCodes, ComponentConfig, ProcessStatus } from '../../app.interface';
import { CommonAPIService } from '../../shared/app.commonservice';
import { ExcelExportComponent } from 'src/modules/excelexport/excelexport.component';
import { asEnumerable } from 'linq-es2015';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { GridComponent } from 'src/modules/grid/grid.component';
import { AdminService } from '../admin.service';
import { ModalComponent } from 'src/modules/modal/modal.component';
import { ModalService } from 'src/modules/modal/modal.service';
import { AccChkDocDetail } from './acc-chk-doc.interface';

@Component({
  selector: 'app-acc-chk-doc-manage',
  templateUrl: './acc-chk-doc-manage.component.html',
  styleUrls: ['./acc-chk-doc-manage.component.css']
})
export class AccChkDocManageComponent implements OnInit {

  @ViewChild('infoform', { static: false }) infoform!: ModalComponent;

  public FilterData: any = {};

  public GlobalConfig: any = {};

  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };

  public FormProp: FormProperty = {
    'ColSize': 2,
    'CaptionBold': false,
    'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };

  public acdListTemplate: FieldTemplate[] = [];
  public acdEditTemplate: FieldTemplate[] = [];
  public acdSubListTemplate: FieldTemplate[] = [];
  public acdListData: any[] = [];
  public acdListDataALL: any[] = [];
  public acdSubListData: any[] = [];
  public acdPermissions: any = [];
  public dataTotalCount: number = 0;
  public dataTotalCountMapping: number = 0;
  public DataInput: object = { PageIndex: 0, PageSize: 0 };
  public selectedInfoData: any;
  public curItemsPerPage: number = 10;
  public editacdData = new AccChkDocDetail();
  public viewName: string = "ListView";
  public selectedTab: string = "";
  public tabName: string = "";
  public DocumentName: string = "";
  public DocumentId: number = 0;

  public Title: string = "";

  public GridProp: any = {
    Editable: true,
    //TableCSS: 'auto',
    PaginationConfig: {
      ItemsPerPage: 10
    },
    ActionBarConfig: {
      ActionBarList: null
    }
  }
  public Config: any = {
    ActionButtons: [],
    ActionButtonsBottom: []
  };

  public IsLoading: boolean = false;
  public subscriptionList$: Subscription[] = [];
  public displayNotifybox: boolean = false;
  public notify: NotificationData = new NotificationData();
  public curFiltCondn: string = '';
  public DispFilter: boolean = false;
  // public DispLoader: boolean = false;

  public ExcelFileName: string = '';
  // public ExcelData: any = [];

  @ViewChild('excelexport', { static: false }) excelexport!: ExcelExportComponent;
  @ViewChild('overallgrid', { static: false }) overallgrid!: GridComponent;

  constructor(private _route: ActivatedRoute, private _router: Router, private _appSharedService: AppSharedService,
    private _commonAPIService: CommonAPIService, private adminService: AdminService, private modalService: ModalService) {

    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);

  }

  ngOnInit() {

    this.selectedTab = this._route.snapshot.data['selectTab'];
    this.tabName = this.selectedTab.charAt(0).toUpperCase() + this.selectedTab.slice(1);

    this.Title = this.tabName + " Details";

    if (!isNullOrUndefined(this._appSharedService.currentRoleData)) {
      this.getACDTemplate();
    }
  }

  getACDTemplate(): void {
    this.IsLoading = true;

    this.acdListTemplate = [];

    let odata: ComponentConfig = {
      Component: 'Admin', ComponentCode: ComponentCodes.AdminComponent
    };

    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {

        let template = res.Data.FieldTemplate!;

        this.acdListTemplate = template.filter(x => x.Group === (this.selectedTab + "List"));
        this.acdEditTemplate = template.filter(x => x.Group === (this.selectedTab + "Edit"));
        this.acdSubListTemplate = template.filter(x => x.Group === (this.selectedTab + "SubList"));

        this.setCompProperties(res.Data.Properties!);

        this.acdPermissions = res.Data.Permission;

        this.ACDGridData();
      }
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getACDTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }

  setCompProperties(Properties: any[]) {
    if (!isNullOrUndefined(Properties) && Properties.length > 0) {
      // 1) ActionButtons field
      this.Config.ActionButtons = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase())[0].JSONData.toString()) : []);

      this.Config.ActionButtonsBottom = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtonsBottom'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtonsBottom'.toLowerCase())[0].JSONData.toString()) : []);

      this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;

       // Other component specific props
       Properties.forEach(prop => {
        // avoiding generic props
        if (!isNullOrUndefined(prop.Name) &&
          prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
          && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
          this.Config[prop.Name] = JSON.parse(prop.JSONData.toString());
        }
      });

    }
  }

  setActionButtonPermissions(): void {
    if (!isNullOrUndefined(this.Config.ActionButtonsBottom) && this.Config.ActionButtonsBottom.length > 0) {

      this.Config.ActionButtonsBottom.forEach((item: any) => {
        if (!isNullOrUndefined(item.Name) && item.Name !== '') {
          item.HasPermission = false;
          //item.IsApplicable = false;

          let peritem = this.acdPermissions.indexOf(item.Name);
          if (peritem >= 0) {
            item.HasPermission = true;
          }
        }
      });
    }
  }

  ACDGridData(): void {

    this.IsLoading = true;

    this.acdListData = [];
    this.acdListDataALL = [];
    this.dataTotalCount = 0;

    let subscribe$: Subscription = this.adminService.getAccountCheckDocumentDetails(this.selectedTab, this.DocumentId).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        if (result.Data != null) {
          this.acdListData = result.Data;
          console.log(this.acdListData);
          this.dataTotalCount = this.acdListData.length;

          this.acdListDataALL = this.acdListData;

          this.acdListData.forEach((item) => {
            item.Action = item.RowStatus == "I" ? '2' : '0,1,3';

            item.IsEditable = 1;
          });
        }
        this.setActionButtonPermissions();

        setTimeout(() => {
          if (!isNullOrUndefined(this.overallgrid)) {
            this.overallgrid.Pagination.CurrentPage = 1;
          }
        }, 50);

      }
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
        //this._toastr.warning(result.Message, this.CompTitle);
      }

      this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);
  }

  DeleteACDData(deleteGridData: any): void {

    this.IsLoading = true;

    let subscribe$: Subscription = this.adminService.deleteAccountCheckDocumentData(this.selectedTab, deleteGridData).subscribe(result => {
      if (!isNullOrUndefined(result)) {

        this.showNotificationMessage
          (
            result.Message,
            result.Status === ProcessStatus.Success ? 1
              : result.Status === ProcessStatus.NoContent ? 2 : 3,
            '', null, '', ''
          );
      }
      this.IsLoading = false;
      if (result.Status === ProcessStatus.Success) {
        this.getACDTemplate();
      }
    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);

  }


  GetMappingDetailsByAccountId(accountId: number): void {

    this.IsLoading = true;

    let subscribe$: Subscription = this.adminService.GetMappingDetailsByAccountId(this.selectedTab, accountId).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        this.acdSubListData = result.Data;

        this.acdSubListData.forEach((item) => {
          item.PhaseId = item.Phase === 1 ? 'P001' : item.Phase === 2 ? 'P002' : null;
          item.PhaseIdDesc = item.Phase === 1 ? 'Phase 1' : item.Phase === 2 ? 'Phase 2' : null;
          item.IsEditable = 1;
        });

        this.dataTotalCountMapping = this.acdSubListData.length;

        //this.GridProp.ActionBarConfig.ActionBarList = null;

        console.log(this.acdSubListData);
      }

      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
        //this._toastr.warning(result.Message, this.CompTitle);
      }

      this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);
  }

  getACDDetailsById(acdTranId: number): void {

    this.IsLoading = true;

    this.editacdData = new AccChkDocDetail();

    let subscribe$: Subscription = this.adminService.getAccountCheckDocumentDetailsById(this.selectedTab, acdTranId).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        this.editacdData = result.Data;

        this.editacdData.IsEditable = 1;

        console.log(this.editacdData);
      }

      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
        //this._toastr.warning(result.Message, this.CompTitle);
      }

      this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);
  }

  SaveACDEdit(): void {

    if (this.validateUserACDSave()) {

      this.IsLoading = true;

      if (this.selectedTab == "subDoc") {
        this.editacdData.DocumentTypeId = this.DocumentId;
      }

      let subscribe$: Subscription = this.adminService.updateAccountCheckDocumentDetails(this.selectedTab, this.editacdData).subscribe(result => {
        if (!isNullOrUndefined(result)) {

          this.showNotificationMessage
            (
              result.Message,
              result.Status === ProcessStatus.Success && result.Message.match("exists") ? 2
                : result.Status === ProcessStatus.Success ? 1 : 3,
              '', null, '', ''
            );

          if (ProcessStatus.Success && !result.Message.match("exists")) {

            if (this.selectedTab == "account" || this.selectedTab == "check") {

              if (this.selectedTab == "account") {
                this.editacdData.AccountId = result.Data;
              }
              if (this.selectedTab == "check") {
                this.editacdData.CheckId = result.Data;
              }


              this.SaveSubListData();
            } else {
              setTimeout(() => {
                this.ReloadACDGridData();
                this.IsLoading = false;
              }, 2000);

            }
          } else {
            this.IsLoading = false;
          }
        }
      },

        err => {
          this.IsLoading = false;
        });

      this.subscriptionList$.push(subscribe$);
      console.log(this.subscriptionList$);

    }
  }

  SaveSubListData(): void {

    let subTab = this.selectedTab == "account" ? "account & Check Mapping" :
      this.selectedTab == "check" ? "check & Document Type Mapping" : "";

    this.acdSubListData.filter(f => f.IsSelected == true).forEach((item) => {
      item.AccountId = this.selectedTab == "check" ? item.AccountId : this.editacdData.AccountId;
      item.CheckId = this.selectedTab == "account" ? item.CheckId : this.editacdData.CheckId;
      item.Phase = item.PhaseId === 'P001' ? 1 : item.PhaseId === 'P002' ? 2 : 1;
    });
    

    let subscribe$: Subscription = this.adminService.updateAccountCheckDocumentDetails(subTab, this.acdSubListData).subscribe(result => {
      if (!isNullOrUndefined(result)) {

        if (ProcessStatus.Success) {
          setTimeout(() => {
            this.ReloadACDGridData();
            this.IsLoading = false;
          }, 2000);
        } else {
          this.IsLoading = false;
        }
      }
    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);

  }

  validateUserACDSave(): boolean {

    if (this.selectedTab == "account") {
      if (isStringNullOrEmpty(this.editacdData.AccountName)) {
        this.showNotificationMessage('Please provide Account Name', 2, '', null, '', '');
        return false;
      }

      if (parseInt(this.editacdData.AccountName)) {
        this.showNotificationMessage('Account Name should not be number', 2, '', null, '', '');
        return false;
      }

      if (isStringNullOrEmpty(this.editacdData.SPOCEmailID) || !isEmail(this.editacdData.SPOCEmailID)) {
        this.showNotificationMessage('Please provide valid SPOC Email', 2, '', null, '', '');
        return false;
      }




      
      // if (isStringNullOrEmpty(this.editacdData.DLEmailID) || !isEmail(this.editacdData.DLEmailID)) {
      //   this.showNotificationMessage('Please provide valid DL Email', 2, '', null, '', '');
      //   return false;
      // }
    }
    else if (this.selectedTab == "check") {

      if (isStringNullOrEmpty(this.editacdData.CheckName)) {
        this.showNotificationMessage('Please provide Check Name', 2, '', null, '', '');
        return false;
      }

      if (parseInt(this.editacdData.CheckName)) {
        this.showNotificationMessage('Check Name should not be number', 2, '', null, '', '');
        return false;
      }

      if (isNullOrUndefined(this.editacdData.ExpectedTurnAroundTime) || !isNumber(this.editacdData.ExpectedTurnAroundTime)) {
        this.showNotificationMessage('Please provide Expected Turn Around Time', 2, '', null, '', '');
        return false;
      }

    }
    else if (this.selectedTab == "document") {

      if (isStringNullOrEmpty(this.editacdData.DocumentTypeName)) {
        this.showNotificationMessage('Please provide Document Type Name', 2, '', null, '', '');
        return false;
      }

      if (parseInt(this.editacdData.DocumentTypeName)) {
        this.showNotificationMessage('Document Type Name should not be number', 2, '', null, '', '');
        return false;
      }

    }
    else if (this.selectedTab == "subDoc") {

      if (isStringNullOrEmpty(this.editacdData.SubDocumentTypeName)) {
        this.showNotificationMessage('Please provide Sub Document Type Name', 2, '', null, '', '');
        return false;
      }

      if (parseInt(this.editacdData.SubDocumentTypeName)) {
        this.showNotificationMessage('Sub Document Type Name should not be number', 2, '', null, '', '');
        return false;
      }

    }

    else if (this.selectedTab == "account & Check Mapping") {

      if (isNullOrUndefined(this.editacdData.AccountId) || this.editacdData.AccountId == 0) {
        this.showNotificationMessage('Please select account', 2, '', null, '', '');
        return false;
      }

      if (isNullOrUndefined(this.editacdData.CheckId) || this.editacdData.CheckId == 0) {
        this.showNotificationMessage('Please select check', 2, '', null, '', '');
        return false;
      }

      // if (isNullOrUndefined(this.editacdData.ValidFromDate) || !isDate(this.editacdData.ValidFromDate)) {
      //   this.showNotificationMessage('Please provide Valid From Date', 2, '', null, '', '');
      //   return false;
      // }

      // if (isNullOrUndefined(this.editacdData.ValidTillDate) || !isDate(this.editacdData.ValidTillDate)) {
      //   this.showNotificationMessage('Please provide Valid Till Date', 2, '', null, '', '');
      //   return false;
      // }
    }

    else if (this.selectedTab == "check & Document Type Mapping") {

      if (isNullOrUndefined(this.editacdData.CheckId) || this.editacdData.CheckId == 0) {
        this.showNotificationMessage('Please select check', 2, '', null, '', '');
        return false;
      }

      if (isNullOrUndefined(this.editacdData.DocumentTypeId) || this.editacdData.DocumentTypeId == 0) {
        this.showNotificationMessage('Please select document', 2, '', null, '', '');
        return false;
      }

      // if (isNullOrUndefined(this.editacdData.ValidFromDate) || !isDate(this.editacdData.ValidFromDate)) {
      //   this.showNotificationMessage('Please provide Valid From Date', 2, '', null, '', '');
      //   return false;
      // }

      // if (isNullOrUndefined(this.editacdData.ValidTillDate) || !isDate(this.editacdData.ValidTillDate)) {
      //   this.showNotificationMessage('Please provide Valid Till Date', 2, '', null, '', '');
      //   return false;
      // }
    }

    return true;
  }

  ReloadACDGridData(): void {
    this.Title = this.selectedTab == "subDoc" ? this.DocumentName : this.tabName + " Details";
    this.viewName = "ListView";
    this.ACDGridData();
  }

  pageItemsChange() {

    if (this.curItemsPerPage > 0) {
      this.GridProp.PaginationConfig.ItemsPerPage = this.curItemsPerPage;
    }
    else {
      this.GridProp.PaginationConfig.ItemsPerPage = 100;
    }
    // reload grid data
    this.ACDGridData();
  }

  actionButtonClick(data: any) {
    let valid: boolean = true;

    if (!isNullOrUndefined(data.Name) && data.Name !== '') {
      switch (data.Name.split('_')[0].toUpperCase()) {
        case 'ADD':
          this.editacdData = new AccChkDocDetail();
          this.Title = "Add " + this.tabName;
          this.viewName = "EditView";

          if (this.selectedTab == "account" || this.selectedTab == "check") {
            this.GetMappingDetailsByAccountId(0)
          }
          break;
        case 'CANCEL':
          if (this.editacdData.IsModified == 1) {
            this.showNotificationMessage('Are you sure you want to cancel un-saved data?',
              2, 'CancelEdit', null, 'Yes', 'No');
          }
          else {
            this.ReloadACDGridData();
          }
          break;
        case 'SAVE':
          this.SaveACDEdit();
          break;
        case 'BACK':
          this.selectedTab = "document";
          this.tabName = this.selectedTab.charAt(0).toUpperCase() + this.selectedTab.slice(1);
          this.Title = this.tabName + " Details";
          this.getACDTemplate();
          break;
      }
    }
  }

  onClick(event: any): void {
    let selitem = event.item;

    switch (event.fieldname.toUpperCase()) {
      case 'ACTION':
        switch (event.icon.Value) {
          case '0':
            // View 
            this.Title = this.selectedTab == "subDoc" ? this.DocumentName : this.tabName + " Edit";
            this.getACDDetailsById
              (
                this.selectedTab == "account" ? selitem.AccountId :
                  this.selectedTab == "check" ? selitem.CheckId :
                    this.selectedTab == "document" ? selitem.DocumentTypeId :
                      this.selectedTab == "subDoc" ? selitem.SubDocumentTypeId :
                        this.selectedTab == "account & Check Mapping" ? selitem.TransactionId :
                          this.selectedTab == "check & Document Type Mapping" ? selitem.TransactionId : 0
              );
            this.viewName = "EditView";

            if (this.selectedTab == "account" || this.selectedTab == "check") {
              this.GetMappingDetailsByAccountId(
                this.selectedTab == "account" ? selitem.AccountId :
                  this.selectedTab == "check" ? selitem.CheckId : 0
              )
            }

            break;
          case '1':
            // this.showNotificationMessage('Are you sure you want to disable ' +
            //   (this.selectedTab == 'subDoc' ? 'Sub Document' :
            //     (this.selectedTab == 'account & Check Mapping' || this.selectedTab == 'check & Document Type Mapping') ? 'this mapping'
            //       : this.selectedTab) + '?',
            this.showNotificationMessage('Are you sure you want to disable?',
              2, 'DeleteACDData', selitem, 'Yes', 'No');
            break;
          case '2':
            this.showNotificationMessage('Are you sure you want to enable?',
              2, 'EnableACDData', selitem, 'Yes', 'No');
            break;
          case '3':
            this.Title = selitem.DocumentTypeName;
            this.selectedTab = "subDoc";
            this.viewName = "ListView";
            this.DocumentId = selitem.DocumentTypeId;
            this.DocumentName = selitem.DocumentTypeName;
            this.getACDTemplate();
            break;
        }
        break;
    }
  }

  onChange(event: any): void {
    let selitem: any = event.item;
    event.item.IsModified = 1;
  }

  actButtonClick(event: any) {
    switch (event.buttonname.toUpperCase()) {
      case 'EXPORTTOEXCEL':
        this.exportToExcel();
        break;
      case 'FILTER':
        this.showFilter();
        break;

    }
  }

  exportToExcel() {

    if (this.acdListData.length === 0) {
      // this.toastr.warning("Report Data is empty");
    }
    else {
      this.IsLoading = true;

      let month = new Date().getMonth() + 1;
      this.ExcelFileName = 'BGV-' + (this.selectedTab == "subDoc" ? "Sub Document" : this.tabName) + '-Details' + new Date().getDate() + '-' + month + '-' + new Date().getFullYear() + ' ' + new Date().toLocaleTimeString()

      let tempdata = [];
      // tempdata.push({ Name: 'Master Data', Data: this.acdListData });
      tempdata.push({
        Name: (this.selectedTab == "subDoc" ? "Sub Document" : this.tabName) + ' Data', Data: this.acdListData
        , ColumnList: !isNullOrUndefined(this.Config.DownloadColumns) && this.Config.DownloadColumns.length > 0 ? this.Config.DownloadColumns.map((x: any) => x.FieldName) : null
        , FieldTemplate: this.acdListTemplate
      });

      this.excelexport.generateExcelTable_fromJSON(tempdata, this.ExcelFileName);

      this.IsLoading = false;
    }
  }

  showFilter(event?: any): void {
    this.DispFilter = !this.DispFilter;

    if (!isNullOrUndefined(event)) {
      this.curFiltCondn = event.curFiltCondn;
    }
  }

  setFilter(event: any) {
    if (event.sCondn.length > 0) {
      this.acdListData = asEnumerable(this.acdListDataALL)
        .Where($ => (eval(event.sCondn)))
        .ToArray();
      this.dataTotalCount = this.acdListData.length;
    }
    else {
      this.acdListData = this.acdListDataALL;
      this.dataTotalCount = this.acdListData.length;
    }
  }

  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item
    };

    this.displayNotifybox = true;
  }

  setNotifyRes(event: any): void {
    console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;
    switch (event.action.toUpperCase()) {
      case 'DELETEACDDATA':
        if (event.result.toUpperCase() === 'YES') {
          event.item.RowStatus = "I"
          this.DeleteACDData(event.item);
        }
        break;
      case 'ENABLEACDDATA':
        if (event.result.toUpperCase() === 'YES') {
          event.item.RowStatus = "A"
          this.DeleteACDData(event.item);
        }
        break;
      case 'CANCELEDIT':
        if (event.result.toUpperCase() === 'YES') {
          this.ReloadACDGridData();
        }
        break;
    }
    if (canClose) {
      this.closeNotifybox();
    }
  }

  closeNotifybox(): void {
    this.displayNotifybox = false;
  }
}
