import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingModule } from 'src/modules/loading/loading.module';
import { ExcelExportComponent } from '../excelexport/excelexport.component';


@NgModule({
  declarations: [
    ExcelExportComponent  
  ],
  imports: [
    BrowserModule,
    FormsModule,
    LoadingModule,
    TranslateModule.forRoot(),
  ],
  bootstrap: [ExcelExportComponent],
  exports: [ExcelExportComponent]
})
  
export class ExcelExportModule { }

