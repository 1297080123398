
<div class="h-100">
    <div class="p-2 contentScroll simpleCard">
        <!--Non Green Parent Page Start From Here -->
        <ng-container *ngIf="!openBGVHeadSection">
            <div class="card h-100 m-0">
                <div class="card-header">
                    <h3>
                        <span>{{SelectedTabName | translate}}</span>
                    </h3>
                </div>
                 <!-- New changes start here -->
                <div class="card-body overflow-auto">
                    <ng-container *ngIf="BGVHeadTemplate!=null && BGVHeadTemplate!=undefined && BGVHeadTemplate.length > 0">
                           <div class="row small-gutter">
                                  <ng-container>
   
                                           <customform #ngcustomform
                                           [FormTemplate]="BGVHeadTemplate | filterBy: {Group: 'BGVHeadScreen'}"
                                           [FormData]="BGVHeadFormData"
                                           [FormProp]="FormProp"
                                           (FieldClick)="onClick($event)"
                                           (FieldChange)="onChange($event)">
   
                                           </customform>
                                       </ng-container>
                             </div>
                           <br>
                           <br>
                          
                           <ng-container *ngFor="let data of SubmittedDetailsList">
                               <ng-container
                                   *ngIf="(SubmitDetailsTemplate!=null && SubmitDetailsTemplate!=undefined && SubmitDetailsTemplate.length > 0)">
                                   <div class="d-flex justify-content-between">
                                       <p class="d-flex text-bold">{{data.VerifiedComponentName |
                                           translate}}</p>
                                   </div>
                                   <br>
                                   
                                       <customform  [FormTemplate]="SubmitDetailsTemplate"
                                       [FormData]="data" 
                                       [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                                       (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                   </customform>
                               
                               </ng-container>
                           </ng-container>
                        </ng-container>
                        <br><br>
                           <ng-container  *ngIf="CommentTemplate!=null && CommentTemplate!=undefined && CommentTemplate.length > 0">
                            <div class="row small-gutter">
                                
                                <customform #SEC_gapsectionData [FormTemplate]="CommentTemplate"
                                [FormData]="BGVHeadFormData" [FormProp]="CommentFormProp"
                                [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                                (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                            </customform>
                            </div>
                           </ng-container>  
                                                       
                       
                  
                
                    </div>
                
            </div>
        </ng-container>
        <!--Non Green Parent Page All End Here -->
    </div>

    <div class="filters buttonHolder">
        <div class="d-flex justify-content-between ">
            <div></div>
            <div>
                <ng-container
                    *ngIf="BGVHeadProperties.ActionButtons != null && BGVHeadProperties.ActionButtons != undefined && !IsReadonly && !RequestData.IsClosed">

                    <!--Non Green Parent Page buttons-->
                    <!-- <ng-container *ngIf="!openBGVHeadSection"> -->
                        <ng-container *ngFor="let actionBar of (BGVHeadProperties.ActionButtons | filterBy: {IsApplicable: true,HasPermission :true, Group :'BGVHead'}
                            | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">

                            <button type="button" [ngClass]="actionBar.CustomClass"
                                (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName |
                                translate
                                }}
                                <span *ngIf="actionBar.ShowArrow" class="ps-3"><i class="ion ion-md-arrow-forward" aria-hidden="true"></i></span>
                            </button>
                        </ng-container>
                    <!-- </ng-container> -->
                </ng-container>
            </div>
        </div>
    </div>

</div>

<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
        (selected)="setNotifyRes($event) " (close)="closeNotifybox() "></notification>
</div>

<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>


<!--Attachment section-->
<ng-container *ngIf="DisplayAttachment==true">
    <file-upload [Module]="'SubCheck'" [RefId1]="RequestId" [IsMultiple]="false" (close)="closeAttachment($event)"
        [Permissions]="AttachPerm" [CandidateId] = "RequestData.CandidateId">
    </file-upload>
</ng-container>