import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { CustomPipeModule } from '../custompipe/custompipe.module';
import { FieldControlModule } from '../fieldcontrol/fieldcontrol.module';
import { FilterModule } from '../filter/filter.module';
import { GridModule } from '../grid/grid.module';
import { LoadingModule } from '../loading/loading.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchByComponent } from './searchby.component';
import { SearchByService } from './searchby.service';
import { FormModule } from '../form/form.module';


@NgModule({
  imports: [CommonModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    FieldControlModule,
    FilterPipeModule,
    TooltipModule.forRoot(),
    TranslateModule.forRoot(),
    PaginationModule.forRoot(),
    FilterModule,
    AlertModule.forRoot(),
    CustomPipeModule, GridModule,LoadingModule,NgbModule,FormModule
  ],
  declarations: [SearchByComponent],
  providers: [SearchByService],
  exports: [SearchByComponent]
})
export class SearchByModule { }

