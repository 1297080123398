
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { CustomPipeModule } from '../custompipe/custompipe.module';
import { NotificationModule } from '../notification/notification.module';
import { ModalComponent } from './modal.component';
import { environment } from '../../environments/environment';
import { AngularDraggableModule } from 'angular2-draggable';
//import { ModalInterface } from './modal.interface';

@NgModule({
  declarations: [
    ModalComponent
  ],
  imports: [
    BrowserModule, FormsModule, TooltipModule, AngularDraggableModule,
    TranslateModule.forRoot(),
    CustomPipeModule, FilterPipeModule
  ],
 // providers: [ModalInterface],
  bootstrap: [ModalComponent],
  exports: [ModalComponent]
})

export class ModalModule { }
