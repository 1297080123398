import { Injectable } from '@angular/core';
import { FieldTemplate } from '../_classes/utility.interface';


@Injectable()
export class UtilityService {
  constructor() { }

  public ParsingURL(url: string, key: string[], keyData: any, type: string = ''): string {
    for (let i = 0; i < key.length; i++) {
      url = url.replace('{' + key[i] + '}', keyData[key[i]]);
    }
    return url;
  }

  // public PreProcessTemplate(Template: Array<FieldTemplate>, Property: Array<string>,
  //           Keys: Array<string>, GlobalConfig: any) {

  //   // Replacing the Global variables with the appropriate

  //   Template.forEach(item => {
  //     // loop for each different Property in a Template item
  //     for (let i = 0; i < Property.length; i++) {
  //       //let propname = (Property[i].indexOf('.') >= 0 ? Property[i].split('.') :  Property[i]);
  //       let propname = Property[i];

  //       // loop for each Key to be replaced
  //       for (let j = 0; j < Keys.length; j++) {
  //         //if (propname.length > 1) {
  //         //    let temp = item[propname[0]][propname[1]];
  //         //    item[propname[0]][propname[1]] = temp.replace('$Global.' + Keys[j], GlobalConfig[Keys[j]])

  //         //}
  //         //else {
  //         //    item[propname] = item[propname].replace('$Global.' + Keys[j], GlobalConfig[Keys[j]])
  //         //}

  //         //let value = s.split('.').reduce((a, b) => a[b], r);
  //         let value = propname.split('.').reduce((a, b) => {
  //           a[b] = a[b].replace('$Global.' + Keys[j], GlobalConfig[Keys[j]]);
  //           return a[b];
  //         }, item);

  //         value = value.replace('$Global.' + Keys[j], GlobalConfig[Keys[j]]);
  //       }
  //     }
  //   });
  // }

  public PreProcessTemplate(Template: Array<FieldTemplate>, Keys: Array<string>, GlobalConfig: any) {

    // Replacing the Global variables with the appropriate values
    Template.forEach(item => {

      // loop for each Key to be replaced
      for (let j = 0; j < Keys.length; j++) {

        // Url in Control.Source
        if (item?.Control?.Source?.Url !== undefined && item.Control.Source.Url !== null) {
          item.Control.Source.Url = item.Control.Source.Url.replace('$Global.' + Keys[j], GlobalConfig[Keys[j]]);
        }

        // Min and Max in EditValidation
        if (item.EditValidate.Min !== undefined && item.EditValidate.Min !== null) {
          item.EditValidate.Min = item.EditValidate.Min.toString().replace('$Global.' + Keys[j], GlobalConfig[Keys[j]]);
        }

        if (item.EditValidate.Max !== undefined && item.EditValidate.Max !== null) {
          item.EditValidate.Max = item.EditValidate.Max.toString().replace('$Global.' + Keys[j], GlobalConfig[Keys[j]]);
        }

        if (!isNullOrUndefined(item.EditValidate.DisableDates)) {
          item.EditValidate.DisableDates?.forEach((x: any) => {
            if (!isNullOrUndefined(x.StartDt)) {
              x.StartDt = x.StartDt.toString().replace('$Global.' + Keys[j], GlobalConfig[Keys[j]]);
            }

            if (!isNullOrUndefined(x.EndDt)) {
              x.EndDt = x.EndDt.toString().replace('$Global.' + Keys[j], GlobalConfig[Keys[j]]);
            }
          });
        }

        // Any other field to be added here

      }
    });
  }
}

// export function isNullOrUndefined<T>(object: T | undefined | null): object is T {  
//   return <T>object !== undefined && <T>object !== null;  
// }  
export function isNullOrUndefined<T>(obj?: T | null): boolean {
  // null == undefined so this is true if obj = null or obj = undefined
  return obj == null;
}

export function isDate(obj:any): boolean {
	return obj instanceof Date && !isNaN(obj.getTime()) ? true : false;
};

export function isNumber(obj:any): boolean {
	return typeof obj === 'number';
};

export function getProperty<Type, Key extends keyof Type>(obj: Type, key: Key) {
  return obj[key];
}
