import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { TranslateModule } from '@ngx-translate/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FilterPipeModule } from 'ngx-filter-pipe';

import { CustomPipeModule } from '../custompipe/custompipe.module';
import { FilterModule } from '../filter/filter.module';
import { GridModule } from '../grid/grid.module';
import { LoadingModule } from '../loading/loading.module';

import { TileComponent } from './tile.component';
import { TileService } from './tile.service';
import { GridDisplayControlModule } from '../grid-dispctrl/grid-dispctrl.module';


@NgModule({
  imports: [CommonModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    FilterPipeModule,
    TooltipModule.forRoot(),
    TranslateModule.forRoot(),
    PaginationModule.forRoot(),
    FilterModule,
    AlertModule.forRoot(),
    CustomPipeModule, GridDisplayControlModule, LoadingModule
  ],
  declarations: [TileComponent],
  providers: [TileService],
  exports: [TileComponent]
})
export class TileModule { }

