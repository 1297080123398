import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html'
})
export class AdminComponent implements OnInit {

  public subscriptionList$: Subscription[] = [];

  
  constructor(private _route: ActivatedRoute, private eleRef: ElementRef, private _router: Router) {

    let subscribe$: Subscription = this._router.events.pipe(
      // filter(event => event instanceof NavigationStart)
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // change in routing
      console.log('change in routing >> ');
      console.log(event.url);
    });

    this.subscriptionList$.push(subscribe$);

  }

  ngOnInit(): void {
  }

}
