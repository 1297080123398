<div class="h-100">
    <div class="p-2 contentScroll simpleCard">
        <!--Non Green Parent Page Start From Here -->
        <!-- <ng-container *ngIf="!openBUHRSection"> -->
        <div class="card h-100 m-0">
            <div class="card-header">
                <h3>
                    <span>{{SelectedTabName | translate}}</span>
                </h3>
            </div>
            <!-- <div class="card-body overflow-auto">
                <ng-container
                    *ngIf="BUHRProperties.BUHRSection!=null && BUHRProperties.BUHRSection!=undefined && BUHRProperties.BUHRSection.length > 0">
                    <ng-container *ngFor="let buHRSection of (BUHRProperties.BUHRSection | filterBy: {CanShow: true})">
                        <p class="text-bold mb-1">
                            <span>{{buHRSection.DisplayName |
                                translate}}</span>
                        </p>

                        <div class="gridAction">
                            <div class="d-flex justify-content-between">

                            </div>
                        </div>

                        
                        <div class="table-responsive">
                            <ng-container
                                *ngIf="BUHRTemplate!=null && BUHRTemplate!=undefined && BUHRTemplate.length > 0">
                                <grid-dispctrl #updateStatusgrid
                                    [FieldTemplate]="BUHRTemplate | filterBy: {Group: buHRSection.Name}"
                                    [GridProp]="GridProp" [GridData]="BUHRFilterData"
                                    [GridDataTotalCount]="BUHRFilterData.length" (FieldClick)="onClick($event)"
                                    (FieldChange)="onChange($event)">
                                </grid-dispctrl>
                            </ng-container>
                        </div>
                      
                        <br />
                        <br />
                    </ng-container>
                </ng-container>
                <br>
                <ng-container
                    *ngIf="BUHRProperties.BUHRCheckSection!=null && BUHRProperties.BUHRCheckSection!=undefined && BUHRProperties.BUHRCheckSection.length > 0">
                    <ng-container
                        *ngFor="let BUHRChkSec of (BUHRProperties.BUHRCheckSection | filterBy: {CanShow: true})">
                        <p class="text-bold mb-1">
                            <span>{{BUHRChkSec.DisplayName |
                                translate}}</span>
                        </p>

                        <div class="gridAction">
                            <div class="d-flex justify-content-between">

                            </div>
                        </div>

                       
                        <div class="table-responsive">
                            <ng-container
                                *ngIf="BUHRTemplate!=null && BUHRTemplate!=undefined && BUHRTemplate.length > 0">
                                <grid-dispctrl #checkStatusgrid
                                    [FieldTemplate]="BUHRTemplate | filterBy: {Group: BUHRChkSec.Name}"
                                    [GridProp]="GridProp" [GridData]="BUHRData"
                                    [GridDataTotalCount]="BUHRData.length" (FieldClick)="onClick($event)"
                                    (FieldChange)="onChange($event)">
                                </grid-dispctrl>
                            </ng-container>
                        </div>
                       
                        <br />
                        <br />
                    </ng-container>
                </ng-container>
                <br>
                <ng-container
                    *ngIf="BUHRProperties.AdditionalSection!=null && BUHRProperties.AdditionalSection!=undefined && BUHRProperties.AdditionalSection.length > 0">
                    <ng-container
                        *ngFor="let additionalSection of (BUHRProperties.AdditionalSection | filterBy: {CanShow: true})">
                        <p class="text-bold mb-1">
                            <span>{{additionalSection.DisplayName |
                                translate}}</span>
                        </p>
                        
                        <div class="table-responsive">
                            <ng-container
                                *ngIf="BUHRTemplate!=null && BUHRTemplate!=undefined && BUHRTemplate.length > 0">
                                <grid-dispctrl #updateStatusgrid
                                    [FieldTemplate]="BUHRTemplate | filterBy: {Group: additionalSection.Name}"
                                    [GridProp]="GridProp" [GridData]="BUHRAdditionalSectionData"
                                    [GridDataTotalCount]="BUHRAdditionalSectionData.length"
                                    (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                </grid-dispctrl>
                            </ng-container>
                        </div>
                        
                        <br />
                        <br />
                    </ng-container>
                </ng-container>
            </div> -->
       
        <!-- </ng-container> -->
        <!--Non Green Parent Page All End Here -->

            <div class="card-body overflow-auto">
                <ng-container *ngIf="BUHRProperties.BUHRSection!=null && BUHRProperties.BUHRSection!=undefined && BUHRProperties.BUHRSection.length > 0">
                    <div class="table-responsive">
                        <ng-container>
                            <div class="row small-gutter">
                                
                                <ng-container
                                    *ngIf="BUHRTemplate!=null && BUHRTemplate!=undefined && BUHRTemplate.length > 0">

                                    <customform #ngcustomform
                                    [FormTemplate]="BUHRTemplate | filterBy: {Group: 'BUHRScreen'}"
                                    [FormData]="BUHRFormData"
                                    [FormProp]="FormProp"
                                    (FieldClick)="onClick($event)"
                                    (FieldChange)="onChange($event)">

                                    </customform>
                                </ng-container>
                            </div>
                        
                            <br>
                            <br>
                            <ng-container *ngFor="let data of SubmittedDetailsList">
                                <ng-container
                                    *ngIf="(SubmitDetailsTemplate!=null && SubmitDetailsTemplate!=undefined && SubmitDetailsTemplate.length > 0)">
                                    <div class="d-flex justify-content-between">
                                        <p class="d-flex text-bold">{{data.VerifiedComponentName |
                                            translate}}</p>
                                    </div>
                                    <br>
                                    
                                        <customform #ngcustomform2 
                                        [FormTemplate]="SubmitDetailsTemplate"
                                        [FormData]="data" 
                                        [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                                        (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                    </customform>
                                
                                </ng-container>
                            </ng-container>
                    </ng-container> 
                    <br>
                    <ng-container
                        *ngIf="BUHRTemplate!=null && BUHRTemplate!=undefined && BUHRTemplate.length > 0">
                        <div class="row small-gutter">
                            <customform #ngcustomform1
                            [FormTemplate]="BUHRTemplate | filterBy: {Group: 'BUHRComment'}"
                            [FormData]="BUHRFormData" 
                            [FormProp]="CommentFormProp">

                            </customform>
                        </div>
                    </ng-container> 
                    <ng-container
                    *ngIf="NonGreenProperties.AdditionalSection!=null && NonGreenProperties.AdditionalSection!=undefined && NonGreenProperties.AdditionalSection.length > 0">
                    <ng-container
                        *ngFor="let additionalSection of (NonGreenProperties.AdditionalSection | filterBy: {CanShow: true})">
                        <p class="text-bold mb-1">
                            <span>{{additionalSection.DisplayName |
                                translate}}</span>
                        </p>
                        <!-- <div class="gridContainer mb-3"> -->
                        <div class="table-responsive">
                            <ng-container
                                *ngIf="NonGreenTemplate!=null && NonGreenTemplate!=undefined && NonGreenTemplate.length > 0">
                                <grid-dispctrl #updateStatusgrid
                                    [FieldTemplate]="NonGreenTemplate | filterBy: {Group: additionalSection.Name}"
                                    [GridProp]="GridProp" [GridData]="NonGreenAdditionalSectionData"
                                    [GridDataTotalCount]="NonGreenAdditionalSectionData.length"
                                    (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                </grid-dispctrl>
                            </ng-container>
                            
                        </div>
                        <!-- </div> -->
                        <br />
                        <br />
                    </ng-container>
                </ng-container>                           
               
                    </div>
                
                </ng-container>
            </div>    
        </div>
    </div>

    <div class="filters buttonHolder">
        <div class="d-flex justify-content-between ">
            <div></div>
            <div>
                <ng-container
                    *ngIf="BUHRProperties.ActionButtons != null && BUHRProperties.ActionButtons != undefined && !IsReadonly && !RequestData.IsClosed">

                    <!--Non Green Parent Page buttons-->
                    <!-- <ng-container *ngIf="!openBUHRSection"> -->
                    <ng-container *ngFor="let actionBar of (BUHRProperties.ActionButtons | filterBy: {IsApplicable: true,HasPermission :true, Group :'BUHR'}
                            | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">

                        <button type="button" [ngClass]="actionBar.CustomClass" (click)="actionButtonClick(actionBar)"
                            [disabled]="!actionBar.IsEnabled"
                            value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName |
                            translate
                            }}
                            <span *ngIf="actionBar.ShowArrow" class="ps-3"><i class="ion ion-md-arrow-forward"
                                    aria-hidden="true"></i></span>
                        </button>
                    </ng-container>
                    <!-- </ng-container> -->
                    <!--Non Green Additional Sections Starts Here-->
        <ng-container *ngIf="openNonGreenSection">
            <div class="card h-100 m-0">
                <div class="card-header">
                    <h3>
                        <span>{{SelectedTabName | translate}} > {{sectionDisplayName| translate}}</span>
                    </h3>
                </div>
          

                <ng-container *ngIf="!viewEmailTemp">
                <div class="card-body overflow-auto">
                    <!-- <div class="gridContainer mb-3">  -->
                       
                        <!-- <div class="table-responsive"> -->
                          
              <!--- Grid section-->
                        <!-- commenting  for BGV Head role start here -->
                            
                           
                        <!-- commenting  for BGV Head role end here -->

                 

                          <!-- Changes  for BGV Head role end here -->
                </div>
            </ng-container> 
            </div>
        </ng-container>
        <!--Non Green Additional Sections Ends Here-->
                </ng-container>
            </div>
        </div>
    </div>

</div>

<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
        (selected)="setNotifyRes($event) " (close)="closeNotifybox() "></notification>
</div>

<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>


<!--Attachment section-->
<ng-container *ngIf="DisplayAttachment==true">
    <file-upload [Module]="'SubCheck'" [RefId1]="RequestId" [IsMultiple]="false" (close)="closeAttachment($event)"
        [Permissions]="AttachPerm" [CandidateId]="RequestData.CandidateId">
    </file-upload>
</ng-container>

<ng-container *ngIf="selectedTab=='CreateEmail' && DisplayAttachment">
    <file-upload [Module]="'Mail'" [RefId1]="RequestId" [RefId2]="" [RefId3]="" [RefId4]="" [IsMultiple]="true"
        (close)="closeAttachmentEmail($event)" [Permissions]="AttachPerm" [UploadType]="" [CandidateId]="">
    </file-upload>
</ng-container>

<!--Modelpop for email-->
<modal-popup id="EmailPopup" style="display: none;" class="modal-body_email" [modaldetail]="{'header':'Insufficiency Clearence Email','height':'580','width':'800'}" >
    <ng-container *ngIf="viewEmailTemp" style="width:100%;"> 
        <div class="modal-body modal-body_email" style="width:100%">
            <div  class="card-body overflow-auto" style="width:100%;">
            <customform #emailcustform style="width:100% ;"                                                                                     
            [FormTemplate]="BUHRTemplate | filterBy: {Group: ('CreateEmail_form')}"
            [FormData]="selectedInfoData" [FormProp]="EmailFormProp"
            [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
            (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
        </customform>
       <label for="lname" style="font-size:16px;"><b>Message </b><label style="color:red;">*</label></label>
       <div Id="divmailtext" contenteditable="true" [innerHtml]=selectedInfoData.MailText1  (change)="changeFn($event)"></div>
         </div>

<!--Attachment section-->
<ng-container *ngIf="selectedTab=='CreateEmail' && DisplayAttachment">
    <file-upload [Module]="'Mail'" [RefId1]="RequestId" [RefId2]="" [RefId3]="" [RefId4]="" [IsMultiple]="true"
        (close)="closeAttachmentEmail($event)" [Permissions]="AttachPerm" [UploadType]="" [CandidateId]="">
    </file-upload>
</ng-container>

        <ng-container  *ngIf="BUHRProperties.Emailbuttons != null && BUHRProperties.Emailbuttons != undefined && BUHRProperties.Emailbuttons.length > 0">

                            <div class="d-flex justify-content-start">

                                <ng-container
                                    *ngFor="let actionBar of Emailbuttons  | filterBy: {IsApplicable: true,HasPermission :true}
                                 | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">

                                    <button type="button" [ngClass]="actionBar.CustomClass"
                                        (click)="btnClick(actionBar)"
                                        [disabled]="!actionBar.IsEnabled"
                                        value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                        |
                                        translate
                                        }}
                                        <span *ngIf="actionBar.ShowArrow" class="ps-3"><i
                                                class="ion ion-md-arrow-forward"
                                                aria-hidden="true"></i></span>
                                    </button>

                                </ng-container>

                            </div>
                            <div> <h5 style="color:red">Note:-Correct format of To and CC section email Id’s by  semi-colon (;) and mentioning only the email Id without any brackets etc. (eg: abc@capgemini.com;xyz@capgemini.com)</h5></div>
                        </ng-container>
                        <br>
                        <!--Attachment Display section-->
                        <ng-container
                            *ngIf="selectedTab=='CreateEmail' && !DisplayAttachment && fileData.length>0 && fileData!=null ">

                            <div class="table-responsive">
                                <table class="table table-sm table-bordered tblGrid fixed">
                                    <caption class="sr-only">File Attachments Grid</caption>
                                    <thead class="tblGridHeader">
                                        <th width="40" scope="col">#</th>
                                        <th class="text-truncate my-0" scope="col">File Name</th>
                                        <th class="text-truncate my-0" scope="col">Created Date</th>
                                        <th class="text-truncate my-0" scope="col">Created By</th>
                                        <th width="75" scope="col">&nbsp;</th>
                                    </thead>
                                    <tbody
                                        *ngIf="fileData!=null &&  fileData!=undefined && fileData.length>0">
                                        <tr *ngFor="let item of fileData; let item_indx=index">
                                            <td>
                                                <p class="text-truncate my-0">{{item_indx+1}}</p>
                                            </td>
                                            <td>
                                                <p class="text-truncate my-0"><a
                                                        [attr.aria-label]="item?.FileName"
                                                        href="javascript:;" class="cellLink"
                                                        title="{{ item.FileName}}">{{
                                                        item.FileName}}</a></p>
                                            </td>
                                            <!-- <td>
                                                <p class="text-truncate my-0" title="{{ item.CreatedDate | date: _appSharedService.AppDateFormat }}">{{ item.CreatedDate | date: _appSharedService.AppDateFormat }}</p>
                                                </td> -->
                                            <td>
                                                <p class="text-truncate my-0"
                                                    title="{{ item.CreatedName}}">
                                                    {{ item.CreatedDate | date: 'dd/MM/yyyy'}}</p>
                                            </td>

                                            <td>
                                                <p class="text-truncate my-0"
                                                    title="{{ item.CreatedBy}}">
                                                    {{ item.CreatedBy}}</p>
                                            </td>
                                            <td>
                                                <ul class="actionList" style="text-align: center">
                                                    <li>
                                                        <!-- [tooltip]="('Click here to delete'| translate)" container="body" placement="top" -->
                                                        <a aria-label="Delete" href="javascript:;"
                                                            *ngIf="AttachPerm.Upload">

                                                        </a>
                                                    </li>
                                                </ul>
                                                <!-- <p><button class="btn btn-primary" *ngIf="Permissions.Upload&&item.IsDeletable" (click)="showDeleteConfirmation(item)"><i class="fa fa-trash"></i></button></p> -->
                                            </td>
                                        </tr>
                                    </tbody>

                                    <tbody
                                        *ngIf="fileData==null ||  fileData==undefined || fileData.length==0">
                                        <tr>
                                            <td colspan="5">No files attached.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr>



                        </ng-container>
        
          </div>  
          <ng-container *ngIf="viewEmailTemp || selectedTab=='CreateEmail'">
            <div class="filters buttonHolder">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                        <ng-container
                            *ngIf="BUHRProperties.Emailbuttons != null && BUHRProperties.Emailbuttons != undefined && BUHRProperties.Emailbuttons.length > 0">
                            <ng-container *ngFor="let actionBar of BUHRProperties.Emailbuttons  | filterBy: {IsApplicable: true,HasPermission :true}
                              | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
        
        
                                <button type="button" [ngClass]="actionBar.CustomClass" (click)="btnClick(actionBar)"
                                    [disabled]="!actionBar.IsEnabled"
                                    value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName |
                                    translate
                                    }}
                                    <span *ngIf="actionBar.ShowArrow" class="ps-3"><i class="ion ion-md-arrow-forward"
                                            aria-hidden="true"></i></span>
                                </button>
        
        
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
          </ng-container>
          <!-- Notification section -->
         <div *ngIf="displayNotifybox">
         <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
         (selected)="setNotifyRes($event) " (close)="closeNotifybox() "></notification>
         </div>

    

</modal-popup>
<modal-popup id="LWD" style="display: none;" [modaldetail]="{'header':'Update Last Working Day'}">
    <ng-container *ngIf="(LWDTemplate !=null && LWDTemplate !=undefined && LWDTemplate.length>0)">
        <customform #lwdform [FormTemplate]="LWDTemplate" [FormData]="LWDData" [FormProp]="LWDFormProp"
            [FormConfig]="LWDFormConfig" [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)"
            (FieldChange)="onChange($event)">
        </customform>
    </ng-container>

    <div class="">
        <div class="d-flex justify-content-end p-3">
            <button type="button" class="rounded-pill btnCustom btnNegative" (click)="closeModal('LWD')"
                value="{{'Cancel' | translate }}">
                {{'Cancel' | translate }}
            </button>
            <button type="button" class="rounded-pill btnCustom btnPrimary" (click)="updateLWDModalPopup()"
                value="{{'Ok' | translate }}">
                {{'Ok' | translate }}
            </button>
        </div>
    </div>

    <!-- Notification section -->
    <!-- <div *ngIf="displayclientNotifybox">
        <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
            (selected)="setNotifyRes($event) " (close)="closeclientNotifybox() "></notification>
    </div> -->

</modal-popup>

<modal-popup id="RevisedSLADate" style="display: none;" [modaldetail]="{'header':'Update Revised SLA Date'}">
    <ng-container
        *ngIf="(RevisedSLADateTemplate !=null && RevisedSLADateTemplate !=undefined && RevisedSLADateTemplate.length>0)">
        <customform #lwdform [FormTemplate]="RevisedSLADateTemplate" [FormData]="RevisedSLADateData"
            [FormProp]="LWDFormProp" [FormConfig]="LWDFormConfig" [GlobalConfig]="GlobalConfig"
            (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
        </customform>
    </ng-container>

    <div class="">
        <div class="d-flex justify-content-end p-3">
            <button type="button" class="rounded-pill btnCustom btnNegative" (click)="closeModal('RevisedSLADate')"
                value="{{'Cancel' | translate }}">
                {{'Cancel' | translate }}
            </button>
            <button type="button" class="rounded-pill btnCustom btnPrimary" (click)="updateRevisedSLADateModalPopup()"
                value="{{'Ok' | translate }}">
                {{'Ok' | translate }}
            </button>
        </div>
    </div>

    <!-- Notification section -->
    <div *ngIf="displaySLANotifybox">
        <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
            (selected)="setNotifyRes($event) " (close)="closeSLANotifybox() "></notification>
    </div>

</modal-popup>