import { escapeRegExp } from '@angular/compiler/src/util';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, setTestabilityGetter } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActionCode, BGVStatus, ComponentCodes, ComponentConfig, ProcessStatus, RoleCodes, StatusColorCode } from 'src/app/app.interface';
import { CommonAPIService } from 'src/app/shared/app.commonservice';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined } from 'src/app/shared/app.sharedservice';
import { GridComponent } from 'src/modules/grid/grid.component';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { FieldTemplate,FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { RequestService } from '../request.service';
import { environment } from 'src/environments/environment';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { ModalService } from 'src/modules/modal/modal.service';
import { isUndefined } from 'ngx-bootstrap/chronos/utils/type-checks';
import { Buffer } from 'buffer';


@Component({
    selector: 'non-green',
    templateUrl: './non-green.component.html'
})

@AutoUnsubscribe
export class NonGreenComponent implements OnInit {

    @Input() RequestId: number = 0;
    @Input() SelectedTabName: string = '';
    @Input() RequestData: any;
    

    @Output() RefreshRequest = new EventEmitter<any>();
    @ViewChild('additionalsectiongrid', { static: false }) additionalsectiongrid!: GridComponent;
    public NonGreenTemplate: FieldTemplate[] = [];
    public NonGreenPermission: any = [];
    public NonGreenProperties: any = [];
    public NonGreenData: any[] = [];
    public NonGreenAdditionalSectionData: any[] = [];


    public IsLoading: boolean = false;
    public IsReadonly: boolean = false;
    public openNonGreenSection: boolean = false;
    public DisplayAttachment: boolean = false;
    public transId: number = 0
    public uploadModule: string = 'SubCheck';

    public subscriptionList$: Subscription[] = [];

    public displayNotifybox: boolean = false;
    public notify: NotificationData = new NotificationData();

    public curItemsPerPage: number = 10;

    public sectionName: string = '';
    public sectionDisplayName: string = '';
    public actionCode: string = '';
    public submitSecionName:string='';
    public verifiedSecionName:string='';
    public sectionData: any[] = []
    public sectionDataSummary: any[] = []
    public AttachmentItemData: any;
    public fileRefId: any[] = [];
    public fileNames: any[] = [];
    //FOR SUBMIT FOR BGV HEAD
    public NonGreenFormData: any = {};
    public SubmitDetailsTemplate: FieldTemplate[] = [];
    public VerifiedComponentsTemplate: FieldTemplate[] = [];
    public SubmittedDetailsList: any[] = [];
    public VerifiedComponentsList: any[] = [];
    public DisplayadditionalGrid: boolean = false;
    public BGVHeadModifiedItems:any[]=[];
    public isResponsetoEmployee:boolean=false;
    public ReinitiateCaseTemplate: any[] = [];
    public viewReinitiateCase: boolean = false;
    public ReasonForReinitiateCase:string='';

    public isAdditonalSectionClicked: boolean = false;
    public isShowOverallCaseButtonClicked: boolean = false;
  

    public i:number=0;
    public count:number=0;

    //Gayatri
    public fileData: any[] = [];
    public Emailbuttons: any = [];
    public FormConfig: any = {
        BaseUrl: environment.baseURL
      };  
      public selectedTab: string = 'CreateEmail';
      @Input() RequestProperty: any = [];
      public selectedInfoData: any = {};
      public viewEmailTemp: boolean = false;
      public viewEmail: boolean = false;
      @Input() RequestTemplate: FieldTemplate[] = [];
      public viewEmailData: any[] = [];
      public selectedEmailData: any = {};
      public successFlag: boolean = false;
      public selectedEmailActionData: any = {};
      public ACTION: string = '';
      public actionCodes: any = {
        'Forward': 'ACM003',
        'Resend': 'ACM004',
        'Create_Email': 'AC009',
        'RaiseL3':'AC009', 
        'Responsetoemployee':'AC009',       
        'SendToVendor':'AC008',
        'FOLLOWUPMAIL':'REM041',
        'SendToCentralHR':'AC007',
        'SHOWCAUSENOTICE':'REM037',
        'SendToBGVHead':'AC033',
        'TerminationApprovalToBGVHead':'AC036',
        'SendToBUHR':'AC010',
        'CloseCase':'AC014'
         
      }
      public raisel3modifieditems: any=[];
      public vendorsubmitmodifieditems:any=[];
      public Tomail: string = '';
      public Ccmail: string = '';
      public Mailsubject: string = '';
      public MailMessage: string = '';
      public showaddverifiedcomp:boolean=false;
      public rows: number = 20;
      public GridProp: any = {
        Editable: true,
        IsSortable: false
    };
    public GlobalConfig: any = {};

    public AttachPerm: any = {
        Upload: true,
        Download: false
    };


    public ReinitiateCaseData: any = {
    IsEditable: 1,
    Code: 0,
    CodeDescription: null
    };

    public focusableEles!: HTMLElement[];
    public firstFocusableEle!: HTMLElement;
    public lastFocusableEle!: HTMLElement;
    constructor(private eleRef: ElementRef, private _requestService: RequestService, private _commonAPIService: CommonAPIService
        , private _appSharedService: AppSharedService, private modalService: ModalService) {
        let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
            this.ngOnInit();
        });
        this.subscriptionList$.push(subscribe$);
    }

    ngOnInit(): void {
        if (!isNullOrUndefined(this._appSharedService.currentRoleData)) {
            // if (this.RequestData.IsStopCaseSubmitted === true || this.RequestData.IsStopCase === true) {
            //     this.GridProp.Editable = false;
            //     this.IsReadonly = true;
            // }
            this.getNonGreenTemplate();
        }
      
        this.rows = (this.FormProp.rows === undefined ? this.rows : this.FormProp.rows);
          this.Emailbuttons = JSON.parse(`[
            {
               "Name":"Attachment",
               "DisplayName":"Attachment",
               "CustomClass":" btnCustom btnNegative small float-left",
               "AccessKey":"N",
               "IsApplicable":true,
               "HasPermission":true,
               "IsEnabled":true,
               "Confirmation":{
                  "IsEnabled":false,
                  "Message":null,
                  "Action":null
               },
               "SeqOrder":1
            }
       
         ]`);    
    }
    public FormProp: FormProperty = {
        'ColSize': 1,
        'CaptionBold': false,
        'CaptionTop': true,
        'IsEditable': true,
        'ShowControlAlways': true,
        'rows':20
      };

      public FormPropReinitiateCase: FormProperty = {
        'ColSize': 2,
        'CaptionBold': false,
        'IsEditable': true
      };
   
      public SubmitFormProp: FormProperty = {
        'ColSize': 2,
        'CaptionBold': false,
        'CaptionTop': true,
        'IsEditable': true,
        'ShowControlAlways': true,
        
      };
      public BasicFormProp: FormProperty = {
        'ColSize': 1,
        'CaptionBold': false,
        'CaptionTop': true,
        'IsEditable': true,
        'ShowControlAlways': true,
        
      };
    
    
    setDefaultPageFocus() {

        setTimeout(() => {

            this.focusableEles = this.eleRef.nativeElement.querySelectorAll(`a[href], area[href],
          input:not([disabled]), select:not([disabled]), textarea:not([disabled]),
               button:not([disabled]), [tabindex="0"]`);
            this.focusableEles = Array.prototype.slice.call(this.focusableEles);

            if (this.focusableEles.length > 0) {
                this.firstFocusableEle = this.focusableEles[0];
                this.lastFocusableEle = this.focusableEles[this.focusableEles.length - 1];
                this.firstFocusableEle.focus();
            }


        }, 50);
    }
   
     
    getNonGreenTemplate(): void {

        this.IsLoading = true;
        let odata: ComponentConfig = {
            Component: 'NonGreen', ComponentCode: ComponentCodes.NonGreen
        };

        let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
            if (res !== undefined && res !== null && res.Status === 200) {
                this.NonGreenTemplate = res.Data.FieldTemplate!;
                this.NonGreenPermission = res.Data.Permission;
                this.SubmitDetailsTemplate=this.NonGreenTemplate.filter(x=>x.Group==='Submit_Template');
                this.VerifiedComponentsTemplate=this.NonGreenTemplate.filter(x=>x.Group==='VerifiedComponents');
                this.ReinitiateCaseTemplate = this.NonGreenTemplate.filter(x => x.Group === 'ReinitiateCaseNG');

                this.setCompProperties(res.Data.Properties!);
                console.log(res.Data.Properties!);
                //this.NonGreenProperties.BlockCols = JSON.parse(`[{"Field":"UploadStatus","Value":"10045","BlockCols":["NGSTATUSCODE","NONGREENCOMMENT"]},{"Field":"UploadStatus","Value":"10044","BlockCols":["NGSTATUSCODE","NONGREENCOMMENT"]},{"Field":"UploadStatus","Value":"10048","BlockCols":["NGSTATUSCODE","NONGREENCOMMENT"]},{"Field":"UploadStatus","Value":"10051","BlockCols":["NGSTATUSCODE","NONGREENCOMMENT"]},{"Field":"UploadStatus","Value":"10047","BlockCols":["NGSTATUSCODE","NONGREENCOMMENT"]},{"Field":"UploadStatus","Value":"10050","BlockCols":["NGSTATUSCODE","NONGREENCOMMENT"]},{"Field":"UploadStatus","Value":"10073","BlockCols":["NGSTATUSCODE","NONGREENCOMMENT"]},{"Field":"UploadStatus","Value":"10074","BlockCols":["NGSTATUSCODE","NONGREENCOMMENT"]},{"Field":"UploadStatus","Value":"10069","BlockCols":["NGSTATUSCODE","NONGREENCOMMENT"]}]`);
                //this.NonGreenProperties.BlockCols = JSON.parse(`[{"Field":"UploadStatus","Value":"10045","BlockCols":["NGSTATUSCODE","NONGREENCOMMENT"]},{"Field":"UploadStatus","Value":"10048","BlockCols":["NGSTATUSCODE","NONGREENCOMMENT"]},{"Field":"UploadStatus","Value":"10051","BlockCols":["NGSTATUSCODE","NONGREENCOMMENT"]},{"Field":"UploadStatus","Value":"10073","BlockCols":["NGSTATUSCODE","NONGREENCOMMENT"]},{"Field":"UploadStatus","Value":"10074","BlockCols":["NGSTATUSCODE","NONGREENCOMMENT"]},{"Field":"UploadStatus","Value":"10069","BlockCols":["NGSTATUSCODE","NONGREENCOMMENT"]}]`);
                this.NonGreenProperties.BlockCols = JSON.parse(`[{"Field":"UploadStatus","Value":"10045","BlockCols":["NGSTATUSCODE","NONGREENCOMMENT"]},{"Field":"UploadStatus","Value":"10048","BlockCols":["NGSTATUSCODE","NONGREENCOMMENT"]},{"Field":"UploadStatus","Value":"10051","BlockCols":["NGSTATUSCODE","NONGREENCOMMENT"]}]`);
                this.getCheckDocumentDetailsList();
                this.setDefaultPageFocus();
            }
        },
            err => {
                this.IsLoading = false;
                console.log('Error on getUpdateOverallStatusTemplate.');
            });
           
            console.log(this.RequestProperty)
          this.getMailerTransaction();
          this.selectedInfoData.IsEditable = 1;
         // this.GetNGMailDetails();
          
          this.getBasicDetails();
          this.getSubmittedDetails();
          //this.getVerifiedComponentsList();
          this.subscriptionList$.push(subscribe$);
          console.log(this.RequestTemplate);    
          
    }

    setCompProperties(Properties: any[]) {
        if (!isNullOrUndefined(Properties) && Properties.length > 0) {
            console.log(this.NonGreenProperties)

            // 2) Blocking Cols based on custom values
            this.NonGreenProperties.BlockCols = (Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase()).length > 0 ?
                JSON.parse(Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase())[0].JSONData.toString()) : []);

            // Other component specific props
            Properties.forEach(prop => {
                // avoiding generic props
                if (!isNullOrUndefined(prop.Name) &&
                    prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
                    && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
                    this.NonGreenProperties[prop.Name] = JSON.parse(prop.JSONData.toString());
                }
            });
        }
    }

    onClick(event: any): void {
        console.log(event);
        let selitem: any = event.item;
        console.log(selitem);
        


        this.fileNames = [];
        this.GetAttachmentDetails(selitem.AttachmentPath);
        if (event.fieldname.toUpperCase() === 'ACTION') {
            this.transId = 0;
            this.transId = selitem.TransactionId;
            selitem.IsRowHighlight = selitem.IsModified;

            // event.icon.IconCSS
            switch (event.icon.Value) {
                case '0':
                    //Upload
                    // if (!isNullOrUndefined(this.sectionData) && this.sectionData.length > 0
                    //     && !isNullOrUndefined(this.sectionData[0].FileName) && this.sectionData[0].FileName !== '') {
                    //     this.deleteAttachment(true);
                    // } else 
                    {
                        selitem.IsModified = 1;
                        selitem.IsRowHighlight = selitem.IsModified;
                        this.AttachmentItemData = selitem;
                        this.DisplayAttachment = true;
                    }

                    this.uploadModule = this.sectionDisplayName === 'Stop Case' ? 'StopCase' : this.uploadModule;
                    this.DisplayAttachment = true;
                    break;
            }
        }
        if (event.fieldname.toUpperCase() === 'FILEACTION') {

            // event.icon.IconCSS
            switch (event.icon.Value) {
                case '0':
                    //Download
                    this.downloadAttachment(selitem);

                    break;
            }
        }
        // if (event.fieldname.toUpperCase() === 'CHECKSTATUS'){

        //     if(this.actionCode===ActionCode.NG_Submit_to_BGV_Head_Approval_For_Termination)
        //     {
        //         let ngTerminationData=this.NonGreenData.filter(x=>x.IsEditable===1);
        //         event.item.DocumentTypeId=ngTerminationData[this.i].DocumentTypeId;
        //         event.item.DocumentTypeName=ngTerminationData[this.i].DocumentTypeName;
        //         event.item.CheckId=ngTerminationData[this.i].CheckId;
               
        //         this.i++;
        //     }

        //     else{
        //         event.item.DocumentTypeId=this.sectionData[this.i].DocumentTypeId;
        //         event.item.DocumentTypeName=this.sectionData[this.i].DocumentTypeName;
        //         event.item.CheckId=this.sectionData[this.i].CheckId;
        //         this.i++;
        //     }
           
           
           
           
           
        //     //let secItem=[];
        //    // secItem= this.sectionData;
        //    // selitem.DocIds='2,5';
        //    // selitem.DocumentTypeId=this.sectionData[0].DocumentTypeId;
        //    // selitem.DocumentTypeName=this.sectionData[0].DocumentTypeName;
        // }

    }
    downloadAttachment(data: any) {
        this.IsLoading = true;

        let odata: any = {
            'Module': data.FileModule,
            'Reference1': Number(this.RequestId),
            'Reference2': data.CheckId,
            'Reference3': 0,
            'Reference4': 0,
            'TransactionId': data.FileReferenceId,
            // 'FilePath': data.FilePath,
            'FilePath': '',
            'FileName': data.FileName
        };

        let subscribe$: Subscription = this._requestService.downloadAttachment(odata).subscribe(result => {
            // if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
            //   console.log(result);
            // }
            if (!isNullOrUndefined(result)) {
                let url = window.URL.createObjectURL(result.data);
                let a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                // a.download = res.filename;
                a.download = data.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
                // this._toastr.success('Resume downloaded successfully', this.CompTitle);
                console.log('Resume downloaded successfully');

                this.notify = {
                    info: {
                        header: 'Confirmation Alert',
                        message: 'Document downloaded successfully',
                        yes: '',
                        no: '',

                        IsHeaderHidden: true,
                        HeaderIcon: 'fa fa-check-circle text-success',
                        IsCrossEnable: true,
                        SizeClass: 'modal-sm'
                    },

                    action: '',
                    item: null
                };

                this.displayNotifybox = true;
            }
            this.IsLoading = false;

        },
            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }
    changeFn(event: any) {
        //this.foo =event.target.value;
      }
      modelChangeFn(event: any) {
       // this.bar = event;
      }
    onChange(event: any): void {
        let selitem: any = event.item;
        // Emitting onchange event of resource plan to parent container
        selitem.IsModified = 1;
        this.transId = 0;
        this.transId = selitem.TransactionId;
        selitem.IsRowHighlight = selitem.IsModified;

        if(!isNullOrUndefined(selitem.ReasonForReinitiateCase))
        {
        this.ReasonForReinitiateCase=selitem.ReasonForReinitiateCase;
        }
        
        this.enableDisableButtons();
        console.log(event)        
         //this.selectedInfoData = JSON.parse(JSON.stringify(selitem));
        switch (event.fieldname.toUpperCase()) {
            case 'NGSTATUSCODE':
                if ((isNullOrUndefined(selitem.NGStatusCode) || selitem.NGStatusCode === '') && !isNullOrUndefined(selitem.OldNGStatusCode) && selitem.OldNGStatusCode !== '') {

                    // this.showNotificationMessage('Please select atleast one Non green status.', 2, '', null, '', '');

                    setTimeout(() => {
                        selitem.NGStatusCode = selitem.OldNGStatusCode
                        selitem.NGStatusDesc = selitem.OldNGStatusDesc
                        selitem.NonGreenComment = selitem.OldNonGreenComment
                        selitem.IsModified = 0;
                        selitem.IsRowHighlight = selitem.IsModified;
                    }, 50);

                }
                break;

            case 'NONGREENCOMMENT':

                if (this.sectionData.length > 0) {
                    let NonGreenComment = "";

                    this.sectionData.forEach((item: any) => {
                        NonGreenComment += NonGreenComment == "" ? item.NonGreenComment : "," + item.NonGreenComment;
                    });

                    this.sectionDataSummary[0].NonGreenComment = NonGreenComment;
                }
                break;
             

        }

        if (event.fieldname.toUpperCase() === 'ACTION') {
            // event.icon.IconCSS
            switch (event.icon.Value) {
                case '0':
                    //Upload
                    this.uploadModule = this.sectionDisplayName === 'Stop Case' ? 'StopCase' : this.uploadModule;
                    this.DisplayAttachment = true;
                    break;
            }
        }
        // if (event.fieldname.toUpperCase() === 'DOCUMENTTYPEID'){

        //     let secItem=[];
        //     secItem= this.sectionData;
        //     selitem.DocIds='2,5';
        //     // selitem.DocumentTypeId=this.sectionData[0].DocumentTypeId;
        //     // selitem.DocumentTypeName=this.sectionData[0].DocumentTypeName;
        // }
        if (this.sectionData.length > 0) {
            let Tomail = "";

            this.sectionData.forEach((item: any) => {
             item.ToRoleId=selitem.ToRoleId;
             item.CCRoleId=selitem.CCRoleId;
             item.Subject=selitem.Subject;
             item.Message=selitem.Message;

            });

        }
       
        let eventdata = {
            fieldname: event.fieldname
        };
    }


    setNotifyRes(event: any): void {
        console.log('notify res >> ' + JSON.stringify(event));
        let canClose: boolean = true;
        switch (event.action.toUpperCase()) {
            case 'VENDORSUBMIT':
                if (event.result.toUpperCase() === 'SUBMIT NOW') {
                        this.IsLoading=true;
                        this.viewEmailTemp = true;
                        this.ACTION = this.actionCodes.SendToVendor;
                        this.openModal('EmailPopup')
                        this.GetNGMailDetails();
                      

                    //this.SubmitNonGreen(event.item, 'Case is sent to vendor.');
                }
                break;
            case 'CENTRALHRSUBMIT':
                if (event.result.toUpperCase() === 'SUBMIT NOW') {
                    this.ACTION = this.actionCodes.SendToCentralHR;

                    //this.SubmitNonGreenForm(this.NonGreenFormData,'Case submitted to Central HR.');   
                    //this.SubmitNonGreen(event.item, 'Case is sent to Central HR.');   
                    
                   this.BGVHeadModifiedItems= event.item.filter((x: any) => x.IsModified === 1);
                    this.SubmitNonGreenForm(this.NonGreenFormData,'');         
                    // this.openModal('EmailPopup')
                    // this.GetNGMailDetails(); 

                }
                break;
            case 'TERMINATIONSUBMIT':
                if (event.result.toUpperCase() === 'SUBMIT NOW') {
                    event.item.forEach((item: any) => {
                        // item.IsModified = (item.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Rejected_1
                        //     || item.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Rejected_2
                        //     || item.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Rejected_3) ? true : item.IsModified;
                        item.IsModified = (item.ColorCode === StatusColorCode.Red || item.ColorCode === StatusColorCode.Amber) ? 1 : 0;
                    });
                    this.ACTION = this.actionCodes.SendToBUHR;
                    this.SubmitNonGreen(event.item, 'Case is sent for termination.');
                }
                break;
                case 'FORTERMINATION':
                    if (event.result.toUpperCase() === 'SUBMIT NOW') {
                        event.item.forEach((item: any) => {
                            // item.IsModified = (item.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Rejected_1
                            //     || item.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Rejected_2
                            //     || item.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Rejected_3) ? true : item.IsModified;
                            item.IsModified = (item.ColorCode === StatusColorCode.Red || item.ColorCode === StatusColorCode.Amber) ? 1 : 0;
                        });
                        this.ACTION = this.actionCodes.SendToBUHR;
                        this.SubmitNonGreen(event.item, 'Case is sent for termination to BU HR.');
                    }
                    break;
    
                
            case 'CLOSECASE':
                if (event.result.toUpperCase() === 'SUBMIT NOW') {
                    event.item.forEach((item: any) => {
                        item.IsClosed = true;
                    });
                    this.viewEmailTemp = true;
                    this.ACTION = this.actionCodes.CloseCase;
                    this.openModal('EmailPopup')
                    this.GetNGMailDetails();
                    //this.SubmitNonGreen(event.item, 'Case closed successfully.');
                }
                break;

            case 'L3INSUFFSUBMIT':
                if (event.result.toUpperCase() === 'SUBMIT NOW') {
                        this.viewEmailTemp = true;
                        this.ACTION = this.actionCodes.RaiseL3;
                        this.openModal('EmailPopup')
                        this.GetNGMailDetails();
                      
                   
                }
                break;
            
            case 'RESPONSETOEMPLOYEESUBMIT':
                if (event.result.toUpperCase() === 'SUBMIT NOW') {                    
                    this.ResponseToEmployee(event.item, 'Response to Candidate/Employee sent successfully.');
                }
                break;

                

            case 'CANCELSUBMISSION':
                if (event.result.toUpperCase() === 'YES') {
                    let FileData = this.sectionData.filter(x => ((!isNullOrUndefined(x.FileName)) && x.FileName !== ''));
                    // if (!isNullOrUndefined(FileData) && FileData.length > 0) {
                    //     this.deleteAttachment(FileData);
                    // } else {
                    this.sectionData.forEach(item => {
                        item.DocumentList = null;
                        item.DocumentListDesc = null;
                        item.IsModified = (item.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Accepted_1
                            || item.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Accepted_2
                            || item.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Accepted_3
                            // || item.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_1
                            // || item.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_2
                            || item.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_3) ? 0 : item.IsModified;
                        item.IsRowHighlight = item.IsModified;
                    });
                    this.sectionData = [];
                    this.openNonGreenSection = false;
                    // }
                }
                break;

            case 'STOPCASESUBMIT':
                if (event.result.toUpperCase() === 'YES') {
                    this.requestStopCase();
                }
                break;

            case 'STOPCASECANCEL':
                if (event.result.toUpperCase() === 'YES') {
                    if (!isNullOrUndefined(this.sectionData[0].FileName) && this.sectionData[0].FileName !== '') {
                        this.deleteAttachment(false);
                    }
                    else {
                        this.sectionData = [];
                        this.openNonGreenSection = false;
                    }
                    // else if (!isNullOrUndefined(this.reportData[0].Comments) && this.reportData[0].Comments !== '') {
                    //     this.openReport = false;
                    // }

                }
                break;

            case 'BGVHEADSUBMIT':
                if (event.result.toUpperCase() === 'SUBMIT NOW') {    
                    this.ACTION = this.actionCodes.SendToBGVHead;
                    //this.SubmitNonGreen(event.item, 'Case is sent to BGV Head.'); 
                    this.BGVHeadModifiedItems=event.item;  
                    this.SubmitNonGreenForm(this.NonGreenFormData,'');         
                    // this.openModal('EmailPopup')
                    // this.GetNGMailDetails();                
                }   
                break;
            case 'BGVHEADSAVE':                          
                         
                    if (event.result.toUpperCase() === 'SAVE') {    
                        //this.BGVHeadModifiedItems=event.item;
                        this.SaveNonGreenForm(this.NonGreenFormData,'');         
                                      
                    } 
 
                break;

            case 'CENTRALHRSAVE':                          
                
            if (event.result.toUpperCase() === 'SAVE') {    

                this.SaveNonGreenForm(this.NonGreenFormData,'');         
                                
            } 
            break;
            case 'DELETEDETAILS':
                this.InsertMoreDetails('Delete', event.item);
                break;
            case 'TERMINATIONAPPROVALSUBMIT':
                if (event.result.toUpperCase() === 'SUBMIT NOW') {    
                    this.ACTION = this.actionCodes.TerminationApprovalToBGVHead;
                    //let ngTerminationData=this.NonGreenData.filter(x=>x.PendingWith === RoleCodes.NonGreen.toString()
                    //&& (x.ColorCode === StatusColorCode.Red||x.ColorCode === StatusColorCode.Amber));
                    
                   // this.SubmitNonGreen(ngTerminationData, 'Case is sent to BGV Head for Termination Approval.');            
                    this.SubmitNonGreenForm(this.NonGreenFormData,'');         
                    // this.openModal('EmailPopup')
                    // this.GetNGMailDetails();
                    //this.SubmitNonGreenForm(this.NonGreenFormData,'Case submitted to BGV Head');                
                }  
                break;
                case 'TERMINATIONAPPROVALSAVE':
                    if (event.result.toUpperCase() === 'SAVE') {    
                       // this.ACTION = this.actionCodes.TerminationApprovalToBGVHead;
                        this.SubmitNonGreenForm(this.NonGreenFormData,'');         
                               
                    }  
                    break;
                case 'REINITIATECASE':
                    if (event.result.toUpperCase() === 'YES') {
                        this.viewReinitiateCase=true;
                        this.openModal('ReinitiateCaseNG')
                    }
                    break;
        
               
        }
        if (canClose) {
            this.closeNotifybox();
        }
    }

    closeNotifybox(): void {
        this.displayNotifybox = false;
    }

    actionButtonClick(data: any) {
        let sectionitems = this.sectionData.filter(x => x.IsModified === 1);
        if (!isNullOrUndefined(data.Name) && data.Name !== '') {
            this.actionCode = '';
            this.actionCode = data.ActionCode;        
            switch (data.Name.toUpperCase()) {
                case 'CLOSECASE':
                    if (this.validateMandatoryFields(this.NonGreenData.filter(x => x.IsEditable === 1)) && this.NonGreenData.filter(x => x.IsEditable === 1).length > 0) {
                        // this.SubmitNonGreen(sectionitems);
                        this.showNotificationMessage('Are you sure you want to close the case?', 1, data.Name, this.NonGreenData.filter(x => x.IsEditable === 1), 'Submit now', 'Cancel');
                    
                       
                    }
                    break;
                case 'RAISEL3INSUFFICIENCY':                  
                    this.sectionData = [];
                    this.sectionData = this.NonGreenData.filter(x => x.IsModified === 1 && x.UploadStatus !== BGVStatus.Green_SR_Submitted
                       // && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Rejected_1
                        && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Accepted_1
                        //&& x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Rejected_2
                        //&& x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Rejected_3
                        && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Accepted_2
                        && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Accepted_3
                       // && x.UploadStatus !== BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_1
                       // && x.UploadStatus !== BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_2
                        && x.UploadStatus !== BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_3
                        && x.UploadStatus !== BGVStatus.L3_Insufficiency_Additional_details_or_documents
                        && x.UploadStatus !== BGVStatus.L3_Insufficiency_Additional_details_or_documents_1
                        && x.UploadStatus !== BGVStatus.L3_Insufficiency_Additional_details_or_documents_2
                        // && x.UploadStatus !== BGVStatus.L3_Insufficiency_cleared
                        );
                    if (!isNullOrUndefined(this.sectionData) && this.sectionData.length > 0) {
                        this.DisplayadditionalGrid=true;
                        this.openAdditionalSection(data);
                    } else {
                        // this.showNotificationMessage('No data available', false);
                        this.showNotificationMessage('No data available.', 2, '', null, '', '');
                    }
                    break;

                case 'RESPONSETOEMPLOYEE':                  
                this.sectionData = [];
                this.sectionData = this.NonGreenData.filter(x => x.IsModified === 1 && x.UploadStatus !== BGVStatus.Green_SR_Submitted                 
                    && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Accepted_1               
                    && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Accepted_2
                    && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Accepted_3                   
                    && x.UploadStatus !== BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_3
                    || x.UploadStatus === BGVStatus.L3_Insufficiency_Additional_details_or_documents
                    || x.UploadStatus === BGVStatus.L3_Insufficiency_Additional_details_or_documents_1
                    || x.UploadStatus === BGVStatus.L3_Insufficiency_Additional_details_or_documents_2
                    
                    );
                if (!isNullOrUndefined(this.sectionData) && this.sectionData.length > 0) {
                    this.DisplayadditionalGrid=true;
                    this.openAdditionalSection(data);
                } else {
                    // this.showNotificationMessage('No data available', false);
                    this.showNotificationMessage('No data available.', 2, '', null, '', '');
                }
                break;

                    
                case 'SUBMITTOVENDOR':
                    this.sectionData = [];
                    this.sectionData = this.NonGreenData.filter(x => (x.IsModified === 1) // && x.NGStatusCode === ActionCode.QC_Accept ----as per bussiness  "Send to vendor" to should enabled at both scenario on accepted and rejected
                        || ((x.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Accepted_1
                            || x.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Accepted_2
                            || x.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Accepted_3
                            //|| x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_1
                            //|| x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_2
                            || x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_3) && x.PendingWith === RoleCodes.NonGreen.toString()));
                    if (!isNullOrUndefined(this.sectionData) && this.sectionData.length > 0) {
                        this.DisplayadditionalGrid=true;
                        this.openAdditionalSection(data);
                    } else {
                        // this.showNotificationMessage('No data available', false);
                        this.showNotificationMessage('No data available.', 2, '', null, '', '');
                    }

                    break;
                case 'FORTERMINATION':

                    if (this.NonGreenData.filter(x => x.PendingWith === '8').length > 0 && this.validateMandatoryFields(this.NonGreenData.filter(x => x.PendingWith === '8'))) {
                        // this.SubmitNonGreen(sectionitems);
                        // this.showSubmitConfirmation('Are you sure wanted to send the case for termination?', data.Name, sectionitems);
                        this.sectionData = [];
                        this.sectionData = this.NonGreenData.filter(x => x.PendingWith === '8');
                        //this.openAdditionalSection(data);
                        // // this.showNotificationMessage('Are you sure you want to send the case for termination?', 1, data.Name, this.NonGreenData.filter(x => x.PendingWith === '8'), 'Submit now', 'Cancel');
                         
                          
                            this.showNotificationMessage('Are you sure you want to submit case to BU HR for termination?', 1, data.Name, this.sectionData, 'Submit now', 'Cancel');
                      
                        break;    
                    
                    }
                    break;
                case 'SUBMITFORCENTRALHR':
                    //this.sectionData = [];
                    this.showaddverifiedcomp=false;
                    this.sectionData = this.NonGreenData.filter(x => x.IsModified === 1 && x.NGStatusCode === ActionCode.QC_Accept && x.ColorCode !== StatusColorCode.Green
                        && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Rejected_1
                        && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Accepted_1
                        && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Rejected_2
                        && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Rejected_3
                        && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Accepted_2
                        && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Accepted_3
                       // && x.UploadStatus !== BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_1
                        //&& x.UploadStatus !== BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_2
                        && x.UploadStatus !== BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_3);
                    if (!isNullOrUndefined(this.sectionData) && this.sectionData.length > 0) {
                        if (this.validateMandatoryFields(this.sectionData)) {
                        this.DisplayadditionalGrid=false;
                        this.openAdditionalSection(data);
                        }
                    } else {
                        // this.showNotificationMessage('No data available', false);
                        this.showNotificationMessage('No data available', 2, '', null, '', '');
                    }
                    break;

                    case 'SUBMITFORBGVHEAD':
                        //this.sectionData = [];
                        this.showaddverifiedcomp=true;
                        this.sectionData = this.NonGreenData.filter(x => x.IsModified === 1 && x.NGStatusCode === ActionCode.QC_Accept && x.ColorCode !== StatusColorCode.Green
                            && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Rejected_1
                            && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Accepted_1
                            && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Rejected_2
                            && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Rejected_3
                            && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Accepted_2
                            && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Accepted_3
                            //&& x.UploadStatus !== BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_1
                            //&& x.UploadStatus !== BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_2
                            && x.UploadStatus !== BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_3);
                        if (!isNullOrUndefined(this.sectionData) && this.sectionData.length > 0) {
                            if (this.validateMandatoryFields(this.sectionData)) {
                             this.DisplayadditionalGrid=false;
                             console.log('action',this.actionCode)
                            
                            this.openAdditionalSection(data);
                            }
                        } else {
                            // this.showNotificationMessage('No data available', false);
                            this.showNotificationMessage('No data available', 2, '', null, '', '');
                        }
                        break;
                case 'FOLLOWUPMAIL':
                    //if (this.validateMandatoryFields(sectionitems)) {
                      
                        this.viewEmailTemp = true;
                        this.ACTION = this.actionCodes.FOLLOWUPMAIL;
                        this.openModal('EmailPopup')
                        this.GetNGMailDetails();
                       //}
                        break;
                 case 'SHOWCAUSENOTICE':
                    
                    //if (this.validateMandatoryFields(sectionitems)) {
                        this.viewEmailTemp = true;
                        this.ACTION = this.actionCodes.SHOWCAUSENOTICE;
                        this.openModal('EmailPopup')
                        this.GetNGMailDetails();
                       //}
                        break;
                              
                case 'VENDORSUBMIT':
                    if (this.validateMandatoryFields(sectionitems)) {
                        // this.SubmitNonGreen(sectionitems);
                        // this.showSubmitConfirmation('Are you sure wanted to submit case to vendor?', data.Name, sectionitems);
                    //    this.viewEmailTemp = true;
                    //    this.ACTION = this.actionCodes.SendToVendor;
                    //    this.openModal('EmailPopup')
                    //    this.GetNGMailDetails();
                        this.vendorsubmitmodifieditems=sectionitems;
                        this.showNotificationMessage('Are you sure you want to submit case to vendor?', 1, data.Name, sectionitems, 'Submit now', 'Cancel');
                    }
                    break;                  
                case 'VENDORCANCEL':
                    this.closeAdditionalSection();
                    break;
                case 'CENTRALHRSUBMIT':
                    if (this.ValidateFormData()) { 
                        // this.SubmitNonGreen(sectionitems);
                        // this.showSubmitConfirmation('Are you sure wanted to submit case to central HR?', data.Name, sectionitems);
                        //let modifyRows = sectionitems.filter(x => !isNullOrUndefined(x.NonGreenComment) && x.NonGreenComment !== '');
                        let modifyRows = sectionitems.filter(x => !isNullOrUndefined(x.NonGreenComment) && x.NonGreenComment !== '');                       
                        // //this.SubmitNonGreenForm(this.NonGreenFormData, 'Case is sent to Central HR.');
                        //  this.viewEmailTemp = true;
                        //  this.ACTION = this.actionCodes.SendToCentralHR;
                        //  this.openModal('EmailPopup')
                        //  this.GetNGMailDetails();

                        // this.showNotificationMessage('Are you sure you want to submit case to Central HR?', 1, data.Name, modifyRows, 'Submit now', 'Cancel');
                        this.showNotificationMessage('Are you sure you want to submit case to Central HR?', 1, data.Name, this.NonGreenData.filter(x => x.PendingWith === RoleCodes.NonGreen.toString()), 'Submit now', 'Cancel');
                    }
                    break;
                case 'CENTRALHRCANCEL':
                    this.closeAdditionalSection();
                    break;
                case 'L3INSUFFSUBMIT':
                    // if (this.validateMandatoryFields(sectionitems)) {
                    //     this.RaiseL3Insufficiency(sectionitems);
                    // }
                    if (this.validateMandatoryFields(sectionitems)) {
                        // this.SubmitNonGreen(sectionitems);
                        // this.showSubmitConfirmation('Are you sure want to raise L3 insufficiency?', data.Name, sectionitems);
                       // this.RaiseL3Insufficiency(sectionitems, 'Insufficiency raised successfully.');
                        // this.viewEmailTemp = true;
                        // this.ACTION = this.actionCodes.RaiseL3;
                        // this.openModal('EmailPopup')
                        // this.GetNGMailDetails();
                      
                       
                       // this.openAdditionalSection(data);
                       this.raisel3modifieditems=sectionitems;
                        this.showNotificationMessage('Are you sure you want to raise L3 insufficiency?', 1, data.Name, sectionitems, 'Submit now', 'Cancel');
                    }
                    break;
                case 'L3INSUFFCANCEL':
                    this.closeAdditionalSection();
                    break;

                case 'RESPONSETOEMPLOYEESUBMIT':

                    if (this.validateMandatoryFields(sectionitems)) {

                        
                        this.showNotificationMessage('Are you sure you want to send response to Candidate/Employee?', 1, data.Name, sectionitems, 'Submit now', 'Cancel');
                    }
                    break;
                case 'RESPONSETOEMPLOYEECANCEL':
                    this.closeAdditionalSection();
                    break;

                //StopCase
                case 'STOPCASE':
                    // Stop Case Button Click 
                    this.openAdditionalSection(data);
                    break;


                //StopCase
                case 'STOPCASESUBMIT':
                    // Stop Case Comments Section Click
                    this.showNotificationMessage('Are you sure you want to stop the case?', 1, 'STOPCASESUBMIT', this.sectionData, 'Yes', 'No');

                    break;

                case 'STOPCASECANCEL':
                    // this.showStopCaseConfirmation();
                    this.closeAdditionalSection();
                    break;

                case 'TERMINATIONSUBMIT':
                    if (!isNullOrUndefined(this.sectionDataSummary) && this.sectionDataSummary.length > 0 && !isNullOrUndefined(this.sectionDataSummary[0].NonGreenComment) && this.sectionDataSummary[0].NonGreenComment !== '') {

                        this.NonGreenData.filter(x => x.PendingWith === RoleCodes.NonGreen.toString()).forEach(item => {
                            item.NGStatusCode = this.sectionDataSummary[0].NGStatusCode;
                            item.NonGreenComment = this.sectionDataSummary[0].NonGreenComment;
                            item.IsModified = 1;
                        });

                        this.showNotificationMessage('Are you sure you want to send the case for termination?', 1, data.Name, this.NonGreenData.filter(x => x.PendingWith === RoleCodes.NonGreen.toString()), 'Submit now', 'Cancel');
                    } else {
                        this.showNotificationMessage('Please provide comments.', 2, '', null, '', '');
                    }
                    break;
                case 'TERMINATIONCANCEL':
                    this.closeAdditionalSection();
                    break;
                case 'BGVHEADSUBMIT':
                    if (this.ValidateFormData()) {
                       // this.SubmitNonGreenForm(this.NonGreenFormData,'Case submitted to BGV Head'); 
                        // this.viewEmailTemp = true;
                        // this.ACTION = this.actionCodes.SendToBGVHead;
                        // this.openModal('EmailPopup')
                        // this.GetNGMailDetails();
                       this.showNotificationMessage('Are you sure you want to submit case to BGV Head for approval?', 1, data.Name,sectionitems, 'Submit now', 'Cancel');
                       
                    }    
                        
                    break;
                    case 'BGVHEADSAVE':
                        if (this.ValidateFormData()) {
                           
                           this.showNotificationMessage('Are you sure you want to Save the changes?', 1, data.Name,sectionitems, 'Save', 'Cancel');
                           
                        }    
                            
                        break;
                    case 'CENTRALHRSAVE':
                        if (this.ValidateFormData()) {
                            
                            this.showNotificationMessage('Are you sure you want to Save the changes?', 1, data.Name,sectionitems, 'Save', 'Cancel');
                            
                        }    
                            
                        break;
                        

                case 'BGVHEADCANCEL':
                        this.closeAdditionalSection();
                    break;
                case 'APPROVALFORTERMINATION':
                        //this.sectionData = [];
                        this.showaddverifiedcomp=true;
                        this.sectionData = this.NonGreenData.filter(x => x.IsModified === 1 && x.NGStatusCode === ActionCode.QC_Accept && x.ColorCode !== StatusColorCode.Green
                            && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Rejected_1
                            && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Accepted_1
                            && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Rejected_2
                            && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Rejected_3
                            && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Accepted_2
                            && x.UploadStatus !== BGVStatus.Non_Green__Exception_Approval_Accepted_3
                            //&& x.UploadStatus !== BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_1
                            //&& x.UploadStatus !== BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_2
                            && x.UploadStatus !== BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_3);
                        if (!isNullOrUndefined(this.sectionData) && this.sectionData.length > 0) {
                            if (this.validateMandatoryFields(this.sectionData)) {
                             this.DisplayadditionalGrid=false;
                             console.log('action',this.actionCode)
                            
                            this.openAdditionalSection(data);
                            }
                        } else {
                            // this.showNotificationMessage('No data available', false);
                            this.showNotificationMessage('No data available', 2, '', null, '', '');
                        }
                        break;
                case 'TERMINATIONAPPROVALSUBMIT':
                    if (this.ValidateFormData()) {
                       // this.SubmitNonGreenForm(this.NonGreenFormData,'Case submitted to BGV Head'); 
                        // this.viewEmailTemp = true;
                        // this.ACTION = this.actionCodes.SendToBGVHead;
                        // this.openModal('EmailPopup')
                        // this.GetNGMailDetails();
                       this.showNotificationMessage('Are you sure you want to submit case to BGV Head for termination approval?', 1, data.Name,sectionitems, 'Submit now', 'Cancel');
                       
                    }    
                        
                    break;

                case 'TERMINATIONAPPROVALSAVE':
                    if (this.ValidateFormData()) {
                        
                        this.showNotificationMessage('Are you sure you want to Save the changes?', 1, data.Name,sectionitems, 'Save', 'Cancel');
                        
                    }    
                        
                    break;

                case 'TERMINATIONAPPROVALCANCEL':
                    this.closeAdditionalSection();
                    break;

                case 'REINITIATECASE':
                    this.showReinitiateCaseConfirmation();
                    break;

            }
        }

    }
    openModal(id: string) {
        this.modalService.open(id);
        // if (id == 'VendorSLA-report') {
        //   this.showRequestReport = true;
        //   this.getRequestReport(this.RequestId);
        // }
    
        document.getElementById('txtvalue')?.focus();
    
      }

      closeModal(id: string) {
        this.modalService.close(id);
      }
    
    getCheckDocumentDetailsList() {
        // this.IsLoading = true;

        let odata: object = { 'requestId': this.RequestId, 'checkId': 0 };

        let subscribe$: Subscription = this._requestService.getCheckDocumentDetailsList(odata).subscribe(result => {
            if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
                this.NonGreenData = result.Data;

                // this.NonGreenData.forEach(item => {
                //     item.Action = '0'
                //     // this.disableRows(item);
                // });

                this.setActionButtonPermissions();
                this.enableDisableButtons();
                this.getNonGreenadditionalSectionList();
               
            }
            // this.IsLoading = false;
        },
            err => {
                console.log('Error on getting getCheckDocumentDetailsList.');
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }

    getNonGreenadditionalSectionList() {
        // this.IsLoading = true;

        let odata: object = { 'requestId': this.RequestId, 'checkId': null };

        let subscribe$: Subscription = this._requestService.getNonGreenadditionalSectionList(odata).subscribe(result => {
            if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
                this.NonGreenAdditionalSectionData = result.Data;
            }
            this.IsLoading = false;
        },
            err => {
                console.log('Error on getting getNonGreenadditionalSectionList.');
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }
    GetNGMailDetails() {
         this.IsLoading = true;
        //this.ACTION = this.actionCodes.RaiseL3;
        let odata: object = { 'requestId': this.RequestId,'Action':this.ACTION};

        let subscribe$: Subscription = this._requestService.GetNGMailDetails(odata).subscribe(result => {
            if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
                this.selectedInfoData=result.Data;
                this.selectedEmailActionData=result.Data;
                this.NonGreenAdditionalSectionData = result.Data;
                this.IsLoading = false;
            }
            this.IsLoading = false;
        },
            err => {
                console.log('Error on getting GetNGMailDetails.');
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }
    setActionButtonPermissions(): void {
        if (!isNullOrUndefined(this.NonGreenProperties.ActionButtons) && this.NonGreenProperties.ActionButtons.length > 0) {

            this.NonGreenProperties.ActionButtons.forEach((item: any) => {

                if (!isNullOrUndefined(item.Name) && item.Name !== '') {
                    item.HasPermission = false;
                    item.IsApplicable = false;
                    let peritem = this.NonGreenPermission.indexOf(item.Name);
                    if (peritem >= 0) {
                        item.HasPermission = true;
                    }
                    switch (item.Name.toUpperCase()) {
                        case 'CLOSECASE':
                            // item.IsApplicable = this.enableCloseCase();
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                            break;
                        case 'RAISEL3INSUFFICIENCY':
                            //   item.IsApplicable = this.enableSubmitCase();
                            this.viewEmailTemp = false;
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                            break;
                        
                        case 'RESPONSETOEMPLOYEE':
                            this.viewEmailTemp = false;
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                            break;
                        case 'SUBMITTOVENDOR':
                            //   item.IsApplicable = this.enableSubmitCase();
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                            break;
                        case 'FORTERMINATION':
                            // item.IsApplicable = this.enableSubmitCase();
                            //item.IsApplicable = true;
                            item.HasPermission=this.RequestData.OfferStatus === 'Joined' ? true : false;
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                            break;
                        case 'SUBMITFORCENTRALHR':
                            //   item.IsApplicable = this.enableSubmitCase();
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                            //item.HasPermission=this.RequestData.OfferStatus === 'Joined' ? true : false;
                            //item.IsApplicable = this.RequestData.OfferStatus === 'Joined' ? true : false;
                            break;
                         case 'SUBMITFORBGVHEAD':
                                //   item.IsApplicable = this.enableSubmitCase();
                                item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                                break;
                         case 'FOLLOWUPMAIL':
                                    //   item.IsApplicable = this.enableSubmitCase();
                                    item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                                    break;
                         case 'SHOWCAUSENOTICE':
                                    //   item.IsApplicable = this.enableSubmitCase();
                                    item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                                    break;
                        case 'VENDORSUBMIT':
                            //   item.IsApplicable = this.enableSubmitCase();
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                            break;
                        case 'VENDORCANCEL':
                            //   item.IsApplicable = this.enableSubmitCase();
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                            break;
                        case 'CENTRALHRSUBMIT':
                            //   item.IsApplicable = this.enableSubmitCase();
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                            //item.HasPermission=this.RequestData.OfferStatus === 'Joined' ? true : false;
                            //item.IsApplicable = this.RequestData.OfferStatus === 'Joined' ? true : false;
                            break;
                        case 'CENTRALHRCANCEL':
                            //   item.IsApplicable = this.enableSubmitCase();
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                            break;
                        case 'L3INSUFFSUBMIT':
                            //   item.IsApplicable = this.enableSubmitCase();
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                            break;     
                        case 'L3INSUFFCANCEL':
                            //   item.IsApplicable = this.enableSubmitCase();
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                            break;
                        case 'RESPONSETOEMPLOYEESUBMIT':
                            //   item.IsApplicable = this.enableSubmitCase();
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                            break;
                        case 'RESPONSETOEMPLOYEECANCEL':
                            //   item.IsApplicable = this.enableSubmitCase();
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                            break;
                        case 'STOPCASE':
                        case 'STOPCASESUBMIT':
                        case 'STOPCASECANCEL':
                            // item.IsApplicable = this.enableStopCase();
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                            break;
                        case 'TERMINATIONSUBMIT':
                            //   item.IsApplicable = this.enableSubmitCase();
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                            // item.HasPermission=this.RequestData.OfferStatus === 'Joined' ? true : false;
                            // item.IsApplicable = this.RequestData.OfferStatus === 'Joined' ? true : false;
                            break;
                        case 'TERMINATIONCANCEL':
                            //   item.IsApplicable = this.enableSubmitCase();
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                            // item.HasPermission=this.RequestData.OfferStatus === 'Joined' ? true : false;
                            // item.IsApplicable = this.RequestData.OfferStatus === 'Joined' ? true : false;
                            break;
                        case 'BGVHEADSUBMIT':
                            //   item.IsApplicable = this.enableSubmitCase();
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                            break;
                        case 'BGVHEADCANCEL':
                            //   item.IsApplicable = this.enableSubmitCase();
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                            break;
                        case 'BGVHEADSAVE':                   
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                        break;
                        case 'CENTRALHRSAVE':                   
                        item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                        break;
                        case 'APPROVALFORTERMINATION':
                            //   item.IsApplicable = this.enableSubmitCase();
                            // item.IsApplicable = true;
                            // item.HasPermission=true;
                            item.HasPermission=this.RequestData.OfferStatus === 'Joined' ? true : false;
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false ? true : false;
                            break;
                        case 'TERMINATIONAPPROVALSUBMIT':
                            //   item.IsApplicable = this.enableSubmitCase();
                            item.HasPermission=this.RequestData.OfferStatus === 'Joined' ? true : false;
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false && this.RequestData.OfferStatus === 'Joined' ? true : false;

                            break;
                        case 'TERMINATIONAPPROVALSAVE':
                            //   item.IsApplicable = this.enableSubmitCase();
                            item.HasPermission=this.RequestData.OfferStatus === 'Joined' ? true : false;
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false && this.RequestData.OfferStatus === 'Joined' ? true : false;
                            break;
                        case 'TERMINATIONAPPROVALCANCEL':
                            //   item.IsApplicable = this.enableSubmitCase();
                            item.HasPermission=this.RequestData.OfferStatus === 'Joined' ? true : false;
                            item.IsApplicable = this.RequestData.IsClosed === false && this.RequestData.IsStopCaseSubmitted===false && this.RequestData.OfferStatus === 'Joined' ? true : false;
                            break;

                        case 'REINITIATECASE':
                            item.IsApplicable = this.RequestData.IsStopCaseSubmitted===true  && this.RequestData.IsStopCase===true ? true : false;
                            break;
                            
                    }
                }
            });
        }
    }



    enableCloseCase(): boolean {
        let flag: boolean = false;
        if (!isNullOrUndefined(this.NonGreenData) && this.NonGreenData.length > 0
            && this.NonGreenData.filter(x => x.IsEditable === 1).length > 0
            && this.NonGreenData.filter(x => x.ColorCode === StatusColorCode.Green).length === this.NonGreenData.length
            && this.NonGreenData.filter(x => x.QCStatusCode === ActionCode.QC_Accept).length === this.NonGreenData.length
            && this.NonGreenData.filter(x => (x.UploadStatus === BGVStatus.Non_Green_Terminated
                || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_1
                || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_1
                || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_1)).length === 0
            && this.NonGreenData.filter(x => x.NGStatusCode === ActionCode.QC_Accept).length === this.NonGreenData.filter(x => x.IsEditable === 1).length

            // && this.NonGreenData.filter(x => x.NGStatusCode === 'AC005').length === 0
            // && this.NonGreenData.filter(x => x.IsEditable === 1
            //     && (x.NGStatusCode === '' || isNullOrUndefined(x.NGStatusCode))).length === 0

            // && this.NonGreenData.filter(x => x.IsEditable === 1
            //     && x.NGStatusCode === 'AC004' && x.UploadStatus !== BGVStatus.Green_SR_Submitted).length === 0

            // && this.NonGreenData.filter(x => x.IsEditable === 0
            //     && x.UploadStatus !== BGVStatus.Closed__Green).length === 0
        ) {
            flag = true;

        }
        return flag;
    }


    showReinitiateCaseConfirmation() {

        this.notify = {
          info: {
            header: 'Confirmation Alert',
            message: 'Are you sure you want to Reinitiate the case?',
            yes: 'Yes',
            no: 'No',
    
            IsHeaderHidden: true,
            HeaderIcon: 'fa fa-check-circle text-success',
            IsCrossEnable: true,
            SizeClass: 'modal-sm'
          },
    
          action: 'REINITIATECASE',
          item: null
        };
    
        this.displayNotifybox = true;
      }
    
      reinitiateCase() {
        this.IsLoading = true;
        this.RequestData.ReasonForReinitiateCase=this.ReasonForReinitiateCase;
        let odata=this.RequestData;
    
            let subscribe$: Subscription = this._requestService.ReinitiateCase(odata).subscribe(result => {
              if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
    
                  this.notify = {
                    info: {
                      header: 'Confirmation Alert',
                      message: 'Case Reinitiated successfully',
                      yes: '',
                      no: '',
    
                      IsHeaderHidden: true,
                      HeaderIcon: 'fa fa-check-circle text-success',
                      IsCrossEnable: true,
                      SizeClass: 'modal-sm'
                    },
    
                    action: '',
                    item: null
                  };
    
                  this.displayNotifybox = true;
                  this.closeModal('ReinitiateCaseNG')

                
              }
    
              this.IsLoading=false;
              this.refreshRequest();
    
            },
    
              err => {
                this.IsLoading = false;
              });
            this.subscriptionList$.push(subscribe$);  
      }

    SubmitNonGreen(modifieditems: any, msg: string) {
        // if (this.validateMandatoryFields(modifieditems)) {
        modifieditems.forEach((item: any) => {
            item.ActionCode = this.actionCode;
            item.ToRoleId=this.Tomail;
            item.CCRoleId=this.Ccmail;

        });
        this.IsLoading = true;

        let subscribe$: Subscription = this._requestService.SubmitQC(modifieditems).subscribe(result => {
            if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
             if(this.ACTION == this.actionCodes.SendToBUHR) 
            {
                this.ACTION == this.actionCodes.SendToBUHR
                this.viewEmailTemp = true;
                this.openModal('EmailPopup')
                this.GetNGMailDetails();

            } 
           

            }
            // this.IsLoading = false;
        },

            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
        // }
    }

    showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string) {
        let headerIcon: string = '';

        if (successFlag === 1) {
            headerIcon = 'fa fa-check-circle text-success';
        } else if (successFlag === 2) {
            headerIcon = 'fa fa-exclamation-circle text-warning';
        } else if (successFlag === 3) {
            headerIcon = 'fa fa-exclamation-triangle text-danger';
        } else {
            headerIcon = 'fa fa-info-circle text-info';
        }

        this.notify = {
            info: {
                header: 'Confirmation Alert',
                message: msg,
                yes: yes,
                no: no,

                IsHeaderHidden: true,
                HeaderIcon: headerIcon,
                IsCrossEnable: true,
                SizeClass: 'modal-sm'
            },

            action: action,
            item: item
        };

        this.displayNotifybox = true;
    }

    refreshRequest(): void {
        this.RefreshRequest.emit({ 'requestId': this.RequestId });
    }

    validateMandatoryFields(modifieditems: any[]): boolean {
        let valid: boolean = true;


        if (isNullOrUndefined(modifieditems) || modifieditems.length === 0) {
            // this.showNotificationMessage('No rows are modified.', false);
            this.showNotificationMessage('No rows are modified.', 2, '', null, '', '');

            valid = false;
        }

        if (!isNullOrUndefined(modifieditems) && modifieditems.length > 0) {



            //Non Green Status Check
            if (this.actionCode !== ActionCode.NG_Raise_L3_Insufficiency) {
                let statusList = modifieditems.filter(x => ((isNullOrUndefined(x.NGStatusCode)) || x.NGStatusCode === '') && (!isNullOrUndefined(x.NonGreenComment) && x.NonGreenComment !== ''));

                if (!isNullOrUndefined(statusList) && statusList.length > 0) {
                    // this.showNotificationMessage('Please select non green status for modified rows.', false);
                    this.showNotificationMessage('Please select non green status for modified rows.', 2, '', null, '', '');
                    valid = false;
                }

                // Optional Field while doing termination
                if (this.actionCode !== ActionCode.NG_Submit_for_termination) {
                    //Comments Check
                    let emptyComments = modifieditems.filter(x => ((isNullOrUndefined(x.NonGreenComment)) || x.NonGreenComment === ''));

                    if (!isNullOrUndefined(emptyComments) && emptyComments.length > 0 && valid) {
                        // this.showNotificationMessage('Please provide comments for modified rows.', false);
                        this.showNotificationMessage('Please provide comments for modified rows.', 2, '', null, '', '');
                        valid = false;
                    }
                }
                


            }

            //Raise L3 Insufficiency validat
            if (this.actionCode === ActionCode.NG_Raise_L3_Insufficiency) {

                //Required Document and comment Check 
                let edtableCount = this.NonGreenData.filter(x => x.IsEditable === 1 && x.UploadStatus !== BGVStatus.Green_SR_Submitted);
                let L3docAndCommentsList = this.sectionData.filter(x => ((isNullOrUndefined(x.DocumentList)) || x.DocumentList === '') && ((isNullOrUndefined(x.NonGreenComment)) || x.NonGreenComment === ''));

                if (!isNullOrUndefined(L3docAndCommentsList) && L3docAndCommentsList.length > 0 && (edtableCount.length === L3docAndCommentsList.length)) {
                    this.showNotificationMessage('Please provide required documents and comments for atleast one row.', 2, '', null, '', '');
                    valid = false;
                }

                //Required Document Check 
                let L3docList = modifieditems.filter(x => ((isNullOrUndefined(x.DocumentList)) || x.DocumentList === '') && ((!isNullOrUndefined(x.NonGreenComment)) && x.NonGreenComment !== ''));

                if (!isNullOrUndefined(L3docList) && L3docList.length > 0 && valid) {
                    // this.showNotificationMessage('Please provide required documents for modified rows.', false);
                    this.showNotificationMessage('Please provide required documents for modified rows.', 2, '', null, '', '');
                    valid = false;
                }

                //Comments Check
                let L3CommentsCheck = modifieditems.filter(x => ((isNullOrUndefined(x.NonGreenComment)) || x.NonGreenComment === '') && ((!isNullOrUndefined(x.DocumentList)) && x.DocumentList !== ''));

                if (!isNullOrUndefined(L3CommentsCheck) && L3CommentsCheck.length > 0 && valid) {
                    // this.showNotificationMessage('Please provide comments for modified rows.', false);
                    this.showNotificationMessage('Please provide comments for modified rows.', 2, '', null, '', '');
                    valid = false;
                }
                // let toroleemail = modifieditems.filter(x => ((isNullOrUndefined(x.ToRoleId)) || x.ToRoleId === ''));
                // if (!isNullOrUndefined(toroleemail) && toroleemail.length > 0 && valid) {
                //     // this.showNotificationMessage('Please provide comments for modified rows.', false);
                //     this.showNotificationMessage('Please provide ToRoleName for modified rows.', 2, '', null, '', '');
                //     valid = false;
                // }
            }
         



        }

        return valid;
    }


    pageItemsChange() {

        if (this.curItemsPerPage > 0) {
            this.GridProp.PaginationConfig.ItemsPerPage = this.curItemsPerPage;
        }
        else {
            this.GridProp.PaginationConfig.ItemsPerPage = 100;
        }


    }

    openAdditionalSection(data: any): void {

        if (!isNullOrUndefined(data.Name) && data.Name !== '' && data.Name.toUpperCase() === 'STOPCASE') {
            this.sectionData = [];

            let secData: any = {
                RequestId: this.RequestId,
                RequestStatus: this.RequestData.RequestStatus,
                ActionCode: data.ActionCode,
                FileReferenceId: 0,
                StopCaseComments: '',
                Report: data.DisplayName,
                Action: '0',
                FileName: '',
                IsEditable: 1,
                IsModified: 0,
                MultiFileReferenceId: null,
                MultiFileName: null
            };

            this.sectionData.push(secData);


        } 
       if(!isNullOrUndefined(data.Name) && data.Name !== '' && (data.Name.toUpperCase() === 'SUBMITFORBGVHEAD'||data.Name.toUpperCase() === 'SUBMITFORCENTRALHR'||data.Name.toUpperCase() === 'APPROVALFORTERMINATION')){
        
        this.getBasicDetails();
        this.getSubmittedDetails();
        this.NonGreenFormData.IsEditable=1
         //this.SubmittedDetailsList=this.SubmittedDetailsList[0]; 
            
         this.SubmittedDetailsList.forEach((item: any) => {
            item.IsEditable =  1 ;
            item.IsModified = 0;
            
          });

          if(data.Name.toUpperCase() === 'APPROVALFORTERMINATION')
          {
            this.i=0;
            while(this.count<this.NonGreenData.filter(x=>x.IsEditable==1).length)
            {
                this.AddMoreDetails();
                this.count++;
            }
          }
     
        
         
          
       
       }
        else {
            this.sectionData.forEach(item => {
                item.DocumentList = null;
                item.DocumentListDesc = null;
                item.FileReferenceId = 0;
                item.FileName = '';
                item.IsModified = ((item.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Accepted_1
                    || item.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Accepted_2
                    || item.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Accepted_3
                    //|| item.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_1
                    //|| item.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_2
                    || item.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_3
                ) && item.PendingWith) === RoleCodes.NonGreen.toString() ? 1 : item.IsModified;
                item.IsRowHighlight = item.IsModified;
                item.NonGreenComment = isNullOrUndefined(item.NonGreenComment) ? '' : item.NonGreenComment;
                item.MultiFileReferenceId= null;
                item.MultiFileName= null;
                item.ToRoleId=isNullOrUndefined(item.ToRoleId) ? '' :item.ToRoleId;
                item.CCRoleId=isNullOrUndefined(item.CCRoleId) ? '' :item.CCRoleId;
                item.Subject=isNullOrUndefined(item.Subject) ? '' :item.Subject;
                item.Message=isNullOrUndefined(item.Message) ? '' :item.Message;
            });
          
            this.sectionDataSummary = [];

            if (this.sectionData.length > 0) {

                let Data = JSON.stringify(this.sectionData[0]);
                this.sectionDataSummary.push(JSON.parse(Data.toString()));

                let colorIsRed = this.sectionData.filter(f => f.ColorCode == "SC002");

                let NonGreenComment = "";

                this.sectionData.forEach((item: any) => {
                    NonGreenComment += NonGreenComment == "" ? item.NonGreenComment : "," + item.NonGreenComment;
                });

                this.sectionDataSummary[0].NonGreenComment = NonGreenComment;
                this.sectionDataSummary[0].IsEditable = data.ActionCode === ActionCode.NG_Submit_for_termination ? 1 : 0;
               
                if (colorIsRed.length > 0) {
                    this.sectionDataSummary[0].ColorCode = colorIsRed[0].ColorCode;
                    this.sectionDataSummary[0].ColorCodeDesc = colorIsRed[0].ColorCodeDesc;
                }

            }

            
        }
        

        // Display Name and Filter name for buttons and bread scrum name display
        this.sectionName = data.Name;
        this.sectionDisplayName = data.DisplayName;
        this.submitSecionName ='Submit_Template';
        this.verifiedSecionName ='VerifiedComponents';


        this.openNonGreenSection = true;
       
    }

    deleteAttachment(FileData: any) {

        this.IsLoading = true;
        let deleteData: any[] = [];

        FileData.forEach((item: any) => {
            let odata: object = {
                'Module': 'SubCheck',
                'Reference1': Number(this.RequestId),
                'Reference2': 0,
                'Reference3': 0,
                'Reference4': 0,
                'TransactionId': item.FileReferenceId,
                'FilePath': '',
                'FileName': item.FileName,
                'UserId': this._appSharedService.UserInfo.actinguserid
            };
            deleteData.push(odata);
        });
        let subscribe$: Subscription = this._requestService.deleteAttachment(deleteData).subscribe(result => {
            if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

                this.sectionData.forEach(item => {
                    item.DocumentList = null;
                    item.DocumentListDesc = null;
                    item.FileReferenceId = 0;
                    item.FileName = '';
                });
                this.sectionData = [];
            }



            this.IsLoading = false;
            this.openNonGreenSection = false;
            // this.refreshRequest();

        },

            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }

    closeAttachment(event: any): void {
        console.log(event.data);

        let selectedData = this.sectionData.filter(x => x.TransactionId === this.transId)[0];

        if (!isNullOrUndefined(event) && event.Reload === true) {
            // selectedData.FilePath = event.data[0].FilePath;
            this.fileRefId = [];
            this.fileNames = [];
            selectedData.FileReferenceId = 0;
            selectedData.FileName = null;
            event.data.forEach((x: any) => this.fileRefId.push(x.TransactionId));
            event.data.forEach((x: any) => this.fileNames.push(x.FileName));
            selectedData.MultiFileReferenceId = this.fileRefId.toString();
            selectedData.MultiFileName = this.fileNames.toString();
            selectedData.FileReferenceId = event.data[0].TransactionId;
            selectedData.FileName = !isNullOrUndefined(this.fileNames) && this.fileNames.length > 0 ? this.fileNames.toString() : event.data[0].FileName;
        }
        this.uploadModule = 'SubCheck';
        this.DisplayAttachment = false;
    }

    closeAdditionalSection(): void {

        if (!isNullOrUndefined(this.sectionData) && this.sectionData.length > 0 && (this.sectionData.filter(x => x.IsModified === 1)).length > 0) {

            // let requiredDocumentRows = (this.sectionData.filter(x => (!isNullOrUndefined(x.DocumentList) && x.DocumentList !== ''))).length
            // let FileNameRows = (this.sectionData.filter(x => (!isNullOrUndefined(x.FileName) && x.FileName !== ''))).length

            // this.showCancelCaseConfirmation();
            this.showNotificationMessage('Are you sure you want to cancel the submission?', 2, 'CANCELSUBMISSION', null, 'Yes', 'No');

        } else {
            this.openNonGreenSection = false;
        }

       

    }

    enableDisableButtons(): void {
        if (!isNullOrUndefined(this.NonGreenProperties.ActionButtons)
            && this.NonGreenProperties.ActionButtons.length > 0
            && (this.NonGreenProperties.ActionButtons.filter((x: any) => x.Group === 'NonGreen')).length > 0) {

            let modifieditems = this.NonGreenData.filter(x => x.IsModified === 1);

            let primaryPageButt = this.NonGreenProperties.ActionButtons.filter((x: any) =>
                x.Group === 'NonGreen');

            if (!isNullOrUndefined(this.NonGreenData) && this.NonGreenData.length > 0) {
                primaryPageButt.forEach((item: any) => {

                    if (!isNullOrUndefined(item.Name) && item.Name !== '') {
                        item.IsEnabled = false;
                        switch (item.Name.toUpperCase()) {
                            case 'CLOSECASE':
                                item.IsEnabled = this.enableCloseCase();
                               
                                break;
                            case 'RAISEL3INSUFFICIENCY':
                                if (!isNullOrUndefined(this.NonGreenData)
                                    && this.NonGreenData.length > 0
                                    && this.NonGreenData.filter(x => x.IsEditable === 1).length > 0
                                    && (this.NonGreenData.filter(x => ((
                                     //    x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_1 
                                     //   || x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_2 
                                         x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_3) && x.PendingWith === RoleCodes.NonGreen.toString())).length === 0)
                                    && this.NonGreenData.filter(x => (x.ColorCode === StatusColorCode.Red || x.ColorCode === StatusColorCode.Amber)).length !== this.NonGreenData.filter(x => (x.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Accepted_1 || x.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Accepted_2 || x.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Accepted_3)).length
                                    && this.NonGreenData.filter(x => (x.UploadStatus === BGVStatus.Non_Green_Terminated
                                        || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_1
                                        // || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_2
                                        // || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_3
                                        )).length === 0
                                    // && this.NonGreenData.filter(x => x.IsEditable === 1
                                    //     && x.NGStatusCode === 'AC005').length > 0
                                    && this.NonGreenData.filter(x => x.IsEditable === 1 && x.UploadStatus !== BGVStatus.Green_SR_Submitted).length > 0
                                    && !this.enableCloseCase()
                                ) {
                                    item.IsEnabled = true;

                                }
                                break;

                            case 'RESPONSETOEMPLOYEE':
                                if (!isNullOrUndefined(this.NonGreenData)
                                    && this.NonGreenData.length > 0
                                    && this.NonGreenData.filter(x => x.IsEditable === 1).length > 0
                                    && (this.NonGreenData.filter(x => ((
                                        //x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_1 
                                        //|| x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_2 
                                         x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_3) && x.PendingWith === RoleCodes.NonGreen.toString())).length === 0)
                                    && this.NonGreenData.filter(x => (x.ColorCode === StatusColorCode.Red || x.ColorCode === StatusColorCode.Amber)).length !== this.NonGreenData.filter(x => (x.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Accepted_1 || x.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Accepted_2 || x.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Accepted_3)).length
                                    && this.NonGreenData.filter(x => (x.UploadStatus === BGVStatus.Non_Green_Terminated
                                        || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_1                                       
                                        // || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_2
                                        // || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_3
                                        )).length === 0
                                  
                                    // && this.NonGreenData.filter(x => x.IsEditable === 1
                                    //     && x.NGStatusCode === 'AC005').length > 0
                                    && this.NonGreenData.filter(x => x.IsEditable === 1 && x.UploadStatus !== BGVStatus.Green_SR_Submitted).length > 0
                                    && !this.enableCloseCase()
                                ) {
                                    item.IsEnabled = true;

                                }
                                break;

                                
                            case 'SUBMITTOVENDOR':
                                if ((!isNullOrUndefined(modifieditems)
                                    && modifieditems.length > 0
                                    && modifieditems.filter(x => x.IsEditable === 1).length > 0
                                    && this.NonGreenData.filter(x => (x.UploadStatus === BGVStatus.Non_Green_Terminated
                                        || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_1
                                        // || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_2
                                        // || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_3
                                        )).length === 0
                                    && modifieditems.filter(x => x.IsEditable === 1).length > 0)
                                    //&& x.NGStatusCode === ActionCode.QC_Accept --as per bussiness  "Send to vendor" to should enabled at both scenario on accepted and rejected
                                    || (this.NonGreenData.filter(x => ((x.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Accepted_1 || x.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Accepted_2 || x.UploadStatus === BGVStatus.Non_Green__Exception_Approval_Accepted_3) && x.PendingWith === RoleCodes.NonGreen.toString())).length > 0)
                                    || (this.NonGreenData.filter(x => ((
                                        // x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_1
                                          //|| x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_2 ||
                                          x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_3) && x.PendingWith === RoleCodes.NonGreen.toString())).length > 0)
                                    && !this.enableCloseCase()) {
                                    item.IsEnabled = true;
                                }
                                break;
                            case 'FORTERMINATION':
                                if (!isNullOrUndefined(this.NonGreenData) && this.NonGreenData.length > 0
                                    && !this.enableCloseCase()
                                    && this.NonGreenData.filter(x => x.PendingWith === RoleCodes.NonGreen.toString()).length > 0
                                    && (this.NonGreenData.filter(x => ((
                                        x.UploadStatus === BGVStatus.Non_Green__termination_Approval_Accepted_By_BGV_Head) 
                                        && x.PendingWith === RoleCodes.NonGreen.toString())).length > 0)
                                ) {
                                    item.IsEnabled = true;

                                }
                                break;
                            case 'SUBMITFORCENTRALHR':
                                if (!isNullOrUndefined(modifieditems) && modifieditems.length > 0
                                    && modifieditems.filter(x => x.IsEditable === 1).length > 0
                                    && (this.NonGreenData.filter(x => ((
                                       //  x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_1 
                                       //  || x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_2 ||
                                         x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_3) && x.PendingWith === RoleCodes.NonGreen.toString())).length === 0)
                                    && this.NonGreenData.filter(x => (x.UploadStatus === BGVStatus.Non_Green_Terminated
                                        || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_1
                                        // || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_2
                                        // || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_3
                                        )).length === 0
                                    && modifieditems.filter(x => x.IsEditable === 1
                                        && x.NGStatusCode === ActionCode.QC_Accept && x.ColorCode !== StatusColorCode.Green).length > 0
                                    && !this.enableCloseCase()) {
                                    item.IsEnabled = true;

                                }
                                break;
                                case 'SUBMITFORBGVHEAD':
                                if (!isNullOrUndefined(modifieditems) && modifieditems.length > 0
                                    && modifieditems.filter(x => x.IsEditable === 1).length > 0
                                    && (this.NonGreenData.filter(x => ((
                                        // x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_1 
                                        // || x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_2 || 
                                        x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_3) && x.PendingWith === RoleCodes.NonGreen.toString())).length === 0)
                                    && this.NonGreenData.filter(x => (x.UploadStatus === BGVStatus.Non_Green_Terminated
                                        || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_1
                                        // || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_2
                                        // || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_3
                                        )).length === 0
                                    && modifieditems.filter(x => x.IsEditable === 1
                                        && x.NGStatusCode === ActionCode.QC_Accept && x.ColorCode !== StatusColorCode.Green).length > 0
                                    && !this.enableCloseCase()) {
                                    item.IsEnabled = true;

                                }
                                break;
                            case 'FOLLOWUPMAIL':
                                
                                if ((this.NonGreenData.filter(x => ((x.UploadStatus === BGVStatus.L3_Insufficiency_Additional_details_or_documents
                                    || x.UploadStatus === BGVStatus.L3_Insufficiency_Additional_details_or_documents_1
                                    || x.UploadStatus === BGVStatus.L3_Insufficiency_Additional_details_or_documents_2)
                                    &&x.DaysBackCreated>=3 ))).length > 0
                                && !this.enableCloseCase()) 
                                    {
                                        item.IsEnabled= true;
                                    }
                                    else
                                    {
                                        item.IsApplicable = false;
                                    }
                                    break;
                            case 'SHOWCAUSENOTICE':
                                
                                    if ((this.NonGreenData.filter(x => ((x.UploadStatus === BGVStatus.L3_Insufficiency_Additional_details_or_documents
                                        || x.UploadStatus === BGVStatus.L3_Insufficiency_Additional_details_or_documents_1
                                        || x.UploadStatus === BGVStatus.L3_Insufficiency_Additional_details_or_documents_2)
                                        &&x.DaysBackCreated>=5 &&this.RequestData.OfferStatus=='Joined'))).length > 0
                                    && !this.enableCloseCase()) {
                                    item.IsEnabled = true;
                                    }
                                    else
                                    {
                                        item.IsApplicable = false;
                                    }
                                    break;        
                            case 'STOPCASE':
                                if (!isNullOrUndefined(this.NonGreenData) && this.NonGreenData.length > 0 && this.RequestData.IsStopCaseSubmitted === false
                                    && this.NonGreenData.filter(x => (x.UploadStatus === BGVStatus.Non_Green_Terminated
                                        || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_1
                                        // || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_2
                                        // || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_3
                                        )).length === 0
                                    && this.RequestData.EnableClosure === false
                                    && this.RequestData.IsClosed === false) {
                                    item.IsEnabled = true;
                                }
                                break;

                            case 'REINITIATECASE':
                                item.IsEnabled=true;
                                break;

                            case 'APPROVALFORTERMINATION':
                                if (!isNullOrUndefined(modifieditems) && modifieditems.length > 0
                                && modifieditems.filter(x => x.IsEditable === 1).length > 0
                                && (this.NonGreenData.filter(x => ((
                                    // x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_1 ||
                                    //  x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_2 ||
                                      x.UploadStatus === BGVStatus.Non_Green_Insufficiency_cleared_by_BUHR_3) && x.PendingWith === RoleCodes.NonGreen.toString())).length === 0)
                                && this.NonGreenData.filter(x => (x.UploadStatus === BGVStatus.Non_Green_Terminated
                                    || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_1
                                    // || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_2
                                    // || x.UploadStatus === BGVStatus.NonGreen_Process_initiated_for_termination_3
                                    )).length === 0
                                && modifieditems.filter(x => x.IsEditable === 1
                                    && x.NGStatusCode === ActionCode.QC_Accept && x.ColorCode !== StatusColorCode.Green).length > 0
                                && !this.enableCloseCase()) {
                                item.IsEnabled = true;

                            }
                        }
                    }
                });
            }
        }
    }

    RaiseL3Insufficiency(modifieditems: any, msg: string) {
        // if (this.validateMandatoryFields(modifieditems)) {
            this.isResponsetoEmployee=false;
        modifieditems.forEach((item: any) => {
            item.ActionCode = this.actionCode;
            item.InsufficiencyComment = item.NonGreenComment;
            item.InsufficiencyType = "L3";
            item.ToRoleId=item.Tomail;
            item.CCRoleId=this.Ccmail;
            item.Subject=this.Mailsubject;
            item.Message=this.MailMessage;
            // item.DocumentStatus = false;
            // item.InsufficiencyStatus = item.InsufficiencyType;
        });
        this.IsLoading = true;

        let subscribe$: Subscription = this._requestService.RaiseL3Insufficiency(modifieditems).subscribe(result => {
            if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
                this.viewEmailTemp = true;
                this.ACTION = this.actionCodes.RaiseL3;
                this.openModal('EmailPopup')
                this.GetNGMailDetails();
                // this.showNotificationMessage(msg, true);
               // this.showNotificationMessage(msg, 1, '', null, '', '');
                // this.getCheckDocumentDetailsList();

                //this.refreshRequest();

            }
            this.IsLoading = false;
        },

            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
        // }
    }

    ResponseToEmployee(modifieditems: any, msg: string) {
        // if (this.validateMandatoryFields(modifieditems)) {
            this.isResponsetoEmployee=true;
        modifieditems.forEach((item: any) => {
            item.ActionCode = this.actionCode;
            item.InsufficiencyComment = item.NonGreenComment;
            item.InsufficiencyType = "L3";
            item.ToRoleId=item.Tomail;
            item.CCRoleId=this.Ccmail;
            item.Subject=this.Mailsubject;
            item.Message=this.MailMessage;
            // item.DocumentStatus = false;
            // item.InsufficiencyStatus = item.InsufficiencyType;
        });
        this.IsLoading = true;

        let subscribe$: Subscription = this._requestService.ResponseToEmployee(modifieditems).subscribe(result => {
            if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
                this.viewEmailTemp = true;
                this.ACTION = this.actionCodes.Responsetoemployee;
                this.openModal('EmailPopup')
                this.GetNGMailDetails();
                // this.showNotificationMessage(msg, true);
               // this.showNotificationMessage(msg, 1, '', null, '', '');
                // this.getCheckDocumentDetailsList();

                //this.refreshRequest();

            }
            this.IsLoading = false;
        },

            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
        // }
    }

    NGDetailsforMail(modifieditems: any, msg: string) {
        
        // if (this.validateMandatoryFields(modifieditems)) {
        modifieditems.forEach((item: any) => {
            item.ActionCode = this.actionCode;
            item.InsufficiencyComment = item.NonGreenComment;
            item.InsufficiencyType = "L3";
            item.ToRoleId=item.Tomail;
            item.CCRoleId=this.Ccmail;
            item.Subject=this.Mailsubject;
            item.Message=this.MailMessage;
            // item.DocumentStatus = false;
            // item.InsufficiencyStatus = item.InsufficiencyType;
        });
        this.IsLoading = true;

        let subscribe$: Subscription = this._requestService.NGDetailsforMail(modifieditems,this.actionCode).subscribe(result => {
            if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
                this.viewEmailTemp = true;
                this.ACTION = this.actionCodes.RaiseL3;
                this.openModal('EmailPopup')
                this.GetNGMailDetails();
                // this.showNotificationMessage(msg, true);
               // this.showNotificationMessage(msg, 1, '', null, '', '');
                // this.getCheckDocumentDetailsList();

                //this.refreshRequest();

            }
            // this.IsLoading = false;
        },

            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
        // }
    }

    // showSubmitConfirmation(msg: string, action: any, data: any) {

    //     this.notify = {
    //         info: {
    //             header: 'Confirmation Alert',
    //             message: msg,
    //             yes: 'Submit now',
    //             no: 'Cancel',

    //             IsHeaderHidden: true,
    //             HeaderIcon: 'fa fa-check-circle text-success',
    //             IsCrossEnable: true,
    //             SizeClass: 'modal-sm'
    //         },

    //         action: action,
    //         item: data
    //     };

    //     this.displayNotifybox = true;
    // }

    disableRows(item: any) {
        if (!isNullOrUndefined(this.NonGreenProperties.BlockCols) && this.NonGreenProperties.BlockCols.length > 0) {
            this.NonGreenProperties.BlockCols.forEach((blockitem: any) => {
                if (blockitem.Value === item[blockitem.Field]) {
                    // blockitem.BlockCols.forEach((item: any) => {
                    item.IsEditable = 0;
                    // });
                }
            });
        }
    }

    setCellLock(fieldname: string, item: any, parent: NonGreenComponent): boolean {
        let bLock = false;
        let BlkCols: any[] = [];

        if (!isNullOrUndefined(parent.NonGreenProperties.BlockCols) && parent.NonGreenProperties.BlockCols.length > 0) {
            parent.NonGreenProperties.BlockCols.forEach((blockitem: any) => {
                if (blockitem.Value === item[blockitem.Field]) {
                    blockitem.BlockCols.forEach((item: any) => {
                        BlkCols.push(item);
                    });
                }
            });
        }

        if (BlkCols.length > 0) {
            bLock = (BlkCols.indexOf(fieldname.toUpperCase()) >= 0 ? true : false);
        }

        return bLock;
    }

    requestStopCase() {

        this.IsLoading = true;

        this.sectionData.forEach(item => {
            item.FilePath = ''
        });

        let subscribe$: Subscription = this._requestService.requestStopCaseByQC(this.sectionData[0]).subscribe(result => {
            if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

                this.notify = {
                    info: {
                        header: 'Confirmation Alert',
                        message: 'Request stopped successfully',
                        yes: '',
                        no: '',

                        IsHeaderHidden: true,
                        HeaderIcon: 'fa fa-check-circle text-success',
                        IsCrossEnable: true,
                        SizeClass: 'modal-sm'
                    },

                    action: '',
                    item: null
                };

                this.displayNotifybox = true;

                this.refreshRequest();

                // this._toastr.success('Request stopped successfully', this.CompTitle);
            }
            if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
                // this._toastr.warning(result.Message, this.CompTitle);
                console.log(result.Message)

            }
            // this.IsLoading = false;

        },

            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }


    showDeleteConfirmation(curitem: any, index: any): void {

        this.notify = {
          info: {
            header: 'Confirmation Alert',
            message: 'Are you sure you want to delete ?',
            yes: 'Yes',
            no: 'No',
    
            IsHeaderHidden: true,
            HeaderIcon: 'fa fa-check-circle text-success',
            IsCrossEnable: true,
            SizeClass: 'modal-sm'
          }
    
          // action: 'DELETE',
          // item: curitem
        };
        let files: any = [];
        if (index > -1) {
    
          this.fileData.splice(index, 1);
          this.ngOnInit();
          // files = this.fileData.forEach((e, i) => e.index = i + 1);
          // 2nd parameter means remove one item only
          //this.fileData = files;]
        }
    
        console.log(this.fileData)
        this.displayNotifybox = true;
      }
      
  btnClick(butt: any): void {
    switch (butt.Name.toUpperCase()) {
      case 'SEND':
        this.sendMailData();
        break;
      case 'CANCEL':
        
        if (this.selectedTab != 'CreateEmail') {
          this.selectedInfoData = {};
          this.viewEmailTemp = false;
          this.viewEmail = false;
        
          
        }
        else {
          this.selectedInfoData = {};
        }
        this.viewEmailTemp = false;
        
        break;
      case 'ATTACHMENT':
        this.DisplayAttachment = true;
        break;
      case 'CLEAR':
        this.DisplayAttachment = false;
        this.fileData = [];
        break;

    }
  }

  getMailerTransaction() {
    console.log(this.selectedTab)
    this.viewEmailData = [];
    this.IsLoading = true;
    let odata: any = {};

    let subscribe$: Subscription = this._requestService.GetMailTransaction(this.RequestId, this.selectedTab).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

        result.Data.forEach((x: any) => this.viewEmailData.push(x));


      }
      console.log(this.viewEmailData)
      this.IsLoading = false;

    },
      err => {
        console.log('Error on getting GetRequestData.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }
  sendMailData() {
    var odata: any = {};
    var transactionId: any = '';
    var MailTransactionId: string = '';
    this.fileRefId = [];
    this.fileNames = [];
    this.fileData.forEach((x: any) => this.fileRefId.push(x.TransactionId));
    this.fileData.forEach((x: any) => this.fileNames.push(x.FileName));
    transactionId = this.fileRefId.toString();
    console.log(this.selectedEmailData)
    this.IsLoading = true;
    this.successFlag = false;
    if(this.ACTION==this.actionCodes.RaiseL3){
        MailTransactionId='17';
    }
    if(this.ACTION==this.actionCodes.Responsetoemployee){
        MailTransactionId='17';
    }
    if(this.ACTION==this.actionCodes.SendToVendor){
        MailTransactionId='22';
    }
    if(this.ACTION==this.actionCodes.FOLLOWUPMAIL){
        MailTransactionId='17';
    }
    if(this.ACTION==this.actionCodes.SHOWCAUSENOTICE){
        MailTransactionId='17';
    }
    if(this.ACTION==this.actionCodes.SendToBGVHead){
        MailTransactionId='18';
    }
    if(this.ACTION==this.actionCodes.SendToCentralHR){
        MailTransactionId='18';
    }
    if(this.ACTION==this.actionCodes.CloseCase){
        MailTransactionId='18';
    }
    var divmaileditabletext : any = ''; 
    divmaileditabletext= document.getElementById("divmailtext")?.innerHTML;
    const buf= Buffer.from(divmaileditabletext, 'utf8'); //btoa(divmaileditabletext)
    var divmaileditabletext1=buf.toString('base64');
    console.log(buf.toString('hex'));
    // Prints: 68656c6c6f20776f726c64
    console.log(buf.toString('base64'));
  
      
      odata = {
        "ToEmailRole": this.selectedInfoData.ToMailMailId,
        "CCEMailRole": this.selectedInfoData.CCMailMailId,
        "EmailMessage": divmaileditabletext1,
        "EmailSubject": this.selectedInfoData.MailSubject,
        "RequestId": this.RequestId,
        "EmailType": this.selectedTab,
        "MailTransactionId": this.selectedEmailActionData.MailTransactionId,
        "Action": this.ACTION,
        "FileReferenceId": transactionId

      //};
    }
    if (this.validateMandatoryField1(this.selectedInfoData, this.ACTION)) {
      let subscribe$: Subscription = this._requestService.SubmitNonGreenMailTransaction(odata).subscribe(result => {
        console.log(result);
        if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

          
        
          this.successFlag = true;
          this.closeModal('EmailPopup')
            if (this.ACTION == this.actionCodes.RaiseL3) { 
                this.RaiseL3Insufficiency(this.raisel3modifieditems, 'Insufficiency raised successfully.');
              this.showNotificationMessage('Insufficiency raised successfully.', 1, '', null, '', '');
            }
            if (this.ACTION == this.actionCodes.Responsetoemployee && this.isResponsetoEmployee) { 
                this.showNotificationMessage('Response to Candidate/Employee sent successfully.', 1, '', null, '', '');
              }
            if (this.ACTION == this.actionCodes.SendToVendor) { 
            this.SubmitNonGreen(this.vendorsubmitmodifieditems, 'Case is sent to vendor.');
            this.showNotificationMessage('Case is sent to vendor.', 1, '', null, '', '');
            }
           if (this.ACTION == this.actionCodes.FOLLOWUPMAIL) { 
                this.showNotificationMessage('Followup Mail Send successfully.', 1, '', null, '', '');
                }
            if (this.ACTION == this.actionCodes.SHOWCAUSENOTICE) { 
                    this.showNotificationMessage('Show Cause Notice Mail Send successfully.', 1, '', null, '', '');
                    }      
           if (this.ACTION == this.actionCodes.SendToBGVHead) { 
                this.SubmitNonGreen(this.BGVHeadModifiedItems,'')
                this.showNotificationMessage('Case is sent to BGV Head.', 1, '', null, '', '');
            }
           if (this.ACTION == this.actionCodes.SendToCentralHR) { 
             this.SubmitNonGreen(this.BGVHeadModifiedItems,'')
                this.showNotificationMessage('Case is sent to Central HR.', 1, '', null, '', '');
            }
           if (this.ACTION == this.actionCodes.TerminationApprovalToBGVHead) { 
            let ngTerminationData=this.NonGreenData.filter(x=>x.PendingWith === RoleCodes.NonGreen.toString()
                    && (x.ColorCode === StatusColorCode.Red||x.ColorCode === StatusColorCode.Amber));
                this.SubmitNonGreen(ngTerminationData,'')
                this.showNotificationMessage('Case submitted to BGV Head for Termination Approval.', 1, '', null, '', '');
            }
            if (this.ACTION == this.actionCodes.CloseCase) { 
                let ngCloseData=  this.NonGreenData.filter(x => x.IsEditable === 1)
                ngCloseData.forEach((item:any)=>{
                 item.IsClosed=true;
                });
                this.SubmitNonGreen(ngCloseData, '');
                 this.showNotificationMessage('Case closed successfully.', 1, '', null, '', '');
            }
          this.refreshRequest();
         

          this.displayNotifybox = true;



          this.fileData = [];
        }
        else if(!isNullOrUndefined(result) && result.Status === ProcessStatus.Error) 
        {
            console.log("This is result  " + result.Message);
            this.successFlag = false;
        }
        // this.IsLoading = false;

      },
        err => {
          this.fileData = [];
          this.successFlag = false;
          console.log('Error');
          this.IsLoading = false;
        });
      this.subscriptionList$.push(subscribe$);
    }


  }
  GetAttachmentDetails(item: any) {
    console.log(item);
    let subscribe$: Subscription = this._requestService.GetAttachmentDetails(item).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        console.log(result.Data)
        result.Data.forEach((x: any) => this.fileNames.push(x));
      }
      console.log(this.fileNames)
      this.IsLoading = false;

    },
      err => {
        console.log('Error on getting GetAttachmentDetails.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }
  validateMandatoryField1(modifieditems: any, action: any): boolean {
    let valid: boolean = true;
    if (action == this.actionCodes.RaiseL3) {
      if (isNullOrUndefined(modifieditems.ToMailMailId) || modifieditems.ToMailMailId.length === 0) {
        this.showNotificationMessage('To field is required.', 2, '', null, '', '');
        valid = false;
      }
    //   else if (isNullOrUndefined(modifieditems.MailText) || modifieditems.MailText.length === 0) {
    //     this.showNotificationMessage('Message field is required.', 2, '', null, '', '');
    //     valid = false;
    //   }
      else if (isNullOrUndefined(modifieditems.MailSubject) || modifieditems.MailSubject.length === 0) {
        this.showNotificationMessage('Subject field is required.', 2, '', null, '', '');
        valid = false;
      }
    }
    if (action == this.actionCodes.Responsetoemployee) {
        if (isNullOrUndefined(modifieditems.ToMailMailId) || modifieditems.ToMailMailId.length === 0) {
          this.showNotificationMessage('To field is required.', 2, '', null, '', '');
          valid = false;
        }
        else if (isNullOrUndefined(modifieditems.MailSubject) || modifieditems.MailSubject.length === 0) {
          this.showNotificationMessage('Subject field is required.', 2, '', null, '', '');
          valid = false;
        }
      }


    else if (action == this.actionCodes.SendToVendor) {
        if (isNullOrUndefined(modifieditems.ToMailMailId) || modifieditems.ToMailMailId.length === 0) {
          this.showNotificationMessage('To field is required.', 2, '', null, '', '');
          valid = false;
        }
        // else if (isNullOrUndefined(modifieditems.MailText) || modifieditems.MailText.length === 0) {
        //   this.showNotificationMessage('Message field is required.', 2, '', null, '', '');
        //   valid = false;
        // }
        else if (isNullOrUndefined(modifieditems.MailSubject) || modifieditems.MailSubject.length === 0) {
            this.showNotificationMessage('Subject field is required.', 2, '', null, '', '');
            valid = false;
          }
      }
      else if (action == this.actionCodes.FOLLOWUPMAIL) {
        if (isNullOrUndefined(modifieditems.ToMailMailId) || modifieditems.ToMailMailId.length === 0) {
          this.showNotificationMessage('To field is required.', 2, '', null, '', '');
          valid = false;
        }
        // else if (isNullOrUndefined(modifieditems.MailText) || modifieditems.MailText.length === 0) {
        //   this.showNotificationMessage('Message field is required.', 2, '', null, '', '');
        //   valid = false;
        // }
        else if (isNullOrUndefined(modifieditems.MailSubject) || modifieditems.MailSubject.length === 0) {
            this.showNotificationMessage('Subject field is required.', 2, '', null, '', '');
            valid = false;
          }
      }
      else if (action == this.actionCodes.SHOWCAUSENOTICE) {
        if (isNullOrUndefined(modifieditems.ToMailMailId) || modifieditems.ToMailMailId.length === 0) {
          this.showNotificationMessage('To field is required.', 2, '', null, '', '');
          valid = false;
        }
        // else if (isNullOrUndefined(modifieditems.MailText) || modifieditems.MailText.length === 0) {
        //   this.showNotificationMessage('Message field is required.', 2, '', null, '', '');
        //   valid = false;
        // }
        else if (isNullOrUndefined(modifieditems.MailSubject) || modifieditems.MailSubject.length === 0) {
            this.showNotificationMessage('Subject field is required.', 2, '', null, '', '');
            valid = false;
          }
      }
      else if (action == this.actionCodes.SendToBGVHead) {
        
        if (isNullOrUndefined(modifieditems.ToMailMailId) || modifieditems.ToMailMailId.length === 0) {
          this.showNotificationMessage('To field is required.', 2, '', null, '', '');
          valid = false;
        }
        // else if (isNullOrUndefined(modifieditems.MailText) || modifieditems.MailText.length === 0) {
        //   this.showNotificationMessage('Message field is required.', 2, '', null, '', '');
        //   valid = false;
        // }
        else if (isNullOrUndefined(modifieditems.MailSubject) || modifieditems.MailSubject.length === 0) {
            this.showNotificationMessage('Subject field is required.', 2, '', null, '', '');
            valid = false;
          }
      }
      else if (action == this.actionCodes.SendToCentralHR) {
        
        if (isNullOrUndefined(modifieditems.ToMailMailId) || modifieditems.ToMailMailId.length === 0) {
          this.showNotificationMessage('To field is required.', 2, '', null, '', '');
          valid = false;
        }
        // else if (isNullOrUndefined(modifieditems.MailText) || modifieditems.MailText.length === 0) {
        //   this.showNotificationMessage('Message field is required.', 2, '', null, '', '');
        //   valid = false;
        // }
        else if (isNullOrUndefined(modifieditems.MailSubject) || modifieditems.MailSubject.length === 0) {
            this.showNotificationMessage('Subject field is required.', 2, '', null, '', '');
            valid = false;
          }
      }
      else if (action == this.actionCodes.TerminationApprovalToBGVHead) {
        
        if (isNullOrUndefined(modifieditems.ToMailMailId) || modifieditems.ToMailMailId.length === 0) {
          this.showNotificationMessage('To field is required.', 2, '', null, '', '');
          valid = false;
        }
        // else if (isNullOrUndefined(modifieditems.MailText) || modifieditems.MailText.length === 0) {
        //   this.showNotificationMessage('Message field is required.', 2, '', null, '', '');
        //   valid = false;
        // }
        else if (isNullOrUndefined(modifieditems.MailSubject) || modifieditems.MailSubject.length === 0) {
            this.showNotificationMessage('Subject field is required.', 2, '', null, '', '');
            valid = false;
          }
      }
      else if (action == this.actionCodes.SendToBUHR) {
        
        if (isNullOrUndefined(modifieditems.ToMailMailId) || modifieditems.ToMailMailId.length === 0) {
          this.showNotificationMessage('To field is required.', 2, '', null, '', '');
          valid = false;
        }
        // else if (isNullOrUndefined(modifieditems.MailText) || modifieditems.MailText.length === 0) {
        //   this.showNotificationMessage('Message field is required.', 2, '', null, '', '');
        //   valid = false;
        // }
        else if (isNullOrUndefined(modifieditems.MailSubject) || modifieditems.MailSubject.length === 0) {
            this.showNotificationMessage('Subject field is required.', 2, '', null, '', '');
            valid = false;
          }
      }
    else {
      if (isNullOrUndefined(modifieditems.ToMailMailId) || modifieditems.ToMailMailId.length === 0) {
        this.showNotificationMessage('To field is required.', 2, '', null, '', '');
        valid = false;
      }
    //   else if (isNullOrUndefined(modifieditems.MailText) || modifieditems.MailText.length === 0) {
    //     this.showNotificationMessage('Message field is required.', 2, '', null, '', '');
    //     valid = false;
    //   }
      else if (isNullOrUndefined(modifieditems.MailSubject) || modifieditems.MailSubject.length === 0) {
        this.showNotificationMessage('Subject field is required.', 2, '', null, '', '');
        valid = false;
      }
    }




    // if (!isNullOrUndefined(modifieditems) && modifieditems.length > 0) {
    //   let OfferStatusList = modifieditems.filter(x => ((isNullOrUndefined(x.OfferStatus)) || x.OfferStatus === ''));

    //   if (!isNullOrUndefined(OfferStatusList) && OfferStatusList.length > 0) {
    //     this.showNotificationMessage('Please select offer status status for modified rows.', 2, '', null, '', '');
    //     valid = false;
    //   }

    // }
    if(valid==false){
        this.IsLoading = false;
    }
    
    return valid;
  }
  closeAttachmentEmail(event: any): void {
    this.DisplayAttachment = false;
    this.fileData = [];
    // this.fileNames=[];

    // event.data.forEach((x: any) => this.fileNames.push(x.FileName));

    //.TransactionId
    if (!isNullOrUndefined(event.data)) {
      event.data.forEach((x: any) => this.fileData.push(x));
      console.log(this.fileData);
      // this.reportData.FilePath = event.data[0].FilePath;
      // this.reportData.TransactionId = event.data[0].TransactionId;
      // this.reportData.FileName = event.data[0].FileName;

      //   this.stopCasesectionData[0].FilePath = event.data[0].FilePath;
      //   this.stopCasesectionData[0].FileReferenceId = event.data[0].TransactionId;
      //   this.stopCasesectionData[0].FileName = event.data[0].FileName;
      // } else {
      //   this.stopCasesectionData[0].IsModified = 0;
      //   this.stopCasesectionData[0].IsRowHighlight = this.stopCasesectionData[0].IsModified;
    }


  }

getBasicDetails() {
    
    
    this.IsLoading = true;
    let odata: any = {};
    this.NonGreenFormData={};
    let subscribe$: Subscription = this._requestService.GetBasicDetails(this.RequestId,this.actionCode).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
      this.NonGreenFormData=result.Data;     
      this.NonGreenFormData.IsEditable=1;
       console.log('formdata',this.NonGreenFormData);
       console.log('formdata',this.RequestData);
      }
      
      this.IsLoading = false;

    },
      err => {
        console.log('Error on getting GetRequestData.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
}

SubmitNonGreenForm(modifieditems: any, msg: string) {
    // if (this.validateMandatoryFields(modifieditems)) {
    this.sectionData
     modifieditems.ActionCode=  this.actionCode;
     let submitDetails=this.SubmittedDetailsList.filter(x=>x.IsModified===1);
     let verifiedcomps=this.VerifiedComponentsList.filter(x=>x.IsModified===1); 
     var BGCobservationtext : any = modifieditems.BGCObservations; 
     //BGCobservationtext= document.getElementById("divmailtext")?.innerHTML;
     
     const buf= Buffer.from(modifieditems.BGCObservations, 'utf8'); //btoa(divmaileditabletext)
     modifieditems.BGCObservations=buf.toString('base64');

     const buf1= Buffer.from(modifieditems.VendorFeedback, 'utf8'); //btoa(divmaileditabletext)
     modifieditems.VendorFeedback=buf1.toString('base64');

     if(submitDetails.length>0)
     {
        this.InsertMoreDetails('Update',submitDetails);
     }
     
    this.IsLoading = true;
    let subscribe$: Subscription = this._requestService.SubmitNonGreenForm(modifieditems).subscribe(result => {
        if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
            if (this.ACTION == this.actionCodes.SendToCentralHR) {
                this.ACTION = this.actionCodes.SendToCentralHR;
                this.viewEmailTemp = true;
                this.openModal('EmailPopup')
                this.GetNGMailDetails();
            }
            if (this.ACTION == this.actionCodes.SendToBGVHead) {
                this.ACTION = this.actionCodes.SendToBGVHead
                this.viewEmailTemp = true;
                this.openModal('EmailPopup')
                this.GetNGMailDetails();
            }
            if(this.ACTION==this.actionCodes.TerminationApprovalToBGVHead)
            {
                this.ACTION = this.actionCodes.TerminationApprovalToBGVHead
                this.viewEmailTemp = true;
                this.openModal('EmailPopup')
                this.GetNGMailDetails();
            }
            this.getBasicDetails();
            //this.getCheckDocumentDetailsList();           
           // this.showNotificationMessage(msg, 1, '', null, '', '');
            //this.openNonGreenSection = false;
          
           
           // this.UpdateVerifiedComponents(verifiedcomps);
        }
         this.IsLoading = false;
    },

        err => {
            this.IsLoading = false;
        });
    this.subscriptionList$.push(subscribe$);
    // }
}
SaveNonGreenForm(modifieditems: any, msg: string) {
    //this.sectionData
     modifieditems.ActionCode=  this.actionCode;
     let submitDetails=this.SubmittedDetailsList.filter(x=>x.IsModified===1);
     if(submitDetails.length>0)
     {
        this.InsertMoreDetails('Update',submitDetails);
     }
     const buf= Buffer.from(modifieditems.BGCObservations, 'utf8'); //btoa(divmaileditabletext)
     modifieditems.BGCObservations=buf.toString('base64');

     const buf1= Buffer.from(modifieditems.VendorFeedback, 'utf8'); //btoa(divmaileditabletext)
     modifieditems.VendorFeedback=buf1.toString('base64');

    this.IsLoading = true;
    let subscribe$: Subscription = this._requestService.SubmitNonGreenForm(modifieditems).subscribe(result => {
        if(!isNullOrUndefined(result) && result.Status === ProcessStatus.Success)
        {
            this.showNotificationMessage('Details saved successfully', 1, '', null, '', '');
          
            this.getBasicDetails();

        }
        this.IsLoading = false;
    },

        err => {
            this.IsLoading = false;
        });
    this.subscriptionList$.push(subscribe$);
    // }
}

AddMoreDetails(): void {
    let detailsmodifieditems = this.SubmittedDetailsList.filter(x => x.IsModified === 1);

    //let basicmodifieditems=this.NonGreenFormData.IsModified===1
   // let applicablemodifieditems = this.checkDocumentDetailsList.filter(x => x.IsModified === 1);
    //let gapmodifieditems = this.additionalSecFormData.filter((x: any) => x.IsModified === 1);

    if (detailsmodifieditems.length === 0
    ) {
      this.InsertMoreDetails('Create',this.SubmittedDetailsList);
    } else {
      this.showNotificationMessage('Please save modified sections before adding more details.', 2, '', null, '', '');
    }

    // this.openModal('AddMore')
}

InsertMoreDetails(mode: string = '',data: any) {

    this.IsLoading = true;
    
    let modifieditems: any[] = [];
    if(mode=='Delete')
    {
        modifieditems=this.SubmittedDetailsList.filter(x=>x.TransactionID==data.TransactionID)
        modifieditems[0].Mode=mode;
        modifieditems[0].ActionCode=this.actionCode;

    }
    else if(mode=='Create')
    {
        
        this.SubmittedDetailsList = [];

        let secData: any = {
          RequestId: this.RequestId,   
          ActionCode: this.actionCode,
          
          Mode: mode,
        };
        this.SubmittedDetailsList.push(secData);
        modifieditems=this.SubmittedDetailsList;
    }
   else
    {
    data.forEach((item: any) => {
        item.RequestId= this.RequestId,
            item.Mode=mode,
            item.ActionCode=this.actionCode
            
    });
    modifieditems=data;
    }   
  
    
    
    let subscribe$: Subscription = this._requestService.InsertMoreDetails(modifieditems).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
       // // let msg = mode === 'Delete' ? 'Check details deleted successfully.' :mode==='Update'? 'Check details updated successfully.': 'Check details added successfully.';
       // // this.showNotificationMessage(msg, 1, '', null, '', '');
        this.getSubmittedDetails();
      }
    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
    
  }
getSubmittedDetails() {
    
    
    this.IsLoading = true;
    let odata: any = {};

    let subscribe$: Subscription = this._requestService.GetSubmittedDetails(this.RequestId,this.actionCode).subscribe(result => {
    if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
      this.SubmittedDetailsList=result.Data;
      this.SubmittedDetailsList.forEach((item: any) => {
        item.IsEditable =  1 ;
        item.IsModified = 0;
      });     
    }
    if (result ===null) {
        this.SubmittedDetailsList=[];
       
    } 
      
      this.IsLoading = false;

    },
      err => {
        console.log('Error on getting GetRequestData.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
}
showConfirmationforDeleteDetails(modifieditem: any) {
    this.showNotificationMessage('Are you sure you want to delete the data?', 2, 'DELETEDETAILS', modifieditem, 'Delete', 'Cancel');
  }
  UpdateCheckDetails(data: any = null) {

    this.IsLoading = true;
    let odata: any = {
      RequestId: this.RequestId,
     TransactionID: !isNullOrUndefined(data) && data.TransactionID > 0 ? data.TransactionID : 0
    };
    
    let subscribe$: Subscription = this._requestService.UpdateCheckDetails(odata).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
       // let msg = mode === 'Delete' ? 'Verified component deleted successfully.' : 'Verified component added successfully.';
       
        // this.closeModal('AddMore');
        // this.AddFormData.DocumentTypeId = null;
        // this.AddFormData.DocumentTypeName = null;
        
        // this.IsLoading = false;
      }
    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
    
  }
ValidateFormData(): boolean {
    let valid: boolean = true;

    let submittedDetailsmodified = this.SubmittedDetailsList.filter((x: any) => x.IsModified === 1).length > 0 ? true : false;
    let verifiedcomponentsmodified = this.VerifiedComponentsList.filter((x: any) => x.IsModified === 1).length > 0 ? true : false;

    if ((!isNullOrUndefined(submittedDetailsmodified) && submittedDetailsmodified===true) || (!isNullOrUndefined(verifiedcomponentsmodified) && verifiedcomponentsmodified === true)) {

        if (!isNullOrUndefined(this.SubmittedDetailsList) && this.SubmittedDetailsList.length > 0
            && this.SubmittedDetailsList.filter((x: any) => 
            //(isNullOrUndefined(x.CheckName) || x.CheckName === '')||
             (isNullOrUndefined(x.DescriptionName) || x.DescriptionName === '')
            ||(isNullOrUndefined(x.DocumentTypeId) ||x.DocumentTypeId===0)
            || (isNullOrUndefined(x.Duration) || x.Duration === '')).length > 0) {
            this.showNotificationMessage('Please fill mandatory fields in Check Details.', 2, '', null, '', '');
            valid=false;
        } 
        // if (!isNullOrUndefined(this.VerifiedComponentsList) && this.VerifiedComponentsList.length > 0
        //          && this.VerifiedComponentsList.filter((x: any) => (isNullOrUndefined(x.CheckName) || x.CheckName === '')
        //         || (isNullOrUndefined(x.CheckStatus) || x.CheckStatus === '')).length > 0) {
        //         this.showNotificationMessage('Please fill mandatory fields in Verified Components.', 2, '', null, '', '');
        //         valid=false;
        // }
                 
    }
    if(this.SubmittedDetailsList.length===0)
    {
        this.showNotificationMessage('Please add atleast one check details to submit form', 2, '', null, '', '');
        valid= false;
    }
    if((isNullOrUndefined(this.NonGreenFormData.BGVRecommendation) || this.NonGreenFormData.BGVRecommendation === '')
    //||(isNullOrUndefined(this.NonGreenFormData.ComponentDetails) || this.NonGreenFormData.ComponentDetails === '') 
    ||(isNullOrUndefined(this.NonGreenFormData.VendorFeedback) || this.NonGreenFormData.VendorFeedback === '')
    ||(isNullOrUndefined(this.NonGreenFormData.BGCObservations) || this.NonGreenFormData.BGCObservations === '')
    ||(isNullOrUndefined(this.NonGreenFormData.CandidateClarification) || this.NonGreenFormData.CandidateClarification === '')
        ) {
            this.showNotificationMessage('Please fill mandatory fields', 2, '', null, '', '');
            valid= false;
    }
    else if ((!isNullOrUndefined(submittedDetailsmodified) && submittedDetailsmodified===false) 
    && (isNullOrUndefined(this.NonGreenFormData.BGVRecommendation) || this.NonGreenFormData.BGVRecommendation === '') 
    //&&(isNullOrUndefined(this.NonGreenFormData.ComponentDetails) || this.NonGreenFormData.ComponentDetails === '')  
    && (isNullOrUndefined(this.NonGreenFormData.BGCObservations) || this.NonGreenFormData.BGCObservations === '')  
    && (isNullOrUndefined(this.NonGreenFormData.CandidateClarification) || this.NonGreenFormData.CandidateClarification === '')  
    && (isNullOrUndefined(this.NonGreenFormData.VendorFeedback) || this.NonGreenFormData.VendorFeedback === '')) {
        this.showNotificationMessage('No data and metadata are modified.', 2, '', null, '', '');
        valid =false;
    }
    
    


    return valid;
}

showOverallStatus()
    {
        if(this.isShowOverallCaseButtonClicked)
        {
            this.isAdditonalSectionClicked=false;
            this.isShowOverallCaseButtonClicked=false;
        }
        
        else{
            this.isAdditonalSectionClicked=true;
            this.isShowOverallCaseButtonClicked=true;
        }
    }

   
}
