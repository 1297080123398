<!-- Modal section -->
<div id="switchUserDialog" role="dialog" class="modal common-modal fade show" tabindex="-1" style="display:block;" #dragboundary>
  <div class="modal-dialog  modal-dialog-centered">
    <!-- Modal content -->
    <div class="ng-draggable modal-content" ngDraggable [inBounds]="true" [bounds]="dragboundary" [handle]="draghandle">
      <!-- Modal header -->
      <div class="modal-header bg-light drag-block-handle" #draghandle>
        <h3 class="modal-title">
          <!-- <i class="fa fa-envelope-o"></i> -->
            Switch User</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeSwitchUser()">
          
        </button>
      </div>
      <!-- Modal body -->
      <div class="modal-body">

        <form id="loginform" class="form-horizontal ng-pristine ng-invalid ng-invalid-required" role="form" 
              (keydown)="keyDown($event)">
<!--   
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="username"><i class="fa fa-user"></i></span>
            </div>
            <input type="email" class="form-control" aria-label="" aria-describedby="username" id="txtUName" name="txtUName"
              required="" >
          </div> -->

          <div class="col-12">
            <div class="form-group">
                <label for="txtUName" class="required sr-only">Email</label>
                <div>
                    <div class="input-group ">
                        <span class="input-group-text" id="basic-addon1"><i
                                class="ion ion-md-person"></i></span>
                        <input type="text" name="txtUname" id="txtUName" 
                            class="form-control form-control-sm"
                            placeholder="Email" aria-label="Email"
                            aria-describedby="basic-addon1" [(ngModel)]="emailid">
                    </div>
                </div>

            </div>
        </div>

        </form>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <!-- <button class="btnCustom btnPrimary rounded-pill">
            <span>Update Case</span><i class="ion ion-md-arrow-forward" aria-hidden="true"></i>
        </button> -->
        <button type="button" class="btnCustom btnNegative rounded-pill" data-bs-dismiss="modal" (click)="closeSwitchUser()">
            <span>{{'Cancel' | translate}}</span>
          </button>
        <button type="button" class="btnCustom btnPrimary rounded-pill" data-bs-dismiss="modal" 
          name="btnLogin" value="Login" (click)="switchUser()">
          <span>{{'Switch' | translate}}</span>
          <i class="ion ion-md-arrow-forward" aria-hidden="true"></i>
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal !-->


<div class="modal-backdrop fade show"></div>