<!-- Manage Roles Component -->

<ng-container>
    <div class="h-200">
        
        <div class="contentScroll p-3 simpleCard" style="overflow: hidden !important;">
            <ng-container *ngIf="(AdminSearchTemplate != null && AdminSearchTemplate != undefined && AdminSearchTemplate.length > 0)">
                <searchby [SearchButtons]="SearchButtons" [SearchTemplate]="AdminSearchTemplate" [FormConfig]="FormConfig"
                  [GlobalConfig]="GlobalConfig" [FormProp]="FormProp" (SearchData)="SearchData($event,false,true)"
                  class="customForm">
                </searchby>
          
          
              </ng-container>
              <div class="card-header"
              *ngIf="(searchEvent.item !=null && searchEvent.item != undefined && 
                  searchEvent.item.Value !=null && searchEvent.item.Value != undefined && searchEvent.item.Value.length > 0)">
              <div class="d-flex w-200 justify-content-between">
                <h3>
                  <span>Search Results:
                    <span class="text-primary ps-2">{{dataTotalCount}} Records Found</span></span>
                </h3>
              </div>
              <!-- <h3>
                <span>Search Results:
                  <span class="text-primary ps-2">{{dataTotalCount}} Records Found</span></span>
              </h3> -->
            </div>
            <div class="card h-200 m-0">
                <div class="card-header">
                    <h3>
                        User Role Details
                    </h3>
                </div>
                <div class="card-body overflow-auto p-0">
                    <div class="contentScroll p-3">
                        <div *ngIf="SelectedTab == 'UserRoleList'">
                            <div class="gridContainer">
                                <ng-container
                                    *ngIf="(roleListTemplate != null && roleListTemplate != undefined && roleListTemplate.length > 0)">

                                    <grid-dispctrl #overallgrid
                                        [FieldTemplate]="(roleListTemplate | filterBy:{Group: 'UserRoleResult'})"
                                        [GridData]="reqListData" [GridProp]="GridProp"
                                        [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                                        (ActionBarClick)="actButtonClick($event)">
                                    </grid-dispctrl>

                                </ng-container>
                            </div>

                        </div>

                        <div *ngIf="SelectedTab == 'UserRoleEdit'" tabindex="0">
                            <ng-container
                                *ngIf="(roleListTemplate != null && roleListTemplate != undefined && roleListTemplate.length > 0)">

                                <div class="bg-light p-3">
                                    <p class="text-bold"></p>

                                    <div class="row form-inline">
                                        <!-- <ng-container
                                            *ngIf="(SearchTemplate != null && SearchTemplate != undefined && SearchTemplate.length > 0)">
                                            <searchby [SearchButtons]="SearchButtons" [SearchTemplate]="SearchTemplate"
                                                [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                                                [FormProp]="FormProp" (SearchData)="SearchData($event)"
                                                class="customForm">
                                            </searchby>
                                        </ng-container> -->

                                        <customform 
                                            [FormTemplate]="(roleListTemplate | filterBy:{Group: 'UserRoleEdit'})"
                                            [FormData]="editUserRoleData" [FormProp]="FormProp"
                                            [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                                            (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                        </customform>
                                        <!-- editUserRoleData.RoleIds == '9') || (editUserRoleData.RoleIds == '14' -->
                                        <customform *ngIf="(editUserRoleData.RoleIds.includes('14'))"
                                            [FormTemplate]="AccountTemplate"
                                            [FormData]="editUserRoleData" [FormProp]="FormProp"
                                            [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                                            (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                        </customform>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="filters buttonHolder" id="vendorTab">
                        <div class="d-flex justify-content-between ">
                            <div></div>
                            <div>
                                <ng-container
                                    *ngIf="Config.ActionButtonsBottom != null && Config.ActionButtonsBottom != undefined">
                                    <ng-container
                                        *ngFor="let actionBar of (Config.ActionButtonsBottom | filterBy: 
                                                {
                                                    IsApplicable: true,HasPermission :true, 
                                                    Group: 
                                                    (
                                                    SelectedTab == 'UserRoleList' ? 'ViewRole' : 
                                                    SelectedTab == 'UserRoleEdit' ? 'AddUserRole' : ''
                                                    ) 
                                                }
                                                | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">

                                        <button type="button" [ngClass]="actionBar.CustomClass"
                                            (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                            value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                            |
                                            translate
                                            }}
                                            <span *ngIf="actionBar.ShowArrow" class="ps-3"><i
                                                    class="ion ion-md-arrow-forward" aria-hidden="true"></i></span>
                                        </button>
                                    </ng-container>
                                </ng-container>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-center">
                        <div class="small align-self-center">
                            Copyright © 2022 Capgemini All rights reserved.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>

<!-- Filter Pop-up -->
<div *ngIf="DispFilter">
    <filter *ngIf="(roleListTemplate!= undefined && roleListTemplate!= null && roleListTemplate.length > 0)"
        [FieldTemplate]="(roleListTemplate | filterBy:{Group: 'UserRoleResult'})" [GridData]="reqListDataALL"
        (close)="showFilter($event)" (selected)="setFilter($event)" [InitialCondn]="curFiltCondn">
    </filter>
</div>

<!-- Excel Download -->
<excel-export #excelexport [FileName]="ExcelFileName"></excel-export>

<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
        (selected)="setNotifyRes($event)" (close)="closeNotifybox()"></notification>
</div>