<div class="h-100">

    <div class="p-2 contentScroll simpleCard">
        <!--Start From Here -->
        <div class="card h-100 m-0">
            <div class="card-header">
                <h3>
                    <div class="d-flex justify-content-between">
                        <span>Submit Background Verification</span>

                    </div>
                </h3>
            </div>

            <div class="card-body overflow-auto dashboard-status">

                <div class="d-flex justify-content-between mt-4">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" checked="" id="attachmentCheck"
                            [(ngModel)]="AcknowledgeSelected" (change)="onChange()">
                        <label class="form-check-label" for="attachmentCheck">
                            <p class="text-bold">I Acknowledge all the information provided is true as per my knowledge
                            </p>
                        </label>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="filters buttonHolder">
        <div class="d-flex justify-content-between ">
            <div></div>
            <div>
                <!-- <button class="btnCustom btnNegative rounded-pill justify-content-start">
                    <span>cancel</span><i class="ion ion-md-arrow-forward" aria-hidden="true"></i>
                </button>
                <button class="btnCustom btnPrimary rounded-pill justify-content-start" data-bs-toggle="modal"
                    data-bs-target="#submit-confirm">
                    <span>Submit</span><i class="ion ion-md-arrow-forward" aria-hidden="true"></i>
                </button> -->
                <ng-container
                    *ngIf="!RequestData.IsClosed && RequestProperty.ActionButtons != null && RequestProperty.ActionButtons != undefined && !IsReadonly">

                    <ng-container *ngFor="let actionBar of (RequestProperty.ActionButtons | filterBy: {IsApplicable: true,HasPermission :true,Group:'SubmitDocument'}
                            | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">

                        <button type="button" [ngClass]="actionBar.CustomClass" (click)="actionButtonClick(actionBar)"
                            [disabled]="!actionBar.IsEnabled"
                            value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName |
                            translate
                            }}
                            <span *ngIf="actionBar.ShowArrow"><i class="ion ion-md-arrow-forward"
                                    aria-hidden="true"></i></span>
                        </button>

                    </ng-container>
                </ng-container>

            </div>
        </div>
    </div>



</div>

<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
        (selected)="setNotifyRes($event)" (close)="closeNotifybox()"></notification>
</div>