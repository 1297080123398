<!-- <div class="container-fluid pt-3"> -->
<div class="">
    <!-- <div class="innerScroll"> -->
    <div class="contentScroll full">
        <div class="card-columns" [ngStyle]="{'column-count': (IsFullScreen == true ? '1' : LayoutData.LayoutSize)}">

            <!-- <div class="row"> -->
            <ng-container *ngIf="TileList != null && TileList!=undefined && TileList.length > 0">
                <ng-container *ngFor="let tileitem of (TileList | filterBy: {CanShow: true}
                        | orderBy: {property: 'Seqorder', direction: '1' , type: 'number'}); let i = index">
                    <div class="col-{{tileitem.DefaultColumn <= 6 ? 6 : 12}}">
                        <tile [TileTemplate]="tileitem" (deleteTile)="deleteTile($event)"
                            (resizeTile)="resizeTile($event)" (fullscreen)="fullScreen($event)">
                        </tile>
                    </div>
                </ng-container>
            </ng-container>
            <!-- </div> -->

        </div>
    </div>
</div>