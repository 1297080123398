<ng-container>
    <!-- Header Section -->
    <nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
        <!-- Logo Section -->
        <a class="navbar-brand" aria-label="Capgemini Logo Redirect to Home Page" href="index.html">
            <img src="assets/images/Capgemini-Logo.svg" alt="Capgemini Logo" /></a>
    </nav>


    <!-- Body Section -->
    <div id="bodyContainer" class="bodyContainer login">
        <div class="contentScroll full">
            <div class="d-flex flex-column justify-content-between h-100">
                <div class="line-image"><span class="sr-only">Capgemini Line image canvas</span></div>
                <div class="h-100">
                    <div class="row align-items-stretch g-0 h-100">
                        <div class="col-7">
                            <div class="bgWrapper">
                                <span class="sr-only">Background image canvas</span>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="row g-0 justify-content-center align-items-center h-100">
                                <div class="col-9 simpleCard customPosition">
                                    <div class="loginContainer">
                                        <h2 class="text-center">Background Verification</h2>
                                        <div class="card">
                                            <div class="card-header">
                                                <h3>
                                                    <span>Login</span>
                                                </h3>
                                            </div>
                                            <div class=" card-body overflow-auto py-4">
                                                <div class="row small-gutter form-inline">
                                                    <ng-container *ngIf="!showOTP">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="emp1" class="required">Email</label>
                                                                <div>
                                                                    <div class="input-group">
                                                                        <span class="input-group-text"
                                                                            id="basic-addon1"><i
                                                                                class="ion ion-md-person"></i></span>
                                                                        <input type="text" id="txtEmailId"
                                                                            class="form-control form-control-sm"
                                                                            placeholder="username@domain.com"
                                                                            aria-label="username@domain.com"
                                                                            aria-describedby="basic-addon1">
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">

                                                                <label for="emp2" class="required">Password</label>
                                                                <div>
                                                                    <div class="input-group">
                                                                        <span class="input-group-text"
                                                                            id="basic-addon2"><i
                                                                                class="ion ion-md-key"></i></span>
                                                                        <input type="password" id="txtPassword"
                                                                            class="form-control form-control-sm"
                                                                            placeholder="Password" aria-label="Password"
                                                                            aria-describedby="basic-addon2">
                                                                        <span class="input-group-text" id="basic-addon3"
                                                                            (click)="togglePassword()">
                                                                            <i [ngClass]="[(showPassword)? 'ion ion-md-eye':'ion ion-md-eye-off']"
                                                                                aria-hidden="true"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="showOTP">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="emp1" class="required">Enter OTP</label>
                                                                <div>
                                                                    <div class="input-group">
                                                                        <span class="input-group-text"
                                                                            id="basic-addon1"><i
                                                                                class="ion ion-md-key"></i></span>
                                                                        <input type="text" id="txtOTP"
                                                                            class="form-control form-control-sm"
                                                                            aria-label="Mail OTP"
                                                                            aria-describedby="basic-addon1">
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="text-danger" >
                                                                {{showOTP}}
                                                            </div> -->
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>

                                                <div class="row form-inline justify-content-end">
                                                    <ng-container *ngIf="!showOTP">
                                                        <div class="col-6 ps-0">
                                                            <div class="form-group mb-0">
                                                                <div class="d-flex justify-content-end ">
                                                                    <!-- <div>
                                                                <input class="form-check-input" type="checkbox"
                                                                    value="" id="flexCheckDefault"> <label
                                                                    for="flexCheckDefault"
                                                                    class="form-check-label">Remember
                                                                    me</label>
                                                            </div> -->

                                                                    <!-- <a  href="javascript:this.blur();" (click)="ResetLoginPassword()"
                                                                        class="text-decoration-underline align-self-center">Forgot
                                                                        Password?</a> -->

                                                                    <a aria-label="Forgot Password" href="#" [routerLink]="['/forgotpswd']"
                                                                        class="text-decoration-underline">Forgot Password</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>

                                            </div>

                                            <div class="filters buttonHolder px-3">
                                                <div class="d-flex justify-content-between">
                                                    <div>
                                                        <a aria-label="Register Now" href="#" [routerLink]="['/signup']"
                                                            class="text-decoration-underline">Register now</a>
                                                    </div>

                                                    <ng-container *ngIf="!showOTP">
                                                        <!-- <button type="button"
                                                        class="btnCustom btnSecondary rounded-pill">
                                                        <span>Sign in with SAML</span><i
                                                            class="ion ion-md-arrow-forward" aria-hidden="true"></i>
                                                    </button> -->
                                                        <button type="button" class="btnCustom btnPrimary rounded-pill"
                                                            (click)="validateUser()">
                                                            <span>Send OTP</span><i class="ion ion-md-arrow-forward"
                                                                aria-hidden="true"></i>
                                                        </button>
                                                    </ng-container>
                                                    <ng-container *ngIf="showOTP">
                                                        <button type="button" class="btnCustom btnPrimary rounded-pill"
                                                            (click)="backToLogin()">
                                                            
                                                            <i class="ion ion-md-arrow-back"
                                                                aria-hidden="true"></i><span>Back</span>
                                                        </button>
                                                        <button type="button" class="btnCustom btnPrimary rounded-pill"
                                                            (click)="validateOTP()">
                                                            
                                                            <i class="ion ion-md-arrow-forward"
                                                                aria-hidden="true"></i><span>Verify OTP</span>
                                                        </button>
                                                    </ng-container>
                                                                         
                                                </div>

                                             
                                            </div>
                                            <div>
                                                <ng-container>
                                                    <div > <h5 style="color:darkgray;"> Note: If you are facing login issue, then please clear browser cache and open application once again, or open application in incognito window. </h5></div>  
                                                </ng-container> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="d-flex justify-content-center bg-light p-2">
                        <div class="small align-self-center">Copyright © 2022 Capgemini All rights
                            reserved.
                        </div>

                    </div>
                </div>

            </div>


        </div>

    </div>

</ng-container>

<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>


<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
        (selected)="setNotifyRes($event) " (close)="closeNotifybox() "></notification>
</div>