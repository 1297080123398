<!-- Dashboard Component -->
<ng-container *ngIf="selectedView == 'dashboard'">
  <dashboard [LayoutData]="DashboardData"></dashboard>
</ng-container>

<ng-container *ngIf="selectedView == 'search' && !UpdateInitiateCaseflag">
  <nav aria-label="breadcrumb" class="breadcrumbSection">
    <div class="d-flex w-100 justify-content-between">
      <ul class="breadcrumb m-0 flex-fill">
        <!-- <li class="breadcrumb-item"><a href="index.html">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Search</li> -->
      </ul>
      <div class="align-self-center breadcrumb m-0">
        <div class="px-2">
          <span class="text-success me-1"><i class="fa fa-circle" aria-hidden="true"></i></span>
          Positive
        </div>
        <div class="px-2">
          <span class="text-warning me-1"><i class="fa fa-circle" aria-hidden="true"></i></span>
          Minor Discrepancy
        </div>
        <div class="px-2">
          <span class="text-danger me-1"><i class="fa fa-circle" aria-hidden="true"></i></span>Discrepancy
        </div>
      </div>
    </div>
  </nav>
  <div class="contentScroll p-2">
    <ng-container *ngIf="(SearchTemplate != null && SearchTemplate != undefined && SearchTemplate.length > 0)">
      <searchby [SearchButtons]="SearchButtons" [SearchTemplate]="SearchTemplate" [FormConfig]="FormConfig"
        [GlobalConfig]="GlobalConfig" [FormProp]="FormProp" (SearchData)="SearchData($event,false,true)"
        class="customForm">
      </searchby>


    </ng-container>

    <div class="contentScroll p-2 simpleCard">
      <div class="card h-100 m-0">
        <div class="card-header"
          *ngIf="(searchEvent.item !=null && searchEvent.item != undefined && 
              searchEvent.item.Value !=null && searchEvent.item.Value != undefined && searchEvent.item.Value.length > 0)">
          <div class="d-flex w-100 justify-content-between">
            <h3>
              <span>Search Results:
                <span class="text-primary ps-2">{{dataTotalCount}} Records Found</span></span>
            </h3>
          </div>
          <!-- <h3>
            <span>Search Results:
              <span class="text-primary ps-2">{{dataTotalCount}} Records Found</span></span>
          </h3> -->
        </div>
        <div class="card-body overflow-auto">
          <!-- <div class="contentScroll p-3"> -->
          <ng-container *ngIf="(reqListTemplate != null && reqListTemplate != undefined && reqListTemplate.length > 0)">
            <grid-dispctrl #overallgrid [FieldTemplate]="reqListTemplate" [GridData]="reqListData" [GridProp]="GridProp"
              [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)" (FieldChange)="onChange($event)"
              (ActionBarClick)="actButtonClick($event)" [cellLock]="setCellLock" [parent]="this"
              (PageChange)="onPageChange($event)">
            </grid-dispctrl>
          </ng-container>
          <!-- </div> -->

          <div class="filters buttonHolder">
            <div class="d-flex justify-content-between ">
              <div></div>
              <div class="addHolidaypage-btn">
                <ng-container
                  *ngIf="CaseInitiateButtons != null && CaseInitiateButtons != undefined && CaseInitiateButtons.length > 0">

                  <ng-container *ngFor="let actionBar of CaseInitiateButtons  | filterBy: {IsApplicable: true,HasPermission :true}
                        | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">

                    <button type="button" [ngClass]="actionBar.CustomClass" (click)="btnClick(actionBar)"
                      [disabled]="!actionBar.IsEnabled"
                      value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName |
                      translate
                      }}
                      <span *ngIf="actionBar.ShowArrow" class="ps-3"><i class="ion ion-md-arrow-forward"
                          aria-hidden="true"></i></span>
                    </button>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-center">
            <div class="small align-self-center">Copyright © 2022 Capgemini All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>



  </div>
</ng-container>


<ng-container *ngIf="selectedView == 'closedgreen'">
  <nav aria-label="breadcrumb" class="breadcrumbSection">
    <div class="d-flex w-100 justify-content-between">
      <ul class="breadcrumb m-0 flex-fill">
        <!-- <li class="breadcrumb-item"><a href="index.html">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Search</li> -->
      </ul>
      <div class="align-self-center breadcrumb m-0">
        <div class="px-2">
          <span class="text-success me-1"><i class="fa fa-circle" aria-hidden="true"></i></span>
          Positive
        </div>
        <div class="px-2">
          <span class="text-warning me-1"><i class="fa fa-circle" aria-hidden="true"></i></span>
          Minor Discrepancy
        </div>
        <div class="px-2">
          <span class="text-danger me-1"><i class="fa fa-circle" aria-hidden="true"></i></span>Discrepancy
        </div>
      </div>
    </div>
  </nav>
  <div class="contentScroll p-2">
    <ng-container *ngIf="(SearchTemplate != null && SearchTemplate != undefined && SearchTemplate.length > 0)">
      <searchby [SearchButtons]="SearchButtons" [SearchTemplate]="SearchTemplate" [FormConfig]="FormConfig"
        [GlobalConfig]="GlobalConfig" [FormProp]="FormProp" (SearchData)="SearchData($event,false,true)"
        class="customForm">
      </searchby>


    </ng-container>

    <div class="contentScroll p-2 simpleCard">
      <div class="card h-100 m-0">
        <div class="card-header"
          *ngIf="(searchEvent.item !=null && searchEvent.item != undefined && 
              searchEvent.item.Value !=null && searchEvent.item.Value != undefined && searchEvent.item.Value.length > 0)">
          <div class="d-flex w-100 justify-content-between">
            <h3>
              <span>Search Results:
                <span class="text-primary ps-2">{{dataTotalCount}} Records Found</span></span>
            </h3>
          </div>

        </div>
        <div class="card-body overflow-auto">
          <ng-container *ngIf="(reqListTemplate != null && reqListTemplate != undefined && reqListTemplate.length > 0)">
            <grid-dispctrl #overallgrid [FieldTemplate]="reqListTemplate" [GridData]="reqListData" [GridProp]="GridProp"
              [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)" (FieldChange)="onChange($event)"
              (ActionBarClick)="actButtonClick_ClosedGreen($event)" [cellLock]="setCellLock" [parent]="this"
              (PageChange)="onPageChange_ClosedGreen($event)">
            </grid-dispctrl>
          </ng-container>
          
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-center">
            <div class="small align-self-center">Copyright © 2022 Capgemini All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>



  </div>
</ng-container>

<!-- Loader section -->
<div [hidden]="!IsLoading">
  <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>

<!-- Filter Pop-up -->
<div *ngIf="DispFilter">
  <filter *ngIf="(reqListTemplate!= undefined && reqListTemplate!= null && reqListTemplate.length > 0)"
    [FieldTemplate]="reqListTemplate" [GridData]="reqListDataALL" (close)="showFilter($event)"
    (selected)="setFilter($event)" [InitialCondn]="curFiltCondn">
  </filter>
</div>


<!-- Excel Download -->
<excel-export #excelexport [FileName]="ExcelFileName"></excel-export>


<!-- Notification section -->
<div *ngIf="displayNotifybox">
  <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
    (selected)="setNotifyRes($event)" (close)="closeNotifybox()"></notification>
</div>

<!-- Info modal popup instead of div ng container id="candidateInfo"-->
<modal-popup id="candidateInfo" style="display: none;" [modaldetail]="{'header':'More Info'}">
  <ng-container *ngIf="(infoListTemplate !=null && infoListTemplate !=undefined && infoListTemplate.length>0
    && selectedInfoData != null && selectedInfoData !=undefined)">
    <!-- <div class="gridContainer">
      <div class="table-responsive">
        <table class="table table-hover table-bordered tblGrid fixed">
          <thead>
            <th scope="col">Search</th>
            <th scope="col">Value</th>
          </thead>
          <tbody>
            <ng-container *ngFor="let infoData of infoListTemplate ; let i = index">
              <tr>
                <td>{{infoData.DisplayName | translate}}</td>
                <td>{{selectedInfoData[infoData.FieldName] | translate}}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div> -->
    <customform #infoform [FormTemplate]="infoListTemplate" [FormData]="selectedInfoData" [FormProp]="FormProp"
      [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)"
      (FieldChange)="onChange($event)">
    </customform>


  </ng-container>
</modal-popup>

<!-- Update Initiate Case -->
<!-- <div *ngIf="UpdateInitiateCaseflag">
  <initiate-case
    [CaseData]="initiateFormData" [CaseUpdateFlag]="true" (close)="closeInitiate()">
  </initiate-case>
</div> -->
