
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FilterPipeModule } from 'ngx-filter-pipe';

import { CustomPipeModule } from '../custompipe/custompipe.module';
import { NotificationModule } from '../notification/notification.module';
import { ActionBarComponent } from './actionbar.component';
import { environment } from '../../environments/environment';


@NgModule({
  declarations: [
    ActionBarComponent
  ],
  imports: [
    BrowserModule, FormsModule, NotificationModule, TooltipModule,
    TranslateModule.forRoot(),
    CustomPipeModule, FilterPipeModule
  ],
  providers: [],
  bootstrap: [ActionBarComponent],
  exports: [ActionBarComponent]
})

export class ActionBarModule { }
