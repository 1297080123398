import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ProcessResponse } from '../shared/_classes/utility.interface';


@Injectable()
export class TileService {

    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    private APIUrl: string;
    private CommAPIUrl: string;

    constructor(private _http: HttpClient) {
        this.APIUrl = environment.baseURL + '/Home/';
        this.CommAPIUrl = environment.baseURL + '/';
    }

    private handleError(error: any): Promise<any> {
        console.error('Error : ', error.message);
        return Promise.reject(error.message || error);
    }

    GetTileData(reqdata : any,url : any): Observable < ProcessResponse < any >> {
        let Params = new HttpParams();
        return this._http.get<ProcessResponse<any>>(this.CommAPIUrl + url,
            { headers: this.headers, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }

}
