<div class="h-100">
    <div class="p-2 contentScroll simpleCard">
        <!--Update Over All Start From Here -->
        <ng-container *ngIf="!openQCSection">
            <div class="card h-100 m-0">
                <div class="card-header">
                    <h3>
                        <span>{{SelectedTabName | translate}}</span>
                    </h3>
                </div>
                <div class="card-body overflow-auto">
                    <ng-container
                        *ngIf="QCProperties.QCSection!=null && QCProperties.QCSection!=undefined && QCProperties.QCSection.length > 0">
                        <ng-container *ngFor="let qcSection of (QCProperties.QCSection | filterBy: {CanShow: true})">

                            <ng-container *ngIf="RequestData.RequestType === 'RT003'">
                                <p class="text-bold mb-1">
                                    <span>{{qcSection.ProjectDisplayName |
                                        translate}}</span>
                                </p>
                            </ng-container>

                            <ng-container *ngIf="RequestData.RequestType !== 'RT003'">
                                <p class="text-bold mb-1">
                                    <span>{{qcSection.StandardDisplayName |
                                        translate}}</span>
                                </p>
                            </ng-container>

                            <div class="gridAction">
                                <div class="d-flex justify-content-between">
                                    <ng-container *ngIf="QCProperties.ActionButtons!=null && QCProperties.ActionButtons!=undefined && QCProperties.ActionButtons.length > 0
                                                            && (QCProperties.ActionButtons | filterBy: {IsApplicable: true,HasPermission :true,Group:qcSection.Name}
                                                            | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}).length >0
                                                    ">
                                        <div class="col-auto flex-fill">
                                            <actionbar
                                                [actionList]="(QCProperties.ActionButtons | filterBy: {IsApplicable: true,HasPermission :true,Group:qcSection.Name}
                                                                    | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'})"
                                                (butClick)="actButtonClick($event)" [noActionClass]="true">
                                            </actionbar>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>

                            <!-- <div class="gridContainer mb-3"> -->
                            <div class="table-responsive">
                                <ng-container
                                    *ngIf="QCTemplate!=null && QCTemplate!=undefined && QCTemplate.length > 0">
                                    <grid-dispctrl #qcmaingrid [GridId]="'QC_'+qcSection.Name+'_'"
                                        [FieldTemplate]="QCTemplate | filterBy: {Group: qcSection.Name}"
                                        [GridProp]="GridProp" [GridData]="QCData | filterBy: {Phase: qcSection.Phase}"
                                        [GridDataTotalCount]="(QCData | filterBy: {Phase: qcSection.Phase}).length"
                                        [cellLock]="setCellLock" [parent]="this" (FieldClick)="onClick($event)"
                                        (FieldChange)="onChange($event, qcSection.Name)">
                                    </grid-dispctrl>
                                </ng-container>
                            </div>
                            <!-- </div> -->
                            <br />
                            <br />
                        </ng-container>
                    </ng-container>

                    <button style="float: right;" type="button" class="rounded-pill btnCustom btnPrimary" (click)="showOverallStatus()">Show/Hide Overall Status
                        <span><i class="ion ion-md-arrow-forward" aria-hidden="true"></i></span>
                    </button>


                    <br><br>
                    
                    <ng-container
                        *ngIf="QCProperties.AdditionalSection!=null && QCProperties.AdditionalSection!=undefined && QCProperties.AdditionalSection.length > 0 && isAdditonalSectionClicked">
                        <ng-container
                            *ngFor="let additionalSection of (QCProperties.AdditionalSection | filterBy: {CanShow: true})">
                            <p class="text-bold mb-1">
                                <span>{{additionalSection.DisplayName |
                                    translate}}</span>
                            </p>
                            <!-- <div class="gridContainer mb-3"> -->
                            <div class="table-responsive">
                                <ng-container
                                    *ngIf="QCTemplate!=null && QCTemplate!=undefined && QCTemplate.length > 0">
                                    <grid-dispctrl #updateStatusgrid
                                        [FieldTemplate]="QCTemplate | filterBy: {Group: additionalSection.Name}"
                                        [GridProp]="GridProp" [GridData]="QCAdditionalSectionData"
                                        [GridDataTotalCount]="QCAdditionalSectionData.length" [cellLock]="setCellLock"
                                        [parent]="this" (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                    </grid-dispctrl>
                                </ng-container>
                            </div>
                            <!-- </div> -->
                            <br />
                            <br />
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <!--Update Over All End Here -->

        <!--Section Submission Start From Here -->
        <ng-container *ngIf="openQCSection">
            <div class="card h-100 m-0">
                <div class="card-header">
                    <h3>
                        <span>{{sectionDisplayName | translate}}</span>
                    </h3>
                </div>
                <div class="card-body overflow-auto">
                    <div class="gridContainer mb-3">
                        <div class="table-responsive">
                            <ng-container *ngIf="QCTemplate!=null && QCTemplate!=undefined && QCTemplate.length > 0">
                                <grid-dispctrl #additionalsectiongrid
                                    [FieldTemplate]="QCTemplate | filterBy: {Group: sectionName}" [GridProp]="GridProp"
                                    [GridData]="sectionData" [GridDataTotalCount]="sectionData.length"
                                    (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                </grid-dispctrl>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <!--Section Submission End Here -->
    </div>

    <div class="filters buttonHolder">
        <div class="d-flex justify-content-between ">
            <div></div>
            <div>
                <ng-container
                    *ngIf="QCProperties.ActionButtons != null && QCProperties.ActionButtons != undefined && !IsReadonly">

                    <!--QC Main Screen buttons-->
                    <ng-container *ngIf="!openQCSection">
                        <ng-container *ngFor="let actionBar of (QCProperties.ActionButtons | filterBy: {IsApplicable: true,HasPermission :true,Group:'QCStatus'}
                            | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">

                            <button type="button" [ngClass]="actionBar.CustomClass"
                                (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName |
                                translate
                                }}
                                <span *ngIf="actionBar.ShowArrow" class="ps-3"><i class="ion ion-md-arrow-forward"
                                        aria-hidden="true"></i></span>
                            </button>
                        </ng-container>
                    </ng-container>

                    <!--QC Additional Screen buttons-->
                    <ng-container *ngIf="openQCSection">
                        <ng-container *ngFor="let actionBar of (QCProperties.ActionButtons | filterBy: {IsApplicable: true,HasPermission :true,Group:sectionName}
                            | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">

                            <button type="button" [ngClass]="actionBar.CustomClass"
                                (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName |
                                translate
                                }}
                                <span *ngIf="actionBar.ShowArrow" class="ps-3"><i class="ion ion-md-arrow-forward"
                                        aria-hidden="true"></i></span>
                            </button>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>

</div>

<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [curitem]="notify.item"
        (selected)="setNotifyRes($event) " (close)="closeNotifybox() "></notification>
</div>

<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>

<!--Attachment section-->
<ng-container *ngIf="DisplayAttachment==true">
    <file-upload [Module]="'StopCase'" [RefId1]="RequestId" [IsMultiple]="false" (close)="closeAttachment($event)"
        [Permissions]="AttachPerm" [UploadType]="QCProperties.UploadFileType" [CandidateId]="RequestData.CandidateId">
    </file-upload>
</ng-container>

<modal-popup id="ReinitiateCaseQC" style="display: none;" [modaldetail]="{'header':'Reinitiate Case'}">

    <ng-container *ngIf="viewReinitiateCase" style="width:100%;">
        <div class="modal-body" style="width:100%">
          <div  class="card-body overflow-auto" style="width:100%;">
            <customform #ReinitiateCaseformQC style="width:100%;"
                [FormTemplate]="ReinitiateCaseTemplate" [FormData]="ReinitiateCaseData" [FormProp]="FormProp"
                [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
            </customform>
             </div>
        </div>
    </ng-container>

    <div class="">
        <div class="d-flex justify-content-end p-3">
            <button type="button" class="rounded-pill btnCustom btnPrimary"
                (click)="reinitiateCase()" value="{{'Submit' | translate }}">
                {{'Submit' | translate }}
            </button>
        </div>
    </div>

</modal-popup>