
import { Component, Input, OnInit, ElementRef, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { BGVStatus, InitiateStatus, ProcessStatus } from 'src/app/app.interface';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined } from 'src/app/shared/app.sharedservice';
import { ModalService } from 'src/modules/modal/modal.service';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { RequestService } from '../request.service';

@Component({
    selector: 'status-summary',
    templateUrl: './status-summary.component.html',

})
@AutoUnsubscribe
export class StatusSummaryComponent implements OnInit {
    @Input() RequestId: number = 0;
    @Input() RequestData: any;
    @Input() DashBoardCheckData: any[] = [];
    @Input() RequestProperty: any = [];
    @Input() RequestPermissions: any = [];

    @Output() CheckClick = new EventEmitter<any>();
    @Output() LeftmenuClick = new EventEmitter<any>();

    public subscriptionList$: Subscription[] = [];

    public IsLoading: boolean = false;
    public IsReadonly: boolean = false;
    public AcknowledgeSelected: boolean = false;
    public disbaleAcknowledgeSelected: boolean = true;

    public showOnBoardingflag: boolean = true;
    public editOnBoardingflag: boolean = true;
    public onBoardingSelected: boolean = true;

    public ValidationMessageData: any[] = [];

    public displayNotifybox: boolean = false;
    public notify: NotificationData = new NotificationData();

    constructor(private _appSharedService: AppSharedService, private _requestService: RequestService, private modalService: ModalService) {
        let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
            this.ngOnInit();
        });
        this.subscriptionList$.push(subscribe$);
    }

    ngOnInit(): void {

        document.addEventListener('contextmenu', event => event.preventDefault());

        document.onkeydown = function (event) {
           // disable F12 key
            if(event.key == 'F12') {
                return false;
            }
            return true;
        }
        if (!isNullOrUndefined(this._appSharedService.currentRoleData)) {
            if (this.RequestData.IsStopCaseSubmitted === true || this.RequestData.IsStopCase === true) {
                this.IsReadonly = true;
            }
            
            let applicableItems=this.DashBoardCheckData.filter(x=>x.IsApplicable===true);
            let count=this.DashBoardCheckData.length;
            let i=0;
            if(applicableItems.length===1 && (applicableItems[0].CheckId===8 || applicableItems[0].CheckId===4 || applicableItems[0].CheckId===6) )
            {
                this.disbaleAcknowledgeSelected=applicableItems[0].OverAllRequestDocumentStatusColorCode==='SC001'?false :true;
            }
            else {
                while(i<count)
                {
                    if(this.DashBoardCheckData[i].InitiateStatus!=='I003')
                    {
                        this.disbaleAcknowledgeSelected = this.DashBoardCheckData[i].OverAllRequestDocumentStatusColorCode === 'SC001' ? false : true;
                    
                        if(this.disbaleAcknowledgeSelected)
                        {
                            break;                 
                        }
                        
                    }
                    i++;
                }
            }
           
            this.setActionButtonPermissions();

            this.showOnBoardingflag = false;
            if (!isNullOrUndefined(this.RequestPermissions) && this.RequestPermissions.length > 0
                && !isNullOrUndefined(this.RequestPermissions.filter((x: any) => x.toUpperCase() === 'SHOWREADYTOONBOARD'))
                && this.RequestPermissions.filter((x: any) => x.toUpperCase() === 'SHOWREADYTOONBOARD').length > 0) {
                this.showOnBoardingflag = true;
            }

            this.editOnBoardingflag = true;
            if (!isNullOrUndefined(this.RequestPermissions) && this.RequestPermissions.length > 0
                && !isNullOrUndefined(this.RequestPermissions.filter((x: any) => x.toUpperCase() === 'EDITREADYTOONBOARD'))
                && this.RequestPermissions.filter((x: any) => x.toUpperCase() === 'EDITREADYTOONBOARD').length > 0) {
                this.editOnBoardingflag = false;
            }

            this.onBoardingSelected = !isNullOrUndefined(this.RequestData.ReadyToOnBoard) && this.RequestData.ReadyToOnBoard !== ''
                && this.RequestData.ReadyToOnBoard === true ? true : false;
        }

    }

    actionButtonClick(data: any) {

        if (!isNullOrUndefined(data.Name) && data.Name !== '') {
            switch (data.Name.toUpperCase()) {
                //Submit Case
                case 'DOCUMENTSUBMIT':
                    if (this.AcknowledgeSelected === true) {
                        this.showNotificationMessage('Are you sure you want to submit the information for background verification?', 1, 'SUBMIT', data.ActionCode, 'Submit', 'Cancel');
                    } else {
                        this.showNotificationMessage('Please select acknowledge all the information provided.', 2, '', null, '', '');
                    }

                    break;
            }
        }
    }

    showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string) {
        let headerIcon: string = '';

        if (successFlag === 1) {
            headerIcon = 'fa fa-check-circle text-success';
        } else if (successFlag === 2) {
            headerIcon = 'fa fa-exclamation-circle text-warning';
        } else if (successFlag === 3) {
            headerIcon = 'fa fa-exclamation-triangle text-danger';
        } else {
            headerIcon = 'fa fa-info-circle text-info';
        }

        this.notify = {
            info: {
                header: 'Confirmation Alert',
                message: msg,
                yes: yes,
                no: no,

                IsHeaderHidden: true,
                HeaderIcon: headerIcon,
                IsCrossEnable: true,
                SizeClass: 'modal-sm'
            },

            action: action,
            item: item
        };

        this.displayNotifybox = true;
    }

    setNotifyRes(event: any): void {
        console.log('notify res >> ' + JSON.stringify(event));
        let canClose: boolean = true;
        switch (event.action.toUpperCase()) {
            case 'SUBMIT':
                if (event.result.toUpperCase() === 'SUBMIT') {
                    this.SubmitChecks();
                    // this.requestStopCase();
                }
                break;
            case 'READYTOONBOARD':
                if (event.result.toUpperCase() === 'OK') {
                    this.updateReadyToOnboard();
                } else if (event.result.toUpperCase() === 'CANCEL') {
                    this.onBoardingSelected = this.onBoardingSelected === false ? true : false;
                }
                break;
        }
        if (canClose) {
            this.closeNotifybox();
        }
    }

    closeNotifybox(): void {
        this.displayNotifybox = false;
    }

    checkClick(data: any): void {
        if ((this.RequestData.IsCreateMode === true && data.InitiateStatus === InitiateStatus.Yet_To_Initiate && !isNullOrUndefined(data.TotalCount) && data.TotalCount > 0) ||
            (this.RequestData.IsCreateMode === false
                && data.IsInsufficiencyLink === true
                // && (data.CheckStatus === BGVStatus.L1_Insufficiency_Mandate_documents
                //     || data.CheckStatus === BGVStatus.L2_Insufficiency_Additional_details_or_documents
                //     || data.CheckStatus === BGVStatus.L3_Insufficiency_Additional_details_or_documents
                //     || data.CheckStatus === BGVStatus.Interim_cleared_IR__Highest_education_pending
                //     || data.CheckStatus === BGVStatus.Interim_cleared_IR__Final_Semester_Documents_pending
                //     || data.CheckStatus === BGVStatus.Interim_cleared_IR_P_Emp__Insufficiency
                //     || data.CheckStatus === BGVStatus.Prev_Employment__InProgress
                //     || data.CheckStatus === BGVStatus.Highest_Education__InProgress
                //     || data.CheckStatus === BGVStatus.Final_Semester__InProgress)
            )
        ) {
            this.CheckClick.emit({ 'checkId': data.CheckId, 'requestId': data.RequestId });
        }
        if ((this.RequestData.IsCreateMode === true && data.InitiateStatus === InitiateStatus.Yet_To_Initiate && (data.CheckId===4 || data.CheckId===8 || data.CheckId===6)) ||
        (this.RequestData.IsCreateMode === false
            && data.IsInsufficiencyLink === true
            
        )
        ) {
        this.CheckClick.emit({ 'checkId': data.CheckId, 'requestId': data.RequestId });
        }

    }

    onacknowledgeChange(): void {
        this.setActionButtonPermissions();
    }

    setActionButtonPermissions(): void {
        if (!isNullOrUndefined(this.RequestProperty.ActionButtons) && this.RequestProperty.ActionButtons.length > 0) {

            this.RequestProperty.ActionButtons.forEach((item: any) => {
                if (!isNullOrUndefined(item.Name) && item.Name !== '') {
                    item.HasPermission = false;
                    item.IsApplicable = false;

                    let peritem = this.RequestPermissions.indexOf(item.Name);
                    if (peritem >= 0) {
                        item.HasPermission = true;
                    }

                    switch (item.Name.toUpperCase()) {
                        case 'DOCUMENTSUBMIT':
                            item.IsApplicable = true;
                            item.IsEnabled = this.AcknowledgeSelected === true ? true : false;
                            break;
                    }
                }
            });
        }
    }

    SubmitChecks() {
        this.IsLoading = true;

        this.ValidationMessageData = [];

        let subscribe$: Subscription = this._requestService.SubmitChecks(this.RequestId).subscribe(result => {
            if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

                if (!isNullOrUndefined(result.Data) && result.Data.length > 0
                    && result.Data.filter((x: any) => !isNullOrUndefined(x.ValidationStaus) && x.ValidationStaus === false).length > 0) {

                    this.ValidationMessageData = result.Data.filter((x: any) => !isNullOrUndefined(x.ValidationStaus) && x.ValidationStaus === false);
                    this.openModal('ValidationPopup');
                    // this.IsLoading = false;
                } else {
                    this.showNotificationMessage('Case updated successfully', 1, '', null, '', '');
                    this.clickbacktodashboard();
                }

            }

            this.IsLoading = false;
        },

            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }
    clickbacktodashboard() {
        let sidebarData: any = null;
        if (!isNullOrUndefined(this.RequestProperty) && !isNullOrUndefined(this.RequestProperty.LeftPaneMenu) && this.RequestProperty.LeftPaneMenu.length > 0
            && this.RequestProperty.LeftPaneMenu.filter((x: any) => x.Name === 'StatusSummary' && x.CanShow === true && x.HasPermission === true).length > 0) {
            sidebarData = this.RequestProperty.LeftPaneMenu.filter((x: any) => x.Name === 'StatusSummary' && x.CanShow === true && x.HasPermission === true)[0]
        }
        if (!isNullOrUndefined(sidebarData) && sidebarData !== '') {
            this.LeftmenuClick.emit({ 'sideBarData': sidebarData });
        }
    }

    changeReadytoOnboard(): void {
        this.showNotificationMessage('Are you sure you want to change the On boarding?', 1, 'ReadyToOnBoard', null, 'Ok', 'Cancel');
    }
    updateReadyToOnboard() {
        this.IsLoading = true;
        let odata: any = {
            RequestId: this.RequestId,
            ReadyToOnboard: this.onBoardingSelected
        };
        let subscribe$: Subscription = this._requestService.updateReadyToOnboard(odata).subscribe(result => {
            if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
                this.showNotificationMessage('Good to go for Onboarding changed successfully.', 1, '', null, '', '');
            }
            this.IsLoading = false;
        },
            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);

    }

    openModal(id: string) {
        this.modalService.open(id);
        document.getElementById('txtvalue')?.focus();
    }

    closeModal(id: string) {
        this.modalService.close(id);
    }
}
