import { AuthConfig } from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {

  // Url of the Identity Provider
  issuer: 'https://signin.capgemini.com',
  
  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/#/callback',

  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: 'bgvprod',

  dummyClientSecret: 'bgvprod@123$',

  scope: 'openid profile email',

  responseType: 'code',

  requestAccessToken: true,
  tokenEndpoint: 'https://signin.capgemini.com/as/token.oauth2',
  userinfoEndpoint: 'https://signin.capgemini.com/idp/userinfo.openid',

  showDebugInformation: true,

}
