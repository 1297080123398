
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { AngularDraggableModule } from 'angular2-draggable';
import { ToastrModule } from 'ngx-toastr';
import { NotificationComponent } from './notification.component';


@NgModule({
  declarations: [NotificationComponent],
  imports: [FormsModule, ToastrModule, BrowserModule, AngularDraggableModule,
    TranslateModule.forRoot()],
  providers: [],
  bootstrap: [NotificationComponent],
  exports: [NotificationComponent]
})

export class NotificationModule { }
