<ng-container *ngIf="(notifyinfo != null && notifyinfo !=undefined)">
<!-- Modal section -->
<div id="notificationDialog" class="modal" tabindex="-1" role="dialog" style="display:block;" #dragboundary>
  <div class="modal-dialog modal-dialog-centered" [class]="notifyinfo.SizeClass" role="alert">
    <!-- Modal content -->
    <div class="ng-draggable modal-content" ngDraggable [inBounds]="true" [bounds]="dragboundary" [handle]="draghandle">
      <!-- Modal header -->
      <div class="drag-block-handle" #draghandle>
        <div class="modal-header" *ngIf="!(notifyinfo.IsHeaderHidden)">
          <h3 class="modal-title">{{notifyinfo.header | translate}}</h3>

          <button *ngIf="notifyinfo.IsCrossEnable==true" type="button" class="close" data-bs-dismiss="modal"
            aria-label="Close" (click)="closeNotifybox()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <ng-container *ngIf="notifyinfo.IsHeaderHidden">
          <!-- *ngIf="notifyinfo.HeaderIcon.length>0" -->
          <!-- <div>
            <div class="d-inline-flex p-2 bd-highlight">
              <div class="d-flex justify-content-center">
                <i [class]="notifyinfo.HeaderIcon" style="font-size: x-large;" aria-hidden="true"></i>
              </div>
              <div class="d-flex justify-content-end">
                <!-'- <button *ngIf="notifyinfo.IsCrossEnable==true" type="button" class="close" data-bs-dismiss="modal"
                aria-label="Close" (click)="closeNotifybox()">
                <span aria-hidden="true">&times;</span></button> -'->
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          </div> -->

          <div class="pt-2 px-1">
            <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
              <i aria-hidden="true" class=""></i>
              <!-- style="font-size: x-large;" -->
              <i aria-hidden="true" style="font-size: 30px;" [class]="notifyinfo.HeaderIcon"></i>
              <i *ngIf="notifyinfo.IsCrossEnable==true" data-bs-dismiss="modal" aria-hidden="true" class="fa fa-times"
                (click)="closeNotifybox()"></i>
            </div>
          </div>
        </ng-container>

      </div>

      <!-- Modal body -->
      <div class="modal-body" style="background-color: #fff;">
        <div class="form-group m-0" style="text-align: center;">
          <!-- <label>{{notifyinfo.message | translate}}</label> -->
          <label [innerHTML]="(notifyinfo.message | translate)"></label>
          <ng-container *ngIf="notifyinfo.IsRemarksApplicable==true">
            <textarea [(ngModel)]="notifyinfo.Remarks" class="form-control" cols="50" rows="6"
              maxlength="500"></textarea>
          </ng-container>
        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" *ngIf="notifyinfo.yes!=null && notifyinfo.yes!=undefined && notifyinfo.yes!=''">
        <label class="text-error" for="footer"
          *ngIf="notifyinfo.NoteMessage!=null && notifyinfo.NoteMessage!=undefined">{{notifyinfo.NoteMessage |
          translate}}</label>
        <input id ="footer" type="button" *ngIf="notifyinfo.no!=null && notifyinfo.no!=undefined && notifyinfo.no!=''"
          [ngClass]="['rounded-pill','btnCustom', (notifyinfo.cssno!=null && notifyinfo.cssno!=undefined && notifyinfo.cssno.length > 0 ? notifyinfo.cssno : 'btnNegative')]"
          data-bs-dismiss="modal" name="btnNo" value="{{notifyinfo.no}}" (click)="btnNoClick(notifyinfo.no)" />
        <input id="custBt" type="button" *ngIf="notifyinfo.yes!=null && notifyinfo.yes!=undefined && notifyinfo.yes!=''"
          [ngClass]="['rounded-pill','btnCustom', (notifyinfo.cssyes!=null && notifyinfo.cssyes!=undefined && notifyinfo.cssyes.length > 0 ? notifyinfo.cssyes : 'btnPrimary')]"
          data-bs-dismiss="modal" name="btnYes" value="{{notifyinfo.yes}}" (click)="btnYesClick(notifyinfo.yes)" />
          <label class="sr-only" for="custBt"
         >{{notifyinfo.NoteMessage |
          translate}}</label>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- /.modal !-->

</ng-container>