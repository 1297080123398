import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'src/app/shared/app.sharedservice';
import { FormComponent } from 'src/modules/form/form.component';
import { FieldTemplate, FormConfig, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'searchby',
    templateUrl: './searchby.component.html',
})

@AutoUnsubscribe
export class SearchByComponent implements OnInit {
    @Input() SearchTemplate: FieldTemplate[] = [];
    @Input() SearchButtons: any = [];
    @Input() FormConfig: FormConfig = {};
    @Input() GlobalConfig: any = {};
    @Input() FormProp: FormProperty = {};


    @ViewChild('searchform', { static: false }) searchform!: FormComponent;

    @Output() SearchData = new EventEmitter<any>();

    public IsLoading: boolean = false;
    public searchData: any = {};

    public DataInput: any = { PageIndex: 0, PageSize: 0 };

    public subscriptionList$: Subscription[] = [];


    constructor(private _router: Router) {

    }

    ngOnInit() {
        // console.log(this.FormConfig);

        this.initSearchData();

        console.log(this.searchData);

    }

    initSearchData(): void {
        this.searchData = {};

        this.searchData['IsEditable'] = 1;

        this.SearchTemplate.forEach((item: any) => {
            // if (item.Control.Type === "dropdown") {
            //     this.searchData[item.FieldName] = 'ALL';
            //     this.searchData[item.LinkedField] = 'ALL';
            // } else {
            //     this.searchData[item.FieldName] = '';
            // }

            this.searchData[item.FieldName] = null;
        });

    }

    onClick(event: any): void {
        let selitem: any = event.item;

        console.log(selitem);

        if (event.fieldname.toUpperCase() === 'SEARCH') {
        }
    }

    onChange(event: any): void {
        let selitem: any = event.item;
    }

    onPageChange(event: any) {
        this.DataInput.PageIndex = event.PageIndex;
        this.DataInput.PageSize = event.PageSize;
    }

    btnClick(butt: string): void {

        this.SearchData.emit({ 'item': this.searchData, 'action': butt });

        if (butt.toUpperCase() === 'CLEAR') {
            this.initSearchData();
        }
    
    }

}
