import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { CustomPipeModule } from '../custompipe/custompipe.module';
// import { FieldControlModule } from '@dist/fieldcontrol-lib';
// import { FieldControlModule } from 'fieldcontrol-lib';
// import { ElioScopeService, ElioUtilityService } from 'fieldcontrol-lib';
import { FieldControlModule } from '../fieldcontrol/fieldcontrol.module';
import { FilterModule } from '../filter/filter.module';
import { ElioScopeService } from '../shared/_services/ElioScope.service';
import { UtilityService } from '../shared/_services/utility.service';
import { GridComponent } from './grid.component';
import { ActionBarModule } from 'src/modules/actionbar/actionbar.module';

@NgModule({
  imports: [
    ActionBarModule,
    CommonModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    FieldControlModule,
    FilterPipeModule,
    TooltipModule.forRoot(),
    TranslateModule.forRoot(),
    PaginationModule.forRoot(),
    FilterModule,
    AlertModule.forRoot(),
    CustomPipeModule
  ],
  declarations: [GridComponent],
  providers: [ElioScopeService, UtilityService],
  exports: [GridComponent]
})
export class GridModule { }

