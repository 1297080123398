 
<ng-container>
    <div class="h-100">
        <div class="contentScroll p-3 simpleCard" style="overflow: hidden !important;">
            <div class="card h-100 m-0">
                <div class="card-header">
                    <h3>
                        BU HR Upload
                    </h3>
                </div>
                <div class="bg-light p-3">
                    <p class="text-bold"></p>
                    <div  class="form-inline">
                    <ng-container>
                         <label class="form-label">Upload File</label>
                        
                          
                            <input class="form-control form-control-sm" id="inputfile" type="file" 
                                (change)="handleUpload($event)" accept="UploadType" />
                                
                                <label for="inputfile" class="sr-only">UploadType</label>
                       
                                <button type="button" class="btnCustom btnPrimary rounded-pill" (click)="uploadFiles()"
                                    value="{{'Upload' | translate }}">{{'Upload' | translate }}
                                </button>
                       
                                <!-- <a type="button" class="btn btn-success" (click)="downloadExcelfile()">Download File</a> -->


                                    <a href="javascript:this.blur();" class="actionList_a" aria-label="Download Excel" (click)="downloadExcelfile()">
                                                 <span><u>Download Sample Excel</u></span>
                                            </a>
                                         
                    </ng-container>
                </div>
            </div>
                <div class="card-body overflow-auto p-0">
                    <div class="contentScroll p-3">
                        <div *ngIf="SelectedTab == 'BUHRList'">
                            <div class="gridContainer">
                                <ng-container
                                    *ngIf="(roleListTemplate != null && roleListTemplate != undefined && roleListTemplate.length > 0)">

                                    <grid-dispctrl #overallgrid
                                        [FieldTemplate]="(roleListTemplate | filterBy:{Group: 'BUHRListResult'})"
                                        [GridData]="roleListData" [GridProp]="GridProp"
                                        [GridDataTotalCount]="dataTotalCount" 
                                        (ActionBarClick)="actButtonClick($event)">
                                    </grid-dispctrl>

                                </ng-container>
                            </div>
                        </div> 
                    </div>                 
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-center">
                        <div class="small align-self-center">
                            Copyright © 2022 Capgemini All rights reserved.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>

<!-- Filter Pop-up -->
<div *ngIf="DispFilter">
    <filter *ngIf="(roleListTemplate!= undefined && roleListTemplate!= null && roleListTemplate.length > 0)"
        [FieldTemplate]="(roleListTemplate | filterBy:{Group: 'BUHRListResult'})" [GridData]="roleListDataALL"
        (close)="showFilter($event)" (selected)="setFilter($event)" [InitialCondn]="curFiltCondn">
    </filter>
</div>

<!-- Excel Download -->
<excel-export #excelexport [FileName]="ExcelFileName"></excel-export>

 <!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify.info" [curaction]="notify.action" [curitem]="notify.item"
        (selected)="setNotifyRes($event)" (close)="closeNotifybox()"></notification>
</div>