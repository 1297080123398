export class MemberDetail {
    TransactionId: number = 0;
    AppId: string = '';
    ProcessId: string = '';
    TenantId: string = '';
    VendorId: number = 0;
    UserId: number = 0;
    Name: string = '';
    Gender: string = '';
    EmailId: string = '';
    ValidFromDate?:Date = new Date('2021-10-26 00:00:00.000');
    ValidTillDate?:Date = new Date('2099-12-31 00:00:00.000');
    IsVendorAdmin: boolean = false;
    IsVendorAdminDesc: string = '';
    IsEditable: number = 1;
    IsModified: number = 0;

    constructor(init?: Partial<MemberDetail>) {
        Object.assign(this, init);
    }
}
