import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingModule } from 'src/modules/loading/loading.module';
import { ExcelViewerComponent } from './excel-viewer.component';


@NgModule({
  declarations: [
    ExcelViewerComponent  
  ],
  imports: [
    BrowserModule,
    FormsModule,
    LoadingModule,
    TranslateModule.forRoot(),
  ],
  bootstrap: [ExcelViewerComponent],
  exports: [ExcelViewerComponent]
})
  
export class ExcelViewerModule { }

