import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { AppSharedService, AutoUnsubscribe, isDate, isEmail, isNullOrUndefined, isNumber, isStringNullOrEmpty } from '../../../shared/app.sharedservice';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ComponentCodes, ComponentConfig, ProcessStatus } from '../../../app.interface';
import { CommonAPIService } from '../../../shared/app.commonservice';
import { ExcelExportComponent } from 'src/modules/excelexport/excelexport.component';
import { asEnumerable } from 'linq-es2015';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { GridComponent } from 'src/modules/grid/grid.component';
import { AdminService } from '../../admin.service';
import { ModalComponent } from 'src/modules/modal/modal.component';
import { ModalService } from 'src/modules/modal/modal.service';
import { MemberDetail } from './manage-member.interface';
import { VendorDetail } from './manage-vendor.interface';

@Component({
  selector: 'app-vendor',
  templateUrl: './manage-vendor.component.html',
  styleUrls: ['./manage-vendor.component.css']
})

@AutoUnsubscribe
export class VendorComponent implements OnInit {

  @ViewChild('infoform', { static: false }) infoform!: ModalComponent;

  public FilterData: any = {};

  public GlobalConfig: any = {};

  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };

  public FormProp: FormProperty = {
    'ColSize': 2,
    'CaptionBold': false,
    'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };

  public venListTemplate: FieldTemplate[] = [];
  public venListData: any[] = [];
  public venListDataALL: any[] = [];
  public memListData: any[] = [];
  public memListDataALL: any[] = [];
  public editMemberData: MemberDetail = new MemberDetail();
  public vendorPermissions: any = [];
  public dataTotalCount: number = 0;
  public DataInput: object = { PageIndex: 0, PageSize: 0 };
  public selectedInfoData: any;
  public curItemsPerPage: number = 10;
  public Title: string = "";

  public VendorId: number = 0;
  public VendorName: string = "";
  public MemberId: number = 0;

  public editVendorData = new VendorDetail();

  public GridProp: any = {
    Editable: true,
    //TableCSS: 'auto',
    PaginationConfig: {
      ItemsPerPage: 10
    },
    ActionBarConfig: {
      ActionBarList: null
    }
  }
  public Config: any = {
    ActionButtons: [],
    ActionButtonsBottom: []
  };

  public IsLoading: boolean = false;
  public subscriptionList$: Subscription[] = [];
  public displayNotifybox: boolean = false;
  public notify: NotificationData = new NotificationData();
  public curFiltCondn: string = '';
  public DispFilter: boolean = false;
  public SelectedTab: string = "VendorList";
  // public DispLoader: boolean = false;

  public ExcelFileName: string = '';
  // public ExcelData: any = [];

  @ViewChild('excelexport', { static: false }) excelexport!: ExcelExportComponent;
  @ViewChild('overallgrid', { static: false }) overallgrid!: GridComponent;

  constructor(private _route: ActivatedRoute, private _router: Router, private _appSharedService: AppSharedService,
    private _commonAPIService: CommonAPIService, private adminService: AdminService, private modalService: ModalService) {

    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);
  }

  ngOnInit() {

    if (!isNullOrUndefined(this._appSharedService.currentRoleData)) {
      this.Config.ActionButtons = JSON.parse(`[{"Name":"ExportToExcel","Icon":"fa fa-file-excel-o","DisplayName":"Dow<u>n</u>load Excel","AccessKey":"N","IsApplicable":true,"HasPermission":true,"IsEnabled":true,"Confirmation":{"IsEnabled":false,"Message":null,"Action":null},"SeqOrder":1},{"Name":"Filter","Icon":"fa fa-filter","DisplayName":"F<u>i</u>lter","AccessKey":"I","IsApplicable":true,"HasPermission":true,"IsEnabled":true,"Confirmation":{"IsEnabled":false,"Message":null,"Action":null},"SeqOrder":2}]`);
      this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;
      this.Title = "Vendor Details";
      this.getVendorTemplate();
    }
  }

  getVendorTemplate(): void {
    this.IsLoading = true;

    this.venListTemplate = [];

    let odata: ComponentConfig = {
      Component: 'Admin', ComponentCode: ComponentCodes.AdminComponent
    };

    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {

        this.venListTemplate = res.Data.FieldTemplate!;

        this.setCompProperties(res.Data.Properties!);

        this.vendorPermissions = res.Data.Permission;

        this.VendorData();
      }
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getVendorTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }

  setCompProperties(Properties: any[]) {
    if (!isNullOrUndefined(Properties) && Properties.length > 0) {

      this.Config.GroupList = (Properties.filter(x => x.Name.toLowerCase() === 'grouplist'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'grouplist'.toLowerCase())[0].JSONData.toString()) : []);

      this.Config.ActionButtons = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase())[0].JSONData.toString()) : []);

      this.Config.ActionButtonsBottom = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtonsBottom'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtonsBottom'.toLowerCase())[0].JSONData.toString()) : []);

      this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;

      // Other component specific props
      Properties.forEach(prop => {
        // avoiding generic props
        if (!isNullOrUndefined(prop.Name) &&
          prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
          && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
          this.Config[prop.Name] = JSON.parse(prop.JSONData.toString());
        }
      });

    }
  }

  setActionButtonPermissions(): void {
    if (!isNullOrUndefined(this.Config.ActionButtonsBottom) && this.Config.ActionButtonsBottom.length > 0) {

      this.Config.ActionButtonsBottom.forEach((item: any) => {
        if (!isNullOrUndefined(item.Name) && item.Name !== '') {
          item.HasPermission = false;
          //item.IsApplicable = false;

          let peritem = this.vendorPermissions.indexOf(item.Name);
          if (peritem >= 0) {
            item.HasPermission = true;

            if (item.Name == "SaveAndManageVolume" && this.VendorId > 0) {
              item.HasPermission = false;
            }

            if (item.Name == "UpdateVendor" && this.VendorId == 0) {
              item.HasPermission = false;
            }
          }
        }
      });
    }
  }

  VendorData(): void {

    this.IsLoading = true;

    this.venListData = [];
    this.venListDataALL = [];
    this.dataTotalCount = 0;

    let subscribe$: Subscription = this.adminService.getVendorDetails().subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        this.venListData = result.Data;
        console.log(this.venListData);
        this.dataTotalCount = this.venListData.length;

        this.venListDataALL = this.venListData;

        this.venListData.forEach((item) => {
          // item.Action = item.RowStatus == "I" ? '2' : '0,1,3';
          item.Action = item.UserDef6;
          item.IsEditable = 1;
        });

        this.setActionButtonPermissions();

        setTimeout(() => {
          if (!isNullOrUndefined(this.overallgrid)) {
            this.overallgrid.Pagination.CurrentPage = 1;
          }
        }, 50);

      }
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
        //this._toastr.warning(result.Message, this.CompTitle);
      }

      this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);
  }

  DeleteVendor(deleteVendorData: any): void {

    this.IsLoading = true;

    let subscribe$: Subscription = this.adminService.deleteVendor(deleteVendorData).subscribe(result => {
      if (!isNullOrUndefined(result)) {

        this.showNotificationMessage
          (
            result.Message,
            result.Status === ProcessStatus.Success ? 1
              : result.Status === ProcessStatus.NoContent ? 2 : 3,
            '', null, '', ''
          );
      }
      this.IsLoading = false;
      if (result.Status === ProcessStatus.Success) {
        this.getVendorTemplate();
      }
    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);

  }

  GetVendorDataById(): void {

    this.IsLoading = true;

    this.editVendorData = new VendorDetail();

    let subscribe$: Subscription = this.adminService.getVendorDetailsById(this.VendorId).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        this.editVendorData = result.Data;

        this.editVendorData.EmailDomainName = this.editVendorData.EmailDomainName.lastIndexOf("@") >= 0 ? this.editVendorData.EmailDomainName.replace('@','')
        : '@' + this.editVendorData.EmailDomainName;

        this.editVendorData.IsEditable = 1;

        this.setActionButtonPermissions()

        console.log(this.editVendorData);
      }

      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
        //this._toastr.warning(result.Message, this.CompTitle);
      }

      this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);
  }

  SaveVendorEdit(action: any): void {


    if (this.validateSave()) {
      this.IsLoading = true;

      this.editVendorData.EmailDomainName = this.editVendorData.EmailDomainName.lastIndexOf("@") >= 0 ? this.editVendorData.EmailDomainName
        : '@' + this.editVendorData.EmailDomainName;

      let subscribe$: Subscription = this.adminService.updateVendorDetails(this.editVendorData).subscribe(result => {
        if (!isNullOrUndefined(result)) {


          this.showNotificationMessage
            (
              result.Message,
              result.Status === ProcessStatus.Success && result.Message.match("exists") ? 2
                : result.Status === ProcessStatus.Success ? 1 : 3,
              '', null, '', ''
            );

          if (ProcessStatus.Success && !result.Message.match("exists")) {
            setTimeout(() => {
              if (action == "SAVEANDMANAGEVOLUME") {
                this._router.navigate(['app/admin/managevolume']);
              }
              else {
                this.ReloadVendorEdit();
              }
              this.IsLoading = false;
            }, 2000);
          } else {
            this.IsLoading = false;
          }
        }
      },

        err => {
          this.IsLoading = false;
        });

      this.subscriptionList$.push(subscribe$);
      console.log(this.subscriptionList$);
    }
  }

  validateSave(): boolean {

    if (isStringNullOrEmpty(this.editVendorData.VendorName)) {
      this.showNotificationMessage('Please provide Vendor Name', 2, '', null, '', '');
      return false;
    }

    if (parseInt(this.editVendorData.VendorName)) {
      this.showNotificationMessage('Vendor Name should not be number', 2, '', null, '', '');
      return false;
    }

    if (isStringNullOrEmpty(this.editVendorData.SupplierCode)) {
      this.showNotificationMessage('Please provide Supplier Code', 2, '', null, '', '');
      return false;
    }
    if (isStringNullOrEmpty(this.editVendorData.FlatNo)) {
      this.showNotificationMessage('Please provide Flat No', 2, '', null, '', '');
      return false;
    }
    if (isStringNullOrEmpty(this.editVendorData.StreetName)) {
      this.showNotificationMessage('Please provide Street Name', 2, '', null, '', '');
      return false;
    }
    if (isStringNullOrEmpty(this.editVendorData.CityName)) {
      this.showNotificationMessage('Please provide City Name', 2, '', null, '', '');
      return false;
    }
    if (isStringNullOrEmpty(this.editVendorData.State)) {
      this.showNotificationMessage('Please provide State', 2, '', null, '', '');
      return false;
    }
    if (isStringNullOrEmpty(this.editVendorData.Country)) {
      this.showNotificationMessage('Please provide Country', 2, '', null, '', '');
      return false;
    }
    if (isNullOrUndefined(this.editVendorData.Pincode) || !isNumber(this.editVendorData.Pincode)) {
      this.showNotificationMessage('Please provide Pincode', 2, '', null, '', '');
      return false;
    }

    // Check Email Domain Name is NULL
    if (isStringNullOrEmpty(this.editVendorData.EmailDomainName)) {

      this.showNotificationMessage('Please provide Domain Name.', 2, '', null, '', '');
      return false;
    }

    // Check Email Domain Name is Not NULL
    if (!isStringNullOrEmpty(this.editVendorData.EmailDomainName)) {
      //let domainName = this.editVendorData.EmailDomainName.includes(this.editVendorData.VendorName);
      let name = this.editVendorData.EmailDomainName.substring(0, this.editVendorData.EmailDomainName.lastIndexOf("@"));
      // Email Domain Name constraint
      // if (domainName==false) {
      //   this.showNotificationMessage('Please provide correct Email Domain Name.', 2, '', null, '', '');
      //   return false;
      // }
      // Checking @ symbol in domain name Email Domain Name 
      if (!isStringNullOrEmpty(name)) {
        this.showNotificationMessage('Please provide only Email Domain Name after @ symbol.', 2, '', null, '', '');
        return false;
      } else if (this.editVendorData.EmailDomainName.lastIndexOf("@") >= 0) {
        this.showNotificationMessage('Please remove @ symbol in Email Domain Name.', 2, '', null, '', '');
        // this.showNotificationMessage('Please remove the Email Domain Name with @ symbol.', 2, '', null, '', '');
        return false;
      } else if (this.editVendorData.EmailDomainName.lastIndexOf(".") < 0) {
        this.showNotificationMessage('Please provide valid Email Domain Name.', 2, '', null, '', '');
        return false;
      }
    }

    if (isStringNullOrEmpty(this.editVendorData.FunctionalContactEmail) || !isEmail(this.editVendorData.FunctionalContactEmail)) {
      this.showNotificationMessage('Please provide valid Functional Contact Email', 2, '', null, '', '');
      return false;
    }
    if (isStringNullOrEmpty(this.editVendorData.QCContactEmail) || !isEmail(this.editVendorData.QCContactEmail)) {
      this.showNotificationMessage('Please provide valid QC Contact Email', 2, '', null, '', '');
      return false;
    }
    if (isStringNullOrEmpty(this.editVendorData.NonGreenContactEmail) || !isEmail(this.editVendorData.NonGreenContactEmail)) {
      this.showNotificationMessage('Please provide valid Non-Green Contact Email', 2, '', null, '', '');
      return false;
    }
    if (isStringNullOrEmpty(this.editVendorData.PrimarySupportEmail) || !isEmail(this.editVendorData.PrimarySupportEmail)) {
      this.showNotificationMessage('Please provide valid Primary Support Email', 2, '', null, '', '');
      return false;
    }
    if (isStringNullOrEmpty(this.editVendorData.Level1EscalationEmail) || !isEmail(this.editVendorData.Level1EscalationEmail)) {
      this.showNotificationMessage('Please provide valid Level1 Escalation Email', 2, '', null, '', '');
      return false;
    }
    if (isStringNullOrEmpty(this.editVendorData.Level2EscalationEmail) || !isEmail(this.editVendorData.Level2EscalationEmail)) {
      this.showNotificationMessage('Please provide valid Level2 Escalation Email', 2, '', null, '', '');
      return false;
    }
    if (isStringNullOrEmpty(this.editVendorData.InvoiceSupportEmail) || !isEmail(this.editVendorData.InvoiceSupportEmail)) {
      this.showNotificationMessage('Please provide valid Invoice Support Email', 2, '', null, '', '');
      return false;
    }
    if (isStringNullOrEmpty(this.editVendorData.InvoiceLevel1EscalationEmail) || !isEmail(this.editVendorData.InvoiceLevel1EscalationEmail)) {
      this.showNotificationMessage('Please provide valid Invoice Level1 Escalation Email', 2, '', null, '', '');
      return false;
    }
    if (isStringNullOrEmpty(this.editVendorData.InvoiceLevel2EscalationEmail) || !isEmail(this.editVendorData.InvoiceLevel2EscalationEmail)) {
      this.showNotificationMessage('Please provide valid Invoice Level2 Escalation Email', 2, '', null, '', '');
      return false;
    }

    return true;
  }

  ReloadVendorEdit(): void {
    this.Title = "Vendor Details";
    this.SelectedTab = "VendorList";
    this.VendorId = 0;
    this.VendorData();
  }

  MemberData(): void {

    this.IsLoading = true;

    this.memListData = [];
    this.memListDataALL = [];
    this.dataTotalCount = 0;

    let subscribe$: Subscription = this.adminService.getMemberDetailsByVendorId(this.VendorId).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        this.memListData = result.Data;
        console.log(this.memListData);
        this.dataTotalCount = this.memListData.length;

        this.memListDataALL = this.memListData;

        this.memListData.forEach((item) => {
          //item.Action = item.RowStatus == "A" ? '3,4' : "5";
          item.Action = item.RowStatus == "A" ? '4,5' : '6';
          item.IsEditable = 1;
        });
        setTimeout(() => {
          if (!isNullOrUndefined(this.overallgrid)) {
            this.overallgrid.Pagination.CurrentPage = 1;
          }
        }, 50);

      }

      this.setActionButtonPermissions();

      this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);
  }

  DeleteMember(deleteMemberData: any): void {


    this.IsLoading = true;

    let subscribe$: Subscription = this.adminService.deleteMember(deleteMemberData).subscribe(result => {
      if (!isNullOrUndefined(result)) {

        this.showNotificationMessage
          (
            result.Message,
            result.Status === ProcessStatus.Success ? 1
              : result.Status === ProcessStatus.NoContent ? 2 : 3,
            '', null, '', ''
          );
      }
      this.IsLoading = false;
      if (result.Status === ProcessStatus.Success) {
        this.MemberData();
      }
    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);

  }

  GetMemberDataByMemberId(): void {

    this.IsLoading = true;

    this.editMemberData = new MemberDetail();

    let subscribe$: Subscription = this.adminService.getMemberDetailsByMemberId(this.MemberId).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        this.editMemberData = result.Data;

        // Remove Domain Name
        let name = this.editMemberData.EmailId.substring(0, this.editMemberData.EmailId.lastIndexOf("@"));
        this.editMemberData.EmailId = !isStringNullOrEmpty(name) ? name : this.editMemberData.EmailId;

        this.editMemberData.IsEditable = 1;

        console.log(this.editMemberData);
      }

      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
        //this._toastr.warning(result.Message, this.CompTitle);
      }

      this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);
  }

  SaveMemberEdit(): void {

    if (this.validateMemberSave()) {

      this.editMemberData.VendorId = this.VendorId;

      this.IsLoading = true;

      let subscribe$: Subscription = this.adminService.updateMemberDetails(this.editMemberData).subscribe(result => {
        if (!isNullOrUndefined(result)) {

          this.showNotificationMessage
            (
              result.Message,
              result.Status === ProcessStatus.Success && result.Message.match("exists") ? 2
                : result.Status === ProcessStatus.Success ? 1 : 3,
              '', null, '', ''
            );

          if (ProcessStatus.Success && !result.Message.match("exists")) {
            setTimeout(() => {
              this.ReloadMemberData();
              this.IsLoading = false;
            }, 2000);
          } else {
            this.IsLoading = false;
          }
        }
      },

        err => {
          this.IsLoading = false;
        });

      this.subscriptionList$.push(subscribe$);
      console.log(this.subscriptionList$);

    }
  }

  validateMemberSave(): boolean {

    // if (isNullOrUndefined(this.editMemberData.UserId) || !isNumber(this.editMemberData.UserId)) {
    //   this.showNotificationMessage('Please provide User ID', 2, '', null, '', '');
    //   return false;
    // }

    if (isStringNullOrEmpty(this.editMemberData.Name)) {
      this.showNotificationMessage('Please provide Name', 2, '', null, '', '');
      return false;
    }
    if (isStringNullOrEmpty(this.editMemberData.Gender)) {
      this.showNotificationMessage('Please provide Gender', 2, '', null, '', '');
      return false;
    }

    // /|| !isEmail(this.editMemberData.EmailId)
    if (isStringNullOrEmpty(this.editMemberData.EmailId)) {
      this.showNotificationMessage('Please provide valid Email', 2, '', null, '', '');
      return false;
    }


    // Check Email Domain Name is Not NULL
    if (!isStringNullOrEmpty(this.editMemberData.EmailId)) {

      let name = this.editMemberData.EmailId.substring(0, this.editMemberData.EmailId.lastIndexOf("@"));
      // let domain = this.editMemberData.EmailId.substring(this.editMemberData.EmailId.lastIndexOf("@") + 1);

      // Checking @ symbol in domain name Email Domain Name 
      if (!isStringNullOrEmpty(name)) {
        this.showNotificationMessage('Please remove the Domain Name with @ symbol.', 2, '', null, '', '');
        return false;
      } else if (this.editMemberData.EmailId.lastIndexOf("@") >= 0) {
        this.showNotificationMessage('Please remove the Domain Name with @ symbol.', 2, '', null, '', '');
        return false;
      }
    }

    // if (isNullOrUndefined(this.editMemberData.ValidFromDate) || !isDate(this.editMemberData.ValidFromDate)) {
    //   this.showNotificationMessage('Please provide Valid From Date', 2, '', null, '', '');
    //   return false;
    // }
    // if (isNullOrUndefined(this.editMemberData.ValidTillDate) || !isDate(this.editMemberData.ValidTillDate)) {
    //   this.showNotificationMessage('Please provide Valid Till Date', 2, '', null, '', '');
    //   return false;
    // }

    return true;
  }

  ReloadMemberData(): void {
    this.SelectedTab = "MemberList";
    this.MemberData();
  }

  pageItemsChange() {

    if (this.curItemsPerPage > 0) {
      this.GridProp.PaginationConfig.ItemsPerPage = this.curItemsPerPage;
    }
    else {
      this.GridProp.PaginationConfig.ItemsPerPage = 100;
    }
    // reload grid data
    this.VendorData();
  }

  actButtonClick(event: any) {
    switch (event.buttonname.toUpperCase()) {
      case 'EXPORTTOEXCEL':
        this.exportToExcel();
        break;
      case 'FILTER':
        this.showFilter();
        break;

    }
  }

  actionButtonClick(data: any) {
    let valid: boolean = true;

    if (!isNullOrUndefined(data.Name) && data.Name !== '') {
      switch (data.Name.toUpperCase()) {
        //StopCase
        case 'MANAGEVOLUME':
          this._router.navigate(['app/admin/managevolume']);
          break;
        case 'ADDVENDOR':
          this.editVendorData = new VendorDetail();
          this.setActionButtonPermissions();
          this.Title = "Add Vendor";
          this.SelectedTab = "VendorEdit";
          break;
        case 'CANCELVENDOR':
          if (this.editVendorData.IsModified == 1) {
            this.showNotificationMessage('Are you sure you want to cancel un-saved data?',
              2, 'CancelVendorEdit', null, 'Yes', 'No');
          }
          else {
            this.ReloadVendorEdit();
          }
          break;
        case 'UPDATEVENDOR':
        case 'SAVEANDMANAGEVOLUME':
          this.SaveVendorEdit(data.Name.toUpperCase());
          break;
        case 'BACKVENDOR':
          this.ReloadVendorEdit();
          break;
        case 'ADDMEMBER':
          this.editMemberData = new MemberDetail();
          this.SelectedTab = "MemberEdit";
          break;
        case 'CANCELMEMBER':
          if (this.editMemberData.IsModified == 1) {
            this.showNotificationMessage('Are you sure you want to cancel un-saved data?',
              2, 'CancelMemberEdit', null, 'Yes', 'No');
          }
          else {
            this.ReloadMemberData();
          }
          break;
        case 'SAVEMEMBER':
          this.SaveMemberEdit();
          break;
      }
    }
  }

  onClick(event: any): void {
    let selitem = event.item;

    switch (event.fieldname.toUpperCase()) {
      case 'ACTION':
        switch (event.icon.Value) {
          case '0':
            // View 
            this.VendorId = selitem.VendorId;
            this.VendorName = selitem.VendorName;
            this.Title = this.VendorName;

            this.GetVendorDataById();
            this.SelectedTab = "VendorEdit";
            break;
          case '1':
            if (selitem.UserDef7 == '0') {
              this.showNotificationMessage('Are you sure you want to disable?',
                2, 'DeleteVendor', selitem, 'Yes', 'No');
            }
            else {
              this.showNotificationMessage('Kindly re-map the candidate digits to other vendor before disable, click yes for manage volume.',
                2, 'ShowManageVolume', selitem, 'Yes', 'No');
            }
            break;
          case '2':
            this.showNotificationMessage('Are you sure you want to enable?',
              2, 'EnableVendor', selitem, 'Yes', 'No');
            break;
          case '3':
            this.VendorId = selitem.VendorId;
            this.VendorName = selitem.VendorName;
            this.Title = this.VendorName;

            this.MemberData();
            this.SelectedTab = "MemberList";
            break;
          case '4':
            // View 
            this.MemberId = selitem.TransactionId;
            this.GetMemberDataByMemberId();
            this.SelectedTab = "MemberEdit";
            break;
          case '5':
            this.showNotificationMessage('Are you sure you want to disable?',
              2, 'DeleteMember', selitem, 'Yes', 'No');
            break;
          case '6':
            this.showNotificationMessage('Are you sure you want to enable?',
              2, 'EnableMember', selitem, 'Yes', 'No');
            break;
        }
        break;
    }
  }

  onChange(event: any): void {
    let selitem: any = event.item;
    event.item.IsModified = 1;
  }

  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item
    };

    this.displayNotifybox = true;
  }

  setNotifyRes(event: any): void {
    console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;
    switch (event.action.toUpperCase()) {
      case 'DELETEVENDOR':
        if (event.result.toUpperCase() === 'YES') {
          event.item.RowStatus = "I";
          this.DeleteVendor(event.item);
        }
        break;
      case 'SHOWMANAGEVOLUME':
        if (event.result.toUpperCase() === 'YES') {
          this._router.navigate(['app/admin/managevolume']);
        }
        break;

      case 'DELETEMEMBER':
        if (event.result.toUpperCase() === 'YES') {
          event.item.RowStatus = "I";
          this.DeleteMember(event.item);
        }
        break;
      case 'ENABLEVENDOR':
        if (event.result.toUpperCase() === 'YES') {
          event.item.RowStatus = "A";
          this.DeleteVendor(event.item);
        }
        break;
      case 'ENABLEMEMBER':
        if (event.result.toUpperCase() === 'YES') {
          event.item.RowStatus = "A";
          this.DeleteMember(event.item);
        }
        break;
      case 'CANCELVENDOREDIT':
        if (event.result.toUpperCase() === 'YES') {
          this.ReloadVendorEdit();
        }
        break;
      case 'CANCELMEMBEREDIT':
        if (event.result.toUpperCase() === 'YES') {
          this.Title = this.VendorName;
          //this.VendorId = this.VendorId;
          this.ReloadMemberData();
        }
        break;
    }
    if (canClose) {
      this.closeNotifybox();
    }
  }

  closeNotifybox(): void {
    this.displayNotifybox = false;
  }

  exportToExcel() {

    if (this.venListData.length === 0) {
      // this.toastr.warning("Report Data is empty");
    }
    else {
      this.IsLoading = true;

      let fileName = "BGV-Vendor-Details";
      let Data = this.venListData;
      let fldtemplate = this.venListTemplate.filter(f => f.Group == "SearchResult");

      if (this.SelectedTab == "MemberList") {
        fileName = "BGV-Member-Details";
        Data = this.memListData;
        fldtemplate = this.venListTemplate.filter(f => f.Group == "MemberResult");
      }

      let month = new Date().getMonth() + 1;
      this.ExcelFileName = fileName + new Date().getDate() + '-' + month + '-' + new Date().getFullYear() + ' ' + new Date().toLocaleTimeString()

      let tempdata = [];
      // tempdata.push({ Name: 'Master Data', Data: this.venListData });
      tempdata.push({
        Name: 'Vendor Data', Data: Data
        , ColumnList: !isNullOrUndefined(this.Config.DownloadColumns) && this.Config.DownloadColumns.length > 0 ? this.Config.DownloadColumns.map((x: any) => x.FieldName) : null
        , FieldTemplate: fldtemplate
      });

      this.excelexport.generateExcelTable_fromJSON(tempdata, this.ExcelFileName);

      this.IsLoading = false;
    }
  }

  showFilter(event?: any): void {
    this.DispFilter = !this.DispFilter;

    if (!isNullOrUndefined(event)) {
      this.curFiltCondn = event.curFiltCondn;
    }
  }

  setFilter(event: any) {
    if (this.SelectedTab == "VendorList") {
      if (event.sCondn.length > 0) {
        this.venListData = asEnumerable(this.venListDataALL)
          .Where($ => (eval(event.sCondn)))
          .ToArray();
        this.dataTotalCount = this.venListData.length;
      }
      else {
        this.venListData = this.venListDataALL;
        this.dataTotalCount = this.venListData.length;
      }
    }
    else if (this.SelectedTab == "MemberList") {
      if (event.sCondn.length > 0) {
        this.memListData = asEnumerable(this.memListDataALL)
          .Where($ => (eval(event.sCondn)))
          .ToArray();
        this.dataTotalCount = this.memListData.length;
      }
      else {
        this.memListData = this.memListDataALL;
        this.dataTotalCount = this.memListData.length;
      }
    }
  }
}
