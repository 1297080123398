<div class="offcanvas offcanvas-end show" [ngStyle]="{'visibility':'visible'}" tabindex="-1" 
    [ngClass]="[(DisplayMode=='PREVIEW')? 'w-50': 'w-75']"
    id="open-attachments" aria-labelledby="all-attachments">
    
    <div class="offcanvas-header">
        <h2 class="offcanvas-title" id="all-attachments"><span>{{'Attachments'|translate}}</span></h2>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"
            (click)="closeAttachment()"></button>
    </div>
    <div class="offcanvas-body bg-light">
        <div class="row g-0 h-100 align-items-stretch">

            <!-- Preview File section -->
            <div class="bgCoolGrey position-relative" [ngClass]="[(DisplayMode=='PREVIEW')? 'col-12':'col-6']"   >
                <h3 class="w-100 p-3 bgMildGrey">{{'Preview'|translate}}: {{previewFileName}} </h3>
                <div class="position-absolute top-50 start-50 translate-middle w-100 h-100">
                    <div class="viewerContainer">                    
                        <!-- <p>Click view icon to preview the attachment</p> -->

                        <ng-container *ngIf="previewFilePath.length == 0">
                            <p class="position-absolute top-50 start-50 translate-middle">Click view icon to preview the attachment</p>
                        </ng-container>

                        <ng-container *ngIf="previewFilePath!=null && previewFilePath!=undefined && previewFilePath!='' && previewFilePath.length > 0">
                            <!-- <p>File: {{previewFileName}} </p> -->

                            <!-- <iframe [src]="previewFilePath" style="width:100%;height:50vh;">
                            </iframe> -->

                            <ng-container
                                *ngIf="(previewFileType!=null && previewFileType!=undefined && previewFileType!='')">

                                <!-- Images -->
                                <ng-container *ngIf="(previewFileType == 'image')">
                                    <div class="text-center borderBox" style="max-height: 100%;overflow: auto;">
                                        <img alt="Capgemini Logo" class="w-100"
                                            [src]="sanitizer.bypassSecurityTrustResourceUrl(previewFilePath)">
                                    </div>
                                </ng-container>

                                <!-- PDF -->
                                <ng-container *ngIf="(previewFileType == 'pdf')">
                                    <pdf-viewer [src]="previewFilePath" [render-text]="true" [original-size]="false"
                                        style="width: 100%; height: 100%">
                                        <!-- style="width: 100%; height: 100%"> -->
                                    </pdf-viewer>
                                </ng-container>

                                <!-- Excel -->
                                <ng-container *ngIf="(previewFileType == 'excel')">
                                    <excel-viewer [srcFile]="previewFileData"></excel-viewer>
                                </ng-container>

                                <!-- Docs -->
                                <ng-container *ngIf="(previewFileType == 'others')">
                                    <ngx-doc-viewer [url]="previewFilePath" viewer="mammoth"
                                        style="width:100%;height:100%;">
                                        <!-- style="width:100%;height:50vh;" > -->
                                    </ngx-doc-viewer>

                                </ng-container>

                                <!-- <ngx-doc-viewer [url]="previewFilePath" viewer="office" style="width:100%;height:50vh;" >
                                </ngx-doc-viewer> -->

                                <!-- <ngx-doc-viewer [url]="previewFilePath" viewer="google" 
                                    style="width:100%;height:50vh;">
                                </ngx-doc-viewer> -->
                                <!--  -->
                                <!-- <ngx-doc-viewer
                                    [viewerUrl]="https://docs.google.com/gview?url=%URL%&embedded=true"
                                    [url]="https://file-examples.com/wp-content/uploads/2017/02/file-sample_100kB.doc"
                                    viewer="url"
                                    style="width:100%;height:50vh;">
                                </ngx-doc-viewer> -->


                            </ng-container>


                        </ng-container>

                    </div>
                </div>
            </div>

            <!-- File List section -->
            <ng-container *ngIf="(DisplayMode=='LIST')">
                <div class="col-6 bg-white">
                    <h3 class="w-100 p-3 bgMildGrey">{{'Select files to download'|translate}}</h3>

                    <div class="px-3">
                        <div class="row py-2">
                            <div class="col-8">
                                <div class="form-check">
                                    <input id="checkval" type="checkbox" class="form-check-input"
                                        (change)="toggleSelectAll()" [checked]="selectAll">
                                    <label  for="checkval" class="form-check-label">
                                        {{'Attachments'|translate}}
                                    </label>
                                </div>
                            </div>
                            <div class="col-4">
                                <div>{{'Actions'|translate}}</div>
                            </div>
                        </div>


                        <ng-container *ngIf="(SectionList != null && SectionList != undefined && SectionList.length >0)">
                            <ng-container *ngFor="let section of SectionList; let i = index">
                                <div class="p-3 mb-2 bg-light text-dark">
                                    <h3>{{section.SectionName | translate}}</h3>
                                </div>

                                <ng-container *ngFor="let attachmentData of section.AttachmentList; let j = index">
                                    <div class="row py-2">
                                        <div class="col-8">
                                            <!-- <div class="form-check">
                                                <input type="checkbox" class="form-check-input"
                                                    [id]="attachmentData.TransactionId">
                                                <label class="form-check-label" [for]="attachmentData.TransactionId">
                                                    {{attachmentData.FileName}}
                                                </label>
                                            </div> -->
                                            <div class="form-check">
                                                <!-- (change)="checkIfAllAttachmentSelected()" -->
                                                <input id="cheval" type="checkbox" class="form-check-input"
                                                    value="{{attachmentData.FileName}}"
                                                    [(ngModel)]="attachmentData.IsSelected">
                                                <label for="cheval" class="form-check-label">
                                                    {{attachmentData.FileName}}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <ul class="actionList d-flex mt-2">
                                                <li>
                                                    <a  aria-label="Download Attachment" href="javascript:;" (click)="downloadAttachment(attachmentData)">
                                                        
                                                        <i class="fa fa-download" aria-hidden="true"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a aria-label="Preview" href="javascript:;"  (click)="setPreviewFile(attachmentData)">
                                                        
                                                        <i class="fa fa-eye" aria-hidden="true"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </ng-container>

                            </ng-container>
                        </ng-container>
                    
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <ng-container *ngIf="(DisplayMode=='LIST')">
        <div class="offcanvas-footer">
            <div class="d-flex justify-content-end p-3">
                <button type="button" class="btnCustom btnSecondary rounded-pill"><i class="fa fa-download"
                        aria-hidden="true"></i> Download selected</button>
                <button type="button" class="btnCustom btnPrimary rounded-pill" (click)="downloadALLAttachment()"><i
                        class="fa fa-download" aria-hidden="true"></i> Download all</button>
            </div>
        </div>
    </ng-container>
</div>

<!-- OLD CODE for attachment -->
<ng-container *ngIf="false">
    <div  id="Attachment" class="modal modal-popup" tabindex="-1" role="dialog" style="display:block;" #dragboundary>
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <!-- Modal content -->
            <div class="ng-draggable modal-content" ngDraggable [inBounds]="true" [bounds]="dragboundary"
                [handle]="draghandle" style="width:700px !important">
                <!-- Modal header -->
                <div class="modal-header bg-light drag-block-handle" #draghandle>
                    <h3 class="modal-title"><i class="fa fa-paperclip"></i> Attachment Details</h3>
                    <button  type="button" class="btn-close text-reset" data-bs-dismiss="modal" aria-label="Close" 
                        (click)="closeAttachment()">
                        <!-- <span aria-hidden="true">&times;</span> -->
                    </button>
                    
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <ng-container *ngIf="Permissions.Upload">
                        <div class="form-group">
                            <div class="custom-file">
                                <ng-container *ngIf="!IsMultiple">
                                    <!-- <label class="form-label" for="inputfile" *ngIf="selFileNames.length == 0">Choose file</label> -->
                                    <!-- <label class="form-label" for="inputfile" [title]="selFileNames"
                                    *ngIf="selFileNames.length > 0">{{selFileNames}}</label> -->
                                    <label for="inputfile" class="sr-only">fileinput</label>
                                    <input class="custom-file-input" id="inputfile" type="file"  (change)="handleUpload($event)"  accept="UploadType" />

                                    <!-- <label for="inputfile" class="form-label"></label>
                                    <input class="form-control" type="file" id="inputfile" (change)="handleUpload($event)"> -->
        
                                </ng-container>
                                
                                <ng-container *ngIf="IsMultiple">
                                
                                    <!-- <label class="form-label" for="inputfilemulti" *ngIf="selFileNames.length == 0">Choose files</label> -->
                                    <!-- <label class="form-label" for="inputfilemulti" [title]="selFileNames"
                                        *ngIf="selFileNames.length > 0">{{selFileNames}}</label> -->
                                        <label for="inputfilemulti" class="sr-only">fileinput</label>
                                    <input class="form-control form-control-sm" id="inputfilemulti" type="file" multiple 
                                            (change)="handleUpload($event)"   accept="UploadType"/>
        
                                </ng-container>

                            </div>
                            <!-- <div class="input-group-append">
                                <input type="button" class="btnPrimary" (click)="uploadAttachments()" value="Upload" />
                            </div> -->
                        </div>
                        <!-- <div class="accordion-body">
                            <div class="row small-gutter form-inline mt-4">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="ed5" class="">Upload Document</label>
                                        <input id="ed5" type="file" class="form-control form-control-sm" (click)="uploadAttachments()">
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </ng-container>

                    <div>
                        <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
                    </div>

                    
                </div>
                <!-- /.modal-content -->

                <div class="modal-footer">
                    <div class="d-flex justify-content-between">
                        <!-- <button type="button" class="btnCustom btnSecondary rounded-pill"><i class="fa fa-download"
                                aria-hidden="true"></i> Download selected</button>
                        <button type="button" class="btnCustom btnPrimary rounded-pill"><i class="fa fa-download"
                                aria-hidden="true"></i> Download all</button> -->

                        <button type="button" class="btnCustom btnPrimary rounded-pill" (click)="uploadAttachments()"
                            value="{{'Upload' | translate }}">{{'Upload' | translate }}
                            <!-- <span class="ps-1"><i class="ion ion-md-arrow-forward" aria-hidden="true"></i></span> -->
                        </button>
                        
                    </div>
                </div>

            </div>
            <!-- /.modal-dialog -->
        </div>
    </div>
</ng-container>

<!-- Notification section -->
<div *ngIf="displayNotifybox ">
    <!-- [modaldata]="modaldata " -->
    <notification [notifyinfo]="notify.info " [curaction]="notify.action " [curitem]="notify.item "
        (selected)="setNotifyRes($event) " (close)="closeNotifybox() "></notification>
</div>