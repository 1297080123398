export class VendorDetail {
    VendorId: number = 0;
    ProcessId: string = '';
    TenantId: string = '';
    VendorName: string = '';
    Description: string = '';
    SupplierCode: string = '';
    Address: string = '';
    FunctionalContactEmail: string = '';
    QCContactEmail: string = '';
    NonGreenContactEmail: string = '';
    PrimarySupportEmail: string = '';
    Level1EscalationEmail: string = '';
    Level2EscalationEmail: string = '';
    InvoiceSupportEmail: string = '';
    InvoiceLevel1EscalationEmail: string = '';
    InvoiceLevel2EscalationEmail: string = '';
    CandidateIdDigits: string = '';
    ClientSpecifications: string = '';
    HireType: string = '';
    UserDef1: string = '';
    UserDef2: string = '';
    UserDef3: string = '';
    UserDef4: string = '';
    UserDef5: string = '';
    UserDef6: string = '';
    UserDef7: string = '';
    RowStatus: string = '';
    VendorIdSec: number = 0;
    VendorNameSec: string = '';
    FlatNo: string = '';
    StreetName: string = '';
    CityName: string = '';
    State: string = '';
    Country: string = '';
    Pincode?: number;
    IsEditable: number = 1;
    IsModified: number= 0;
    EmailDomainName:string = '';

    constructor(init?: Partial<VendorDetail>) {
        Object.assign(this, init);
    }
}
